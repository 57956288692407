import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';



const TeamSalesReportSkeleton = () => {
    const rows = Array.from(new Array(6));

    return (
        <div style={{width:'100%',display:'flex',gap:'20px',flexDirection:'column'}}>
        <div style={{width:'100%',display:'flex',gap:'40px',marginBottom:'20px'}}>
        <div style={{width:'30%',display:'flex',gap:'10px',flexDirection:'column'}}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        </div>
        <Skeleton variant="rounded" width="70%" height="212px" />

        </div>
        <div>
        <Table  aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell><Skeleton width="60%" height="30px" /></TableCell>
                    <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                    <TableCell><Skeleton width="40%" height="30px" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((_, index) => (
                    <TableRow key={index}>
                        <TableCell><Skeleton width="60%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </div>
       
            
        </div>
    );
};

export default TeamSalesReportSkeleton;
