import { styled } from "@material-ui/core";
import React from "react";
import SideBar from "./sidebar/SideBar";
import Content from "./content";

const ProductServiceWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
}));
const ProductService = () => {
  return (
    <ProductServiceWrapper>
      <SideBar />
      <Content/>
    </ProductServiceWrapper>
  );
};

export default ProductService;
