import { Box } from "@material-ui/core";
import React, { useState } from "react";
import InsuranceCompanyHeader from "./InsuranceCompanyHeader";
import ContentBody from "./contentBody";

const Content = ({
  isLoading,
  insuranceCompanyList,
  setInsuranceCompanyList,
  search,
  setSearch,
  folderList,
  handleFolderList,
  selectedFolder,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  total,
  folderObject,
  setFilterTrigger,
  filterTrigger
}) => {
  
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  
  return (
    <Box flex={1} sx={{ background: "white", borderRadius: "8px" }}>
      <InsuranceCompanyHeader
        search={search}
        setSearch={setSearch}
        setInsuranceCompanyList={setInsuranceCompanyList}
        folderList={folderList}
        handleFolderList={handleFolderList}
        selectedFolder={selectedFolder}
        setPage={setPage}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
        insuranceCompanyList={insuranceCompanyList}
        setFilterTrigger={setFilterTrigger}
        filterTrigger={filterTrigger}
      />
      <ContentBody
        insuranceCompanyList={insuranceCompanyList}
        setInsuranceCompanyList={setInsuranceCompanyList}
        selectedFolder={selectedFolder}
        isLoading={isLoading}
        folderList={folderList}
        handleFolderList={handleFolderList}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        page={page}
        total={total}
        folderObject={folderObject}
        setFilterTrigger={setFilterTrigger}
        filterTrigger={filterTrigger}
        setSelectedCompanies={setSelectedCompanies}
        selectedCompanies={selectedCompanies}
      />
    </Box>
  );
};

export default Content;
