import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BackIcon, DeleteIcon, EditIcon, ErrorIcon, SaveIcon } from "./Icons";
import "./open-ai.css";
import { deleteOpenAIApikey, getOpenAIApikey, saveOpenAIApikey } from "../../api/profileApi";
import Loading from "./Loading";
import { Button, Divider, IconButton, Modal, Typography } from "@material-ui/core";
import NewConfirmAlert from "../activity/new-alert/NewConfirmAlert";

const hideMiddle = (inputString) => {
    if (inputString.length <= 6 * 2) {
        return inputString;
    }

    const firstPart = inputString.slice(0, 6);
    const lastPart = inputString.slice(-6);
    return `${firstPart}**************************************${lastPart}`;
};

const urlify = (text) => {
    try {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
    } catch (err) {
        return text;
    }
}

const OpenAi = () => {
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [apiKey, setApiKey] = useState("");
    const [version, setVersion] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [errorModalOpen, setErrorModalOpen] = useState(false);


    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const resp = await getOpenAIApikey();
            if (resp && resp.success) {
                setApiKey(resp.data.apiKey);
                setVersion(resp.data.version);
            } else {
                setEdit(true);
            }
        } catch (err) {
            setEdit(true);
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const saveApiKey = async () => {
        try {
            setSubmitting(true);
            const resp = await saveOpenAIApikey({ apiKey: apiKey, version: "paid" });
            if (resp && resp.success) {
                window.showNotification("SUCCESS", resp.message);
                setEdit(false);
            } else {
                setEdit(true);
                setErrorMessage(resp?.data.message);
                setErrorModalOpen(true);
            }
        } catch (err) {
            setEdit(true);
            console.log(err)
        } finally {
            setSubmitting(false);
        }
    };

    const deleteApiKey = () => {
        NewConfirmAlert({
            onSubmit: async () => {
                try {
                    setDeleting(true);
                    const resp = await deleteOpenAIApikey();
                    if (resp && resp.success) {
                        setApiKey("");
                        setEdit(true);
                    } else {
                        setEdit(false);
                    }
                } catch (err) {
                    setEdit(false);
                    console.log(err)
                } finally {
                    setDeleting(false);
                }
            },
            title: "Are You Sure?",
            description: "Are you sure you want to delete this API key? This action cannot be undone.",
            cancelText: "Cancel",
            submitText: "Delete",
            width: "480px",
        });
    };

    return (
        <div className="app_integration_form_section">
            <div className="page_heading">
                <h4>OpenAI Integration</h4>
                <Link to={'/user/settings'} className="accent--bg--color">
                    <BackIcon />
                    Back
                </Link>
            </div>
            <div className="page_content">
                {
                    loading ?
                        <Loading /> :
                        <Fragment>
                            <div className="d-flex align-items-center">
                                <div>
                                    <h5>Connect via Api Key</h5>
                                    <p className="sm_font">Connect via api key from CRM to OpenAI</p>
                                </div>
                            </div>

                            <div className="input_group">
                                <label>Api Key</label>
                                <div className="app_store_module__preview_field">
                                    {
                                        isEdit ?
                                            <input type="text" className="validate" onChange={(event) => {
                                                setApiKey(event.target.value);
                                            }} value={apiKey} placeholder="OpenAI api key" required="required" /> :
                                            <h5 className="readable_edit_input">{hideMiddle(apiKey)}</h5>
                                    }
                                </div>
                            </div>

                            <div className="btn_group">
                                {
                                    !isEdit ?
                                        <Fragment>
                                            <button type="button" className="custom_btn eidt_btn" onClick={() => {
                                                setEdit(true);
                                            }}>
                                                <EditIcon />
                                                Edit
                                            </button>
                                            <button disabled={deleting} type="button" className="custom_btn delete_btn"
                                                onClick={deleteApiKey}>
                                                <DeleteIcon />
                                                {deleting ? "Deleting..." : "Delete"}
                                            </button>
                                        </Fragment> :
                                        <button disabled={submitting} type="submit" className="custom_btn eidt_btn"
                                            onClick={saveApiKey}>
                                            <SaveIcon />
                                            {submitting ? "Saving..." : "Save"}
                                        </button>
                                }
                            </div>
                        </Fragment>
                }
            </div>

            {
                !loading &&
                <div className="page_content pt-1">
                    <div className="content_footer">
                        <h5>Why do I need my OpenAI API Key and where can I find it?</h5>
                        <p className="sm_font">You will need your OpenAI api key to use <strong>Ask AI</strong> in text
                            editor.</p>
                        <div className="footer_action">
                            <p>Login to OpenAI, then goto to <strong><a href="https://platform.openai.com/api-keys"
                                target="_blank">https://platform.openai.com/api-keys</a></strong>
                            </p>
                            <p>Click <strong>Create new secret key</strong>, put a name and save.</p>
                        </div>
                    </div>
                </div>
            }
            {
                errorMessage &&
                <Modal
                    open={errorModalOpen}
                    onClose={() => {
                        setErrorModalOpen(false);
                        setApiKey("");
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div style={{
                        width: '600px',
                        borderRadius: '12px',
                        backgroundColor: '#ffffff',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '16px 0',
                        }}>
                            <IconButton
                                variant="outlined"
                                color="orange"
                                style={{ width: '75px', height: '75px' }}
                            >
                                <ErrorIcon width={50} height={50} color="red" />
                            </IconButton>
                        </div>
                        <div style={{ color: 'red', textAlign: 'center', padding: '8px 24px' }}>
                            <Typography
                                sx={{ color: 'red' }}
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: urlify(errorMessage) }}
                            />
                        </div>
                        <Divider style={{ marginTop: '20px' }} />
                        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px 24px' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={() => {
                                    setErrorModalOpen(false);
                                    setApiKey("");
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    );
};

export default OpenAi;