import React from 'react';
import { Box } from '@material-ui/core';
import LostReasonHeader from './LostReasonHeader';
import LostReasonBody from './LostReasonBody/LostReasonBody';


const LostReasons = () => {
    return (
        <Box flex={1} sx={{background:'white',borderRadius:'8px'}}>
           <LostReasonHeader/>
           <LostReasonBody/>
        </Box>
    );
};

export default LostReasons;