import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Add } from '@material-ui/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createNewPersonalizedField, reloadPersonalizedTag } from '../../../actions/CustomFieldAction';
import BasicInput from '../../custom/BasicInput';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  createNewPersonalizedField: (params, callback) => dispatch(createNewPersonalizedField(params, callback)),
  reloadPersonalizedTag: (params, callback) => dispatch(reloadPersonalizedTag(params, callback)),
})

const PersonilizFromApp = (props) => {
  const [loading, setLoading] = useState(false)
  const [fieldName, setFieldName] = useState('')
  const [personalizedValue, setPersonalizedValue] = useState('')

  const handleSubmit = () => {
    if(fieldName === ''){
      window.showNotification("WARNING", "Personalized field name can not be empty")
      return
    }

    if(personalizedValue === ''){
      window.showNotification("WARNING", "Personalized field value can not be empty")
      return
    }
    setLoading(true)
    props.createNewPersonalizedField({
      personalized_title: fieldName,
      personalized_value: personalizedValue
    }, () => {
      props.reloadPersonalizedTag({status: true})
      setLoading(false)
      setFieldName('')
      setPersonalizedValue('')
      try{
        let local_data = localStorage.getItem("global__custom__field__data");
        if(local_data){
            localStorage.removeItem("global__custom__field__data")
        }
    }
    catch(error){
        console.log(error)
    }
    })
  }

  return (
    <form className="custom__form personailized__form">
      <div className="personailized__form__left">
        <FormControl>
          <FormLabel>Personalized Field Name</FormLabel>
          {/* <Input placeholder="Enter Personalized Name" 
          value={fieldName} 
          onChange={(event) => setFieldName(event.target.value)} 
          disableUnderline={true} /> */}
          <BasicInput 
            value={fieldName} 
            onChange={e => setFieldName(e.target.value)} 
            placeholder="Enter Personalized Name" 
            fullWidth
          />
        </FormControl>
        <FormControl>
          <FormLabel>Personalized value</FormLabel>
          {/* <Input placeholder="Enter Personalized value" 
          value={personalizedValue} 
          onChange={(event) => setPersonalizedValue(event.target.value)} 
          disableUnderline={true} /> */}

          <BasicInput 
            value={personalizedValue} 
            onChange={e => setPersonalizedValue(e.target.value)} 
            placeholder="Enter Personalized value" 
            fullWidth
          />
        </FormControl>
        <FormControl className="personalized__tag">
          <FormLabel style={{paddingBottom:"16px"}}>Personalized Tag</FormLabel>
          <p>[[ {fieldName.split(" ").join("_")} ]]</p>
        </FormControl>

        <div className="personilize__btn__wr">
         {/* <Button
          className="reset__icon"
          
         >
          <span className="reset__icon__svg"><svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.6665 0C7.07521 0 5.54908 0.632141 4.42386 1.75736C3.29865 2.88258 2.6665 4.4087 2.6665 6H0.666504L3.25984 8.59333L3.3065 8.68667L5.99984 6H3.99984C3.99984 4.76232 4.4915 3.57534 5.36667 2.70017C6.24184 1.825 7.42883 1.33333 8.6665 1.33333C9.90418 1.33333 11.0912 1.825 11.9663 2.70017C12.8415 3.57534 13.3332 4.76232 13.3332 6C13.3332 7.23768 12.8415 8.42466 11.9663 9.29983C11.0912 10.175 9.90418 10.6667 8.6665 10.6667C7.37984 10.6667 6.21317 10.14 5.37317 9.29333L4.4265 10.24C5.51317 11.3333 6.99984 12 8.6665 12C10.2578 12 11.7839 11.3679 12.9091 10.2426C14.0344 9.11742 14.6665 7.5913 14.6665 6C14.6665 4.4087 14.0344 2.88258 12.9091 1.75736C11.7839 0.632141 10.2578 0 8.6665 0Z" fill="#133159" fill-opacity="0.65"/>
          </svg>
          </span>
         Reset
         </Button> */}
        
          <Button
            className="add__icon add__icon__personalized accent--bg--text--color"
            startIcon={loading ? <MoreHorizIcon /> : <Add />}
            onClick={handleSubmit}
          >
            <span className="create__content">Create Personalized</span>
          </Button>
        </div>
      </div>
    </form>
  );
}
const PersonilizFrom = connect(mapStateToProps, mapDispatchToProps)(PersonilizFromApp);
export default PersonilizFrom;
