import React from 'react';
import { MenuItem, Select, makeStyles } from '@material-ui/core';
import { BasicInputSelect } from '../productsStyle';

const useStyles = makeStyles((theme) => ({
    menuItem: {
      "&:hover": {
        backgroundColor: "#cbd4ff",
        color: "#133159",
      },
      "&.Mui-selected": {
        backgroundColor: "#cbd4ff",
        color: "#133159",
      },
    }
  }));

const BasicSelect = ({ options, defaultText = 'Select', name = 'default', onChange,multiple=false,value='', ...rest }) => {

    const {menuItem} = useStyles();
    const handleSelectChange = (event) => {
        onChange(event);
    };

    return (
        <Select
            value={value}
            multiple={multiple}
            fullWidth
            onChange={handleSelectChange}
            name={name}
            displayEmpty
            input={<BasicInputSelect />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        maxHeight: '280px'
                    },
                },
                getContentAnchorEl: null,
            }}
            {...rest}
        >
            <MenuItem className={menuItem} value="" disabled>
                {defaultText}
            </MenuItem>
            {options.map((option, index) => (
                <MenuItem className={`${menuItem} dropdownhelper-menuitem-class`} key={index} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default BasicSelect;
