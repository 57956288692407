import React, { useEffect, useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import { Skeleton } from "@material-ui/lab";
import { SidebarLeftIcon, SidebarRightIcon } from "../../products/common/Icons";
import { CustomScrollbar } from "../../products/productsStyle";
import EachFolderItem from "./EachFolderItem";
import CreateNewFolderModal from "./modal/CreateNewFolderModal";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../products/common/productSelector";
import { getCustomFieldFolder, handleNewFolderCreateModal, handleSelectedCustomFieldFolder } from "../../../actions/CustomFieldInsuranceAction";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  width: isSidebarCollapsed ? 56 : 268,
  height: "calc(100vh - 255px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));

const SideBar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const dispatch = useDispatch();
  const {openNewFolderCreateModal,gettigFolders, folderList, customFieldParams } = useSelector(customFieldInsuranceState);

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const showContent = isSidebarCollapsed ? "none" : "block";

  useEffect(()=>{
    dispatch(getCustomFieldFolder());
  },[]);  // eslint-disable-line react-hooks/exhaustive-deps 

  const handleSelectAll = () => {
    dispatch(handleSelectedCustomFieldFolder(null));
};

  const renderFolderList = () => {
    if (gettigFolders) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (folderList?.length > 0 && !gettigFolders) {
      return folderList.map((folder) => (
        <EachFolderItem
          key={folder.id}
          folder={folder}
        />
      ));
    }
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
      <Typography className="sidebarTitle" variant="body2">
          My Custom Fields
        </Typography>
        <IconButton color="primary" onClick={handleCollapsed} size="small">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
        <ListItem
          button
           selected={customFieldParams.group_id === null}
           onClick={handleSelectAll}
          style={{ marginBottom: 4 }}
        >
          <ListItemIcon>
            <FolderIcon
              color="primary"
              fontSize="small"
              style={{ color: "#346fef" }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={"text.primary"}
              variant="body2"
              fontWeight={600}
              minWidth={170}
            >
              All Custom Fields
            </Typography>
          </ListItemText>
        </ListItem>

        <CustomScrollbar style={{ maxHeight: "calc(100vh - 420px)" }}>
          <div className="folderList">{renderFolderList()}</div>
        </CustomScrollbar>

        <Divider light style={{ marginTop: 16, marginBottom: 8 }} />
        <ListItem button onClick={() => dispatch(handleNewFolderCreateModal(true))}>
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? " Create Category" : ""}>
              <AddIcon style={{ color: "#346fef" }} fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={600}
              minWidth={170}
              style={{ color: "#346fef" }}
            >
              New Folder
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {openNewFolderCreateModal && (
        <CreateNewFolderModal
          open={openNewFolderCreateModal}
          onClose={() => dispatch(handleNewFolderCreateModal(false))}
        />
      )}
    </SidebarWrapper>
  );
};

export default SideBar;
