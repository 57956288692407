import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import BootstrapTooltip from '../globals/BootstrapTooltip';
import Loader from '../globals/Loader';
import {  getLookupSettingApi, updateLookupSettingApi } from '../../api/profileApi';
// import { Icon } from '@material-ui/core';
import { SettingIconList } from '../../constants/SettingIconList';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

const SendEmailWithLookupFilterArr = ['isSendUndeliverable', 'isSendDeliverable', 'isSendRisky',  'isRuntimeLookupValidationForEmail'];
const SendTextWithLookupFilterArr = ['isRuntimeLookupValidationForText', 'isSendLandlineForText', 'isSendMobileForText'];
const SendCallWithLookupFilterArr = ['isRuntimeLookupValidationForCall', 'isSendLandlineForCall', 'isSendMobileForCall'];
const SendRvmWithLookupFilterArr = ['isRuntimeLookupValidationForRvm', 'isSendLandlineForRvm', 'isSendMobileForRvm'];


const LookupValidation = (props) => {
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [data, setData] = useState([]);
    const [settingLebel, setSettingLebel] = useState([]);

    useEffect(() => {
        if (window.setActiveSidebar) {
            window.setActiveSidebar("setting");
        }
        loadData();
    }, []);

    // useEffect(() => {
    //     loadData();
    // }, [reset]);

    const loadData = () => {
        setLoading(true);
        getLookupSettingApi({ form_data: { } })
            .then((response) => {
                setData(response.data.data['settings']);
                setSettingLebel(response.data.data['setting_label'])
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getLebel = (keyText='')=>{
        if(settingLebel[keyText]){
            return settingLebel[keyText];
        }else{
            return keyText;
        }
    }


    const handleData = (e, value)=>{
        if(!isSubmit){
            let old_data = data;
            let new_data = {};
            if(e.target.name === 'isSendRvmWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendRvmWithLookupFilter: true, isRuntimeLookupValidationForRvm:false, isSendLandlineForRvm:false, isSendMobileForRvm:true};
                }else{
                    new_data = { ...old_data, isSendRvmWithLookupFilter: false, isRuntimeLookupValidationForRvm:false, isSendLandlineForRvm:false, isSendMobileForRvm:false};
                }
            }else if(e.target.name === 'isSendCallWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendCallWithLookupFilter: true, isRuntimeLookupValidationForCall:false, isSendLandlineForCall:false, isSendMobileForCall:true};
                }else{
                    new_data = { ...old_data, isSendCallWithLookupFilter: false, isRuntimeLookupValidationForCall:false, isSendLandlineForCall:false, isSendMobileForCall:false};
                }
            }else if(e.target.name === 'isSendTextWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendTextWithLookupFilter: true, isRuntimeLookupValidationForText:false, isSendLandlineForText:false, isSendMobileForText:true};
                }else{
                    new_data = { ...old_data, isSendTextWithLookupFilter: false, isRuntimeLookupValidationForText:false, isSendLandlineForText:false, isSendMobileForText:false};
                }
            }else if(e.target.name === 'isSendEmailWithLookupFilter'){
                if(value === true){
                    new_data = { ...old_data, isSendEmailWithLookupFilter: true, isRuntimeLookupValidationForEmail:false, isSendUndeliverable:false, isSendDeliverable:true, isSendRisky:false};
                }else{
                    new_data = { ...old_data, isSendEmailWithLookupFilter: false, isRuntimeLookupValidationForEmail:false, isSendUndeliverable:false, isSendDeliverable:false, isSendRisky: false};
                }
            }else{
               new_data = { ...old_data, [e.target.name]: value}; 
            }

            setData(new_data);

        }
    }

    const handleUpdate=(e)=>{
        if(!isSubmit){
            setIsSubmit(true);
            updateLookupSettingApi({ form_data: data })
            .then((response) => {
                if(response.data.status === 'success'){
                   window.showNotification('SUCCESS', response.data.message);
                }else{
                    window.showNotification('ERROR', response.data.message);
                }
            })
            .finally(() => {
                setIsSubmit(false);
            });
        }
    }

    const commonHtmlRender = (key='', value=false, isDisable=true)=>{
        return (
            <>
                <td>{getLebel(key)}</td>
                <td className='text-center'>
                    <label className="switch">
                        <small className="on">Yes</small>
                        <input 
                            type="checkbox" 
                            // defaultChecked={false}
                            checked={value === true ? true :false}
                            name = {key}
                            disabled = {isDisable? "disabled" : ""}
                            onChange={(e) => {handleData(e, !value)}}
                        />
                        <span className="slider round"></span>
                        <small className="off">No</small>
                    </label>
                </td>
            </>
        )
    } 

    const renderData = () => {
        if (loading) {
            let loadingView = [];
            for (let i = 0; i < 5; i++) {
                loadingView.push(
                    <tr key={i}>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td className='text-center'> <Skeleton width="20%" height={"16px"}> </Skeleton></td>
                    </tr>    
                );
            }
            return loadingView;
        } else if (!loading) {
            let view = [];
            let i=0;

            for (const [key, value] of Object.entries(data)) {
                i++;

                if(SendEmailWithLookupFilterArr.includes(key)){
                    view.push(
                        <tr key={i}>
                            {commonHtmlRender(key, value, !data.isSendEmailWithLookupFilter)}
                        </tr>
                    )
                }else if(SendTextWithLookupFilterArr.includes(key)){
                    view.push(
                        <tr key={i}>
                            {commonHtmlRender(key, value, !data.isSendTextWithLookupFilter)}
                        </tr>
                    )
                }else if(SendCallWithLookupFilterArr.includes(key)){
                    view.push(
                        <tr key={i}>
                            {commonHtmlRender(key, value, !data.isSendCallWithLookupFilter)}
                        </tr>
                    )
                }else if(SendRvmWithLookupFilterArr.includes(key)){
                    view.push(
                        <tr key={i}>
                            {commonHtmlRender(key, value, !data.isSendRvmWithLookupFilter)}
                        </tr>
                    )
                }else{
                    view.push(
                        <tr key={i} style={{background: "rgba(0, 85, 255, 0.05)"}}>
                            {commonHtmlRender(key, value, false)}
                        </tr>
                    )
                }
                
            }
            return view;
        }
    }

    return (
        <Fragment>
            <div className='settings_section'>
                <Fragment>
                    <div className='container-fluid mail__inbox mt-1'>
                        <div className='tagBox'>
                            <div className='tag_list'>
                                <h5>Lookup Validation</h5>
                            </div>
                            <p style={{color:"red"}}>**Alert: Enabling services will deducts credits!</p>
                            <div className='add_new_user'>
                                <Link
                                    className={"modal-trigger accent--bg--text--color"}
                                    to='/user/settings'
                                >
                                    <span>
                                        {SettingIconList.back}
                                    </span>
                                    <span>Back</span>
                                </Link>

                            </div>
                        </div>
                        <div className='details'>
                            { isSubmit &&
                                <div style={{paddingTop: "400px", left:"50%", position: "absolute", zIndex: "500"}}>
                                    <Loader/>
                                </div>
                            }
                            
                            <table className='custom-menu-table'>
                                <thead>
                                    <tr className='header'>
                                        <th style={{width: '50%'}}>Setting Name </th>
                                        <th className='text-center' style={{width: '50%'}}>Status</th>
                                    </tr>
                                </thead>
                                <tbody className='tbody_content'>{renderData()}</tbody>
                            </table>

                            <Box sx={{py: 5, textAlign: 'center'}}>
                                <button onClick={(e)=>handleUpdate(e)} className='btn accent--bg--text--color'>
                                    Update</button>  
                            </Box>
                            
                        </div>
                    </div>
                </Fragment>
            </div>
        </Fragment>
    );
}

export default LookupValidation;