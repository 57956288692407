import { Box, Typography } from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {EMPTY_DATA_IMG} from "../../../constants/CoreConstants";

const useStyles = makeStyles(()=>({
    wrapper: {
        display: "flex",
        flexDirection:'column-reverse',
        height: "calc(100vh - 250px)",
        alignItems: "center",
        justifyContent: "center",
    },
    leftSide: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop:'20px'
    },
    rightSide: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent:'center'
    }
}));

const EmptyProductSalesDrawer = () => {
  const classes = useStyles();

  return (
    <div  className={classes.wrapper}>
      <Box className={classes.leftSide}>
          <Typography align="center" variant="h5">
            No one sales this product yet!
          </Typography>
      </Box>
      <Box className={classes.rightSide}>
        <img
          height={300}
          src={EMPTY_DATA_IMG}
          alt="Empty data"
        />
      </Box>
    </div>
  );
};

export default EmptyProductSalesDrawer;
