import React, {useEffect} from "react";
import MapOrderDetails from "./MapOrderDetails";
import Utils from "../../helpers/Utils";

const MapOrderV2 = () => {
    useEffect(() => {
        document.title = `Map order | ${Utils.getAccountData("AuthUserAgencyName")}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar("map_finder");
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            <MapOrderDetails/>
        </div>
    );
};

export default MapOrderV2;