import { Button, withStyles } from "@material-ui/core";

  export const CustomButton = withStyles(()=>({
    root: {
      fontWeight: 600,
      fontSize: "16px",
      backgroundColor: "#346fef !important",
      color: "white !important",
      "& svg":{
        color: "white !important",
      },
      "&:focus, &:hover": {
        backgroundColor: "#346fef !important",
      },
    }
  }))(Button);