import {
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { salesVsGoalReport } from "../../../api/productsApi";
import Utils from "../../../helpers/Utils";
import DateRangePicker from "../dateRangePicker";
import { userTimeSelectConverter } from "../helper/functions";
import EmptySalesVsGoal from "./EmptySalesVsGoal";
import SalesVsGoalSkeleton from "./SalesVsGoalSkeleton";

const Wrapper = styled("div")(({ theme }) => ({
  padding: 16,
  background: "white",
  borderRadius: "8px",
  minHeight: "calc(100vh - 180px)",

  "& .salesVsGoalHeader": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
    "& .filter": {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
  },

}));
const useStyles = makeStyles({
  root: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  container: {
    maxHeight: 640,
    overflowX: 'hidden',
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "6px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "6px",
    },
  },
  table: {
    minWidth: 600,
  },
  head: {
    backgroundColor: "#f5f5f5",
    fontWeight: "700",
    fontSize: "18px",
    border: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  subhead: {
    backgroundColor: "#f5f5f5",
    fontWeight: "600",
    fontSize: "16px",
    border: "1px solid rgba(224, 224, 224, 1)",
    textAlign: "center",
    position: 'sticky',
    top: 56,
    zIndex: 1,
  },
  cell: {
    textAlign: "center",
    border: "1px solid rgba(224, 224, 224, 1)",
    fontSize: "16px",
  },
});


const SalesVsGoalReport = ({assetsLoaded}) => {
  const [resetClosedRange, setResetClosedRange] = useState(false);
  const [isLoading,setIsLoading] = useState(true);
  const [reportData,setReportData] = useState(null);
  const { teamUsers } = useSelector((state) => state.productReducer);
  const [teamUserObject, setTeamUserObject] = useState([]);
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null
  });
 

  const classes = useStyles();

  useEffect(() => {
    let length = teamUsers.length;

    let tmp = [];
    for (let i = 0; i < length; i++) {
      tmp[teamUsers[i].id] = teamUsers[i].full_name;
    }
    setTeamUserObject(tmp);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(()=>{
    if(assetsLoaded){
    handleTeamSalesReport().then();}
  },[formData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTeamSalesReport = async () => {
    try {
      let submitData = {
        startDate: formData.startDate ? userTimeSelectConverter(formData.startDate) : null,
        endDate: formData.endDate ? userTimeSelectConverter(formData.endDate) : null,
        role: Utils.getAccountData('userIsAdmin') ? 'ADMIN' : Utils.getAccountData('userIsOwner') ? 'OWNER' : 'AGENT',
      }


      setIsLoading(true);
      const resp = await salesVsGoalReport(submitData);

      if (resp && resp.success && resp.data[0]) {
        setReportData(resp.data);
      } else {
        setReportData([]);
      }
    } catch (err) {
      console.log(err);
      setReportData([]);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrapper>
      <div className="salesVsGoalHeader">
        <Typography style={{ fontSize: '18px', fontWeight: '600', color: '#346FEF', textAlign: 'center' }}>
          Sales vs Goals
        </Typography>

        <div className="filter">
          <DateRangePicker
            style={{ right: 0 }}
            reset={resetClosedRange}
            updateReset={() => setResetClosedRange(false)}
            callback={(data) => {
              setFormData(prev => ({
                ...prev,
                startDate: data.startDate,
                endDate: data.endDate
              }));
            }}
          />
        </div>
      </div>
      { isLoading ? <SalesVsGoalSkeleton/> :
      (reportData && !reportData[0] && !isLoading ) ? <EmptySalesVsGoal/> :
       ( <div className={classes.root}>
            <TableContainer className={classes.container}>
        <Table  stickyHeader aria-label="sticky table simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{textAlign:'left'}} className={classes.head} rowSpan={2}>
                      Team Member
                    </TableCell>
                    {(Utils.getAccountData("agencyBusinessType") === undefined || 
                      Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ? (
                      <>
                            <TableCell className={classes.head} colSpan={2}>
                              Value of Deals
                            </TableCell>
                            <TableCell className={classes.head} colSpan={2}>
                              Products/Services
                            </TableCell>
                      </>
                      ) : (
                      <>
                            <TableCell className={classes.head} colSpan={2}>
                              Total Premium
                            </TableCell>
                            <TableCell className={classes.head} colSpan={2}>
                              Policy Count
                            </TableCell>
                      </>
                    )}
                    
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.subhead}>GOAL</TableCell>
                    <TableCell className={classes.subhead}>ACTUAL</TableCell>
                    <TableCell className={classes.subhead}>GOAL</TableCell>
                    <TableCell className={classes.subhead}>ACTUAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.bodyWidth}>
                  {
                    reportData.map((row) => (
                      <TableRow key={row.name}>
                        <TableCell style={{textAlign:'left'}} className={classes.cell} component="th" scope="row">
                        {row.userId ? teamUserObject[row.userId] ? teamUserObject[row.userId] : '' : ''}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {Utils.formatCurrency(row.totalGoalSale, "$")}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {Utils.formatCurrency(row.totalActualSale, "$")}
                        </TableCell>
                        <TableCell className={classes.cell}>{row.totalGoalProduct}</TableCell>
                        <TableCell className={classes.cell}>{row.totalActualProduct}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>)
      }
    </Wrapper>
  );
};

export default SalesVsGoalReport;
