import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Close, PlayArrow } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const SalePackage=(props) =>{
  

  let description = props.item.description;
  let des_list = [];
  if(description != null && description !== ""){
    try{
      des_list = description.split('|')
    }catch(error){

    }
  }
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0',
      boxShadow: "none",
      padding: "20px",
      borderRadius: "6px",
      position: "relative",
      maxWidth: "600px",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const purchaseAction=()=>{

    if(props.packageInformation.packageInfo && props.packageInformation.packageInfo.agency_external_approved_package_id !== props.item.id){
      props.updateReducerInformations({'paymentPage': true})

    }else if(!props.packageInformation.hasPackage){
      props.updateReducerInformations({'paymentPage': true})

    }
  }

  const paymentMessage=()=>{

    if(props.item && props.item.life_line){
        if(props.item.life_line <=31 && props.item.life_line >=30){
            return 'Monthly Subscription Fee:'
        }else if (props.item.life_line ===365){
            return 'Annual Subscription Fee:'
        }else {
            return `Subscription Fee:`
        }
    }else{
        return 'Subscription Fee:'
    }
}

  return ( 
      // <div className="sale__package" onClick={() => props.onChangePackage(props.item)}>
      <div className={`sale__package ${props.packageInformation.selectedPackage.id === props.item.id ? 'mi_active_card' : '' }`} onClick={() => props.onChangePackage(props.item)}>
        <h2 className="sale_package_title">
          {props.item.name}
          {/* {props.props.packageInformation.selectedPackage.id === props.item.id &&
            <CheckCircleIcon className="list_icon" />
          } */}
        </h2>

        <Typography className="sale_package_footer_text">
          <strong>{paymentMessage()} </strong> <span>${(parseFloat(props.item.subscription_fee) + parseFloat(props.item.additional_charge)).toFixed(2)}</span>
        </Typography>

        <Box className="sale_package_footer alter">
          <Typography className="sale_package_footer_text alt">
            <strong>Billing: Every </strong> {props.item.life_line} days
          </Typography>
          {props.packageInformation.videoLink &&
          <Button
            variant="contained"
            color="white"
            className="play__btn"
            endIcon={<PlayArrow />}
            onClick={handleOpen}
          >
            How To Use
          </Button>
          }
          <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} modal_fade`}>
          <IconButton className="close_modal_icon" onClick={handleClose}>
            <Close/>
          </IconButton>
            <h2 id="transition-modal-title">An Introduction To GeoFarming</h2>
            {/* <p id="transition-modal-description">An Introduction To GeoFarming</p> */}
            <iframe width="560" height="315" src={`${props.packageInformation.videoLink}`} title="Geo Farming" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </Fade>
      </Modal>

        </Box>


        <List
          component="nav"
          aria-label="main mailbox folders"
          className="sale_package_list"
        >
          {des_list.map((item, index) => {
            return (
              <ListItem button key={index}>
                <ListItemIcon>
                  <CheckCircleIcon className="list_icon" />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            )
          })}
          {/* <ListItem button>
            <ListItemIcon>
              <CheckCircleIcon className="list_icon" />
            </ListItemIcon>
            <ListItemText primary={`Search limit: ${props.item.usage_limit}`} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <CheckCircleIcon className="list_icon" />
            </ListItemIcon>
            <ListItemText primary={`Team member limit: ${props.item.max_user_count}`} />
          </ListItem> */}
        </List>

        <Box className="sale_package_footer_btn">
          <Button
            variant="contained"
            color="primary"
            className="pay__btn alt"
            onClick={()=>purchaseAction()}
          >
            {((props.packageInformation.selectedPackage && props.packageInformation.packageInfo) && 
              ((props.packageInformation.selectedPackage.id === props.item.id) || (props.packageInformation.packageInfo.package_id === props.item.id))) &&
            <CheckCircleIcon className="list_icon" />
            }
            {props.packageInformation.packageInfo && props.packageInformation.packageInfo.agency_external_approved_package_id === props.item.id ?
            <>&nbsp;Purchased</>
            : 'Get Access Now'}
            
          </Button>
        </Box>
      </div> 
  );
}

export default SalePackage;
