import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialSelect from "@material-ui/core/Select";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getMapListContactsApi } from "../../api/mapList";
import { updateDataAfterMapSkipTrace } from "../../api/profileApi";
import { MenuProps } from "../../constants/CoreConstants";
import { useIsMounted } from "../../hooks/IsMounted";
import ActionDropDown from "./actions/ActionDropDown";
import { checkEmptyArray, convertIncome } from "./helpers";
import { BackIcon, FilterIcon, RefreshIcon } from "./Icons";
import Styles from "./MapOrderDetails.module.css";
import MapOrderItemCombine from "./MapOrderItemCombine";
import MapOrderItemFailed from "./MapOrderItemFailed";
import MapOrderItemProcessing from "./MapOrderItemProcessing";
import MapOrderListSkeleton from "./MapOrderListSkeleton";
import MapOrderTableWrapper from "./MapOrderTableWrapper";
import Pagination from "./Pagination";

const MapOrderDetails = () => {
  const { id } = useParams();
  const isMounted = useIsMounted();
  const sortType = "desc";
  const [loading, setLoading] = useState(false);
  const [mapContacts, setMapContacts] = useState(null);
  const [filters, setFilters] = useState(null);
  const [emailNumberSearch, setEmailNumberSearch] = useState("all");
  const [genderSearch, setGenderSearch] = useState("all");
  const [lengthOfResidence, setLengthOfResidence] = useState([]);
  const [livingSquareFeet, setLivingSquareFeet] = useState([]);
  const [income, setIncome] = useState([]);
  const [creditRating, setCreditRating] = useState([]);
  const [homeOwnerFlag, setHomeOwnerFlag] = useState([]);
  const [homeValue, setHomeValue] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [yearBuilt, setYearBuilt] = useState([]);
  const [subdivisionName, setSubdivisionName] = useState([]);
  const [autoXdate, setAutoXdate] = useState([]);
  const [autoXdateSource, setAutoXdateSource] = useState([]);
  const [saleDate, setSaleDate] = useState([]);
  const [pool, setPool] = useState([]);
  const [motorcycle, setMotorcycle] = useState([]);
  const [rv12, setRv12] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [reset, setReset] = useState(0);
  const [orderDetails, setOrderDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [query, setQuery] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [currentPageIds, setCurrentPageIds] = useState([]);
  const [exportData, setExportData] = useState([]);
  const intervals = [5000, 7000, 10000, 10000, 10000];
  const [pollCount, setPollCount] = useState(intervals.length);
  const [skiptracingIds, setSkiptracingIds] = useState([]);

  useEffect(() => {
    if (currentPage !== "") {
      (async () => {
        await loadData();
      })();
    }
  }, [// eslint-disable-line react-hooks/exhaustive-deps
    currentPage,
    reset,
    perPage,
    sortType,
    debouncedQuery,
    emailNumberSearch,
    genderSearch,
    lengthOfResidence,
    livingSquareFeet,
    income,
    creditRating,
    homeOwnerFlag,
    homeValue,
    maritalStatus,
    yearBuilt,
    subdivisionName,
    autoXdate,
    autoXdateSource,
    saleDate,
    pool,
    motorcycle,
    rv12
  ]);

  useEffect(() => {
    if (isMounted) {
      const timer = setTimeout(() => {
        setDebouncedQuery(query);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const reload = async () => {
      try {
        const res = await updateDataAfterMapSkipTrace({
          mapFetchContactIds: skiptracingIds
        });

        if (res && res.success) {
          let data = res.data;

          setMapContacts((prevData) => ({ ...prevData, ...data }));
          prepareExportData({ ...mapContacts, ...data });

          let remainingSkiptracingIds = skiptracingIds.filter((key) => !(data[key] && (data[key].map_skip_trace_status === "SUCCESS" || data[key].map_skip_trace_status === "TRACE_FAILED")));

          if (!remainingSkiptracingIds[0]) {
            setSkiptracingIds([]);
            setPollCount(intervals.length);
          } else {
            setSkiptracingIds(remainingSkiptracingIds);
            setPollCount(prevCount => prevCount + 1);
          }
        }
      } catch (err) {
        console.log(err);
        setPollCount(intervals.length);
      }
    };
    const nextInterval = intervals[pollCount % intervals.length];
    const pollingInterval = setInterval(() => {
      if (pollCount < intervals.length) {
        (async () => {
          await reload();
        })();
      } else {
        clearInterval(pollingInterval);
      }
    }, nextInterval);

    return () => clearInterval(pollingInterval);
  }, [pollCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const startPolling = () => {
    setSelectedIds([]);
    setSkiptracingIds([...selectedIds]);
    setPollCount(0);
  };

  const isNumber = (text) => {
    try {
      return /^\d+$/.test(text);
    } catch (err) {
      return false;
    }
  }

  const loadData = async () => {
    try {
      setLoading(true);
      let res = await getMapListContactsApi({
        mapListOrderId: id,
        page: currentPage,
        perPage: perPage,
        searchText: debouncedQuery,
        filter: {
          ...(emailNumberSearch !== "all") && { email_number_search: emailNumberSearch },
          ...(genderSearch !== "all") && { gender_search: genderSearch },
          ...(lengthOfResidence.length) && { length_of_residence_search: lengthOfResidence },
          ...(livingSquareFeet.length) && { living_square_feet_search: livingSquareFeet },
          ...(income.length) && { income_search: income },
          ...(creditRating.length) && { credit_rating_search: creditRating },
          ...(homeOwnerFlag.length) && { home_owner_flag_search: homeOwnerFlag },
          ...(homeValue.length) && { home_value_search: homeValue },
          ...(maritalStatus.length) && { marital_status_search: maritalStatus },
          ...(yearBuilt.length) && { year_build_search: yearBuilt },
          ...(subdivisionName.length) && { subdivision_name_search: subdivisionName },
          ...(autoXdate.length) && { auto_xdate_search: autoXdate },
          ...(autoXdateSource.length) && { auto_xdate_source_search: autoXdateSource },
          ...(saleDate.length) && { sale_date_search: saleDate },
          ...(pool.length) && { pool_search: pool },
          ...(motorcycle.length) && { motorcycle_search: motorcycle },
          ...(rv12.length) && { rv_12_search: rv12 },
        }
      });

      if (
        res &&
        res.data &&
        res.data.data &&
        res.data.success
      ) {
        setCurrentPageIds([]);
        setSelectedIds([]);
        setSelectedContacts([]);
        setMapContacts(res.data.data);
        prepareExportData(res.data.data);
        setTotalPages(res.data.totalPage);
        if (res.data && res.data.moreData && res.data.moreData.orderDatails) {
          setOrderDetails(res.data.moreData.orderDatails);
        }

        if (res.data && res.data.moreData && res.data.moreData.filterData) {
          setFilters(res.data.moreData.filterData);
        }
      }
      setLoading(false);
    } catch (er) {
      setLoading(false);
    }
  };

  const prepareExportData = (data) => {
    if (typeof mapContacts !== "object") {
      return;
    }

    const item = [];

    for (const [id, contact] of Object.entries(data)) {
      if (isNumber(id)) {
        contact.income = convertIncome(contact.income);
        item.push(contact);

        const enhanceData = contact.enhance_data;
        if (enhanceData) {
          for (const [contacts] of Object.entries(enhanceData)) {
            for (const [, iContact] of Object.entries(contacts)) {
              iContact.income = convertIncome(iContact.income);

              if (iContact.additional_contacts && Array.isArray(iContact.additional_contacts)) {
                item.push(...iContact.additional_contacts);
              }
              item.push(iContact);
            }
          }
        }
      }
    }

    setExportData(item);
  };

  const handleSelectMapContact = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedFileContactId) => selectedFileContactId !== id));
      setSelectedContacts(selectedContacts.filter((selectedFileContact) => selectedFileContact.id !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
      setSelectedContacts([...selectedContacts, exportData.find((exportDataItem) => exportDataItem.id === id)]);
    }
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };
  const selectAddressGroup = (ids) => {
    setSelectedIds((prevState) => ([...prevState, ...ids]));
    setSelectedContacts(exportData.filter((exportDataItem) => (ids.includes(exportDataItem.id))));
  };

  const deSelectAddressGroup = (ids) => {
    setSelectedIds((prevState) => (prevState.filter((id) => (!ids.includes(id)))));
    setSelectedContacts((prevState) => (prevState.filter((selectedContact) => (!ids.includes(selectedContact.id)))));
  };

  const handleCurrentPageIds = (id) => {
    if (!currentPageIds.includes(parseInt(id))) {
      setCurrentPageIds((prevState) => ([...prevState, parseInt(id)]));
    }
  };

  const updateSkipTraceStatus = (id, status) => {
    let data = { ...mapContacts };
    if (data[id]) {
      data[id].map_skip_trace_status = status;
      setMapContacts(data);
    } else {
      console.log(`ID ${id} not found.`);
    }
  };

  const renderMapContacts = () => {
    let item = [];

    if (typeof mapContacts === "object") {
      Object.entries(mapContacts).forEach(([id, contact], i) => {
        if (isNumber(id)) {
          if (contact.map_skip_trace_status === "NOT_TRACED" || (contact.map_skip_trace_status === "SUCCESS" && contact.enhance_data)) {
            item.push(
              <MapOrderItemCombine
                contact={contact}
                onSelect={handleSelectMapContact}
                collectIds={handleCurrentPageIds}
                isSelected={isSelected}
                selectAddressGroup={selectAddressGroup}
                deSelectAddressGroup={deSelectAddressGroup}
                allSelected={((currentPageIds.length) > 0 && (currentPageIds.length === selectedIds.length))}
                index={i}
              />);
          } else if (contact.map_skip_trace_status === "PROCESSING") {
            item.push(
              <MapOrderItemProcessing
                contact={contact}
                onSelect={handleSelectMapContact}
                collectIds={handleCurrentPageIds}
                isSelected={isSelected}
                index={i}
              />);
          } else if (contact.map_skip_trace_status === "TRACE_FAILED" || (contact.map_skip_trace_status === "SUCCESS" && !contact.enhance_data)) {
            item.push(
              <MapOrderItemFailed
                contact={contact}
                onSelect={handleSelectMapContact}
                collectIds={handleCurrentPageIds}
                isSelected={isSelected}
                index={i}
              />);
          }
        }
      });
    }
    return item;
  };

  const renderFilter = () => (
    <Grid container className={Styles.filterWrapper}>
      <Grid item xs={3}>
        <label>
          Search by Email and Number
          <p>
            <MaterialSelect
              onChange={(event) => {
                setEmailNumberSearch(event.target.value);
              }}
              value={emailNumberSearch}
              MenuProps={MenuProps}
              className="customSelect"
            >
              <MenuItem className="dropdownhelper-menuitem-class" value={"all"}>All</MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={"only-number"}>Number available</MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={"only-email"}>Email available</MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={"number-email"}>Number and email both available</MenuItem>
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Search by gender
          <p>
            <MaterialSelect
              onChange={(event) => {
                setGenderSearch(event.target.value);
              }}
              value={genderSearch}
              MenuProps={MenuProps}
              className="customSelect"
            >
              <MenuItem className="dropdownhelper-menuitem-class" value={"all"}>All</MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={"Male"}>Male</MenuItem>
              <MenuItem className="dropdownhelper-menuitem-class" value={"Female"}>Female</MenuItem>
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Length of Residence
          <p>
            <MaterialSelect
              onChange={(event) => {
                setLengthOfResidence(event.target.value);
              }}
              multiple
              value={lengthOfResidence}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.lengthOfResidenceArray))}
            >
              {
                filters &&
                typeof filters.lengthOfResidenceArray !== "undefined" &&
                filters.lengthOfResidenceArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Living Square Feet
          <p>
            <MaterialSelect
              onChange={(event) => {
                setLivingSquareFeet(event.target.value);
              }}
              multiple
              value={livingSquareFeet}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.livingSquareFeetArray))}
            >
              {
                filters &&
                typeof filters.livingSquareFeetArray !== "undefined" &&
                filters.livingSquareFeetArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Income
          <p>
            <MaterialSelect
              onChange={(event) => {
                setIncome(event.target.value);
              }}
              multiple
              value={income}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && filters.incomeArray && checkEmptyArray(Object.keys(filters.incomeArray)))}
            >
              {
                filters &&
                typeof filters.incomeArray !== "undefined" &&
                Object.keys(filters.incomeArray).map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{filters.incomeArray[item]}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Credit Score
          <p>
            <MaterialSelect
              onChange={(event) => {
                setCreditRating(event.target.value);
              }}
              multiple
              value={creditRating}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.creditRatingArray))}
            >
              {
                filters &&
                typeof filters.creditRatingArray !== "undefined" &&
                filters.creditRatingArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Home Owner Flag
          <p>
            <MaterialSelect
              onChange={(event) => {
                setHomeOwnerFlag(event.target.value);
              }}
              multiple
              value={homeOwnerFlag}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.homeOwnerFlagArray))}
            >
              {
                filters &&
                typeof filters.homeOwnerFlagArray !== "undefined" &&
                filters.homeOwnerFlagArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Home Value
          <p>
            <MaterialSelect
              onChange={(event) => {
                setHomeValue(event.target.value);
              }}
              multiple
              value={homeValue}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.homeValueArray))}
            >
              {
                filters &&
                typeof filters.homeValueArray !== "undefined" &&
                filters.homeValueArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Marital Status
          <p>
            <MaterialSelect
              onChange={(event) => {
                setMaritalStatus(event.target.value);
              }}
              multiple
              value={maritalStatus}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.maritalStatusArray))}
            >
              {
                filters &&
                typeof filters.maritalStatusArray !== "undefined" &&
                filters.maritalStatusArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Year Build
          <p>
            <MaterialSelect
              onChange={(event) => {
                setYearBuilt(event.target.value);
              }}
              multiple
              value={yearBuilt}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.yearBuiltArray))}
            >
              {
                filters &&
                typeof filters.yearBuiltArray !== "undefined" &&
                filters.yearBuiltArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Subdivision Name
          <p>
            <MaterialSelect
              onChange={(event) => {
                setSubdivisionName(event.target.value);
              }}
              multiple
              value={subdivisionName}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.subdivisionNameArray))}
            >
              {
                filters &&
                typeof filters.subdivisionNameArray !== "undefined" &&
                filters.subdivisionNameArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Auto Xdate
          <p>
            <MaterialSelect
              onChange={(event) => {
                setAutoXdate(event.target.value);
              }}
              multiple
              value={autoXdate}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.autoXdateArray))}
            >
              {
                filters &&
                typeof filters.autoXdateArray !== "undefined" &&
                filters.autoXdateArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Auto Xdate Source
          <p>
            <MaterialSelect
              onChange={(event) => {
                setAutoXdateSource(event.target.value);
              }}
              multiple
              value={autoXdateSource}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.autoXdateSourceArray))}
            >
              {
                filters &&
                typeof filters.autoXdateSourceArray !== "undefined" &&
                filters.autoXdateSourceArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Sale Date
          <p>
            <MaterialSelect
              onChange={(event) => {
                setSaleDate(event.target.value);
              }}
              multiple
              value={saleDate}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.saleDateArray))}
            >
              {
                filters &&
                typeof filters.saleDateArray !== "undefined" &&
                filters.saleDateArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Pool
          <p>
            <MaterialSelect
              onChange={(event) => {
                setPool(event.target.value);
              }}
              multiple
              value={pool}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.poolArray))}
            >
              {
                filters &&
                typeof filters.poolArray !== "undefined" &&
                filters.poolArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          Motorcycle
          <p>
            <MaterialSelect
              onChange={(event) => {
                setMotorcycle(event.target.value);
              }}
              multiple
              value={motorcycle}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.motorcycleArray))}
            >
              {
                filters &&
                typeof filters.motorcycleArray !== "undefined" &&
                filters.motorcycleArray.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
      <Grid item xs={3}>
        <label>
          RV-12
          <p>
            <MaterialSelect
              onChange={(event) => {
                setRv12(event.target.value);
              }}
              multiple
              value={rv12}
              MenuProps={MenuProps}
              className="customSelect"
              disabled={(filters && checkEmptyArray(filters.rv12Array))}
            >
              {
                filters &&
                typeof filters.rv12Array !== "undefined" &&
                filters.rv12Array.map((item, index) => (
                  <MenuItem className="dropdownhelper-menuitem-class" value={item}>{item}</MenuItem>
                ))
              }
            </MaterialSelect>
          </p>
        </label>
      </Grid>
    </Grid>
  );

  const renderNoData = () => {
    return (
      <TableRow>
        <TableCell colSpan={9}>
          <div className={Styles.loaderWrapper}>
            <h5 className={Styles.marginTop}>No contact available</h5>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const renderDataLoading = () => {
    return (
        <TableRow>
          <TableCell colSpan={9}>
            {/* <div className={Styles.loaderWrapper}>
              <CircularProgress />
              <h5 className={Styles.marginTop}>Getting contacts....</h5>
            </div> */}
           <MapOrderListSkeleton/>
          </TableCell>
        </TableRow>
    );
  };

  return (
    <div className={Styles.pageWrapper}>
      <div className={Styles.pageHeader}>
        <div style={{ display: "grid" }}>
          <Typography variant="h6" style={{ fontWeight: "600" }}>
            Map Order: {"  "}
            <span className={Styles.highlight}>
              {
                !loading && orderDetails ? (orderDetails.title ? orderDetails.title : orderDetails.order_no) : "...."
              }
            </span>
          </Typography>
          <Typography variant="caption" style={{ fontWeight: "600" }}>
            **The leads below will be deleted in 2 hours. If you wish to keep them you can add them as a contact or download the list as a csv file.
          </Typography>
        </div>
        <div className={Styles.pageHeaderAction}>
          <a href="#!" className={Styles.filterBtn} onClick={(e) => {
            e.preventDefault();
            setShowFilters(!showFilters);
          }}>
            <FilterIcon />
          </a>
          <a href="#!" className={Styles.refreshBtn} onClick={(e) => {
            e.preventDefault();
            setReset(reset + 1);
          }}>
            <RefreshIcon />
            Refresh
          </a>
          <Link to={`/user/map-orders`} className={Styles.backBtn}>
            <BackIcon />
            Back
          </Link>
        </div>
      </div>

      {showFilters && renderFilter()}

      <div className={Styles.pageBody}>
        <div className={Styles.detailsPane}>
          <div className={Styles.detailsPaneHeader}>
            <div className={Styles.searchFieldWrapper}>
              <input
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <ActionDropDown
              selectedIds={selectedIds}
              orderId={id}
              selectedContacts={selectedContacts}
              orderDetails={orderDetails}
              updateSkipTraceStatus={updateSkipTraceStatus}
              setReset={setReset}
              startPolling={startPolling}
            />
          </div>
          <div className={Styles.detailsPaneBody}>
            <MapOrderTableWrapper variant="outlined">
              <TableContainer className="skip-trace-table-container">
                <Table stickyHeader className="skip-tracing-table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={Styles.enhance_data_dnc_result_header}>
                        <Box style={{ display: "flex", alignItems: "center", margin: "5px 0px 5px 10px", padding: 0 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{ color: "#fff" }}
                                indeterminate={((selectedIds.length > 0) && (currentPageIds.length > selectedIds.length))}
                              />
                            }
                            checked={((currentPageIds.length) > 0 && (currentPageIds.length === selectedIds.length))}
                            onChange={(event, checked) => {
                              if (checked) {
                                setSelectedIds(currentPageIds);
                                setSelectedContacts(exportData.filter((exportDataItem) => (currentPageIds.includes(exportDataItem.id))));
                              } else {
                                setSelectedIds([]);
                              }
                            }}
                          />
                          Address
                        </Box>
                      </TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Name</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Email</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Email Status</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Number</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Number Status</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Landline/Mobile</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>DNC Status</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>SkipTrace Status</TableCell>
                      <TableCell className={`${Styles.textAlignCenter} ${Styles.enhance_data_dnc_result_header}`}>Income</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {
                      loading ?
                        renderDataLoading() :
                        (mapContacts && totalPages > 0) ?
                          renderMapContacts() :
                          renderNoData()
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </MapOrderTableWrapper>
            {
              totalPages > 0 &&
              <Pagination
                perPage={perPage}
                setPerPage={setPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapOrderDetails;
