import React from 'react';
import {  virtualNumberRequestForShakenStirRegistration } from '../../../api/profileApi';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import NewConfirmLinkAlert from '../../activity/new-alert/NewConfirmLinkAlert';


const RegisterShakenStirView = ({ item, updateTable, canRegisterShakenStir }) => {
    const requestForShakenStirRegistration = () => {
        NewConfirmLinkAlert({
            onSubmit : () => {
                virtualNumberRequestForShakenStirRegistration({
                    "form_data": {
                        type: 'single',
                        id: item.id
                    }
                }).then(response => {
                    if (response.data.status === 'success') {
                        window.showNotification('SUCCESS', response.data.message);
                        updateTable();
                    } else {
                        window.showNotification('ERROR', response.data.message);
                    }
                }).catch(error => {
                    window.showNotification('ERROR', 'Something went wrong');
                });
            },
            title: 'Would you like to register this number for Shaken/Stir and CNAM?',
            description: '',
            cancelText: 'No',
            submitText: 'Yes',
            width: '530px',
            footerLink: {text: 'Learn more', link: 'https://s3.us-east-1.amazonaws.com/pypepro/contact/27567256/SRH4WAqAZ5RoKYDk3IduoNTmKPxTQcDM3x5CYYgZ.pdf'}
        });
    }

    return (
        <div className='registerShakenStir'>
            {(canRegisterShakenStir === true && item.shaken_stir_status === 0) &&
                <button className='btn btn-primary' style={{fontSize: "14px"}}  onClick={requestForShakenStirRegistration}>Register</button>
            }

            {(canRegisterShakenStir === true && item.shaken_stir_status === 1) &&
                //SUCCESS
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <CheckCircleIcon fontSize="small" style={{ height: '22px', width: '22px', color: '#008000' }} />
                    <span className='pl-1' style={{fontSize: '14px'}}>Twilio Approved</span>
                </div>
            }

            {(canRegisterShakenStir === true && item.shaken_stir_status === 2) &&
                //PENDING
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <InfoIcon fontSize="small" style={{ height: '22px', width: '22px', color: '#777B7E' }} />
                    <span className='pl-1' style={{fontSize: '14px'}}>In Review</span>
                </div>
            }

            {(canRegisterShakenStir === true && item.shaken_stir_status === 3) &&
                //PROCESSING
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <InfoIcon fontSize="small" style={{ height: '22px', width: '22px', color: '#777B7E' }} />
                    <span className='pl-1' style={{fontSize: '14px'}}>In Review</span>
                </div>
            }

            {(canRegisterShakenStir === true && item.shaken_stir_status === 4) &&
                //ERROR
                <>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <ReportProblemIcon fontSize="small" style={{ height: '22px', width: '22px', color: '#DC143C' }} />
                    <span className='pl-1' style={{fontSize: '14px'}}>In Review</span>
                </div>
                 <div style={{paddingLeft: '22px'}}>
                    <span className='pl-1' style={{fontSize: '13px', textDecoration: 'underline', color:'#3c7ef3', cursor: 'pointer'}}
                        onClick={requestForShakenStirRegistration}>
                        Re-Submit
                    </span>
                </div>
                </>
            }

            {(canRegisterShakenStir === true && item.shaken_stir_status === 5) &&
                //QUEUE
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <InfoIcon fontSize="small" style={{ height: '22px', width: '22px', color: '#777B7E' }} />
                    <span className='pl-1 ' style={{fontSize: '14px'}}>
                        In Review
                    </span>
                </div>
            }
        </div>
    )
}

export default RegisterShakenStirView;