import {
  TablePagination,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSelectedPolicies, getPolicyTypes } from "../../../../../actions/ProductAction";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../../common/productSelector";
import BasicSelect from "../BasicSelect";
import { policyTypeStyle } from "../PolicyTypesStyle";
import PolicyTypeTable from "./PolicyTypeTable";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  filterStatus: {
    width: "202px !important",
  }
});

const POLICY_TYPE_STATUS = [
  {
    value: "ALL",
    label: "Show All",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

const PolicyTypesBody = () => {
  const [formData, setFormData] = useState({
    filterStatus: "",
    filterCategory: "",
  });

  const { selectedFolder } = useSelector(policyTypeFolderState);

  const { pagination, totalCount,search } = useSelector(policyTypeState);

  useEffect(() => {
    setFormData({
      filterStatus: "",
      filterCategory: "",
    });
  }, [selectedFolder]);

  const dispatch = useDispatch();

  const { productBodyTitle, grayColor } = policyTypeStyle();

  const handleFilterStatus = (event) => {
    setFormData({ ...formData, filterStatus: event.target.value });

    dispatch(
      getPolicyTypes({
        page: 1,
        perPage: 10,
        status: event.target.value,
        userProductFolderId: selectedFolder === "all" ? null : selectedFolder,
        search:search
      })
    );
  };

  let campaignStatus = null;
  switch (selectedFolder) {
    case "all":
      campaignStatus = null;
      break;
    case "favorite":
      campaignStatus = { isFavorite: "YES" };
      break;
    case "archive":
      campaignStatus = { status: "ARCHIVE" };
      break;
    case "trash":
      campaignStatus = { status: "TRASH" };
      break;
    default:
      campaignStatus = { userProductFolderId: selectedFolder };
  }

  const handleChangePage = (event, newPage) => {
    dispatch(
      getPolicyTypes({ ...pagination, page: newPage + 1, ...campaignStatus, search:search })
    );
    dispatch(addSelectedPolicies({selectedPolicies:[],allChecked:false}));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(
      getPolicyTypes({
        ...pagination,
        perPage: event.target.value,
        search:search,
        ...campaignStatus,
      })
    );
    dispatch(addSelectedPolicies({selectedPolicies:[],allChecked:false}));
  };

  const classes = useStyles();
  return (
    <div className={classes.bodyWrapper}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography className={productBodyTitle} variant="h6">
            All Policy Types
          </Typography>
          <Typography variant="body2" className={grayColor}>
            In this section you’ll list all the different types of insurance
            policies sell in your agency. <br />
            If you offer the policy type click the toggle button on. If you
            don't click the toggle button off.
          </Typography>
        </div>

        <div>
          <BasicSelect
            options={POLICY_TYPE_STATUS}
            defaultText="Filter By Status"
            mapping={{
              value: "value",
              label: "label",
            }}
            value={formData?.filterStatus}
            onChange={handleFilterStatus}
            className={classes.filterStatus}
          />
          {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <BasicSelect
              options={POLICY_TYPE_STATUS}
              defaultText="Filter By Status"
              mapping={{
                value: "value",
                label: "label",
              }}
              value={formData?.filterStatus}
              onChange={handleFilterStatus}
            />
          </Grid>

          <Grid item xs={6}>
            <BasicSelect
              options={[POLICY_TYPE_ALL_CATEGORY, ...policyTypeFolders]}
              defaultText="Filter By Category"
              mapping={{
                value: "id",
                label: "title",
              }}
              value={formData?.filterCategory}
              onChange={handleFilterCategory}
            />
          </Grid>
        </Grid> */}
        </div>
      </div>

      <PolicyTypeTable />

      <div className={classes.paginationWrapper}>
        <div style={{ fontStyle: "italic", color: "#2D6CFF" }}>
          <span className={classes.textFontWeight}>
            **Be sure to choose your policy types carefully. <br />
            This data will be used to compile reports that outline the types of
            policies you are closing your agency.
          </span>
        </div>

        <div style={{ minWidth: "410px" }}>
          <TablePagination
            component="div"
            count={totalCount}
            page={pagination?.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pagination?.perPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationButton}
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyTypesBody;
