import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, MenuItem} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleReactValidator from "simple-react-validator";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import removeHtmlTagFromString from "../../../helpers/utils/removeHtmlTagFromString";
import {ADDRESS_TYPE_LIST, REMIND_TYPE_LIST, STATUS, TYPE,} from "../../../constants/reminderConstant";
import {addNewReminderInvoice, deleteReminderInvoice, editReminderInvoice,} from "../../../api/InvoiceApi";
import DefaultEditor from "../common/Editor/DefaultEditor";
import {insertPersonalizedTag} from "../common/utils/insertPersonalizedTag";
import PersonalizedTag from "../common/Personalized/PersonalizedTag";
import Styles from "./ReminderStyles.module.css";
import BasicInput from "../../custom/BasicInput";
import BasicSelect from "../../custom/BasicSelect";


const AddOrEditReminder = ({
                               isEdit,
                               reminderInfo,
                               onRenderCallback,
                               onClose,
                               type,
                               systemDefaultInfo
                           }) => {
    const [, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const bodyRef = useRef(null);
    const subjectRef = useRef(null);


    const simpleValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: setForceUpdate},
            className: "small text-danger mdi mdi-alert pt-1 pl-1",
        })
    );

    const initialFormDataState = {
        name: "",
        addressType: "",
        numberOfDays: "",
        remindType: "",
        from: "",
        subject: "",
        body: "",
        status: true,
        type: "",
    };

    const [formData, setFormData] = useState(initialFormDataState);

    const handleChangeInput = (name, value) => {
                setFormData({
            ...formData,
            [name]: value,
        });
    };



    useEffect(() => {
        if (reminderInfo?.id) {
            setFormData({
                name: reminderInfo.name,
                addressType: reminderInfo.addressType,
                numberOfDays: reminderInfo.numberOfDays,
                remindType: reminderInfo.remindType,
                from: type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone,
                subject: reminderInfo.subject,
                body: type === TYPE.SMS ? removeHtmlTagFromString(reminderInfo.body) : reminderInfo.body,
                status: reminderInfo.status === STATUS.ACTIVE,
                type: reminderInfo.type,
            });
        }
    }, []);

    const handleSubmit = () => {
        if (simpleValidator.current.allValid()) {
            try {
                const requestBody = {
                    id: reminderInfo?.id,
                    name: formData.name,
                    addressType: formData.addressType,
                    numberOfDays: +formData.numberOfDays,
                    remindType: formData.remindType,
                    from: type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone,
                    subject: formData.subject,
                    body: formData.body,
                    status: formData.status ? STATUS.ACTIVE : STATUS.INACTIVE,
                    type: type,
                };

                if (!requestBody.from) return;

                if (isEdit) {
                    requestBody.id = reminderInfo?.id;

                    editReminderInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                window.showNotification("SUCCESS", res.message);
                                onRenderCallback();
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification("ERROR", error.response.data.message);
                        })
                        .finally(() => setLoading(false));
                } else {
                    addNewReminderInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                window.showNotification("SUCCESS", res.message);
                                onRenderCallback();
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification("ERROR", error.response.data.message);
                        })
                        .finally(() => setLoading(false));
                }
            } catch (error) {
                window.showNotification("ERROR", error.response.data.message);
                onClose();
            }
        } else {
            simpleValidator.current.showMessages();
            setForceUpdate(true);
        }
    };

    const handleDelete = () => {
        NewConfirmAlert({
            onSubmit: () =>
                deleteReminderInvoice({id: reminderInfo.id, type})
                    .then((res) => {
                        res = res.data;
                        if (res.success) {
                            onRenderCallback();
                            window.showNotification("SUCCESS", res.message);
                        } else {
                            window.showNotification(
                                "ERROR",
                                res.message || "Error occurred while deleting data"
                            );
                        }
                    })
                    .catch((error) => {
                        window.showNotification(
                            "Error occurred while deleting data",
                            "error"
                        );
                    }),
            title: "Are You Sure?",
            description: "Do you want to delete this information?",
            cancelText: "Cancel",
            submitText: "Confirm",
            width: "480px",
        });
    };

    const from = type === TYPE.EMAIL ? systemDefaultInfo?.email : systemDefaultInfo?.phone;
    return (
        <Box style={{maxWidth: 700}}>
            <Box style={{display: "flex", flexDirection: "column", gap: '20px'}}>
                <Box>
                    <InputLabel required>Name Your Reminder</InputLabel>
                    <BasicInput
                    fullWidth
                    type="text"
                        variant={"outlined"}
                        name="name"
                        placeholder="Type your reminder name"
                        value={formData.name}
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                    />
                    <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                        "Name",
                        formData.name,
                        "required"
                    )}</FormHelperText>
                </Box>

                <Box>
                    <InputLabel required style={{marginBottom:'12px'}}>Remind</InputLabel>
                    <Box className={Styles.remindStack}>
                        <BasicSelect
                        options={ADDRESS_TYPE_LIST}
                        mapping={{label:'label',value:'value'}}
                        fullWidth
                        size={"small"}
                        variant={"outlined"}
                        value={formData.addressType}
                        onChange={(event) => {
                            handleChangeInput("addressType", event.target.value);
                        }}
                        />
                        <BasicInput
                        type="text"
                        variant={"outlined"}
                        placeholder="1"
                        name="numberOfDays"
                        value={formData.numberOfDays}
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                        />
                        <Typography variant={"caption"}>Days</Typography>
                        <BasicSelect
                        options={REMIND_TYPE_LIST}
                        mapping={{label:'label',value:'value'}}
                        fullWidth
                        variant={"outlined"}
                        value={formData.remindType}
                        onChange={(event) => {
                            handleChangeInput("remindType", event.target.value);
                        }}
                        />
                        <Typography variant={"caption"} style={{minWidth: 'max-content'}}>Due Date*</Typography>
                    </Box>
                    <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                        "Remind",
                        formData.addressType,
                        "required"
                    )}</FormHelperText>
                </Box>

                <Box>
                    <InputLabel required>From</InputLabel>
                    <BasicInput
                    fullWidth
                    variant={"outlined"}
                    type={type === TYPE.EMAIL ? "email" : "tel"}
                    placeholder={
                        (type === TYPE.EMAIL ? "From email " : "From number ") + "Should not be empty"
                    }
                    name="from"
                    OutlinedInputProps={{
                        maxLength: 100,
                    }}
                    value={from}
                    disabled
                    onChange={(e) => {
                        handleChangeInput(e.target.name, e.target.value);
                    }}
                    />
                </Box>

                {type === TYPE.EMAIL && (
                    <Box>
                        <InputLabel required>Subject</InputLabel>
                        <Box className={Styles.inputAndPersonalized}>
                            <BasicInput
                            fullWidth
                            type="text"
                            variant={"outlined"}
                            placeholder="Type subject"
                            name="subject"
                            inputRef={subjectRef}
                            value={formData.subject}
                            onChange={(e) => {
                                handleChangeInput(e.target.name, e.target.value);
                            }}
                            />
                            <PersonalizedTag
                                onClick={(personalizedTag) =>
                                    insertPersonalizedTag(
                                        personalizedTag,
                                        subjectRef,
                                        (value) => handleChangeInput("subject", value)
                                    )
                                }
                            />
                        </Box>

                        <FormHelperText className={Styles.helperText}>
                            {simpleValidator.current.message(
                                "Subject",
                                formData.subject,
                                "required"
                            )}
                        </FormHelperText>
                    </Box>
                )}

                <Box>
                    <Box className={Styles.labelAndPersonalized}>
                        <InputLabel required> {type === TYPE.EMAIL ? "Email" : "SMS"} Body</InputLabel>
                        <PersonalizedTag
                            isSMS={type === TYPE.SMS}
                            onClick={(personalizedTag) =>
                                insertPersonalizedTag(personalizedTag, bodyRef, (value) =>
                                    handleChangeInput("body", value)
                                )
                            }
                        />
                    </Box>

                    {type === TYPE.EMAIL ? (
                        <>
                            <DefaultEditor
                                name={"body"}
                                value={formData.body}
                                onChange={(value) => handleChangeInput("body", value)}
                                height={'200'}
                                forwardRef={bodyRef}
                            />
                            <FormHelperText
                                className={Styles.helperText}>{simpleValidator.current.message("body", formData.body, "required")}</FormHelperText>
                        </>
                    ) : (
                        <>
                            <BasicInput
                            fullWidth
                            multiline
                            variant={"outlined"}
                            minRows={6}
                            maxRows={6}
                            name="body"
                            value={formData.body}
                            onChange={(e) => handleChangeInput("body", e.target.value)}
                            inputRef={bodyRef}
                            OutlinedInputProps={{
                                maxLength: 250,
                            }}
                            />
                            <FormHelperText
                                className={Styles.helperText}>{simpleValidator.current.message("SMS body", formData.body, "required|max:250")}</FormHelperText>
                        </>
                    )}
                </Box>

                <Box className={Styles.modalFooterActions}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                name="status"
                                checked={formData.status}
                                onChange={(e) =>
                                    handleChangeInput(e.target.name, e.target.checked)
                                }
                            />
                        }
                        label="Enable This Reminder"
                    />

                    <Box className={Styles.buttonStack}>
                        {isEdit && (
                            <Button
                                variant="contained"
                                endIcon={<DeleteIcon/>}
                                size="small"
                                onClick={handleDelete}
                            >
                                Delete this reminder
                            </Button>
                        )}

                        <Button size="small" onClick={onClose}>
                            Cancel
                        </Button>

                        <Button
                            color={"primary"}
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                            disabled={loading}
                            className={Styles.saveButton}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddOrEditReminder;
