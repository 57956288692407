import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      alignItems: "center",
      gap: 20,

      "& .headerText": {
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "#525252",
          margin: 0,
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,

      "& .backButton": {
        width: 110,
      }
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/custom_fields_icon.png`}
          alt=""
          height={70}
        />

        <div className="headerText">
          <h5 className="title">Custom Fields</h5>
          <p className="description">
          Custom fields are a user-defined fields that can be added to a contact record to store additional data
          </p>
        </div>
      </div>

      <div className="headerRight">
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          className="backButton"
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Header;