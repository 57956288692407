import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableHead, TableRow,  } from '@material-ui/core';


const ConnectedGoogleAccountSkeleton = () => {
    const rows = Array.from(new Array(8));

    return (
        <Table  aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell align="center"><Skeleton width="900px" /></TableCell>
                    <TableCell align="center"><Skeleton width="300px" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((_, index) => (
                    <TableRow key={index}>
                        <TableCell align="center"><Skeleton width="900px" /></TableCell>
                        <TableCell align="center"><Skeleton width="300px" /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default ConnectedGoogleAccountSkeleton;
