import { Box, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const AddGoalSkeleton = () => {
  return (
    <div>
        <Grid container>
       <Grid item xs={6} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
         
        </Box>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
          
        </Box>
      </Grid>
        </Grid> 
        <Grid container>
       <Grid item xs={5} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
        </Box>
      </Grid>
      <Grid item xs={3} style={{ marginBottom: '12px',marginLeft:'12px',marginRight:'12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
        </Box>
      </Grid>
      <Grid item xs={3} style={{ marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
        </Box>
      </Grid>
        </Grid> 
        <Grid container>
       <Grid item xs={6} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
        </Box>
      </Grid>
      <Grid item xs={5} style={{ marginBottom: '12px',marginLeft:'12px',marginRight:'12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={40} />
        </Box>
      </Grid>
        </Grid> 
    </div>
  );
};

export default AddGoalSkeleton;
