import { Editor } from "@tinymce/tinymce-react";
import React from 'react';

const apiKeys = () => {
    return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
}

const DefaultEditor = ({forwardRef, value, onChange, height="400px", name="", placeholder="Enter a message here!"}) => {
    return (
        <Editor
            ref={forwardRef}
            apiKey={apiKeys()}
            // apiKey="bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr"
            value={value}
            cloudChannel="5-dev"
            textareaName={name}
            init={{
                height: height,
                browser_spellcheck: true,
                menubar: false,
                placeholder: placeholder,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                convert_urls: false,
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent link | ' +
                    'removeformat | help',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                forced_root_block: false
            }}
            onEditorChange={(value) => onChange(value)}
        />
    );
};

export default DefaultEditor;