import React, {useContext, useEffect, useState} from "react";
import "./calllogs-style.css";
import FilterCallLog from "./FilterCallLog";
import {callOutcomeListForCallLogDropdown, getCallLog, getUserAndVirtualNumberArray} from "../../api/reportApi";
import {GlobalTable} from "../globals/GlobalTable";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import {
    CallMade,
    CloudDownloadOutlined,
    Phone,
    PhoneCallback,
    PhoneForwarded,
    PhoneMissed
} from "@material-ui/icons";
import Pagination from "./Pagination";
import {CallLogTableSkeleton} from "./CallLogTableSkeleton";
import {HistoryProvider} from "../../App";

const callStatus = (message) => {
    switch (message){
        case "Missed Call":
            return <Tooltip title={"Missed Call"} placement={"top"} arrow>
                <PhoneMissed fontSize="small" style={{color:"#f00"}} />
            </Tooltip>;
        case "Forward Call":
            return <Tooltip title={"Forward Call"} placement={"top"} arrow>
                <PhoneForwarded fontSize="small" style={{color:"#d5d507"}}/>
            </Tooltip>;
        case "Outgoing Call":
            return <Tooltip title={"Outgoing Call"} placement={"top"} arrow>
                <CallMade fontSize="small" style={{color:"#27ae60"}}/>
            </Tooltip>;
        case "Incoming Call":
            return <Tooltip title={"Incoming Call"} placement={"top"} arrow>
                <PhoneCallback fontSize="small" style={{color:"#215adc"}}/>
            </Tooltip>;
        default:
            return <Tooltip title={"Phone Call"} placement={"top"} arrow>
                <Phone style={{color:"#215adc"}}/>
            </Tooltip>;
    }
};

const CallLogs = () => {
    const [filterQuery, setFilterQuery] = useState({});
    const [loadingData, setLoadingData] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const perPage = 20;
    const [list, setList] = useState([]);
    const [callOutcomeList, setCallOutcomeList] = useState([{ outcome: "All Outcome" }]);
    const [callOutcomeArray, setCallOutcomeArray] = useState(null);
    const [virtualNumberArray, setVirtualNumberArray] = useState(null);
    const [userArray, setUserArray] = useState(null);
    const [subUserList, setSubUserList] = useState([ { full_name: "All Users" }]);
    const history = useContext(HistoryProvider);

    useEffect(()=>{
        callOutcomeListForCallLogDropdown()
        .then(response => {
          if (response && response.data && response.data.data) {
            let datas= {};
            let tempData = response.data.data;
            for(let i=0; i< tempData.length; i++){
                datas['outcome_'+tempData[i].id] = tempData[i].outcome;
            }
            setCallOutcomeArray(datas);
            setCallOutcomeList([{ outcome: "All Outcome" }, ...tempData]);
          }else{
            setCallOutcomeArray({})
          }
        }).catch((error)=>{
            setCallOutcomeArray({})
      console.log(error)
    });
    },[]);
    
    useEffect(()=>{
        getUserAndVirtualNumberArray()
        .then(response => {
            setUserArray(response.data.userInfo ?? {} )
            setVirtualNumberArray(response.data.virtualNumberList ?? {})
            if(response.data.users){
                setSubUserList([...subUserList,...response.data.users])
            }
        }).catch((error)=>{
            setVirtualNumberArray({})
      console.log(error)
    });
    },[]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        getCallLogs({ perPage, currentPage });
    },[]);// eslint-disable-line react-hooks/exhaustive-deps 


    const getCallLogs = (body) => {
        setCurrentPage(body.currentPage);
        setLoadingData(true);
        getCallLog(body)
            .then((res)=>{
                if (res.data && res.data.status === "success"){
                    setTotalRecords(res.data.count);
                    if (res.data.data && Array.isArray(res.data.data)) {
                        setList(res.data.data);

                        let pages = Math.round(res.data.count / perPage);
                        if (pages < 1){
                            setTotalPages(1);
                        }else {
                            setTotalPages(pages);
                        }
                    }
                    setLoadingData(false);
                }
            }).catch((err)=>{
            console.log(err);
        });
    };

    const downloadFile = (url) => {
        const filename = url.substring(url.lastIndexOf("/") + 1).split("?")[0];
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function() {
            let a = document.createElement("a");
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = filename;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a = null;
        };
        xhr.open('GET', url);
        xhr.send();
    }

    const columns = [
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Lead Info</span>,
            selector: row => {

                if(!row.contact){
                    return null;
                }
                let name;
                if(row.contact.first_name && row.contact.first_name.trim() !== ''){
                    name = row.contact.first_name+' ';
                }
                if(row.contact.last_name && row.contact.last_name.trim() !== ''){
                    name = name + row.contact.last_name;
                }
                 
                const id = row.contact.id;
                const number =row.contact.number ?? null;

                return <Link to={`/contacts/${id}`} target="_blank">
                    <div style={{padding : "10px 5px" }}>
                        {name &&
                        <div className="call_log_display_name">
                            <span className="call_log_display_icon"><svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.75 2.842c0 1.567 1.234 2.842 2.75 2.842S8.25 4.41 8.25 2.842C8.25 1.275 7.016 0 5.5 0S2.75 1.275 2.75 2.842ZM10.389 12H11v-.632c0-2.437-1.92-4.42-4.278-4.42H4.278C1.918 6.947 0 8.93 0 11.367V12h10.389Z" fill="#546376"/></svg></span>
                            {name}
                        </div>
                        }
                        {number &&
                            <div className="log_contact_number">
                                <span className="call_log_display_icon"><svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9.439 7.136-1.48-.17a1.159 1.159 0 0 0-.955.333L5.932 8.37a8.764 8.764 0 0 1-3.839-3.84l1.078-1.077c.25-.25.373-.6.332-.955L3.334 1.03A1.166 1.166 0 0 0 2.174 0H1.168C.51 0-.039.548.002 1.206a9.898 9.898 0 0 0 9.256 9.256c.658.04 1.206-.507 1.206-1.165V8.289a1.155 1.155 0 0 0-1.025-1.153Z" fill="#546376"/></svg></span>
                                {'+'+number}
                                </div>
                        }
                        {!name && !number &&
                            <div className="log_contact_email_email">
                                <span className="call_log_display_icon"><svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.583.416A2.083 2.083 0 0 0 .5 2.499v.21l6.25 3.365L13 2.71V2.5A2.083 2.083 0 0 0 10.917.415H2.583Z" fill="#546376"/><path d="M13 3.892 6.997 7.124a.52.52 0 0 1-.494 0L.5 3.892v4.857a2.083 2.083 0 0 0 2.083 2.083h8.334A2.083 2.083 0 0 0 13 8.75V3.892Z" fill="#546376"/></svg></span>
                                {row.contact.email ?? ''}
                            </div>
                        }
                    </div>
                    </Link>;
            },
            sortable: false,
            minWidth:'145px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}></span>,
            selector: row => {
                return callStatus(row.message);
            },
            sortable: false,
            width:'60px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Assigned To</span>,
            selector: row => {
                return row.user_id ? userArray['user_'+row.user_id] ? userArray['user_'+row.user_id] : "" : '';
            },
            sortable: false,
            minWidth:'40px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px',cursor: "pointer"}}>Recording</span>,
            selector: row => {
                return row.message_url ?  <div><audio style={{width:"180px"}} className={` audioPlayer`} controls>
                <source src={row['message_url']} type="audio/mp3"/>
                    Your browser does not support the audio element.
              </audio></div> : "";

            },
            sortable: false,
            minWidth:'230px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Virtual Number</span>,
            selector: row => {
                return row.virtual_number_id? virtualNumberArray ? virtualNumberArray['virtual_'+row.virtual_number_id] ? virtualNumberArray['virtual_'+row.virtual_number_id] : "" : '' : '';

            },
            sortable: false,
            minWidth:'200px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Call Time</span>,
            selector: row => {
                return row.created_at ? window.globalTimezoneConversionToDifferentTimezone(row.created_at, '', '', 'from_now') : "";
            },
            sortable: false,
            minWidth:'25px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Call Outcome</span>,
            selector: (row) => {
                return row.call_outcome_id? callOutcomeArray ? callOutcomeArray['outcome_'+row.call_outcome_id] ? callOutcomeArray['outcome_'+row.call_outcome_id] : "" : '' : '';
            },
            sortable: false,
            minWidth:'25px'
        },
        {
            name: <span style={{fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Action</span>,
            selector: row => {
                return row.message_url ? <IconButton onClick={()=>downloadFile(row.message_url)}>
                    <CloudDownloadOutlined/>
                </IconButton> : "" ;
            },
            sortable: false,
            minWidth:'25px'
        },
        {
            name: <span style={{ fontWeight: 'normal', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Call Duration</span>,
            selector: row => {
                try {
                    if(row.callDuration ===null){
                        return "N/A"
                    }
                    const callDurationInSeconds = row.callDuration ?? 0;
                    const hour = parseInt(callDurationInSeconds / 3600);
                    const extrasec = callDurationInSeconds % 3600;
                    const minutes = Math.floor(extrasec / 60);
                    const seconds = extrasec % 60;
                    return `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                } catch (error) {
                    console.error('An error occurred while processing call duration:', error);
                    return ' '; 
                }
            },
            sortable: false,
            minWidth: '25px'
        }
        
    ];


    return (
        <div>
            <div className="call_log__wrapper">
                <div className="header__box">
                    <div className="call_logs__list__title">
                        <h5>Call Logs</h5>
                    </div>
                    <div className="call_log__filter__search__area">
                        <div className="call_log__list__filter__area">
                            <p className="lead-count-in-contact-list justify-content-between"></p>
                            <Button
                                className="list__settings_btn"
                                variant="contained"
                                color="primary"
                                startIcon={<i className="material-icons">summarize</i>}
                                onClick={()=>{
                                    history.push({
                                        pathname: "/user/telephony-service/call-logs-details" ,
                                        state : {
                                            subUserList : subUserList ,
                                            callOutcomeArray: callOutcomeArray,
                                            callOutcomeList: callOutcomeList
                                        }
                                    })
                                }}
                            >
                                User Wise Report
                            </Button>
                            <FilterCallLog
                                callOutcomeList={callOutcomeList}
                                subUserList={subUserList}
                                fetchUserReportData={(data)=>{
                                    getCallLogs({ currentPage: currentPage, perPage, ...data });
                                    setFilterQuery({...data});
                                }}
                            />
                        </div>
                    </div>
                </div>
                {callOutcomeArray && virtualNumberArray && userArray &&
                <div className="log_global_parent_div">
                <GlobalTable
                    table_unique_id={'call_records'}
                    columns={columns}
                    data={list}

                    keyField={'id'}
                    noDataComponent={(
                        <span style={{padding: '50px', color: 'red'}}>No Call Logs Found!</span>
                    )}
                    progressPending={loadingData}
                    defaultSortField="title"
                    selectableRows={false}
                    pagination={false}
                    subHeader={false}
                    noHeader={true}
                    noHeaderData={{
                        noContextMenu: false,
                        contextActions: [],
                        actions: []
                    }}
                    progressComponent={<CallLogTableSkeleton/>}
                />
                </div>
                }
                <Pagination
                    paginationData={{ totalRecords, totalPages, currentPage, perPage, filterQuery }}
                    getCallLogs={getCallLogs}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </div>
    );
};

export default CallLogs;

