import { useState } from "react";

import Styles from "./style.module.css";
import {
  AddIcon,
  CloseIcon,
} from "../../../../customField/custom-field-v2/helpers/IconList";
import BasicSelect from "../../../../custom/BasicSelect";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../../../products/common/productSelector";
import {
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
  PRIVACY_LEVEL,
  PRIVACY_OPTION_CUSTOM_FIELD,
  PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT,
} from "../../../../../constants/CoreConstants";
import SelectWithIcon from "./SelecteWithIcon";
import BasicInput from "../../../../custom/BasicInput";
import {
  addUserMergeField,
  handleSelectedUserMergeFieldFolder,
  updateUserMergeField,
} from "../../../../../actions/CustomFieldInsuranceAction";

const AddOrEditMergeField = ({
  title = "Add New merged Field",
  onClose = () => {},
  edit = null,
}) => {
  const {
    userMergeFieldFolderList,
    userMergeFieldParams,
    isAddingUserMergeField,
    isUpdatingUserMergeField,
  } = useSelector(customFieldInsuranceState);
  const folderObj = {};
  userMergeFieldFolderList.forEach((folder) => {
    folderObj[folder.id] = folder.title;
  });

  const canUserManageGlobalItem = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,
    PERMISSION_LEVELS.WRITE
  );

  const [states, setStates] = useState({
    field_name: edit ? edit.title : "",
    field_value: edit ? edit.field_value : "",
    group_id: edit
      ? edit.user_personalized_field_group_id
      : userMergeFieldParams.group_id || userMergeFieldFolderList[0]?.id,
    privacy_level: !canUserManageGlobalItem
      ? PRIVACY_LEVEL.PRIVATE
      : PRIVACY_LEVEL.GLOBAL,
  });
  const [errors, setErrors] = useState({
    field_name: "",
    field_value: "",
  });
  const isGroupIdChanged = states.group_id !== edit?.user_personalized_field_group_id;
  const groupId = isGroupIdChanged ?  states.group_id : userMergeFieldParams.group_id;
  const isFieldUpdated = 
    states.field_name !== edit?.title ||
    states.field_value !== edit?.field_value ||
    isGroupIdChanged;

  const dispatch = useDispatch();

  const handleFieldName = (e) => {
    setErrors({ ...errors, field_name: "" });
    setStates({ ...states, field_name: e.target.value });
  };
  const handleFieldValue = (e) => {
    setErrors({ ...errors, field_value: "" });
    setStates({ ...states, field_value: e.target.value });
  };
  const handleGroupId = (id) => {
    setStates({ ...states, group_id: id });
  };
  const handlePrivacyLevel = (e) => {
    setStates({ ...states, privacy_level: e.target.value });
  };

  const validationForm = () => {
    const errors = {};
    const fieldName = states.field_name?.trim();

    if (!fieldName) {
      errors.field_name = "merged field name is required.";
    } else if (fieldName.length > 180) {
      errors.field_title =
        "merged field name must not exceed 180 characters.";
    }

    if (!states.field_value?.trim()) {
      errors.field_value = "merged field value is required.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };
const getGroupNameById = (id) => folderObj[id] || " ";

  const handleSubmit = () => {
    if (
      !validationForm() ||
      (isAddingUserMergeField ||
      isUpdatingUserMergeField ||
      !isFieldUpdated)
    ) {
      return;
    }

    if (edit) {
      const payload = {
        id: edit.id,
        title: states.field_name,
        field_value: states.field_value,
        group_id: states.group_id,
        group_name: getGroupNameById(states.group_id)
      };
      dispatch(
        updateUserMergeField({
          ...payload,
          callback: () => {
            onClose();
            dispatch(handleSelectedUserMergeFieldFolder(groupId));
          },
        })
      );
    } else {
      const payload = {
        title: states.field_name,
        field_value: states.field_value,
        group_id: states.group_id,
        privacy_level: states.privacy_level,
        group_name: getGroupNameById(states.group_id)
      };
      dispatch(
        addUserMergeField({
          ...payload,
          callback: () => {
            onClose();
            dispatch(handleSelectedUserMergeFieldFolder(states.group_id));
          },
        })
      );
    }
  };
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <div style={{display:"flex",alignItems:"center",gap:5}}>
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/contacts/1.0.0/custom_fields_icon.png`}
            alt="merge fields"
            height={50}
          />
          <span className={Styles.title}>{title}</span>
        </div>
        <span className={Styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Merged Field Name <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <BasicInput
              onChange={handleFieldName}
              value={states.field_name}
              placeholder="Enter merged Field Name"
              fullWidth
            />
          </div>
          {errors.field_name && (
            <div className={Styles.error}>{errors.field_name}</div>
          )}
        </div>

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Merged value <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <BasicInput
              onChange={handleFieldValue}
              value={states.field_value}
              placeholder="Enter merged Field Name"
              fullWidth
            />
          </div>
          {errors.field_value && (
            <div className={Styles.error}>{errors.field_value}</div>
          )}
        </div>

        {!edit && (
          <div className={Styles.inputWrp}>
            <span className={Styles.lebelText}>Field Type</span>
            <div className={Styles.inputFieldWrp}>
              <BasicSelect
                options={
                  canUserManageGlobalItem
                    ? PRIVACY_OPTION_CUSTOM_FIELD
                    : PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT
                }
                mapping={{ label: "label", value: "value" }}
                defaultText="Select Field Type"
                onChange={handlePrivacyLevel}
                value={states.privacy_level}
              />
            </div>
          </div>
        )}

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Folder Group</span>
          <div className={Styles.inputFieldWrp}>
            <SelectWithIcon
              options={userMergeFieldFolderList}
              mapping={{ label: "title", value: "id" }}
              defaultText="Select Folder Group"
              onChange={(e) => handleGroupId(e.target.value)}
              value={states.group_id}
            />
          </div>
        </div>
      </div>

      <div className={Styles.footerWrp}>
        <div className={Styles.buttonWrp}>
          <div
            className={
              isAddingUserMergeField || isUpdatingUserMergeField || !isFieldUpdated
                ? Styles.disabledButton
                : Styles.button
            }
            onClick={handleSubmit}
          >
            <span className={Styles.submitIcon}>
              <AddIcon />{" "}
            </span>
            <span className={Styles.submitText}>
              {edit
                ? isUpdatingUserMergeField
                  ? "Updating Field"
                  : "Update Field"
                : isAddingUserMergeField
                ? "Adding Field..."
                : "Add Field"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddOrEditMergeField;
