import { Button, makeStyles } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useState } from "react";
import AddNewUserModal from "./AddNewUserModal";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      alignItems: "center",
      gap: 20,

      "& .headerText": {
        "& .title": {
          fontSize: 22,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 16,
          color: "grey",
          margin: 0,
        },
      },
    },

    "& .addNewUserButton": {
      backgroundColor: "#3f51b5",
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },
  },
}));

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/manage_users_icon.png"
          alt=""
          height={50}
        />

        <div className="headerText">
          <h5 className="title">Manage Users</h5>
          <p className="description">
            From this screen you can add and remove users from your team and
            adjust their permission settings.
          </p>
        </div>
      </div>

      <div className="headerRight">
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          disableElevation
          className="addNewUserButton"
          onClick={() => setIsModalOpen(true)}
        >
          Add New User
        </Button>
      </div>

      {isModalOpen && (
        <AddNewUserModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Header;
