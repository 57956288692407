import {useEffect, useRef, useState} from "react";
import {PROFILE_TAB} from "../../../constants/CoreConstants";
import TextInput from "../../globals/TextInput";
import SimpleReactValidator from "simple-react-validator";
import ImageUploadInput from "../../globals/ImageUploadInput";
import ImageShow from "../../globals/ImageShow";
import {connect} from "react-redux";
import {updateCompanyInfo} from "../../../actions/profileAction";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { Typography } from "@material-ui/core";
import Info from "@material-ui/icons/Info";
import './company-info.css'

const CompanyInfo = (props) => {
    let userInitialValue = {
        id: null,
        company_name:null,
        url:null,
        logo:null,
        minimized_logo : null
    };

    const [user, setUser] = useState(userInitialValue);
    const [uploaded_file,setUploadedFile] = useState(null);
    const [uploaded_minimized_file,setMinimizedUploadedFile] = useState(null);
    const [, forceUpdate] = useState();
    const [saving,setSaving] = useState(false);

    const validator = useRef(new SimpleReactValidator({
        className: 'text-danger',
        autoForceUpdate: {forceUpdate: forceUpdate},
        messages: {
            url: 'Please provide a valid url'
        },
    }));

    const callback = (name,value) => {
        // const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        loadData();
    }, [props.user]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = () => {
        //make request
        if(props.user) {
            setUser(prevState => ({
                ...prevState,
                id : props.user.id,
                company_name : props.user.company_name,
                url : props.user.url,
                logo : props.user.logo,
                minimized_logo : props.user.minimized_logo
            }));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(saving){
            return
        }
        setSaving(true)
        const formValid = validator.current.allValid();
        if (formValid) {
            //make request
            let formData = new FormData();
            formData.append("company_name", user.company_name !== null ? user.company_name : '');
            formData.append("url", user.url !== null ? user.url : '');
            formData.append("logo", user.logo !== null ? user.logo : '');
            formData.append("minimized_logo", user.minimized_logo !== null && user.minimized_logo !== undefined  ? user.minimized_logo : '');
            formData.append("columns[]", 'id');
            formData.append("columns[]", 'company_name');
            formData.append("columns[]", 'url');
            formData.append("columns[]", 'logo');
            if(uploaded_file) {
                formData.append("qqfile", uploaded_file[0]);
            }
            if(uploaded_minimized_file) {
                formData.append("minimizedLogo", uploaded_minimized_file[0]);
            }
            props.updateCompanyInfo({"form_data":formData},(res) => {
                if(res.success) {
                  window.showNotification("SUCCESS", res.message)
                } else {
                  window.showNotification("ERROR", res.message)
                }
                setSaving(false)
            });
        }else{
            let messages = validator.current.getErrorMessages();
            if(messages.url) {
                window.showNotification("ERROR",messages.url);
            }
            validator.current.showMessages();
            forceUpdate(true);
            setSaving(false)
        }
    };

    return (
        <div id="company__info" className={"profile_right_item"+(parseInt(props.active_tab) === PROFILE_TAB.COMPANY_INFO ? " active" : " d-none")}>
            <form method={'post'} onSubmit={(event) => handleSubmit(event)}>
                <div className="accent--bg--text--color p-4">
                    <h6 className="m-0">Company Info</h6>
                </div>
                <div className="company__info__container" >
                    <div className="company__info__row">
                        <p className="company__info__row__title">Company name</p>
                        <div className="company__info__row__right">
                            <TextInput
                                fieldName    = {'company_name'}
                                callBack     = {callback}
                                value        = {user.company_name}
                                fieldType    = {'text'}
                                placeHolder  = "Company name here"
                                extraClassName = ""
                            />
                            {/*{validator.current.message('company_name', user.company_name, 'required|min:2|max:120')}*/}
                        </div>
                    </div>
                    <div className="company__info__row">
                        <div className="">

                            <p className="company__info__row__title d-flex">Website Address
                                <BootstrapTooltip arrow placement="top-start" title={
                                    <>
                                        <Typography color="inherit">Examples : </Typography>
                                        <p>https://example.com</p>
                                        <p>https://www.example.com</p>
                                        <p>https://www.example.com/webhook</p>
                                    </>
                                }>
                                <Info fontSize="small" className="ml-2"/>
                                </BootstrapTooltip>
                            </p>


                        </div>
                        <div className="company__info__row__right">

                                <TextInput
                                    fieldName    = {'url'}
                                    callBack     = {callback}
                                    value        = {user.url}
                                    fieldType    = {'text'}
                                    placeHolder  = "Enter website address"
                                    extraClassName = ""
                                />

                            {validator.current.message('url', user.url, 'min:2|max:120|url')}
                        </div>
                    </div>

                    <div className="company__info__row">
                     <p className="company__info__row__title">Full Logo</p>
                        <div className="company__info__row__right company__info__row__right__img ">
                            <ImageShow
                                imageUrl = {user.logo ?? "https://i.ibb.co/MnR9FqX/image.png"}
                                isServerImage = {false}
                                imageAlt = {'Logo'}
                                extraClassName={'responsive-img mh250'}
                            />
                        </div>
                    </div>
                    <div className="company__info__row">
                     <p className="company__info__row__title">New Full Logo</p>
                        <div className="company__info__row__right">
                            <p className="img__file__condition">Recommend image size 250 x 100px</p>
                            <ImageUploadInput
                                callBack     = {setUploadedFile}
                                filesLimit   = {1}
                                maxFileSize = {512000}
                            />
                            <p className="img__file__condition">Maximum image size 500kb</p>
                        </div>
                    </div>

                    <div className="company__info__row">
                     <p className="company__info__row__title">Minimized Logo</p>
                        <div className="company__info__row__right mt-3 minimized__img__for__company">
                            <ImageShow
                                imageUrl = {user.minimized_logo ?? "https://i.ibb.co/MnR9FqX/image.png"}
                                isServerImage = {false}
                                imageAlt = {'Minimized Logo'}
                                extraClassName={'responsive-img mh250'}
                            />
                        </div>
                    </div>
                    <div className="company__info__row">
                        <p className="company__info__row__title">New Minimized Logo</p>
                        <div className="company__info__row__right">
                            <p className="img__file__condition">Recommend image size 250 x 100px</p>
                            <ImageUploadInput
                                callBack     = {setMinimizedUploadedFile}
                                filesLimit   = {1}
                                maxFileSize = {512000}
                            />
                            <p className="img__file__condition">Maximum image size 500kb</p>
                        </div>
                    </div>
                </div>

                <div className="profile_right_footer py-3">
                    <button disabled={saving} type={'submit'} className="profile_right_footer__btn__wr p-2 d-inline-flex justify-content-center align-items-center accent--bg--text--color">
                        <span
                            className="mr-2 d-inline-flex justify-content-center align-items-center">
                            <i className="material-icons">
                                save
                            </i>
                        </span>
                        {saving ? "Saving" :"Save "} Company Info
                </button>
                </div>
            </form>
        </div>
    )
}
const MapStateToProps = (state) => {
    return {
        user: state.profileReducer.user,
        response: state.profileReducer.response
    };
}
const MapDispatchToProps = (dispatch) => {
    return {
        updateCompanyInfo: (params,callback) => dispatch(updateCompanyInfo(params,callback))
    }
}
const CompanyInfoTab = connect(MapStateToProps,MapDispatchToProps)(CompanyInfo);
export default CompanyInfoTab;
