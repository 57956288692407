import React from "react";
import {
    Box,
    IconButton,
    InputBase,
    MenuItem,
    Select,
    withStyles,
    Typography
} from "@material-ui/core";
import {
    NavigateBefore as NavigateBeforeIcon,
    NavigateNext as NavigateNextIcon
} from "@material-ui/icons";
import Styles from "./MapOrderDetails.module.css";

const StyledInputBase = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3) + " !important",
        },
        width: (props) => (props.width || "100px") + " !important",
        "& svg": {
            color: "#8b85cf"
        },
        color: "var(--text-secondary-color) !important"
    },
    input: {
        borderRadius: "4px !important",
        position: "relative !important",
        border: "1px solid #8b85cf !important",
        fontSize: "16px !important",
        padding: "10px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        height: (props) => (props.height || "inherit") + " !important",
        textAlign: (props) => (props.textAlign || "inherit") + " !important",
        marginBottom: "0px !important",
        "&:focus": {
            borderRadius: "4px !important",
            boxShadow: "none !important"
        }
    }
}))(InputBase);

const StyledIconButton = withStyles((theme)=>({
    root: {
        width: "40px",
        height: "40px",
        display: "inline-flex",
        border: "1px solid rgba(98,88,255,.5)",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        padding: "8px",
        margin: "0!important",
        "& svg": {
            width: "25px",
            height: "25px",
            color: "#8b85cf"
        },
        "& .MuiTouchRipple-ripple .MuiTouchRipple-child": {
            borderRadius: "4px",
            backgroundColor: "#8b85cf"
        },
    }
}))(IconButton);

const StyledDivider = withStyles((theme) => ({
    root: {
        position: "relative",
        margin: "0 10px",
        color: "rgba(148,157,178,.24)",
    },
    divider: {
        position: "absolute",
        content: "",
        top: "-14px",
        left: 0,
        width: "1px",
        background: "#6258ff80",
        height: "30px",
        transform: "rotate(25deg)",
    },
}))(({ classes }) => (
    <Box className={classes.root}>
        <div className={classes.divider}></div>
    </Box>
));

const Pagination = ({ perPage, setPerPage, currentPage, setCurrentPage, totalPages }) => {

    let previousPage = null;
    let nextPage = null;

    if (currentPage > 1) {
        previousPage = currentPage - 1;
    }

    if (currentPage !== totalPages) {
        if (typeof currentPage === "string" && currentPage !== ""){
            let parsedCurrentPage = parseInt(currentPage);
            setCurrentPage(parsedCurrentPage);
        }
        nextPage = currentPage + 1;
    }

    const onChangePage = (page) => {
        page = page.replace(/[a-z|A-Z|!@#$%^&* ]/g,"");
        if (page !== "" && page > 0) {
            if (totalPages >= page) {
                setCurrentPage(parseInt(page));
            }
        }else{
            setCurrentPage("");
        }
    }

    const handleStepChange = (event, page) => {
        event.preventDefault();
        if (page != null && page > 0) {
            setCurrentPage(page);
        }
    }

    return(
        <Box className={Styles.tablePagination}>
            <Box className={Styles.tablePaginationInner}>
                <Box className={Styles.perPageSelect}>
                    <Typography>Rows per page:</Typography>
                    <Select
                        labelId="perPage-label"
                        id="perPage"
                        name="perPage"
                        displayEmpty
                        value={perPage}
                        onChange={(e)=>{
                            setPerPage(e.target.value);
                        }}
                        input={<StyledInputBase/>}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        <MenuItem className="dropdownhelper-menuitem-class" value={20}>20</MenuItem>
                        <MenuItem className="dropdownhelper-menuitem-class" value={50}>50</MenuItem>
                        <MenuItem className="dropdownhelper-menuitem-class" value={100}>100</MenuItem>
                        <MenuItem className="dropdownhelper-menuitem-class" value={300}>300</MenuItem>
                    </Select>
                </Box>
                <Box className={Styles.pageSelect}>
                    <StyledIconButton disabled={previousPage === null} onClick={(event) => handleStepChange(event, previousPage)}>
                        <NavigateBeforeIcon/>
                    </StyledIconButton>
                    <StyledInputBase value={currentPage} width={"50px"} textAlign={"center"} onChange={(event)=> {onChangePage(event.target.value)}}/>
                    <StyledDivider/>
                    <StyledInputBase value={totalPages} width={"50px"} textAlign={"center"} readOnly/>
                    <StyledIconButton disabled={nextPage === null} onClick={(event) => handleStepChange(event, nextPage)}>
                        <NavigateNextIcon/>
                    </StyledIconButton>
                </Box>
            </Box>
        </Box>
    );
};

export default Pagination;