
const GeoIcons = {
  arrowRight: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.506 3.436a.75.75 0 0 1 1.058.07l7 8a.75.75 0 0 1 0 .988l-7 8a.75.75 0 1 1-1.128-.988l5.911-6.756H4a.75.75 0 0 1 0-1.5h14.347l-5.911-6.756a.75.75 0 0 1 .07-1.058Z"
        fill="#030D45"
      />
    </svg>
  ),
  fdfsd: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width="500"
      height="500"
      style={{
        width: "100%",
        height: "100%",
        transform: "translate3d(0,0,0)"
      }}
    >
      <defs>
        <clipPath id="a">
          <path d="M0 0h500v500H0z" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <g style={{display:"block"}}>
          <path
            fill="#FFF"
            d="M250 375.149c69.864 0 126.5-56.636 126.5-126.5s-56.636-126.5-126.5-126.5-126.5 56.636-126.5 126.5 56.636 126.5 126.5 126.5z"
          />
          <path
            d="M250 375.149c69.864 0 126.5-56.636 126.5-126.5s-56.636-126.5-126.5-126.5-126.5 56.636-126.5 126.5 56.636 126.5 126.5 126.5z"
            fill="none"
          />
          <path
            fill="#FFF9EF"
            d="m218.461 289.732-75.457 4.154c-2.92.16-5.058-2.711-4.066-5.463l41.391-114.86a3.578 3.578 0 0 1 2.802-2.318l50.121-7.98-14.79 126.467z"
          />
          <path
            d="m218.461 289.732-75.457 4.154c-2.92.16-5.058-2.711-4.066-5.463l41.391-114.86a3.578 3.578 0 0 1 2.802-2.318l50.121-7.98-14.79 126.467z"
            fill="none"
          />
          <path
            fill="#FFF9EF"
            d="m280.436 311.071-61.975-21.339 14.791-126.466 47.184 20.859v126.946z"
          />
          <path
            d="m280.436 311.071-61.975-21.339 14.791-126.466 47.184 20.859v126.946z"
            fill="none"
          />
          <path
            fill="#FFF9EF"
            d="m352.228 296.52-71.792 14.551V184.125l41.638-12.135a3.252 3.252 0 0 1 4.07 2.35l28.799 117.903a3.512 3.512 0 0 1-2.715 4.277z"
          />
          <path
            d="m352.228 296.52-71.792 14.551V184.125l41.638-12.135a3.252 3.252 0 0 1 4.07 2.35l28.799 117.903a3.512 3.512 0 0 1-2.715 4.277z"
            fill="none"
          />
          <path
            fill="#39B54A"
            d="m218.461 289.732-75.26 4.143c-3.013.165-5.22-2.798-4.196-5.637l25.994-72.13 59.328 23.476-5.866 50.148z"
          />
          <path
            d="m218.461 289.732-75.26 4.143c-3.013.165-5.22-2.798-4.196-5.637l25.994-72.13 59.328 23.476-5.866 50.148z"
            fill="none"
          />
          <path
            fill="#8BCA84"
            d="m251.442 301.088-32.98-11.356 5.864-50.149 45.124 21.393-18.008 40.112z"
          />
          <path
            d="m251.442 301.088-32.98-11.356 5.864-50.149 45.124 21.393-18.008 40.112z"
            fill="none"
          />
          <path
            fill="#EF3834"
            d="m202.208 226.016-35.886-13.583 13.942-38.687a3.903 3.903 0 0 1 3.058-2.532l49.931-7.948-5.293 45.25-25.752 17.5z"
          />
          <path
            d="m202.208 226.016-35.886-13.583 13.942-38.687a3.903 3.903 0 0 1 3.058-2.532l49.931-7.948-5.293 45.25-25.752 17.5z"
            fill="none"
          />
          <path
            fill="#FF8C89"
            d="m271.633 180.233-43.673 28.284 5.292-45.251 39.369 17.403-.988-.436z"
          />
          <path
            d="m271.633 180.233-43.673 28.284 5.292-45.251 39.369 17.403-.988-.436z"
            fill="none"
          />
          <path
            fill="#3395D2"
            d="m224.869 234.946-17.35-6.837 19.737-13.568-2.387 20.405z"
          />
          <path
            d="m224.869 234.946-17.35-6.837 19.737-13.568-2.387 20.405z"
            fill="none"
          />
          <path
            fill="#83B3E0"
            d="m271.382 256.82-46.513-21.874 2.387-20.405 23.211-15.155 29.882 18.568.087 19.251-9.054 19.615z"
          />
          <path
            d="m271.382 256.82-46.513-21.874 2.387-20.405 23.211-15.155 29.882 18.568.087 19.251-9.054 19.615z"
            fill="none"
          />
          <path
            fill="#FF8C89"
            d="m280.436 311.071-24.518-8.442 24.518-55.054v63.496z"
          />
          <path
            d="m280.436 311.071-24.518-8.442 24.518-55.054v63.496z"
            fill="none"
          />
          <path
            fill="#EF3834"
            d="m304.543 306.186-24.107 4.885v-63.496l11.325-22.424 27.194 14.432-14.412 66.603z"
          />
          <path
            d="m304.543 306.186-24.107 4.885v-63.496l11.325-22.424 27.194 14.432-14.412 66.603z"
            fill="none"
          />
          <path
            fill="#3395D2"
            d="m352.228 296.52-41.333 8.378 21.368-105.513 22.681 92.857a3.514 3.514 0 0 1-2.716 4.278z"
          />
          <path
            d="m352.228 296.52-41.333 8.378 21.368-105.513 22.681 92.857a3.514 3.514 0 0 1-2.716 4.278z"
            fill="none"
          />
          <path
            fill="#39B54A"
            d="m329.894 189.691-10.195 45.247-39.263-22.568v-28.245l41.534-12.106a3.357 3.357 0 0 1 4.2 2.426l3.724 15.245z"
          />
          <path
            d="m329.894 189.691-10.195 45.247-39.263-22.568v-28.245l41.534-12.106a3.357 3.357 0 0 1 4.2 2.426l3.724 15.245z"
            fill="none"
          />
          <path
            fill="#3395D2"
            d="m280.436 237.205-.087-19.25 7.379 4.757-7.292 14.493z"
          />
          <path
            d="m280.436 237.205-.087-19.25 7.379 4.757-7.292 14.493z"
            fill="none"
          />
          <path
            fill="#8BCA84"
            d="m280.436 212.37-25.183-16.015 21.503-13.858 3.68 1.628v28.244z"
          />
          <path
            d="m280.436 212.37-25.183-16.015 21.503-13.858 3.68 1.628v28.244z"
            fill="none"
          />
        </g>
        <g style={{display:"block"}}>
          <path
            fill="#FCB100"
            d="M186.895 139.915c-11.018 0-19.95-8.932-19.95-19.95 0-11.02 8.932-19.951 19.95-19.951 11.02 0 19.951 8.932 19.951 19.95 0 11.018-8.932 19.951-19.95 19.951zm0-65.266c-24.98 0-45.233 20.229-45.233 45.182 0 37.928 27.525 52.595 40.68 88.166.533 1.441 1.911 4.652 4.553 4.652 2.643 0 4.02-3.211 4.554-4.652 13.154-35.57 40.68-50.238 40.68-88.166 0-24.952-20.252-45.182-45.234-45.182z"
          />
          <path
            d="M186.895 139.915c-11.018 0-19.95-8.932-19.95-19.95 0-11.02 8.932-19.951 19.95-19.951 11.02 0 19.951 8.932 19.951 19.95 0 11.018-8.932 19.951-19.95 19.951zm0-65.266c-24.98 0-45.233 20.229-45.233 45.182 0 37.928 27.525 52.595 40.68 88.166.533 1.441 1.911 4.652 4.553 4.652 2.643 0 4.02-3.211 4.554-4.652 13.154-35.57 40.68-50.238 40.68-88.166 0-24.952-20.252-45.182-45.234-45.182z"
            fill="none"
          />
          <path
            fill="#FDE092"
            d="M186.895 139.915c-11.018 0-19.95-8.932-19.95-19.95 0-11.02 8.932-19.951 19.95-19.951 11.02 0 19.951 8.932 19.951 19.95 0 11.02-8.932 19.951-19.95 19.951zm0-49.408c-16.268 0-29.456 13.188-29.456 29.456 0 16.269 13.188 29.457 29.456 29.457 16.269 0 29.457-13.188 29.457-29.457 0-16.268-13.188-29.456-29.457-29.456z"
          />
          <path
            d="M186.895 139.915c-11.018 0-19.95-8.932-19.95-19.95 0-11.02 8.932-19.951 19.95-19.951 11.02 0 19.951 8.932 19.951 19.95 0 11.02-8.932 19.951-19.95 19.951zm0-49.408c-16.268 0-29.456 13.188-29.456 29.456 0 16.269 13.188 29.457 29.456 29.457 16.269 0 29.457-13.188 29.457-29.457 0-16.268-13.188-29.456-29.457-29.456z"
            fill="none"
          />
        </g>
      </g>
    </svg>
  ),
};

export default GeoIcons;