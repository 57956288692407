import { Box, InputAdornment, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { InsuranceCompanyIcon } from "../../../ten-dlc/helpers/Icon";
import AddOrEditNewCompanyModal from "./AddOrEditNewCompanyModal";
import { CustomButton } from "./contentBody/CustomButton";
import { BasicInput, insuranceCompanyStyle } from "./InsuranceCompanyStyle";
import SelectedCompaniesActions from "./SelectedCompaniesActions";

const InsuranceCompanyHeader = ({
  setInsuranceCompanyList,
  search,
  setSearch,
  folderList,
  handleFolderList,
  selectedFolder,
  setPage,
  selectedCompanies,
  setSelectedCompanies,
  insuranceCompanyList,
  setFilterTrigger,
  filterTrigger
}) => {

  const [openAddModal, setOpenAddModal] = useState(false);
  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
     setPage(0);
  };

  const { productBodyTitle,subTitle } = insuranceCompanyStyle();

  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        {" "}

        <div style={{display:"flex",justifyContent:"center"}}>
         <span style={{marginRight:"5px"}}><InsuranceCompanyIcon /></span>
          <div>
          <Typography style={{height:'unset !important'}} className={productBodyTitle}>
            Insurance companies
          </Typography>
          <Typography style={{height:'unset !important'}} className={subTitle}>
           Select the insurance companies that you represent in your agency.
          </Typography>
          </div>
        </div>
      
        <Box sx={{ display: "flex", alignItems: "center", gap: 8 }}>
       
          {(selectedCompanies && selectedCompanies[0]) ? (
            <SelectedCompaniesActions 
            setInsuranceCompanyList={setInsuranceCompanyList}
            folderList={folderList}
            handleFolderList={handleFolderList}
            selectedCompanies={selectedCompanies}
            insuranceCompanyList={insuranceCompanyList}
            setSelectedCompanies={setSelectedCompanies}
            setFilterTrigger={setFilterTrigger}
            filterTrigger={filterTrigger}
            />
          ) :
            (<BasicInput
            value={search}
            placeholder="Search for existing company"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#656565" }} />
              </InputAdornment>
            }
            style={{ width: "300px" }}
            onChange={handleSearch}
          />)}
          <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
            <CustomButton
              variant="contained"
              onClick={() => setOpenAddModal(true)}
              startIcon={<AddIcon />}
              disableElevation
            >
              New Company
            </CustomButton>
          </Box>
        </Box>
      </Box>

      {openAddModal && (
        <AddOrEditNewCompanyModal
          open={openAddModal}
          onClose={handleCloseModal}
          setInsuranceCompanyList={setInsuranceCompanyList}
          folderList={folderList}
          handleFolderList={handleFolderList}
          selectedFolder={selectedFolder}
        />
      )}
    </div>
  );
};

export default InsuranceCompanyHeader;
