import { Box, Paper, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useReducer } from "react";
import "./CardMainPage.css";
import {
  getCompanyLogoApi,
  getMapIntegrationPackageApi,
  getTeamUserListApi,
  getUserSelectedPackageApi,
  hasMapIntegrationV2,
  hasMapIntetrationApi,
  mapYourSalesForceLoginApi,
  updateUserExternalServiceAccessApi
} from "../../api/profileApi";
import { CreditCardModal } from "./CreditCardModal";
import SalePackage from './SalePackage'
import PurchasedPackageInformation from "./PurchasedPackageInformation";
import TeamUserList from "./TeamUserList";
import moment from "moment";
import Utils from "../../helpers/Utils";
import { fetchUser } from "../../api/profileApi";
import { Link } from "react-router-dom";
import { HistoryProvider } from "../../App";
import InfoIcon from '@material-ui/icons/Info';
import CardEnv from "./stripeCard/CardEnv";
import CardPayment from "./CardPayment";

const CardMainPage = () => {

  const history = useContext(HistoryProvider);

  const reducerFunction = (state, data) => {

    switch (data.type) {

      case 'updateDataList':
        return { ...state, ...data.payload }
      default:
        return data.payload;
    }
  }

  const [packageInformation, dispatchPackageInformation] = useReducer(reducerFunction, {
    'hasPackage': false, 'loading': true, 'packageInfo': null,
    additionalCharge: 0.0, 'submitModal': '', 'packageList': [], 'packageLoading': true, 'totalCharge': '', 'transactionId': '', 'message': '',
    'selectedPackage': null, teamMember: [], loadingTeamUserList: false, updateAccess: false, currentUsages: 0, paymentPage: false,
    'userNumber': '', 'cardNumber': '', 'cvc': '', 'focus': '', 'cardMaxLength': '16', 'expiry': '',
    'cardmaxLength': '16', 'submitting': false, 'focusUpdate': '', videoLink: null, changePackage: false, useExisitngCard: true, mapUserCardInfo: null,
    mapUserInfo: false, companyLogo: null, makeDefaultCard: true, isPaused: false, packageSource: 'USER'
  })

  //            _
  //     .__(.)< (MEOW)
  //       \___)
  //


  /// in-case of fire add comment to this section ## current-section-start

  useEffect(() => {
    if (history.location.state) {
      getAllPackagesV2(history.location.state)
    } else {
      getAllPackagesV2()
    }
  }, [])

  /// in-case of fire add comment to this section ## current-section-end


  /// in-case of fire remove comment from this section ## backup-section-start

  // useEffect(() => {
  //   if (history.location.state) {
  //     if (Utils.getAccountData("agencyId") === 8){
  //       getAllPackagesV2(history.location.state)
  //     }else {
  //       getAllPackages(history.location.state)
  //     }
  //   } else {
  //     if (Utils.getAccountData("agencyId") === 8){
  //       getAllPackagesV2()
  //     }else {
  //       getAllPackages()
  //     }
  //   }
  // }, [])

  /// in-case of fire remove comment from this section ## backup-section-end

  useEffect(() => {

    if (packageInformation.userNumber === '') {
      try {
        var number = Utils.getAccountData('phone_number');
        if (number !== undefined && number !== null && number !== "") {
          updateReducerInformation(number, 'userNumber')
        }
        else {
          fetchUser().then(res => {
            let response = res.data
            updateReducerInformation(response.phone, 'userNumber')

          })
        }
      } catch (error) {

      }
    }

  }, [])

  useEffect(() => {
    if (!packageInformation.companyLogo) {

      getCompanyLogoApi().then((res) => {

        let response = res.data;

        if (response.status === 'success') {
          updateReducerInformation(response.companyLogo, 'companyLogo')

        }
      })
    }

  }, []);

  const getAllPackagesV2 = (prevPageData = null) => {
    hasMapIntegrationV2({ serviceType: 1 })
        .then((res)=>{
          if (res){
            let response = res;
            if (response.has_data) {
              dispatchPackageInformation({
                'type': 'updateDataList', 'payload': {
                  hasPackage: true, 'loading': false,
                  'additionalCharge': 0, 'currentUsages': response.current_usages ?? 0
                }
              })

              if (response.forceLogin) {
                response.data[0]['forceLogin'] = response.forceLogin
              } else {
                response.data[0]['forceLogin'] = false
              }
              updateReducerInformations({ isPaused: response.data[0].status === 2, packageSource: response.data[0].package_source });
              /* get team user list */
              getTeamUserList()
              getMapIntegrationPackageList(true, response.data[0])
              // getSelectedPackageDetails(response.data[0].package_id)
            } else {
              if (prevPageData) {
                dispatchPackageInformation({
                  'type': 'updateDataList', 'payload': {
                    packageList: prevPageData.packageList,
                    selectedPackage: prevPageData.selectedPackage, videoLink: prevPageData.videoLink, 'additionalCharge': prevPageData.selectedPackage.additional_charge,
                    'paymentPage': true, 'loading': false, 'packageLoading': false
                  }
                });
              } else {
                updateReducerInformation(false, 'loading')

                getMapIntegrationPackageList();

              }
              // getAgencySettingForAdditionalCharge()
            }
          }
        }).catch((error) => {
      console.log(error)
    });
  }

  const getAllPackages = (prevPageData = null) => {

    hasMapIntetrationApi({ service_type: 1 }).then(res => {
      let response = res.data;
      if (response.has_data) {
        dispatchPackageInformation({
          'type': 'updateDataList', 'payload': {
            hasPackage: true, 'loading': false,
            'additionalCharge': 0, 'currentUsages': response.current_usages ?? 0
          }
        })

        if (response.forceLogin) {
          response.data[0]['forceLogin'] = response.forceLogin
        } else {
          response.data[0]['forceLogin'] = false

        }
        /* get team user list */
        getTeamUserList()
        getMapIntegrationPackageList(true, response.data[0])
        // getSelectedPackageDetails(response.data[0].package_id)
      } else {
        if (prevPageData) {
          dispatchPackageInformation({
            'type': 'updateDataList', 'payload': {
              packageList: prevPageData.packageList,
              selectedPackage: prevPageData.selectedPackage, videoLink: prevPageData.videoLink, 'additionalCharge': prevPageData.selectedPackage.additional_charge,
              'paymentPage': true, 'loading': false, 'packageLoading': false
            }
          })
        } else {
          updateReducerInformation(false, 'loading')

          getMapIntegrationPackageList();

        }
        // getAgencySettingForAdditionalCharge()
      }

    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const getMapIntegrationPackageList = (packageExists = false, packageInfo = null) => {
    getMapIntegrationPackageApi().then(res => {
      let response = res.data
      if (response.status === 'success') {
        if (response.html.length > 0) {
          if (packageExists) {
            let storedPackage = false

            if (packageInfo && packageInfo['package_id'] === 18) {
              packageInfo['subscription_fee'] = 0;
              console.log(packageInfo);
              dispatchPackageInformation({
                'type': 'updateDataList', 'payload': {
                  packageList: response.html,
                  selectedPackage: response.html[0], videoLink: response.videoLink, 'additionalCharge': response.html[0].additional_charge,
                  packageInfo: packageInfo
                }
              })
              storedPackage = true
            } else {
              for (let i = 0; i < response.html.length; i++) {
                if (response.html[i].id === packageInfo.agency_external_approved_package_id) {
                  packageInfo['subscription_fee'] = response.html[i].subscription_fee
                  dispatchPackageInformation({
                    'type': 'updateDataList', 'payload': {
                      packageList: response.html,
                      selectedPackage: response.html[0], videoLink: response.videoLink, 'additionalCharge': response.html[0].additional_charge,
                      packageInfo: packageInfo
                    }
                  })
                  storedPackage = true
                  break;
                }
              }

              if (!storedPackage) {
                dispatchPackageInformation({
                  'type': 'updateDataList', 'payload': {
                    packageList: response.html,
                    selectedPackage: response.html[0], videoLink: response.videoLink, 'additionalCharge': response.html[0].additional_charge,
                    packageInfo: packageInfo
                  }
                })
              }
            }

          } else {
            dispatchPackageInformation({
              'type': 'updateDataList', 'payload': {
                packageList: response.html,
                selectedPackage: response.html[0], videoLink: response.videoLink, 'additionalCharge': response.html[0].additional_charge
              }
            })
            // updateReducerInformation(response.html[0].additional_charge,'additionalCharge')
          }

        }
        if (packageExists && packageInfo && packageInfo['package_id'] === 18) {
          packageInfo['subscription_fee'] = 0;
          dispatchPackageInformation({
            'type': 'updateDataList', 'payload': {
              packageList: response.html,
              selectedPackage: null, videoLink: response.videoLink, 'additionalCharge': null,
              packageInfo: packageInfo
            }
          })
        }
        // if(response.html.html.length > 0){
        //   dispatchPackageInformation({'type': 'updateDataList','payload': {packageList: response.html.html,
        //     selectedPackage : response.html.html[0]}})
        // }
      }
      updateReducerInformation(false, 'packageLoading')
    }).catch(error => {
      updateReducerInformations({ 'loading': false, 'packageLoading': false })

    })
  }

  const getTeamUserList = () => {
    updateReducerInformation(true, 'loadingTeamUserList')
    getTeamUserListApi({ service_type: 1 }).then(res => {
      let response = res.data;
      if (response.all_users !== undefined) {
        let all_users = response.all_users;
        let active_users = [];
        if (response.active_users !== undefined) {
          active_users = response.active_users
        }
        all_users.forEach((item, index) => {
          active_users.forEach((active_item, active_index) => {
            if (active_item.user_id === item.id) {
              all_users[index].has_access = true;
              all_users[index].external_access_id = active_item.id;
              all_users[index].next_update_date = active_item.created_at;
              all_users[index].change_date = active_item.change_date;
            }
          })
        })
        updateReducerInformation(all_users, 'teamMember')
      }
      updateReducerInformation(false, 'loadingTeamUserList')
    }).catch(error => {
      updateReducerInformation(false, 'loadingTeamUserList')
    })
  }

  const getSelectedPackageDetails = (package_id) => {
    updateReducerInformation(true, 'packageLoading')
    getUserSelectedPackageApi({ package_id: package_id }).then(res => {
      let response = res.data;
      if (response.status === "success") {
        updateReducerInformation(response.html, 'selectedPackage')
        updateReducerInformation(response.current_usages, 'currentUsages')
      }
      updateReducerInformation(false, 'packageLoading')
    }).catch(error => {
      updateReducerInformation(false, 'packageLoading')
    })
  }

  const updateReducerInformation = (value, key) => {

    dispatchPackageInformation({ 'type': 'updateDataList', 'payload': { [key]: value } })

  }
  const updateReducerInformations = (newData) => {

    dispatchPackageInformation({ 'type': 'updateDataList', 'payload': newData })

  }

  const onChangePackage = (value) => {

    dispatchPackageInformation({
      'type': 'updateDataList',
      'payload': {
        'additionalCharge': value['additional_charge'],
        'selectedPackage': value
      }
    })
  }

  const handleUserAccess = (user, value, index) => {
    if (packageInformation.updateAccess) {
      return
    }
    updateReducerInformations({ 'updateAccess': true })

    if (!value) {
      let current_date = moment(new Date());
      let next_update_date = moment(user.change_date);
      let diff = current_date.diff(next_update_date) >= 0
      if (!diff) {
        let message = "You can not update this member access before " + moment(user.change_date).format('MM/DD/YYYY')
        window.showNotification('WARNING', message);
        updateReducerInformations({ 'updateAccess': false })
        return;
      }
    }
    // updateReducerInformation(true, 'updateAccess')
    let old_data = packageInformation.teamMember;
    old_data[index].has_access = value;
    updateReducerInformations({ 'teamMember': old_data })
    // updateReducerInformation(old_data, 'teamMember')
    updateUserExternalServiceAccessApi({
      service_type: 1,
      user_id: user.id,
      value: value
    }).then(res => {
      let response = res.data;
      if (response.status !== 'success') {
        if (response.status === 'exceed_limit') {
          let old_data = packageInformation.teamMember;
          old_data[index].has_access = !value;
          updateReducerInformation(old_data, 'teamMember')
          updateReducerInformation(false, 'updateAccess')
          window.showNotification('ERROR', "Team member access limit exceeds!");
          return;
        }
        let old_data = packageInformation.teamMember;
        old_data[index].has_access = !value;
        updateReducerInformation(old_data, 'teamMember')
        window.showNotification('ERROR', "Something went wrong!");
      } else {
        if (value) {
          let teams = packageInformation.teamMember;
          teams[index].change_date = packageInformation.packageInfo.renew_date
          updateReducerInformation(teams, 'teamMember')
        }
      }
      updateReducerInformation(false, 'updateAccess')
    }).catch(error => {
      let old_data = packageInformation.teamMember;
      old_data[index].has_access = !value;
      updateReducerInformation(old_data, 'teamMember')
      updateReducerInformation(false, 'updateAccess')
    })
  }

  const handleForceLoginToMapYourSales = (user_info) => {
    mapYourSalesForceLoginApi({ key: packageInformation.packageInfo.external_api_key }).then(res => {
      let response = res.data
      if (response.status === "success") {
        let base_url = process.env.REACT_APP_MAP_YOUR_SALES_BASE_URL,
          user_id = Utils.getAccountData('userId')
        // window.open(base_url+'outside/force/login/redirect/'+response.data+'/'+user_id, '_blank');
        Object.assign(document.createElement('a'), {
          target: '_blank',
          href: base_url + 'outside/force/login/redirect/' + response.data + '/' + user_id
        }).click();
      } else {
        window.showNotification('ERROR', "Can not login to Geofarming !");
      }
    }).catch(error => { })
  }

  if (!Utils.getAccountData('userIsOwner')) {
    return (
      <div className="new__map__integration__container">
        <h4> Please Contact your Team owner to purchase Map Package</h4>
      </div>
    )
  }

  return (
    <Box className="cardmainPage__wrapper">
      {!packageInformation.paymentPage &&
        <div>
          <h1 className="cardHeadingTitle alt">
            {!packageInformation.packageLoading && packageInformation.packageList.length !== 0 && !packageInformation.paymentPage &&
              'Select Your Plan Below'
            }
            <Link className="card__domain__more__info" to={{ pathname: "/geo-farming-user", state: packageInformation }} target={'_blank'} >
              <InfoIcon /> Click here For more info
            </Link>
          </h1>
        </div>
      }
      <Box sx={{ flexGrow: 1 }}>
        {!packageInformation.loading && (!packageInformation.hasPackage) &&
          <div>
            <Paper elevation={0}>
              {packageInformation.packageLoading &&
                <div className="empty__package___list">
                  Please wait...
                </div>
              }
              {(!packageInformation.packageLoading && packageInformation.packageList.length === 0) && !packageInformation.paymentPage &&
                <div className="empty__package___list">
                  No package available
                </div>
              }

              {(!packageInformation.packageLoading && packageInformation.packageList.length !== 0) && !packageInformation.paymentPage &&
                <Grid className="mainGrid" style={{ flexWrap: 'nowrap' }} container spacing={3}>
                  {packageInformation.packageList.map((item, index) => {
                    return (
                        <Grid item sm={4} md={4} lg={4} key={index} >
                          <SalePackage
                              item={item}
                              key={index}
                              packageInformation={packageInformation}
                              onChangePackage={onChangePackage}
                              updateReducerInformations={updateReducerInformations}
                          />
                        </Grid>
                    )
                  })
                  }
                </Grid>
              }
            </Paper>
          </div>
        }

        {/*in-case of fire add comment to this section ## current-section-start*/}

        {(!packageInformation.packageLoading && packageInformation.packageList.length !== 0) && packageInformation.paymentPage &&
            <CardEnv updateReducerInformations={updateReducerInformations} packageInformation={packageInformation} getTeamList={getTeamUserList} />
        }

        {/*in-case of fire add comment to this section ## current-section-end*/}



        {/*in-case of fire remove comment from this section ## backup-section-start*/}

        {/*{(!packageInformation.packageLoading && packageInformation.packageList.length !== 0) && packageInformation.paymentPage &&*/}
        {/*    (*/}
        {/*        Utils.getAccountData("agencyId") === 8 ?*/}
        {/*            <CardEnv updateReducerInformations={updateReducerInformations} packageInformation={packageInformation} getTeamList={getTeamUserList} /> :*/}
        {/*            <CardPayment updateReducerInformations={updateReducerInformations} packageInformation={packageInformation} getTeamList={getTeamUserList} />*/}
        {/*    )*/}
        {/*}*/}

        {/*in-case of fire remove comment from this section ## backup-section-end*/}

        {
          ((packageInformation.submitModal === 'success' || packageInformation.submitModal === 'exists') && packageInformation.selectedPackage) &&
          <CreditCardModal packageInformation={packageInformation} closeAction={() => updateReducerInformation('', 'submitModal')} />
        }

        {
          !packageInformation.packageLoading && packageInformation.hasPackage && !packageInformation.paymentPage && packageInformation.packageInfo &&
          <>
            <PurchasedPackageInformation packageInformation={packageInformation} handleForceLoginToMapYourSales={handleForceLoginToMapYourSales} updateReducerInformations={updateReducerInformations} onChangePackage={onChangePackage} />
            {!packageInformation.changePackage && packageInformation.teamMember.length > 0 &&
              <TeamUserList users={packageInformation.teamMember} isLoading={packageInformation.loadingTeamUserList} handleUserAccess={handleUserAccess} packageRecurringData={packageInformation.packageInfo?.renew_date} />
            }
            {packageInformation.teamMember.length === 0 && !packageInformation.changePackage &&
                <div className="map__access__team__user__list">
                  <p>No Team member found</p>
                </div>
            }
          </>
        }
      </Box >
    </Box >
  );
}

export default CardMainPage;