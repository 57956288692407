import { Box, Typography } from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {EMPTY_DATA_IMG} from "../../../constants/CoreConstants";

const useStyles = makeStyles(()=>({
    wrapper: {
        display: "flex",
        height: "calc(100vh - 250px)"
    },
    leftSide: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "60%",
    },
    rightSide: {
        width: "40%",
        display: "flex",
        alignItems: "center"
    }
}));

const EmptySalesVsGoal = () => {
  const classes = useStyles();

  return (
    <div  className={classes.wrapper}>
      <Box className={classes.leftSide}>
        <div>
          <Typography align="center" variant="h5">
            No Sales Vs Goal Report Available
          </Typography>
        </div>
      </Box>
      <Box className={classes.rightSide}>
        <img
          height={300}
          src={EMPTY_DATA_IMG}
          alt="Empty data"
        />
      </Box>
    </div>
  );
};

export default EmptySalesVsGoal;
