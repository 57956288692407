import React, { useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import SwitchC from "../customField/subComponents/Switch";
import BasicSelect from "../products/policyTypes/content/BasicSelect";
import { BasicInput } from "../products/productsStyle";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import PermissionDescriptionModal from "./PermissionDescriptionModal";
import CollaboratorsDescriptionModal from "./CollaboratorsDescriptionModal";

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    "& .MuiDialog-paper": {
      maxWidth: 1000,
      minWidth: 900,
    },
    "& .modalHeader": {
      display: "flex",
      //   justifyContent: "space-between",
      gap: 16,
      alignItems: "center",
      padding: theme.spacing(2),
      borderBottom: "1px solid #3c7ef31a",
      fontSize: 24,
      marginBottom: theme.spacing(2),

      "& .headerLeft": {
        display: "flex",
        alignItems: "center",
        gap: 20,

        "& .headerText": {
          "& .title": {
            fontSize: 22,
            fontWeight: "bold",
            margin: "0 0 8px 0",
            color: "var(--dark_blue)",
          },

          "& .description": {
            fontSize: 16,
            color: "grey",
            margin: 0,
          },
        },
      },
    },
    "& .modalFooter": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "15px",
      borderTop: "1px solid rgba(148, 157, 178, 0.24)",
      padding: "16px 24px",
      gap: "10px",
      "& .submitButton": {
        "&:hover, &:focus": {
          backgroundColor: "#3f51b5",
        },
      },
    },
    "& .error": {
      color: "var(--red)",
      fontSize: "14px",
    },
  },

  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "18px",
    marginBottom: theme.spacing(1),
  },
  sectionSubtitle: {
    color: "gray",
    fontSize: 14,
    // fontWeight: 600,
    lineHeight: "16px",
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: theme.spacing(0.25),
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",

    "& .flexWrapper": {
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
  },
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  },
}));

const PERMISSION_LEVELS = [
  { label: "Admin", value: "ADMIN" },
  { label: "Standard User", value: "STANDARD" },
  { label: "Limited User", value: "LIMITED" },
];

const AddNewUserModal = ({ open, onClose }) => {
  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    phone: "",
  });
  const [permissionLevel, setPermissionLevel] = useState("");
  const [permissionStatus, setPermissionStatus] = useState({
    manageBilling: true,
    manageUsers: true,
    manageGlobalItems: true,
    exportContacts: true,
    editLeadOwner: true,
    calender: true,
    scheduler: true,
  });
  const [collaborators, setCollaborators] = useState([]);

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    permissionLevel: "",
  });

  const [isPermissionDescription, setIsPermissionDescription] = useState(false);
  const [isCollaboratorDescription, setIsCollaboratorDescription] =
    useState(false);

  const classes = useStyles();

  const handleUserInfo = (e) => {
    setErrors({
      fullName: "",
      email: "",
      phone: "",
      permissionLevel: "",
    });
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handlePermissionLevel = (event) => {
    setErrors({
      ...errors,
      permissionLevel: "",
    });
    setPermissionLevel(event.target.value);
  };

  const handlePermissionStatus = (value, name) => {
    setPermissionStatus({
      ...permissionStatus,
      [name]: value,
    });
  };
  const handleCollaborators = (event) => {
    setCollaborators(event.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
    const phoneRegex = /^[0-9]{10}$/; // Phone number validation for 10-15 digits

    // Validate Full Name
    if (!userInfo.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    // Validate Email
    if (!userInfo.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(userInfo.email)) {
      newErrors.email = "Invalid email format.";
    }

    // Validate Phone
    if (!userInfo.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(userInfo.phone)) {
      newErrors.phone = "Invalid phone number. Use 10 digits.";
    }

    // Validate Permission Level
    if (!permissionLevel.trim()) {
      newErrors.permissionLevel = "Permission level is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      alert("Form submitted successfully!");
    }
  };
  return (
    <>
      <Dialog
        minWidth={500}
        open={open}
        onClose={onClose}
        className={classes.modalWrapper}
      >
        <div className="modalHeader">
          <div className="headerLeft">
            <img
              src="https://d24gujj67p1uc1.cloudfront.net/assets/images/releases/1.0.0/manage_users_icon.png"
              alt=""
              height={50}
            />

            <div className="headerText">
              <h5 className="title">New User</h5>
              <p className="description">
                Add a new user to your team and set their permission levels.
              </p>
            </div>
          </div>
        </div>

        <div className="modalBody">
          <div className={classes.contentWrapper}>
            <div className={classes.section}>
              <div>
                <Typography variant="h6" className={classes.sectionTitle}>
                  New User Info
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Full Name <span style={{ color: "var(--red)" }}>*</span>
                    </Typography>
                    <BasicInput
                      name="fullName"
                      value={userInfo.fullName}
                      onChange={handleUserInfo}
                      fullWidth
                      placeholder="Sally Doe"
                      required
                    />
                    {errors.fullName && (
                      <span className="error">{errors.fullName}</span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Email <span style={{ color: "var(--red)" }}>*</span>
                    </Typography>
                    <BasicInput
                      name="email"
                      type="email"
                      value={userInfo.email}
                      onChange={handleUserInfo}
                      fullWidth
                      placeholder="sallydoe@example.com"
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Phone <span style={{ color: "var(--red)" }}>*</span>
                    </Typography>
                    <BasicInput
                      name="phone"
                      value={userInfo.phone}
                      onChange={handleUserInfo}
                      fullWidth
                      placeholder="248-410-6153"
                    />
                    {errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Permission Level{" "}
                    <span style={{ color: "var(--red)" }}>*</span>
                  </Typography>
                  <Typography variant="h6" className={classes.sectionSubtitle}>
                    To learn More about the different permission levels{" "}
                    <span
                      style={{ cursor: "pointer", color: "#3f51b5" }}
                      onClick={() => setIsPermissionDescription(true)}
                    >
                      click here
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <BasicSelect
                    fullWidth
                    name="category"
                    defaultText="Select an options"
                    value={permissionLevel}
                    options={PERMISSION_LEVELS}
                    mapping={{ label: "label", value: "value" }}
                    onChange={handlePermissionLevel}
                  />

                  {errors.permissionLevel && (
                    <span className="error">{errors.permissionLevel}</span>
                  )}
                </Grid>
              </Grid>

              <div>
                <TableContainer component={"span"}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        {permissionLevel !== "LIMITED" ? (
                          <>
                            <TableCell
                              align="center"
                              className={classes.tableHeader}
                            >
                              <div className="flexWrapper">
                                <span>Manage Billing</span>
                                <BootstrapTooltip
                                  title="If toggled on, the Standard User will gain access to view and manage the billing associated with your account.
"
                                  arrow
                                  placement="top"
                                >
                                  <HelpIcon />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableHeader}
                            >
                              <div className="flexWrapper">
                                <span>Manage Users</span>

                                <BootstrapTooltip
                                  title="If toggled on, the Standard User will gain access to add/remove users and adjust their permission levels."
                                  arrow
                                  placement="top"
                                >
                                  <HelpIcon />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                            <TableCell
                              align={"center"}
                              className={classes.tableHeader}
                            >
                              <div className="flexWrapper">
                                <span>Manage Global Items</span>

                                <BootstrapTooltip
                                  title="If toggled on, the Standard User will gain access to manage Global Items (global campaigns, global tags, global triggers, global custom fields, global quick replies, etc)."
                                  arrow
                                  placement="top"
                                >
                                  <HelpIcon />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              align="center"
                              className={classes.tableHeader}
                            >
                              <div className="flexWrapper">
                                <span>
                                  Manage Another <br /> User's Calender
                                </span>
                                <BootstrapTooltip
                                  title="If toggled on, the Standard User will gain access to view and manage the billing associated with your account.
"
                                  arrow
                                  placement="top"
                                >
                                  <HelpIcon />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableHeader}
                            >
                              <div className="flexWrapper">
                                <span>
                                  View Another User's <br /> Appointment
                                  Scheduler
                                </span>

                                <BootstrapTooltip
                                  title="If toggled on, the Standard User will gain access to add/remove users and adjust their permission levels."
                                  arrow
                                  placement="top"
                                >
                                  <HelpIcon />
                                </BootstrapTooltip>
                              </div>
                            </TableCell>
                          </>
                        )}

                        <TableCell
                          align={"center"}
                          className={classes.tableHeader}
                        >
                          <div className="flexWrapper">
                            <span>Export Contacts</span>

                            <BootstrapTooltip
                              title="If toggled on, the Standard User will gain access to export contacts from the system."
                              arrow
                              placement="top"
                            >
                              <HelpIcon />
                            </BootstrapTooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          align={"center"}
                          className={classes.tableHeader}
                        >
                          <div className="flexWrapper">
                            <span>Edit Lead owner</span>

                            <BootstrapTooltip
                              title="If toggled on, the Standard User will gain access to edit the lead owner of any contact in the system. They will have the ability to reassign contacts to themselves or any other user on their team."
                              arrow
                              placement="top"
                            >
                              <HelpIcon />
                            </BootstrapTooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow className={classes.tableRowWrapper}>
                        {permissionLevel !== "LIMITED" ? (
                          <>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <SwitchC
                                status={permissionStatus.manageBilling}
                                brandColor={"#3f51b5"}
                                onChange={(event) =>
                                  handlePermissionStatus(event, "manageBilling")
                                }
                                onText="On"
                                offText="Off"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <SwitchC
                                status={permissionStatus.manageUsers}
                                brandColor={"#3f51b5"}
                                onChange={(event) =>
                                  handlePermissionStatus(event, "manageUsers")
                                }
                                onText="On"
                                offText="Off"
                              />
                            </TableCell>
                            <TableCell align="center">
                              <SwitchC
                                status={permissionStatus.manageGlobalItems}
                                brandColor={"#3f51b5"}
                                onChange={(event) =>
                                  handlePermissionStatus(
                                    event,
                                    "manageGlobalItems"
                                  )
                                }
                                onText="On"
                                offText="Off"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <SwitchC
                                status={permissionStatus.calender}
                                brandColor={"#3f51b5"}
                                onChange={(event) =>
                                  handlePermissionStatus(event, "calender")
                                }
                                onText="On"
                                offText="Off"
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              component="th"
                              scope="row"
                            >
                              <SwitchC
                                status={permissionStatus.scheduler}
                                brandColor={"#3f51b5"}
                                onChange={(event) =>
                                  handlePermissionStatus(event, "scheduler")
                                }
                                onText="On"
                                offText="Off"
                              />
                            </TableCell>
                          </>
                        )}

                        <TableCell align="center">
                          <SwitchC
                            status={permissionStatus.exportContacts}
                            brandColor={"#3f51b5"}
                            onChange={(event) =>
                              handlePermissionStatus(event, "exportContacts")
                            }
                            onText="On"
                            offText="Off"
                          />
                        </TableCell>

                        <TableCell align="center">
                          <SwitchC
                            status={permissionStatus.editLeadOwner}
                            brandColor={"#3f51b5"}
                            onChange={(event) =>
                              handlePermissionStatus(event, "editLeadOwner")
                            }
                            onText="On"
                            offText="Off"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    Collaborators
                  </Typography>
                  <Typography variant="h6" className={classes.sectionSubtitle}>
                    Select any standard users on your team that you'd like to
                    add as a collaborator{" "}
                    <span style={{ color: "#3f51b5" }}>(optional)</span>
                    <br />
                    If someone is added as a collaborator, they'll have access
                    and permission as the user.
                    <br />
                    <br />
                    To learn more about collaborators{" "}
                    <span
                      style={{ cursor: "pointer", color: "#3f51b5" }}
                      onClick={() => setIsCollaboratorDescription(true)}
                    >
                      click here
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <BasicSelect
                    fullWidth
                    multiple
                    defaultText="Select Collaborators"
                    value={collaborators}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <span style={{ color: "rgba(8, 40, 82, 0.4)" }}>
                            Select Collaborators
                          </span>
                        );
                      }

                      return selected.join(", ");
                    }}
                    options={[
                      { label: "Option 1", value: "Option 1" },
                      { label: "Option 2", value: "Option 2" },
                    ]}
                    mapping={{ label: "label", value: "value" }}
                    onChange={handleCollaborators}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className="modalFooter">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            size="small"
            disableElevation
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitButton"
            color="primary"
            size="small"
            disableElevation
            onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Dialog>

      <PermissionDescriptionModal
        open={isPermissionDescription}
        onClose={() => setIsPermissionDescription(false)}
      />

      <CollaboratorsDescriptionModal
        open={isCollaboratorDescription}
        onClose={() => setIsCollaboratorDescription(false)}
      />
    </>
  );
};

export default AddNewUserModal;
