import React from "react";
import { Cell, Label, Pie, PieChart, Text } from "recharts";
import { styled } from "@material-ui/core";
import Utils from "../../../../helpers/Utils";
import Title from "./Title";

const CustomChartWrapper = styled('div')(({theme}) => ({
  position: 'relative',

  "& :focus": {
    outline: "none !important",
  },

  "& .currentlyAt": {
    minWidth: 55,
    position: "absolute",
    textAlign: "center",
    padding: 8, 
  },
}));

const CustomChart = ({ maxValue, currentValue, remaining, startedValue }) => {

  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: 33.33, color: "#ff0055" },
    { name: "B", value: 33.33, color: "#ffc905" },
    { name: "C", value: 33.33, color: "#33f28b" },
  ];
  const cx = 150;
  const cy = 200;
  const iR = 100;
  const oR = 150;
  // const value = Math.min(100, (currentValue / maxValue) * 100);
  const calculateValue = () => {
    if (maxValue === 0 ) return 0;
    const valueToUse = Math.min(currentValue, maxValue);
    return (valueToUse / maxValue) * 100;
  };
  const value = calculateValue();
  const startLabelX = cx - oR + 20;
  const endLabelX = cx + oR - 20;
  // const middleLabelX = cx + oR - 150;
  const labelY = cy + oR - 120;

  const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    // const length = (iR + 2 * oR) / 3;
    const length = oR - 5;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 15;
    const x0 = cx;
    const y0 = cy;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle
        key="needle-circle"
        cx={x0}
        cy={y0}
        r={r}
        fill={color}
        stroke="none"
      />,
      <path
        key="needle-path"
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill={color}
      />,
    ];
  };

  const renderCustomLabel = ({ viewBox }, value) => {
    const { cx, cy } = viewBox;
    return (
      <>
     { (currentValue >= maxValue) ? (
      <>
      <image
          href={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/achived_goal_icon.png`}
          x={cx-40}
          y={cy-85} 
          width="85px"
          height="85px"
          style={{ objectFit: "cover" }}
        />
        <Text
          x={cx}
          y={cy +25}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: "14px", fill: "#3A414A",fontWeight: "bold" }}
        >
            You've reached your goal!
        </Text>
    </>
     ) : ( 
     <>
        <Text
          x={cx}
          y={cy - 40}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: "16px", fill: "#3A414A" }}
        >
          STILL NEEDED
        </Text>
        <Text
          x={cx}
          y={cy - 10}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: "24px", fontWeight: "bold", fill: "#282C33" }}
        >
          {Utils.formatCurrency(Utils.toZero(value), "$")}
        </Text>
      </>
    )}
      </>
    );
  };

  const formatCurrency = (value) => {
    return "$" + Utils.abbreviateNumber(value) || "0.00";
  };

  const currentAtPosition = () => {
    const angle = (value / 100) * 180; // Map value to an angle from 0 to 180
    const radius = 200; // Distance from center, adjust as necessary

    // Convert polar coordinates to Cartesian
    let x = cx + radius * Math.cos((180 - angle) * RADIAN);
    let y = cy - radius * Math.sin((180 - angle) * RADIAN);

    if (value === 0) {
      y -= 10;
    }
    if (value === 50) {
      x -= 20;
    }
    if (value > 50) {
      x -= 30;
    }
    if (value >= 100) {
      y -= 10;
    }

    return { left: x, top: y };
  };
  
  return (
    <CustomChartWrapper>
      <div className={`currentlyAt`} style={{ ...currentAtPosition() }}>
        <Title
          style={{
            fontSize: 18,
            lineHeight: "18px",
            color: "#282C33",
          }}
          text={formatCurrency(currentValue)}
        />
      </div>
      <svg
        width={333}
        height={285}
        style={{ position: "absolute", overflow: "visible" }}
      >
        <defs>
          <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow
              dx="0"
              dy="0"
              stdDeviation="4"
              floodColor="rgba(0, 0, 0, 0.35)"
            />
          </filter>
        </defs>
      </svg>
      <PieChart width={333} height={273}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          filter="url(#shadow)"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>

        {needle(value, data, cx, cy, iR, oR, "#333")}
        <Pie
          data={[{ name: "Still Needed", value: remaining || 1}]}
          dataKey="value"
          cx="47.5%"
          cy="73%"
          innerRadius={0}
          outerRadius={100}
          fill="#fff"
          isAnimationActive={false}
          isInteractive={false}
          // filter="url(#Shadow)"
          // className={classes.pieChart}
        >
          <Label
            content={(content) => renderCustomLabel(content, remaining)}
            position="center"
          />
        </Pie>

        <text
          x={startLabelX}
          y={labelY}
          textAnchor="middle"
          fill="#3A414A"
          fontSize={16}
        >
          {/* {goalStatFilter === GOAL_TYPE_SALES ? "$" : ""}  */}
          $0
        </text>
        <text
          x={endLabelX}
          y={labelY}
          textAnchor="middle"
          fill="#3A414A"
          fontSize={16}
        >
          {formatCurrency(Math.abs(maxValue))}
        </text>
        {/* <text
          x={middleLabelX}
          y={labelY}
          textAnchor="middle"
          fill="#3A414A"
          fontSize={16}
        >
          Still Needed
        </text>
        <text
          x={middleLabelX}
          y={labelY + 30}
          textAnchor="middle"
          fill="#282C33"
          fontSize={24}
        >
          {formatCurrency(Math.abs(remaining))}
        </text> */}
      </PieChart>
    </CustomChartWrapper>
  );
};

export default CustomChart;
