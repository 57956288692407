import { Button, Grid, styled, Typography } from "@material-ui/core";
import React, { useState } from "react";
import GoalChart from "./GoalChart";
import GoalList from "./GoalList";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px !important",
  fontSize: "14px !important",
  textTransform: "capitalize !important",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "#346fef !important",
  },
}));

const DailyGoalsStats = ({ data, renderTeamMember }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div
      style={{
        padding: "10px 12px 10px",
        backgroundColor: "#ffffff",
        border: "1px solid #E9EAEC",
        borderRadius: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          style={{
            marginBottom: "11px",
            marginLeft: "4px",
            width: "calc(65% - 20px)",
          }}
        >
          Hi {renderTeamMember() ? renderTeamMember().split(" ")[0] : ""},
          here's your agency's goal for today
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CustomButton
            size="small"
            variant={activeTab === 1 ? "contained" : "text"}
            color={activeTab === 1 ? "primary" : "inherit"}
            onClick={() => setActiveTab(1)}
            disableElevation
          >
            Sales
          </CustomButton>
        </div>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={6}>
          <GoalList goalData={data} />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <GoalChart chartData={data} />
        </Grid>
      </Grid>
    </div>
  );
};

export default DailyGoalsStats;
