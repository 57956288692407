import React from 'react';
import { styled } from '@material-ui/core';
import SalesGoalTable from './SalesGoalTable';

const SalesGoalBodyWrapper = styled('div')(({theme}) => ({
    height: '92%',
    padding: theme.spacing(2),
}));

const SalesGoalBody = () => {
    return (
        <SalesGoalBodyWrapper>
            <SalesGoalTable />
        </SalesGoalBodyWrapper>
    );
};

export default SalesGoalBody;