import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditRecycleEventModal from "./AddOrEditRecycleEventModal";
import { CustomButton } from "./RecycleEventBody/CustomButton";
import { LostReasonStyle } from "./RecycleEventStyle";
import { HelpOutlineOutlined } from "@material-ui/icons";

const RecycleEventHeader = () => {
  const { topbarHeaderTitle } = LostReasonStyle();
  const [openAddRecycleModal, setOpenAddRecycleModal] = useState(false);
  const handleCloseModal = () => {
    setOpenAddRecycleModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <Typography variant="h5" className={topbarHeaderTitle}>
          Recycle Events
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
          <Button
            size="small"
            variant="text"
            startIcon={<HelpOutlineOutlined />}
          >
            <i>How does this work?</i>
          </Button>

          <CustomButton
            variant="contained"
            onClick={() => setOpenAddRecycleModal(true)}
            startIcon={<AddIcon />}
            disableElevation
          >
            New Event
          </CustomButton>
        </Box>
      </Box>

      {openAddRecycleModal && (
        <AddOrEditRecycleEventModal
          open={openAddRecycleModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default RecycleEventHeader;
