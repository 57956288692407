import React, { useContext, useEffect, useState } from "react";
import { getMapIntegrationPackageApi } from "../../api/profileApi";
import { HistoryProvider } from "../../App";
import './geo-farming-user-noti.style.css';
import GeoIcons from './GeoIcons';
import StepLeft from "./StepLeft";
import StepRight from './StepRight';
import WhatIsGeoFraming from "./WhatIsGeoFraming";

const GeoFarmingUserNotiMain = () => {

    const history = useContext(HistoryProvider);

    const [selectedPackage, setSelectedPackage] = useState(null)
    const [packageList, setPackageList] = useState(null)
    const [videoLink, setVideoLink] = useState(null)

    const handleRoute = (routeLink) => {

        if(selectedPackage){
            history.push({ pathname: routeLink, state: { selectedPackage: selectedPackage, videoLink:videoLink, packageList : packageList } })

        }
    }
    useEffect(() => {
        getMapIntegrationPackageList()
    }, [])
    const getMapIntegrationPackageList = () => {
        getMapIntegrationPackageApi().then(res => {
            let response = res.data
            if (response.status === 'success') {
                if (response.html.length > 0) {
                    setPackageList(response.html)
                    setSelectedPackage(response.html[0])
                    setVideoLink(response.videoLink)
                }
                // if(response.html.html.length > 0){
                //   dispatchPackageInformation({'type': 'updateDataList','payload': {packageList: response.html.html, 
                //     selectedPackage : response.html.html[0]}})
                // }
            }
            // updateReducerInformation(false,'packageLoading')
        }).catch(error => {
            // updateReducerInformations({'loading': false, 'packageLoading': false})

        })
    }


    return (
        <div className="geo__farming__wrapper__update">
            <div className="geo__top__section__wr">
                <h1 className="geo__heading__h1">Geographic Farming</h1>
                <h4 className="geo__sub__heading">Pinpoint your ideal prospects anywhere in the United States</h4>
                <div className="geo__top__vidoe__wr">
                    {videoLink != null &&
                        <iframe width="420" height="315"
                            src={videoLink} title="video">
                        </iframe>
                        
                    }


                </div>
                {selectedPackage !== null &&
                    <a href="#!" className="geo__get__start__btn" onClick={(e) =>{ e.preventDefault(); handleRoute('map/integration')}}>
                        <span className="geo__get__start__top"><span>{GeoIcons.arrowRight}</span> <span>Get Started!</span></span>
                        <span className="geo__get__start__bottom">Unlock this feature for&nbsp;
                            {(parseFloat(selectedPackage.subscription_fee) + parseFloat(selectedPackage.additional_charge)).toFixed(2)}
                            /month</span>
                    </a>
                }
            </div>
            <WhatIsGeoFraming
                title="What Is Geographic Farming?"
                content1="
                Geographic farming is known by a few different names…geo farming, circle prospecting, neighbor marketing, farming, etc."
                content2="Geographic farming is a proven method of marketing your business to a specific geographic region to raise awareness and generate leads."
                content3="This strategy allows you to pinpoint your ideal clients so you can focus your marketing efforts and dollars."
            // img={whatLeftImg}

            />
            <div className="geo__framing__how__does__it__work">
                <h2>How Does It Work?</h2>
                {/* <div className="geo__farming__how__border"></div> */}
            </div>
            <div className="geo__farming__overly__section">
                <div className="what__geo__framing__overly"></div>
                <StepLeft
                    step="STEP 1:"
                    title="Find Your Ideal Prospects"
                    content="Use our simple mapping tool to target your ideal clients. Target a city, a neighborhood, or even specific homes in that neighborhood!"
                    img="https://salespype.com/wp-content/uploads/2022/04/geographic-farming.png"
                />
            </div>
            <div className="geo__farming__setp__2">
                <div className="geo__farming__setp__2__overly"></div>
                <StepRight
                    step="STEP 2:"
                    title="Access Real-Time Data"
                    content="Within seconds we’ll compile and display data on the households you selected. You’ll receive data points like:"
                    // content2="We’ll organize all your client conversations in 1 easy to manage inbox."
                    img="https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/data-image-mys.png"
                />
            </div>
            <div className="geo__farming__setp__3">
                <div className="geo__farming__setp__3__overly"></div>
                <StepLeft
                    step="STEP 3:"
                    title="Click Send!"
                    content="Now that you have the data you can reach out to your new leads manually or automate the entire process."
                    content2="We offer a variety of ways to reach out to your clients and prospects."
                    img="https://salespype.com/wp-content/uploads/2022/04/geo-farming-2.png"
                    social={true}
                />
            </div>

            {/* <div className="geo__framing__faq__wr">
                    <Faq/>
                </div> */}

            <div className="geo__question__wr">
                <div className="geo__question__inner">
                    <h2>Ready For Local Domination ?</h2>
                    <p>Click below to get started or reach or reach out to our team.</p>
                    <div className="question__step__btn__wr">
                        <a href="#!" className="geo__get__start__btn" onClick={(e) =>{ e.preventDefault(); handleRoute('map/integration')}}>
                            <span className="geo__get__start__top"><span>{GeoIcons.arrowRight}</span> <span>Get Started!</span></span>
                            {selectedPackage !== null &&

                                <span className="geo__get__start__bottom">Unlock this feature for&nbsp;
                                    {(parseFloat(selectedPackage.subscription_fee) + parseFloat(selectedPackage.additional_charge)).toFixed(2)}
                                    /month</span>
                            }
                        </a>
                    </div>

                </div>
            </div>



        </div>
    )
}


export default GeoFarmingUserNotiMain;