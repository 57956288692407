import {Box} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import TriggerPersonalizedAccordion from "../Accordion/TriggerPersonalizedAccordion";
import CustomPopOver from "../Popover/CustomPopOver";
import {StyledList} from "./PersonalizedTagStyles";

const PersonalizedTag = ({ onClick, isSMS = false }) => {
    const {data: personalizeTags = []} = useSelector(state => state.invoicePersonalizeTagReducer)

  const renderData = personalizeTags?.map(
    (eachGroup, eachGroupIndex) => (
      <TriggerPersonalizedAccordion
        key={eachGroupIndex}
        title={eachGroup.group_name}
        expanded={eachGroupIndex === 0}
      >

        <StyledList>
          {eachGroup.list.map((eachData, eachDataIndex) => {
              if (eachData.value === "[[previous_unpaid_invoice_link]]" && isSMS) return null;
              return (
                  <li
                      key={eachGroupIndex + "_" + eachDataIndex}
                      onClick={() => onClick(eachData.value)}
                  >
                      {eachData.field_label}
                  </li>
              )
          })}
        </StyledList>
      </TriggerPersonalizedAccordion>
    )
  );

  return (
    <CustomPopOver buttonText={"Personalized"} color={"#635dff"}>
      <Box
        sx={{
          maxHeight:400,
          overflowY: 'auto',
          background: '#c2c2c21f',
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          maxWidth: "250px",
          width: "100%",
          padding: "8px",
        }}
      >
        {renderData}
      </Box>
    </CustomPopOver>
  );
};

export default PersonalizedTag;
