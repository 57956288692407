import { FormControl, FormControlLabel, Icon, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { saveUserCustomMenu } from "../../api/profileApi";
import GlobalModal from "../../components/globals/Modal/GlobalModal";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";
import Meaterial from "./MaterialData";


const Popup = (props) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [link, setLink] = useState('');
    const [linkTarget, setLinkTarget] = useState('CURRENT_TAB');
    const [icon, setIcon] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('#3a3a3a');
    const [textColor, setTextColor] = useState('#ffffff');
    const [isIframe, setIsIframe] = useState('YES');
    const [status, setStatus] = useState('ACTIVE');
    const [find, setFind] = useState('');
    //validator
    const [, forceUpdate] = useState() 
    const simpleValidator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate: forceUpdate },
        className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    const meaterialData = Meaterial.icons;

    useEffect(() => {
        if (props.data) {
            setTitle(props.data.title)
            setLink(props.data.link)
            setLinkTarget(props.data.link_target)
            setIcon(props.data.icon)
            setBackgroundColor(props.data.background_color)
            setTextColor(props.data.text_color)
            setIsIframe(props.data.is_iframe)
            setStatus(props.data.status)
        }
    }, [props.data])

    const renderIcon=(e)=>{
        const results = meaterialData.filter(post => {
            if (find === ""){
                if(post.popularity>140000) return true;
            }else{
                return post.name.replace('_',' ').toLowerCase().includes(find.toLowerCase())
            }
            
        })
        let rendHtml = []
 
        rendHtml = results.map((item, index)=>{
            if(index<200){
                return(
                    <div className="p-1 findIcon" onClick={()=>setIcon(item.name)}><Icon style={{fontSize : 24}}>{item.name} </Icon> </div>
                )    
            }
            
        })
        if(rendHtml.length === 0){
           return(
            <div className="text-center">
                    <p className="p-0 m-0" style={{color:'rgba(19, 49, 89, 0.5)'}}>No icon found!</p>
                </div> 
           );
        }else{
           return rendHtml;  
        }

        
    }

    const toggle = (reset = false, updateData=null) => {
        props.hideModal(reset, updateData);
    }

    const onSubmitHandle = (e) => {
        e.preventDefault();
        if(simpleValidator.current.allValid()){
            setLoading(true);
            let formData = {
                menu_id: props.data ? props.data.id : null,
                id: props.data ? props.data.id : null,
                title:title,
                link:link,
                link_target:linkTarget,
                icon:icon,
                background_color:backgroundColor,
                text_color:textColor,
                is_iframe:isIframe,
                status:status
            }
            saveUserCustomMenu({
                "form_data": formData
            }).then((response) => {
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.message, 'success');
                    if(props.data){
                        toggle(false, formData);
                    }else{
                        toggle(true);
                    }
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });

        }else{
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const buttonSubmitText = () =>{
        if(loading){
            if(props.data){
                return 'Updating...'
            }
            return 'Saving...'
        }else{
            if(props.data){
                return 'Update'
            }
            return 'Save'
        }
    }

    return (
        <GlobalModal
            open={props.isOpen}
            onClose={() => props.hideModal()}
            title={props.title}
            buttonIcon={<SaveIcon />}
            buttonText={buttonSubmitText()}
            // className={'global-xs-modal'}
            onSubmit={onSubmitHandle}>
            <div className=""> 
                
                <div className="custom_menu_form_content">
                    {loading &&
                        <div className="loader-container">
                            <Loader />
                        </div>
                    }
                    <div className="input-field custom_input">
                        <h5 className="label_text">Title <span>*</span></h5>
                        <input className="modal_input" type="text" placeholder="Enter title" value={title} onChange={e => setTitle(e.target.value)} />
                        {simpleValidator.current.message('title', title, 'required')}
                    </div>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Link <span>*</span></h5>
                        <input className="modal_input" type="text" placeholder="Enter link" value={link} onChange={e => setLink(e.target.value)} />
                        {simpleValidator.current.message('link', link, 'required')}
                    </div>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Link Target <span>*</span></h5>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-link-target"
                                value={linkTarget}
                                label="Link Target"
                                onChange={e => setLinkTarget(e.target.value)}
                            >
                                <MenuItem value={'CURRENT_TAB'}>Current Tab</MenuItem>
                                <MenuItem value={'NEW_TAB'}>New Tab</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Icon <span>*</span></h5><small></small>
                        <div>{icon && <Icon style={{fontSize : 34}}>{icon} </Icon>}</div>
                        <input className="modal_input" type="text" placeholder="Search icon" value={find} onChange={e => setFind(e.target.value)} />
                        <div className="icon-container">
                            {renderIcon()}
                        </div>
                        
                        {/* <input className="modal_input" type="text" placeholder="play_circle_filled" value={icon} onChange={e => setIcon(e.target.value)} /> */}
                        {simpleValidator.current.message('icon', icon, 'required')}
                    </div>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Background Color <span>*</span></h5>
                        <input className="modal_input" type="color" value={backgroundColor} onChange={e => setBackgroundColor(e.target.value)} />
                    </div>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Text Color <span>*</span></h5>
                        <input className="modal_input" type="color" value={textColor} onChange={e => setTextColor(e.target.value)} />
                    </div>

                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="is_iframe" value={isIframe}
                            onChange={e => setIsIframe(e.target.value)}
                        >
                            <FormControlLabel value="YES" control={<Radio />} label="Show in iframe inside the platform" />
                            <FormControlLabel value="NO" control={<Radio />} label="Show outside the platform" />
                        </RadioGroup>
                    </FormControl>

                    <div className="input-field custom_input">
                        <h5 className="label_text">Status <span>*</span></h5>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={status}
                                label="Status"
                                onChange={e => setStatus(e.target.value)}
                            >
                                <MenuItem value={'ACTIVE'}>Active</MenuItem>
                                <MenuItem value={'INACTIVE'}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                
            </div>
        </GlobalModal >
    )
}

export default Popup;