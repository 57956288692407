import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import StarIcon from "@material-ui/icons/Star";
import React, { useState } from "react";
import CustomConfirmAlert from "../../../common/confirm-alert/CustomConfirmAlert";
import { renderFolderIcon } from "../../../helper/functions";
import AddOrEditNewProductModal from "../AddOrEditNewProductModal";
import { useDispatch, useSelector } from "react-redux";
import { productFolderState } from "../../../common/productSelector";
import RefreshIcon from '@material-ui/icons/Refresh';
import { deleteProduct, deleteProductForever, toggleFavorite } from "../../../../../actions/ProductAction";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));

const PopoverStyles = styled(Popover)(({ theme }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  "& .popoverWrapper": {
    padding: theme.spacing(1),
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  },

  "& .popoverList": {
    padding: 0,
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .icon": { color: "#1d2939" },
    "& .text": { color: "#1d2939", fontWeight: 600, textAlign: "left" },
  },
}));

const EachProductTableItem = ({ product }) => {
  const {
    id,
    title,
    description,
    unit_cost,
    unit_price,
    is_favorite,
    billing_frequency,
    folders,
  } = product || {};

  const [openAddOrEditModal, setOpenAddOrEditModalModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  
  const { selectedFolder } = useSelector(productFolderState);

  const dispatch = useDispatch();

  const handleEditProduct = () => {
    setOpenAddOrEditModalModal(true);
    handlePopoverClose();
  };

  const handleFavoriteProduct = () => {
    dispatch(toggleFavorite({ is_favorite: is_favorite === "NO" ? "YES" : "NO", id }));
    handlePopoverClose();
  };

  const confirmDeleteProduct = () => {
    dispatch(deleteProduct({id: id, status: "TRASH"}));
  };

  const handleDeleteProduct = () => {
    handlePopoverClose();
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteProduct(),
      title: "Are you sure you want to delete this product?",
      description: "You cannot undo this action",
    });
  };

  const handleRestoreProduct = () => {
    dispatch(deleteProduct({id: id, status: "ACTIVE"}));
  }

  const confirmDeleteForever = () => {
    dispatch(deleteProductForever(id));
  };

  const handleDeleteForever = () => {
    handlePopoverClose();
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteForever(),
      title: "Are you sure you want to delete this product?",
      description: "You cannot undo this action",
    });
  }

  const renderActionButton = () => {
    switch (selectedFolder) {
      case "trash":
        return (
          <>
            <ListItem onClick={handleRestoreProduct} button>
              <ListItemIcon>
                <RefreshIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Restore</Typography>
              </ListItemText>
            </ListItem>
            <ListItem onClick={handleDeleteForever} button>
              <ListItemIcon>
                <DeleteIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Delete Forever</Typography>
              </ListItemText>
            </ListItem>
          </>
        )
    
      default:
        return (
          <>
            <ListItem onClick={handleEditProduct} button>
              <ListItemIcon>
                <EditIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">Edit</Typography>
              </ListItemText>
            </ListItem>

            <ListItem onClick={handleFavoriteProduct} button>
              <ListItemIcon>
                <StarIcon
                  fontSize="small"
                  className="icon"
                  style={{ color: isFavorite ? "#f79009" : "#1d2939" }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography className="text">
                  {isFavorite ? "Remove Favorite" : "Favorite"}
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem button onClick={handleDeleteProduct}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" className="icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography fontSize="small" className="text">
                  Delete
                </Typography>
              </ListItemText>
            </ListItem>
          </>
        )
    }
  }

  

  // Popover
  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;

  const isFavorite = is_favorite === "YES" ? true : false;
  return (
    <TableRowWrapper>
      <TableCell component="th" scope="row">
        <div className="titleWrap">
          {title}
          <StarIcon
            className={isFavorite ? "iconFavorite active" : "iconFavorite"}
            color="red"
            onClick={handleFavoriteProduct}
          />
        </div>

        {folders?.length > 0 && (
          <div className="folderWrap">
            {folders.map((folder) => (
              <div className="folderItem">
                {renderFolderIcon(folder.folder_icon, folder.folder_color, 14)}
                <span className="folderName">{folder.title}</span>
              </div>
            ))}
          </div>
        )}
      </TableCell>
      <TableCell align="left">{description}</TableCell>
      <TableCell align="center">${unit_cost}</TableCell>
      <TableCell align="center">${unit_price}</TableCell>
      <TableCell align="center">{billing_frequency}</TableCell>
      <TableCell align="center">
        <IconButton size="small" onClick={handlePopover}>
          <MoreVertIcon />
        </IconButton>
      </TableCell>

      <PopoverStyles
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        elevation={1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="popoverWrapper">
          <List className="popoverList" component="div" dense>
            {renderActionButton()}
          </List>
        </div>
      </PopoverStyles>

      {openAddOrEditModal && (
        <AddOrEditNewProductModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModalModal(false)}
          edit={product}
        />
      )}
    </TableRowWrapper>
  );
};

export default EachProductTableItem;
