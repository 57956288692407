import { InputBase, makeStyles, styled, withStyles } from "@material-ui/core";

export const BasicInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "&.MuiInputBase-multiline": {
      padding: "0 !important",
    },
    "& .MuiInputAdornment-positionEnd": {
      position: "absolute",
      right: "12px",
    },
    "& .pricePadding": {
            paddingLeft: "24px !important"
        }
  },

  input: {
    position: "relative",
    // color: "var(--dark_blue)",
    backgroundColor: "white !important",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },

    "&.MuiSelect-select": {
      height: "1rem !important",
    },
  },
}))(InputBase);

export const BasicInputSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    backgroundColor: "white !important",
    position: "relative",
    // color: "var(--dark_blue)",
    fontSize: "13px",
    margin: "0 !important",

    padding: " 9px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

export const productsStyle = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  flexCenterBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  linkButton: {
    color: "#3C7EF3",
    fontWeight: 600,
    "&:focus": { backgroundColor: "transparent !important" },
  },
  primaryButton: {
    backgroundColor: "#3C7EF3",
    fontWeight: 600,
    color: "white",
    minWidth: "fit-content",
    "&:hover, &:focus": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
  },
  manualEntryButton: {
    backgroundColor: "#3C7EF3",
    fontWeight: 600,
    color: "white",
    padding: "5px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#3C7EF3",
      color: "white",
    },
  },
  addDealFooter: {
    height: 93,
    color: "#FFF",
    paddingInline: "85px",
    borderEndStartRadius: "8px",
    borderEndEndRadius: "8px",
    backgroundColor: " rgb(24, 31, 72)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .btn": {
      width: 90,
    },
  },
  searchPopover: {
    "& .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded": {
      minWidth: 610,
      padding: 8,
    },

    "& .awesome__scroll_bar": {
      maxHeight: 270,
      paddingRight: 8,
      paddingBottom: 0,
    },
    "& .awesome__scroll_bar::-webkit-scrollbar": {
      width: "8px",
    },
  },
}));

export const CustomScrollbar = styled('div')({
  overflowY: "auto",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
    backgroundColor: "#2c3e50",
  },
  "&::-webkit-scrollbar-thumb": {
    // backgroundColor: "#706fd3",
    backgroundColor: "#346fef",
    borderRadius: "10px",
    // outline: '1px solid #8e44ad'
  },
});

