import { makeStyles } from "@material-ui/core";
import React from "react";
import { EditCallOutcome, icons } from "../icon";
import CallOutcomesSkeleton from "./CallOutcomesSkeleton";

const modalStyles = makeStyles((theme) => ({
  submitButton: {
    fontWeight: 600,
    backgroundColor: "#E5E8EF",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dcdcdc !important",
      border: "none",
      color: "#4550f8",
      "& svg path": {
        fill: "#4550f8",
      }
    },
    "&:focus": {
      backgroundColor: "#E5E8EF !important",
      border: "no4550f8ne !important",
    },
  },
  buttonText: {
    display: "flex",
    justifyContent: "center",
    gap: 7,
    alignItems: "center",
    padding: "10px",
  },
}));
const CallOutcomeTable = ({
  loading,
  data,
  onEdit,
  onDelete,
}) => {
  const classes = modalStyles();


  const renderData = () => {
    if (!loading && !data.length) {
      return (
        <tr>
          <td colSpan="2">
            <div style={{ paddingTop: 50 }}>NO DATA AVAILABLE</div>
          </td>
        </tr>
      );
    }
    return data.map((item, index) => (
      <tr key={index}>
        <td width="80%" style={{ paddingLeft: "15px" }}>
          {item.outcome}
        </td>
          <td
            width="20%"
            style={{
              paddingLeft: "10px",
              paddingRight: "2px",
              textAlign: "center",
            }}
          >
            <button
              className={`${classes.submitButton} mr-2`}
              onClick={() => {
                onEdit(item);
              }}
            >
              <div className={classes.buttonText}>
                <span><EditCallOutcome /></span>
                <span> Edit</span>
              </div>
            </button>
            <button
              className="delete"
              onClick={() => {
                onDelete(item);
              }}
            >
              {icons.delete}
              Delete
            </button>
          </td>
      </tr>
    ));
  };

  return (
    <div style={{ minHeight: "48vh" }}>
      {loading ? (
        <CallOutcomesSkeleton />
      ) : (
        <table className="data_table">
          <thead>
            <tr>
              <th
                width="80%"
                style={{ paddingLeft: "10px", paddingRight: "2px" }}
              >
                Call Disposition Name
              </th>
                <th
                  width="20%"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "2px",
                    textAlign: "center",
                  }}
                >
                  Action
                </th>
            </tr>
          </thead>
          <tbody>{renderData()}</tbody>
        </table>
      )}
    </div>
  );
};

export default CallOutcomeTable;
