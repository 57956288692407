export const IconList = {
    search : <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.5 11.3633H11.71L11.43 11.0933C12.41 9.95328 13 8.47328 13 6.86328C13 3.27328 10.09 0.363281 6.5 0.363281C2.91 0.363281 0 3.27328 0 6.86328C0 10.4533 2.91 13.3633 6.5 13.3633C8.11 13.3633 9.59 12.7733 10.73 11.7933L11 12.0733V12.8633L16 17.8533L17.49 16.3633L12.5 11.3633ZM6.5 11.3633C4.01 11.3633 2 9.35328 2 6.86328C2 4.37328 4.01 2.36328 6.5 2.36328C8.99 2.36328 11 4.37328 11 6.86328C11 9.35328 8.99 11.3633 6.5 11.3633Z" fill="#3C7EF3"/>
            </svg>,
    info: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.5888 0 0 3.58885 0 8.00005C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8.00005C16 3.58885 12.4112 0 8 0ZM8 14.5455C4.39079 14.5455 1.45455 11.6092 1.45455 8.00005C1.45455 4.39088 4.39079 1.45455 8 1.45455C11.6092 1.45455 14.5455 4.39088 14.5455 8.00005C14.5455 11.6092 11.6092 14.5455 8 14.5455Z" fill="#ADB7C5"/>
            <path d="M7.99911 3.40112C7.46451 3.40112 7.0296 3.83632 7.0296 4.37126C7.0296 4.90571 7.46451 5.34052 7.99911 5.34052C8.5337 5.34052 8.96861 4.90571 8.96861 4.37126C8.96861 3.83632 8.5337 3.40112 7.99911 3.40112Z" fill="#ADB7C5"/>
            <path d="M7.99864 6.78271C7.59699 6.78271 7.27136 7.10834 7.27136 7.50999V11.8736C7.27136 12.2753 7.59699 12.6009 7.99864 12.6009C8.40028 12.6009 8.72591 12.2753 8.72591 11.8736V7.50999C8.72591 7.10834 8.40028 6.78271 7.99864 6.78271Z" fill="#ADB7C5"/>
        </svg>,
    smsIcon : <svg width="20" height="20" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.332.832H1.665a.833.833 0 0 0-.833.83v5.007c0 .457.374.83.833.83h1.25v1.666L5.562 7.5h2.77c.46 0 .833-.373.833-.83V1.662a.832.832 0 0 0-.833-.83z" fill="#000"/></svg>,
    emailIcon : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="#000" d="M467 61H45C20.218 61 0 81.196 0 106v300c0 24.72 20.128 45 45 45h422c24.72 0 45-20.128 45-45V106c0-24.72-20.128-45-45-45zm-6.214 30L256.954 294.833 51.359 91h409.427zM30 399.788V112.069l144.479 143.24L30 399.788zM51.213 421l144.57-144.57 50.657 50.222c5.864 5.814 15.327 5.795 21.167-.046L317 277.213 460.787 421H51.213zM482 399.787 338.213 256 482 112.212v287.575z"/></svg>,
    callIcon : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 513.64 513.64"><path d="m499.66 376.96-71.68-71.68c-25.6-25.6-69.12-15.359-79.36 17.92-7.68 23.041-33.28 35.841-56.32 30.72-51.2-12.8-120.32-79.36-133.12-133.12-7.68-23.041 7.68-48.641 30.72-56.32 33.28-10.24 43.52-53.76 17.92-79.36l-71.68-71.68c-20.48-17.92-51.2-17.92-69.12 0L18.38 62.08c-48.64 51.2 5.12 186.88 125.44 307.2s256 176.641 307.2 125.44l48.64-48.64c17.921-20.48 17.921-51.2 0-69.12z"/></svg>,
    shareIcon : <svg viewBox="0 -22 512 511" width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M512 233.82 299.223.5v139.203h-45.239C113.711 139.703 0 253.414 0 393.687v73.77l20.094-22.02a360.573 360.573 0 0 1 266.324-117.5h12.805v139.204zm0 0"/></svg>,
    formIcon : <svg width="20" height="20" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M490.818 113.54c-20.05-11.58-45.79-4.68-57.37 15.37l-37.26 64.55V140c0-5.52-4.48-10-10-10-5.53 0-10 4.48-10 10v88.09l-109.83 190.23c-.7 1.2-1.13 2.54-1.28 3.93l-7.52 69.75H20.188V20h356v30c0 5.52 4.47 10 10 10 5.52 0 10-4.48 10-10V10c0-5.52-4.48-10-10-10h-376c-5.53 0-10 4.48-10 10v492c0 5.52 4.47 10 10 10h376c5.52 0 10-4.48 10-10V361.45l110.01-190.54c11.57-20.05 4.68-45.79-15.38-57.37zm-207.5 326.12 28.83 16.64-33.24 24.28zm92.87 52.34h-79l39.16-28.61c1.12-.82 2.06-1.87 2.76-3.07l37.08-64.22zm-49.4-50.34-38.11-22 146.37-253.51 38.1 22zm162.08-280.75-5.72 9.92-38.1-22 5.72-9.92c6.06-10.5 19.55-14.11 30.05-8.05 10.51 6.07 14.12 19.55 8.05 30.05z"/><path d="M386.188 85c-2.64 0-5.21 1.07-7.07 2.93s-2.93 4.44-2.93 7.07c0 2.64 1.07 5.21 2.93 7.07s4.44 2.93 7.07 2.93 5.21-1.07 7.07-2.93 2.93-4.44 2.93-7.07-1.07-5.21-2.93-7.07-4.43-2.93-7.07-2.93zM299.188 106h-202c-5.53 0-10 4.48-10 10s4.47 10 10 10h202c5.52 0 10-4.48 10-10s-4.48-10-10-10zM299.188 162h-202c-5.53 0-10 4.48-10 10s4.47 10 10 10h202c5.52 0 10-4.48 10-10s-4.48-10-10-10zM299.188 218h-74.25c-5.53 0-10 4.48-10 10s4.47 10 10 10h74.25c5.52 0 10-4.48 10-10s-4.48-10-10-10zM309.188 284c0-5.52-4.48-10-10-10h-202c-5.53 0-10 4.48-10 10s4.47 10 10 10h202c5.52 0 10-4.48 10-10zM266.188 340c0-5.52-4.48-10-10-10h-62.5c-5.53 0-10 4.48-10 10s4.47 10 10 10h62.5c5.52 0 10-4.48 10-10zM97.188 406h88c5.52 0 10-4.48 10-10s-4.48-10-10-10h-88c-5.53 0-10 4.48-10 10s4.47 10 10 10zM97.188 238h66.66c5.53 0 10-4.48 10-10s-4.47-10-10-10h-66.66c-5.53 0-10 4.48-10 10s4.47 10 10 10zM97.188 350h51.5c5.52 0 10-4.48 10-10s-4.48-10-10-10h-51.5c-5.53 0-10 4.48-10 10s4.47 10 10 10z"/></svg>,
    campaignIcon : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 1200 1200"><path d="M1030.014 96.91C874.836 250.578 646.766 344.637 427.042 357.866H100.749C49.358 358.572 2.898 402.262 0 459.889V607.02c.701 51.369 43.068 99.121 100.699 102.021H231.52c-34.962 118.567 2.593 238.596 43.15 356.415 60.166 56.81 197.785 49.742 242.638-24.195-95.523-75.091-142.05-277.145-26.889-326.979 196.285 22.271 390.749 122.01 539.594 255.716 51.169-.701 97.705-42.745 100.699-100.062V628.618c102.449-36.383 81.738-164.259 0-190.28V197.659c-.683-51.365-43.073-97.799-100.699-100.7v-.049zm2.595 130.135v613.453C888.778 728.424 711.072 653.216 527.741 619.46V448.134c179.894-27.072 355.835-110.077 504.868-221.089z"/></svg>,
    folder_icon:(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.6667 5.00004H10.0001L8.33342 3.33337H3.33341C2.40841 3.33337 1.67508 4.07504 1.67508 5.00004L1.66675 15C1.66675 15.925 2.40841 16.6667 3.33341 16.6667H16.6667C17.5917 16.6667 18.3334 15.925 18.3334 15V6.66671C18.3334 5.74171 17.5917 5.00004 16.6667 5.00004ZM15.8334 11.6667H13.3334V14.1667H11.6667V11.6667H9.16675V10H11.6667V7.50004H13.3334V10H15.8334V11.6667Z" fill="#346FEF"/>
        </svg>  
    ),
}