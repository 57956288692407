import React, { useState } from "react";
import { OutlineButton } from "./contentBody/CustomButton";
import { IconList } from "../../../../constants/IconList";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { Cancel, CheckCircle, DeleteForever } from "@material-ui/icons";
import { INSURANCE_COMPANIES_STATUS } from "../../../../constants/CoreConstants";
import CustomConfirmAlert from "../../common/confirm-alert/CustomConfirmAlert";
import { bulkDeleteInsuranceCompany, insuranceAddBulkFolder, updateInsuranceCompanyBulkStatus } from "../../../../api/productsApi";
import AddOrEditNewCompanyModal from "./AddOrEditNewCompanyModal";

const SelectedCompaniesActions = ({selectedCompanies,setInsuranceCompanyList,folderList, handleFolderList, insuranceCompanyList,
    setSelectedCompanies,setFilterTrigger,
    filterTrigger
}) => {
  const [openAddFolderModal,setOpenAddFolderMOdal] = useState(false);
 
    const handleActiveStatus = async() => {
        const payload = {
            insurance_company_ids: selectedCompanies,
            status: INSURANCE_COMPANIES_STATUS.ACTIVE
        }
        try {
            
             const resp = await updateInsuranceCompanyBulkStatus(payload);

             if(resp.success){
                const updatedList = insuranceCompanyList.map(company => {
                    if (selectedCompanies.includes(company.id)) {
                     
                        return { ...company, status: INSURANCE_COMPANIES_STATUS.ACTIVE };
                    } else {
                        
                        return company;
                    }
                });
                setInsuranceCompanyList(updatedList);
                setSelectedCompanies([]);
                window.showNotification('success', resp.message);
             } else {
              window.showNotification('error', resp.message);
             }

        } catch (error) {
            console.log(error.message);
        }
    }
    const handleInactiveStatus = async() => {
        const payload = {
            insurance_company_ids: selectedCompanies,
            status: INSURANCE_COMPANIES_STATUS.INACTIVE
        }
        try {
            
            const resp = await updateInsuranceCompanyBulkStatus(payload);

            if(resp.success){
               const updatedList = insuranceCompanyList.map(company => {
                   if (selectedCompanies.includes(company.id)) {
                    
                       return { ...company, status: INSURANCE_COMPANIES_STATUS.INACTIVE };
                   } else {
                       
                       return company;
                   }
               });
               setInsuranceCompanyList(updatedList);
               setSelectedCompanies([]);
               window.showNotification('success', resp.message);
              } else {
               window.showNotification('error', resp.message);
              }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleDeletedCompany = async () => {

        const newPayload = {
          insurance_company_ids: selectedCompanies,
        };
        try {
          const response = await bulkDeleteInsuranceCompany(newPayload);
    
          if (response?.success) {
            setFilterTrigger(filterTrigger+1);
            setSelectedCompanies([]);
            window.showNotification("SUCCESS", response.message);
          } else {
            window.showNotification("ERROR", response.data.message);
          }
        } catch (error) {
            console.log(error);
        }
    
      };

    const handleDeleteCompany = () => {
        CustomConfirmAlert({
          onSubmit: () => handleDeletedCompany(),
          title: "Are you sure you want to delete these Insurance Company?",
          description: "You cannot undo this action",
        });
      };

      const handleAddFolder = async () => {
        setOpenAddFolderMOdal(true);
      }


      const updateSelectedCompaniesData = (folderIds) => {
        if (folderIds && folderIds[0]) {
          const newData = insuranceCompanyList.map((company) => {
            if (selectedCompanies.includes(company.id)) {
              const uniqueFolders = new Set(company.folders); 
              folderIds.forEach((id) => uniqueFolders.add(id)); 
              
              return {
                ...company,
                folders: Array.from(uniqueFolders)
              };
            }
            return company;
          });
          setInsuranceCompanyList(newData);
          setSelectedCompanies([]);
        }
      };
      

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <OutlineButton
        variant="outlined"
        startIcon={IconList.folder_icon}
        disableElevation
        onClick={handleAddFolder}
      >
        Add To Folder
      </OutlineButton>
      {
        openAddFolderModal && 
        <AddOrEditNewCompanyModal
        open={openAddFolderModal}
          onClose={()=>setOpenAddFolderMOdal(false)}
          setInsuranceCompanyList={setInsuranceCompanyList}
          folderList={folderList}
          handleFolderList={handleFolderList}
          fromSelectedCompany={true}
          updateSelectedCompaniesData={updateSelectedCompaniesData}
          selectedCompanies={selectedCompanies}
          
        />
      }
      <BootstrapTooltip arrow placement="top" title={"ACTIVE"}>
        <CheckCircle onClick={handleActiveStatus}  style={{ fontSize: 28,color: "rgb(148, 157, 178)",cursor: "pointer" }}/>
      </BootstrapTooltip>
      <BootstrapTooltip arrow placement="top" title={"INACTIVE"}>
        <Cancel onClick={handleInactiveStatus} style={{ fontSize: 28,color: "rgb(148, 157, 178)",cursor: "pointer"}} />
      </BootstrapTooltip>
      <BootstrapTooltip arrow placement="top" title={"DELETE"}>
        <DeleteForever onClick={handleDeleteCompany}  style={{ fontSize: 28,color: "rgb(148, 157, 178)",cursor: "pointer" }}/>
      </BootstrapTooltip>
    </div>
  );
};

export default SelectedCompaniesActions;
