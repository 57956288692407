import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
  },
  headerContainer: { 
    display: "flex",
    flexDirection:"column",
    fontFamily:"var(--poppins) !important", 
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    margin: "5px",
    height: "25px",
  },
  logo: {
    objectFit: "cover",
    height: "60px",
    width: "60px",
  },
  bodyTitle: {
    fontWeight: 600,
    fontSize: "16px",
    margin: "5px",
  },
  bodyDescription: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    margin: "5px",
  },
  backButtonArea: {
    padding: "0px 40px 40px 0px",
    display: "flex",
    justifyContent: "end",
  },
  backButton: {
    backgroundColor: "#3f51b5",
    color: "white",
    transition: "background-color 0.3s ease",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: "#3f51b5",
      boxShadow: "0 2px 4px rgba(0,123,255,.2)",
    },
  },
  list: {
    marginTop: "10px",
    marginLeft: 50
  },
  listItem: {
    lineHeight: "22px",
    fontSize: "15px",
    color: "black",
    fontWeight: 400,
    listStyleType: "disc !important",
  },
  contentBody: {
    padding: "10px 30px 10px 40px",
  },
}));

const PermissionDescriptionModal = ({ open, onClose }) => {
  const classes = useStyles();
  const ImgSrc = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/policy/1.0.0/policy_types_icon.png`;
  const bodyDescription = {
    descriptionOne:
      "By default, the person who creates your Pluto account is the Admin. An Admin can do anything they want inside the system with no restrictions at all. They can View, Manage, and Delete all items within the platform. The Admin is the only user that has access to Billing information.",

    descriptionOneTwo:
      " A Standard User's permission levels are similar to that of an Admin minus the access to billing information. When assigning the Standard role to a user, the following permissions can be turned on or off:",

    descriptionOneThree:
      "A limited user has the lowest permission level. A limited user can only see contacts and items assigned to them. They can't view or access anything related to other users on their team. When assigning the limited user role, the following permissions can be turned on or off:",
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <div style = {{ display: "flex", alignItems: "center" }}>
          <img src={ImgSrc} alt="logo" className={classes.logo} />
          <div className={classes.headerContainer}>
            <span className={classes.dialogTitle}>
              Understanding Permission Levels
            </span>
            <span className={classes.bodyDescription}>
              Take a moment to review the different permission levels.
            </span>
          </div>
        </div>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <div className={classes.contentBody}>
          <div className={classes.section}>
            <p className={classes.bodyTitle} gutterBottom>
              Admin
            </p>
            <p className={classes.bodyDescription}>
              {bodyDescription.descriptionOne}
            </p>
          </div>
          <div className={classes.section}>
            <p className={classes.bodyTitle} gutterBottom>
              Standard User {" "}
              <span style={{ color: "#3f51b5", fontSize: "14px" }}>
                (recommended when working as a team)
              </span>
            </p>
            <p className={classes.bodyDescription}>
              {bodyDescription.descriptionOneTwo}
            </p>
            <ul className={classes.list}>
              <li className={classes.listItem}> Manage billing</li>
              <li className={classes.listItem}>
                
                Manage users (Add, Remove, Permission levels)
              </li>
              <li className={classes.listItem}> Export contacts</li>
              <li className={classes.listItem}> Change lead owner</li>
              <li className={classes.listItem}> Manage global items</li>
              <li className={classes.listItem}> Manage user goals</li>
              <li className={classes.listItem}> Manage user reports</li>
            </ul>
          </div>
          <div className={classes.section}>
            <p className={classes.bodyTitle} gutterBottom>
              Limited User
            </p>
            <p className={classes.bodyDescription}>
              {bodyDescription.descriptionOneThree}
            </p>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                
                View & manage another user's calendar
              </li>
              <li className={classes.listItem}>
                
                View another user's appointment scheduler page
              </li>
              <li className={classes.listItem}> Export contacts</li>
              <li className={classes.listItem}>
                
                Reassign contacts to another user on the team
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.backButtonArea}>
          <button onClick={onClose} className={classes.backButton}>
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              Back
            </span>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PermissionDescriptionModal;
