import moment from 'moment-timezone';
import React, { useEffect, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchLeadGroupList } from "../../actions/profileAction";
import { removeGroup } from "../../api/profileApi";
import "../../components/group/assets/image/plus_btn_circle.png";
import CustomConfirmAlert from "../team/CustomConfirmAlert";
import Utils from "./../../helpers/Utils";
import "./assets/css/group-lists-responsive.css";
import "./assets/css/group-lists.css";
import { GlobalTable } from "./GlobalTable";
import LeadGroupModal from "./LeadGroupModal";
import LeadGroupSkeleton from "./LeadGroupSkeleton";


const App = (props) => {

    const totalGroups = 0;
    const operation = [];
    const queryTerm = [];
    const noHeader = true;

    const history = useHistory();
    const [leadGroups, setLeadGroups] = useState([]);
    const [leadGroupToEdit, setLeadGroupToEdit] = useState(null);
    const [page, setPage] = useState(1)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [firstLoad, setFirstLoad] = useState(true)
    const [isFetchingData, setIsFetchingData] = useState(true)
    const [openLeadGroupModal, setOpenLeadGroupModal] = React.useState(false);
    const userTimezone = Utils.getAccountData('userTimezoneRaw');


    useEffect(() => {
        document.title = `Lead Group | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
    },[])

    useEffect(() => {
        setIsFetchingData(true);
        props.fetchLeadGroupList({page: page, itemPerPage: itemPerPage, operation: JSON.stringify(operation), queryTerm: JSON.stringify(queryTerm)}, fetchLeadGroupResponse)
    }, [firstLoad]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchLeadGroupResponse = (response) => {
        setLeadGroups(response)
        setIsFetchingData(false)
    }

    const onChangePage = (page) => {
        setPage(page)
    }

    const onChangeRowsPerPage = (page) => {
        setItemPerPage(page)
    }
    const EachAvatar = (props) => {

        const fullName = props.data.full_name.substr(0,2);
        return (
            <div className="distribution__icon"><span>{fullName}</span></div>
        );
    };


    const generateAvatar = (groupUsers) => {
        let groupUsersArr = [];

        if(groupUsers !== undefined){
            for(let i = 0; i < groupUsers.length; i++){
                groupUsersArr.push(<EachAvatar key={i} data={groupUsers[i]} />)
            }
        }


        return groupUsersArr;
    }

    const handleCloseLeadGroupModal = () => {
        setLeadGroupToEdit(null);
        setOpenLeadGroupModal(false);

    }

    const handleCloseLeadGroupModalAndUpdate = () => {
        handleCloseLeadGroupModal();
        window.showNotification("SUCCESS", "Group create/update success")
        setFirstLoad(!firstLoad);
    }

    const deleteData = (_id) => {
        //setLoading(true);
        removeGroup({
            "form_data": {
                group_id: _id
            }
        })
        .then(response => {
            if (response.data.status === 'success' || response.data.success === true) {
              window.showNotification("SUCCESS", response.data.message)
                setFirstLoad(!firstLoad);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                      window.showNotification("ERROR", 'Not removed')
                    });
                }
            } else {
              window.showNotification("ERROR", response.data.message)
            }
        }).finally(() => {
            //setLoading(false);
        });
    }
    const TimezoneConversation = (inputTime, currentTimezone = 'UTC', convertTimezone = 'UTC', format = "YYYY-MM-DD HH:mm:ss") => {
        try {
            var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
            var formattedTime = currentTimeInGivenTimezone.clone().tz(convertTimezone).format(format);
            return <span>{formattedTime}</span>;
        } catch (e) {
            return <span>{inputTime}</span>; 
        }
    }
    const columns = [
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Group Name</span>,
            selector: row => row['name'],
            sortable: false,
            minWidth:'250px'
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Created Time</span>,
            selector: row => {
                return <span>
                    {TimezoneConversation(row['created_at'], 'UTC', userTimezone, "MMM D, YYYY h:mm A")}
                </span>;
            },
            sortable: false,
            minWidth: '250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Distribution Type</span>,
            selector: row => row['method'] == 1 ? 'Round Robin' : 'First To Claim', // eslint-disable-line eqeqeq

            sortable: false,
            minWidth:'250px'
        },
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Distribution</span>,
            selector: row => row['group_users'],
            sortable: false,
            minWidth:'250px',
            cell: row =><div className={"d-flex lead-group-distribution"}>
                { generateAvatar(row['group_users'])}
                <button className="group__edit__btn"row={row} onClick={(event) => {
                    setLeadGroupToEdit(row);
                    setOpenLeadGroupModal(true);
                }} >
                        <span className="material-icons">
                                add
                       </span>
                </button>
            </div>
        },
       
        {
            name: <span style={{fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px'}}>Action</span>,
            selector: row => row['id'],
            sortable: false,
            minWidth:'250px',
            cell: row =>
                <div>
                    <button className="group__edit__btn"row={row} onClick={(event) => {
                        setLeadGroupToEdit(row);
                        setOpenLeadGroupModal(true);
                    }} >
                        <span className="custom_svg_fill_wrpper" arrow title="Edit Group">
                            <svg width="24px" height="20px" viewBox="0 -32 576 576" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" /></svg>
                        </span>
                    </button>
                    <button className="group__edit__btn"row={row} onClick={(event) => {
                        CustomConfirmAlert({
                            handleConfirm: () => {
                                deleteData(row.id);
                            },
                            callConfirmLast: true,
                            title: 'Are you sure?',
                            description: 'If you delete this lead group you will not be able restore it again',
                            type_word: 'DELETE GROUP',
                            icon: 'question',
                            closeButtonText: 'Cancel',
                            confirmButtonText: 'Delete'
                        });
                    }} >
                        <span arrow title="Delete">
                            <svg width="25" height="20" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.125 8.3125L4.57715 22.1069C4.60744 22.3949 4.74329 22.6615 4.95851 22.8553C5.17374 23.0491 5.45308 23.1563 5.74268 23.1562H19.2573C19.5469 23.1563 19.8263 23.0491 20.0415 22.8553C20.2567 22.6615 20.3926 22.3949 20.4229 22.1069L21.875 8.3125H3.125ZM15.2344 18.9307L12.5 16.1963L9.76562 18.9307L8.52246 17.6875L11.2568 14.9531L8.52246 12.2188L9.76562 10.9756L12.5 13.71L15.2344 10.9756L16.4775 12.2188L13.7432 14.9531L16.4775 17.6875L15.2344 18.9307Z" fill="#FF264A"></path>
                                <path d="M22.8516 2.84375H2.14844C1.82483 2.84375 1.5625 3.10608 1.5625 3.42969V6.16406C1.5625 6.48767 1.82483 6.75 2.14844 6.75H22.8516C23.1752 6.75 23.4375 6.48767 23.4375 6.16406V3.42969C23.4375 3.10608 23.1752 2.84375 22.8516 2.84375Z" fill="#FF264A"></path>
                            </svg>
                        </span>
                    </button>
                </div>

        },
    ];


    return (

            <div className="main__wrapper">
                <div className="main__top d-flex justify-content-between align-items-center">
                    <div className="main__top__text d-flex align-items-center">
                        <h1 className="mt-4">Lead Groups</h1>
                    </div>
                    <div className="main__top__button d-flex align-items-center">
                        <a onClick={(e)=> { e.preventDefault(); setOpenLeadGroupModal(true) }} className="add__group__btn modal-trigger accent--bg--text--color" href="#!">
                           <span className="material-icons">
                                add_circle_outline
                                </span>
                                <span className="mx-2">Add New Group</span>
                        </a>
                        {
                            history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                            <Link className={"add__group__btn modal-trigger accent--bg--text--color"} to="/user/settings"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span className="mx-2">Back</span></Link>
                        }
                    </div>
                </div>

                <div className="main__bottom awesome__scroll_bar lead-group-main-table">

                   { isFetchingData ? <LeadGroupSkeleton/> :( 
                    <GlobalTable
                        table_unique_id={'lead_group'}
                        columns={columns}
                        data={leadGroups}

                        keyField={'id'}
                        noDataComponent={(
                            <span style={{padding: 10, color: 'red'}}>No Group created yet! Create new...</span>
                        )}

                        defaultSortField="title"

                        selectableRows={false}

                        pagination={leadGroups.length === 0 ? false : true}
                        paginationData ={{
                            paginationTotalRows: totalGroups,
                            paginationPerPage: itemPerPage,
                            onChangePage: onChangePage, //for pagination change
                            onChangeRowsPerPage: onChangeRowsPerPage, //for per page change
                            paginationComponentOptions: {
                                rowsPerPageText : 'Pages of '
                            }
                        }}
                        subHeader={true}
                        noHeader={noHeader}
                        noHeaderData={{
                            noContextMenu: false,
                            contextActions: [],
                            actions: []
                        }}
                    />
                    )}

                    {openLeadGroupModal &&
                        <LeadGroupModal
                            openLeadGroupModal={openLeadGroupModal}
                            data={leadGroupToEdit}
                            hideModal={handleCloseLeadGroupModal}
                            SaveAndHideModal={handleCloseLeadGroupModalAndUpdate}
                            title={leadGroupToEdit ? 'Update Group' : 'New Group'}
                        />
                    }

                </div>
            </div>
    )
}

const mapStateToProps = state => {
    return {

    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchLeadGroupList: (params, callback) => dispatch(fetchLeadGroupList(params, callback))
    };
}


const LeadGroup = connect(mapStateToProps, mapDispatchToProps)(App);

export default LeadGroup;