import React, { useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useDispatch } from "react-redux";
import {
  deletePolicyTypeFolder,
  getPolicyTypes,
  onSelectPolicyTypeFolder,
} from "../../../../actions/ProductAction";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import CustomConfirmAlert from "../../common/confirm-alert/CustomConfirmAlert";
import { renderFolderIcon } from "../../helper/functions";
import CreateNewFolderModal from "./CreateNewFolderModal";

const PopoverStyles = styled(Popover)(({ theme }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  "& .popoverWrapper": {
    padding: theme.spacing(1),
    boxShadow:
      "rgba(0, 0, 0, 0.4) 0px 5px 5px -3px, rgba(0, 0, 0, 0.28) 0px 8px 10px 1px, rgba(0, 0, 0, 0.24) 0px 3px 14px 2px",
  },

  "& .popoverList": {
    padding: 0,
    "& .MuiListItemIcon-root": {
      minWidth: 30,
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .icon": { color: "#1d2939" },
    "& .text": { color: "#1d2939", fontWeight: 600 },
  },
}));

const useStyles = makeStyles({
  folderTitle: {
    width: "85%",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const EachFolderItem = ({ folder, selectedFolder }) => {
  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isDeletable = folder?.deletable ? true : false;

  const dispatch = useDispatch();

  const handleSelectedFolder = () => {
    dispatch(onSelectPolicyTypeFolder(folder.id));

    dispatch(
      getPolicyTypes({ page: 1, perPage: 10, userProductFolderId: folder.id })
    );
  };

  const handleEditCategory = () => {
    setOpenAddFolderModal(true);
    handlePopoverClose();
  };

  const confirmDeleteCategory = () => {
    dispatch(deletePolicyTypeFolder(folder.id));
  };

  const handleDeleteCategory = () => {
    handlePopoverClose();
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteCategory(),
      title: "Are you sure you want to delete this category?",
      description: "You cannot undo this action",
    });
  };
  // Popover
  const handlePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? "simple-popover" : undefined;
  const { folderTitle } = useStyles();
  return (
    <>
      <BootstrapTooltip
        title={`${folder?.title.length > 26 ? folder.title : ""}`}
      >
        <ListItem
          button
          selected={selectedFolder}
          onClick={handleSelectedFolder}
        >
          <ListItemIcon style={{'& svg': {color: 'unset !important'}}}>
            {renderFolderIcon(folder.folder_icon, folder.folder_color, 20)}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" className={folderTitle}>
              {folder.title}
            </Typography>
          </ListItemText>

          <ListItemSecondaryAction>
            {isDeletable && (
              <IconButton size="small" onClick={handlePopover}>
                <MoreVertIcon style={{ color: "#949db2" }} />
              </IconButton>
            )}

            <PopoverStyles
              id={popoverId}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              elevation={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div className="popoverWrapper">
                <List className="popoverList" component="div" dense>
                  <ListItem onClick={handleEditCategory} button>
                    <ListItemIcon>
                      <EditIcon
                        fontSize="small"
                        className="icon"
                        style={{ color: "#1d2939" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        className="text"
                        style={{
                          color: "#1d2939",
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Edit
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem
                    button
                    onClick={() => handleDeleteCategory(folder.id)}
                  >
                    <ListItemIcon>
                      <DeleteIcon
                        fontSize="small"
                        style={{ color: "#1d2939" }}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        color={"text.primary"}
                        style={{
                          color: "#1d2939",
                          fontWeight: 600,
                          textAlign: "left",
                        }}
                      >
                        Delete
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            </PopoverStyles>
          </ListItemSecondaryAction>
        </ListItem>
      </BootstrapTooltip>
      {openAddFolderModal && (
        <CreateNewFolderModal
          open={openAddFolderModal}
          onClose={() => setOpenAddFolderModal(false)}
          edit={folder}
        />
      )}
    </>
  );
};

export default EachFolderItem;
