import React, {useState} from "react";
import {FormControlLabel, Modal, RadioGroup} from "@material-ui/core";
import {
    StyledButton,
    StyledEmailInput,
    StyledEmailInputGroup,
    StyledEmailInputLabel,
    StyledExportContent,
    StyledExportWrapper, StyledUl
} from "./dealReportUseStyles";
import Utils from "../../helpers/Utils";
import {contactExport} from "../../api/java17CoreApi";
import { FILE_FORMAT } from "../../constants/CoreConstants";
import { CustomRadio } from "../emailSettings/connectedEmailV2/ConnectedEmailStyle";

const ExportDealReportModal = (props) => {
    const [emails, setEmails] = useState("");
    const [emailsArray, setEmailsArray] = useState([]);
    const [errors, setErrors] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [accepted, setAccepted] = useState(false);
    const [type, setType] = useState(FILE_FORMAT.CSV)

    const handleChange = (event) => {
        if (errors){
            setErrors("");
        }

        setEmails(event.target.value);
    };

    const handleSubmit = async () => {
      try{
          setSubmitting(true);
          const emailsArray = [];

          const splitEmails = emails.split(",");

          for(let i = 0;i < splitEmails.length; i++){
              let email = splitEmails[i];

              if (email.trim() !== ""){
                  emailsArray.push(email);
              }else if (email.trim() === ""){
                  setErrors("Remove extra comma");
                  break;
              }
          }

          setEmailsArray(emailsArray);

          const invalidEmails = emailsArray.filter((email) => (!Utils.validateEmail(email)));

          if (invalidEmails.length > 0) {
              setErrors(`Invalid emails {${invalidEmails.join(", ")}}`);
              return;
          }

          let submitData = {
              page: props.formData.page,
              perPage: props.formData.perPage,
              dealCreatedAtStart: props.formData.dealCreatedAtStart ? window.globalTimezoneConversionToDifferentTimezone(props.formData.dealCreatedAtStart, Utils.getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss") : null,
              dealCreatedAtEnd: props.formData.dealCreatedAtEnd ? window.globalTimezoneConversionToDifferentTimezone(props.formData.dealCreatedAtEnd, Utils.getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss") : null,
              dealCloseStartDate: props.formData.dealCloseStartDate ? window.globalTimezoneConversionToDifferentTimezone(props.formData.dealCloseStartDate, Utils.getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss") : null,
              dealCloseEndDate: props.formData.dealCloseEndDate ? window.globalTimezoneConversionToDifferentTimezone(props.formData.dealCloseEndDate, Utils.getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss") : null,
              userIds: props.formData.userIds,
              dealStatus: props.formData.dealStatus,
              search: props.formData.search ?? null,
              emails: emailsArray,
              type
          }

          const resp = await contactExport(submitData);

          if (resp?.success) {
              setAccepted(true);
              setErrors("");
              window.showNotification("success", resp?.message);
          } else {
              console.error('Failed to export contacts:', resp.message);
              window.showNotification("error", resp?.message);
          }
      }catch (err){
          console.log(err);
      }finally {
          setSubmitting(false);
      }
    };

    const handleClose = () => {
        setEmails("");
        setEmailsArray([]);
        setErrors("");
        setAccepted(false);
        props.onClose();
    };

    return(
        <Modal
            open={props.open}
            onClose={handleClose}
            className={"global-xs-modal add_list_modal"}
            style={{ display: "grid", placeContent: "center" }}
        >
            <StyledExportWrapper>
                {
                    accepted ?
                        <StyledExportContent>
                            <h1>Consider it done!</h1>
                            <div>
                                <StyledEmailInputLabel>
                                    The report is on its way to the email address listed below.<br/>
                                    It may take up to 5 minutes to arrive in your inbox so please be patient. 
                                </StyledEmailInputLabel>
                                <StyledUl>
                                    {
                                        emailsArray.map((email)=> (
                                            <li>{email}</li>
                                        ))
                                    }
                                </StyledUl>
                            </div>
                            <StyledButton style={{ marginTop: "10px", float: "right" }} onClick={handleClose}>Done</StyledButton>
                        </StyledExportContent> :
                        <StyledExportContent>
                            <h1>Export Deal Report</h1>
                            <StyledEmailInputGroup>
                                <StyledEmailInputLabel>
                                    Enter emails with comma (,) separated
                                </StyledEmailInputLabel>
                                <StyledEmailInput type="text" value={emails} onChange={handleChange} placeholder={"example1@gmail.com,example2@gmail.com,..."}/>
                                {
                                    errors &&
                                    <StyledEmailInputLabel style={{ color: "red" }}>
                                        {errors}
                                    </StyledEmailInputLabel>
                                }
                                <StyledEmailInputLabel style={{ fontStyle: "italic", textAlign: "start" }}>
                                    ***The exported file will be sent to these emails after the export process is complete.
                                </StyledEmailInputLabel>
                                <>
                                <StyledEmailInputLabel style={{marginTop:'8px'}}>
                                Choose your file format:
                                </StyledEmailInputLabel>
                                <RadioGroup style={{display:'flex',flexDirection:'row',gap:'20px', alignItems:'center',marginLeft:'12px'}}
                                 value={type} onChange={(event) => { setType(event.target.value) }}>
                                    <FormControlLabel
                                        value={FILE_FORMAT.CSV}
                                        control={<CustomRadio size="small" color="primary" />}
                                        label={FILE_FORMAT.CSV}
                                        style={{color: "#36454F"}}
                                    />
                                    <FormControlLabel
                                        value={FILE_FORMAT.PDF}
                                        control={<CustomRadio size="small" color="primary" />}
                                        label={FILE_FORMAT.PDF}
                                        style={{color: "#36454F"}}
                                    />
                                </RadioGroup>
                                </>
                            </StyledEmailInputGroup>
                            <StyledButton disabled={submitting || !emails.trim()} style={{ marginTop: "10px", float: "right" }} onClick={handleSubmit}>Export</StyledButton>
                            <StyledButton style={{ marginTop: "10px", float: "right", marginRight: "5px", backgroundColor: "red" }} onClick={handleClose}>Cancel</StyledButton>
                        </StyledExportContent>
                }

            </StyledExportWrapper>
        </Modal>
    );
}

export default ExportDealReportModal;