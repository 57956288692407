import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { deleteInsuranceCompanyApi, updateInsuranceCompanyStatusApi } from "../../../../../api/productsApi";
import EachCompanyItem from "./EachCompany";
import BodySkeleton from "../BodySkeleton";
import { CustomScrollbar } from "../../../productsStyle";
import {markStepComplete} from "../../../../../api/onboardApi";
import Utils from "../../../../../helpers/Utils";
import useQueryParam from "../../../../../hooks/useQueryParam";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

const ContentBody = ({
  insuranceCompanyList,
  setInsuranceCompanyList,
  selectedFolder,
  isLoading,
}) => {
  const [openTab, setOpenTab] = useQueryParam("open-tab");
  const [from, setFrom] = useQueryParam("from");

  const updateInsuranceCompanyStatus = async (payload) => {
    const newPayload = {
      insurance_company_folder_id: selectedFolder,
      insurance_company_id: payload.id,
      selected: payload.checked,
    };
    try {
      const response = await updateInsuranceCompanyStatusApi(newPayload);

      if (response?.success) {
        if (openTab === "INSURANCE_COMPANIES" && from === "onboard"){
          const onboardComplete = await markStepComplete({ key: "SELECT_COMPANY" });
          if (onboardComplete?.success) {
            Utils.removeQueryParam(["from", "open-tab"]);
            setOpenTab("");
            setFrom("");
          }
        }
        window.showNotification("SUCCESS", response.message);

        setInsuranceCompanyList((prevState) =>
          prevState.map((company) => {
            if (company.id === payload.id) {
              return {
                ...company,
                selected: payload.checked === "TRUE" ? 1 : 0,
              };
            }
            return company;
          })
        );
      } else {
        window.showNotification("ERROR", response.data.message);
      }
    } catch (error) {}
  };

  const handleDeletedCompany = async (id) => {

    const newPayload = {
      insurance_company_id: id,
    };
    try {
      const response = await deleteInsuranceCompanyApi(newPayload);

      if (response?.success) {
        window.showNotification("SUCCESS", response.message);

        setInsuranceCompanyList((prevState) =>
          prevState.filter((company) => company.id !== id)
        );
      } else {
        window.showNotification("ERROR", response.data.message);
      }
    } catch (error) {}

  };

  const handleUpdateCompanyName = (payload) => {
    setInsuranceCompanyList((prevState) =>
      prevState.map((company) => {
        if (company.id === payload?.insurance_company_id) {
          return {
            ...company,
            company_name: payload.company_name,
          };
        }
        return company;
      })
    );
  };

  const classes = useStyles();
  if (isLoading) {
    return <BodySkeleton />;
  } else {
    return (
      <div className={classes.bodyWrapper}>
        <CustomScrollbar style={{ maxHeight: "calc(100vh - 270px)" }}>
          <Grid container>
            {insuranceCompanyList?.map((data) => (
              <EachCompanyItem
                key={data.id}
                company={data}
                onSelectCompany={updateInsuranceCompanyStatus}
                onConfirmDelete={handleDeletedCompany}
                onUpdateCompanyName={handleUpdateCompanyName}
              />
            ))}
          </Grid>
        </CustomScrollbar>
      </div>
    );
  }
};

export default ContentBody;
