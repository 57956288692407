import React, { useEffect, useState } from "react";
import {
    StyledAddEmailInput,
    StyledConnectEmailButton,
    StyledConnectEmailContent, StyledConnectEmailIconWrapper,
    StyledConnectEmailWrapper
} from "./ConnectedEmailsStyles";
import { connectNylas, fetchUser } from "../../../api/profileApi";
import { DeviceHub } from "@material-ui/icons";
import Utils from "../../../helpers/Utils";
import Loading from "./Loading";

const ConnectEmailView = ({ handleCancel, defaultNylasEmail }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loaderText, setLoaderText] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [isEditable, setIsEditable] = useState(true);
    const [connectingEmail, setConnectingEmail] = useState('');

    useEffect(() => {
        if (!defaultNylasEmail) {
            getData();
        }
    }, [defaultNylasEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        try {
            setLoaderText('Please wait while loading...');
            setIsLoading(true);
            const resp = await fetchUser();

            if (resp && resp.data) {
                setConnectingEmail(getNylasEmail(resp.data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }

    const getNylasEmail = (userData) => {
        try {
            if (!isLoading && userData) {
                let nylasEmailProviderInfo = JSON.parse(userData.nylas_email_provider_info);
                if (nylasEmailProviderInfo?.email) {
                    setIsEditable(false);
                }
                return nylasEmailProviderInfo?.email || "";
            }
        } catch (err) {
            console.log(err);
        }
        return "";
    };

    const initConnectionProcess = async () => {
        if (connectingEmail === "") {
            window.showNotification('error', 'Email address required');
            return;
        }
        if (!Utils.validateEmail(connectingEmail)) {
            window.showNotification('error', 'Invalid email address');
            return;
        }
        setIsSubmitting(true);
        try {
            const resp = await connectNylas({ email: connectingEmail });

            if (resp && resp.success && resp.data && resp.data.redirect) {
                setIsRedirecting(true);
                window.showNotification('success', 'Please wait while redirecting...');
                setLoaderText('Please wait while redirecting...');
                setTimeout(() => {
                    window.open(resp.data.redirect, '_self');
                }, 2000);
            } else {
                window.showNotification('error', resp?.message || 'Unable to connect. Please try again later!', 3000);
            }
        } catch (error) {
            window.showNotification('error', 'Unable to connect. Please try again later!');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <StyledConnectEmailWrapper>
            {
                isRedirecting || isLoading ?
                    <Loading loaderText={loaderText} /> :
                    <StyledConnectEmailContent>
                        <StyledConnectEmailIconWrapper>
                            <DeviceHub />
                        </StyledConnectEmailIconWrapper>
                        <h1>Sign in to your email account</h1>
                        <StyledAddEmailInput disabled={!isEditable} type="email" value={connectingEmail} onChange={(event) => { setConnectingEmail(event.target.value) }} placeholder={'Your personal email address'} />
                        <StyledConnectEmailButton disabled={isSubmitting} onClick={initConnectionProcess}>Next</StyledConnectEmailButton>
                    </StyledConnectEmailContent>
            }
        </StyledConnectEmailWrapper>
    );
};

export default ConnectEmailView;