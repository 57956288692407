import {green, orange, red} from "@material-ui/core/colors";
import Icons from "./Icons";

export const getDncStatusLabelAndColor = (label) => {
    if (label === "VERIFIED_OK") return ["DNC Inactive", green[500]];
    else if (label === "DO_NOT_CALL") return ["DNC Active", orange[500]];
    else if (label === "NOT_VERIFIED") return ["Not Verified", red[500]];
    else return ["N/A", "error"];
};

export const getLandlineMobileStatus = (value) => {
    if (!value) {
        return "NOT AVAILABLE";
    } else {
        return value.toUpperCase();
    }
};

export const getNumberValidationStatusLabelAndColor = (label) => {
    if (label === "VALID") return ["Valid", green[500]];
    if (label === "INVALID") return ["Invalid", orange[500]];
    else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
    else return ["N/A", "error"];
};

export const getEmailValidationStatusLabelAndColor = (label) => {
    if (label === "VALID") return ["Valid", green[500]];
    if (label === "INVALID") return ["Invalid", orange[500]];
    else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
    else return ["N/A", "error"];
};

export const getEmailValidationStatusIcon = (status) => {
    if (status === "VALID") return Icons.checkMark;
    if (status === "INVALID") return Icons.cross;
    else if (status === "NOT_VERIFIED") return "";
    else return "";
};

export const getNumberValidationStatusIcon = (status) => {
    if (status === "VALID") return Icons.checkMark;
    if (status === "INVALID") return Icons.cross;
    else if (status === "NOT_VERIFIED") return "";
    else return "";
};

export const getDncStatusIcon = (status) => {
    if (status === "VERIFIED_OK") return Icons.checkMark;
    else if (status === "DO_NOT_CALL") return Icons.doNotCall;
    else if (status === "NOT_VERIFIED") return '';
    else return "";
};

export const getSkipTraceStatusLabelAndColor = (label) => {
    if (label === "PENDING" || label === "NOT_VERIFIED") return ["Not Validated", "default"];
    else if (label === "TRACED") return ["Traced", green[500]];
    else if (label === "TRACE_FAILED") return ["Trace Failed", orange[500]];
    else if (label === "NOT_VERIFIED") return ["Not Validated", red[500]];
    else return ["", "error"];
};

export const checkEmptyArray = (array) => {
    let empty = true;
    if (typeof array !== "undefined" && array.length > 0){
        empty = false;
    }
    return empty;
};

export const convertIncome = (_income) => {

    if (!_income)
        return "";

    if (_income === "A")
        return "Under $10K";
    else if (_income === "B")
        return "$10-$19,999";
    else if (_income === "C")
        return "$20-$29,999";
    else if (_income === "D")
        return "$30-$39,999";
    else if (_income === "E")
        return "$40-$49,999";
    else if (_income === "F")
        return "$50-$59,999";
    else if (_income === "G")
        return "$60-$69,999";
    else if (_income === "H")
        return "$70-$79,999";
    else if (_income === "I")
        return "$80-$89,999";
    else if (_income === "J")
        return "$90-$99,999";
    else if (_income === "K")
        return "$100-$149,999";
    else if (_income === "L")
        return "$150-$174,999";
    else if (_income === "M")
        return "$175-$199,999";
    else if (_income === "N")
        return "$200-$249,999";
    else if (_income === "O")
        return "$250-$274,999";
    else if (_income === "P")
        return "$275-$299,999";
    else if (_income === "Q")
        return "$300-$349,999";
    else if (_income === "R")
        return "$350-$374,999";
    else if (_income === "S")
        return "$375-$399,999";
    else if (_income === "T")
        return "$400-$449,999";
    else if (_income === "U")
        return "$450-$474,999";
    else if (_income === "V")
        return "$475-$499,999";
    else if (_income === "W")
        return "$500-$549,999";
    else if (_income === "X")
        return "$575-$599,999";
    else if (_income === "Y")
        return "$600-$649,999";
    else if (_income === "Z")
        return "$650+";
    else
        return _income;
}