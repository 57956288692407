export const GET_USER = 'GET_USER';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const UPDATE_FAVICON = 'UPDATE_FAVICON';
export const SET_RESPONSE = 'SET_RESPONSE';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_USER_SET = 'UPDATE_USER_SET';
export const FETCH_LEAD_GROUP_LIST = 'FETCH_LEAD_GROUP_LIST';
export const SET_LEAD_GROUP_LIST = 'SET_LEAD_GROUP_LIST';
export const FETCH_USERS_LIST = 'FETCH_USERS_LIST';
export const USERS_LIST = 'USERS_LIST';
export const CREATE_OR_UPDATE_LEAD_GROUP = 'CREATE_OR_UPDATE_LEAD_GROUP';
export const UPDATE_LEAD_GROUP_SET = 'UPDATE_LEAD_GROUP_SET';

export const RELOAD_CUSTOM_FIELD_FLAG = 'RELOAD_CUSTOM_FIELD_FLAG';
export const RELOAD_PERSONALIZED_TAG_FLAG = 'RELOAD_PERSONALIZED_TAG_FLAG';
export const UPDATE_USER_SIGNATURE = 'UPDATE_USER_SIGNATURE';

export const GET_TAX = 'GET_TAX'
export const ADD_TAX = 'ADD_TAX'
export const UPDATE_TAX = 'UPDATE_TAX'
export const DELETE_TAX = 'DELETE_TAX'

export const GET_INVOICE_PERSONALIZE_TAGS = 'GET_INVOICE_PERSONALIZE_TAGS'
export const GET_INVOICE_PERSONALIZE_TAGS_SUCCESS = 'GET_INVOICE_PERSONALIZE_TAGS_SUCCESS'
export const GET_INVOICE_PERSONALIZE_TAGS_ERROR = 'GET_INVOICE_PERSONALIZE_TAGS_ERROR'