import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';



const SalesVsGoalSkeleton = () => {
    const rows = Array.from(new Array(8))

    return (
        <div style={{width:'100%',display:'flex',gap:'20px',flexDirection:'column'}}>
        <div>
        <Table  aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell ><Skeleton width="60%" height="30px" /></TableCell>
                    <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                    <TableCell><Skeleton width="40%" height="30px" /></TableCell>
                    <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                    <TableCell><Skeleton width="40%" height="30px" /></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((_, index) => (
                    <TableRow key={index}>
                        <TableCell><Skeleton width="60%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                        <TableCell ><Skeleton width="40%" height="30px" /></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        </div>
       
            
        </div>
    );
};

export default SalesVsGoalSkeleton;
