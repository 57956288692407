import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {CustomButton, PageBody, PageHeader, PageWrapper} from "./ConnectedEmailStyle";
import ConnectedEmailList from "./ConnectedEmailList";
import ConnectEmailModal from "./ConnectEmailModal";
import ConnectEmailCalenderSyncModal from "./ConnectEmailCalenderSyncModal";
import {getUserConnectedEmailListLimit} from "../../../api/profileApi";
import ConnectedEmailLimitUpdateModal from "./ConnectedEmailLimitUpdateModal";
import {EMAIL_PROVIDER, EMAIL_PROVIDER_DEFAULT} from "../../../constants/CoreConstants";

const Index = () => {
    const history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const from = params.get("from");
    const userConnectedEmailUid = params.get("userConnectedEmailUid");
    const [openConnectNewEmailModal, setOpenConnectNewEmailModal] = useState(false);
    const [defaultNylasEmail, setDefaultNylasEmail] = useState(null);
    const [openConnectSyncCalenderModal, setOpenConnectSyncCalenderModal] = useState(false);
    const [connectedEmails, setConnectedEmails] = useState([]);
    const [editEmailLimitModal, setEditEmailLimitModal] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchConnectedEmails().then();
    }, []);

    useEffect(() => {
        if (Array.isArray(connectedEmails) && connectedEmails[0]) {
            if (from && from === "connect-email" && userConnectedEmailUid && params.has("success")) {
                let newEmail = connectedEmails.find((connectedEmail)=>(connectedEmail.uid === userConnectedEmailUid));
                const emailProvider = newEmail.credentialInfoProvider ? newEmail.credentialInfoProvider.toUpperCase() : "UNKNOWN";

                setEditEmailLimitModal(newEmail);
                removeQueryParam(["userConnectedEmailUid", "from", "success"]);

                if (emailProvider !== "IMAP") {
                    setOpenConnectSyncCalenderModal(true);
                }
            }
        }
    }, [connectedEmails]);

    const fetchConnectedEmails = async () => {
        try {
            const resp = await getUserConnectedEmailListLimit();
            if (resp && resp.data && resp.success) {
                setConnectedEmails(resp.data);

                let nylasDefault = resp.data.find((itemData) => itemData.provider === EMAIL_PROVIDER.NYLAS && itemData.isDefault === EMAIL_PROVIDER_DEFAULT.YES);
                if (nylasDefault) {
                    setDefaultNylasEmail(nylasDefault);
                }
            }
        } catch (err) {
            console.log(err);
        }finally {
            setIsLoading(false);
        }
    };

    const removeQueryParam = (paramsToRemove) => {
        const searchParams = new URLSearchParams(location.search);

        paramsToRemove.forEach((param) => searchParams.delete(param));
        const newSearch = searchParams.toString();
        history.replace({
            pathname: location.pathname,
            search: newSearch,
        });
    };

    const handleCloseModal = () => {
        setOpenConnectSyncCalenderModal(false);
    };

    return(
        <PageWrapper>
            <PageHeader>
                <div>
                    <h5>Connected Emails</h5>
                    <p>
                        <b>These emails will be used to send emails to your contacts</b>
                    </p>
                </div>
                <CustomButton
                    onClick={() => {
                        setOpenConnectNewEmailModal(true);
                    }}
                >
                    Add New Personal Email
                </CustomButton>
            </PageHeader>

            <PageBody>
                <ConnectedEmailList
                    isLoading={isLoading}
                    connectedEmails={connectedEmails}
                    setConnectedEmails={setConnectedEmails}
                    setEditEmailLimitModal={setEditEmailLimitModal}
                    fetchConnectedEmails={fetchConnectedEmails}
                />
            </PageBody>
            {
                openConnectNewEmailModal &&
                <ConnectEmailModal
                    defaultNylasEmail={defaultNylasEmail}
                    open={openConnectNewEmailModal}
                    onClose={()=>{
                        setOpenConnectNewEmailModal(false);
                    }}
                />
            }
            {
                openConnectSyncCalenderModal &&
                <ConnectEmailCalenderSyncModal
                    open={openConnectSyncCalenderModal}
                    onClose={handleCloseModal}
                />
            }

            {
                !!editEmailLimitModal &&
                <ConnectedEmailLimitUpdateModal
                    open={!!editEmailLimitModal}
                    editEmailLimitModal={editEmailLimitModal}
                    onClose={()=>{
                        setEditEmailLimitModal(null);
                    }}
                    setConnectedEmails={setConnectedEmails}
                    setEditEmailLimitModal={setEditEmailLimitModal}
                />
            }
        </PageWrapper>
    );
};

export default Index;