import styled from "styled-components";

export const PageWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
`;

export const PageHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0px 0px 10px 0px;
`;

export const StyledButton = styled.button`
    padding: 8px 15px;
    margin: 1.0933333333rem 0 .656rem 0;
    background: #00ff91;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color: #000;
    border: none;
    cursor: pointer;
    &:focus {
        background-color: #00ff91 !important;
    }
`;

export const StyledCancelButton = styled.button`
    color: #f4f5ec;
    display: inline-block;
    padding: 10px 15px 8px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    background: #c81e1e;
    border: 1px solid #c81e1e;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    &:focus {
        background: #c81e1e;
        border: 1px solid #c81e1e;
    }
`;

export const PageBody = styled.div`
    width: 100%;
`;

export const StyledTable = styled.table`
  border-radius: 0 !important;
`;

export const StyledTableHead = styled.thead`
 
`;

export const StyledTableRow = styled.tr`

`;

export const StyledTableCell = styled.td`
    padding-left: 10px;
`;

export const StyledTableBody = styled.tbody`
 
`;

export const StyledChip = styled.span`
    background-color: aquamarine;
    padding: 5px 10px;
    border-radius: 25px;
    font-size: 12px;
`;

export const StyledEmailAddCardContainer = styled.div`
    width: 480px;
    text-align: center;
`;

export const StyledEmailAddCard = styled.div`
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

export const StyledAddEmailInputContainer = styled.div`
    text-align: left;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #636671;
    padding: 24px 32px 6px;
`;

export const StyledAddEmailSubmitContainer = styled.div`
    padding: 8px 32px 26px;
    display: flex;
    gap: 10px;
`;

export const StyledAddEmailInput = styled.input`
    width: 100% !important;
    height: 48px !important;
    margin-top: 4px !important;
    margin-bottom: 7px !important;
    border: 1px solid #8D94A5 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    font-size: 16px !important;
    padding: 12px !important;
    line-height: 24px !important;
    font-weight: normal !important;
    &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }
`;

export const StyledConnectEmailButton = styled.button`
    color: #f4f5ec;
    display: inline-block;
    padding: 10px 15px 8px 15px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    background: #3c7ef3;
    border: 1px solid #3c7ef3;
    height: 48px;
    width: 100%;
    border-radius: 4px;
    margin-top: 10px;
    &:focus {
        background: #3c7ef3;
        border: 1px solid #3c7ef3;
    },
`;

export const StyledConnectEmailWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 30rem;
    margin: 0 auto;
`;

export const StyledConnectEmailContent = styled.div`
    text-align: center;
    h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
`;

export const StyledConnectEmailIconWrapper = styled.div`
    width: 80px;
    height: 80px;
    background-color: #3c7ef3;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto 25px auto;
    svg {
        font-size: 50px;
        color: #fff;
    }
`;