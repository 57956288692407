import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { resetUserCustomFieldState } from "../../actions/CustomFieldInsuranceAction";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../products/common/productSelector";
import { CUSTOM_FIELD_TAB_VALUE } from "../../constants/CoreConstants";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      gap: 20,
      width:'75%',

      "& .headerText": {
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "#525252",
          margin: 0,
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,

      "& .backButton": {
        width: 110,
      }
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { customFieldTabValue } = useSelector(customFieldInsuranceState);
  const customFieldDescription = "Custom fields are a user-defined fields that can be added to a contact record to store additional data"
  const mergeFieldDescription ="Merge fields can be used to personalize messages, such as bulk emails or text messages, or to send the same message to multiple recipients. Example: Merge fields can be used to automatically insert a client's first and last name, address, or phone number in an email template." 
  const standardFieldsDescription = "Standard Fields are the default system fields inside of our CRM. These can't be edited or deleted. To add new fields, click the Custom Fields tab"

  const getTitle = () => {
    switch (customFieldTabValue) {
      case CUSTOM_FIELD_TAB_VALUE.STANDARD_FIELDS:
        return "Standard Fields";
      case CUSTOM_FIELD_TAB_VALUE.MERGE_FIELDS:
        return "Merge Fields";
      default:
        return "Custom Fields";
    }
  };
  const getDescription = () => {
    switch (customFieldTabValue) {
      case CUSTOM_FIELD_TAB_VALUE.STANDARD_FIELDS:
        return standardFieldsDescription;
      case CUSTOM_FIELD_TAB_VALUE.MERGE_FIELDS:
        return mergeFieldDescription;
      case CUSTOM_FIELD_TAB_VALUE.CUSTOM_FIELDS:
        return customFieldDescription;
      default:
        return customFieldDescription; 
    }
  };
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/contacts/1.0.0/custom_fields_icon.png`}
          alt=""
          height={70}
        />

        <div className="headerText">
          <h5 className="title">{getTitle()}</h5>
          <p className="description">
          {getDescription()}
          </p>
        </div>
      </div>

      <div className="headerRight">
      <Link to="/user/settings">
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          className="backButton"
          onClick={()=> dispatch(resetUserCustomFieldState())}
        >
          Back
        </Button>
      </Link> 
      </div>
    </div>
  );
};

export default Header;