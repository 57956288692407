import React from "react";
import {
  AchievedIcon,
  GoalsStarIcon,
  TargetIcon,
} from "../../common/Icons";
import { styled } from "@material-ui/core";
import GoalItem from "./GoalItem";
import Utils from "../../../../helpers/Utils";

const GoalListWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
}));

const GoalList = ({goalData}) => {
  const formatCurrency = (value) => {
      return "$" + Utils.abbreviateNumber(value) || "0.00";
  };

  const handleValue = (key) => {
    return goalData[key] || 0;
  };

  return (
    <GoalListWrapper className="goalList">
      <GoalItem
        text="STARTED TODAY AT"
        value={formatCurrency(handleValue("startedTodayAt"))}
        icon={<GoalsStarIcon />}
        bgColor={"#ECF4FF"}
      />
      <GoalItem
        text="SHOULD BE AT"
        value={formatCurrency(handleValue("shouldBeAt"))}
        icon={<TargetIcon />}
        bgColor={"#EEFFF5"}
      />
      <GoalItem
        text="GOAL FOR TODAY"
        value={formatCurrency(handleValue("shouldBeAt"))}
        icon={<AchievedIcon />}
        bgColor={"#F4F6FB"}
      />
    </GoalListWrapper>
  );
};

export default GoalList;
