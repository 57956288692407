import {createContext, useReducer} from "react";
import ActionType from "./ActionTypes";

export const LeadPermissionRequestContext = createContext({});

const leadPermissionRequestStates = {
    isLoading: true,
    leadPermissionRequests: [],
    subUserList: [],
    totalRecords: 0,
    totalPage: 0,
    perPage: 10,
    page: 1
};


const leadPermissionRequestReducer = (state,action) => {
    switch (action.type){
        case ActionType.SET_LEAD_PERMISSION_REQUESTS:
            return { ...state, leadPermissionRequests: action.payload };
        case ActionType.UPDATE_PER_PAGE:
            return { ...state, perPage: action.payload };
        case ActionType.UPDATE_PAGE:
            return { ...state, page: action.payload };
        case ActionType.UPDATE_TOTAL_PAGE:
            return { ...state, totalPage: action.payload };
        case ActionType.UPDATE_TOTAL_RECORDS:
            return { ...state, totalRecords: action.payload };
        case ActionType.UPDATE_TABLE_DATA:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const LeadPermissionRequestProvider = ({children}) => {
    const [state, dispatch] = useReducer(leadPermissionRequestReducer, leadPermissionRequestStates);
    return (
        <LeadPermissionRequestContext.Provider value={{state, dispatch}}>
            {children}
        </LeadPermissionRequestContext.Provider>
    )
};