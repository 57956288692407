import React from "react";
import { makeStyles } from "@material-ui/core";
import Header from "./Header";
import MainContent from "./MainContent";


const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 120px)",
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));


const PermissionSettings = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <MainContent />
    </div>
  );
};

export default PermissionSettings;
