import React from 'react';
import {  Typography, } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const CutOffTimeSkeleton = () => {
  return (
    <div>
      <Typography variant="h4" paragraph>
        <Skeleton variant="text" width="100%" />
      </Typography>
      <Typography variant="h4" paragraph>
        <Skeleton variant="text" width="70%" />
      </Typography>
      <Typography variant="body1" paragraph>
        <Skeleton variant="text" width="80%" />
      </Typography>
      <div style={{ marginTop: 16 }}>
        <Skeleton variant="rect" height={40} />
        <Skeleton variant="rect" height={56} style={{ marginTop: 16 }} />
        <div style={{ marginTop: 16 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rect" height={56} style={{ marginTop: 16 }} />
        </div>
        <div style={{ marginTop: 16 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rect" height={56} style={{ marginTop: 16 }} />
        </div>
        <div style={{ marginTop: 16 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rect" height={56} style={{ marginTop: 16 }} />
        </div>
      </div>
    </div>
  );
};

export default CutOffTimeSkeleton;
