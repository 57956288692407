import React from 'react';
import { Box } from '@material-ui/core';
import ProductHeader from './ProductHeader';
import ProductBody from './productBody';

const Content = () => {
    return (
        <Box flex={1} sx={{background:'white',borderRadius:'8px'}}>
            <ProductHeader />
            <ProductBody />
        </Box>
    );
};

export default Content;