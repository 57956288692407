import React, { Fragment, useEffect, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddVirtualNumberPopup from "../AddVirtualNumberPopup";
import VirtualNumberEdit from "./VirtualNumberEdit";
import CallRecordingDisclosureEdit from "./CallRecordingDisclosureEdit";
import ForwardNumberEdit from "./ForwardNumberEdit";
import RegisterShakenStirView from "./RegisterShakenStirView";
import CallWhisperEdit from "./CallWhisperEdit";
import VoicemailMessageEdit from "./VoicemailMessageEdit";
import IncomingCallTimeout from "./IncomingCallTimeout";
import SwitchC from "../../customField/subComponents/Switch";
import VirtualNumberTableSkeleton from ".././VirtualNumberTableSkeleton";
import {
  deleteVirtualNumber,
  getUserCallRecordingSettingApi,
  getVirtualNumberList,
  updateUserCallRecordingSettingApi,
  virtualNumberCallRecordSetting,
  virtualNumberUpdateDialerSetting,
  virtualNumberUpdateForwardSetting,
} from "../../../api/profileApi";
import { useIsMounted } from "../../../hooks/IsMounted";
import CustomConfirmAlertMod from "../../customField/custom-field-v2/helpers/confirm-alert-modified/CustomConfirmAlertMod";
import "./VirtualNumberV2.css";
import { DeleteForever } from "@material-ui/icons";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import Utils from "../../../helpers/Utils";
import { markStepComplete } from "../../../api/onboardApi";
import useQueryParam from "../../../hooks/useQueryParam";
import CustomDynamicModal from "../../invoiceSettings/common/modal/CustomDynamicModal";

const SELECT_BEFORE_DIAL = "1";
const FIXED_DIAL_NUMBER = "2";

const VirtualNumbersV2 = (props) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const useStyles = makeStyles((theme) => ({
    tableContainer: {
      maxHeight: "550px",

      width: sideBarOpen ? "calc(100vw - 220px)" : "calc(100vw - 120px)",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "4px",
      },
    },
    table: {
      width: "100%",
    },
    tableBody: {
      height: "calc(100vh-100px)",
      overflowY: "auto",
    },
    tableHeader: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      backgroundColor: "#e5e8ef",
    },
    tableHead: {
      fontSize: '15px !important',
      fontWeight: '600',
      backgroundColor: "#e5e8ef",
    }
  }));
  const sortType = "asc";
  const sortBy = "created_at";
  const callRecordingCharge =
    props.data.call_record_charge &&
      typeof props.data.call_record_charge !== "undefined"
      ? props.data.call_record_charge
      : 0
    ;
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [query, setQuery] = useState("");
  const [reset, setReset] = useState(0);
  const [addNewModalVisible, setAddNewModalVisible] = useState(false);
  const [callForwarding, setCallForwarding] = useState(
    props.data.setting && typeof props.data.setting.forward !== "undefined"
      ? props.data.setting.forward
      : "2"
  );
  const [callRecording, setCallRecording] = useState(
    props.data.call_record_setting &&
      typeof props.data.call_record_setting.value !== "undefined" &&
      parseInt(props.data.call_record_setting.value)
      ? 1
      : 0
  );
  const [dialFrom, setDialFrom] = useState(
    props.data.setting &&
      typeof props.data.setting["dialer-selection"] !== "undefined"
      ? props.data.setting["dialer-selection"]
      : FIXED_DIAL_NUMBER
  );
  const [number, setNumber] = useState(
    props.data.setting &&
      typeof props.data.setting["dialer-number"] !== "undefined"
      ? props.data.setting["dialer-number"]
      : null
  );
  const [userCallRecording, setUserCallRecording] = useState("1");
  const [savingUserCallRecording, setSavingUserCallRecording] = useState(false);

  const [canRegisterShakenStir, setCanRegisterShakenStir] = useState(false);
  const classes = useStyles();
  const [openTab] = useQueryParam("open-tab");
  const [from] = useQueryParam("from");
  const [openBuyVirtualNumberModal, setOpenBuyVirtualNumberModal] = useState(false);

  useEffect(() => {
    getUserCallRecordSetting();

    if (openTab === "BUY_NUMBER") {
      window.buyVirtualNumberModal(true, {
        skipGetStarted: true,
        callback: async (res) => {
          if (res) {
            setReset(reset + 1);

            if (from === "onboard") {
              const onboardComplete = await markStepComplete({ key: "GET_VIRTUAL_NUMBER" });
              if (onboardComplete?.success) {
                Utils.removeQueryParam(["from", "open-tab"]);
              }
            }
          }
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [currentPage, reset, perPage, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  // debouncing
  useEffect(() => {
    if (isMounted) {
      const timer = setTimeout(() => {
        setDebouncedQuery(query);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dialFrom === FIXED_DIAL_NUMBER && props.data.numbers) {
      const firstNumber = Object.keys(props.data.numbers)[0] || null;

      if (number) {
        const isMatch = Object.keys(props.data.numbers).some((key) => key.includes(number));
        if (!isMatch && firstNumber) {
          setNumber(firstNumber);
          updateDialFromData(dialFrom, firstNumber);
        }
      } else if (firstNumber) {
        setNumber(firstNumber);
        updateDialFromData(dialFrom, firstNumber);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateDialFromData = (dialFromValue, numberValue) => {
    if (dialFromValue === FIXED_DIAL_NUMBER && !numberValue) {
      window.showNotification("ERROR", "Please Select a number");
      return false;
    }

    let form = {
      "dialer-selection": dialFromValue,
      "dialer-number": numberValue,
    };

    virtualNumberUpdateDialerSetting({
      form_data: form,
    })
      .then((response) => {
        if (response.data.status === "success") {
          window.showNotification("SUCCESS", response.data.message);
        } else {
          window.showNotification("ERROR", response.data.message);
        }
      })
      .catch((error) => {
        window.showNotification("ERROR", "Something went wrong");
      });

  };

  const getUserCallRecordSetting = () => {
    getUserCallRecordingSettingApi()
      .then((res) => {
        const response = res.data;
        if (response.success === "success") {
          setUserCallRecording(response.message.value);
        }
      })
      .catch((error) => {
        console.log(
          "Profile module > VirtualNumbers > getUserCallRecordSetting",
          error
        );
      });
  };

  const loadData = () => {
    setLoading(true);

    getVirtualNumberList({
      form_data: {
        page: currentPage + 1,
        per_page: perPage,
        sort_by: sortBy,
        sort_type: sortType,
        query: debouncedQuery,
      },
    })
      .then((response) => {
        if (
          response.data.canRegisterShakenStir !== undefined &&
          response.data.canRegisterShakenStir === true
        ) {
          if (props.registrationType && props.registrationType === "SOLE_PROPRIETOR") {
            setCanRegisterShakenStir(false);
          } else {
            setCanRegisterShakenStir(true);
          }
        }
        setData(response.data.data.data);
        setTotal(response.data.data.total);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {


    checkBodyClassName();

    const observer = new MutationObserver(() => {
      checkBodyClassName();
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const checkBodyClassName = () => {
    const bodyClassName = document.querySelector("body").className;

    if (bodyClassName.includes("has-fixed-sidenav")) {
      setSideBarOpen(true);
    }

    if (bodyClassName.includes("active__minimal_sidebar")) {
      setSideBarOpen(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };


  const changeCallSetting = (e) => {
    // let value = props.data.call_record_setting && typeof props.data.call_record_setting.value !== 'undefined' && parseInt(props.data.call_record_setting.value) ? 0 : 1;
    let changeValue = callRecording ? 0 : 1;
    CustomConfirmAlertMod({
      onSubmit: () => {
        virtualNumberCallRecordSetting({
          form_data: {
            value: changeValue,
          },
        })
          .then((response) => {
            if (response.data.status === "success") {
              setCallRecording(changeValue);
              window.showNotification("SUCCESS", response.data.message);
            } else {
              window.showNotification("ERROR", response.data.message);
            }
          })
          .catch((error) => {
            window.showNotification("ERROR", "Something went wrong");
          });
      },
      title: `Would you like to ${changeValue === 1 ? "turn on" : "turn off"
        } call recording?`,
      description:
        changeValue === 1
          ? callRecordingCharge !== 0
            ? `You will be charged an extra $${callRecordingCharge} per minute if you enable call recording.`
            : ""
          : "It will disable call record when make a call to contact.",
      cancelText: "Cancel",
      submitText: "Yes, Confirm",
    });
  };

  const updateForwardNumber = () => {
    let newSetting = callForwarding.toString() === "1" ? "2" : "1";
    setCallForwarding(newSetting);

    let form = {
      forward: newSetting,
    };

    virtualNumberUpdateForwardSetting({
      form_data: form,
    })
      .then((response) => {
        if (response.data.status === "success") {
          window.showNotification("SUCCESS", response.data.message);
        } else {
          setCallForwarding(newSetting.toString() === "1" ? "2" : "1");
          window.showNotification("ERROR", response.data.message);
        }
      })
      .catch((error) => {
        window.showNotification("ERROR", "Something went wrong");
      });
  };

  const updateUserCallRecording = () => {
    if (savingUserCallRecording) {
      return;
    }
    setSavingUserCallRecording(true);
    setUserCallRecording(userCallRecording === "1" ? "0" : "1");
    const previous_value = userCallRecording;
    updateUserCallRecordingSettingApi({
      value: userCallRecording === "1" ? "0" : "1",
    })
      .then((res) => {
        const response = res.data;
        if (response.success === "error") {
          setUserCallRecording(previous_value);
        }
        setSavingUserCallRecording(false);
      })
      .catch((error) => {
        console.log(
          "Profile module > VirtualNumbers > updateUserCallRecording",
          error
        );
        setSavingUserCallRecording(false);
      });
  };

  const handleVirtualNumberAddModal = (needToReload = false) => {
    if (needToReload) {
      setReset(reset + 1);
    }
    setAddNewModalVisible(false);
  };

  const deleteNumber = (id) => {
    NewConfirmAlert({
      onSubmit: () => {
        deleteVirtualNumber({
          form_data: {
            type: "single",
            id: id,
          },
        })
          .then((response) => {
            if (response.data.status === "success") {
              window.showNotification("SUCCESS", response.data.message);
              setReset(reset + 1);
              try {
                window.updateVirtualNumberList();
              } catch (error) {
                console.error('on delete updateVirtualNumberList', error);
              }
            } else {
              window.showNotification("ERROR", response.data.message);
            }
          })
          .catch((error) => {
            window.showNotification(
              "ERROR",
              "Data delete failed. Submit the form again"
            );
          });
      },
      title: "Are You Sure?",
      description: "You will not be able to recover this data. Do you want to remove this number?",
      cancelText: 'No, Keep it',
      submitText: 'Yes, Delete it',
      width: "480px",
    });
  };

  const handleDialFromChange = (dialFromValue) => {
    setDialFrom(dialFromValue);

    if (dialFromValue !== FIXED_DIAL_NUMBER || number) {
      updateDialFromData(dialFromValue, number);
      return;
    }

    const firstNumber = props.data.numbers ? Object.keys(props.data.numbers)[0] : null;

    if (firstNumber) {
      setNumber(firstNumber);
      updateDialFromData(dialFromValue, firstNumber);
    } else {
      updateDialFromData(dialFromValue, number);
    }
  }

  const handleChangeNumber = (numberValue) => {
    setNumber(numberValue);
    updateDialFromData(dialFrom, numberValue);
  }
  const handlePermission = () => {
    setOpenBuyVirtualNumberModal(false);
    window.buyVirtualNumberModal(true, {
      skipGetStarted: true,
      callback: async (res) => {
        if (res) {
          setReset(reset + 1);

          if (openTab === "BUY_NUMBER" && from === "onboard") {
            const onboardComplete = await markStepComplete({
              key: "GET_VIRTUAL_NUMBER",
            });
            if (onboardComplete?.success) {
              Utils.removeQueryParam(["from", "open-tab"]);
            }
          }
        }
      },
    });
  };

  return (
    <Fragment>
      <div style={{ zIndex: -1 }}>
        <div
          // className="container"
          style={{
            padding: "16px",
            backgroundColor: "#f1f1f1",
            borderRadius: "4px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
            display: "flex",
            alignItems: "center",
            height: "120px",
          }}
        >
          <p>
            <label>
              <h6>Dial From</h6>
              <RadioGroup
                row
                value={dialFrom}
                onChange={(e) => handleDialFromChange(e.target.value)}
              >
                <FormControlLabel
                  value={SELECT_BEFORE_DIAL}
                  control={<Radio />}
                  label="Select before dial"
                />
                <FormControlLabel
                  value={FIXED_DIAL_NUMBER}
                  control={<Radio />}
                  label="Fixed dial number"
                />
              </RadioGroup>
            </label>
          </p>
          {dialFrom === FIXED_DIAL_NUMBER && props.data.numbers[0] && (
            <p>
              <label>
                <p>Select a number</p>
                <Select
                  style={{ width: "200px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={number}
                  onChange={(e) => handleChangeNumber(e.target.value)}
                  variant="outlined"
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}
                >
                  {props.data.setting &&
                    Object.entries(props.data.numbers).map(
                      ([key, value], i) => (
                        <MenuItem key={i} value={key}>
                          {value}
                        </MenuItem>
                      )
                    )}
                </Select>
              </label>
            </p>
          )}
        </div>

        <div className="custom-container">
          <div className="flex-end">
            <div className="announcement">
              <span className="switch-box">Call Recording Announcement</span>
              <SwitchC
                status={userCallRecording === "1"}
                brandColor={"#f94f72"}
                onChange={updateUserCallRecording}
              />
            </div>

            <div className="announcement">
              <span className="switch-box">Call Forwarding</span>
              <SwitchC
                status={callForwarding.toString() === "1"}
                brandColor={"#f94f72"}
                onChange={updateForwardNumber}
              />
            </div>

            <div className="announcement">
              <span className="switch-box">Call Recording</span>
              <SwitchC
                status={callRecording}
                brandColor={"#f94f72"}
                onChange={changeCallSetting}
              />
            </div>

            {props.data.numberLimit && (
              <a href="#!" className="buy-additional" onClick={(e)=>{
                e.preventDefault();
                setOpenBuyVirtualNumberModal(true);
                }}>
                <span style={{ paddingRight: "10px" }}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="10" cy="10" r="10" fill="white"></circle>
                    <path
                      d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                      className={"accent--fill--color"}
                    ></path>
                  </svg>
                </span>
                Buy additional phone number
              </a>
              
            )}
            {
              openBuyVirtualNumberModal && (
                <CustomDynamicModal
                  open={openBuyVirtualNumberModal}
                  onClose={()=> setOpenBuyVirtualNumberModal(false)}
                  headerIconSrc={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
                  headerTitle={"Notice"}
                  closeButtonText={"No"}
                  submitButtonText={"Yes"}
                  handleSubmit={handlePermission}
                >
                    <Typography style={{
                      height: "unset !important",
                      color: "#133159",
                      fontWeight: 500,
                      fontSize: "15px",
                      textAlign:'center',
                    }}>
                      {`Additional phone numbers are charged at a rate of $${parseFloat(
                      props.data.buyPrice.replace(/,/g, "")
                      ).toFixed(2)}/month. Do you wish to continue?`}
                  </Typography>
                </CustomDynamicModal>
              )
            }
          </div>
        </div>

        <div className="stir-box">
          <div className="flex-space-between">
            <Typography>Phone Numbers</Typography>
            <a
              style={{
                visibility:
                  canRegisterShakenStir === true ? "visible" : "hidden",
                color: "#007bff",
                textDecoration: "none",
              }}
              target="_blank"
              download=""
              href="https://s3.us-east-1.amazonaws.com/pypepro/contact/27567256/SRH4WAqAZ5RoKYDk3IduoNTmKPxTQcDM3x5CYYgZ.pdf"
              rel="noreferrer"
            >
              Shaken STIR, CNAM registration Help Doc
            </a>

            <div style={{ position: "relative" }}>
              <input
                type="text"
                placeholder="Search Number"
                style={{
                  padding: "8px 16px",
                  borderRadius: "12px",
                  border: "1px solid",
                  fontSize: "14px",
                  width: "240px",
                }}
                onChange={(e) => setQuery(e.target.value)}
              />
              <div className="absolute-center-right">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10./0629 5.94625 10.0629Z"
                    fill="#133159"
                    fillOpacity="0.65"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} width="14%">Number</TableCell>
                  <TableCell className={classes.tableHead} width="14%">Forward Number</TableCell>
                  <TableCell className={classes.tableHead} width="13%">Shaken/Stir, CNAM</TableCell>
                  <TableCell className={classes.tableHead} width="14%">Call Whisper Message</TableCell>
                  <TableCell className={classes.tableHead} width="14%">Voicemail Message</TableCell>
                  <TableCell className={classes.tableHead} width="12%">Incoming Call Timeout</TableCell>
                  <TableCell className={classes.tableHead} width="14%">Call Recording Disclosure</TableCell>
                  <TableCell className={classes.tableHead} width="14%">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <VirtualNumberTableSkeleton />
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <VirtualNumberEdit
                          item={row}
                          updateTable={() => setReset(reset + 1)}
                        />
                      </TableCell>
                      <TableCell>
                        <ForwardNumberEdit item={row} />
                      </TableCell>
                      <TableCell>
                        <RegisterShakenStirView
                          item={row}
                          updateTable={() => setReset(reset + 1)}
                          canRegisterShakenStir={canRegisterShakenStir}
                        />
                      </TableCell>
                      <TableCell>
                        <CallWhisperEdit item={row} />
                      </TableCell>
                      <TableCell>
                        <VoicemailMessageEdit item={row} />
                      </TableCell>
                      <TableCell>
                        <IncomingCallTimeout item={row} />
                      </TableCell>
                      <TableCell>
                        <CallRecordingDisclosureEdit item={row} />
                      </TableCell>
                      <TableCell style={{ textAlign: 'center' }}>

                        <IconButton onClick={() => deleteNumber(row.id)}>
                          {/* <Clear style={{color:'red'}} fontSize="medium" /> */}
                          <DeleteForever style={{ color: 'red', cursor: 'pointer' }} fontSize="medium" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={perPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      {addNewModalVisible && (
        <AddVirtualNumberPopup
          isOpen={addNewModalVisible}
          handleModal={handleVirtualNumberAddModal}
        />
      )}
    </Fragment>
  );
};

export default VirtualNumbersV2;
