import React, { useEffect, useState } from 'react';
import {
  StyledTable,
  StyledTableBody,
  StyledTableRow,
  StyledTableCell,
  StyledButton,
  StyledTableHead,
  PageWrapper,
  PageHeader
} from '../components/emailSettings/connectedEmails/ConnectedEmailsStyles';
import NewConfirmAlert from '../components/activity/new-alert/NewConfirmAlert';
import {  deleteGoogleAccountApi, googleAccountApi } from '../api/profileApi';
import { Link } from 'react-router-dom';
import '../components/profile/profile.css';
import { Backdrop, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { BackIcon } from '../components/ten-dlc/helpers/Icon';
import ConnectedGoogleAccountSkeleton from './ConnectedGoogleAccountSkeleton';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    color: 'black',
    
  },
  text: {
    marginTop: '50px',
  },
}));

const ConnectedGoogleAccount = () => {
  
  const [data,setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const classes = useStyles();

  const fetchGoogleAccount = async () => {
    try {
      setLoading(true);
      const resp = await googleAccountApi();
      if(resp?.success){
        setData(resp.data);
      }
    } catch (err) {
      window.showNotification("ERROR", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    fetchGoogleAccount();
  }, []);

  const handleDisconnect = (accountId) => {
    NewConfirmAlert({
      onSubmit: async () => {
        
        try {
          setBackDropOpen(true); 
          const resp = await deleteGoogleAccountApi({accountId});
          if (resp && resp.success) {
            setData(data.filter(item => item.accountId !== accountId));
            window.showNotification("SUCCESS", resp.message);
          } else {
            window.showNotification("ERROR", resp.message);
          }
        } catch (err) {
          window.showNotification("ERROR", "Something Went Wrong");
        } finally {
          setBackDropOpen(false);
        }
      },
      title: "Are You Sure?",
      description: "Are you sure you want to disconnect this email address?",
      cancelText: "Cancel",
      submitText: "Disconnect",
      width: "480px",
    });
  };

  const handleClose = () => {
    setBackDropOpen(false);
  };

  return (
    <div>
      <PageWrapper>
        <PageHeader>
          <div>
            <h5>Connected Google Accounts</h5>
            <p>
              <b>These google accounts are used for importing contacts from spreadsheets.</b>
            </p>
          </div>
          {
          
          <Link to="/user/settings" style={{width:'100px',height:'40px', padding:'8px 15px', background:'#7B24FF', display:'flex', alignitems:'center', gap:'16px',color:'#fff', borderRadius:'5px'}}>
              <span>
                  <BackIcon/>
              </span>
              <span>Back</span>
          </Link>
          }
        </PageHeader>
      </PageWrapper>
      {loading ? <ConnectedGoogleAccountSkeleton/> : 
       ( 
      <StyledTable>
        <StyledTableHead style={{ background: '#f0efff',color: 'black',fontSize: '20px'}}>
          <StyledTableRow>
            <StyledTableCell style={{ width: '80%' }}>Email</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>Action</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>
        <StyledTableBody>
          {
            data.map((connectedEmail, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{connectedEmail.email}</StyledTableCell>
                <StyledTableCell>
                  <StyledButton style={{ fontSize: '14px', padding: '6px 10px' }} onClick={() => handleDisconnect(connectedEmail.accountId)}>
                    Disconnect
                  </StyledButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </StyledTableBody>  
      </StyledTable>
    )}
      <Backdrop
        open={backDropOpen}
        onClick={handleClose}
        className={classes.backdrop}
      >
       <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
       <CircularProgress color="inherit" />
       <Typography className={classes.text}>Disconnecting...</Typography>
       </div>
      </Backdrop>
    </div>
  );
};

export default ConnectedGoogleAccount;
