import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: "774px",
    maxHeight: "500px",
    width: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "5px 10px 5px 15px",
  },
  headerTite: {
    fontSize: "20px",
    fontWeight: 600,
    color: "#344767",
  },
  text: {
    fontSize: "16px",
    color: "#344767",
  },
  image: {
    width: 80,
    height: "auto",
    marginRight: theme.spacing(2),
  },
  modalContentBody: {
    display: "flex",
    flexDirection: "column",
    minHeight: "144px",
    width: "100%",
    padding: "30px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 10,
    padding: "30px",
  },
  confirmButton: {
    color: "#fff",
    fontWeight: 600,
    width:"100px",
    backgroundColor: " #4550f8",
    borderRadius: " 5px",
    border: "2px solid #4550f8 ",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.4)",
    "&:focus": {
      backgroundColor: "#4550f8 !important",
    },
    "&:hover": {
      backgroundColor: "#4550f8 !important",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.7)",
    },
  },
  cancelButton: {
    color: "#000",
    fontWeight: 600,
    backgroundColor: "#ffffff",
    borderRadius: " 5px",
    border: "2px solid #aeb1b7",
    "&:focus": {
      backgroundColor: "#ffffff !important",
    },
    "&:hover": {
      backgroundColor: "#ffffff !important",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
    },
  },
}));

const ConnectEmailCalenderSyncModal = ({ handleCancel, image }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.modalContent}>
        <Box className={classes.header}>
            {image && (
            <img src={image} alt="Calender Sync" className={classes.image} />
            )}
            <div>
            <Typography className={classes.headerTite}>
                Calendar Events
            </Typography>
            <Typography className={classes.text}>
                Sync up your calendar to stay organized.
            </Typography>
            </div>
        </Box>
        <Divider />
        <Box className={classes.modalContentBody}>
            <Typography className={classes.text} style={{ marginBottom: "20px" }}>
            We can synchronize the calendar events associated with your connected
            email account. This will allow the events to show up inside of our
            platform.
            </Typography>
            <Typography className={classes.text}>
            Would you like to synchronize your calendar events?
            <br />
            (recommended)
            </Typography>
        </Box>
        <Box className={classes.footer}>
            <Button
            className={classes.confirmButton}
            onClick={() => {
                history.push("/user/profile/calendar-sync");
            }}
            >
            Yes
            </Button>
            <Button
            onClick={handleCancel}
            className={classes.cancelButton}
            >
            Not Now
            </Button>
        </Box>
    </Box>
  );
};

export default ConnectEmailCalenderSyncModal;
