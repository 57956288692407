import { useEffect, useState } from "react";
import { deleteCallOutcome, getCallOutcomeList } from "../../api/profileApi";
import { useIsMounted } from '../../hooks/IsMounted';
import CallOutcomePopup from './CallOutcomePopup';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../leadSource/lead_source.css';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Utils from "../../helpers/Utils";
import CustomConfirmAlert from "../team/CustomConfirmAlert";
import CallOutcomesSkeleton from "./Skeleton/CallOutcomesSkeleton";

const paginationIcon = {
    previous: <svg width="14" height="14" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.50171 0.248609C8.79377 0.248039 9.07682 0.349756 9.30171 0.536108C9.42828 0.641045 9.53291 0.769922 9.6096 0.915356C9.68629 1.06079 9.73353 1.21992 9.74863 1.38364C9.76372 1.54737 9.74637 1.71246 9.69757 1.86946C9.64876 2.02646 9.56946 2.1723 9.46421 2.29861L3.86421 8.99861L9.26421 15.7111C9.36804 15.839 9.44558 15.9861 9.49237 16.144C9.53916 16.3019 9.55427 16.4676 9.53685 16.6313C9.51943 16.7951 9.46981 16.9539 9.39084 17.0984C9.31187 17.2429 9.20511 17.3705 9.07671 17.4736C8.94737 17.5874 8.79592 17.6732 8.63184 17.7257C8.46776 17.7782 8.2946 17.7962 8.12324 17.7786C7.95187 17.761 7.78599 17.7082 7.63602 17.6234C7.48604 17.5387 7.3552 17.4238 7.25171 17.2861L1.21421 9.78611C1.03035 9.56244 0.929847 9.28189 0.929847 8.99236C0.929847 8.70283 1.03035 8.42227 1.21421 8.19861L7.46421 0.69861C7.5896 0.547338 7.7489 0.427757 7.92916 0.349571C8.10942 0.271387 8.30558 0.236795 8.50171 0.248609Z" fill="white"/>
    </svg>,
    backwardSlash : <svg width="21" height="37" viewBox="0 0 21 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="19.7058" y1="0.470588" x2="0.88231" y2="35.7647" stroke="#3C7EF3" strokeWidth="2"/>
    </svg>,
    next: <svg width="14" height="14" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.32251 17.7514C1.03045 17.752 0.747402 17.6502 0.522512 17.4639C0.395939 17.359 0.291312 17.2301 0.214622 17.0846C0.137932 16.9392 0.0906866 16.7801 0.0755918 16.6164C0.0604969 16.4526 0.0778495 16.2875 0.126655 16.1305C0.175461 15.9735 0.25476 15.8277 0.360012 15.7014L5.96001 9.00139L0.560013 2.28889C0.456181 2.16103 0.378641 2.01391 0.331851 1.85599C0.285062 1.69806 0.269944 1.53245 0.287368 1.36866C0.304792 1.20488 0.354413 1.04615 0.433381 0.901604C0.512348 0.757058 0.619104 0.629544 0.747513 0.52639C0.876845 0.412595 1.0283 0.32676 1.19238 0.274274C1.35646 0.221788 1.52961 0.203783 1.70098 0.221389C1.87235 0.238995 2.03822 0.291833 2.1882 0.376585C2.33818 0.461337 2.46902 0.576175 2.57251 0.713891L8.61001 8.21389C8.79387 8.43756 8.89437 8.71811 8.89437 9.00764C8.89437 9.29717 8.79387 9.57773 8.61001 9.80139L2.36001 17.3014C2.23462 17.4527 2.07532 17.5722 1.89506 17.6504C1.7148 17.7286 1.51865 17.7632 1.32251 17.7514Z" fill="white"/>
    </svg>
}


const CallOutcome = () => {
    const sortType = 'asc';

    const history = useHistory();
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [editCallOutcome, setEditCallCome] = useState(null);


    useEffect(() => {
        Utils.scrollToTop();
    },[]);

    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


    const deleteData = (_id) => {
        setLoading(true);
        deleteCallOutcome({
            "form_data": {
                id: _id
            }
        })
        .then(response => {
            setReset(reset + 1);
        }).finally(() => {
            setLoading(false);
        });
    }

    const loadData = () => {
        setLoading(true);
        getCallOutcomeList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: 'outcome',
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {
            setData(response.data.data.data);
            setTotal(response.data.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if(newPage === null){
            return;
        }
        setCurrentPage(newPage-1);
    };


    const handlePerPageChange = (event) => {
		setPerPage(event.target.value);
	}

    const renderPagination = () => {


        let previous_page = null, next_page = null; 
        let totalPages = Math.ceil(total / perPage);
        let _currentPage = currentPage + 1;
        if(_currentPage > 1){
            previous_page = _currentPage - 1
        }
    
        // eslint-disable-next-line eqeqeq
        if(_currentPage != totalPages) {
            next_page = _currentPage + 1
        }
    
        // eslint-disable-next-line eqeqeq
        if(total == 0){
            return false;
        }
    
        return(
            <div className="pagination_bar">
                <ul>
                    <li>
                        <a href="#!" onClick={e => handleChangePage(e, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {paginationIcon.previous}
                        </a>
                    </li>
                    <li>
                        <a href="#!" onClick={e => e.preventDefault()}>
                            <span>{_currentPage}</span>
                        </a>
                    </li>
                    <li>
                        <a href="#!" onClick={e => e.preventDefault()} className="d-flex align-items-center"> 
                            <span>
                                {paginationIcon.backwardSlash}
                            </span>	
                            {totalPages}
                        </a>
                    </li>
                    <li>
                        <a href="#!" onClick={(e) => handleChangePage(e, next_page)}
                            style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
                        >
                            {paginationIcon.next}	
                        </a>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total Outcome : {total}</li>
                </ul>
            </div>
        )
    }

    const renderData = () => {
        if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="2">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => (
                <tr>
                    <td width="80%" style={{ paddingLeft: "10px", paddingRight: "2px" }}>{item.outcome}</td>
                    <td width="20%" style={{ paddingLeft: "10px", paddingRight: "2px", textAlign: "center" }}>
                        <a href="#!" className="edit mr-2" onClick={(e) => {
                            e.preventDefault();
                            setEditCallCome(item);
                            setModalVisible(true);
                        }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.08573 2.91356L13.0862 6.91425L4.39929 15.6016L0.832505 15.9953C0.355018 16.0482 -0.0484094 15.6444 0.00471426 15.1669L0.401579 11.5975L9.08573 2.91356ZM15.5606 2.31793L13.6822 0.43946C13.0962 -0.146487 12.146 -0.146487 11.56 0.43946L9.7929 2.20668L13.7934 6.20737L15.5606 4.44015C16.1465 3.85389 16.1465 2.90388 15.5606 2.31793Z" fill="#133159"></path>
                            </svg>
                            Edit
                        </a>
                        <a href="#!" className="delete" onClick={(e) => {
                            e.preventDefault();
                            CustomConfirmAlert({
                                handleConfirm: () => {
                                    deleteData(item.id);
                                },
                                callConfirmLast: true,
                                title: 'Are you sure?',
                                description: 'If you delete this call outcome you will not be able restore it again',
                                type_word: 'DELETE OUTCOME',
                                icon: 'question',
                                closeButtonText: 'Cancel',
                                confirmButtonText: 'Delete'
                            });
                            
                        }}>
                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.7497 1.99995H8.99979V1.49997C8.99979 0.671547 8.32824 0 7.49983 0H5.49987C4.67145 0 3.99991 0.671547 3.99991 1.49997V1.99995H1.24997C0.559643 1.99995 0 2.5596 0 3.24992V4.2499C0 4.52605 0.223839 4.74989 0.499988 4.74989H12.4997C12.7759 4.74989 12.9997 4.52605 12.9997 4.2499V3.24992C12.9997 2.5596 12.4401 1.99995 11.7497 1.99995ZM4.99988 1.49997C4.99988 1.22435 5.22425 0.999977 5.49987 0.999977H7.49983C7.77544 0.999977 7.99981 1.22435 7.99981 1.49997V1.99995H4.99988V1.49997Z" fill="#FF264A"></path>
                                <path d="M0.949209 5.75C0.859992 5.75 0.7889 5.82456 0.79315 5.91368L1.20564 14.571C1.24376 15.3723 1.90187 15.9998 2.70373 15.9998H10.2967C11.0985 15.9998 11.7566 15.3723 11.7948 14.571L12.2073 5.91368C12.2115 5.82456 12.1404 5.75 12.0512 5.75H0.949209ZM8.50016 6.99997C8.50016 6.72373 8.7239 6.49998 9.00015 6.49998C9.27639 6.49998 9.50014 6.72373 9.50014 6.99997V13.4998C9.50014 13.7761 9.27639 13.9998 9.00015 13.9998C8.7239 13.9998 8.50016 13.7761 8.50016 13.4998V6.99997ZM6.00022 6.99997C6.00022 6.72373 6.22396 6.49998 6.5002 6.49998C6.77645 6.49998 7.00019 6.72373 7.00019 6.99997V13.4998C7.00019 13.7761 6.77645 13.9998 6.5002 13.9998C6.22396 13.9998 6.00022 13.7761 6.00022 13.4998V6.99997ZM3.50027 6.99997C3.50027 6.72373 3.72402 6.49998 4.00026 6.49998C4.27651 6.49998 4.50025 6.72373 4.50025 6.99997V13.4998C4.50025 13.7761 4.27651 13.9998 4.00026 13.9998C3.72402 13.9998 3.50027 13.7761 3.50027 13.4998V6.99997Z" fill="#FF264A"></path>
                            </svg>
                            Delete
                        </a>
                    </td>
                </tr>
            ))
        }
    }

    return (
        <div className="settings_section">
            <div className="settings_content">
                
                <div id="call_outcome" className="col s12 active">
                    <div className="heading">
                        <h5>Call Outcome <span>list</span></h5>
                        <div className="add_new_user d-flex">
                            <button type="submit" className="btn_submit accent--bg--text--color mr-2" onClick={() => setModalVisible(true)}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10"></circle>
                                    <path d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z" className={"accent--fill--color"}></path>
                                </svg>
                                Add New Call Outcome
                            </button>
                            {
                                history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                                <Link to="/user/settings" className={"modal-trigger accent--bg--text--color"}>
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path>
                                        </svg>
                                    </span>
                                    <span>Back</span>
                                </Link>
                            }
                        </div>
                        
                    </div>
                    
                    <form action="#" method="POST">
                        <div className="search_form">
                            <div className="form_group"  style={{height: 40}}>
                                <span>Show</span>
                                <div className="input-field col s12">
                                    <select style={{display: 'block'}} value={perPage} onChange={handlePerPageChange}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                                <p>Records</p>
                            </div>

                            <div className="form_group search">
                                <input type="text" placeholder="Search" onChange={(e) => setQuery(e.target.value)}/>
                                <svg width="21" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7939 13.4145H13.8905L13.5703 13.1058C14.691 11.8022 15.3656 10.1098 15.3656 8.26873C15.3656 4.16354 12.038 0.835938 7.93282 0.835938C3.82762 0.835938 0.5 4.16354 0.5 8.26873C0.5 12.3739 3.82762 15.7015 7.93282 15.7015C9.77387 15.7015 11.4663 15.0269 12.7699 13.9062L13.0786 14.2264V15.1298L18.7962 20.8359L20.5 19.132L14.7939 13.4145ZM7.93282 13.4145C5.08548 13.4145 2.78702 11.1161 2.78702 8.26873C2.78702 5.4214 5.08548 3.12295 7.93282 3.12295C10.7802 3.12295 13.0786 5.4214 13.0786 8.26873C13.0786 11.1161 10.7802 13.4145 7.93282 13.4145Z" fill="#133159" fill-opacity="0.35"></path>
                                </svg>
                            </div>
                        </div>
                    </form>
                    <div className="responsive-table">
                       { 
                       loading ? <CallOutcomesSkeleton/> : (
                       <table className="data_table">
                            <thead>
                                <tr>
                                    <th width="80%" style={{ paddingLeft: "10px", paddingRight: "2px" }}>
                                        Outcome
                                    </th>
                                    <th width="20%" style={{ paddingLeft: "10px", paddingRight: "2px", textAlign: "center" }}>Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderData()}
                            </tbody>
                        </table>
                         )}             
                    </div>

                    {/* <Pagination /> */}
                    {renderPagination()}

                </div>

                {
                    modalVisible && (
                        <CallOutcomePopup
                            hideModal={(isReset) => {
                                if(isReset) {
                                    setReset(reset + 1);
                                }
                                setEditCallCome(null);
                                setModalVisible(false);
                            }}
                            title={'Add Call Outcome'}
                            editCallOutcome={editCallOutcome}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default CallOutcome;