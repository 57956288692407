import React, { useEffect, useState } from "react";
import DealReportStyles, {BootstrapInput, StyledButton} from "./dealReportUseStyles";
import GlobalUserListDropdown from "./UserListDropdown/Index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MenuItem, Select } from "@material-ui/core";
import DateRangePicker from "./dateRangePicker";
import { getAccountData } from "../../helpers/Utils";
import useDebounce from "../../hooks/useDebounce";
import useDidUpdateEffect from "../../hooks/useDidUpdateEffect";
import ExportDealReportModal from "./ExportDealReportModal";
import { MagnifyGlassIcon } from "../ten-dlc/helpers/Icon";
import {useSelector} from "react-redux";

const Filter = ({ setFormData, formData }) => {
    const { teamUsers: team } = useSelector((state) => state.productReducer);
    const styles = DealReportStyles();
    const [teamUsers, setTeamUsers] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState("WIN");
    const [search, setSearch] = useState("");
    const [resetCreatedRange, setResetCreatedRange] = useState(false);
    const [resetClosedRange, setResetClosedRange] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const deSearch = useDebounce(search, 500);
    
    useEffect(() => {
        if (team){
            let teamUsersPush = [];

            team.forEach((subUser) => {
                teamUsersPush.push({
                    value: subUser.id,
                    label: subUser.full_name,
                });
            });

            if (!getAccountData("userIsAgent")) {
                let newData = [], newIds = [];
                for (let i = 0; i < teamUsersPush.length; i++) {
                    if (teamUsersPush[i].value === getAccountData("userId")) {
                        newData.push(teamUsersPush[i]);
                        newIds.push(teamUsersPush[i].value);
                    }
                }
                setUsers(newData);
                setUserIds(newIds);
            }

            setTeamUsers(teamUsersPush);
        }
    }, [team]);

    useDidUpdateEffect(() => {
        setFormData((prevData) => ({ ...prevData, page: 1, search: deSearch }));
    }, [deSearch]);

    const handleSelectUser = (item, remove) => {
        let tempUsers = [];
        let tempUserIds = [];

        if (item.value === "__clear_all__") {
            tempUserIds = [];
            return;
        }
        if (item.value === "__all__") {
            if (remove) {
                tempUsers = [];
                tempUserIds = [getAccountData("userId")];
            } else {
                tempUsers = [item];
                tempUserIds = ["__all__"];
            }
        } else {
            if (userIds.length > 0 && userIds[0] === "__all__") {
                tempUsers = [item];
                tempUserIds = [item.value];
            } else {
                let old_data_ids = [...userIds];
                let old_data_full = [...users];
                if (remove) {
                    tempUserIds = old_data_ids.filter(
                        (list) => item.value !== list
                    );

                    tempUsers = old_data_full.filter(
                        (list) => item.value !== list.value
                    );
                } else {
                    old_data_ids.push(item.value);
                    old_data_full.push(item);
                    tempUsers = old_data_full;
                    tempUserIds = old_data_ids;
                }
            }
        }

        setUsers(tempUsers);
        setUserIds(tempUserIds);

        if (tempUserIds[0] === "__all__") {
            tempUserIds = teamUsers.map((user) => (user.value));
        }

        setFormData((prevState) => ({ ...prevState, userIds: tempUserIds, page: 1 }));
    };
   
    return (
        <div className={styles.filterWrapper}>
            <div style={{position:"relative"}} className={styles.inputGroup}>
                <span className={styles.inputLabel}>Search</span>
                <input className={styles.inputField} value={search} onChange={(e) => {
                    setSearch(e.target.value)
                }} type="text" placeholder="Search by name or company" />
                <div className={styles.magnifyGlass}>
                    <MagnifyGlassIcon/>
                </div>
            </div>
            <div className={styles.inputGroup}>
                <span className={styles.inputLabel}>Date Deal Added</span>
                <DateRangePicker
                    style={{ right:'-84%' }}
                    reset={resetCreatedRange}
                    updateReset={() => setResetCreatedRange(false)}
                    callback={(data) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            dealCreatedAtStart: data.startDate,
                            dealCreatedAtEnd: data.endDate,
                            dealCloseStartDate: null,
                            dealCloseEndDate: null
                        }));
                        setResetClosedRange(true);
                    }} />
            </div>
            <div className={styles.inputGroup}>
                { (getAccountData("agencyBusinessType") === undefined || 
                    getAccountData("agencyBusinessType") !== "INSURANCE") ?  
               <span className={styles.inputLabel}>Date Deal Closed</span> :
                <span className={styles.inputLabel}>Effective Date</span>}
                
                <DateRangePicker 
                    style={{ right:'-28%' }}
                    reset={resetClosedRange}
                    updateReset={() => setResetClosedRange(false)}
                    callback={(data) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            dealCreatedAtStart: null,
                            dealCreatedAtEnd: null,
                            dealCloseStartDate: data.startDate,
                            dealCloseEndDate: data.endDate
                        }));
                        setResetCreatedRange(true);
                    }} />
            </div>
            <div className={styles.inputGroup}>
                <span className={styles.inputLabel}>Deal Status</span>
                <Select
                    value={status}
                    displayEmpty
                    onChange={(e) => {
                        setStatus(e.target.value);
                        setFormData((prevState) => ({ ...prevState, dealStatus: e.target.value, page: 1 }));
                    }}
                    input={<BootstrapInput />}
                    inputProps={{ "aria-label": "Without label" }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                    IconComponent={ExpandMoreIcon}
                    sx={{ minHeight: '45px' }}
                >
                    <MenuItem className="dropdownhelper-menuitem-class" value={null}>All</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value={"ACTIVE"}>Active</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value={"WIN"}>Won</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value={"LOST"}>Lost</MenuItem>
                </Select>
            </div>
            {
                !getAccountData("userIsAgent") &&
                <div className={styles.inputGroup}>
                    <span className={styles.inputLabel} style={{marginBottom:'4px'}}>User</span>
                    <GlobalUserListDropdown
                        userList={teamUsers}
                        handleSelect={(value, remove) => {
                            handleSelectUser(value, remove);
                        }}
                        selected={users}
                        selectedId={userIds}
                    />
                </div>
            }
            <StyledButton onClick={() => { setShowExportModal(true); }}>
                Export
            </StyledButton>

            <ExportDealReportModal
                formData={formData}
                open={showExportModal}
                onClose={()=>{ setShowExportModal(false); }}
            />
        </div>
    );
}

export default Filter;