import React, { useRef, useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {
  deleteVirtualNumberSetting,
  forwardVirtualNumberSubmit,
} from "../../../api/profileApi";
import { Popover, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";

const ForwardNumberEdit = ({ item }) => {
  const inputField = useRef(null);
  const [validation, setValidation] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldValue, setFieldValue] = useState(item.forward_number);
  const [mouseOver, setMouseOver] = useState(false);
  const [defaultValue, setDefaultValue] = useState(item.forward_number);
  const inputRef = useRef(null);
  const [editedFieldValue, setEditedFieldValue] = useState(item.forward_number);
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleCrossPress = () => {
    setAnchorEl(null);
    setEdit(false);
    setValidation(false);
    setValidationText("");
    setEditedFieldValue(fieldValue)
  };
  const handleEnterPress = (event) => {
    var code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setSaving(true);
    forwardVirtualNumberSubmit({
      id: item.id,
      forward_number: editedFieldValue,
    }).then((res) => {
      setSaving(false);
      if (res.data.status === "validation-error") {
        window.showNotification(
          "ERROR",
          res.data.message[Object.keys(res.data.message)[0]][0]
        );
      } else if (res.data.status === "error") {
        window.showNotification("ERROR", res.data.message);
      } else if (res.data.status === "success") {
        setFieldValue(editedFieldValue);
        setEdit(false);
        setAnchorEl(null);
        window.showNotification("SUCCESS", "Forward Number Changed");
      }
    });
  };

  const deleteSetting = () => {
    NewConfirmAlert({
      onSubmit: () => {
        setSaving(true);
        deleteVirtualNumberSetting({
          id: item.id,
          setting: "forward_number",
        }).then((res) => {
          setSaving(false);
          if (res.data.status === "validation-error") {
            window.showNotification(
              "ERROR",
              res.data.message[Object.keys(res.data.message)[0]][0]
            );
          } else if (res.data.status === "error") {
            window.showNotification("ERROR", res.data.message);
          } else if (res.data.status === "success") {
            setDefaultValue("");
            setFieldValue("");
            setEditedFieldValue("");
            setAnchorEl(null);
            window.showNotification("SUCCESS", "Forward Number Deleted");
          }
        });
      },
    title: "Are You Sure?",
    description: "Are you sure to delete this forward number?",
    cancelText: 'No, Keep it',
    submitText: 'Yes, Delete it',
    width: "480px",
  });
  };
  const handleCheckPress = () => {
    handleSubmit();
  };


  const divStyle = {
    width: "170px",
    border: mouseOver ? "1px solid #000" : "1px solid transparent",
    borderRadius: "8px",
    transition: "border 0.3s ease, padding 0.3s ease",
    paddingLeft: "8px",
    paddingRight: "8px",
    cursor: "pointer",
  };

  const inputStyle = {
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    padding: "10px",
    color: "gray",
  };

  const inputEditStyle = {
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    fontSize: "inherit",
    paddingLeft: "4px",
    paddingRight: "4px",
    boxShadow: "none",
    textDecoration: "none",
    border: "1px solid gray",
    borderRadius: "8px",
  };

  const dropdownStyle = {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    width: "250px",
    padding: "8px",
  };

  const handleDivClick = (event) => {
    if (!fieldValue) {
      setEdit(true);
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={divStyle}
        onClick={handleDivClick}
        ref={dropdownRef}
      >
        {fieldValue ? (
          <Typography 
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
          style={inputStyle}>{fieldValue}</Typography>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px 0px",
            }}
          >
            <AddCircle
              style={{ marginTop: "6px", marginBottom: "6px" }}
              fontSize="medium"
            />
          </div>
        )}
      </div>
      <Popover
        ref={inputRef}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={handleCrossPress}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={dropdownStyle}>
          <div className="d-flex align-items-center justify-content-between">
            {validation && (
              <small className="text-danger">{validationText}</small>
            )}
          </div>
          <div>
            {
            edit && (
            <>
              <style>
                {`
                input[type='number']::-webkit-inner-spin-button,
                input[type='number']::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type='number'] {
                    -moz-appearance: textfield;
                }
                `}
              </style>
              <input
                ref={inputField}
                value={editedFieldValue}
                onKeyPress={handleEnterPress}
                onChange={(e) => setEditedFieldValue(e.target.value)}
                type="number"
                disabled={!edit}
                defaultValue={editedFieldValue}
                placeholder={edit ? "Enter Forward number" : ""}
                style={inputEditStyle}
              />
            </>
            )}
            {!edit && (
                <Typography 
                  
                  style={{ padding: "10px" }}>{fieldValue}
                </Typography>
            )}
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                {!edit && (
                  <div style={{ display: "flex", gap: "16px" }}>
                    <BootstrapTooltip arrow title={`Update Forward Number`}>
                      <span
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        <CreateIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                    {(defaultValue !== "" || fieldValue)  && (
                      <BootstrapTooltip arrow title={`Delete Forward Number`}>
                        <span onClick={deleteSetting}>
                          <DeleteIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                    {saving && (
                      <BootstrapTooltip arrow title={`Updating Forward Number`}>
                        <span>
                          <MoreHorizIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                  </div>
                )}
              </div>
              {edit && !saving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                    marginTop: "10px",
                  }}
                >
                  {(editedFieldValue && editedFieldValue !== defaultValue) && (
                    <BootstrapTooltip arrow title="Confirm">
                      <span onClick={handleCheckPress}>
                        <DoneIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                  )}
                  <BootstrapTooltip arrow title="Cancel">
                    <span onClick={handleCrossPress}>
                      <ClearIcon fontSize="small" />
                    </span>
                  </BootstrapTooltip>
                </div>
              )}
            </>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ForwardNumberEdit;
