import React, {useContext, useState} from "react";
import {updateViewControl} from "../../api/profileApi";
import {ViewControlContext} from "./StateProvider";
import CustomSwitch from "../common/Switch";
import ActionTypes from "./ActionTypes";
import DashboardViewSkeleton from "./DashboardViewSkeleton";

const DashboardView = () => {
    const {state} = useContext(ViewControlContext);

    if (state.isLoading){
        return (
            <DashboardViewSkeleton/>
            
        )
    }

  return (
      <div id="dashboard-view" className="col s12 active">
          <div className="responsive-table">
              <table className="dashboard-view_table">
                  <thead>
                      <tr>
                          <th style={{ paddingLeft: "20px", width: "95%" }}>Section</th>
                          <th style={{ textAlign: "center" }}>View</th>
                      </tr>
                  </thead>
                  <tbody>
                      {
                          (state.viewControls && state.viewControls.length > 0) ? state.viewControls.map((viewControl, index)=>{
                            return (
                                <EachSection viewControl={viewControl} index={index} key={"itm"+index}/>
                            )
                          }) :
                              <tr>
                                  <td style={{ textAlign: "center" }} colSpan="2">No Setting Found!</td>
                              </tr>
                      }
                  </tbody>
              </table>
          </div>
      </div>
  );
};

export default DashboardView;


const EachSection = ({ viewControl, index }) => {
    const [viewState,setViewState] = useState(viewControl.view === 'ON');
    const {dispatch} = useContext(ViewControlContext);

    const handleUpdateViewControl = () => {
        try {
            updateViewControl({
                id: viewControl.id,
                control_id: viewControl.control_id,
                identifier: viewControl.identifier,
                view: viewState ? 'OFF' : 'ON'
            })
                .then((res)=>{
                    res = res.data;
                    if (res.status === 'success'){

                        if (res.data !== 1 && res.data && res.data.id !== null){
                            dispatch({
                                type: ActionTypes.UPDATE_INDEX_VALUE,
                                payload: {
                                    index: index,
                                    data: {
                                        id: res.data.id,
                                        control_id: res.data.id
                                    }
                                }
                            });
                        }
                        setViewState(!viewState);
                        window.showNotification("SUCCESS",res.message);
                    }else {
                        window.showNotification("ERROR",res.message);
                    }
                }).catch((err)=>{
                window.showNotification("ERROR","Something went wrong!");
            });
        }catch (e){
            window.showNotification("ERROR","Something went wrong!");
        }
    }
    return (
        <tr>
            <td style={{ paddingLeft: "20px" }}>
                {viewControl.friendly_name}
            </td>
            <td style={{ paddingRight: "20px" }}>
                <CustomSwitch
                    status={viewState}
                    brandColor={'#f94f72'}
                    onChange={handleUpdateViewControl}
                />
            </td>
        </tr>
    );
}