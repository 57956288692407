import { TablePagination } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { changeStatusUserPaymentCredential, getUserPaymentCredential } from "../../api/InvoiceApi";
import Utils from "../../helpers/Utils";
import SwitchC from "../customField/subComponents/Switch";
import ActionTypes from "./ActionTypes";
import { InvoiceContext } from "./InvoiceProvider";

const InvoiceTable = () => {
  const { state, dispatch } = useContext(InvoiceContext);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const toggleStatus = (index) => {
    const oldData = [...state.payment_credentials];
    const updatedStatus = oldData[index].status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    changeStatusUserPaymentCredential({ id: oldData[index].id, status: updatedStatus })
      .then((response) => {
        response = response.data;
        if (response.success) {
          oldData[index].status = updatedStatus;
          dispatch({
            type: ActionTypes.SET_CREDENTIAL,
            payload: { payment_credentials: oldData },
          });
          Utils.showNotification("Payment credentials Status Changed successfully","success");
        }
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.SET_ERROR, payload: err.message });
      })
      .finally(() => {
        dispatch({ type: ActionTypes.UPDATE_TABLE_DATA, payload: { isLoading: false } });
      });
  };

  const renderJson = (value) => {
    try {
      return Object.entries(value).map(([key, value]) => (
        <li>{key && key.replace("_", " ")}: {value}</li>
      ));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadData();
  }, [state.page, state.per_page]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    setIsLoadingData(true);
    dispatch({ type: ActionTypes.SET_LOADING, payload: true });

    try {
      getUserPaymentCredential({ page: state.page, per_page: state.per_page })
        .then((response) => {
          response = response.data.data;
          dispatch({
            type: ActionTypes.GET_CREDENTIAL,
            payload: {
              data: response.data,
              total: response.total,
              total_page: response.total_page,
              page: response.current_page,
              per_page: response.per_page,
            },
          });
        })
        .catch((err) => {
          dispatch({ type: ActionTypes.SET_ERROR, payload: err.message });
        })
        .finally(() => {
          setIsLoadingData(false);
          dispatch({ type: ActionTypes.UPDATE_TABLE_DATA, payload: { isLoading: false } });
        });
    } catch (err) {
      setIsLoadingData(false);
      dispatch({ type: ActionTypes.SET_ERROR, payload: err.message });
    }
  };

  const renderTableRows = () => {
    if (isLoadingData) {
      let loadingView = [];
      for (let i = 0; i < state.per_page; i++) {
          loadingView.push(
              <tr key={i}>
                  <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                  <td > 
                    <Skeleton width="100%" height={"16px"}> </Skeleton>
                    <Skeleton width="100%" height={"16px"}> </Skeleton>
                  </td>
                  <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                  <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
              </tr>    
          );
      }
      return loadingView;
    }

    if (state.payment_credentials && state.payment_credentials.length > 0) {
      return state.payment_credentials.map((credential, index) => (
        <tr key={credential.id}>
          <td>{credential.payment_name}</td>
          <td>
            <ul>{renderJson(credential.payment_credentials)}</ul>
          </td>
          <td>{credential.status}</td>
          <td style={{ textAlign: "center" }}>
            <div className="switch team_switch">
              <SwitchC
                status={credential.status === "ACTIVE"}
                brandColor={"#f94f72"}
                onChange={() => toggleStatus(index)}
                onText="On"
                offText="Off"
              />
            </div>
          </td>
        </tr>
      ));
    }

    return (
      <tr>
        <td colSpan={4} style={{ textAlign: "center", paddingTop: 20 }}>
          <h5 className="text-center p-3">NO CREDENTIAL DATA AVAILABLE</h5>
        </td>
      </tr>
    );
  };


  return (
    <div>
      <table>
        <thead>
          <tr className="header">
            <th>Payment Name</th>
            <th>Credentials</th>
            <th>Status</th>
            <th style={{ textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody className="tbody_content">
          {renderTableRows()}
        </tbody>
      </table>
      <div style={{ position: 'sticky', bottom: 0, background: 'white' }}>
        <TablePagination
          component="div"
          count={state.total}
          page={state.page - 1}
          rowsPerPage={state.per_page}
          onPageChange={(event, page) => {
            dispatch({ type: ActionTypes.SET_CREDENTIAL, payload: { isLoading:true, page: page + 1 } });
          }}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={(event) => {
            dispatch({ type: ActionTypes.SET_CREDENTIAL, payload: { per_page: parseInt(event.target.value, 10), page: 1 } });
          }}
        />
      </div>
    </div>
  );
};

export default InvoiceTable;