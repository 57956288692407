import React, { useState, useCallback} from "react";
import PropTypes from 'prop-types';
import BasicInput from "../custom/BasicInput";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  modalContainer: {
    width: "540px",
    background: "white",
    borderRadius: "14px",
    boxShadow: "7px 7px 10px 7px #ddd",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    gap: "5px",
  },
  logoContainer: {
    width: "50px",
    height: "50px",
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    paddingLeft: "5px",
  },
  divider: {
    borderTop: "1px solid #ccc",
    margin: "0",
  },
  content: {
    padding: "20px",
  },
  description: {
    padding: "5px",
    textAlign: "center",
    marginBottom: "20px",
  },
  inputContainer: {
    padding: "5px",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    padding: "5px",
    marginTop: "20px",
  },
  button: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "all 0.2s ease",
  },
  closeButton: {
    backgroundColor: "#e0e0e0",
    color: "#333",
    "&:focus, &:hover": {
      backgroundColor: "#ccc",
      transform: "translateY(-1px)",
    },
  },
  confirmButton: {
    backgroundColor: "#ef244a",
    color: "#fff",
    "&:focus, &:hover": {
      backgroundColor: "#c32845",
      transform: "translateY(-1px)",
    },
  },
  text: {
    fontSize: "14px",
    padding: "0px",
    margin: "0px",
  },
  textType: {
    fontWeight: 700,
  },
});

const CustomDeleteModal = (({
  onClose,
  handleConfirm,
  confirmationText,
  closeButtonText = "Close",
  confirmButtonText = "Confirm",
  showConfirmButton = true,
  showCloseButton = true,
  description = "",
  placeholder = "",
  imgSrc = "",
  title = "",
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
    setErrorMessage("");
  }, []);

  const handleSubmit = useCallback(() => {
    if (!confirmationText || (inputValue === confirmationText)) {
      onClose();
      handleConfirm();
    } else {
      setErrorMessage("Please type the field name correctly.");
    }
  }, [inputValue, confirmationText, handleConfirm, onClose]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.header}>
        {imgSrc && (
          <div className={classes.logoContainer}>
            <img src={imgSrc} alt="logo" className={classes.logo} />
          </div>
        )}
        {title && <span className={classes.title}>{title}</span>}
      </div>
      <hr className={classes.divider} />
      <div className={classes.content}>
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        {confirmationText && (
          <div className={classes.inputContainer}>
            <p className={classes.text}>
              Please type{" "}
              <span className={classes.textType}>"{confirmationText}"</span> to
              confirm
            </p>
            <BasicInput
              value={inputValue}
              onChange={handleInputChange}
              placeholder={placeholder}
              fullWidth
            />
            {errorMessage && (
              <div className={classes.errorMessage}>{errorMessage}</div>
            )}
          </div>
        )}
        <div className={classes.actions}>
          {showCloseButton && (
            <button
              onClick={handleClose}
              className={`${classes.button} ${classes.closeButton}`}
            >
              {closeButtonText}
            </button>
          )}
          {showConfirmButton && (
            <button
              onClick={handleSubmit}
              className={`${classes.button} ${classes.confirmButton}`}
            >
              {confirmButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

CustomDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  confirmationText: PropTypes.string,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  showConfirmButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  imgSrc: PropTypes.string,
  title: PropTypes.string,
};

CustomDeleteModal.displayName = 'CustomDeleteModal';

export default React.memo(CustomDeleteModal);