import { Button } from "@material-ui/core";
import Warning from "@material-ui/icons/Warning";
import { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux";
import Select from 'react-select';
import { getUser } from "../../actions/profileAction";
import { updateTimezone } from "../../api/profileApi";
import { getCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";

const generateTimezoneList = () => {
    let timezones = [];

    Utils.generateTimezoneList().forEach(element => {
        timezones.push({
            value: Object.getOwnPropertyNames(element)[0],
            label: element[Object.getOwnPropertyNames(element)]
        })
    });

    return timezones;
}

const Timezone = (props) => {
    const [selectedTimezone, setSelectedTimezone] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.user && props.user.timezone) {
            setSelectedTimezone({
                value: props.user.timezone,
                label: Utils.generateTimezoneList(props.user.timezone)
            })
        }
    }, [props.user])

    const submitData = () => {
        setLoading(true);

        updateTimezone({
            "form_data": {
                timezone: selectedTimezone.value,
            }
        }).then((response) => {
            Utils.showNotification(response.data.message, 'success');
        }).finally(() => {
            props.getUser({
                'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
            })
            setLoading(false);
        })
    }

    return (
      <Fragment>
        <div id="timezone" class="col s12 active">
          <p class="alart d-flex align-items-center">
            <Warning className="mr-2" />
            You selected your time zone during your initial setup. This cannot
            be changed. For further questions reach out to our support team.
          </p>
          <div class="selet_input">
            <label>Timezone</label>
            <div class="time_select timezone-time-select">
              <Select
                options={generateTimezoneList()}
                isClearable={false}
                isDisabled={props.user.timezone ? true : false}
                value={selectedTimezone}
                onChange={(_selectedOption) => {
                  setSelectedTimezone(_selectedOption);
                }}
                placeholder="Select Timezone"
              />
            </div>
          </div>
          {props.user && !props.user.timezone && (
            <div className="mt-3">
              <Button
                variant="contained"
                className={"accent--bg--text--color "}
                disabled={loading}
                onClick={submitData}
              >
                {!loading ? "Save Timezone" : "Saving..."}
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
}


const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Timezone);