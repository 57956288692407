import React from 'react'
import './first-promoter.css'
import {getUser} from "../../actions/profileAction";
import {connect} from "react-redux";
import Utils from '../../helpers/Utils';

const FirstPromoter = (props) => {

    return (
      <div className="container-fluid public-api-container awesome__scroll_bar">
        <div className="row">
          {(Utils.getAccountData("first_promoter_signup_link") !== undefined ||
            Utils.getAccountData("first_promoter_signup_link") != null) && (
            <iframe
              height="2100px"
              width="100%"
              title="First Promoter Signup Link"
              style={{ border: "none" }}
              src={Utils.getAccountData("first_promoter_signup_link")}
            ></iframe>
          )}
        </div>
      </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstPromoter);