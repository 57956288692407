import { makeStyles } from '@material-ui/core/styles';
import { Save } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { } from "../../api/profileApi";
import Loader from "../globals/Loader";
import GlobalModal from "../globals/Modal/GlobalModal";
import Step2 from "./step2";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const SnapShotPopupNew = (props) => {
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeSnapShot, setActiveSnapShot] = React.useState(null);
    const [globalTemplatesObj, setGlobalTemplatesObj] = React.useState({});
    //const [snapShotDetails, setSnapShotDetails] = useState([]);
    //validator
    // const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered

    useEffect(()=>{
        setComponentLoading(false);
        if(props.snapShot){
            setActiveSnapShot(props.snapShot)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = (reset = false) => {
        props.hideModal(reset);
    }

    const handleNext = () => {
        const newActiveStep = activeStep + 1
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onSubmitHandle = (e) =>{
        e.preventDefault();

    }


    const handleComplete2ndStep = (globalTemplatesObj) => {
        setGlobalTemplatesObj(globalTemplatesObj);
        // setSnapShotDetails(snapShotTemps);
        handleNext();
    };

    const getStepContent = (step) => {

        return <Step2 handleBack={handleBack} handleStep={(globalTemplatesObj, snapShotTemps) => handleComplete2ndStep(globalTemplatesObj, snapShotTemps) } activeSnapShot={activeSnapShot} globalTemplatesObj={globalTemplatesObj} subUser={props.data}  removePreve={true} removeNext={true} removeSkipe={true}/>;  
    }

    return (
        <GlobalModal
            title={props.title}
            onClose={toggle}
            onSubmit={onSubmitHandle}
            open={true}
            buttonText={loading ? 'Saving..' : 'Save'}
            buttonIcon={<Save/>}
            className={"snapshot-modal"}
            
        >

        {
            componentLoading ? (
                <div style={{paddingTop: 50}}>
                    <Loader/>
                </div>
            ) : (
                <>
                    <div className={classes.root}>
                        {/* <Box sx={{ width: '100%' }}>
                            <Stepper alternativeLabel linear activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const buttonProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepButton
                                                {...buttonProps}
                                            >
                                                {label}
                                            </StepButton>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box> */}


                        <div>
                            {getStepContent(activeStep)}
                        </div>


                    </div>
                </>
            )
        }

        </GlobalModal>
    );
}

export default SnapShotPopupNew;