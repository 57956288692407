import { Skeleton } from "@material-ui/lab";

const PopUpSkeleton = () => {
    return (
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'12px'}}>
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
            <Skeleton width={'100%'} height={40} />
        </div>
    );
}

export default PopUpSkeleton;