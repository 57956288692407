import {
  Dialog,
  IconButton,
  TableCell,
  TableRow,
  styled,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteLostReason } from "../../../../../actions/ProductAction";
import CustomDeleteModal from "../../../../settings/CustomDeleteModal";
import AddOrEditLostReasonModal from "../AddOrEditLostReasonModal";

const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
},
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
    color:'var( --dark_blue)',
    fontSize:'15px'
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
    padding:'10px'
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));


const EachLostReasonTableItem = ({ policy }) => {
  const { id, reason, deletable } = policy || {};
  const dispatch = useDispatch();
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const DescriptionForDelete = "You are attempting to delete a global lost deal reason.This change will be applied to all users on your team.";
  const ImgSrcForDeleteModal = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`;

  const handleEditLostReason = () => {
    setOpenAddOrEditModal(true);
  };

  const confirmDeleteLostReason = async () => {
    dispatch(deleteLostReason({ lost_deal_reason_id: id }));
  };

  const handleDeleteLostReason = () => {
    setOpenDeleteModal(true);
  };

  return (
    <TableRowWrapper>
      <TableCell style={{width:'80%'}}>
        <div className="titleWrap">{reason}</div>
      </TableCell>
      {/*<TableCell align="right">*/}
      {/*  <SwitchC*/}
      {/*    status={status}*/}
      {/*    brandColor={"#346fef"}*/}
      {/*    onChange={() => setStatus(!status)}*/}
      {/*  />*/}
      {/*</TableCell>*/}
      <TableCell style={{width:'20%'}}>
        {
          deletable &&
          ( 
            <div style={{display:'flex',alignItems:'center'}}>
                <IconButton onClick={handleEditLostReason}>
                  <EditIcon fontSize="small" className="icon" />
                </IconButton>
                <IconButton onClick={handleDeleteLostReason}>
                  <DeleteIcon style={{color:"red"}} fontSize="small" className="icon" />
                </IconButton>
            </div>
          )}
        
      </TableCell>
      <Dialog
        open={openDeleteModal}
        PaperProps={{
          style: {
            borderRadius: '14px',
          },
        }}
      >
          <CustomDeleteModal
            title="Notice"
            onClose={() => setOpenDeleteModal(false)}
            handleConfirm={confirmDeleteLostReason}
            closeButtonText="Close"
            confirmButtonText="Delete"
            showConfirmButton={true}
            showCloseButton={true}
            description={DescriptionForDelete}
            placeholder="Type lost deal reason"
            imgSrc={ImgSrcForDeleteModal}
            confirmationText={reason}
          />
      </Dialog>

      {openAddOrEditModal && (
        <AddOrEditLostReasonModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={policy}
        />
      )}
    </TableRowWrapper>
  );
};

export default EachLostReasonTableItem;
