import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import AppListHeader from "./appListHeader/AppListHeader";
import AppListTable from "./appListTable/AppListTable";
import { getAppList } from "../../api/profileApi";
import TableLoaderSkeleton from "../invoiceSettings/common/TableLoaderSkeleton";
import GlobalEmptyPage from "../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import { EmptyInboxIcon } from "../invoiceSettings/common/icons/invoicePageIcons";
import Utils from "../../helpers/Utils";
import { Box } from "@material-ui/core";

export const AppList = () => {
  const [appList, setAppList] = useState();
  const [renderId, setRenderId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const onRenderCallback = () => {
    setRenderId(Utils.generateRandomString(5));
  };

  useEffect(() => {
    setIsLoading(true);

    getAppList({ pageSize: 100 })
      .then((res) => {
        if (res && res.status && res.status === "success") {
          res = res.data;
          setAppList(res);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [renderId]);

  let content = null;

  const removeId = (id) => {
    setAppList((prevAppList) =>
      prevAppList.filter((app) => app.authorizationApp.id !== id)
    );
  };

  if (isLoading /* && !renderId */) {
    content = (
      <Box p={2}>
        <TableLoaderSkeleton numberOfRows={10} columns={4} minWidth={120} />
      </Box>
    );
  } else if (appList?.length === 0 && !isLoading) {
    content = (
      <GlobalEmptyPage icon={<EmptyInboxIcon />} title={"No data available"} />
    );
  } else if (appList?.length > 0) {
    content = <AppListTable appList={appList} removeId={removeId} />;
  }

  return (
    <Paper elevation={0}>
      <AppListHeader onRenderCallback={onRenderCallback} />

      {content}
    </Paper>
  );
};
