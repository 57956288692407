import TablePagination from '@material-ui/core/TablePagination';
import { Skeleton } from "@material-ui/lab";
import moment from 'moment-timezone';
import React, { Fragment, useEffect, useState } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getUser } from "../../actions/profileAction";
import { activateUser, deactivateUser, deleteUserWithoutTransfer, getSubUserAuthKey, getSubUserList } from "../../api/profileApi";
import { deleteCookie, getCookie, setCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import { useIsMounted } from "../../hooks/IsMounted";
import useQueryParam from "../../hooks/useQueryParam";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import CustomConfirmAlert from "./CustomConfirmAlert";
import Popup from "./Popup";
import SnapShotPopup from "./SnapShotPopup";
import './Team.css';
import TeamSkeleton from "./TeamSkeleton";
import TransferDataPopup from "./TransferDataPopup";
import './custom-alert-pypepro.css';

const Team = (props) => {

    const isMounted = useIsMounted();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortType, setSortType] = useState('asc');
    const [sortBy, setSortBy] = useState('full_name');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [snapShotModalVisible, setSnapShotModalVisible] = useState(false);
    const [transferDataModalVisible, setTransferDataModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [userLimitOver, setUserLimitOver] = useState(false);
    const userTimezone = Utils.getAccountData('userTimezoneRaw');
    const [openTab] = useQueryParam("open-tab");

    useEffect(() => {
        document.title = `Team | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
        props.getUser({
            'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
        })

        if (openTab === "CREATE_NEW_USER"){
            setModalVisible(true);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, sortType, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    const loadData = () => {
        setLoading(true);

        getSubUserList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {
            if(response.data.data.from === 1){
                let maxUser = response.data.maxSubUser;
                let currentUser = response.data.data.total;
                if(currentUser >= maxUser){
                    setUserLimitOver(true);
                }
            }

            setData(response.data.data.data);
            setTotal(response.data.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleChangeStatus = (e, _id, index) => {
        if(!e.target.checked){
            // CustomConfirmAlert(
            //     () => changeStatus(e, _id, index),
            //     'Are you sure?',
            //     'This user will be lost his all virtual numbers. This user have to buy new virtual number if you active this user again later.',
            //     '',
            //     'done'
            // )
            CustomConfirmAlert({
                handleConfirm : () => changeStatus(e, _id, index),
                title: 'Are you sure?',
                description: 'When you deactivate a user we release their phone number(s) back to the phone company. If you reactivate this user later they will need to select a new number.',
                type_word: 'DEACTIVATE USER',
                icon: 'question',
                closeButtonText: 'Cancel'
            })
        }else{
            activateUser({
                "form_data": {
                    user_id: _id
                }
            })
            .then(response => {
                let old_data = [...data]
                old_data[index].status = 1
                setData(old_data)
                window.showNotification("SUCCESS", "User status activated")
                // setReset(reset + 1);
            }).finally(() => {
                setLoading(false);
            });
        }
        
    }

    const changeStatus = (e, _id, index) => {
        deactivateUser({
            "form_data": {
                user_id: _id
            }
        })
        .then(response => {
            let old_data = [...data]
            old_data[index].status = 0
            setData(old_data)
            window.showNotification("SUCCESS", "User status deactivated")
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteData = (_id) => {
        setLoading(true);
        deleteUserWithoutTransfer({
            "form_data": {
                user_id: _id
            }
        })
        .then(response => {
            setReset(reset + 1);
        }).finally(() => {
            setLoading(false);
        });
    }

    const forceLogin = (e,userId) => {
        e.preventDefault();
        setLoading(true);
        let parentId = Utils.getAccountData('userId');

        getSubUserAuthKey({id : userId}).then(res => {
            if(res.data.success) {
                let parentKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
                setCookie(process.env.REACT_APP_ACCESS_TOKEN, res.data.data.user.api_key, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                setCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN, parentKey, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
                setCookie(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(res.data.data.userAccountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
                setTimeout(() => {
                    const channel = new BroadcastChannel('pypepro-channel');

                    // Send a message to other tabs
                    channel.postMessage({action :"reload-page-"+parentId, link: '/'});

                }, 1000);
            }
        });
    }
    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 4; i++) {
            skeletons.push(
            <tr key={i}>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
                <td > <Skeleton width={"100%"} height={"16px"}> </Skeleton></td>
            </tr>  
            );
        }
        return skeletons;
    };
    const TimezoneConversation = (inputTime, currentTimezone = 'UTC', convertTimezone = 'UTC') => {
        try {
            var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
            var formattedTime = currentTimeInGivenTimezone.clone().tz(convertTimezone).format("MMM D, YYYY h:mm A");
            return formattedTime.toUpperCase();
        } catch (e) {
            return inputTime;
        }
    };
    const renderData = () => {
        if (loading) {
            return (
                // <tr style={{height: 200}}>
                //     <td colspan="5">
                //         <div style={{paddingTop: 0}}>
                //             <Loader/>
                //         </div>
                //     </td>
                // </tr>
                loadingSkeleton()
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="5">
                        <div style={{paddingTop: 0}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => (
                <tr>
                    <td><span style={{ fontSize: '.9em' }}>{item.full_name} {item.is_template_user === 1 ? <BootstrapTooltip arrow placement={"top"} title={"Snapshot User/ Template User"}>
                        <sup className="snapshot-user-mark">S</sup>
                    </BootstrapTooltip> : ""}</span></td>
                    <td>{item.email}</td>
                    <td>
                        <label class="switch">
                            <small class="on">On</small>
                            <input 
                                type="checkbox" 
                                defaultChecked={item.status === 1}
                                checked={item.status === 1}
                                onChange={(e) => {handleChangeStatus(e, item.id, index)}}
                            />
                            <span class="slider round"></span>
                            <small class="off">Off</small>
                        </label>
                    </td>
                    <td><span style={{ fontSize: '0.87em' }}>{item.name}</span></td>
                    <td><span style={{ fontSize: '0.77em' }}>{TimezoneConversation(item.created_at, 'UTC', userTimezone, "MMM D, YYYY h:mm A")}</span></td>                    <td>
                        <BootstrapTooltip arrow title="Edit User">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                setDataToEdit(item);
                                setModalVisible(true);
                            }}>
                                <span className="custom_svg_fill_wrpper">
                                    <svg width="24px" height="24px" viewBox="0 -32 576 576" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"/></svg>
                                </span>
                            </a>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Load quick clone">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                setDataToEdit(item);
                                setSnapShotModalVisible(true);
                            }}>
                                <span className="custom_svg_fill_wrpper">
                                    <svg width="24px" height="24px" viewBox="0 0 512 512"
                                         xmlns="http://www.w3.org/2000/svg">
                                      <polygon fill="#8996AB" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432"
                                               className="ci-primary"/>
                                      <path fill="#8996AB"
                                            d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z"
                                            className="ci-primary"/>
                                    </svg>

                                </span>
                            </a>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Force Login">
                            <a href="#!" onClick={(e) => forceLogin(e,item.id)}>
                                <span className="custom_svg_fill_wrpper">
                                   <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.53846C10.1472 3.53846 8.64516 5.03085 8.64516 6.8718C8.64516 8.71274 10.1472 10.2051 12 10.2051C13.8528 10.2051 15.3548 8.71274 15.3548 6.8718C15.3548 5.03085 13.8528 3.53846 12 3.53846ZM7.09677 6.8718C7.09677 4.18118 9.29202 2 12 2C14.708 2 16.9032 4.18118 16.9032 6.8718C16.9032 9.56241 14.708 11.7436 12 11.7436C9.29202 11.7436 7.09677 9.56241 7.09677 6.8718Z" fill="#030D45"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.46618 15.3333C7.30244 15.3333 5.54839 17.0761 5.54839 19.226C5.54839 19.3389 5.56915 19.4186 5.59092 19.465C5.60929 19.5041 5.62506 19.5153 5.63821 19.5224C6.23988 19.8477 7.92682 20.4615 12 20.4615C16.0732 20.4615 17.7601 19.8477 18.3618 19.5224C18.3749 19.5153 18.3907 19.5041 18.4091 19.465C18.4309 19.4186 18.4516 19.3389 18.4516 19.226C18.4516 17.0761 16.6976 15.3333 14.5338 15.3333H9.46618ZM4 19.226C4 16.2265 6.44729 13.7949 9.46618 13.7949H14.5338C17.5527 13.7949 20 16.2265 20 19.226C20 19.7766 19.7994 20.4966 19.1019 20.8737C18.1779 21.3733 16.1959 22 12 22C7.80414 22 5.82206 21.3733 4.89812 20.8737C4.20063 20.4966 4 19.7766 4 19.226Z" fill="#030D45"/>
                                    </svg>
                                </span>
                            </a>
                        </BootstrapTooltip>
                        <BootstrapTooltip arrow title="Delete">
                            <a href="#!" onClick={(e) => {
                                e.preventDefault();
                                CustomConfirmAlert({
                                    handleConfirm : () => {
                                        CustomConfirmAlert({
                                            handleConfirm : () => {
                                                setDataToEdit(item);
                                                setTransferDataModalVisible(true);
                                            },
                                            handleOnClose: () => {
                                                deleteData(item.id);
                                            },
                                            title: 'Are you sure?',
                                            description: 'Do you want to transfer the contacts to other users? If you click No, all contacts of the user will be deleted permanently.',
                                            icon: 'question',
                                            closeButtonText: 'No, delete all',
                                            confirmButtonText: "Yes, transfer"
                                        })
                                    },
                                    callConfirmLast: true,
                                    title: 'Are you sure?',
                                    description: 'Do you want delete this user? You can not restore user information again.',
                                    type_word: 'DELETE USER',
                                    icon: 'question',
                                    closeButtonText: 'Cancel'
                                })
                            }}>
                                <span>
                                    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.125 8.3125L4.57715 22.1069C4.60744 22.3949 4.74329 22.6615 4.95851 22.8553C5.17374 23.0491 5.45308 23.1563 5.74268 23.1562H19.2573C19.5469 23.1563 19.8263 23.0491 20.0415 22.8553C20.2567 22.6615 20.3926 22.3949 20.4229 22.1069L21.875 8.3125H3.125ZM15.2344 18.9307L12.5 16.1963L9.76562 18.9307L8.52246 17.6875L11.2568 14.9531L8.52246 12.2188L9.76562 10.9756L12.5 13.71L15.2344 10.9756L16.4775 12.2188L13.7432 14.9531L16.4775 17.6875L15.2344 18.9307Z" fill="#FF264A"></path>
                                        <path d="M22.8516 2.84375H2.14844C1.82483 2.84375 1.5625 3.10608 1.5625 3.42969V6.16406C1.5625 6.48767 1.82483 6.75 2.14844 6.75H22.8516C23.1752 6.75 23.4375 6.48767 23.4375 6.16406V3.42969C23.4375 3.10608 23.1752 2.84375 22.8516 2.84375Z" fill="#FF264A"></path>
                                    </svg>
                                </span>
                            </a>
                        </BootstrapTooltip>
                    </td>
                </tr>
            ))
        }
    }

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    return (
        <Fragment>
            <div class="settings_section">
                {
                    !props.user ? (
                        <TeamSkeleton/>
                    ) : (
                        <Fragment>
                            <div class="container-fluid mail__inbox">
                                <div class="tagBox">
                                    <div class="tag_list">
                                        <h5>Users list</h5>
                                    </div>
                                    <div class="add_new_user">
                                        {
                                            userLimitOver ? (
                                                <p style={{ color: 'red' }}>Team user limit over.</p>
                                            ) : (
                                        <a href="#add_new_user_modal" class="modal-trigger accent--bg--text--color" onClick={(e) => {
                                            e.preventDefault();
                                            setModalVisible(true);
                                        }}>
                                            <span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10"></circle>
                                                    <path d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z" className="accent--fill--color"></path>
                                                </svg>
                                            </span>Add New User
                                        </a>
                                            )}

                                        {
                                            history.location.state !== undefined && history.location.state.from === 'accountPage' && 
                                            <Link className={'modal-trigger accent--bg--text--color'} to="/user/profile"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                                        }
                                        {
                                            history.location.state !== undefined && history.location.state.from === 'settingPage' &&
                                            <Link className={'modal-trigger accent--bg--text--color'} to="/user/settings"><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                                        }
                                    </div>
                                </div>
                                    <div class="details snapshot-details">
                                    <div class="items">
                                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            {/* <label>Show</label>
                                            <Select
                                                styles={{width: '200px'}}
                                                options={pageSizeOption}
                                                isClearable={false}
                                                value={{
                                                    label: perPage,
                                                    value: perPage,
                                                }}
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999999 }),
                                                    valueContainer: provided => ({ 
                                                        ...provided, 
                                                        width: '50px',
                                                    })
                                                }}
                                                onChange={(_selectedOption) => {setPerPage(_selectedOption.value)}}
                                            />
                                            <label>Records</label> */}
                                        </div>
                                        <div class="record_search">
                                            <input type="text" placeholder="Search user" class="search" onChange={(e) => setQuery(e.target.value)}/>
                                            <div class="search_icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                                </svg>
                                            </div>							
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr class="header">
                                                <th>
                                                    Full name
                                                    <svg onClick={() => {
                                                        setSortBy('full_name');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                    style={{cursor: 'pointer', float: 'right'}}
                                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                                    </svg>
                                                </th>
                                                <th>
                                                    Email
                                                    <svg onClick={() => {
                                                        setSortBy('email');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                    style={{cursor: 'pointer', float: 'right'}}
                                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                                    </svg>
                                                </th>
                                                <th>
                                                    User Status
                                                    <svg onClick={() => {
                                                        setSortBy('status');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                    style={{cursor: 'pointer', float: 'right'}}
                                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                                    </svg>
                                                    </th>
                                                    <th>
                                                        User Type
                                                        <svg onClick={() => {
                                                            setSortBy('status');
                                                            if (sortType === 'asc') {
                                                                setSortType('desc');
                                                            } else {
                                                                setSortType('asc');
                                                            }
                                                        }}
                                                            style={{ cursor: 'pointer', float: 'right' }}
                                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                            <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                                        </svg>
                                                    </th>
                                                   <th>
                                                    Created At
                                                    <svg onClick={() => {
                                                        setSortBy('name');
                                                        if (sortType === 'asc') {
                                                            setSortType('desc');
                                                        } else {
                                                            setSortType('asc');
                                                        }
                                                    }}
                                                    style={{cursor: 'pointer', float: 'right'}}
                                                    width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                        <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                                    </svg>
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="tbody_content">
                                            {renderData()}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    true && (
                                        <div className="mt-3">
                                            <TablePagination
                                                component="div"
                                                count={total}
                                                page={currentPage}
                                                onChangePage={handleChangePage}
                                                rowsPerPage={perPage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Fragment>
                    )
                }
			</div>
            {
                modalVisible && (
                    <Popup
                        hideModal={(isReset) => {
                            if(isReset) {
                                setReset(reset + 1);
                            }
                            setDataToEdit(null);
                            setModalVisible(false);
                        }}
                        title={'Sub User'}
                        user={props.user}
                        data={dataToEdit}
                    />
                )
            }
            {
                snapShotModalVisible && (
                    <SnapShotPopup
                        hideModal={() => {
                            setDataToEdit(null);
                            setSnapShotModalVisible(false);
                        }}
                        title={'Quick Clone'}
                        user={props.user}
                        data={dataToEdit}
                    />
                )
            }
            {
                transferDataModalVisible && (
                    <TransferDataPopup
                        hideModal={() => {
                            setReset(reset + 1);
                            setDataToEdit(null);
                            setTransferDataModalVisible(false);
                        }}
                        title={'Transfer Data'}
                        user={props.user}
                        data={dataToEdit}
                    />
                )
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Team);