import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { requestAuthCode, getAppInfo } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";
import Styles from "../appList/appListHeader/createAppModal/ApplistModal.module.css";
import { AppListStyles } from "./AppListStyles";
import { CloseIcon } from "../ten-dlc/helpers/Icon";

const AppAuthorization = (props) => {
  const [open, setOpen] = useState(false);
  const [invalidModalOpen,setInvalidModalOpen]=useState(false)
  const [appName,setAppName]=useState("")
  const [loading, setLoading] = useState(true);
  const [appInfo, setAppInfo] = useState(null);
  const user = Utils.getAccountData("userId");
  const query = new URLSearchParams(props.location.search);
  let client_id = query.get("clientId");
  const [clientId, setClientId] = useState(client_id);

  const { modalFooter } = AppListStyles();

  const useStyles = makeStyles({
    root: {
      marginTop: "8px",
      minWidth: "100px",
    },
    dividerPadding: {
      marginTop: "5px",
      marginBottom: "5px",
    },
    label: {
      fontSize: "13px !important",
      color: "#797979",
    },
  });
  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAppInfo(clientId, {})
      .then((res) => {
        if (res.status === "success") {
          setAppInfo(res.data.resourcePermission);
          if(res.data.authorizationApp.appName){
            setAppName(res.data.authorizationApp.appName)
          }
          setOpen(true);
        } else {
          setInvalidModalOpen(true)
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setInvalidModalOpen(true)
     
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, clientId]);

  const handleAuthorize = () => {
    try {
      if (clientId && user) {
        requestAuthCode({ client_id: clientId })
          .then((res) => {
            if (res.status === "success" && res.data.redirectUri) {
              Utils.showNotification("Authorization Successfull", "success");
              window.location.href = res.data.redirectUri;
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
          })
          .finally(() => {
            //
          });
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const { flexCenterBetween } = AppListStyles();
  const classes = useStyles();
  return (
   <Box>
     <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
    >
      <Paper className={Styles.modalInner}>
        {loading && (
          <div className={Styles.loaderContainer}>
            <Loader />
          </div>
        )}
        <Box className={flexCenterBetween} px={3} pt={3}>
             <Typography variant="h5">{appName}</Typography> 
          <a href="/">
          <IconButton size="small" color="secondary" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
          </a>
        </Box>
        <div className={Styles.paddingWrp}>
        <Typography className={Styles.checkbox_header}>
            Scopes
              </Typography>
          {appInfo?.map((resourceData, i) => {
            return (
              <Box key={i}>
                <Divider className={classes.dividerPadding} />
                <Box sx={{ display: "flex", gap: "50px" }}>
                  <Typography
                    className={`${classes.root} ${Styles.modal_fontSize}`}
                  >
                    {resourceData.resourceName.replace("_", " ")}
                  </Typography>
                  <Grid container>
                    {resourceData.actions.map((data, i) => (
                      <Grid item xs={4} key={i}>
                        <FormControlLabel
                          classes={{ label: classes.label }}
                          control={
                            <Checkbox
                              size="small"
                              checked={data.hasPermission}
                              name={`${resourceData.resourceName}-${data.actionName}`}
                              color="primary"
                              onChange={() => {}}
                              disabled
                            />
                          }
                          label={data.actionName}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )
          })}
        </div>

        {appInfo && (
          <div className={modalFooter}>
            <Button
              variant="contained"
              className={Styles.submit}
              color="primary"
              size="small"
              onClick={handleAuthorize}
            >
              Allow
            </Button>
         <a href="/">
            <Button
              variant="outlined"
              color="secondary"
              onClick={onCloseModal}
              size="small"
            >
              Deny
            </Button>
          </a>
          </div>
        )}
      </Paper>
    </Modal>

    <Modal
      open={invalidModalOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
    >
      <Paper className={Styles.modalInner}> 
        <Box className={flexCenterBetween} p={4}>
             <Typography color="error" variant="h5">App cannot be authenticated !</Typography> 
          <a href="/">
          <IconButton size="small" color="secondary" onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
          </a>
        </Box>  
      </Paper>
    </Modal>
   </Box>
  );
};

export default AppAuthorization;
