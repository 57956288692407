import { Box, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { requestAuthCode } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import Loader from "../globals/Loader";

const AppAuthorizeUrl = () => {
  const { clientID } = useParams();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [redirected, setRedirected] = useState(false);
  const user = Utils.getAccountData("userId");

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        if (clientID && user && !redirected) {
          const res = await requestAuthCode({ client_id: clientID });

          if (isMounted && res.status === "success" && res.data.redirectUri) {
            setRedirectUrl(res.data.redirectUri);
            window.location.href = res.data.redirectUri;
            setRedirected(true);
          }
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      setRedirectUrl("");
      setRedirected(false);
    };
  }, [clientID, user]);

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Paper variant="outlined">
        <Box p={2} textAlign={"center"} width={800} minHeight={80}>
          {redirectUrl ? (
            <Typography variant="h6">Redirecting to - {redirectUrl}</Typography>
          ) : (
            <Loader />
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default AppAuthorizeUrl;
