import React from "react";
import { Skeleton } from "@material-ui/lab";

const SkeletonComponent = () => {
  return (
    <>
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="listItem"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.11)",
            borderRadius: "8px",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <div
            className="itemHeader"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
            }}
          >
            <div style={{ width: "40%" }}>
              <Skeleton variant="text" width={150} height={20} />
            </div>

            <Skeleton
              variant="rect"
              width={150}
              height={24}
              style={{ borderRadius: "6px" }}
            />
            <div
              className="actions"
              style={{ display: "flex", alignItems: "center", gap: "16px" }}
            >
              <Skeleton variant="circle" width={32} height={32} />
              <Skeleton variant="circle" width={32} height={32} />
              <Skeleton variant="circle" width={32} height={32} />
              <Skeleton
                variant="rect"
                width={64}
                height={32}
                style={{ borderRadius: "6px" }}
              />
            </div>
          </div>

          <div className="itemDetails" style={{ display: "flex" }}>
            <div style={{ marginBottom: "8px", width: "40%" }}>
              <Skeleton
                variant="text"
                width={200}
                height={20}
                style={{ marginRight: "16px" }}
              />
              <Skeleton variant="text" width={120} height={20} />
            </div>
            <div>
              <Skeleton
                variant="text"
                width={220}
                height={20}
                style={{ marginRight: "16px" }}
              />
              <Skeleton variant="text" width={100} height={20} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SkeletonComponent;
