import React, {useState,useEffect} from "react";
import Styles from "./SkipTraceModal.module.css";
import {
    FormControlLabel,
    Modal,
    Paper,
    Button,
    Typography,
    Box,
    Radio,
    FormControl,
    CircularProgress
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {SEARCH_CATEGORY} from "../../../constants/CoreConstants";
import {getCreditSettings} from "../../../api/profileApi";
import {skipTraceRequestApi} from "../../../api/mapList";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#008945",
                padding: "8px 20px",
                borderRadius: "4px",
                width: "40%"
            },
            "&.MuiButton-text": {
                color: "grey"
            },
            "&.MuiButton-contained": {
                color: "white"
            },
            "&.MuiButton-outlined": {
                color: "brown"
            }
        }
    };
});

const cleanNumber = (number) => {
    try{
        if(isNaN(number)){
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split(".");
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, "") + ".00";
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + "0" : parts[1];
        return `${parts[0]}.${decimalPart}`;
    }catch(err){
        return "x.xx";
    }
}

const SkipTraceModal = ({ isOpen, onClose, orderId, selectedIds, updateSkipTraceStatus, startPolling }) => {
    const classes = useStyles();
    const [carrierLookupPrices, setCarrierLookupPrices] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [loadingPrice, setLoadingPrice] = useState(false);
    const carrierLookupCreditTypes = { "CARRIER_LOOKUP": 64, "EMAIL_LOOKUP": 65, "DNC_LOOKUP": 66, "SKIP_TRACING_LOOKUP": 67 };
    const [selectedSearchCategory, setSelectedSearchCategory] = useState([SEARCH_CATEGORY.HOUSE_CURRENT_OWNER]);

    useEffect(() => {
        if (!carrierLookupPrices) {
            setLoadingPrice(true);
            getCreditSettings()
                .then((response)=>{
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            setCarrierLookupPrices(response.data.data);
                        }
                    }
                }).catch((err)=>{
                console.log(err);
            }).finally(()=>{
                setLoadingPrice(false);
            });
        }
    }, []);

    const requestSkipTrace = async () => {
        setSubmitting(true);
        try{
            let payload = {
                mapFetchContactIds: selectedIds,
                mapListOrderId: orderId,
                action: selectedSearchCategory,

            };

            const response = await skipTraceRequestApi(payload);

            if (response && response.data && response.data.success){
                selectedIds.forEach((eid)=>{
                    updateSkipTraceStatus(eid, "PROCESSING");
                });
                startPolling();
                window.showNotification("success", response.data.message);
            }else {
                window.showNotification("success", response.data.message || "Unable to send skip trace request");
            }
            setSubmitting(false);
            onClose();
        }catch (err){
            setSubmitting(false);
            window.showNotification("error", "Unable to send skip trace request");
        }
    };

    const getPrice = (lookupType) => {
        try{
            if (carrierLookupPrices){
                let price = carrierLookupPrices.find((carrierLookupPrice)=>(carrierLookupPrice.type === carrierLookupCreditTypes[lookupType]));
                if (price){
                    return price.value;
                }
            }
            return 0;
        }catch (err){
            return 0;
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {

            }}
            aria-labelledby="enhanced-modal-title"
            aria-describedby="enhanced-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                <Box component="div" className={Styles.paddingWrp}>
                    <Box component="div" className={Styles.modalBody} style={{ position: "relative" }}>
                        {
                            loadingPrice &&
                            <Box component="div" className={Styles.modalBackdrop}>
                                <CircularProgress/>
                            </Box>
                        }

                        <Box className={Styles.mmTexts}>
                            <Typography className={Styles.bodyTitle}>{`Skiptrace will cost $${cleanNumber(getPrice("SKIP_TRACING_LOOKUP"))} for each contact`}</Typography>
                            <Typography className={Styles.descriptionText}>These credits will be deducted from your balance.</Typography>
                        </Box>
                        <Box px={4} className={Styles.mmSearch}>
                            <FormControl onChange={(event)=>{
                                setSelectedSearchCategory([event.target.value]);
                            }} style={{ flexDirection: "row" }}>
                                <FormControlLabel
                                    name="search-category"
                                    value={SEARCH_CATEGORY.HOUSE_CURRENT_OWNER}
                                    checked={selectedSearchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_OWNER)}
                                    control={<Radio color="primary" />}
                                    label="Find current owner"
                                />
                                <FormControlLabel
                                    name="search-category"
                                    value={SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT}
                                    checked={selectedSearchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT)}
                                    control={<Radio color="primary" />}
                                    label="Find current resident"
                                />
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={Styles.modalFooter}>
                        <Button size="large" onClick={requestSkipTrace} className={`${classes.root} ${Styles.submitButton}`} variant="contained" disabled={submitting}>
                            { submitting ? "Tracing..." : "Skiptrace" }
                        </Button>
                        <Button size="large" onClick={onClose} className={`${classes.root} ${Styles.cancelButton}`} variant="contained">
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default SkipTraceModal;