import React from 'react';
import {  Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const CanSpamAddressSkeleton = () => {
  return (
    <div style={{ padding: 16 }}>
      <Typography variant="body1" paragraph>
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="80%" style={{ marginTop: 8 }} />
        <Skeleton variant="text" width="70%" style={{ marginTop: 8 }} />
      </Typography>
      
      <div style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rect" height={40} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rect" height={100} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rect" height={40} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rect" height={40} />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 16 }}>
          <Grid item xs={12} md={3}>
            <Skeleton variant="text" width="60%" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant="rect" height={40} />
          </Grid>
        </Grid>
        <div style={{ marginTop: 16 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rect" height={50} style={{ marginTop: 8 }} />
        </div>
        <div style={{ marginTop: 16 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="rect" height={50} style={{ marginTop: 8 }} />
        </div>
      </div>
    </div>
  );
};

export default CanSpamAddressSkeleton;
