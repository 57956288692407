import { styled } from "@material-ui/core";
import React from "react";
import SalesGoalHeader from "./SalesGoalHeader";
import SalesGoalBody from "./goalsBody";
import {withSalesGoalContext} from "./goalsBody/SalesGoalsContext";

const SalesGoalWrapper = styled("div")(({ theme }) => ({
  background: "white",
  borderRadius: "8px",
  minHeight: "calc(100vh - 180px)",
}));

const SalesGoal = () => {
    return (
        <SalesGoalWrapper>
            <SalesGoalHeader />
            <SalesGoalBody />
        </SalesGoalWrapper>
    );
};

export default withSalesGoalContext(SalesGoal);