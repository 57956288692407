import React, { useState } from 'react';

const SearchItemWithIcon = ({onSearch, placeholder = "Search"}) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
        onSearch(event.target.value); 
    };

    return (
        <div className="search-bar" style={styles.searchBar}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                style={styles.icon}
            >
            <path d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleChange}
                className="search-input"
                style={styles.input}
            />
        </div>
    );
};
const styles = {
    searchBar: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderRadius: '60px',
        padding: '2px 10px',
        width: '100%', // Adjust the width as needed
        boxSizing: 'border-box',
        height: '65%',
    },
    icon: {
        marginRight: '10px',
        marginLeft : '5px',
        color: '#888',
    },
    input: {
        border: 'none',
        outline: 'none',
        flex: 1,
        padding: '5px',
        borderBottom: 'none',
        boxShadow: 'none',
        marginBottom:'0px',
    },
};

export default SearchItemWithIcon;