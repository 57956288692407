import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import emailStyle from './emailStyle.module.css'
import { icons } from '../../callLogs/icon';

const EmailConfirmAlert = (props) => {
  let default_params = {
    type: 'warning',
    title: '',
    description: '',
    highlightText: '',
    cancelText: 'No, Keep it',
    submitText: 'Yes, Delete it',
    onCloseClick: () => {},
    onSubmit: () => {},
    closeOnClickOutside: true,
    width: '570px'
  }
  let data_params = {...default_params, ...props}
  let {
    title, description, closeOnClickOutside, cancelText, submitText, onCloseClick, onSubmit, width
  } = data_params

  const handleSubmit = (onClose) => {
    onSubmit();
    onClose();
  }
  return (
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={emailStyle.mainWrp} style={{ width: width }}>
             <span style={{display:"flex",justifyContent:"center"}}>{icons.emailModalIcon(80)}</span>
            <div className={`${emailStyle.bodyWrp} `}>
              <span className={emailStyle.bodyTitle}>{title}</span>
                {
                    description ? <span className={emailStyle.descriptionText}>{description}</span> : ''
                }
            </div>
            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
              <div className={emailStyle.footerWrp}>
                <span onClick={() => { onCloseClick(); onClose();}} className={`${emailStyle.button} ${emailStyle.cancelButton}`}>{cancelText}</span>
                  <span onClick={() => {handleSubmit(onClose)}} className={`${emailStyle.button} ${emailStyle.submitButton}`}>
                  {submitText}
                  </span>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: closeOnClickOutside,
      overlayClassName: emailStyle.overlay
    })
  )
}
export default EmailConfirmAlert;