import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const VirtualNumberTableSkeleton = () => {
  return (
   <Box width={'100%'}>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
        <Box width={'100%'} display={'flex'} sx={{gap:'10px'}}>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
            <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
             <Box p={1} width={'100%'}> 
                <Skeleton variant="rounded" width={'100%'} height={'50px'}/>
            </Box>
        </Box>
   </Box>
  );
};

export default VirtualNumberTableSkeleton;