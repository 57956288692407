import React, { useState } from "react";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { AppListStyles } from "../AppListStyles";
import AddIcon from "@material-ui/icons/Add";
import HelpIcon from "@material-ui/icons/Help";
import DescriptionIcon from '@material-ui/icons/Description';
import CreateAppModal from "./createAppModal/CreateAppModal";

const AppListHeader = ({ onRenderCallback }) => {
  const [openModal, setOpenModal] = useState(false);
  const { primaryButton } = AppListStyles();

  const handleModalToggle = () => {
    setOpenModal((prevState) => !prevState);
  };

  return (
    <Box mb={2}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}  p={2}>
        <Typography variant="h5">OAuth2 Application</Typography>

          <Box display={'flex'}>
              <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  startIcon={<DescriptionIcon color="primary"/>}
                  onClick={()=>{ window.open("/public-api/doc", "__blank") }}
              >
                  Api Doc
              </Button>
              <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginRight: "10px" }}
                  startIcon={<HelpIcon color="primary"/>}
                  onClick={()=>{ window.open("/public-api/authorization-doc", "__blank") }}
              >
                 Authorization Help
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  className={primaryButton}
                  startIcon={<AddIcon style={{color:'white !important'}}/>}
                  onClick={handleModalToggle}
              >
                  Create App
              </Button>
          </Box>
      </Box>

      <Divider light />

      <CreateAppModal onRenderCallback={onRenderCallback} open={openModal} onClose={handleModalToggle} />
    </Box>
  );
};

export default AppListHeader;
