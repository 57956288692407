import {
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import BasicInput from "../custom/BasicInput";
import BasicSelect from "../custom/BasicSelect";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    "& .MuiDialog-paper": {
      maxWidth: 700,
      minWidth: 600,
    },
    "& .modalHeader": {
      display: "flex",
      justifyContent: "space-between",
      gap: 16,
      alignItems: "center",
      padding: theme.spacing(2),
      borderBottom: "1px solid #3c7ef31a",
      fontSize: 24,
      marginBottom: theme.spacing(2),

      "& .headerLeft": {
        display: "flex",
        alignItems: "center",
        gap: 20,

        "& .headerText": {
          "& .title": {
            fontSize: 22,
            fontWeight: "bold",
            margin: "0 0 8px 0",
            color: "var(--dark_blue)",
          },

          "& .description": {
            fontSize: 16,
            color: "grey",
            margin: 0,
          },
        },
      },
    },
    "& .modalFooter": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: "15px",
      borderTop: "1px solid rgba(148, 157, 178, 0.24)",
      padding: "16px 24px",
      gap: "10px",
      "& .submitButton": {
        "&:hover, &:focus": {
          backgroundColor: "#3f51b5",
        },
      },
    },
    "& .error": {
      color: "var(--red)",
      fontSize: "14px",
    },
  },

  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  section: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "18px",
    marginBottom: theme.spacing(1),
  },
  sectionSubtitle: {
    color: "gray",
    fontSize: 14,
    // fontWeight: 600,
    lineHeight: "16px",
    marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(2),
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: theme.spacing(0.25),
  },
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",

    "& .flexWrapper": {
      display: "flex",
      alignItems: "center",
      gap: 4,
    },
  },
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  },
  checkIconColor: {
    color: "white",
    height: "30px",
    width: "30px",
  },
  outerIconColor: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },
  innerIconColor: {
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "2px solid white",
  },
}));

const AddTagModal = ({ open, onClose }) => {
  const [selectedColor, setSelectedColor] = useState("#346fef");
  const classes = useStyles();

  return (
    <>
      <Dialog
        minWidth={500}
        open={open}
        onClose={onClose}
        className={classes.modalWrapper}
      >
        <div className="modalHeader">
          <div className="headerLeft">
            <img
              src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
              alt=""
              height={50}
            />

            <div className="headerText">
              <h5 className="title">Create a New Tag</h5>
              {/* <p className="description">
                Add a new user to your team and set their permission levels.
              </p> */}
            </div>
          </div>

          <div className="headerRight">
            <IconButton color="secondary">
              <CloseIcon onClick={onClose} />
            </IconButton>
          </div>
        </div>

        <div className="modalBody">
          <div className={classes.contentWrapper}>
            <div className={classes.section}>
              <div>
                {/* <Typography variant="h6" className={classes.sectionTitle}>
                  New User Info
                </Typography> */}

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Tag Name <span style={{ color: "var(--red)" }}>*</span>
                    </Typography>
                    <BasicInput
                      name="fullName"
                      //   value={userInfo.fullName}
                      //   onChange={handleUserInfo}
                      fullWidth
                      placeholder=""
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Type <span style={{ color: "var(--red)" }}>*</span>
                    </Typography>
                    <BasicSelect
                      fullWidth
                      name="category"
                      defaultText="Select an options"
                      value={""}
                      options={[
                        { label: "My Tags", value: "my" },
                        { label: "Global Tag", value: "global" },
                      ]}
                      mapping={{ label: "label", value: "value" }}
                      // onChange={handlePermissionLevel}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ marginBottom: "20px" }}>
                      <Typography>Choose a tag color</Typography>
                      <div style={{ display: "flex", gap: 8, marginTop: 8 }}>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #346fef" }}
                          onClick={() => setSelectedColor("#346fef")}
                        >
                          {selectedColor === "#346fef" && (
                            <div
                              style={{ background: " #346fef" }}
                              className={classes.innerIconColor}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #924cff" }}
                          onClick={() => setSelectedColor("#924cff")}
                        >
                          {selectedColor === "#924cff" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #924cff" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #008951" }}
                          onClick={() => setSelectedColor("#008951")}
                        >
                          {selectedColor === "#008951" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #008951" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: " #f79009" }}
                          onClick={() => setSelectedColor("#f79009")}
                        >
                          {selectedColor === "#f79009" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: " #f79009" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#00bec5" }}
                          onClick={() => setSelectedColor("#00bec5")}
                        >
                          {selectedColor === "#00bec5" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#00bec5" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#1d2939" }}
                          onClick={() => setSelectedColor("#1d2939")}
                        >
                          {selectedColor === "#1d2939" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#1d2939" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={classes.outerIconColor}
                          style={{ background: "#475467" }}
                          onClick={() => setSelectedColor("#475467")}
                        >
                          {selectedColor === "#475467" && (
                            <div
                              className={classes.innerIconColor}
                              style={{ background: "#475467" }}
                            >
                              <CheckIcon
                                className={classes.checkIconColor}
                                fontWeight={600}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <p style={{ textAlign: "center" }}>
                      Admins with the proper permissions can create both global
                      tags and My Tags. Global Tags are shared with all users on
                      your team. My Tags are individual tags only available in
                      your account
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>

        <div className="modalFooter">
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            size="small"
            disableElevation
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="submitButton"
            color="primary"
            size="small"
            disableElevation
            // onClick={handleSubmit}
          >
            Save
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default AddTagModal;
