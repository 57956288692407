import { Collapse, Grid, IconButton, styled, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import { useSelector } from "react-redux";
import { deleteSealsGoalApi } from "../../../../api/productsApi";
import CustomConfirmAlert from "../../common/confirm-alert/CustomConfirmAlert";
import DailyGoalsStats from "./DailyGoalsStats";
import MonthlyGoalStatus from "./MonthlyGoalStatus";
import { useSalesGoalContext } from "./SalesGoalsContext";
import StatSkeleton from "./StatSkeleton";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    "&:focus": { backgroundColor: "transparent !important" },
}));

const EmptyScreen = styled(Typography)(({ theme }) => ({
    height: "272px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white"
}));

const useStyles = makeStyles(()=>({
    tableRow: {
        "&.MuiTableRow-root": {
            cursor: "pointer"
        }
    }
}));

const EachGoalRow = (props) => {
    const { row, onEdit } = props;
    const styles = useStyles();
    const { pipelines, teamUsers } = useSelector((state) => state.productReducer);
    const {fetchData} = useSalesGoalContext();
    
    const handleDelete = async () => {
        try{
            const resp = await deleteSealsGoalApi({ id: row.id });
            if (resp?.success) {
                window.showNotification("success", resp.message);
                fetchData();
            } else {
                window.showNotification("error", resp?.message || "Something went wrong");
            }
        }catch (err){
            window.showNotification("error", "Something went wrong");
        }
    };

    const handleDeleteForever = (event) => {
        event.stopPropagation();

        CustomConfirmAlert({
            onSubmit: () => handleDelete(),
            title: "Are you sure you want to delete this Goal?",
            description:"You cannot undo this action"
        });
    };

    const renderTeamMember = () => {
        const teamMember = teamUsers.find((user) => (user.id === row.userId));
        if (teamMember) {
            return teamMember.full_name
        }
        return null;
    };

    const renderPipelines = () => {
        const matchingPipeLines = pipelines.filter(pipeline => row.pipelineIds.includes(pipeline.id));

        if (matchingPipeLines && Array.isArray(matchingPipeLines)) {
            return matchingPipeLines.map((pipelineName, index) => (<span>{pipelineName.title}{index !== (matchingPipeLines.length - 1) && ", "}</span>));
        }

        return null;
    };

    const handleToggleCollapse = () => {
        props.setOpenCollapseModal((prevState)=>{
            if(prevState === row.id){
                return null;
            }else{
                return row.id;
            }
        });

        if (!props.openCollapseModal){
            props.handleSalesGoalStats(row.id).then();
        }
    };
    const dateTimeConversion = (date) => {
        if (window.formatDateWithoutTimezoneChange) {
          return window.formatDateWithoutTimezoneChange(date, 'UTC', 'MM-DD-YYYY');
        } else {
          return date ;
        }
      };  
      const FormattedStartDate = dateTimeConversion(row.startDate);
      const FormattedEndDate = dateTimeConversion(row.endDate);

    return (
        <React.Fragment>
            <TableRow className={styles.tableRow} onClick={handleToggleCollapse} sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell component="th" scope="row">{renderTeamMember()}</TableCell>
                <TableCell align="center">${row.salesGoal}</TableCell>
                <TableCell align="center">{row.productCount}</TableCell>
                <TableCell align="center">{row.frequency}</TableCell>
                <TableCell align="center">{renderPipelines()}</TableCell>
                <TableCell align="center">{`${FormattedStartDate} - ${FormattedEndDate}`}</TableCell>
                <TableCell align="center">
                    <CustomIconButton size="small" onClick={(event) => {
                        event.stopPropagation();
                        onEdit(row);
                        }}>
                        <EditIcon style={{ fontSize: 18 }} fontSize="12" color="inherit" />
                    </CustomIconButton>
                    <CustomIconButton size="small" onClick={handleDeleteForever}>
                        <DeleteIcon style={{ fontSize: 18 }} fontSize="12" color="error" />
                    </CustomIconButton>
                    <CustomIconButton
                        aria-label="expand row"
                        size="small"
                        >
                        {props.openCollapseModal ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </CustomIconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ padding: 0, background: "rgb(233, 233, 233)" }} colSpan={8}>
                    <Collapse in={props.openCollapseModal} timeout="auto" unmountOnExit>
                        {
                            props.isLoading ?
                            <StatSkeleton/> :
                                (
                                    props.salesGoalStats ?
                                        <Grid container spacing={1} style={{ padding: 8 }}>
                                            <Grid item xs={12} md={8}>
                                                <DailyGoalsStats
                                                    data={props.salesGoalStats}
                                                    row={row}
                                                    renderTeamMember={renderTeamMember}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <MonthlyGoalStatus
                                                    data={props.salesGoalStats}
                                                    row={row}
                                                />
                                            </Grid>
                                        </Grid> :
                                        <Grid container spacing={1} style={{ padding: 8 }}>
                                            <Grid item md={12}>
                                                <EmptyScreen>
                                                    <Typography>No data available</Typography>
                                                </EmptyScreen>
                                            </Grid>
                                        </Grid>
                                )
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default EachGoalRow;