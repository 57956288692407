import {
    Box,
    Checkbox,
    makeStyles,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
  } from "@material-ui/core";
  import AddIcon from "@material-ui/icons/Add";
  import React, { useEffect, useState } from "react";


  import { CustomScrollbar } from "../../../productsStyle";

import EachInsuranceCompaniesTableItem from "./EachInsuranceCompaniesTableItem";
import { CustomButton } from "./CustomButton";
import AddOrEditNewCompanyModal from "../AddOrEditNewCompanyModal";
  
  const PolicyTypeListWrapper = styled("div")({
    marginTop: 18,
    "& .policyTypeItem": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 16,
      border: "1px solid #E5E8EF",
      borderRadius: 6,
    },
    "& .policyCategoryItem": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 18,
      border: "1px solid #346fef",
      borderRadius: 6,
    },
  });
  
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#fafafa",
      zIndex: 3,
      position: "sticky",
      top: 0,
    },
    tableHeader: {
      color:'var( --deep_dark_blue)',
      fontSize: "16px",
      whiteSpace: "nowrap",
      fontWeight:600
    },
    tableBodyHeight: {
      height: `calc(100vh - 345px) !important`,
      overflowY: "auto",
      marginTop: "16px",
    },
    topbarNewProductButton: {
      fontWeight: 600,
      fontSize: "16px",
      background: "#346fef",
      color: "white",
      "&:focus,&:hover": {
        background: "#346fef",
      },
    },
    checkbox: {
      color: 'rgba(0, 0, 0, 0.54) !important',
      '&.Mui-checked': {
        color: '#343fef !important',
      }
    }
  });
  
  const InsuranceCompaniesTable = ({
    insuranceCompanyList,
    folderList,
    handleFolderList,
    selectedFolder,
    setInsuranceCompanyList,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    total,
    folderObject,
    onUpdateCompanyName,
    setFilterTrigger,
    filterTrigger,
    setSelectedCompanies,
    selectedCompanies,
}) => {
    const [openAddModal, setOpenAddModal] = useState(false);
    const classes = useStyles();
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
      if(!selectedCompanies[0]){
        setAllChecked(false);
      }
    }, [selectedCompanies]);

    const handleAllChecked = () => {
      if ((!allChecked && selectedCompanies[0]) || insuranceCompanyList.length !== selectedCompanies.length ) {
        const newSelectedCompanies = insuranceCompanyList.map((item) => item.id);
        setAllChecked(true);
        setSelectedCompanies(newSelectedCompanies);
      } else {
        setAllChecked(false);
        setSelectedCompanies([]);
      }
    };

    const handleContactCheckboxChange = (id) => {
      setSelectedCompanies((prevSelected) => {
        const newSelected = prevSelected.includes(id)
          ? prevSelected.filter((companyId) => companyId !== id)
          : [...prevSelected, id];
        if(newSelected.length === insuranceCompanyList.length){
          setAllChecked(true);
        }
        else  {
          setAllChecked(false);
        } 
    
        return newSelected;
      });
    };

    const handleCloseModal = () => {
        setOpenAddModal(false);
      };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setFilterTrigger(filterTrigger+1);
        setSelectedCompanies([]);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
        setFilterTrigger(filterTrigger+1);
        setSelectedCompanies([]);
      };

      return (
        <PolicyTypeListWrapper>
           { (insuranceCompanyList && insuranceCompanyList[0]) ? 
            <>
          <CustomScrollbar className={`${classes.tableBodyHeight}`}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeader} width={"25%"}>
                    <Checkbox
                      checked={allChecked}
                      indeterminate={
                        selectedCompanies.length > 0 &&
                        selectedCompanies.length < insuranceCompanyList.length
                      }
                      size="small"
                      onClick={handleAllChecked}
                      className={classes.checkbox}  
                    />
                      Insurance Companies
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                    Insurance Companies Category
                    </TableCell>
                    <TableCell align={"center"} className={classes.tableHeader}>
                      Status
                    </TableCell>
                    <TableCell align={"center"} className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
  
                <TableBody>
                  {insuranceCompanyList.map((company) => (
                    <EachInsuranceCompaniesTableItem key={company.id} 
                    company={company}
                    folderList={folderList}
                    handleFolderList={handleFolderList}
                    selectedFolder={selectedFolder}
                    setInsuranceCompanyList={setInsuranceCompanyList}
                    folderObject={folderObject}
                    onUpdateCompanyName={onUpdateCompanyName}
                    isChecked={selectedCompanies.includes(company.id)}
                    onCheckboxChange={() => handleContactCheckboxChange(company.id)}
                     />
                  )) 
                  }
                </TableBody>
              </Table>
            </TableContainer>
           
          </CustomScrollbar>
          <TablePagination
                  rowsPerPageOptions={[10,25,50,100]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </> : 
                 <div className={`${classes.tableBodyHeight}`} style={{ display: "flex" }}>
                 <Box
                   sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     width: "60%",
                   }}
                 >
                   <div>
                     <Typography align="center" variant="h5">
                       You haven't added any Company Name yet!
                     </Typography>
                     <Typography
                       variant="body1"
                       align="center"
                       style={{ color: "gray" }}
                     >
                       Click the button below to get started.
                     </Typography>
                     <Box display={"flex"} justifyContent={"center"} mt={2}>
                       <CustomButton
                         variant="contained"
                         onClick={() => setOpenAddModal(true)}
                         startIcon={<AddIcon />}
                         disableElevation
                       >
                          New Company
                       </CustomButton>
                     </Box>
         
                     
               {openAddModal && (
                 <AddOrEditNewCompanyModal
                   open={openAddModal}
                   onClose={handleCloseModal}
                   setInsuranceCompanyList={setInsuranceCompanyList}
                   folderList={folderList}
                   handleFolderList={handleFolderList}
                   selectedFolder={selectedFolder}
                 />
               )}
                   </div>
                 </Box>
                 <Box sx={{ width: "40%", display: "flex", alignItems: "center" }}>
                   <img
                     height={300}
                     src={`${process.env.REACT_APP_CDN_LINK}assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png`}
                     alt="Empty data"
                   />
                 </Box>
               </div>}
        </PolicyTypeListWrapper>
      );
    // }
  };
  
  export default InsuranceCompaniesTable;