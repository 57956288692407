import React, { useEffect, useState } from "react";
import Styles from "./Styles.module.css";
import PhoneSettings from "../phoneSettings/PhoneSettings";
import TrustCenter from "../trustCenter/TrustCenter";
import { useHistory, useLocation } from "react-router-dom";
import ShakenStirCnam from "../shakenStirCnam/ShakenStirCnam";
import { getA2pTrustCenterStatusApi, getUserA2PRegistrationTypeApi } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import CallOutcome from "../settings/CallOutcome";
import CallLogs from "../callLogs/CallLogs";
import CheckFullInfo from "../callLogs/CheckFullInfo";

const telephonyTabs = {
    PHONE_NUMBERS: "/user/telephony-service/phone-settings",
    TRUST_CENTER: "/user/telephony-service/trust-center",
    SHAKEN_STIR_CNAM: "/user/telephony-service/shaken-stir-cnam",
    CALL_OUTCOME: "/user/telephony-service/call-outcome",
    CALL_LOGS: "/user/telephony-service/call-logs",
    CALL_LOGS_DETAILS: "/user/telephony-service/call-logs-details",
};

const TelephonyService = () => {
    const history = useHistory();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [a2pRegistration, setA2pRegistration] = useState(null);
    const [a2pRegistrationUpdate, setA2pRegistrationUpdate] = useState(null);
    const [campaignUseCase, setCampaignUseCase] = useState(null);
    const [campaignUseCaseUpdate, setCampaignUseCaseUpdate] = useState(null);
    const [registrationType, setRegistrationType] = useState(null);
    const [tabs, setTabs] = useState([
        {
            code: telephonyTabs.PHONE_NUMBERS,
            label: "Phone Numbers",
        },
        {
            code: telephonyTabs.CALL_OUTCOME,
            label: "Call Outcomes",
        },
        {
            code: telephonyTabs.CALL_LOGS,
            label: "Call Logs",
        },
    ]);

    useEffect(() => {
        // Get the parent user ID
        const parentUser = Utils.getAccountData('parentId');

        // Get the Canadian users string from the environment variable and split it into an array
        let canadianUsers = process.env.REACT_APP_CANADIAN_USER;

        // Check if the parent user is in the array of Canadian users
        if (Utils.getAccountData("userIsOwner") && !canadianUsers.includes(parentUser)) {
            geta2pInfos();
        } else {
            setLoading(false);
            setLoaded(true);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const geta2pInfos = () => {
        let tempTabs = [...tabs];
        getA2pTrustCenterStatusApi()
            .then((res) => {
                res = res.data;
                if (res.success) {
                    if (res.data && res.data.a2pRegistration) {
                        setA2pRegistration(res.data.a2pRegistration);
                    }
                    if (res.data && res.data.a2pRegistrationUpdate) {
                        setA2pRegistrationUpdate(res.data.a2pRegistrationUpdate);
                    }
                    if (res.data && res.data.campaignUseCase) {
                        setCampaignUseCase(res.data.campaignUseCase);
                    }
                    if (res.data && res.data.campaignUseCaseUpdate) {
                        setCampaignUseCaseUpdate(res.data.campaignUseCaseUpdate);
                    }

                    tempTabs.push({
                        code: telephonyTabs.TRUST_CENTER,
                        label: "Trust Center",
                    });

                    tempTabs.push({
                        code: telephonyTabs.SHAKEN_STIR_CNAM,
                        label: "Shaken/Stir",
                    });
                } else {
                    tempTabs.push({
                        code: telephonyTabs.SHAKEN_STIR_CNAM,
                        label: "Shaken/Stir",
                    });
                }
            })
            .catch((err) => {
                setA2pRegistration(null);
                setCampaignUseCase(null);
            })
            .finally(() => {
                setLoading(false);
                setLoaded(true);
                setTabs(tempTabs);
            });

        getUserA2PRegistrationTypeApi()
            .then((res) => {
                res = res.data;
                if (res && res.success && res.userA2pRegistrationType) {
                    setRegistrationType(res.userA2pRegistrationType);
                }
                setLoading(false);
            })
            .catch((err) => {
                setRegistrationType(null);
                setLoading(false);
            })
            .finally(() => {
            });
    };


    const getTabs = () => {
        switch (activeTab) {
            case telephonyTabs.PHONE_NUMBERS:
                return <PhoneSettings
                    registrationType={registrationType}
                />;
            case telephonyTabs.TRUST_CENTER:
                return <TrustCenter
                    a2pRegistration={a2pRegistration}
                    a2pRegistrationUpdate={a2pRegistrationUpdate}
                    campaignUseCase={campaignUseCase}
                    campaignUseCaseUpdate={campaignUseCaseUpdate}
                    loading={loading}
                    geta2pInfos={geta2pInfos}
                    loaded={loaded}
                />;
            case telephonyTabs.SHAKEN_STIR_CNAM:
                return <ShakenStirCnam
                    registrationType={registrationType}
                />;
            case telephonyTabs.CALL_OUTCOME:
                return <CallOutcome />;
            case telephonyTabs.CALL_LOGS:
                return <CallLogs />;
            case telephonyTabs.CALL_LOGS_DETAILS:
                return <CheckFullInfo />;
            default:
                return <PhoneSettings
                    registrationType={registrationType}
                />;
        }
    };

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                {
                    activeTab !== telephonyTabs.CALL_LOGS_DETAILS &&
                    <div className={Styles.pageHeaderLeft}>
                        <div className={Styles.pageTab}>
                            {
                                tabs.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={`${Styles.pageTabItem} ${tab.code === activeTab ? Styles.activeTab : ""}`}
                                        onClick={() => {
                                            setActiveTab(tab.code)
                                            history.push(tab.code)
                                        }}>
                                        {tab.label}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
                <div className={Styles.pageBody}>
                    {getTabs()}
                </div>
            </div>
        </div>
    );
};

export default TelephonyService;
