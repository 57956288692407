import React, { useEffect, useRef, useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import CreateIcon from "@material-ui/icons/Create";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { incomingCallTimeoutUpdate } from "../../../api/profileApi";
import { Popover, Typography } from "@material-ui/core";

const IncomingCallTimeout = ({ item }) => {
  const inputField = useRef(null);
  const [validation, setValidation] = useState(false);
  const [validationText, setValidationText] = useState("");
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fieldValue, setFieldValue] = useState(item.incoming_call_timeout);
  const [mouseOver, setMouseOver] = useState(false);
  const [defaultValue, setDefaultValue] = useState(item.incoming_call_timeout);
  const inputRef = useRef(null);
  const [editedFieldValue, setEditedFieldValue] = useState(
    item.incoming_call_timeout
  );
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  useEffect(() => {
    const handleClick = (event) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        return;
      }
      handleCrossPress();
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [fieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCrossPress = () => {
    setEdit(false);
    setValidation(false);
    setValidationText("");
    setAnchorEl(null);
    setEditedFieldValue(fieldValue)
  };

  const handleEnterPress = (event) => {
    var code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      handleSubmit();
    }
  };

  const handleCheckPress = () => {
    handleSubmit();
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("id", item.id);
    formData.append("incoming_call_timeout", editedFieldValue);

    setSaving(true);
    incomingCallTimeoutUpdate(formData).then((res) => {
      setSaving(false);
      if (res.data.status === "validation-error") {
        window.showNotification("ERROR", res.data.message);
      } else if (res.data.status === "error") {
        window.showNotification("ERROR", res.data.message);
      } else if (res.data.status === "success") {
        setDefaultValue(editedFieldValue);
        setFieldValue(editedFieldValue);
        setAnchorEl(null);
        window.showNotification("SUCCESS", "Incoming Call Timeout changed");
        handleCrossPress(false);
      }
    });
  };



  const divStyle = {
    width: "120px",
    border: mouseOver ? "1px solid #000" : "1px solid transparent",
    borderRadius: "8px",
    transition: "border 0.3s ease, padding 0.3s ease",
    paddingLeft: "8px",
    paddingRight: "8px",
    cursor: "pointer",
    minHeight: "40px",
  };

  const inputStyle = {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    margin: "0px",
    width: "100%",
    pointerEvents: "none",
  };

  const inputEditStyle = {
    backgroundColor: "transparent",
    marginTop: "4px",
    marginBottom: "4px",
    width: "100%",
    fontSize: "inherit",
    padding: "4px",
    boxShadow: "none",
    textDecoration: "none",
    border: "1px solid gray",
    borderRadius: "8px",
  };

  const dropdownStyle = {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    width: "250px",
    padding: "8px",
  };

  const handleDivClick = (event) => {
    if (!fieldValue) {
      setEdit(true);
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={divStyle}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onClick={handleDivClick}
        ref={dropdownRef}
      >
        <input
          style={inputStyle}
          value={fieldValue ? fieldValue : ""}
          type="text"
          disabled={!edit}
          defaultValue={fieldValue}
          placeholder={edit ? "Enter Forward number" : ""}
        />
      </div>

      <Popover
        ref={inputRef}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={dropdownStyle}>
          <div className="d-flex align-items-center justify-content-between">
            {validation && (
              <small className="text-danger">{validationText}</small>
            )}
          </div>
          <div>
            <Typography># of seconds before sending to voicemail :</Typography>
            {edit && (
                <>
              <style>
                {`
                input[type='number']::-webkit-inner-spin-button,
                input[type='number']::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type='number'] {
                    -moz-appearance: textfield;
                }
                `}
              </style>
              <input
                ref={inputField}
                value={editedFieldValue}
                onKeyPress={handleEnterPress}
                onChange={(e) => setEditedFieldValue(e.target.value)}
                type={edit ? "number" : "text"}
                disabled={!edit}
                placeholder={edit ?  "Enter Forward number" : ""}
                style={inputEditStyle}
              />
            </>)}
           {!edit && ( 
            <>
              <Typography>{fieldValue}</Typography>
            </>)}
            <>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!edit && (
                  <div style={{ display: "flex", gap: "16px" }}>
                    <BootstrapTooltip arrow title={`Update Forward Number`}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEdit(true);
                        }}
                      >
                        <CreateIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                    {saving && (
                      <BootstrapTooltip arrow title={`Updating  Number`}>
                        <span>
                          <MoreHorizIcon fontSize="small" />
                        </span>
                      </BootstrapTooltip>
                    )}
                  </div>
                )}
              </div>
              {edit && !saving && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "16px",
                    marginTop: "10px",
                  }}
                >
                  {(editedFieldValue && editedFieldValue !== defaultValue) && (
                    <BootstrapTooltip arrow title="Confirm">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={handleCheckPress}
                      >
                        <DoneIcon fontSize="small" />
                      </span>
                    </BootstrapTooltip>
                  )}
                  <BootstrapTooltip arrow title="Cancel">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={handleCrossPress}
                    >
                      <ClearIcon fontSize="small" />
                    </span>
                  </BootstrapTooltip>
                </div>
              )}
            </>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default IncomingCallTimeout;
