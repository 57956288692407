import {sendRequest} from './rootApi';
import Api from "../constants/Api";

export const getCallLog = async (payload) => {
    return sendRequest("post", Api.getCallLog, payload);
}

export const callOutcomeListForCallLogDropdown = async (payload) => {
    return sendRequest("post", Api.callOutcomeListForCallLogDropdown, payload);
}

export const getUserAndVirtualNumberArray = async (payload) => {
    return sendRequest("post", Api.getUserAndVirtualNumberArray, payload);
}

export const getOutgoingCallCount = async (payload) => {
    return sendRequest("post", Api.getOutgoingCallCount, payload);
}

export const getOutgoingCallOutcome = async (payload) => {
    return sendRequest("post", Api.getOutgoingCallOutcome, payload);
}