import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CustomScrollbar } from "../../products/productsStyle";
import AddCustomField from "./modal/add-field/AddCustomField";
import { customFieldInsuranceState } from "../../products/common/productSelector";
import { useDispatch, useSelector } from "react-redux";
import { getCustomField, handlePageChange, handleRowChange, handleSearch, userCustomFieldsOrderChange } from "../../../actions/CustomFieldInsuranceAction";
import BasicInput from "../../custom/BasicInput";
import useDebounce from "../../../hooks/useDebounce";
import GlobalEmptyPage from "../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import EachContantItem from "./EachContantItem";
import CustomDrawerPype from "../../common/drawer/CustomDrawerPype";
import { EMPTY_DATA_IMG } from "../../../constants/CoreConstants";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SkeletonComponent from "./ContantSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    padding: "16px",
    flex: 1,

    "& .headWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: "1px solid #cdcdcd",
    },

    "& .newFieldButton": {
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      width:'250px !important',
      "& .search": {
        border: "1px solid rgb(167 167 167 / 65%) !important",
        margin: 0,
        "&:focus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
      },
    },

    "& .customFieldList": {
      "& .listItem": {
        padding: 8,
        border: "1px solid #e1e1e1",
        borderRadius: 4,
        marginBottom: 8,

        "&:not(:last-child)": {
          marginBottom: 8,
        },

        "& .itemHeader": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 8,
          marginBottom: 8,
          borderBottom: "1px solid #e1e1e1",

          "& .title": {
            fontSize: 15,
            fontWeight: 600,
            color:'var(--dark_blue)'
          },

          "& .actions": {
            display: "flex",
            alignItems: "center",
            gap: 16,
            width:'300px',
            "& .MuiChip-label":{
              width:'100px !important',
              textAlign:'center'
            },
            "& .privateChip": {
              marginRight: 40,
              backgroundColor: "#00ff91",
               width:'170px !important'
            },
            "& .globalChip": {
              marginRight: 40,
              backgroundColor: "#6258FF",
              color:'#FFF',
               width:'170px !important'
            },
          },
        },

        "& .itemDetails": {
          paddingInline: 16,
          "& .title": {
            fontSize: 15,
            fontWeight: 600,
            color:'var(--dark_blue)'
          },
          "& .sub": {
            fontSize: 14,
            color: "#546376",
          },
        },
      },
    },
  },
}));

const Reorder = (list, startIndex, endIndex) => {
  try {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  } catch (error) {
    return [];
  }
};


const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? "lightgreen" : "inherit",
  borderRadius: "7px",
  ...draggableStyle,
});

const Content = () => {
  const { totalFields, fields, customFieldParams, gettingFields } = useSelector(customFieldInsuranceState);
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const debouncedSearch = useDebounce(search, 1000);


  useEffect(() => {
    dispatch(
      getCustomField({
        ...customFieldParams,
        page: customFieldParams.page + 1
      })
    );
  }, [customFieldParams]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(debouncedSearch !== null){
      dispatch(handleSearch(debouncedSearch));
    }
  },[debouncedSearch]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchInput = (e) => {
    setSearch(e.target.value?.trim());
  };

  const handleChangePage = (event, newPage) => {
    dispatch(handlePageChange(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(handleRowChange(event.target.value));
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination || destination.index === source.index) {
      return;
    }

    const reOrderedFields = Reorder(fields, source.index, destination.index);

    const submitData = {
      id: fields[source.index].id,
      group_id: customFieldParams.group_id,
      destination_index: destination.index,
      source_index: source.index,
      destination_id: fields[destination.index].id,
    };
    dispatch(userCustomFieldsOrderChange({ submitData, reOrderedFields }));
  };


  return (
    <div className={classes.root}>
      <div className="headWrapper">
        <div class="record_search">
          <BasicInput
            placeholder="Search By Field Name"
            onChange={(e) => handleSearchInput(e)}
          />
          <div class="search_icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#133159"
                fill-opacity="0.65"
              ></path>
            </svg>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="newFieldButton"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() => setIsDrawerOpen(true)}
        >
          New Custom Field
        </Button>
      </div>
      {gettingFields ? (
        <SkeletonComponent/>
      ) : Array.isArray(fields) && fields[0] ? (
       <div style={{display:'flex', gap:'20px', width:'100%'}}>
         <div style={{width:'75%'}}>
          <CustomScrollbar style={{ height: "calc(100vh - 410px)" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="customFieldList"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fields.map((field, index) => (
                      <Draggable
                        key={field.id.toString()}
                        draggableId={field.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <EachContantItem field={field} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CustomScrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalFields}
            rowsPerPage={customFieldParams.limit}
            page={customFieldParams.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div style={{width:'25%', height: "calc(100vh - 410px)"}}>
          <img style={{width:'100%', height:'100%', objectFit:'contain'}} 
           src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/custom_field/1.0.0/global_custom_fields_tab.jpeg`}
          alt="custom field" 
          />
        </div>
       </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <GlobalEmptyPage image={EMPTY_DATA_IMG} title="No Fields Found" />
        </div>
      )}

  {isDrawerOpen && 
     <CustomDrawerPype
        isShow={isDrawerOpen}
        hanldeDrawer={(status) => {}}
        renderChild={() => {
          return <AddCustomField onClose={() => setIsDrawerOpen(false)} />;
        }}
      />
    }
    </div>
  );
};

export default Content;