import React from 'react';
import {  Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const GenaralSettingSkeleton = () => {
  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="rect" height={56} />
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            {[...Array(7)].map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton variant="rect" height={50} />
              </Grid>
            ))}
          </Grid>
          <div style={{ marginTop: 16 }}>
            <Skeleton variant="rect" height={36} width={120} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default GenaralSettingSkeleton;
