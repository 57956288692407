import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditNewPolicyTypeModal from "./AddOrEditNewPolicyTypeModal";
import { policyTypeStyle } from "./PolicyTypesStyle";
import { CustomButton } from "./policyTypesBody/CustomButton";

const PolicyTypesHeader = () => {
  const { topbarHeaderTitle } = policyTypeStyle();
  const [openAddPolicyTypeModal, setOpenAddPolicyTypeModal] = useState(false);
  const handleCloseModal = () => {
    setOpenAddPolicyTypeModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <Typography variant="h5" className={topbarHeaderTitle}>
          Manage Policy Types
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
          <CustomButton
            variant="contained"
            onClick={() => setOpenAddPolicyTypeModal(true)}
            startIcon={<AddIcon />}
            disableElevation
          >
            New Policy Type
          </CustomButton>
        </Box>
      </Box>

      {openAddPolicyTypeModal && (
        <AddOrEditNewPolicyTypeModal
          open={openAddPolicyTypeModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default PolicyTypesHeader;
