import React from 'react';
import { Box } from '@material-ui/core';
import RecycleEventHeader from './RecycleEventHeader';
import RecycleEventBody from './RecycleEventBody/RecycleEventBody';


const RecycleEvent = () => {
    return (
        <Box flex={1} sx={{background:'white',borderRadius:'8px'}}>
           <RecycleEventHeader/>
           <RecycleEventBody/>
        </Box>
    );
};

export default RecycleEvent;