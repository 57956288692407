import {
    StyledCancelButton,
    StyledConnectEmailButton,
    StyledConnectEmailContent,
    StyledConnectEmailIconWrapper, StyledConnectEmailWrapper
} from "./ConnectedEmailsStyles";
import { EventNote } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const ConnectEmailCalenderSync = ({ handleCancel }) => {
    const history = useHistory();  
    return (
        <StyledConnectEmailWrapper>
            <StyledConnectEmailContent>
                <StyledConnectEmailIconWrapper>
                    <EventNote />
                </StyledConnectEmailIconWrapper>
                 <h1>Sync your calendar events</h1>
                 <p>Synchronize your connected email calendar events with your account calendar</p>
                 <div>
                    <StyledCancelButton onClick={handleCancel}>Maybe later</StyledCancelButton>
                    <StyledConnectEmailButton onClick={() => { history.push("/user/profile/calendar-sync") }}>Connect calendar</StyledConnectEmailButton>
                 </div>
            </StyledConnectEmailContent>
        </StyledConnectEmailWrapper>
    );
};

export default ConnectEmailCalenderSync;
