import moment from "moment";
import { useContext, useState } from "react";
import { HistoryProvider } from "../../App";
import { NOTIFICATION_ICON, SITE_NOTIFICATION } from "../../constants/CoreConstants";
import { IconList } from "../../constants/IconList";
import Utils from "../../helpers/Utils";

const Notification = ({ notification, statusChange, deleteNotification, index }) => {

    const history = useContext(HistoryProvider);
    const fullName = notification.contact ? notification.contact.first_name + ' ' + notification.contact.last_name : '';
    const number = notification.contact ? notification.contact.number : '';
    const email = notification.contact ? notification.contact.email : '';
    const [checked,setChecked] = useState(false);

    const formatMessage = (message) => {
        let noteText = message;
        let regix = /\@.*?\)/g; // eslint-disable-line no-useless-escape
        let matchStrign = noteText.match(regix);

        if (Array.isArray(matchStrign)) {
            matchStrign.forEach(str => {
                let eachStr = str.match(/@\[(.*)\]\(.*\)/);
                if (eachStr) {
                    noteText = noteText.replace(str, "<b>@" + eachStr[1] + "</b>")
                }
            });
        }

        var content = noteText.replace(/<[^>]*>/g, '');
        return content;
    }

    return ( 
        <div className={`tabs_list_notification ${checked ? 'checked_notifiation' : ''}`}>
            <div className="items_box">
                <div className="checkbox">
                    <form action="">
                        <input type="checkbox" onChange={() => {setChecked(true);statusChange(notification.id,index)}}/>
                    </form>
                </div>
                <div className="userBox">
                    <p>{notification.type === SITE_NOTIFICATION.CAMPAIGN_PAUSE ? IconList.campaignIcon : Utils.getInitial(fullName)}</p>
                    {NOTIFICATION_ICON[notification.type]}
                </div>
                <div className="notification_info">
                    <div className="userName">
                        {
                            notification.type === SITE_NOTIFICATION.CAMPAIGN_PAUSE ?
                            <h6>Campaign Paused <span className="date">{moment(notification.local_created_at.date_time).format('Do MMMM YYYY, h:mm A')}</span></h6> :
                            <h6>{Utils.getContactDisplayName(fullName,number,email,true)} <span className="date">{moment(notification.local_created_at.date_time).format('Do MMMM YYYY, h:mm A')}</span></h6>
                        }
                    </div>
                    <div className="info_msg">
                        <p>{formatMessage(notification.message)}</p>
                    </div>
                </div>
            </div>
            <div className="mark_icons">
                <ul>

                    {
                        notification.type === SITE_NOTIFICATION.CAMPAIGN_PAUSE &&
                        <li>
                            <a href={'/campaign/details/'+notification.permalinkRaw} className="notification__icon" onClick={e => {e.preventDefault();history.push('/campaign/details/'+notification.permalinkRaw)}}>
                                {IconList.campaignIcon}	
                            </a>
                        </li>
                    }

                    {
                        notification.contact_id !== null &&
                        <li>
                            <a href={'/contacts/'+notification.contact_id} onClick={e => {e.preventDefault();history.push('/contacts/'+notification.contact_id)}}>
                                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.27344 0C3.52877 0 2.10938 1.4194 2.10938 3.16406C2.10938 4.90873 3.52877 6.32812 5.27344 6.32812C7.0181 6.32812 8.4375 4.90873 8.4375 3.16406C8.4375 1.4194 7.0181 0 5.27344 0Z" fill="#546376"/>
                                    <path d="M9.21014 8.3952C8.34389 7.51563 7.19552 7.03125 5.97656 7.03125H4.57031C3.35138 7.03125 2.20298 7.51563 1.33673 8.3952C0.474726 9.27045 0 10.4258 0 11.6484C0 11.8426 0.157406 12 0.351562 12H10.1953C10.3895 12 10.5469 11.8426 10.5469 11.6484C10.5469 10.4258 10.0721 9.27045 9.21014 8.3952Z" fill="#546376"/>
                                </svg>	
                            </a>
                        </li>
                    }
                    
                    <li>
                        <a href="#!" onClick={e => {e.preventDefault(); deleteNotification(notification.id,index)}}>
                            <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.26442 1.57692H7.09615V1.18269C7.09615 0.529501 6.56665 0 5.91346 0H4.33654C3.68335 0 3.15385 0.529501 3.15385 1.18269V1.57692H0.985577C0.441267 1.57692 0 2.01819 0 2.5625V3.35096C0 3.5687 0.176492 3.74519 0.394231 3.74519H9.85577C10.0735 3.74519 10.25 3.5687 10.25 3.35096V2.5625C10.25 2.01819 9.80873 1.57692 9.26442 1.57692ZM3.94231 1.18269C3.94231 0.965372 4.11922 0.788461 4.33654 0.788461H5.91346C6.13078 0.788461 6.30769 0.965372 6.30769 1.18269V1.57692H3.94231V1.18269Z" fill="#FF264A"/>
                                <path d="M0.748192 4.53516C0.677846 4.53516 0.621792 4.59395 0.625143 4.66422L0.950383 11.4904C0.980443 12.1221 1.49935 12.6169 2.1316 12.6169H8.11848C8.75073 12.6169 9.26964 12.1221 9.2997 11.4904L9.62494 4.66422C9.62829 4.59395 9.57223 4.53516 9.50189 4.53516H0.748192ZM6.70196 5.52073C6.70196 5.30292 6.87838 5.1265 7.09619 5.1265C7.31401 5.1265 7.49042 5.30292 7.49042 5.52073V10.6457C7.49042 10.8635 7.31401 11.04 7.09619 11.04C6.87838 11.04 6.70196 10.8635 6.70196 10.6457V5.52073ZM4.73081 5.52073C4.73081 5.30292 4.90723 5.1265 5.12504 5.1265C5.34285 5.1265 5.51927 5.30292 5.51927 5.52073V10.6457C5.51927 10.8635 5.34285 11.04 5.12504 11.04C4.90723 11.04 4.73081 10.8635 4.73081 10.6457V5.52073ZM2.75966 5.52073C2.75966 5.30292 2.93607 5.1265 3.15389 5.1265C3.3717 5.1265 3.54812 5.30292 3.54812 5.52073V10.6457C3.54812 10.8635 3.3717 11.04 3.15389 11.04C2.93607 11.04 2.75966 10.8635 2.75966 10.6457V5.52073Z" fill="#FF264A"/>
                            </svg>								
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
 
export default Notification;