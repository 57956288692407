import React from "react";
import { Tab, Tabs, styled } from "@material-ui/core";
import Utils from "../../helpers/Utils";
import { HOME_PAGE_TABS } from "../products/helper/coreConstant";

const TabWrapper = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiTabs-flexContainer": {
    gap: "20px !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#346fef",
  },
  "& .Mui-selected": {
    color: "#346FEF !important",
  },
  "& .tabItem": {
    color: "#133159A6",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const HomePageTab = ({ value, handleChange }) => {
  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === "INSURANCE";
  return (
    <TabWrapper
      value={value}
      onChange={handleChange}
      className="tabWrapper"
      textColor="primary"
      indicatorColor="primary"
    >


      {isAgencyTypeInsurance && (
        <Tab
          className="tabItem"
          value={HOME_PAGE_TABS.POLICY_TYPES}
          label="Policy Types"
        />
      )}

      {isAgencyTypeInsurance && (
        <Tab
          className="tabItem"
          value={HOME_PAGE_TABS.INSURANCE_COMPANIES}
          label="Insurance Companies"
        />
      )}

      {isAgencyTypeInsurance && (
        <Tab
          className="tabItem"
          value={HOME_PAGE_TABS.LOST_REASONS}
          label="Lost Reasons"
        />
      )}

      {isAgencyTypeInsurance && (
        <Tab
          className="tabItem"
          value={HOME_PAGE_TABS.RECYCLE_EVENT}
          label="Recycle Events"
        />
      )}
      {isAgencyTypeInsurance && (
        <Tab
          className="tabItem"
          value={HOME_PAGE_TABS.LEAD_SOURCE}
          label="Lead Sources"
        />
      )}


    </TabWrapper>
  );
};

export default HomePageTab;
