import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { addLeadSource, deleteLeadSource, editLeadSource, getAllLeadSource, getLeadSourceByPagination, _handleDeleteLeadSourceModal, _handleLeadSourceEdit, _handleLeadSourceModal, _handleLeadSourcePagination, _handleLeadSourceReload, _handleLeadSourceSearch } from '../../actions/leadSourceAction';
import { icons } from './icon';
import './lead_source.css'
import 'react-confirm-alert/src/react-confirm-alert.css';

import DeleteLeadSourceModal from './modals/DeleteLeadSourceModal';
import AddLeadSourceModal from './modals/AddLeadSourceModal';
import Utils from "../../helpers/Utils";
import {Link, useHistory} from "react-router-dom";
import NewConfirmAlert from '../activity/new-alert/NewConfirmAlert';
import LeadSourceSkeleton from './LeadSourceSkeleton';
import { BasicInput } from '../products/productsStyle';

const mapStateToProps = state => ({
    leadSourceReducer : state.leadSourceReducer
});

const mapDispatchToProps = dispatch => ({
    addLeadSource: (params, callback) => dispatch(addLeadSource(params, callback)),
    editLeadSource: (params, callback) => dispatch(editLeadSource(params, callback)),
    deleteLeadSource: (params, callback) => dispatch(deleteLeadSource(params, callback)),
    getLeadSourceByPagination: (params, callback) => dispatch(getLeadSourceByPagination(params, callback)),
    getAllLeadSource: (params, callback) => dispatch(getAllLeadSource(params, callback)),
    _handleLeadSourceModal: (params) => dispatch(_handleLeadSourceModal(params)),
    _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
    _handleLeadSourceSearch: (params) => dispatch(_handleLeadSourceSearch(params)),
    _handleLeadSourceReload: (params) => dispatch(_handleLeadSourceReload(params)),
    _handleLeadSourcePagination: (params) => dispatch(_handleLeadSourcePagination(params)),
    _handleDeleteLeadSourceModal: (params) => dispatch(_handleDeleteLeadSourceModal(params)),
})

const LeadSourceApp = (props) => {
    const {show_modal, show_delete_modal} = props.leadSourceReducer
    return(
            <div className="container-fluid mail__inbox">
                {/* head */}
                <Header />
                <div className="details">
                    
                    {/* search */}
					<Search />

                    {/* table */}
					<Table />
                </div>

                {/* pagination */}
				<Pagination />

                {show_modal &&
                <AddLeadSourceModal />
                }

				{show_delete_modal &&
                <DeleteLeadSourceModal />
                }

            </div>
    )

}
const LeadSource = connect(mapStateToProps, mapDispatchToProps)(LeadSourceApp);
export default LeadSource;


const HeaderApp = props => {

	const history = useHistory();

    const handleAddLeadSourceModal = (e) => {
        e.preventDefault()
        props._handleLeadSourceModal(true)
    }

	useEffect(() => {
		document.title = `Lead Source | ${Utils.getAccountData('AuthUserAgencyName')}`;
		if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
	},[]);

    return(
    	<>
        <div className="tagBox lead_source_page">
            <div className="tag_list">
                <h5>Lead Source </h5>
            </div>
            <div className="add_new_user ">
                <a href="#!" className="modal-trigger accent--bg--text--color" onClick={handleAddLeadSourceModal}>
                    <span>{icons.plus}</span>Add New Lead Source
                </a>
				{
					history.location.state !== undefined && history.location.state.from === 'settingPage' &&
					<Link to="/user/settings" className={"modal-trigger accent--bg--text--color"}>
						<span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path>
							</svg>
						</span>
						<span>Back</span>
					</Link>
				}
            </div>
        </div>
		</>
    )
}
export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);


const SearchApp = props => {
	const {per_page, search_text} = props.leadSourceReducer
	const [perPage, setPerPage] = useState(per_page)
	const [search, setSearch] = useState(search_text)
	
	const handlePerPageChange = (event) => {
		setPerPage(event.target.value)
		props._handleLeadSourcePagination({perPage: event.target.value})
	}

	const handleSearchChange = (event) => {
		setSearch(event.target.value)
		props._handleLeadSourcePagination({currentPage: 1})
		props._handleLeadSourceSearch(event.target.value)
	}

    return(
		<div className="items">
			<div className="records">
				<span>Show</span>
				<div className="input-field col s12">
					<select style={{display: 'block'}} value={perPage} onChange={handlePerPageChange}>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="100">100</option>
					</select>
				</div>
				<p>Records</p>
			</div>
			<div className="record_search">
				<BasicInput
				fullWidth
				type="text" 
				placeholder="Search lead source" 
				value={search} 
				onChange={handleSearchChange}
				/>
				<div className="search_icon">
					{icons.search}
				</div>							
			</div>
		</div>
    )
}
export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchApp);


const TableApp = props => {
	const {need_to_reload, per_page, search_text, current_page} = props.leadSourceReducer
	const [list, setList] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if(need_to_reload){
			props._handleLeadSourceReload(false)
			if(!loading){
				setLoading(true)
				fetchLeadSourceData()
			}
		}
	}, [need_to_reload]) // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {
		if(!loading){
			 setLoading(true)
			fetchLeadSourceData()
		}
	}, [per_page, search_text]) // eslint-disable-line react-hooks/exhaustive-deps


	const fetchLeadSourceData = () => {
		props.getLeadSourceByPagination({perPage : per_page, query: search_text, currentPage: current_page}, (res) => {
			setList(res.data)
			setLoading(false)
			props._handleLeadSourcePagination({
				totalRecords: res.total,
				totalPages: res.last_page
			})
		})
	}

    return(
		<>
       { loading ? <LeadSourceSkeleton/> :  (
		<table>
			<thead>
				<tr className="header">
					<th>Lead Source</th>
					<th>Created</th>
					<th>Defined By</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody className="tbody_content">

				{(!loading && list.length === 0) &&
				<tr>
					<td colSpan={4} style={{textAlign: 'center'}}>No lead source found</td>
				</tr>
				}

				{!loading ? 
				list.map((eachItem, index) => {
						return(<EachRow eachItem={eachItem} eachIndex={index} key={index}/>)
					}) : <></>
				}
			</tbody>
		</table>
	)}
		</>
    )
}
export const Table = connect(mapStateToProps, mapDispatchToProps)(TableApp);

const EachRowApp = props => {
	const {eachItem} = props

	const handleEditModal = (e) => {
		e.preventDefault()
		props._handleLeadSourceEdit({editFlag : true, editData: eachItem})
		props._handleLeadSourceModal(true)
	}

	const handleDelete = (e) => {
		e.preventDefault()
		props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer:true})

		NewConfirmAlert({
			onSubmit : () => {
				props._handleDeleteLeadSourceModal({deleteFlag : true, deleteData: eachItem, isTransfer:false})
			},
			onCloseClick : () => {
				props._handleDeleteLeadSourceModal({deleteFlag : false, deleteData: null, isTransfer:true})
			},
			title: 'Are you sure?',
			description: 'Do you want to delete this source?',
			cancelText: 'Cancel',
			submitText: 'Delete',
			width: '480px'
		}); 

	}

	return(
		<tr>
			<td>
				<div className="icon_text d-flex">
					<span>
                        {
                        eachItem.source_title === 'Map' ? icons.map : 
						eachItem.source_title === 'Outgoing Call' ? icons.outgoingCall :
						eachItem.source_title === 'Incoming SMS' ? icons.incomingSms :
						eachItem.source_title === 'Api' ? icons.api :
						eachItem.source_title === 'Contact Share' ? icons.contactShare :
						eachItem.source_title === 'Mobile app' ? icons.outgoingCall :
						eachItem.source_title === 'Facebook' ? icons.facebook :
						eachItem.source_title === 'Followupboss' ? icons.followup :
						eachItem.source_title === 'Zapier' ? icons.zapier :
						''
						}
					</span>
					<span>{eachItem.source_title}</span> 
				</div>
			</td>
			<td className="date_time">
				<span className="date">{window.globalTimezoneConversionToDifferentTimezone(eachItem.created_at,'UTC','','MM/DD/Y, h:mm a')}</span>
			</td>
	        
            <td className="system_button system_button__custom__v2"> <button style={eachItem.defined_by == 2 ? { backgroundColor: '#3C7EF3', color: '#FFF' } : {}}>{eachItem.defined_by == 2 ? 'USER' : 'SYSTEM'}</button></td>
			<td className="edit_button__custom">
				<div className='edit_button__custom__row'>
					<a href="!#" className="modal-trigger" onClick={handleEditModal}>
                        {/* <span className="button_icon">{icons.edit}</span> */}
                        <span className="custom_svg_fill_wrpper" arrow title="Edit Lead">
                            <svg width="24px" height="24px" viewBox="0 -32 576 576" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" /></svg>
                        </span>
                        
					</a>
				    
                    {eachItem.defined_by == 2 &&
					<a className='delete_button__custom__svg' href="!#" onClick={handleDelete}>
                            {/* <DeleteIcon color="error"/> */}
                            <span arrow title='Delete'>
                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.125 8.3125L4.57715 22.1069C4.60744 22.3949 4.74329 22.6615 4.95851 22.8553C5.17374 23.0491 5.45308 23.1563 5.74268 23.1562H19.2573C19.5469 23.1563 19.8263 23.0491 20.0415 22.8553C20.2567 22.6615 20.3926 22.3949 20.4229 22.1069L21.875 8.3125H3.125ZM15.2344 18.9307L12.5 16.1963L9.76562 18.9307L8.52246 17.6875L11.2568 14.9531L8.52246 12.2188L9.76562 10.9756L12.5 13.71L15.2344 10.9756L16.4775 12.2188L13.7432 14.9531L16.4775 17.6875L15.2344 18.9307Z" fill="#FF264A"></path>
                                    <path d="M22.8516 2.84375H2.14844C1.82483 2.84375 1.5625 3.10608 1.5625 3.42969V6.16406C1.5625 6.48767 1.82483 6.75 2.14844 6.75H22.8516C23.1752 6.75 23.4375 6.48767 23.4375 6.16406V3.42969C23.4375 3.10608 23.1752 2.84375 22.8516 2.84375Z" fill="#FF264A"></path>
                                </svg>
                            </span>
					</a>
					}
				</div>
			</td>
		</tr>
	)
}
export const EachRow = connect(mapStateToProps, mapDispatchToProps)(EachRowApp);


const paginationApp = props => {
	const { total_records, total_pages, current_page } = props.leadSourceReducer

	const handlePaginationChange = (event, page) => {
		event.preventDefault()
		if(page != null){
			props._handleLeadSourcePagination({currentPage: page})
			props._handleLeadSourceReload(true)
		}
	}

	let previous_page = null, next_page = null; 
	if(current_page > 1){
		previous_page = current_page - 1
	}

	// eslint-disable-next-line eqeqeq
	if(current_page != total_pages){
		next_page = current_page + 1
	}

	// eslint-disable-next-line eqeqeq
	if(total_records == 0){
		return false;
	}

    return(
        <div className="pagination_bar">
			<ul>
				<li>
					<a href="#!" onClick={(event) => handlePaginationChange(event, previous_page)}
						style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
					>
						{icons.previous}
					</a>
				</li>
				<li>
					<a href="#!" onClick={e => e.preventDefault()}>
						<span>{current_page}</span>
					</a>
				</li>
				<li>
					<a href="#!" onClick={e => e.preventDefault()} className="d-flex align-items-center"> 
						<span>
							{icons.backwardSlash}
						</span>	
						{total_pages}
					</a>
				</li>
				<li>
					<a 
						href="#!" onClick={(event) => handlePaginationChange(event, next_page)}
						style={next_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1'} : {}}
					>
						{icons.next}	
					</a>
				</li>
				<li style={{color: '#9daac1', marginLeft: 20}}>Total lead sources : {total_records}</li>
			</ul>
		</div>
    )
}
export const Pagination = connect(mapStateToProps, mapDispatchToProps)(paginationApp);