export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) {
    return "N/A";
  }

  if (phoneNumberString.length < 11) {
    phoneNumberString = "1" + phoneNumberString;
  }
  return phoneNumberString
    .replace(/\D+/g, "")
    .replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4");
}

export const truncate = (clamp = 3, maxWidth) => {
  return {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": clamp,
    "-webkit-box-orient": "vertical",
    maxWidth,
  };
};

export const activeStatusClass = (status) => {
  switch (status) {
    case "WIN":
      return "dealWon";
    case "LOST":
      return "dealLost";
    default:
      return "dealActive";
  }
};

export const activeStatus = (status) => {
  switch (status) {
    case "WIN":
      return "Won";
    case "LOST":
      return "Lost";
    default:
      return "Active";
  }
};