import { TableCell, TableHead, TableRow, withStyles } from "@material-ui/core";
import React from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#d5d6e0",
    color: "black",
    fontSize: "16px",
    borderRight: "2px solid white",
  },
  body: {
    fontSize: 14,
    textAlign: "left",
  },
}))(TableCell);

const AppListTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell style={{ whiteSpace: "nowrap" }}>
          App Name
        </StyledTableCell>
        <StyledTableCell>Client ID</StyledTableCell>
        <StyledTableCell>Client Secret</StyledTableCell>
        <StyledTableCell>Redirect Url</StyledTableCell>
        <StyledTableCell align="center">Action</StyledTableCell>
      </TableRow>
    </TableHead>
  );
};

export default AppListTableHeader;
