import { Button } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Add, Announcement, Save} from "@material-ui/icons";
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { getUserCreationDetails, saveSubUser } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import SwitchC from "../customField/subComponents/Switch";
import Loader from "../globals/Loader";
import GlobalModal from "../globals/Modal/GlobalModal";
import Payment from "./Payment";
import './Popup.css';
import { SnapshotOptions } from "./SnapshotOptions";
import CustomConfirmAlert from "../customField/custom-field-v2/helpers/confirm-alert/CustomConfirmAlert";
import {A2P_REGISTRATION_TYPES} from "../../constants/CoreConstants";
import useQueryParam from "../../hooks/useQueryParam";
import {markStepComplete} from "../../api/onboardApi";

const SNAPSHOT_FOR_SUBUSER = {
    campaign_info : true,
    form_info : true,
    custom_form_info : true,
    tag_info : true,
    trigger_info : true,
    quick_reply_info : true,
    pipeline_info : true,
    calendar_info : true,
    custom_fields_info : true,
    personalized_fields_info : true,
    lead_sources_info : true,
}

const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '35px',
      height: '40px',
      fontSize: '14px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 6px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '40px',
    }),
  };
  

const Popup = (props) => {
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState(null);
    const [needPayment, setNeedPayment] = useState(false);
    const [hasSnapshot, setHasSnapshot] = useState(false);

    const [role, setRole] = useState(null);
    const [roleList, setRoleList] = useState([]);
    const [snapshotList, setSnapshotList] = useState([]);
    const [snapshot, setSnapshot] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [stripeToken, setStripeToken] = useState(null);
    const [conversationAccess, setConversationAccess] = useState(false);
    const [cardOption, setCardOption] = useState('existing');
    const [templateUser, setTeamplateUser] = useState(false);
    const [isEnabledSnapshot, setIsEnabledSnapshot] = useState(false);
    const [isEnabledNoteEdit, setIsEnabledNoteEdit] = useState(false);
    const [selectedSnapshots, setSelectedSnapshots] = useState(SNAPSHOT_FOR_SUBUSER);
    const [snapshortRequiredMessage, setSnapshortRequiredMessage] = useState("");
    const [openTab] = useQueryParam("open-tab");
    const [from] = useQueryParam("from");

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    const [subUserCreateAlert, setSubUserCreateAlert] = useState(false);

    // console.log('************************************************')
    // console.log(props)
    useEffect(() => {
        
        loadData();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        getUserCreationDetails({
            "form_data": {
                user_id: props.data ? props.data.id : null,
                team_id: props.user.team_id
            }
        })
        .then(response => {
            setData(response.data.data);
            
            let roles = [];
            let snapshots = [];
            let object = response.data.data.roles;

            for (const property in object) {
                roles.push({
                    value: property,
                    label: object[property]
                })
            }
            setRoleList(roles);

            if (typeof response.data.data.needPayment !== 'undefined') {
                setNeedPayment(response.data.data.needPayment);
            }

            if (typeof response.data.data.hasSnapshot !== 'undefined') {
                setHasSnapshot(response.data.data.hasSnapshot);
            }

            if (typeof response.data.data.role !== 'undefined') {
                let existingRole = response.data.data.role;
                setRole({
                    label: existingRole.name,
                    value: existingRole.id,
                });
            }

            let object2 = response.data.data.snapshots;
            for (const property in object2) {
             
                snapshots.push({
                    value: object2[property].id,
                    label: object2[property].title
                })
            }
            setSnapshotList(snapshots);

            if (typeof response.data.data.editUser !== 'undefined') {
                let existingUser = response.data.data.editUser;
                if (existingUser) {
                    setName(existingUser.full_name);
                    setEmail(existingUser.email);
                    setPhone(existingUser.phone);
                    setConversationAccess(parseInt(existingUser.can_see_all_conversation));
                    setTeamplateUser(existingUser.is_template_user);
                }
            }

            if (response.data.data.noteEditAllowed){
                setIsEnabledNoteEdit(response.data.data.noteEditAllowed == 1);
            }
            if (response?.data?.data?.a2pInfos && response?.data?.data?.a2pInfos?.registration_type === A2P_REGISTRATION_TYPES.SOLE_PROPRIETOR){
                setSubUserCreateAlert(true);
            }
        })
        .finally(() => {
            setComponentLoading(false)
        })
    }   

    const toggle = (reset = false) => {
        props.hideModal(reset);
    }

    const handleSave = (allowNonUsNumber=0) => {
        if (isEnabledSnapshot && !snapshot){
            setSnapshortRequiredMessage("The snapshot field is required.");
            return;
        }

        if (simpleValidator.current.allValid()) {

            if ((cardOption === 'new')) {
                if (!stripeToken) {
                    Utils.showNotification('Please Enter Correct Card Information', 'error');
                    return false;
                }
            }

            let formData;

            if (needPayment && !templateUser) {
                formData = {
                    user_id: props.data ? props.data.id : null,
                    limit: '-1', //fixed
                    agency_id: props.user.agency_id,
                    role_id: parseInt(role.value),
                    full_name: name,
                    email: email,
                    phone: phone,
                    can_see_all_conversation: conversationAccess ? 1 : 0,
                    customer_id: (typeof data.customer !== 'undefined' && typeof data.customer.customer_id !== 'undefined' && data.customer.customer_id) ? data.customer.customer_id : null,
                    existingCard: (cardOption === 'existing') ? 1 : 0,
                    name: props.user.full_name,
                    stripeToken: stripeToken,
                    allowNonUsNumber: allowNonUsNumber,
                    isEnabledNoteEdit: isEnabledNoteEdit
                }
            } else {
                formData = {
                    user_id: props.data ? props.data.id : null,
                    limit: '-1', //fixed
                    agency_id: props.user.agency_id,
                    role_id: parseInt(role.value),
                    full_name: name,
                    email: email,
                    phone: phone,
                    can_see_all_conversation: conversationAccess ? 1 : 0,
                    templateUser : templateUser,
                    snapshot_id: snapshot ? parseInt(snapshot.value) : null,
                    allowNonUsNumber: allowNonUsNumber,
                    isEnabledNoteEdit: isEnabledNoteEdit
                }
            }

            if(isEnabledSnapshot) {
                formData.selectedSnapshots = selectedSnapshots;
            }

            setLoading(true);

            saveSubUser({
                "form_data": formData
            }).then(async (response) => {
                if (response.data.status === 'success') {
                    toggle(true);
                    if (openTab === "CREATE_NEW_USER" && from === "onboard"){
                        const onboardComplete = await markStepComplete({ key: "ADD_TEAM_MEMBER" });
                        if (onboardComplete?.success) {
                            Utils.removeQueryParam(["from", "open-tab"]);
                        }
                    }
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();

        if(!Utils.validateUsMobileNumber(phone)){
            CustomConfirmAlert({
                onSubmit : () => {
                    handleSave(1);
                },
                title: 'Important Information!',
                description: 'The phone number you have entered is not a US number. If you continue with this number, the user will not receive any kind of SMS notifications.',
                cancelText: 'Cancel',
                submitText: 'Yes, Proceed with this'
            });
            return;
        }

        handleSave();
    }

    return (
        <GlobalModal
            title={props.title}
            onClose={toggle}
            onSubmit={onSubmitHandle}
            open={true}
            buttonText={loading ? 'Saving..' : 'Save'}
            buttonIcon={<Save/>}
            className="pp_sub_user"

        >

        {
            componentLoading ? (
                <div style={{paddingTop: 50}}>
                    <Loader/>
                </div>
            ) : (
                <>
                    {
                        subUserCreateAlert && (
                            <div className="important_alert">
                                <div className="title"><Announcement />Attention</div>
                                <p className="message_line_1">As you are on <b>SOLE PROPRIETORSHIP A2P REGISTRATION</b>. Your team users will face interruption on telephony services.</p>
                                <p className="message_line_2">Please move to <b>A2P STANDARD REGISTRATION / LOW VOLUME REGISTRATION</b> to allow your team users interruption free telephony services.</p>
                            </div>
                        )
                    }

                    {
                        (needPayment && !templateUser && !props.data) && (
                            <div className="charged">
                                <h5>You'll be Charged <span className="light_blue_text">${data.package.sub_user_price} </span>To create a new user.</h5>
                                <p>You have purchased this package <span className="light_blue_text">{moment(data.package.created_at).format('MM/DD/YYYY')}. </span>Remaining <span className="red_text">{moment(data.package.expire_date).fromNow(true)}</span>.
                                    So , to create a user today for this package period you have to pay <span className="light_blue_text">${data.newUserCreationCostToday}.</span>
                                </p>
                                <h6>Next time you have to pay <span className="light_blue_text">${data.package.sub_user_price} </span> for this user.</h6>
                            </div>
                        )
                    }
                    <div className="price_form_content">
                        <div className="price_form create_sub_user">
                            {/*{*/}
                            {/*    !props.data && */}
                            {/*    <div className="conversative d-flex align-items-center">*/}
                            {/*        <div className="title">*/}
                            {/*            <h6>Template user : </h6>*/}
                            {/*        </div>*/}
                            {/*        <div className="switch team_switch">*/}
                            {/*            <SwitchC*/}
                            {/*                status={templateUser}*/}
                            {/*                brandColor={"#f94f72"}*/}
                            {/*                onChange={() => {*/}
                            {/*                        setTeamplateUser(!templateUser);*/}
                            {/*                        if (!templateUser){*/}
                            {/*                            setRole({*/}
                            {/*                                "value": "3",*/}
                            {/*                                "label": "agent"*/}
                            {/*                            });*/}
                            {/*                        }else {*/}
                            {/*                            setRole(null);*/}
                            {/*                        }*/}
                            {/*                    }*/}
                            {/*                }*/}
                            {/*                onText="Yes"*/}
                            {/*                offText="No"*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            <div className="input-field">
                                <h5 className="label_text">Role <span>*</span></h5>
                                <Select
                                    options={roleList}
                                    isDisabled={templateUser}
                                    isClearable={false}
                                    value={role}
                                    styles={customStyles}
                                    onChange={(_selectedOption) => {setRole(_selectedOption)}}
                                />
                                {simpleValidator.current.message('role', role, 'required')}
                            </div>
                            <div className="input-field custom_input">
                                <h5 className="label_text">Full Name<span>*</span></h5>
                                <input placeholder="Enter Full Name" type="text" className="validate" value={name} onChange={e => setName(e.target.value)}/>
                                {simpleValidator.current.message('name', name, 'required')}
                            </div>
                            <div className="input-field custom_input">
                                <h5 className="label_text">Email<span>*</span></h5>
                                <input placeholder="Enter Email" type="text" className="validate" value={email} onChange={e => setEmail(e.target.value)}/>
                                {simpleValidator.current.message('email', email, 'required|email')}
                            </div>

                            <div className="input-field custom_input">
                                <h5 className="label_text">Phone <span>*</span></h5>
                                <input placeholder="Enter Phone" type="text" className="validate" value={phone} onChange={e => setPhone(e.target.value)}/>
                                {simpleValidator.current.message('phone', phone, 'required|phone')}
                            </div>
                            {
                                !templateUser && (
                                    <div className="conversative d-flex align-items-center">
                                        <div className="title">
                                            {/* <h6>Can See Contact's All Conversations?<span>*</span> </h6> */}
                                            <h6>Allow user to see conversations with contacts not assigned to them?</h6>
                                        </div>
                                        <div className="switch team_switch">
                                            <SwitchC
                                                status={conversationAccess}
                                                brandColor={"#f94f72"}
                                                onChange={() => setConversationAccess(!conversationAccess)}
                                                onText="Yes"
                                                offText="No"
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            <div className="conversative d-flex align-items-center mt-2">
                                <div className="title">
                                    <h6>Allow user to edit notes?</h6>
                                </div>


                                <div className="switch team_switch">
                                    <SwitchC
                                        status={isEnabledNoteEdit}
                                        brandColor={"#f94f72"}
                                        onChange={() => {
                                            setIsEnabledNoteEdit(!isEnabledNoteEdit);
                                        }}
                                        onText="Yes"
                                        offText="No"
                                    />
                                </div>
                            </div>

                            {
                                !props.data && (
                                    <div className="conversative d-flex align-items-center mt-2">
                                        <div className="title">
                                            <h6>Would you like to use our “Quick Clone” feature to set up the new account? </h6>
                                        </div>


                                        <div className="switch team_switch">
                                            <SwitchC
                                                status={isEnabledSnapshot}
                                                brandColor={"#f94f72"}
                                                onChange={() => {
                                                    setIsEnabledSnapshot(!isEnabledSnapshot);
                                                    setSnapshot(null);
                                                    setSnapshortRequiredMessage("");
                                                }}
                                                onText="Yes"
                                                offText="No"
                                            />
                                        </div>
                                    </div>
                                )
                            }

                            {
                                isEnabledSnapshot &&
                                <>
                                    <p><u>SELECT AN EXISTING "QUICK CLONE" OR CREATE A NEW ONE BELOW:</u></p>
                                    <div className="title mt-2 create-new-quick-clone">
                                        <Link to="/user/profile/snapshot" className="modal-trigger accent--bg--text--color"
                                           // onClick={(e) => {
                                           //     // e.preventDefault();
                                           // }}
                                        >
                                            <span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="10" cy="10" r="10"></circle>
                                                    <path
                                                        d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
                                                        className="accent--fill--color"></path>
                                                </svg>
                                            </span>New Quick Clone
                                        </Link>
                                        {/*<Link  to="/user/profile/snapshot"> <Button className="margin-left-zero-mui-btn-label" variant="outlined" size="small">click here </Button></Link>*/}
                                    </div>
                                    <div className="input-field mb-3">
                                <h5 className="label_text">Select Quick Clone<span>*</span></h5>
                                <Select
                                    options={snapshotList}
                                    isClearable={false}
                                    value={snapshot}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999999 }),
                                        valueContainer: provided => ({
                                            ...provided,
                                            width: '50px',
                                        })
                                    }}
                                    onChange={(_selectedOption) => {
                                        setSnapshot(_selectedOption);
                                        setSnapshortRequiredMessage("");
                                    }}
                                />
                                {
                                    snapshortRequiredMessage ?
                                        <div className="small text-danger mdi mdi-alert pt-1 pl-1">{ snapshortRequiredMessage }</div> : <div/>
                                }
                            </div>
                                </>
                            }



                            {
                                (isEnabledSnapshot && snapshot!=null) &&
                                <SnapshotOptions
                                    selectedSnapshots={selectedSnapshots}
                                    onChange={snapshots => setSelectedSnapshots(snapshots)}/>

                            }
                        </div>

                        {
                            (needPayment && !templateUser && !props.data) && (
                                <div className="payment_details mt-5">
                                    <h1>Payment details</h1>
                                    {
                                        (typeof data.customer !== 'undefined' && typeof data.customer.customer_id !== 'undefined' && data.customer.customer_id) && (
                                            <div className="mb-3">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="gender" name="gender1" value={cardOption} onChange={e => setCardOption(e.target.value)} row>
                                                        <FormControlLabel value="existing" control={<Radio />} label="Existing" />
                                                        <FormControlLabel value="new" control={<Radio />} label="New" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        )
                                    }
                                    {
                                        (cardOption === 'existing' && typeof data.cardInfo !== 'undefined' && typeof data.cardInfo.customer_id !== 'undefined' && data.cardInfo.customer_id) && (
                                            <Fragment>
                                            <div className="defult_card d-flex align-items-center">
                                                    <a href="#!" className="defult_button" onClick={e => e.preventDefault()}>Default</a>
                                                    <span className="card_no">**** **** **** {data.cardInfo.card_number}</span>
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                    {
                                        (cardOption === 'new') && (
                                            <Fragment>
                                                {
                                                    data && (
                                                        <Payment
                                                            stripePublicKey={data.stripePublicKey}
                                                            callback={setStripeToken}
                                                        />
                                                    )
                                                }
                                            </Fragment>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </>
            )
        }

        </GlobalModal>
    );
}

export default Popup;