import { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress,
    Checkbox
} from "@material-ui/core";
import {
    Lock as LockIcon
} from "@material-ui/icons";
import Utils from "../../helpers/Utils";
import {
    getCompanyLogoApi,
    mysBuyNewSubscription, mysDowngradeSubscription, mysUpgradeSubscription,
} from "../../api/profileApi";
import "./CardPayment.css";
import CardForm from "./stripeCard/CardForm";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import moment from "moment/moment";
import NewConfirmAlert from "../activity/new-alert/NewConfirmAlert";


const CardPaymentV2 = (props) => {
    const [radioValue, setRadioValue] = useState("exists");
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!props.packageInformation.companyLogo) {
            getCompanyLogoApi().then((res) => {
                let response = res.data;
                if (response.status === "success") {
                    props.updateReducerInformations({ "companyLogo": response.companyLogo });
                }
            });
        }

        if (props.packageInformation){
            if (props.packageInformation.useExisitngCard){
                setRadioValue("exists");
            }else {
                setRadioValue("new");
            }
        }
    }, [props.packageInformation]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateCardInfo = (value, key) => {
        if (key === "expiry") {
            expiryCheck(value, key);
        } else {
            props.updateReducerInformations({ [key]: value });
        }
    };

    const expiryCheck = (value, key) => {
        value = Utils.returnFilteredText(value, "number");
        if (value.length <= 2) {
            if (value > 12) {
                props.updateReducerInformations({ [key]: 12 + "/" });
            } else {
                if (value.length === 1) {
                    props.updateReducerInformations({ [key]: value });
                } else {
                    props.updateReducerInformations({ [key]: value + "/" });
                }
            }
        } else if (value.length === 3) {
            if (value[2] > 1)
                props.updateReducerInformations({ [key]: value[0] + value[1] + "/" + value[2] });
        } else if (value.length === 4) {
            if ((value[2] > 1 && value[2] < 3 && value[3] > 1) || value[2] > 3) {
                props.updateReducerInformations({ [key]: value[0] + value[1] + "/" + value[2] + value[3] });
            }
        }
    };

    let totalPrice = `${(parseFloat(props.packageInformation.selectedPackage?.subscription_fee) + parseFloat(props.packageInformation.selectedPackage?.additional_charge)).toFixed(2)}`;

    const buyNewSubscription = (payload) => {
        mysBuyNewSubscription(payload)
            .then((res)=>{
                if (res && res.success){
                    let packageInfo = res.data;

                    let successMessage = <span>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.</span>;
                    if (packageInfo.renew_date && packageInfo.renew_date.date) {
                        packageInfo.renew_date = moment(packageInfo.renew_date.date).format("MM-DD-YYYY");
                    }
                    packageInfo["subscription_fee"] = props.packageInformation.selectedPackage.subscription_fee;
                    props.updateReducerInformations({
                        submitModal: "success",
                        hasPackage: true,
                        packageInfo: packageInfo,
                        message: successMessage,
                        submitting: false,
                        paymentPage: false
                    });

                    sessionStorage.setItem("mapPackage" + Utils.getAccountData("userId"), "true");
                    props.getTeamList();
                }else {
                    window.showNotification("error", res.message);
                }
            }).finally(()=>{
                props.updateReducerInformations({
                    submitting: false
                });
            });
    }
    const upgradeSubscription = (payload) => {
        mysUpgradeSubscription(payload)
            .then((res)=>{
                if (res && res.success){
                    let packageInfo = res.data;

                    let successMessage = <span>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.</span>;
                    if (packageInfo.renew_date && packageInfo.renew_date.date) {
                        packageInfo.renew_date = moment(packageInfo.renew_date.date).format("MM-DD-YYYY");
                    }
                    packageInfo["subscription_fee"] = props.packageInformation.selectedPackage.subscription_fee;
                    props.updateReducerInformations({
                        submitModal: "success",
                        hasPackage: true,
                        packageInfo: packageInfo,
                        message: successMessage,
                        submitting: false,
                        paymentPage: false
                    });

                    sessionStorage.setItem("mapPackage" + Utils.getAccountData("userId"), "true");
                    props.getTeamList();
                }else {
                    window.showNotification("error", res.message);
                }
            }).finally(()=>{
                props.updateReducerInformations({
                    submitting: false
                });
        });
    }
    const downgradeSubscription = (payload) => {
        mysDowngradeSubscription(payload)
            .then((res)=>{
                if (res && res.success){
                    let info = props.packageInformation.packageInfo;
                    info["downgrade_package_id"] = props.packageInformation.selectedPackage.package_id;
                    info["is_downgrade"] = 1;
                    props.updateReducerInformations({
                        submitModal: "success-downgrade",
                        hasPackage: true,
                        packageInfo: info,
                        message: res.message,
                        submitting: false,
                        paymentPage: false,
                        changePackage: false
                    });
                }else {
                    window.showNotification("error", res.message);
                }
            }).finally(()=>{
                props.updateReducerInformations({
                    submitting: false
                });
        });
    }

    const handleSubmit = async () => {
        let payload = {
            packageId: props.packageInformation.selectedPackage.package_id,
            stripeTokenInfo: null,
            makeDefaultCard: false
        };

        if (!props.packageInformation.useExisitngCard){
            if (!stripe || !elements) {
                return;
            }
            const token = await stripe.createToken(elements.getElement(CardElement));

            if (typeof token.error && !token.token) {
                return;
            }

            payload.stripeTokenInfo = token.token;
            payload.makeDefaultCard = props.packageInformation.makeDefaultCard;
        }

        if (props.packageInformation.submitting) {
            return;
        }

        if (props.packageInformation.packageLoading) {
            window.showNotification("Geofarming package updating...")
        }

        let type = "buy";
        let buttonText = "Buy Now";

        if (props.packageInformation.hasPackage && props.packageInformation.packageInfo) {
            if (props.packageInformation.packageInfo.subscription_fee > props.packageInformation.selectedPackage.subscription_fee) {
                type = "downgrade";
                buttonText = "Downgrade";
            } else {
                type = "upgrade";
                buttonText = "Upgrade";
            }
        }

        NewConfirmAlert({
            onSubmit : () => {
                props.updateReducerInformations({ "submitting": true });
                if (type === "downgrade") {
                    downgradeSubscription(payload);
                }else if (type === "upgrade") {
                    upgradeSubscription(payload);
                }else {
                    buyNewSubscription(payload);
                }
            },
            onCloseClick : () => {

            },
            title: `Are you sure to ${type} package?`,
            description: "Please confirm your order below",
            cancelText: "No",
            submitText: buttonText,
            width: "480px"
        });
    };

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
        if (e.target.value === "exists") {
            props.updateReducerInformations({ "useExisitngCard": true });
        } else {
            props.updateReducerInformations({ "useExisitngCard": false });
        }
    };

    const packageBuyType = () => {
        if (props.packageInformation.hasPackage && props.packageInformation.packageInfo) {
            if (props.packageInformation.packageInfo.subscription_fee > props.packageInformation.selectedPackage.subscription_fee) {
                return "Downgrade package";
            } else if (props.packageInformation.packageInfo.subscription_fee < props.packageInformation.selectedPackage?.subscription_fee) {
                return "Upgrade package";
            } else {
                return "Buy Package now!";
            }
        } else {
            return "Buy Package now!";
        }
    };

    const paymentMessage = () => {
        if (props.packageInformation.selectedPackage && props.packageInformation.selectedPackage.life_line) {
            if (props.packageInformation.selectedPackage.life_line <= 31) {
                return "Monthly Payment";
            } else if (props.packageInformation.selectedPackage.life_line === 365) {
                return "Annual Payment";
            } else {
                return `Payment ${props.packageInformation.selectedPackage.life_line} days`;
            }
        } else {
            return "Payment";
        }
    };

    return (
        <Box className="cardmainPage__wrapper mip_cardPayment_page">
            <div className="cardmainPage__wrapper__toper">
                <h1 className="cardHeadingTitle__title">Activate your subscription</h1>
                <button onClick={() => updateCardInfo(false, "paymentPage")}>Back to packages</button>
            </div>
            <Grid container className="mx-20 mip_two_columns" spacing={3}>
                <Grid item xs={7}>
                    <div className="mip_customPaper pip_billing_cycle">
                        <h1 className="cardHeadingTitle"><img src="https://i.ibb.co/6gnrXnQ/Frame.png"  alt="Frame"/>{props.packageInformation.selectedPackage.name}</h1>
                        <h4>Billing Cycle</h4>
                        <div className="mip_payment_type">
                            <label><input type="radio" checked={true} />{paymentMessage()}</label>
                            <span>${totalPrice}</span>
                        </div>
                    </div>

                    <div className="mip_customPaper mip_form mt-20">
                        <h1 className="cardHeadingTitle">
                            <img src="https://i.ibb.co/wWCkhrH/Group-10034-1.png"  alt="Group"/></h1>
                        <h4 className="mtl">Credit Card Information</h4>
                        {(!props.searchingMapUser && props.packageInformation.mapUserCardInfo) &&
                            <RadioGroup aria-label="gender" name="gender1" value={radioValue} onChange={handleRadioChange} className="cardmainPage__radio__wrapper">
                                <FormControlLabel size="small" value="exists" control={<Radio />} label="Use existing Card" />
                                <FormControlLabel size="small" value="new" control={<Radio />} label="New Card" />
                            </RadioGroup>
                        }
                        {(!props.searchingMapUser && (props.packageInformation.useExisitngCard && props.packageInformation.mapUserCardInfo)) &&
                            <Grid className="mip_form_wrapper alt2" container spacing={2}>
                                <Grid className="formItem" item xs={4}>
                                    <label>Existing Card</label>
                                    <input
                                        type="text"
                                        name="number"
                                        placeholder="Enter Card Number"
                                        value={`**** **** **** ${props.packageInformation.mapUserCardInfo.card_number}`}
                                        readOnly
                                    />
                                    <img src="https://i.ibb.co/Twd3Crg/Vector-2.png" className="inputIcon" alt="Vector"/>
                                </Grid>
                            </Grid>
                        }
                        {(!props.searchingMapUser && !props.packageInformation.useExisitngCard) &&
                            <div className="mip_form_wrapper alt2">
                                <CardForm/>
                                <div className="mip_form_checkbox_default">
                                    <FormControlLabel checked={props.packageInformation.makeDefaultCard} onChange={(event, checked)=>{ props.updateReducerInformations({ makeDefaultCard: checked }) }} control={<Checkbox/>} label="Save this card as default card"/>
                                </div>
                            </div>
                        }

                        <hr />
                        <h4 className="mtl">Billing Information</h4>
                        <Grid className="mip_form_wrapper alt" container spacing={2}>
                            <Grid className="formItem" item xs={6}>
                                <label>Name</label>
                                <input type="text" value={Utils.getAccountData("fullName")} readOnly/>
                            </Grid>
                            <Grid className="formItem" item xs={6}>
                                <label>Email</label>
                                <input type="text" placeholder="Email" value={Utils.getAccountData("email")} readOnly/>
                            </Grid>
                            <Grid className="formItem" item xs={6}>
                                <label>Phone</label>
                                <input type="text" placeholder="Email" value={props.packageInformation.userNumber} readOnly/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={5}>
                    {props.packageInformation.companyLogo &&
                        <Box className="mip_company_info">
                            <div className="mip_company_logo">
                                <img src={`${props.packageInformation.companyLogo}?compress=1&resize=400x300`} alt="Logo" />
                            </div>
                        </Box>
                    }

                    <div className="mip_customPaper">
                        <h1 className="cardHeadingTitle">Order Summary</h1>
                        <div className="mip_cardHeaderBox">
                            <h4>Today’s Payment</h4>
                            <p>Geofarming Tool	<span>  ${totalPrice}</span></p>
                        </div>
                        <div className="mip_cardHeaderBox alt">
                            <h4>Future Payment</h4>
                            <p>{paymentMessage()}	<span>  ${totalPrice}<span>All Prices in USD</span></span></p>
                        </div>
                        {!props.searchingMapUser &&
                            <div className="mip_payment_order_summery">
                                <Button onClick={() => handleSubmit()} variant="contained" className="mip_cardPayment_btn mip_cardPayment_btn_buy" startIcon={<LockIcon />}>
                                    {packageBuyType()} {props.packageInformation.submitting ? <CircularProgress /> : ""}
                                </Button>
                                <p><i>Charges will appear on your statement from: CRM - Geofarming Tool</i></p>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CardPaymentV2;