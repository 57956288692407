import React from "react";
import { Box, Table, TableContainer } from "@material-ui/core";
import AppListTableBody from "./AppListTableBody";
import AppListTableHeader from "./AppListTableHeader";

const AppListTable = ({appList,removeId}) => {
  return (
    <Box p={2}>
      <TableContainer>
        <Table>
          <AppListTableHeader />
          <AppListTableBody appList={appList} removeId={removeId}  />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AppListTable;
