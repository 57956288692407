import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { WarningIcons, CloseIcon } from "./IconList";
import Styles from "./style.module.css";

const CustomConfirmAlert = (props) => {
  let default_params = {
    type: "warning",
    title: "",
    description: "",
    highlightText: "",
    cancelText: "No, Keep it",
    submitText: "Yes, Delete it",
    onCloseClick: () => {},
    onSubmit: () => {},
    closeOnClickOutside: true,
  };
  let data_params = { ...default_params, ...props };
  let {
    title,
    description,
    closeOnClickOutside,
    highlightText,
    cancelText,
    submitText,
    onCloseClick,
    onSubmit,
  } = data_params;
  const handleSubmit = (onClose) => {
    onSubmit();
    onClose();
  };
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={Styles.mainWrp}>
          <div className={Styles.header}>
            <span
              className={Styles.close}
              onClick={() => {
                onCloseClick();
                onClose();
              }}
            >
              <CloseIcon height="18px" width="18px" color="#ec131f" />
            </span>
          </div>
          <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
            <span>
              <WarningIcons height="48px" width="48px" color="#f79009" />
            </span>
            <span className={Styles.bodyTitle}>{title}</span>
            <span className={Styles.descriptionText}>{description}</span>

            <div
              className={`${Styles.highlightWrp} ${Styles.highlightWrpWarning}`}
            >
              <span>
                <WarningIcons height="24px" width="24px" color="#f79009" />
              </span>
              <div className={Styles.highlightBody}>
                <span className={`${Styles.bodyTitle} ${Styles.titleWarning}`}>
                  Warning !
                </span>
                <span
                  className={`${Styles.descriptionText} ${Styles.titleWarning}`}
                >
                  {highlightText}
                </span>
              </div>
            </div>
          </div>
          <div className={Styles.footerWrp}>
            <span
              onClick={() => {
                onCloseClick();
                onClose();
              }}
              className={`${Styles.button} ${Styles.cancelButton}`}
            >
              {cancelText}
            </span>
            <span
              onClick={() => {
                handleSubmit(onClose);
              }}
              className={`${Styles.button} ${Styles.submitButtonWarning}`}
            >
              {submitText}
            </span>
          </div>
        </div>
      );
    },
    closeOnClickOutside: closeOnClickOutside,
    overlayClassName: Styles.overlay,
  });
};
export default CustomConfirmAlert;
