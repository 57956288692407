import {
    TableRow,
    styled,
  } from "@material-ui/core";
export const LostReasonStyle = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
  },
    color: theme.palette.text.primary,
  
    "& .titleWrap": {
      color:'var( --dark_blue)',
      fontSize:'15px',
      padding:"10px",
    },
    "& .buttonWrap": {
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      gap:10
    },
  
    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
      padding:'10px'
    },
    "& .actionContainer":{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 3,
    },
    "& .editButton": {
      cursor: "pointer",
      border: "none",
      background: "transparent",
      borderRadius: "50px",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#E5E8EF",
        color: "#466fef",
      },
      "&:focus": {
        backgroundColor: "none",
      },
    },
    "& .deleteButton": {
      cursor: "pointer",
      border: "none",
      background: "transparent",
      borderRadius: "50px",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#E5E8EF",
        color: "#466fef",
      },
      "&:focus": {
        backgroundColor: "none",
      },
    },
  }));