import React, {useContext, useRef, useState} from "react";
import {Button, Dialog, styled, Typography} from "@material-ui/core";
import BasicInput from "../custom/BasicInput";
import SimpleReactValidator from "simple-react-validator";
import {cancelUserAccount} from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import {deleteCookie} from "../../helpers/Cookie";
import {HistoryProvider} from "../../App";

const ModalWrapper = styled(Dialog)(({theme}) => ({
    "& .MuiDialog-paperWidthSm": {
        maxWidth: 900,
    },
    minWidth: 900,
    minHeight: 650,

    "& .modalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        borderBottom: "1px solid #d3d3d3",

        "& .titleWrapper": {
            display: "flex",
            alignItems: "center",
            gap: 8,
        },

        "& .titleHeader": {
            fontSize: "20px",
        },
    },

    "& .modalBody": {
        padding: 16,

        "& .formLabel": {
            fontWeight: "600 !important",
            color: "#181f48",
            marginBottom: "0px !important",
        },
    },

    "& .actionButton": {
        backgroundColor: "#3E50F7",
        "&.MuiButton-contained.Mui-disabled": {
            color: "#fff",
            backgroundColor: "rgb(62 80 247 / 39%)",
        },
        "&:hover, &:focus": {
            backgroundColor: "#3E50F7",
        },
    },

    "& .cancelButton": {
        backgroundColor: "#d72121",
        "&:hover, &:focus": {
            backgroundColor: "#d72121",
            color: "#fff",
        },
    },

    "& .modalFooter": {
        padding: "10px",
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px"
    },

    "& .errorMessage": {
        color: "#dc3545",
        fontSize: "12px",
        paddingTop: "0.25rem",
        paddingLeft: "0.25.rem"
    }
}));

const CancelAccount = ({ open, onClose }) => {
    const history = useContext(HistoryProvider);
    const [submitting, setSubmitting] = useState(false);
    const [password, setPassword] = useState("");
    const [, forceUpdate] = useState();
    const simpleValidator = useRef(new SimpleReactValidator({
        autoForceUpdate: { forceUpdate: forceUpdate },
        className: "errorMessage"
    }));

    const handleSubmit = () =>{
        if (simpleValidator.current.allValid()) {
            let formData;

            formData = {
                password: password
            };

            setSubmitting(true);

            cancelUserAccount({
                "form_data": formData
            }).then((response) => {

                if (response.data.data.status === "success") {
                    Utils.showNotification("Account is canceled!", "SUCCESS");
                    setTimeout(function(){
                        deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
                        history.push("/user/login");
                        window.location.reload();
                    }, 3000);
                } else {
                    Utils.showNotification(response.data.data.html, "ERROR");

                }
            }).finally(() => {
                setSubmitting(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    };

    return(
        <ModalWrapper open={open} onClose={onClose}>
            <div className="modalHeader">
                <div className="titleWrapper">
                    <div>
                        <Typography className={"titleHeader"}>Cancel Account</Typography>
                        <Typography variant="subtitle1" className={"subHeader"}>
                            If you cancel your account all of your data will be permanently deleted.
                        </Typography>
                    </div>
                </div>
            </div>

            <div className="modalBody">
                <Typography className="formLabel">Confirm password for account cancellation</Typography>
                <BasicInput value={password} onChange={(e) => {
                    setPassword(e.target.value);
                }} fullWidth/>
                { simpleValidator.current.message("password", password, "required") }
            </div>
            <div className="modalFooter">
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="cancelButton"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="actionButton"
                    onClick={handleSubmit}
                    disabled={submitting}
                >
                    { submitting ? "Submitting..." : "Confirm" }
                </Button>
            </div>
        </ModalWrapper>
    );
};

export default CancelAccount;