import React, { useState } from "react";
import {
  Button,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import BootstrapTooltip from "../../common/BootstrapTooltip";
import CustomDrawerPype from "../../customField/custom-field-v2/helpers/drawer/CustomDrawerPype";
import { MoveIcons } from "../../customField/custom-field-v2/helpers/IconList";
import SwitchC from "../../customField/subComponents/Switch";
import { CustomScrollbar } from "../../products/productsStyle";
import AddCustomField from "./modal/add-field/AddCustomField";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    padding: "16px",
    flex: 1,

    "& .headWrapper": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: 16,
      marginBottom: 16,
      borderBottom: "1px solid #cdcdcd",
    },

    "& .newFieldButton": {
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      "& .search": {
        border: "1px solid rgb(167 167 167 / 65%) !important",
        margin: 0,
        "&:fucus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
      },
    },

    "& .customFieldList": {
      "& .listItem": {
        padding: 8,
        border: "1px solid #e1e1e1",
        borderRadius: 4,

        "&:not(:last-child)": {
          marginBottom: 8,
        },

        "& .itemHeader": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 8,
          marginBottom: 8,
          borderBottom: "1px solid #e1e1e1",

          "& .title": {
            fontSize: 18,
            fontWeight: "bold",
          },

          "& .actions": {
            display: "flex",
            alignItems: "center",
            gap: 16,

            "& .chip": {
              marginRight: 40,
            },
          },
        },

        "& .itemDetails": {
          paddingInline: 16,
          "& .title": {
            fontWeight: 600,
          },
          "& .sub": {
            fontSize: 14,
            color: "#546376",
          },
        },
      },
    },
  },
}));

const Content = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="headWrapper">
        <div class="record_search">
          <input
            type="text"
            placeholder="Search By Field Name"
            class="search"
            // onChange={(e) => setQuery(e.target.value)}
          />
          <div class="search_icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#133159"
                fill-opacity="0.65"
              ></path>
            </svg>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="newFieldButton"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() => setIsDrawerOpen(true)}
        >
          New Custom Field
        </Button>
      </div>

      <CustomScrollbar style={{ maxHeight: "calc(100vh - 410px)" }}>
        <div className="customFieldList">
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="listItem">
            <div className="itemHeader">
              <div className="title">Policy Type</div>
              <div className="actions">
                <Chip
                  size="small"
                  label="Global Field"
                  clickable
                  color="primary"
                  className="chip"
                />

                <BootstrapTooltip title={"Edit"} placement={"top"} arrow>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Delete"} placement={"top"} arrow>
                  <IconButton size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title={"Move"} placement={"top"} arrow>
                  <IconButton size="small">
                    <MoveIcons />
                  </IconButton>
                </BootstrapTooltip>

                <SwitchC
                  status={true}
                  brandColor={"#3f51b5"}
                  onChange={() => {}}
                  onText="On"
                  offText="Off"
                />
              </div>
            </div>

            <div className="itemDetails">
              <Grid container>
                <Grid item xs={6}>
                  <span className="title">Personal Tag: [[policy_type]]</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Type: Dropdown</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Maximum Character Allowed: 255</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Format: Select</span>
                </Grid>
                <Grid item xs={6}>
                  <span className="sub">Value: Auto, Home, Life, Umbrella</span>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </CustomScrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        // count={total}
        // rowsPerPage={rowsPerPage}
        // page={page}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <CustomDrawerPype
        isShow={isDrawerOpen}
        hanldeDrawer={(status) => {}}
        renderChild={() => {
          return <AddCustomField onClose={() => setIsDrawerOpen(false)} />;
        }}
      />
    </div>
  );
};

export default Content;
