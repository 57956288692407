import { makeStyles } from "@material-ui/core";

const LeadSourceStyle = makeStyles({
    headerContainer: {
      display: "flex",
      gap: "10px",
      marginLeft: "16px",
      alignItems: "center",
    },
    headerText: {
      height: "unset !important",
      fontSize: "20px",
      fontWeight: 600,
      color: "#133159",
    },
    headerDescription: {
      height: "unset !important",
      fontSize: "14px",
      fontWeight: 400,
      color: "#133159",
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    button: {
      padding: "8px 16px",
      border: "none",
      borderRadius: "20px",
      cursor: "default",
      "&:focus, &:hover": {
        backgroundColor: "#efefef",
      },
    },
    actionButton: {
      border: "none",
      cursor: "pointer",
      background: "transparent",
      "&:focus, &:hover": {
        backgroundColor: "transparent",
      },
    },
    text: {
      fontSize: "16px !important",
    },
    addLeadSourceButton: {
      padding: "12px 16px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      background: "#466fef",
      "&:focus, &:hover": {
        backgroundColor: "#466fef",
      },
    },
    buttonContainer: {
      display: "flex",
      gap: "5px",
      justifyContent: "center",
      alignItems: "center",
      color:"white",
      textTransform: "uppercase",
      fontWeight:600
    }
  });
export default LeadSourceStyle;