import {useEffect, useState} from "react"
import {processSnapShotItems} from "../../api/profileApi";

const Step4 = ({globalAssetSelector, globalTemplatesObjAfterConflict, activeSnapShot, subUser}) => {

    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(globalAssetSelector.campaign_info !== undefined &&
            globalTemplatesObjAfterConflict.campaignTempOptionValue !== undefined &&
            activeSnapShot.value !== undefined
        ){
            processSubUserSnapShot();
        }
    }, [globalAssetSelector, globalTemplatesObjAfterConflict]) // eslint-disable-line react-hooks/exhaustive-deps

    const convertTempOptionValue = (mainObj, optionValue, replaceString) => {
        Object.entries(globalTemplatesObjAfterConflict[optionValue]).forEach(([key, value]) => {
            let newKey = key.replace(replaceString, '');
            if(value === 1 || value === '1'){
                let obj = {
                    [newKey] : value
                };
                mainObj = { ...mainObj, ...obj };
            }
        });

        return mainObj;
    }

    const processSubUserSnapShot = () => {
        setLoading(true);
        let campaign_info = {};
        let form_info = {};
        let custom_field_info = {};
        let leadflow_info = {};
        let tag_info = {};
        let trigger_info = {};
        let custom_form_info = {};
        let quick_reply_info = {};
        let pipeline_info = {};
        let calendar_info = {};
        let personalized_field_info = {};

        campaign_info = convertTempOptionValue(campaign_info, 'campaignTempOptionValue', 'camp-');

        custom_field_info = convertTempOptionValue(custom_field_info, 'customFieldTempOptionValue', 'custom-field-');

        form_info = convertTempOptionValue(form_info, 'formTempOptionValue', 'form-');

        leadflow_info = convertTempOptionValue(leadflow_info, 'leadFlowTempOptionValue', 'lead-flow-');

        tag_info = convertTempOptionValue(tag_info, 'tagFieldTempOptionValue', 'tag-');

        trigger_info = convertTempOptionValue(trigger_info, 'triggerTempOptionValue', 'trigger-');

        custom_form_info = convertTempOptionValue(custom_form_info, 'customFormTempOptionValue', 'custom-form-');

        quick_reply_info = convertTempOptionValue(quick_reply_info, 'quickReplyTempOptionValue', 'quick-reply-');

        pipeline_info = convertTempOptionValue(pipeline_info, 'pipelineTempOptionValue', 'pipeline-');

        calendar_info = convertTempOptionValue(calendar_info, 'calendarTempOptionValue', 'calendar-');

        personalized_field_info = convertTempOptionValue(personalized_field_info, 'personalizedFieldTempOptionValue', 'personalized-field-');


        let conflict_asset_selector = {campaign_info, form_info, custom_field_info, leadflow_info, tag_info, trigger_info, custom_form_info, quick_reply_info, pipeline_info, calendar_info, personalized_field_info};

        let userId = subUser.id;

        processSnapShotItems({
            "form_data": {
                conflict_asset_selector : {
                    [userId] : conflict_asset_selector
                },
                asset_selector : globalAssetSelector,
                snapshotId : activeSnapShot.value,
                subUserId : subUser.id
            }
        })
            .then(response => {
                let apiResponse = response.data;

                if(apiResponse.status === 'success'){
                    if(apiResponse.data !== undefined &&  apiResponse.data.isConflict !== undefined ){

                    }
                }else{
                    console.log('ERROR');
                }
            }).finally(() => {
            setLoading(false);
        });
    }

    return(
        <div className="twilio___migration__gerenal_step twili__migration___container">
            {
                loading ?
                    <>
                        <div className="row processing_wrapper_v2">
                            Processing . . .
                        </div>
                    </>
                    :
                    <>
                        <div className="row processing_wrapper_v2">
                            Quick clone copy is in process
                        </div>
                    </>
            }

        </div>
    )
}
export default Step4;