import {
  TablePagination,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { productFolderState, productState } from "../../../common/productSelector";
import { productStyle } from "../ProductStyle";
import ProductTable from "./ProductTable";
import { getProducts } from "../../../../../actions/ProductAction";

const useStyles = makeStyles({
  bodyWrapper: {padding: '16px'},
  paginationWrapper: {
    display: 'flex',
    justifyContent:  'space-between',
    marginTop: '16px'
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton:{
    "& .MuiIconButton-root":{
      "&:focus":{
        background:'white'
      },
      "&:hover":{
        background:'rgba(0, 0, 0, 0.04)'
      }
    }
  }

});

const ProductBody = () => {
  const dispatch = useDispatch();

  const { pagination, totalCount} = useSelector(productState);

  const { selectedFolder } = useSelector(productFolderState);
  const { productBodyTitle, grayColor } = productStyle();

  let campaignStatus = null;
  switch (selectedFolder) {
      case 'all':
          campaignStatus = null;
          break;
      case 'favorite':
          campaignStatus = { isFavorite: 'YES' };
          break;
      case 'archive':
          campaignStatus = { status: 'ARCHIVE' };
          break;
      case 'trash':
          campaignStatus = { status: 'TRASH' };
          break;
      default:
          campaignStatus = { userProductFolderId: selectedFolder };
  }

  const handleChangePage = (event, newPage) => {
    // setPage(newPage);
    dispatch(getProducts({ ...pagination, page: newPage + 1, ...campaignStatus }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(getProducts({ ...pagination, perPage: event.target.value, ...campaignStatus }));
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };


  const classes = useStyles();
  return (
    <div className={classes.bodyWrapper}>
      <Typography className={productBodyTitle} variant="h6">
        All Products & Services
      </Typography>
      <Typography variant="body2" className={grayColor}>
      In this section you’ll list all the different types of products and services that your business offers.
      </Typography>

      <ProductTable />

      <div className={classes.paginationWrapper} >
        <div style={{ fontStyle: "italic", color: "#2D6CFF" }}>
          <span className={classes.textFontWeight}>
            **Be sure to choose your products and services carefully.{" "}
          </span>
          <span className={classes.textFontWeight}>
            {" "}
            This data will be used to compile reports that outline the types of
            products and services your business is selling.
          </span>
        </div>
        

      <div style={{minWidth:'410px'}}>
          <TablePagination
            component="div"
            count={totalCount}
            page={pagination?.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={pagination?.perPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductBody;
