import React, {useEffect, useRef, useState} from "react";
import SimpleReactValidator from "simple-react-validator";
import {Box, Button} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import CoreTextField from "../common/TextField/CoreTextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";
import PersonalizedTag from "../common/Personalized/PersonalizedTag";
import {insertPersonalizedTag} from "../common/utils/insertPersonalizedTag";
import {addSmsTemplate, getSmsTemplate} from "../../../api/InvoiceApi";
import Styles from "./SendStyles.module.css";

const SendWithText = () => {
  const [, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState("");

  const bodyRef = useRef(null);

  const handleBody = (value) => {
    setBody(value);
  };

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
    })
  );

  useEffect(() => {
    getSmsTemplate().then((res) => {
      res = res.data;
      if (res.success) {
        const { body } = res.data || {};
        handleBody(body);
      } else {
        window.showNotification("ERROR", res.message);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (simpleValidator.current.allValid()) {
      try {
        const requestBody = {
          body,
        };
        console.log(requestBody);

        if (requestBody) {
          addSmsTemplate(requestBody)
            .then((res) => {
              res = res.data;
              if (res.success) {
                window.showNotification("SUCCESS", res.message);
                // onRenderCallback();
              } else {
                window.showNotification("ERROR", res.message);
              }
            })
            .catch((error) => {
              window.showNotification("ERROR", "Error occurred");
            })
            .finally(() => setLoading(false));
          window.showNotification("SUCCESS", "SMS template saved successfully");
        }
        window.showNotification("SUCCESS", "SMS Template Saved");
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {
      simpleValidator.current.showMessages();
      setForceUpdate(true);
    }
  };
  return (
    <Paper variant={"outlined"} className={Styles.SendTemplateBox}>
        <Typography variant={"h5"} className={Styles.topBar}>Text Message Template</Typography>

      <Box component="form" sx={{ bgcolor: "white", p: 3, mb: 4 }}>
        <Box >
          <Box className={Styles.emailBodyInput}>
            <InputLabel required>SMS Body</InputLabel>
            <PersonalizedTag
                isSMS={true}
                onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, bodyRef, (value) =>
                        handleBody(value)
                    )
                }
            />
          </Box>
          <CoreTextField
              fullWidth
              multiline
              variant={"outlined"}
              required
              minRows={6}
              maxRows={6}
              name="body"
              value={body}
              onChange={(e) => handleBody(e.target.value)}
              inputRef={bodyRef}
              OutlinedInputProps={{
                maxLength: 250,
              }}
          />
          <FormHelperText className={Styles.helperText}>{simpleValidator.current.message("SMS Body", body, "required|max:250")}</FormHelperText>
        </Box>

        <Box className={Styles.saveButtonBox}>
          <Button
              color={"primary"}
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
              className={Styles.saveButton}

          >
            Save
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default SendWithText;
