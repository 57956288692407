import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Warning } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { permissionDeniedModalStyle } from "./permissionDeniedModalStyle";

const PermissionDeniedModal = ({
  open,
  onClose,
  permissionModule,
  action = "modify",
}) => {
  const classes = permissionDeniedModalStyle();

  const getDescription = () => {
    switch (action) {
      case "create":
        return `You do not have the necessary permission to create the ${permissionModule}.`;
      case "edit":
        return `You do not have the necessary permission to edit the ${permissionModule}.`;
      case "delete":
        return `You do not have the necessary permission to delete the ${permissionModule}.`;
      default:
        return `You do not have the necessary permission to modify the ${permissionModule}.`;
    }
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.bodyWrapper}>
              <Warning style={{ color: "var(--red)", fontSize: 60 }} />
              <span
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                  fontWeight: 600,
                }}
              >
                Permission Denied
              </span>
              <span style={{ fontSize: "14px", marginBottom: "20px" }}>
                {getDescription()}
              </span>
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                className={classes.closeButton}
              >
                Close
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default PermissionDeniedModal;