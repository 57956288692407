import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import AddOrEditNewProductModal from "./AddOrEditNewProductModal";
import { CustomButton } from "./productBody/CustomButton";
import { productStyle } from "./ProductStyle";

const ProductHeader = () => {
  const { topbarHeaderTitle } = productStyle();
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const handleCloseModal = () => {
    setOpenAddProductModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <Typography variant="h5" className={topbarHeaderTitle}>
          Manage Products & Services
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
          <CustomButton
            variant="contained"
            onClick={() => setOpenAddProductModal(true)}
            startIcon={<AddIcon />}
            disableElevation
          >
            New Product/Service
          </CustomButton>
        </Box>
      </Box>

      {openAddProductModal && (
        <AddOrEditNewProductModal
          open={openAddProductModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ProductHeader;
