import { makeStyles } from '@material-ui/core';
import React from 'react';
import Header from './Header';
import MainContent from './MainContent';

const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 120px)",
      borderRadius: "10px",
    },
  }));

const CustomFields = () => {
    const classes = useStyles();
        return (
          <div className={classes.root}>
            <Header />
            <MainContent />
          </div>
        );
};

export default CustomFields;