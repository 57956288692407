import React from "react";
import SideBar from "./SideBar";
import Content from "./Content";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
  },
}));

const CustomFieldsContent = () => {
const classes = useStyles();
  return (
    <div className={classes.root}>
      <SideBar />
      <Content />
    </div>
  );
};

export default CustomFieldsContent;
