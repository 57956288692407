import { useEffect, useState } from "react"
import Utils from "../../helpers/Utils";
import { saveNotifyAccount } from "../../api/profileApi";
import GlobalModal from "../../components/globals/Modal/GlobalModal";
import SaveIcon from "@material-ui/icons/Save";
import { BasicInput } from "../products/productsStyle";
import PopUpSkeleton from "./PopupSkeleton";


const Popup = (props) => {
    const [loading, setLoading] = useState(false);
    const [formData , setFormData] = useState({
        'full_name' : '',
        'email' : '',
        'phone' : '',
    })
    const [validationText,setValidationText] = useState("")
    const [errors,setIsErrors] = useState({emailOrPhone: '',full_name:'',phone:''})

    useEffect(() => {
        if (props.data) {
            setFormData(props.data)
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    const handleChange = (e,field=null) => {

        let {name,value} = e.target
       
        if(name==="email"){
			let invalid = "";
			if (!Utils.validateEmail(value) && value!=="") {
				invalid = "Invalid email";
			}
			setValidationText(invalid)
		}

        if(field === 'number'){
            if(!Utils.checkLetterExistInText(value) || value === ''){
                // value = value.replace('+','')
                
                if(value.length > 0 && value[0] !== '1'){
                    value= 1+value;
                }
                setFormData({
                    ...formData ,
                    phone : value
                })
            }else{
                try{
                    value = value.replace(/[^0-9]+/g, '')
                    if(value.length > 0 && value[0] !== '1'){
                        value= 1+value;
                    }
                    setFormData({
                        ...formData ,
                        phone : value
                    })                }
                catch(e){
                    console.log(e)
                }
                
            }
        }else{
            setFormData({
                ...formData ,
                [name] : e.target.value
            })
            
            setIsErrors({
                ...errors,emailOrPhone:'',
                full_name:''
            })
        }
        
    }


    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (handleValidation() && validationText ==="") {
            let formDatas;

            if (!props.data) {
                formDatas = {
                    ...formData
                };
                
            } else {
                formDatas = {
                   ...formData,
                    id: props.data.id
                };
                
            }

            setLoading(true);
            saveNotifyAccount({
                "form_data": formDatas
            }).then((response) => {
                if (response.data.status === 'success') {
                    toggle();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } 

    }

    const handleValidation = () => {
        let formInputList = {...formData}
        let errors = {}

        if(formInputList.email === "" && formInputList.phone=== ""){
            errors.emailOrPhone = "email or phone is required"
        }

        if(formInputList.full_name === ""){
            errors.full_name = "full name is required"
        }

        if(formInputList.phone !== ""){
           if(formInputList.phone.length   < 11 ){
            errors.phone = "phone number is invalid"
           }
        }
        
        if(Object.keys(errors).length>0){
            setIsErrors(errors)
            return false
        }else{
            return true
        }
    }


    return (
        <GlobalModal
            open={props.isOpen}
            onClose={() => props.hideModal("cancel")}
            title={props.title}
            buttonIcon={<SaveIcon />}
            buttonText="Save Notify Account"
            className={'global-xs-modal notify__wrapper__v2'}
            onSubmit={onSubmitHandle}>
            <div className="modal-content notify__container__v2">
                {loading ?
                    <div className="modal_body">
                        <PopUpSkeleton/>
                    </div>
                    :
                    <div style={{display:'flex',flexDirection:'column',gap:'20px'}}>
                        <BasicInput 
                        name="full_name"  
                        fullWidth 
                        type="text" 
                        placeholder="Full Name" 
                        value={formData.full_name} 
                        onChange={(e)=>handleChange(e,'full_name')}
                        />
                        {errors.full_name !== '' && <span className="notify__account__input__error">{errors.full_name}</span>}
                       <BasicInput
                        name="email" 
                        type="text" 
                        fullWidth 
                        placeholder="Email" 
                        value={formData.email} 
                        onChange={(e)=>handleChange(e,'email')}
                        />
                        {errors.emailOrPhone !== '' && <span className="notify__account__input__error">{errors.emailOrPhone}</span>}
                        
                        {
                            errors.emailOrPhone === '' && validationText !=="" &&
                            <span className="notify__account__input__error">{validationText}</span>
                            
						}
                         <BasicInput
                         mask={ ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        
                         placeholder="Enter a number"
                         fullWidth
                         guide={true}
                         keepCharPositions={false}
                         onChange={(e)=> handleChange(e,'number')}
                         value={formData.phone}
                         />
                         {errors.phone !== '' && <span className="notify__account__input__error">{errors.phone}</span>}
                        
                    </div>
                }
            </div>
        </GlobalModal>
    )
}

export default Popup;