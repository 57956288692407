import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState } from 'react';
const DEFAULT_FILE_LIMIT = 1;

const uploadIcon = () => {

        return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.77 12.258c.424 0 .768.347.768.774v3.763c0 .665.498 1.21 1.143 1.265 1.953.166 5.039.392 7.319.392 2.28 0 5.366-.227 7.32-.392a1.257 1.257 0 0 0 1.142-1.265v-3.763c0-.427.344-.774.769-.774.425 0 .769.347.769.774v3.763c0 1.456-1.096 2.684-2.552 2.808-1.954.165-5.097.397-7.448.397s-5.494-.232-7.449-.397C3.096 19.479 2 18.25 2 16.795v-3.763c0-.427.344-.774.77-.774Z" fill="#030D45"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.456 4.227a.766.766 0 0 1 1.088 0l3.077 3.097c.3.302.3.792 0 1.094a.766.766 0 0 1-1.088 0l-1.764-1.775v6.39a.772.772 0 0 1-.769.774.772.772 0 0 1-.77-.775V6.643L9.468 8.418a.766.766 0 0 1-1.088 0 .778.778 0 0 1 0-1.094l3.077-3.097Z" fill="#030D45"/></svg>
    
}   
const ImageUploadInput = (props) => {

    const [images,setImages] = useState([]);

    const handleChange = (file) => {
        if(file.length === 0) {
            return false;
        }

        // eslint-disable-next-line eqeqeq
        if((props.filesLimit !== undefined && props.filesLimit == 1) || DEFAULT_FILE_LIMIT === 1) {
            props.callBack(file);
            return false;
        }

        let uploadedImages = images;
        if(images.length > 0)
            uploadedImages.push(file);
        else
            uploadedImages = file;
        setImages(uploadedImages);
        if(uploadedImages.length > 0)
            props.callBack(uploadedImages);
    };

    const handleDelete = (prevImage) => {
        // eslint-disable-next-line eqeqeq
        if((props.filesLimit !== undefined && props.filesLimit == 1) || DEFAULT_FILE_LIMIT === 1) {
            props.callBack(null);
            return false;
        } else {
            let previousFiles = [images];
            const indexToRemove = previousFiles.indexOf(prevImage);
            previousFiles.splice(indexToRemove, 1);
            setImages(previousFiles);
            props.callBack(previousFiles);
        }
    };

    const showNotification = (message,variant) => {
        window.showNotification(variant.toUpperCase(),message);
    }

    return (
        <DropzoneArea
            acceptedFiles={['image/*']}
            Icon={uploadIcon}
            filesLimit={props.filesLimit !== undefined ? props.filesLimit : DEFAULT_FILE_LIMIT}
            maxFileSize={props.maxFileSize !== undefined ? props.maxFileSize : 5000000}
            dropzoneText={"Drag and drop image"}
            onChange={handleChange}
            onDelete={handleDelete}
            showAlerts={false}
            onAlert={showNotification}
        />
    );
}
export default ImageUploadInput;