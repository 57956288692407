import {InputBase, makeStyles, styled, TableCell, TableRow, withStyles} from "@material-ui/core";

const DealReportStyles = makeStyles((theme) => ({
    formControl: {
      background: theme.palette.background.paper,
      marginBlock: theme.spacing(1),
      minWidth: 120,
      border: '1px solid #e2e3e9',
      borderRadius: theme.spacing(.5),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    select: {
      paddingBlock: theme.spacing(.5),
      paddingInline: theme.spacing(1.5),
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent !important'
      }
    },
    MuiDisabled: {
      "&#ibMenuDisabledId": {
        color: `${theme.palette.text.primary} !important`
      }
    },
    
    menuItem: {
      "&#ibMenuItemId": {
        color: `${theme.palette.text.primary} !important`
      }
    },

    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: theme.spacing(.5)
    },
    itemInput: {
      background: 'white !important',
      border: '1px solid #e2e3e9 !important',
      paddingBlock:'10px !important',
      paddingLeft: '10px !important',
      borderRadius: '5px !important',
      marginBottom: '0px !important',
      boxShadow:'none !important'
    },

    textArea: {
      /* width: 75%; */
      height: '100px',
      padding:'10px',
      borderRadius: '5px',
        border: '1px solid #e2e3e9 !important',
      '&:focus-visible': {
        outline: 'none!important'
    },
      label_text: {
       color: '#133159 !important',
       fontSize: '16px',
       lineHeight: '30px',
       margin: 0,
      }
    },
    add_product_modal: {
      
    },
    filterWrapper: {
        display: "flex",
        gap: "10px",
        marginBottom: "10px",
        flexWrap: "wrap"
    },
    inputGroup: {
        display: "flex",
        flexDirection: "column"
    },
    inputLabel: {

    },
    inputField: {
      color: "#133159a6 !important",
      minWidth:"285px",
      width: "100% !important",
      height: "45px !important",
      marginTop: "4px !important",
      marginBottom: "7px !important",
      border: "1px solid rgb(141, 148, 165) !important",
      backgroundColor: "#ffffff !important",
      boxSizing: "border-box !important",
      borderRadius: "4px !important",
      fontSize: "16px !important",
      padding: "12px !important",
      lineHeight: "24px !important",
      fontWeight: "normal !important",
        "&:focus": {
            outline: "0 !important",
            boxShadow: "none !important"
        }
    },
    magnifyGlass : {
       position: "absolute",
        right: "10px",
        top: "70%",
        transform: "translateY(-50%)"
    }
  }));

export default DealReportStyles;

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#316aff',
        color: theme.palette.common.white,
        borderRadius: 0,
        fontSize: 16,
        fontWeight: 600,
    },
    body: {
        fontFamily: 'roboto, sans-serif !important',
        fontSize: 14,
        color: 'rgb(42 59 94)'
    },
}))(TableCell);

export const WrapperStyle = styled("div")(({ theme }) => ({
    "& .title": {
        color: "var(--dark_blue)",
        textTransform: "capitalize",
        marginBottom: theme.spacing(2),
    },
    "& .deal-report-filter": {},

    "& .contactWrapper": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.6),
    },
    "& .dealWon": {
        textTransform: "capitalize",
        backgroundColor: "#00ff91",
        color: "#181f48",
        fontWeight:"bold"
    },
    "& .dealLost": {
        textTransform: "capitalize",
        backgroundColor: "#ff5722",
        color: "white",
        fontWeight:"bold"
    },
    "& .dealActive": {
        textTransform: "capitalize",
        backgroundColor: "#ffc107",
        color: "black",
        fontWeight:"bold"
    },

    "& .dealReportDashboard": {
        marginBottom: theme.spacing(2),

        "& .dashboardItem": {
            color: "rgba(0, 0, 0, 0.87)",
            padding: theme.spacing(2),
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            overflow: "hidden",

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",

            minWidth: "0px",
            overflowWrap: "break-word",
            backgroundColor: "rgb(255, 255, 255)",
            backgroundClip: "border-box",
            border: "0px solid rgba(0, 0, 0, 0.125)",
            borderRadius: "1rem",
            boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
        },

        "& .dashboardIcon": {
            width: "3rem",
            height: "3rem",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            WebkitBoxPack: "center",
            WebkitBoxAlign: "center",
            opacity: 1,
            background:
                "linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))",
            color: "rgb(255, 255, 255)",
            borderRadius: "0.5rem",
            boxShadow:
                "rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem",
        },

        "& .dashboardText": {
            minWidth: 150,
            "& .textTitle": {
                margin: "0px",
                fontSize: "1rem",
                lineHeight: 1.5,
                letterSpacing: "0.02857em",
                opacity: 1,
                textTransform: "capitalize",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(103, 116, 142)",
                fontWeight: 700,
            },
            "& .textValue": {
                margin: "0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontSize: "1.5rem",
                lineHeight: 1.375,
                letterSpacing: "0em",
                opacity: 1,
                textTransform: "none",
                verticalAlign: "unset",
                textDecoration: "none",
                color: "rgb(52, 71, 103)",
                fontWeight: 700,
            },
        },
    },
    '& .dealReportTable': {
        '& .contactItem': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            '& svg': {
                fontSize: 14
            }
        }
    }
}));

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        marginTop: "4px",
        width: "120px"
    },
    input: {
        color: "#133159a6 !important",
        borderRadius: "4px !important" ,
        position: "relative !important",
        border: "1px solid rgb(141, 148, 165) !important",
        fontSize: "16px !important",
        padding: "12px 26px 12px 12px !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        backgroundColor: "#ffffff !important",
        "&:focus": {
            borderRadius: "4px !important" ,
            backgroundColor: "#ffffff !important",
        },
    },
}))(InputBase);

export const StyledButton = styled("button")(()=>({
    height: "44px",
    padding: "8px 15px",
    margin: "26px 0 0 0",
    background: "#3c7ef3",
    border: "none",
    borderRadius: "5px",
    fontFamily: "var(--poppins)",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.01em",
    color: "#fff",
    cursor: "pointer",
    "&:focus": {
        backgroundColor: "#3c7ef3 !important"
    },
    "&:disabled": {
        opacity: "0.7",
        cursor: "not-allowed"
    }
}));

export const StyledEmailInputGroup = styled("div")(()=>({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
}));

export const StyledEmailInput = styled("input")(()=>({
    width: "100% !important",
    height: "48px !important",
    marginTop: "4px !important",
    marginBottom: "7px !important",
    border: "1px solid #8D94A5 !important",
    boxSizing: "border-box !important",
    borderRadius: "4px !important",
    fontSize: "16px !important",
    padding: "12px !important",
    lineHeight: "24px !important",
    fontWeight: "normal !important",
    "&:focus-visible": {
        outline: "none !important",
        boxShadow: "none !important",
    }
}));

export const StyledExportContent = styled("div")(()=>({
    textAlign: "center",
    width: "100%",
    "& h1": {
        fontSize: "1.5rem",
        marginBottom: "1rem",
        color: "#36454F",
    }
}));

export const StyledExportWrapper = styled("div")(()=>({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    padding: "2rem",
    border: "1px solid #ddd",
    borderRadius: "4px",
    width: "43rem",
    margin: "0 auto",
}));

export const StyledEmailInputLabel = styled("label")(()=>({
    fontSize: "13px",
    color: "#36454F",
}));

export const StyledUl = styled("ul")(()=>({
    padding: 0,
    margin: 0,
    marginTop: "10px",
    "& li": {
        backgroundColor: "#e7e7df",
        padding: "5px 2px",
        "&:not(:last-child)": {
            marginBottom: "4px"
        }
    }
}));