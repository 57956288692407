import React, { useMemo } from "react";
import {CardElement} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../../../hooks/useResponsiveFontSize";

const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    return useMemo(
        () => ({
            style: {
                base: {
                    fontSize,
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                }, invalid: {
                    color: "#9e2146"
                },
            }, hidePostalCode: true
        }),
        [fontSize]
    );
};

const CardForm = (props) => {
    const options = useOptions();

    return (
        <div>
            <span className="stripe_input_label">Card Details</span>
            <label className="stripe_input">
                <CardElement
                    options={options}
                    onReady={() => {
                        // console.log("CardElement [ready]");
                    }}
                    onChange={event => {
                        // console.log("CardElement [change]", event);
                    }}
                    onBlur={() => {
                        // console.log("CardElement [blur]");
                    }}
                    onFocus={() => {
                        // console.log("CardElement [focus]");
                    }}
                />
            </label>
        </div>
    );
};

export default CardForm;