import { InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { deleteCallOutcome, getCallOutcomeList } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import { useIsMounted } from '../../hooks/IsMounted';
import BasicInput from "../custom/BasicInput";
import { icons } from "../leadSource/icon";
import '../leadSource/lead_source.css';
import CallOutcomePopup from './CallOutcomePopup';
import CustomDeleteModal from "./CustomDeleteModal";
import CallOutcomesSkeleton from "./Skeleton/CallOutcomesSkeleton";

const CallOutcome = () => {
    const sortType = 'asc';
    const history = useHistory();
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [editCallOutcome, setEditCallCome] = useState(null);
    const description = "You are attempting to delete a global call outcome disposition. This change will be applied to all users on your team."
    const DeleteModalImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`


    useEffect(() => {
        Utils.scrollToTop();
    },[]);

    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    // debouncing
    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


    const deleteData = (_id) => {
        setLoading(true);
        deleteCallOutcome({
            "form_data": {
                id: _id
            }
        })
        .then(response => {
            setReset(reset + 1);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleDelete = (item) => {
        confirmAlert({
          customUI: ({ onClose }) => (
            <CustomDeleteModal
              confirmationText={item.outcome}
              title="Notice"
              onClose={onClose}
              handleConfirm={() => deleteData(item.id)}
              closeButtonText="Cancel"
              confirmButtonText="Delete"
              showConfirmButton={true}
              showCloseButton={true}
              description={description}
              placeholder="Type call disposition name"
              imgSrc= {DeleteModalImg}
            />
          ),
          closeOnClickOutside: false,
        });
      };

    const loadData = () => {
        setLoading(true);
        getCallOutcomeList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: 'outcome',
                sort_type: sortType,
                query: debouncedQuery
            }
        })
        .then(response => {
            setData(response.data.data.data);
            setTotal(response.data.data.total);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleChangePage = (event, newPage) => {
        event.preventDefault();
        if(newPage === null){
            return;
        }
        setCurrentPage(newPage-1);
    };


    const handlePerPageChange = (event) => {
		setPerPage(event.target.value);
	}

    const renderPagination = () => {
        let previous_page = null, next_page = null; 
        let totalPages = Math.ceil(total / perPage);
        let _currentPage = currentPage + 1;
        if(_currentPage > 1){
            previous_page = _currentPage - 1
        }
        // eslint-disable-next-line eqeqeq
        if(_currentPage != totalPages) {
            next_page = _currentPage + 1
        }
        // eslint-disable-next-line eqeqeq
        if(total == 0){
            return false;
        }
    
        return(
            <div className="pagination_bar">
                <ul>
                    <li>
                        <button onClick={e => handleChangePage(e, previous_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1',padding:"13px",border:"none",borderRadius:"5px"} : {backgroundColor: '#9daac1', borderColor: '#9daac1',padding:"13px",border:"none",borderRadius:"5px"}}
                        >
                            {icons.paginationPrevious}
                        </button>
                    </li>
                    <li>
                        <a href="#!" onClick={e => e.preventDefault()}>
                            <span>{_currentPage}</span>
                        </a>
                    </li>
                    <li>
                        <a href="#!" onClick={e => e.preventDefault()} className="d-flex align-items-center"> 
                            <span>
                                {icons.paginationBackwardSlash}
                            </span>	
                            {totalPages}
                        </a>
                    </li>
                    <li>
                        <button onClick={(e) => handleChangePage(e, next_page)}
                            style={previous_page == null ? {cursor : 'not-allowed', backgroundColor: '#9daac1', borderColor: '#9daac1',padding:"13px",border:"none",borderRadius:"5px"} : {backgroundColor: '#9daac1', borderColor: '#9daac1',padding:"13px",border:"none",borderRadius:"5px"}}
                        >
                            {icons.paginationNext}	
                        </button>
                    </li>
                    <li style={{color: '#9daac1', marginLeft: 20}}>Total Outcome : {total}</li>
                </ul>
            </div>
        )
    }

    const renderData = () => {
      if (!loading && !data.length) {
        return (
          <tr>
            <td colSpan="2">
              <div style={{ paddingTop: 50 }}>NO DATA AVAILABLE</div>
            </td>
          </tr>
        );
      } else if (!loading && data.length) {
        return data.map((item, index) => (
          <tr key={index}>
            <td
              width="80%"
              style={{ paddingLeft: "10px", paddingRight: "2px" }}
            >
              {item.outcome}
            </td>
            <td
              width="20%"
              style={{
                paddingLeft: "10px",
                paddingRight: "2px",
                textAlign: "center",
              }}
            >
              <a
                href="#!"
                className="edit mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setEditCallCome(item);
                  setModalVisible(true);
                }}
              >
                {icons.editCallOutcome}
                Edit
              </a>
              <a
                href="#!"
                className="delete"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(item);
                }}
              >
                {icons.delete}
                Delete
              </a>
            </td>
          </tr> 
        ));
      }
    };

    return (
      <div className="settings_section">
        <div className="settings_content">
          <div id="call_outcome" className="col s12 active">
            <div className="heading">
              <h5>
                Call Outcome <span>list</span>
              </h5>
              <div className="add_new_user d-flex">
                <button
                  type="submit"
                  className="btn_submit accent--bg--text--color mr-2"
                  onClick={() => setModalVisible(true)}
                >
                  {icons.addNewPlus}
                  Add New Call Outcome
                </button>
                {history.location.state !== undefined &&
                  history.location.state.from === "settingPage" && (
                    <Link
                      to="/user/settings"
                      className={"modal-trigger accent--bg--text--color"}
                    >
                      <span>
                        {icons.CallOutcomeback}
                      </span>
                      <span>Back</span>
                    </Link>
                  )}
              </div>
            </div>

            <form action="#" method="POST">
              <div className="search_form">
                <div className="form_group" style={{ height: 40 }}>
                  <span>Show</span>
                  <div className="input-field col s12">
                    <select
                      style={{ display: "block" }}
                      value={perPage}
                      onChange={handlePerPageChange}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                  <p>Records</p>
                </div>

                <div className="">
                  <BasicInput
                    value={query}
                    placeholder="Search call disposition name"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon style={{ color: "#656565" }} />
                      </InputAdornment>
                    }
                    style={{ width: "300px" }}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </div>
            </form>
            <div style={{minHeight:"48vh"}}>
              {loading ? (
                <CallOutcomesSkeleton />
              ) : (
                <table className="data_table">
                  <thead>
                    <tr>
                      <th
                        width="80%"
                        style={{ paddingLeft: "10px", paddingRight: "2px" }}
                      >
                        Call Disposition Name
                      </th>
                      <th
                        width="20%"
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "2px",
                          textAlign: "center",
                        }}
                      >
                        Action{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderData()}</tbody>
                </table>
              )}
            </div>

            {/* <Pagination /> */}
            {renderPagination()}
          </div>

          {modalVisible && (
            <CallOutcomePopup
              hideModal={(isReset) => {
                if (isReset) {
                  setReset(reset + 1);
                }
                setEditCallCome(null);
                setModalVisible(false);
              }}
              title={"Add Call Outcome"}
              editCallOutcome={editCallOutcome}
            />
          )}
        </div>
      </div>
    );
}

export default CallOutcome;