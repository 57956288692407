import React, { useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditLostReasonModal from "./AddOrEditLostReasonModal";
import { CustomButton } from "./LostReasonBody/CustomButton";
import { LostReasonStyle } from "./LostReasonStyle";
import { HelpOutlineOutlined } from "@material-ui/icons";

const LostReasonHeader = () => {
  const { topbarHeaderTitle } = LostReasonStyle();
  const [openAddLostReasonModal, setOpenAddLostReasonModal] = useState(false);
  const handleCloseModal = () => {
    setOpenAddLostReasonModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <Typography variant="h5" className={topbarHeaderTitle}>
          Lost Deal Reasons
        </Typography>
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
          <Button
            size="small"
            variant="text"
            startIcon={<HelpOutlineOutlined />}
          >
            <i>How does this work?</i>
          </Button>

          <CustomButton
            variant="contained"
            onClick={() => setOpenAddLostReasonModal(true)}
            startIcon={<AddIcon />}
            disableElevation
          >
            New Reason
          </CustomButton>
        </Box>
      </Box>

      {openAddLostReasonModal && (
        <AddOrEditLostReasonModal
          open={openAddLostReasonModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default LostReasonHeader;
