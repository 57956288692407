import React, {Fragment, useEffect, useState} from "react";
import {Box, Checkbox, Chip, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import {
    convertIncome,
    getDncStatusLabelAndColor,
    getEmailValidationStatusLabelAndColor,
    getLandlineMobileStatus,
    getNumberValidationStatusLabelAndColor, getSkipTraceStatusLabelAndColor,
    getEmailValidationStatusIcon, getNumberValidationStatusIcon,
    getDncStatusIcon,
} from "./helpers";
import Styles from "./MapOrderDetails.module.css";
import Utils from "../../helpers/Utils";
import moment from 'moment-timezone';
const userTimezone = Utils.getAccountData('userTimezoneRaw');



const MapOrderItemCombine = ({ contact, index, onSelect, isSelected, collectIds, allSelected, selectAddressGroup, deSelectAddressGroup  }) => {
    const [fullAddress, setFullAddress] = useState("");
    const [subContactIds, setSubContactIds] = useState([]);
    const [selectedSubContactIds, setSelectedSubContactIds] = useState([]);
    const [isGroupSelected, setGroupSelected] = useState(false);

    const firstKey = Object.keys(contact)[0];
    let enhanceCreatedAt = 0;
    if (contact[firstKey] && contact[firstKey]['enhance_data_search_clean_contact_history'] && contact[firstKey]['enhance_data_search_clean_contact_history']['created_at']) {
        enhanceCreatedAt = contact[firstKey]['enhance_data_search_clean_contact_history']['created_at'];
        enhanceCreatedAt = TimezoneConversation(enhanceCreatedAt, 'UTC', userTimezone, 'M-D-Y');
    }

    const TimezoneConversation = (inputTime, currentTimezone = 'UTC', convertTimezone = 'UTC', format = "YYYY-MM-DD HH:mm:ss") => {

        try {
            var currentTimeInGivenTimezone = moment.tz(inputTime, currentTimezone);
            return currentTimeInGivenTimezone
                .clone()
                .tz(convertTimezone)
                .format(format);
        } catch (e) {
            return inputTime
        }

    }

    useEffect(() => {
        if (contact){
            collectIds(contact.id);

            let tempFullAddress = contact.address;

            if (contact.city){
                tempFullAddress += ", ";
                tempFullAddress += contact.city;
            }

            if (contact.state){
                tempFullAddress += ", ";
                tempFullAddress += contact.state;
            }

            if (contact.zip){
                tempFullAddress += " ";
                tempFullAddress += contact.zip;
            }

            setFullAddress(tempFullAddress);

            if (contact.enhance_data){
                let tempSubContactIds = [];
                Object.entries(Object.values(contact.enhance_data)[0]).forEach(([id, iContact])=>{
                    tempSubContactIds.push(parseInt(id));
                    collectIds(id);
                    if (iContact && iContact.additional_contacts){
                        iContact.additional_contacts.forEach((additionalContact)=>{
                            tempSubContactIds.push(additionalContact.id);
                            collectIds(additionalContact.id);
                        });
                    }
                });

                setSubContactIds(tempSubContactIds);
            }
        }
    }, [contact]);

    useEffect(()=>{
        if (allSelected){
            setGroupSelected(true);
            if (selectedSubContactIds[0]){
                setSelectedSubContactIds([]);
            }
        }else if (!allSelected){
            setGroupSelected(false);
            if (selectedSubContactIds[0]){
                setSelectedSubContactIds([]);
            }
        }
    },[allSelected]);

    const handleInternalIds = (id) => {
        onSelect(id);
        if (selectedSubContactIds.includes(id)) {
            if (selectedSubContactIds.length < subContactIds.length){
                setGroupSelected(false);
            }
            setSelectedSubContactIds(
                selectedSubContactIds.filter(
                    (selectedFileContactId) => selectedFileContactId !== id
                )
            );
        } else {
            if ((selectedSubContactIds.length + 1) === subContactIds.length){
                setGroupSelected(true);
            }
            setSelectedSubContactIds([...selectedSubContactIds, id]);
        }
    };

    return(
        <Fragment key={index}>
            <TableRow className="stt-address-row">
                <TableCell className="stt-address-cell">
                    <Box style={{ display: "flex", alignItems: "center", margin: "5px 0px 5px 10px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    indeterminate={((selectedSubContactIds.length > 0) && (selectedSubContactIds.length < subContactIds.length))}
                                />
                            }
                            checked={isGroupSelected}
                            onChange={(event, checked)=>{
                                if (checked){
                                    selectAddressGroup([...subContactIds, contact.id]);
                                    setSelectedSubContactIds(subContactIds);
                                    setGroupSelected(true);
                                }else {
                                    deSelectAddressGroup([...subContactIds, contact.id]);
                                    setSelectedSubContactIds([]);
                                    setGroupSelected(false);
                                }
                            }}
                        />
                        <Box>
                            {fullAddress}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="left">
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        {contact.first_name} {contact.last_name}
                    </Box>
                </TableCell>
                <TableCell align="left">{contact.email}</TableCell>
                <TableCell align="center">
                    {getEmailValidationStatusIcon(contact.email_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getEmailValidationStatusLabelAndColor(contact.email_valid_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getEmailValidationStatusLabelAndColor(contact.email_valid_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="left">{contact.number}</TableCell>
                <TableCell align="center">
                    {getNumberValidationStatusIcon(contact.number_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getNumberValidationStatusLabelAndColor(contact.number_valid_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getNumberValidationStatusLabelAndColor(contact.number_valid_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    <Chip
                    label={getLandlineMobileStatus(contact.number_state)}
                    className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                    size="small"
                    />
                    {/*<Chip*/}
                    {/*    label={getLandlineMobileStatus(contact.number_state)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getLandlineMobileStatus(contact.number_state)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    {getDncStatusIcon(contact.dnc_status)}
                    {/*<Chip*/}
                    {/*    label={getDncStatusLabelAndColor(contact.dnc_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getDncStatusLabelAndColor(contact.dnc_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center" className="stt-address-cell">
                    {
                        enhanceCreatedAt !== 0 &&
                        <Chip
                            size={"small"}
                            className={Styles.custom_data_enhance_blue_btn}
                            color='primary'
                            style={{
                                backgroundColor: getSkipTraceStatusLabelAndColor("TRACED")[1],
                            }}
                            label={enhanceCreatedAt !== 0 ? enhanceCreatedAt : ''}
                        />
                    }
                    {/*<Chip*/}
                    {/*    size="small"*/}
                    {/*    variant="default"*/}
                    {/*    color="primary"*/}
                    {/*    style={{ backgroundColor: getSkipTraceStatusLabelAndColor(contact.skip_trace_status)[1] }}*/}
                    {/*    label={getSkipTraceStatusLabelAndColor(contact.skip_trace_status)[0]}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    {convertIncome(contact.income)}
                </TableCell>
            </TableRow>
            {
                contact.enhance_data &&
                Object.entries(Object.values(contact.enhance_data)[0]).map(([id, iContact])=>(
                    <MapOrderItemComplexContact contact={iContact} onSelect={handleInternalIds} isSelected={isSelected}/>
                ))
            }
        </Fragment>
    );
};

export default MapOrderItemCombine;

const MapOrderItemComplexContact = ({ contact, onSelect, isSelected, isTop=true }) => {
    return (
        <Fragment>
            <TableRow className={`stt-address-details-row ${isTop ? ' top-contact': ''}`}>
                <TableCell></TableCell>
                <TableCell align="left">
                    <Box style={{ display: "flex", alignItems: "center", margin: "5px 0px 5px 0px" }}>
                        <Checkbox
                            size="small"
                            color="primary"
                            checked={isSelected(contact.id)}
                            onClick={()=>{
                                onSelect(contact.id)
                            }}
                        />
                        {contact.first_name} {contact.last_name}
                    </Box>
                </TableCell>
                <TableCell align="left">{contact.email}</TableCell>
                <TableCell align="center">
                    {getEmailValidationStatusIcon(contact.email_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getEmailValidationStatusLabelAndColor(contact.email_valid_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getEmailValidationStatusLabelAndColor(contact.email_valid_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="left">{contact.number}</TableCell>
                <TableCell align="center">
                    {getNumberValidationStatusIcon(contact.number_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getNumberValidationStatusLabelAndColor(contact.number_valid_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getNumberValidationStatusLabelAndColor(contact.number_valid_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    <Chip
                        label={getLandlineMobileStatus(contact.number_state)}
                        className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                        size="small"
                    />
                    {/*<Chip*/}
                    {/*    label={getLandlineMobileStatus(contact.number_state)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getLandlineMobileStatus(contact.number_state)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    {getDncStatusIcon(contact.dnc_status)}
                    {/*<Chip*/}
                    {/*    label={getDncStatusLabelAndColor(contact.dnc_status)[0]}*/}
                    {/*    color="primary"*/}
                    {/*    size="small"*/}
                    {/*    style={{ backgroundColor: getDncStatusLabelAndColor(contact.dnc_status)[1] }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="center">
                    {convertIncome(contact.income)}
                </TableCell>
            </TableRow>
            {
                contact.additional_contacts &&
                contact.additional_contacts.map((iContact, i)=>(
                    <MapOrderItemComplexContact key={i} contact={iContact} onSelect={onSelect} isSelected={isSelected} isTop={false}/>
                ))
            }
        </Fragment>
    );
};