import React, { Fragment, useEffect, useRef, useState } from "react";

import Pagination from "./Pagination";
import Utils from "../../helpers/Utils";
import { SettingIconList } from "../../constants/SettingIconList";
import './report-style.css';
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useIsMounted } from "../../hooks/IsMounted";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { getUserSmartWebhooksHistoryListApi } from "../../api/smartWebhookApi";


let currentDate = moment(new Date()).format("YYYY-MM-DD");
let prevDate = moment(currentDate).subtract(30, "days").format("YYYY-MM-DD");
const SmartWebhookHistoryReport = (props) => {
    const isMounted = useIsMounted();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [searchStatus, setSearchStatus] = useState(-1);
    const parentData = props.location.state.item;

    const [filterQuery, setFilterQuery] = useState({
        startDate: null,
        endDate: null
    });

    const [openDateField, setOpenDateField] = useState({
        isStartDateOpen: false,
        isEndDateOpen: false
    });

    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const handleDateChange = (item, value) => {
        let momentObj = moment(value);
        if (item === "startDate") {
            if (value) {
                setFilterQuery({ ...filterQuery, startDate: momentObj.format("YYYY-MM-DD") });
            } else {
                setFilterQuery({ ...filterQuery, startDate: prevDate });
            }
        } else if (item === "endDate") {
            if (value) {
                setFilterQuery({ ...filterQuery, endDate: momentObj.format("YYYY-MM-DD") });
            } else {
                setFilterQuery({ ...filterQuery, endDate: currentDate });
            }
        }
    };

    useEffect(() => {
        document.title = `Smart Webhook Report | ${Utils.getAccountData('AuthUserAgencyName')}`;
    }, [])

    useEffect(() => {
        if (isMounted) {
            const timer = setTimeout(() => {
                loadData({ currentPage: 1, perPage: perPage })
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [searchText]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData({ currentPage: 1, perPage: perPage })
    }, [filterQuery, searchStatus]) // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = (payload = null) => {
        if (payload && payload.currentPage !== currentPage) {
            setCurrentPage(payload.currentPage)
        }
        if (payload && payload.perPage !== perPage) {
            setPerPage(payload.perPage)
        }

        setLoading(true);

        getUserSmartWebhooksHistoryListApi({
            webhookId: parentData.webhookId,
            page: payload.currentPage,
            limit: payload.perPage,
            searchText: searchText,
            status: searchStatus === -1 ? '' : searchStatus,
            startDate: filterQuery.startDate,
            endDate: filterQuery.endDate
        })
        .then(response => {
            console.log(response)
            if (response.data && response.success) {
                // let pages = Math.round(response.count / payload.perPage);
                // if (pages < 1) {
                //     setTotalPages(1);
                // } else {
                //     setTotalPages(pages);
                // }
                setTotalPages(totalPages);
                setData(response.data);
                setTotal(response.count);
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    const renderData = () => {
        if (loading) {
            let loadingView = [];
            for (let i = 0; i < perPage; i++) {
                loadingView.push(
                    <tr key={i}>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                        <td > <Skeleton width="100%" height={"16px"}> </Skeleton></td>
                    </tr>
                );
            }
            return loadingView;

        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colSpan={6} style={{ paddingTop: 50, textAlign: "center" }}>
                        NO DATA AVAILABLE
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return (
                data.map((item, index) => (
                    <tr key={index}>
                        <td>
                            <ul>
                                <li>{item.name} </li>
                                <li>{item.email} </li>
                                <li>{item.phone} </li>
                            </ul>
                        </td>
                        <td>
                            <span style={{ color: item.status == 2 ? '#52b202' : '#b23c17'}}>
                                {item.status == 2 ?'Success':'Failed'}
                            </span>
                            
                        </td> 
                        <td>
                            {item.gateway_response &&
                            <span className="m__report_tooltip">
                                {item.gateway_response.slice(0, 50)}...
                                <div>{item.gateway_response}</div>
                            </span>
                            }
                            
                        </td>
                        <td>
                            { window.globalTimezoneConversionToDifferentTimezone(item.send_date, 'UTC','','MM/DD/YYYY hh:mm a')}
                        </td>
                    </tr>
                ))
            )
        }
    }

    return (
        <Fragment>
            <div className='container-fluid mail__inbox mt-1'>
                <div className='tagBox'>
                    <div className='tag_list'>
                        <h5>{parentData.webhookTitle} <br /> <small>{parentData.usedAt}</small></h5>

                    </div>
                    <div className='add_new_user'>
                        <Link
                            className={"modal-trigger accent--bg--text--color"}
                            to='/user/smart-webhook-report'
                        >
                            <span>
                                {SettingIconList.back}
                            </span>
                            <span>Back</span>
                        </Link>

                    </div>
                </div>
                <div className="details">

                    <Fragment>
                        <div className="">
                            <div className='tagBox'>
                                <div className='d-flex'>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={perPage}
                                            label=""
                                            onChange={(e) => { loadData({ currentPage: 1, perPage: e.target.value }) }}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div style={{ paddingLeft: "10px", paddingTop: "3px" }}>records</div>
                                </div>
                                <div style={{ marginLeft: "auto", padding: "0px 15px" }}>
                                    <div style={{ display: "flex", gap: 10 }}>
                                        <div className="webhook-report-filter">
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="-simple-select"
                                                    value={searchStatus}
                                                    label="Filter by status"
                                                    onChange={(e) => { setSearchStatus(e.target.value) }}
                                                >
                                                    <MenuItem value={-1}>Filter by status</MenuItem>
                                                    <MenuItem value={1}>Failed</MenuItem>
                                                    <MenuItem value={2}>Success</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className="webhook-datepicker">
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    inputRef={startDateRef}
                                                    clearable
                                                    onClick={() => setOpenDateField({ isStartDateOpen: true, isEndDateOpen: false })}
                                                    onClose={() => setOpenDateField({ isStartDateOpen: false, isEndDateOpen: false })}
                                                    open={openDateField.isStartDateOpen}
                                                    inputProps={{ readOnly: true }}
                                                    value={filterQuery.startDate ? filterQuery.startDate : null}
                                                    onChange={(value) => handleDateChange("startDate", value)}
                                                    margin="normal"
                                                    format="MM/dd/yyyy"
                                                    KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
                                                    placeholder="Start Date"
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    inputRef={endDateRef}
                                                    clearable
                                                    onClick={() => setOpenDateField({ isStartDateOpen: false, isEndDateOpen: true })}
                                                    onClose={() => setOpenDateField({ isStartDateOpen: false, isEndDateOpen: false })}
                                                    open={openDateField.isEndDateOpen}
                                                    inputProps={{ readOnly: true }}
                                                    value={filterQuery.endDate ? filterQuery.endDate : null}
                                                    onChange={(value) => handleDateChange("endDate", value)}
                                                    margin="normal"
                                                    format="MM/dd/yyyy"
                                                    KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
                                                    placeholder="End Date"
                                                />
                                            </MuiPickersUtilsProvider>


                                        </div>

                                        <div class="record_search">
                                            <input type="text" value={searchText} placeholder="Search contact" class="search" onChange={(e) => setSearchText(e.target.value)} />
                                            <div class="search_icon">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z" fill="#133159" fill-opacity="0.65"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table >
                                <thead >
                                    <tr>
                                        <th style={{paddingLeft: "30px", width: '22%' }}>Contact Info</th>
                                        <th style={{paddingLeft: "30px", width: '10%'}}>Status</th>
                                        <th style={{paddingLeft: "30px", width: '28%'}}>Response </th>
                                        <th style={{paddingLeft: "30px", width: '15%'}}>Send At </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>

                            <Pagination
                                paginationData={{ totalRecords: total, totalPages, currentPage, perPage }}
                                setCurrentPage={setCurrentPage}
                                getNewData={loadData}
                            />

                        </div>
                    </Fragment>
                </div>
            </div>
        </Fragment>
    )
}

export default SmartWebhookHistoryReport;