import {
    Checkbox,
    LinearProgress,
    MenuItem,
    Modal,
    Radio,
    styled,
    Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
} from "@material-ui/core";

export const PageWrapper = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center !important"
});

export const PageHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%"
});

export const CustomTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        // gap: 15,
        "&.MuiInputBase-adornedStart .MuiInputBase-input": {
            marginLeft: "15px !important",
        },

        "&.MuiInputBase-inputAdornedEnd .MuiInputBase-input": {
            marginRight: "15px !important",
        },
    },
    "& .MuiInputBase-input": {
        height: "unset !important",
        margin: "unset !important",
        borderBottom: "unset !important",
        paddingBlock: "10px !important",
        paddingLeft: "10px !important",
        "&:focus": {
            boxShadow: "none !important",
        },
    },
});

export const CustomRadio = styled(Radio)({
    "& .PrivateSwitchBase-input": {
        position: "static",
        opacity: "unset",
        pointerEvents: "auto",
        "& +span": { display: "none" },
    },
});

export const CustomCheckbox = styled(Checkbox)({
    "& .PrivateSwitchBase-input": {
        position: "static",
        opacity: "unset",
        pointerEvents: "auto",
        "& +span": { content: "unset !important" },
    },
});

export const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    "&.Mui-selected, &.Mui-selected:hover": {
        //   backgroundColor: '#cbd4ff!important',
        backgroundColor: theme.palette.primary.light,
    },
    ":hover": {
        backgroundColor: theme.palette.primary.light,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            paddingLeft: "8px",

            "& .MuiMenuItem-root": {
                color: "gold !important",
            },
        },
    },
};

export const CustomEmailLimitTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        // gap: 15,
        "&.MuiInputBase-adornedStart .MuiInputBase-input": {
            marginLeft: "15px !important",
        },

        "&.MuiInputBase-inputAdornedEnd .MuiInputBase-input": {
            marginRight: "15px !important",
        },
    },
    "& .MuiInputBase-input": {
        textAlign: "center",
        height: "unset !important",
        margin: "unset !important",
        //   borderBottom: "unset !important",
        paddingBlock: "4px !important",
        paddingLeft: "10px !important",
        border: "1px solid rgba(29, 41, 57, 0.12)!important",
        borderRadius: "8px !important",

        "&:focus": {
            boxShadow: "none !important",
        },
    },
});

export const EmailLimitModalWrapper = styled(Modal)(({ theme }) => ({
    "& .modalWrapper": {
        position: "absolute",
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "8px",
        boxShadow: theme.shadows[5],

        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "&:focus-visible": {
            outline: "none"
        }
    },

    "& .modalHeaderWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        borderBottom: `1px solid rgba(148, 157, 178, 0.12)`,
        "& .modalHeaderTitle": {
            display: "flex",
            alignItems: "center",
            gap: 8,
        },
    },

    "& .modalBodyWrapper": {
        padding: "24px 16px",
    },

    "& .modalFooterWrapper": {
        display: "flex",
        justifyContent: "flex-end",
        gap: 8,
        padding: "16px",
        borderTop: `1px solid rgba(148, 157, 178, 0.12)`,
    },

    "& .limitScopeWrapper": {
        "& .MuiRadio-colorPrimary.Mui-checked": {
            color: '#3c7ef3'
        },
        "& .MuiFormControlLabel-label": {
            fontFamily: "Roboto, sans-serif !important",
            color: 'rgba(0, 0, 0, 0.87) '
        }
    }
}));

export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: 16,
    "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#3c7ef3 !important",
    },
}));

export const CustomStyledTable = styled(Table)(({ theme }) => ({
    "& .MuiTableHead-root": {
        "& .MuiTableRow-head": {
            "& .MuiTableCell-head": {
                fontSize: "16px !important",
                fontWeight: 500,
                color: "var(--dark_blue)",
                backgroundColor: "var(--gray_light)",
                paddingInline: "16px !important",
                paddingBlock: "12px !important",
            },
        },
    },

    "& .MuiTableBody-root": {
        "& .MuiTableRow-root": {
            borderBottom: "3px solid rgba(60, 126, 243, 0.05)",
        },
    },
}));

export const CustomButton = styled("button")({
    padding: "8px 15px",
    margin: "1.0933333333rem 0 .656rem 0",
    background: "#3c7ef3",
    borderRadius: "5px",
    fontFamily: "var(--poppins)",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: ".01em",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "#3c7ef3 !important"
    },
    "&:hover": {
        opacity: "0.7"
    }
});

export const PageBody = styled("div")({
   width: "100%"
});

export const StyledConnectEmailWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    padding: "2rem",
    border: "1px solid #ddd",
    borderRadius: "4px",
    width: "30rem",
    margin: "0 auto",
    "&:focus-visible": {
        outline: "none"
    }
});

export const StyledConnectEmailContent = styled("div")({
    textAlign: "center",
    "& h1": {
      fontSize: "1.5rem",
      marginBottom: "1rem"
    }
});

export const StyledConnectEmailIconWrapper = styled("div")({
    width: "80px",
    height: "80px",
    backgroundColor: "#3c7ef3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    margin: "0 auto 25px auto",
    "& svg": {
        fontSize: "50px",
        color: "#fff"
    }
});

export const StyledAddEmailInput = styled("input")({
    width: "100% !important",
    height: "48px !important",
    marginTop: "4px !important",
    marginBottom: "7px !important",
    border: "1px solid #8D94A5 !important",
    boxSizing: "border-box !important",
    borderRadius: "4px !important",
    fontSize: "16px !important",
    padding: "12px !important",
    lineHeight: "24px !important",
    fontWeight: "normal !important",
    "&:focus-visible": {
        outline: "none !important",
        boxShadow: "none !important"
    }
});

export const StyledConnectEmailButton = styled("button")({
    color: "#f4f5ec",
    display: "inline-block",
    padding: "10px 15px 8px 15px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    background: "#3c7ef3",
    border: "1px solid #3c7ef3",
    height: "48px",
    width: "100%",
    borderRadius: "4px",
    marginTop: "10px",
    "&:focus": {
        background: "#3c7ef3",
        border: "1px solid #3c7ef3"
    }
});

export const StyledCancelButton = styled("button")({
    color: "#f4f5ec",
    display: "inline-block",
    padding: "10px 15px 8px 15px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    cursor: "pointer",
    background: "#c81e1e",
    border: "1px solid #c81e1e",
    height: "48px",
    width: "100%",
    borderRadius: "4px",
    "&:focus": {
        background: "#c81e1e",
        border: "1px solid #c81e1e"
    }
});

export const StyledTable = styled(Table)({
    borderRadius: '0 !important',
});

export const StyledTableHead = styled(TableHead)({});

export const StyledTableRow = styled(TableRow)({});

export const StyledTableCell = styled(TableCell)({
    paddingLeft: '10px',
});

export const StyledTableBody = styled(TableBody)({});