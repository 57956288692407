import React from "react";

const StepRight = ({step,title,content="",img}) => {
    return(
        <div className="geo__step__right__wr">
            <div className="geo__step__right__left">
                <img src={img} alt="step"/>
            </div>
            <div className="geo__step__right__right">
                <h2 className="geo__step__right__right__step">{step}</h2>
                <h3 className="geo__step__right__right__title">{title}</h3>
                <p className="geo__step__right__right__content">{content}</p>
                
                <div className="geo__marketing__social">
                    <div className="geo__marketing__social__single">
                        {/* <TelegramIcon/><span>Name</span> */}
                        <ol style={{listStyle : 'disc'}}>
                            <li style={{fontSize: '20px'}}>Name</li>
                            <li style={{fontSize: '20px'}}>Phone</li>
                            <li style={{fontSize: '20px'}}>Email</li>
                            <li style={{fontSize: '20px'}}>Income Range</li>
                            <li style={{fontSize: '20px'}}>Home Value</li>
                            <li style={{fontSize: '20px'}}>Marital Status</li>
                            <li style={{fontSize: '20px'}}>Credit Score Range</li>
                            <li style={{fontSize: '20px'}}>And Much More…</li>
                        </ol>
                    </div>
                   
                </div>
            </div>
          
        </div>
    )
}


export default StepRight;