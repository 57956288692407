import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const TeamSkeleton = () => {
  const skeletonRows = Array.from(new Array(8));

  return (
    <Box padding={3}>
      <Paper style={{ padding: '20px', borderRadius: '8px' }}>
      <Box display="flex" alignItems="center" marginBottom={1}>
          <Skeleton variant="rect" width="140px" height={30} />
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center" marginBottom={2}>
          <Skeleton variant="rect" width="30%" height={40} />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Skeleton width="80px" />
              </TableCell>
              <TableCell>
                <Skeleton width="80px" />
              </TableCell>
              <TableCell>
                <Skeleton width="100px" />
              </TableCell>
              <TableCell>
                <Skeleton width="100px" />
              </TableCell>
              <TableCell>
                <Skeleton width="100px" />
              </TableCell>
              <TableCell>
                <Skeleton width="80px" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skeletonRows.map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rect" width={60} height={30} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="space-around">
                    <Skeleton variant="circle" width={30} height={30} />
                    <Skeleton variant="circle" width={30} height={30} />
                    <Skeleton variant="circle" width={30} height={30} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default TeamSkeleton;
