import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Utils from "../../helpers/Utils";
import { icons } from "../leadSource/icon";

const Header = (props) => {
  const history = useHistory();

  useEffect(() => {
    document.title = `Lead Permission Requests | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }
  }, []);

  return (
    <div>
      <div className="tagBox lead_source_page">
        <div className="tag_list">
          <h5>Lead Permission Requests</h5>
        </div>
        <div className="add_new_user" style={{ display: "flex" }}>
          <button
            className="modal-trigger accent--bg--text--color"
            style={{
              padding: "15px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              props.refresh(Math.random() * 10);
            }}
          >
            <span>{icons.refresh}</span>
            <span style={{ padding: "10px" }}>Refresh</span>
          </button>
          {history.location.state !== undefined &&
            history.location.state.from === "settingPage" && (
              <Link
                to="/user/settings"
                className="modal-trigger accent--bg--text--color"
              >
                <span>{icons.back}</span>
                <span>Back</span>
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};

export default Header;
