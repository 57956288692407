import { Button } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { Editor } from "@tinymce/tinymce-react";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { updateProfileReducerSignatureData } from '../../actions/profileAction';
import { saveSignature } from "../../api/profileApi";
import { image_upload_handler } from "../../api/rootApi";
import Loader from "../globals/Loader";

const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

const SignatureApp = (props) => {

    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [showSignature, setShowSignature] = useState(false);
    const { user } = useSelector(state => state.profileReducer);

    useEffect(() => {
        if (user && (user.signature !== '')) {
            setContent(user.signature);

            if (user.show_signature === 1) {
                setShowSignature(user.show_signature);
            }
        }
    }, [user])

    const apiKeys = () => {
        return "f2zuqw07g06pnduwafiv7wehs2nc44j426n4nq0rjdhs8w1x";
    }

    const onSubmitHandle = (e) => {
        e.preventDefault();
        
        
        let formData;

        let content_text = content.includes("user_signature_content") ? content : `<div class="user_signature_content">${content}</div>`;

        if (showSignature) {

            if(content == null || content.trim().length === 0) {
                window.showNotification('ERROR','Email signature is required');
                return;
            }

            formData = {
                signatureBody: content_text,
                message: content_text,
                signatureShow: 1
            };
        } else {
            formData = {
                signatureBody: content_text,
                message: content_text,
            };
        }

        props.updateProfileReducerSignatureData({signature:content, show_signature:showSignature ? 1 : 0})
        setLoading(true);
        
        saveSignature({
            "form_data": formData
        }).then((response) => {
            if (response.data.status === 'success') {
                window.showNotification('SUCCESS',response.data.message);
                setTimeout(function(){
                    window.location.reload();
                }, 500);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        window.showNotification('ERROR',error);
                    });
                }
            } else {
                window.showNotification('ERROR',response.data.message);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <>
            {
                ( !user ) ? (
                    <div style={{paddingTop: 50}}>
                        <Loader/>
                    </div>
                ) : (
                    <div>
                        <p>Email Signature
                        {
                            showSignature && <span className="required__field">*</span>
                        }
                        </p>
                        <Editor
                            apiKey={apiKeys()}
                            // apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                            value={content}
                            cloudChannel='5-dev'
                            textareaName='email_reply'
                            init={{
                                height: '60vh',
                                browser_spellcheck: true,
                                menu: {
                                    favs: {
                                        title: 'My Favorites',
                                        items: 'code visualaid | searchreplace | emoticons'
                                    }
                                },
                                menubar: 'favs file edit view insert format tools table help',
                                plugins: [
                                    'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                    'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                    'insertdatetime media table contextmenu code help wordcount template'
                                ],
                                convert_urls: false,
                                toolbar:'insertfile undo redo | styleselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | link image | bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                imagetools_proxy: emailUploadFileApi,
                                images_upload_handler: image_upload_handler,
                                file_picker_types: 'file image media',
                                content_style: "p { margin: 0px; }"
                            }}
                            onEditorChange={(value) => setContent(value)}
                        />

                        <p>
                            <div className="d-flex align-items-center">
                                <p className="show_signature_txt">
                                    Show signature in email body? 
                                    <Tooltip title={'Turn it ON if you want to show email signature by default. You can also use email signature from personalize field.'}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 0C4.486 0 0 4.48606 0 10.0001C0 15.5141 4.486 20 10 20C15.514 20 20 15.5141 20 10.0001C20 4.48606 15.514 0 10 0ZM10 18.1818C5.48848 18.1818 1.81818 14.5115 1.81818 10.0001C1.81818 5.48861 5.48848 1.81818 10 1.81818C14.5115 1.81818 18.1818 5.48861 18.1818 10.0001C18.1818 14.5115 14.5115 18.1818 10 18.1818Z" fill="#133159" fill-opacity="0.5"></path>
                                            <path d="M10.0009 4.24219C9.3327 4.24219 8.78906 4.78619 8.78906 5.45485C8.78906 6.12292 9.3327 6.66643 10.0009 6.66643C10.6692 6.66643 11.2128 6.12292 11.2128 5.45485C11.2128 4.78619 10.6692 4.24219 10.0009 4.24219Z" fill="#133159" fill-opacity="0.5"></path>
                                            <path d="M9.99893 8.48438C9.49687 8.48438 9.08984 8.89141 9.08984 9.39347V14.848C9.08984 15.3501 9.49687 15.7571 9.99893 15.7571C10.501 15.7571 10.908 15.3501 10.908 14.848V9.39347C10.908 8.89141 10.501 8.48438 9.99893 8.48438Z" fill="#133159" fill-opacity="0.5"></path>
                                        </svg>
                                    </Tooltip>
                                </p>
                                <label className="switch">
                                    <small className="on">On</small>
                                    <input 
                                        type="checkbox" 
                                        checked={showSignature}
                                        onChange={(e) => {setShowSignature(e.target.checked)}}
                                    />
                                    <span className="slider round"></span>
                                    <small className="off">Off</small>
                                </label>
                            </div>
                        </p>
                        <div className="mt-3">
                            <Button className={"accent--bg--text--color"} variant="contained" color="primary" disabled={loading} onClick={onSubmitHandle}>
                                {loading ? 'Saving..' : 'Save Email Signature'}
                            </Button>
                        </div>
                    </div> 
                )
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    updateProfileReducerSignatureData: (params) => dispatch(updateProfileReducerSignatureData(params)),
})
let Signature = connect(null, mapDispatchToProps)(React.memo(SignatureApp))
export default Signature;