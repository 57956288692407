import React, {useEffect, useRef, useState} from "react";
import SimpleReactValidator from "simple-react-validator";
import {Box, Button} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DefaultEditor from "../common/Editor/DefaultEditor";
import PersonalizedTag from "../common/Personalized/PersonalizedTag";
import {insertPersonalizedTag} from "../common/utils/insertPersonalizedTag";
import {addEmailTemplateInvoice, getEmailTemplate,} from "../../../api/InvoiceApi";
import {PERSONALIZED_TAGS_FOR_EMAIL_SEND} from "../../../constants/personalizedTags";
import CoreTextField from "../common/TextField/CoreTextField";
import Styles from "./SendStyles.module.css";

const initialState = {
    subject: "",
    body: "",
};

const SendWithMail = () => {
    // const classes = InvoiceUseStyles();
    const [loading, setLoading] = useState(false);
    const [, setForceUpdate] = useState(false);
    const [formData, setFormData] = useState(initialState);

    const subjectRef = useRef(null);
    const bodyRef = useRef(null);

    const handleChangeInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const simpleValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: setForceUpdate},
            className: "small text-danger mdi mdi-alert pt-1 pl-1",
        })
    );

    useEffect(() => {
        getEmailTemplate().then((res) => {
            res = res.data;
            if (res.success) {
                const {id, subject, body} = res.data || {};
                if (id) setFormData({subject, body});
            } else {
                window.showNotification("ERROR", res.message);
            }
        });
    }, []);

    const handleSubmit = () => {
        if (simpleValidator.current.allValid()) {
            try {
                const requestBody = {
                    subject: formData.subject,
                    body: formData.body,
                };

                if (requestBody) {
                    addEmailTemplateInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                window.showNotification("SUCCESS", res.message);
                                // onRenderCallback();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification("ERROR", error.response.data.message);
                        })
                        .finally(() => setLoading(false));
                    window.showNotification(
                        "SUCCESS",
                        "Email template saved successfully"
                    );
                } else {
                    window.showNotification("ERROR", "res.message");
                }
            } catch (error) {
                console.error("An error occurred:", error.response.data.message);
            }
        } else {
            simpleValidator.current.showMessages();
            setForceUpdate(true);
        }
    };
    return (
        <Paper variant={"outlined"} className={Styles.SendTemplateBox}>
            <Typography variant={"h5"} className={Styles.topBar}>Email Template</Typography>

            <Box component="form" className={Styles.sendForm}>
                <Box className={Styles.subjectInput}>
                    <InputLabel required>Subject</InputLabel>
                    <Box className={Styles.inputAndPersonalized}>
                        <CoreTextField
                            fullWidth
                            required
                            variant={"outlined"}
                            type="text"
                            placeholder="Type email subject"
                            name="subject"
                            value={formData.subject}
                            inputRef={subjectRef}
                            onChange={(e) => handleChangeInput(e.target.name, e.target.value)}
                            OutlinedInputProps={{
                                maxLength: 200,
                            }}
                        />
                        <PersonalizedTag
                            onClick={(personalizedTag) =>
                                insertPersonalizedTag(
                                    personalizedTag,
                                    subjectRef,
                                    (value) => handleChangeInput("subject", value)
                                )
                            }
                        />
                    </Box>
                    <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                        "Email Subject",
                        formData.subject,
                        "required|max: 200"
                    )}</FormHelperText>
                </Box>
                <Box>
                    <Box className={Styles.emailBodyInput}>
                        <InputLabel required>Email Body</InputLabel>
                        <PersonalizedTag
                            onClick={(personalizedTag) =>
                                insertPersonalizedTag(personalizedTag, bodyRef, (value) =>
                                    handleChangeInput("body", value)
                                )
                            }
                            personalizeTags={PERSONALIZED_TAGS_FOR_EMAIL_SEND}
                        />
                    </Box>
                    <DefaultEditor
                        name={"body"}
                        value={formData.body}
                        onChange={(value) => handleChangeInput("body", value)}
                        forwardRef={bodyRef}
                        height={'200'}
                    />
                    <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                        "Email Body",
                        formData.body,
                        "required"
                    )}</FormHelperText>
                </Box>

                <Box className={Styles.saveButtonBox}>
                    <Button
                        color={"primary"}
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                        className={Styles.saveButton}

                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default SendWithMail;
