import { makeStyles } from "@material-ui/core/styles";

const ConnectedEamilEmptyPageUseStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "24px",
    height: "100%",
    width: "100%",
    gap: "5px",
  },
  titleText: {
    maxWidth: "500px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
  },
  footerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    marginTop: "20px",
    padding: "20px",
  },
  footerBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  footerText: {
    color: "#497ff3",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 600,
  },
  imageWrapper: {
    height: "224px",
    width: "310px",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },

  iconWrapper: {
    width: "auto",
    height: "60%",
    "& svg": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  button: {
    padding: "8px 15px",
    margin: "1.0933333333rem 0 .656rem 0",
    background: "#00ff91",
    borderRadius: "5px",
    fontFamily: "var(--poppins)",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: ".01em",
    color: "#000",
    border: "none",
    cursor: "pointer",
    "&:focus": {
      backgroundColor: "#00ff91 !important",
    },
  },
}));

export default ConnectedEamilEmptyPageUseStyles;
