import React, {useEffect, useState} from "react";
import {Box, Button} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import InvoiceDesign from "./InvoiceDesign";
import ImageShow from "../globals/ImageShow";
import ImageUploadInput from "../globals/ImageUploadInput";
import DefaultEditor from "./common/Editor/DefaultEditor";
import InvoicePageSubHeader from "./common/header/InvoicePageSubHeader";
import {CHOOSE_TEMPLATE_LIST, TEMPLATE_TYPE,} from "../../constants/invoiceConstant";
import {getCompanyInfoInvoice, saveCompanyInfoInvoice,} from "../../api/InvoiceApi";
import Styles from "./CompanyInfo.module.css";

export const CompanyInfo = () => {
    const [files, setFiles] = useState(null);
    const [filePreview, setFilePreview] = useState("");
    const [showPreview, setShowPreview] = useState(false);
    const [invoiceNote, setInvoiceNote] = useState("");
    const [termsAndCondition, setTermsAndCondition] = useState("");
    const [template, setTemplate] = useState(CHOOSE_TEMPLATE_LIST[TEMPLATE_TYPE.DEFAULT].value);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setLoading(true);
        getCompanyInfoInvoice()
            .then((res) => {
                res = res.data;
                if (res && res.success) {
                    res = res.data;
                    if (res) {
                        setInvoiceNote(res.invoiceNote);
                        setTermsAndCondition(res.termsAndCondition);
                        setTemplate(res.templateId);
                        setFilePreview(res.logoUrl);
                        if (res.logoUrl) {
                            setShowPreview(true);
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const saveCompanyInfoData = () => {
        let formData = new FormData();
        if (files) {
            formData.append("files", files[0]);
        }
        formData.append("invoiceNote", invoiceNote);
        formData.append("termsAndCondition", termsAndCondition);
        formData.append("template", template);

        setIsSubmitting(true);
        saveCompanyInfoInvoice(formData)
            .then((res) => {
                let data = res.data;
                if (data && data.success) {
                    window.showNotification("SUCCESS", data.message);
                } else {
                    window.showNotification("ERROR", data.message);
                }
            })
            .catch((error) => {
                console.error("API error:", error);
            }).finally(() => {
            setIsSubmitting(false);
        });
    };

    const handleTemplateDesign = (template) => {
        setTemplate(template.value);
    };

    return (
        <div id="header" className={Styles.company__info__container}>
            {loading ? (
                    <Box sx={{width: 250, display: "flex", flexDirection: "column", gap: "8px"}}>
                        <Skeleton variant="rect" height={100} style={{borderRadius: "4px"}}/>
                        <Skeleton variant="rect" height={48} style={{borderRadius: "4px"}}/>
                    </Box>
                ) :
                (filePreview && showPreview) ?
                    <div className={Styles.company__info__row}>
                        <div
                            className={`${Styles.company__info__row__right} ${Styles.company__info__row__right__img}`}
                            style={{position: "relative"}}
                        >
                            <ImageShow
                                imageUrl={filePreview}
                                imageAlt="Uploaded Image"
                                isServerImage={false}
                                extraClassName={"responsive-img"}
                            />
                            <div className={Styles.changeImageButton} onClick={() => {
                                setShowPreview(false)
                            }}>
                                Change Logo
                            </div>
                        </div>
                    </div>
                    :
                    <div className={Styles.company__info__row}>
                        <InvoicePageSubHeader
                            title={"Company Logo"}
                            description={"Upload your company logo here. This will be displayed at the top of your invoice."}
                        />
                        <div
                            className={Styles.company__info__row__right}
                            style={{maxWidth: "300px"}}
                        >
                            <p className={Styles.img__file__condition}>
                                Recommended image size 250 x 100px
                            </p>
                            <ImageUploadInput
                                callBack={(data) => {
                                    setFiles(data);
                                    setFilePreview(URL.createObjectURL(data[0]));
                                    setShowPreview(true);
                                }}
                                filesLimit={1}
                            />
                        </div>

                        {
                            (files || filePreview || showPreview) &&
                            <div style={{marginLeft: "105px", marginTop: "5px"}}>
                                <Button onClick={() => setShowPreview(true)} variant="contained" style={{
                                    backgroundColor: "#D22B2B",
                                    color: "white",
                                    fontSize: "16px"
                                }}>Cancel</Button>
                            </div>
                        }
                    </div>
            }

            <div className={Styles.invoice}>
                <InvoicePageSubHeader
                    title={"Invoice Design"}
                    description={"Save time by creating email and text templates. The messages you create here will be used when you send an invoice a to a client via email or text."}
                />
                <InvoiceDesign
                    onSelect={handleTemplateDesign}
                    selectedTemplate={template}
                />
            </div>

            <div className={Styles.footerOption}>
                <InvoicePageSubHeader
                    title={"Footer options (optional)"}
                    description={
                        "You can add a default note as well as terms and conditions to the footer of your invoice below."
                    }
                />

                <div className={Styles.editor}>
                    <div className={Styles.editorContent}>
                        <p>Customer Notes (optional)</p>
                        <div className="editor-container">
                            <DefaultEditor
                                name={"invoice_note"}
                                value={invoiceNote}
                                onChange={(value) => setInvoiceNote(value)}
                            />
                        </div>
                    </div>

                    <div className={Styles.editorContent}>
                        <p>Terms and Conditions (optional)</p>
                        <div className="editor-container">
                            <DefaultEditor
                                name={"invoice_terms_and_condition"}
                                value={termsAndCondition}
                                onChange={(value) => setTermsAndCondition(value)}
                            />

                        </div>
                    </div>
                </div>

                <div className={Styles.save_button}>
                    <Button
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={saveCompanyInfoData}
                        style={{backgroundColor: "#3C7EF3", color: "white", width: "115px", fontSize: "16px"}}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
};