import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getInsuranceCompanyApi,
  getInsuranceCompanyFoldersApi,
} from "../../../api/productsApi";
import useDebounce from "../../../hooks/useDebounce";
import Content from "./content";
import SideBar from "./sidebar/SideBar";

const InsuranceCompaniesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
}));
const InsuranceCompanies = () => {
  const [insuranceCompanyList, setInsuranceCompanyList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [isCompanyListLoading, setIsCompanyListLoading] = useState(false);
  const [isFolderListLoading, setIsFolderListLoading] = useState(false);
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  // get folder / category
  useEffect(() => {
    const getFolders = async () => {
      setIsFolderListLoading(true);
      try {
        const response = await getInsuranceCompanyFoldersApi();
        if (response?.success) {
          setFolderList(response?.data);
          setSelectedFolder(response?.data[0].id);
        } else {
          setFolderList([]);
        }
      } catch (error) {
      } finally {
        setIsFolderListLoading(false);
      }
    };
    getFolders();
  }, []);

  // get insurance company
  useEffect(() => {
    const getInsuranceCompany = async () => {
      setIsCompanyListLoading(true);
      const payload = {
        insurance_company_folder_id: selectedFolder,
        search: debouncedSearch || null,
      };

      try {
        const response = await getInsuranceCompanyApi(payload);

        if (response.success) {
          setInsuranceCompanyList(response.data);
        } else {
          setInsuranceCompanyList([]);
        }
      } catch (error) {
      } finally {
        setIsCompanyListLoading(false);
      }
    };
    if (selectedFolder) {
      getInsuranceCompany();
    }
  }, [selectedFolder, debouncedSearch]);

  const handleFolderList = (value) => {
    const { payload, type } = value;
    if (type === "update") {
      setFolderList((prevState) =>
        prevState.map((folder) => {
          if (folder.id === payload.id) {
            return payload;
          }
          return folder;
        })
      );
    } else {
      setFolderList((prevState) => [...prevState, payload]);
    }
  };

  return (
    <InsuranceCompaniesWrapper>
      <SideBar
        isLoading={isFolderListLoading}
        folderList={folderList}
        setFolderList={setFolderList}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        handleFolderList={handleFolderList}
      />
      <Content
        insuranceCompanyList={insuranceCompanyList}
        setInsuranceCompanyList={setInsuranceCompanyList}
        search={search}
        setSearch={setSearch}
        folderList={folderList}
        handleFolderList={handleFolderList}
        isLoading={!selectedFolder || isCompanyListLoading}
        selectedFolder={selectedFolder}
      />
    </InsuranceCompaniesWrapper>
  );
};

export default InsuranceCompanies;