import { all } from "redux-saga/effects";
import customFieldMiddleware from "./customFieldMiddleware";
import leadSourceMiddleware from "./leadSourceMiddleware";
import profileMiddleware from "./profileMiddleware";
import invoicePersonalizeTagMiddleware from "./invoicePersonalizeTagMiddleware";
import productMiddleware from "./productMiddleware";

export default function* rootMiddleware() {
    yield all(
        [
            profileMiddleware(),
            customFieldMiddleware(),
            leadSourceMiddleware(),
            invoicePersonalizeTagMiddleware(),
            productMiddleware()
        ]
    )
}
