import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import SimpleReactValidator from "simple-react-validator";
import { AppListStyles, BasicInput } from "../../AppListStyles";
import Styles from "./ApplistModal.module.css";
import Loader from "../../../globals/Loader";
import Utils from "../../../../helpers/Utils";
import NewConfirmAlert from "../../../activity/new-alert/NewConfirmAlert";
import { resourceData } from "../../../../constants/CoreConstants";
import { createNewApp } from "../../../../api/profileApi";

const useStyles = makeStyles({
  root: {
    marginTop: "8px",
    minWidth: "150px",
  },
  dividerPadding: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  label: {
    fontSize: "13px !important",
    color: "#797979",
  },
});

const CreateAppModal = ({ open, onClose, onRenderCallback }) => {
  const [, setForceUpdate] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [selectAll, setSelectAll] = useState(false);
  const [resourcePermissions, setResourcePermissions] = useState(resourceData);

  const handleStateChange = (event) => {
    const { name, checked } = event.target

    if (name === "selectAll") {
      setSelectAll(checked)
      setResourcePermissions((prevPermissions) => ({
        ...prevPermissions,
        resourcePermissions: prevPermissions.resourcePermissions.map(
          (resource) => ({
            ...resource,
            actions: resource.actions.map((action) => ({
              ...action,
              hasPermission: action.show ? checked : action.hasPermission,
            })),
          })
        ),
      }))
    } else {
      const [resourceName, actionName] = name.split("-")
      setResourcePermissions((prevPermissions) => {
        const updatedPermissions = prevPermissions.resourcePermissions.map(
          (resource) =>
            resource.resourceName === resourceName
              ? {
                  ...resource,
                  actions: resource.actions.map((action) =>
                    action.actionName === actionName && action.show
                      ? { ...action, hasPermission: checked }
                      : action
                  ),
                }
              : resource
        )

        const allChecked = updatedPermissions.every((resource) =>
          resource.actions.every(
            (action) => action.hasPermission || !action.show
          )
        )

        setSelectAll(allChecked)

        return {
          ...prevPermissions,
          resourcePermissions: updatedPermissions,
        }
      })
    }
  }


  const { modalFooter, flexCenterBetween } = AppListStyles();

  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: setForceUpdate },
      className: "small text-danger mdi mdi-alert pt-1 pl-1",
      validators: {
        url: {
          message: "The :attribute must be a valid URL.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(ftp|http|https):\/\/[^ "]+$/i
            );
          },
        },
      },
    })
  );

  const handleFormDataChange = (field, value) => {
    if (field === 'redirect_uris') {
      const redirectUrisArray = value.split(' ').filter(uri => uri.trim() !== '');
  
      setResourcePermissions({
        ...resourcePermissions,
        [field]: redirectUrisArray,
      });
  
      validator.current.message("redirectUrl", redirectUrisArray, "url");
    } else {
      setResourcePermissions({
        ...resourcePermissions,
        [field]: value,
      });
    }
  };
  

  const onCloseModal = () => {
    setResourcePermissions({
      name: "",
      redirect_uris: [],
      resourcePermissions: resourcePermissions.resourcePermissions,
    });
    validator.current.hideMessages();
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!submitting) {
      setSubmitting(true);
      validator.current.hideMessages();

      // Check if at least one checkbox is selected
      const isAnyCheckboxSelected =
        resourcePermissions.resourcePermissions.some((resource) =>
          resource.actions.some((action) => action.hasPermission === true)
        );

      if (validator.current.allValid() && isAnyCheckboxSelected) {
        setSubmitting(false);
        NewConfirmAlert({
          onSubmit: () => {
            createNewApp(resourcePermissions)
              .then((res) => {
                console.log(res);
                if (res.status === "success") {
                  setResourcePermissions(resourceData);
                  setSelectAll(false);
                  onClose();
                  onRenderCallback();
                  Utils.showNotification("App Saved Successfully", "success");
                } else {
                  Utils.showNotification("App Not created");
                }
              })
              .catch((error) => {
                console.error("API Error:", error);
                Utils.showNotification("App Not created", error);
              })
              .finally(() => {
                // setIsLoading(false);
              });
          },
          title: "Are You Sure?",
          description: "You can't change your data after submission?",
          cancelText: "Cancel",
          submitText: "Confirm",
          width: "480px",
        });
      } else {
        if (!isAnyCheckboxSelected) {
          // Display error message for checkboxes
          Utils.showNotification("Select at least one checkbox", "error");
        }

        validator.current.showMessages();
        setForceUpdate(true);
        setSubmitting(false);
      }
    }
  };

  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
    >
      <Paper className={Styles.modalInner}>
        {submitting && (
          <div className={Styles.loaderContainer}>
            <Loader />
          </div>
        )}
        <Box className={flexCenterBetween} p={2}>
          <Typography variant="h5">Create App</Typography>

          <IconButton size="small" color="secondary">
            <CloseIcon onClick={onCloseModal} />
          </IconButton>
        </Box>

        <Divider light />

        <div className={Styles.paddingWrp}>
          <Box>
            <FormControl className={Styles.formControl}>
              <span className={Styles.label}>
                App Name
                <span className={Styles.required}> *</span>
              </span>
              <BasicInput
                placeholder="App Name"
                name="appName"
                value={resourcePermissions?.name}
                onChange={(e) => {
                  handleFormDataChange("name", e.target.value);
                }}
              />
              {validator.current.message(
                "name",
                resourcePermissions?.name,
                "required"
              )}
            </FormControl>

            <FormControl className={Styles.formControl}>
              <span className={Styles.label}>
                Redirect URL
                <span className={Styles.required}> *</span>
              </span>
              <BasicInput
                placeholder="Redirect URL"
                name="redirectUrl"
                value={resourcePermissions.redirect_uris}
                onChange={(e) => {
                  handleFormDataChange("redirect_uris", e.target.value);
                }}
              />
              {validator.current.message(
                "redirect_uris",
                resourcePermissions.redirect_uris,
                "required|url"
              )}
            </FormControl>
            <Box
              sx={{
                paddingTop: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className={Styles.checkbox_header}>
                Please choose the app scope
              </Typography>
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    size="small"
                    checked={selectAll}
                    onChange={handleStateChange}
                    name="selectAll"
                    color="primary"
                  />
                }
                label="Select All"
              />
            </Box>
            {resourcePermissions.resourcePermissions.map((resourceData, i) => {
              return (
                <Box key={i}>
                  <Divider className={classes.dividerPadding} />
                  <Box sx={{ display: "flex", gap: "50px" }}>
                    <Typography
                      className={`${classes.root} ${Styles.modal_fontSize}`}
                    >
                      {resourceData.resourceName.replace("_", " ")}
                    </Typography>
                    <Grid container>
                      {resourceData.actions.map((data, i) => (
                        data.show ? (
                          <Grid item xs={4} key={i}>
                            <FormControlLabel
                              classes={{ label: classes.label }}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={data.hasPermission}
                                  onChange={handleStateChange}
                                  name={`${resourceData.resourceName}-${data.actionName}`}
                                  color="primary"
                                />
                              }
                              label={data.actionName}
                            />
                          </Grid>
                        ) : null
                      ))}
                    </Grid>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </div>

        <div className={modalFooter}>
          <Button
            variant="contained"
            className={Styles.submit}
            color="primary"
            size="small"
            onClick={handleSubmit}
          >
            Save
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={onCloseModal}
            size="small"
          >
            Cancel
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default CreateAppModal;
