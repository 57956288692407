import { useState } from 'react';
import {
  changeCustomFieldStatusApi, deleteCustomFieldApi,
  updateCustomFieldDataApi
} from '../../../../api/CustomFieldApi';
import SwitchC from "../../subComponents/Switch";
import { updateLocalStorage } from "../CustomFieldV2App";
import CustomConfirmAlert from "../helpers/confirm-alert/CustomConfirmAlert";
import { CloseIcon, DeleteIcons, EditIcons } from '../helpers/IconList';
import { handleStoreFields } from "../reducer/Actions";
import { useCFContext } from '../reducer/CustomProvider';
import Styles from './style.module.css';
const EditCustomField = ({
  title="Edit Custom Field",
  onClose=() => {}, selectedItem, index
}) => {
  const {state, dispatch} = useCFContext();
  const [status, setStatus] = useState(selectedItem.status);
  const [states, setStates] = useState({
    adding: false,
    savingValue: false,
    deleting: false,
    field_name: selectedItem.title,
    field_value: String(selectedItem.default_values),
    field_type: selectedItem.type_id
  });

  const handleFieldName = (e) => {
    setStates({...states, field_name: e.target.value})
  }
  const handleFieldValue = (e) => {
    setStates({...states, field_value: e.target.value})
  }
  const handleSubmit = (field_type) => {
    /* check validation */
    const payload = {
      "fieldId": selectedItem.id,
      "field_type": field_type
    };
    if(field_type === 'field_value'){
      if(states.field_value.trim() === ""){
        window.showNotification('warning', "Field value can not be empty !");
        return;
      }
      if(states.savingValue){
        return
      }
      setStates({...states, savingValue: true});
      payload.value = states.field_value
    }
    else if(field_type === 'field_title'){
      if(states.field_name.trim() === ""){
        window.showNotification('warning', "Field name can not be empty !");
        return;
      }
      if(states.adding){
        return
      }
      setStates({...states, adding: true});
      payload.value = states.field_name
    }
    try{
      updateCustomFieldDataApi(payload).then(res => {
        let response = res.data;
        if(response.status === 'success'){
          window.showNotification('success', response.html);
          let old_data = [...state.fields];
          old_data[index].title = states.field_name;
          old_data[index].default_values = states.field_value;
          dispatch(handleStoreFields(old_data))
          updateLocalStorage()
        }else{
          window.showNotification('error', response.html);
        }
        setStates({...states, adding: false, savingValue: false});
      }).catch(error => {
        setStates({...states, adding: false, savingValue: false});
      })
    }catch(error){setStates({...states, adding: false, savingValue: false});}
  }
  const handleStatus = () => {
    CustomConfirmAlert({
      onSubmit : () => {
        changeCustomFieldStatusApi({fieldId : selectedItem.id, status: !status}).then(res => {
          let response = res.data
          if (response.status === 'success') {
            window.showNotification('success', response.html);
            setStatus(!status)
            let old_data = [...state.fields];
            old_data[index].status = status ? 0 : 1;
            dispatch(handleStoreFields(old_data))
            updateLocalStorage()
          } else {
            setStatus(status)
          }
        })
      },
      title: 'Are you sure to ' + (status ?  'disable' : 'enable') + '?',
      description: 'Updated & sync with account information.',
      cancelText: 'No, Keep it',
      submitText: 'Yes, Change it'
    })
  }
  const handleDelete = () => {
    CustomConfirmAlert({
      onSubmit : () => {
        deleteCustomFieldApi({fieldId : selectedItem.id}).then(res => {
          let response = res.data;
          if (response.status === 'success') {
            window.showNotification('success', response.html);
            let old_data = [...state.fields];
            old_data.splice(index, 1);
            dispatch(handleStoreFields(old_data))
            updateLocalStorage()
            /* check list length of old_data, and delete group if length = 0, selected = null if needed */
            /* 
            dispatch(handleStoreFolders(updatedFolders));
            dispatch(handleSelectFolder(null));
            */
            onClose();            
          }
        })
      },
      title: 'Are you sure to delete ?',
      description: 'Updated & sync with account information.'
    })
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.title}>{title}</span>
        <span className={Styles.closeButton} onClick={onClose}><CloseIcon /></span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`} >
        <div className={`${Styles.inputWrp} ${Styles.editFieldWrapper}`}>
          <span className={Styles.lebelText}>Field Name <code style={{color:'red'}}>*</code></span>
          <div className={Styles.inputFieldWrp}>
            <input onChange={handleFieldName} value={states.field_name} className={Styles.inputField} placeholder="Enter field name" />
          </div>
          <div className={Styles.buttonWrp}>
            <div className={Styles.button} onClick={()=>handleSubmit('field_title')}>
              <span className={Styles.submitIcon}><EditIcons height='18px' width='18px' color="#fff"/> </span>
              <span className={Styles.submitText}>{states.adding ? 'Saving Field Name...' :'Save Field Name'}</span>
            </div>
          </div>
        </div>

        {/* for value: check box, radio button, drop down */}
        {(states.field_type === 5 || states.field_type === 6 || states.field_type === 7) &&
        <div className={`${Styles.inputWrp} ${Styles.editFieldWrapper}`}>
          <span className={Styles.lebelText}>Set value <code style={{color:'red'}}>*</code></span>
          <div className={Styles.inputFieldWrp}>
            <input onChange={handleFieldValue} value={states.field_value} className={Styles.inputField} placeholder="Enter field value" />
            <span className={Styles.hintText}>Multiple values separated by , (comma) example: value1 , value2 , value3</span>
          </div>
          <div className={Styles.buttonWrp}>
            <div className={Styles.button} onClick={()=>handleSubmit('field_value')}>
              <span className={Styles.submitIcon}><EditIcons  height='18px' width='18px' color="#fff"/> </span>
              <span className={Styles.submitText}>{states.savingValue ? 'Saving Field Value...' :'Save Field Value'}</span>
            </div>
          </div>
        </div>
        }

        <div className={`${Styles.inputWrp} ${Styles.editFieldWrapper}`}>
          <span className={Styles.lebelText}>Actions</span>
          <div className={Styles.actionWarpper}>
            <div className={Styles.eachAction}>
              <div className={Styles.eachActionContent}>
                <span>Status change:</span>
                <small>If you changed this field status to <code>OFF</code>, this field will not shown in personalized list.</small>
              </div>
              <SwitchC status={status} brandColor={"#f94f72"} onChange={handleStatus}/>
            </div>

            <div className={Styles.eachAction}>
              <div className={Styles.eachActionContent}>
                <span>Remove Field:</span>
                <small>You can not undo this change again. Also, custom field data will not replaced if you already used it.</small>
              </div>
              <div className={Styles.buttonDelete} onClick={()=>{handleDelete()}}>
                <span className={Styles.submitIcon}><DeleteIcons fill={"#fff"} width={"20px"} height={"20px"}/> </span>
                <span className={Styles.submitText}>{states.deleting ? 'Deleting...' :'Delete'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default EditCustomField;