import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { CustomScrollbar } from "../../products/productsStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  handleUserMergeFieldPageChange,
  handleUserMergeFieldRowChange,
  handleUserMergeFieldSearch,
  getUserMergeField,
  getMergeFieldPreviewData
} from "../../../actions/CustomFieldInsuranceAction.js";
import useDebounce from "../../../hooks/useDebounce";
import BasicInput from "../../custom/BasicInput";
import GlobalEmptyPage from "../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import EachContantItem from "./EachContantItem";
import CustomDrawerPype from "../../common/drawer/CustomDrawerPype";
import { EMPTY_DATA_IMG } from "../../../constants/CoreConstants";
import SkeletonComponent from "./ContantSkeleton";
import { IconList } from "../../../constants/IconList";
import AddOrEditMergeField from "./modal/add-field/AddOrEditMergeField";
import { customFieldInsuranceState } from "../../products/common/productSelector";
import PreviewMergeFieldData from "./PreviewMergeFieldData.jsx";
import ContentStyles from "./ContentStyles.js";


const Content = () => {
  const { gettingUserMergeFields, userMergeFieldList, userMergeFieldParams,totalMergeField, gettingUserMergeFieldPreviewData } =
  useSelector(customFieldInsuranceState);
  const [isDrawerOpen, setIsDrawerOpen] = useState();
  const classes = ContentStyles();
  const [search, setSearch] = useState(null);
  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(search, 1000);
  useEffect(()=>{
    dispatch(
      getMergeFieldPreviewData({
        for_drop_down: false,
        get_custom_data: true,
        get_personalized_data: true,
      })
    );
  },[]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(getUserMergeField({
      ...userMergeFieldParams, 
      page: userMergeFieldParams.page + 1}));
  }, [userMergeFieldParams]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(debouncedSearch !== null){
      dispatch(
        handleUserMergeFieldSearch(debouncedSearch)
      );
    }
  },[debouncedSearch]);  // eslint-disable-line react-hooks/exhaustive-deps


  const handleSearchInput = (e) => {
    setSearch(e.target.value?.trim());
  };

  const handleChangePage = (event, newPage) => {
    dispatch(handleUserMergeFieldPageChange(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(handleUserMergeFieldRowChange(event.target.value));
  };

  return (
    <div className={classes.root}>
      <div className="headWrapper">
        <div class="record_search">
          <BasicInput
            placeholder="Search By Field label..."
            onChange={(e) => handleSearchInput(e)}
          />
          <div class="search_icon">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#133159"
                fill-opacity="0.65"
              ></path>
            </svg>
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          className="newFieldButton"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() => setIsDrawerOpen(true)}
        >
          New Merge Field
        </Button>
      </div>
      {gettingUserMergeFields || gettingUserMergeFieldPreviewData ? (
        <SkeletonComponent/>
      ) : (Array.isArray(userMergeFieldList) && userMergeFieldList?.length > 0)  ? (
      <div style={{ display: "flex", gap: "20px", width: "100%" }}>
        <div style={{ width: "80%" }}>
          <CustomScrollbar style={{ height: "calc(100vh - 410px)" }}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell style={{display:'flex', alignItems:'center', gap:'6px'}} className={classes.tableHeader}>
                      <span>Field Label</span>
                      <span style={{marginTop:'3px'}}>{IconList.info}</span>
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Merged Value{" "}
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Merged Tag
                    </TableCell>
                    <TableCell className={classes.tableHeader}>Type</TableCell>
                    <TableCell style={{width:'80px'}} className={classes.tableHeader}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userMergeFieldList.map((field, index) => {
                    return <EachContantItem field={field} key={index} />;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalMergeField}
            rowsPerPage={userMergeFieldParams.limit}
            page={userMergeFieldParams.page}
             onPageChange={handleChangePage}
             onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <div>
          <CustomScrollbar style={{ width: "250px", height: "calc(100vh - 410px)" }}>
              <PreviewMergeFieldData />
          </CustomScrollbar>
        </div>
      </div>
       ) : ( 
       <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "500px",
          }}
        >
          <GlobalEmptyPage 
          image={EMPTY_DATA_IMG} 
          title="No Fields Found" />
        </div> 
       )} 

      {isDrawerOpen && (
        <CustomDrawerPype
          isShow={isDrawerOpen}
          hanldeDrawer={(status) => {}}
          renderChild={() => {
            return <AddOrEditMergeField onClose={() => setIsDrawerOpen(false)} />;
          }}
        />
      )}
    </div>
  );
};

export default Content;
