import { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { connect } from "react-redux";
import { createOrUpdateLeadGroup, fetchUsersList } from "../../actions/profileAction";
import SimpleReactValidator from 'simple-react-validator';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from "@material-ui/core/Select";
import Radio from '@material-ui/core/Radio';
import Utils from "../../helpers/Utils";
import { getTeamUsersWithoutTemplate, updateOrCreateLeadGroup } from "../../api/profileApi";
import GlobalModal from "../globals/Modal/GlobalModal";
import { AddCircle, Save } from "@material-ui/icons"
import SkeletonLoader from "./SkeletonLoader";
import BasicInput from "../custom/BasicInput";

const styles = makeStyles((theme) => ({
    dropdownStyle: {
         padding: 10,
        borderRadius: "5%",
    },
}));

const LeadGroupModal = (props) => {

    const classes = styles();
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [teamUsers, setTeamUsers] = useState([]);
    const [defaultTeamUsers, setDefaultTeamUsers] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [groupType, setGroupType] = useState(1);
    const [notClaimWithIn, setNotClaimWithIn] = useState(30);
    const [assignTo, setAssignTo] = useState(0);

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    useEffect(() => {
        // loadData();
        loadUserList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (props.data) {
            setGroupName(props.data.name);
            setGroupType(parseInt(props.data.method));
            setNotClaimWithIn(props.data.time_to_auto_assign);
            setAssignTo(props.data.assigned_to);
            let userArray = [];
            props.data.group_users.forEach(element => {
                userArray.push(element.id)
            });
            setSelectedUserIds(userArray);
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    const SaveAndToggle = () => {
        props.SaveAndHideModal();
    }

    // const loadData = () => {
    //     // props.fetchUsersList({}, fetchUsersListResponse);
    // }

    const loadUserList = () =>{
        getTeamUsersWithoutTemplate({
            "form_data": {}
        })
            .then(response => {
                setTeamUsers(response.data.data);
                setDefaultTeamUsers(response.data.data);

            }).finally(() => {
            setComponentLoading(false);
        });
    };

    // const fetchUsersListResponse = (response) => {
    //     setTeamUsers(response.data);
    //     setDefaultTeamUsers(response.data);
    //     setComponentLoading(false);
    // }

    const handelCheckBox = (_userId) => {
        let usersArray = [...selectedUserIds];

        if (usersArray.includes(_userId)) {
            usersArray = usersArray.filter(function(item) {
                return item !== _userId
            });
        } else {
            usersArray.push(_userId);
        }

        setSelectedUserIds(usersArray);
    }

    const handleGroupTypeChange = (event) => {
        setGroupType(parseInt(event.target.value));
    };

    const onSubmitHandle = (e) =>{
        e.preventDefault();
        
        if (simpleValidator.current.allValid()) {
            let formData;

            if (props.data) {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                    group_id: props.data.id 
                }
            } else {
                formData = {
                    groupName: groupName,
                    assigned_to: assignTo,
                    methodType: groupType,
                    time_to_auto_assign: notClaimWithIn,
                    selectedUsers: selectedUserIds,
                }
            }

            setLoading(true);

            updateOrCreateLeadGroup({
                "form_data": formData
            }).then(response => {
                if (response.data.status === 'success' || response.data.success === true) {
                    SaveAndToggle();
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const searchUser = (keyword) => {
        keyword = keyword.toUpperCase().trim();
        if (keyword !== '') {
            // eslint-disable-next-line array-callback-return
            const userData = teamUsers.filter((data)=> {
                if (data.full_name.toUpperCase().indexOf(keyword) > -1) {
                    return data;
                }
            });

            setTeamUsers(userData);
        } else {
            setTeamUsers(defaultTeamUsers);
        }
    }

    return (
        <GlobalModal
            open={true}
            title={props.title}
            onClose={toggle}
            onSubmit={onSubmitHandle}
            buttonText={!loading ? (props.data ? 'Update Group' : 'Create New Group') : 'Please Wait...'}
            buttonIcon={props.data ? <Save/> : <AddCircle/>}
        >
            {
                componentLoading ? (
                    <div style={{paddingTop: 50}}>
                        <SkeletonLoader/>                    
                        </div>
                ) : (
                    <div class="modal__content__bottom">
                        <div class="modal__input__box">
                            <label for="" class="active">Group Name</label>
                            <BasicInput
                            fullWidth type="text" placeholder="Enter Group Name" value={groupName} onChange={e => setGroupName(e.target.value)}
                            />
                            {simpleValidator.current.message('group name', groupName, 'required')}
                        </div>
                        <div class="modal__input__box modal__search__box">
                            <BasicInput
                            fullWidth type="text" placeholder="Type and Search User" onChange={e => searchUser(e.target.value)}/>
                            <span class="serach_icon">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z" fill="#3C7EF3"></path>
                                </svg>
                            </span>
                            <div class="modal__search__preview awesome__scroll_bar">
                                {simpleValidator.current.message('user', selectedUserIds, 'required')}
                                {
                                    teamUsers.map((item, index) => (
                                        <label>
                                            <input type="checkbox" class="filled-in" checked={selectedUserIds.includes(parseInt(item.id))} onChange={() => handelCheckBox(item.id)}/>
                                            <span class="checkbox__text_1">{item.full_name}</span>
                                        </label>
                                    ))
                                }

                            </div>
                        </div>
                        <div class="modal__input__box d-flex justify-content-between">
                            <RadioGroup aria-label="gender" className="w-100" name="gender1" value={groupType} onChange={handleGroupTypeChange}>
                                <div className="modal__checkbox mr-0">
                                    <label>
                                        <FormControlLabel value={1} control={<Radio />} label="Round Robin" checked={(groupType === 1)? true : null}/>
                                        <span className="checkbox__text__holder">
                                        <span className="checkbox__text_1">Round Robin</span>
                                        <span className="checkbox__text_2">Evenly distributes leads to agents in sequential order</span>
                                    </span>
                                    </label>
                                </div>
                                <div className="modal__checkbox mr-0">
                                    <label>
                                        <FormControlLabel value={2} control={<Radio />} label="First To Claim" checked={(groupType === 2)? true : null} />
                                        <span className="checkbox__text__holder">
                                        <span className="checkbox__text_1">First To Claim</span>
                                        <span className="checkbox__text_2">Multiple agents contacted, first to claim lead wins</span>
                                    </span>
                                    </label>
                                </div>

                            </RadioGroup>
                        </div>
                        { 
                            groupType === 2 && (
                                <div className="modal__input__box d-flex justify-content-between lead_group_select_fields">
                                    <div className="row">
                                        <div className="col s12 pl-0">
                                            <div className="modal__select d-flex align-items-center">
                                                <p className="modal__select__text col s6">If not claimed after</p>
                                                <div className="input-field col s6 add_grp_select">
                                                    <input type="number" value={notClaimWithIn} onChange={(e) => setNotClaimWithIn(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col s12 pl-0">
                                            <div className="modal__select d-flex align-items-center">
                                                <p className="modal__select__text col s6">Assign to</p>
                                                <div className="input-field col s6 add_grp_select">
                                                    <Select className={"check-class"}
                                                            value={assignTo}
                                                            onChange={(e)=>setAssignTo(e.target.value)}
                                                            MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                                                    >
                                                        <MenuItem value="0">Select User</MenuItem>
                                                        {

                                                            teamUsers.map((item, index) => (
                                                                <MenuItem key={index} value={item.id} >{item.full_name}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    {simpleValidator.current.message('', assignTo, 'required')}
                                                    <span className="select_icon">
                                                <svg width="14" height="7" viewBox="0 0 14 7" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 1L7 6L13 1" stroke="white"
                                                            stroke-width="1.5" stroke-linecap="round"
                                                            stroke-linejoin="round"/>
                                                </svg>
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

        </GlobalModal>
    );
}


const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchUsersList: (params, callback) => dispatch(fetchUsersList(params, callback)),
        createOrUpdateLeadGroup: (params, callback) => dispatch(createOrUpdateLeadGroup(params, callback))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeadGroupModal);