import { Box, Button, TableBody, TableCell, Tooltip } from "@material-ui/core";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { useState } from "react";
import {
  AppListStyles,
  AppListTableCell,
  AppListTableRow,
} from "../AppListStyles";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import { deleteApp } from "../../../api/profileApi";
import Utils from "../../../helpers/Utils";
import { CheckCircleIcon } from "../../ten-dlc/helpers/Icon";

const AppListTableBody = ({ appList, removeId }) => {
  const [copiedClientId, setCopiedClientId] = useState(null);
  const [copiedClientSecret, setCopiedClientSecret] = useState(null);
  const { alignJustifyCenter, closeButton, flexCenterBetween } =
    AppListStyles();

    const handleCopy = (text, type) => {
      navigator.clipboard.writeText(text);
      Utils.showNotification("Copy to clipboard", "success");
      if (type === "clientId") {
        setCopiedClientId(text);
        setTimeout(() => {
          setCopiedClientId(null);
        }, 1500); 
      } else if (type === "clientSecret") {
        setCopiedClientSecret(text);
        setTimeout(() => {
          setCopiedClientSecret(null);
        }, 1500); 
      }
    };

  const handleDelete = (appId) => {
    NewConfirmAlert({
      onSubmit: () => {
        deleteApp(appId, {})
          .then((res) => {
            if (res.status === "success") {
              Utils.showNotification("App Deleted", "success");
              removeId(appId);
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
          })
          .finally(() => {});
      },
      title: "Are You Sure?",
      description: "You Want to delete this App?",
      cancelText: "Cancel",
      submitText: "Confirm",
      width: "480px",
    });
  };
  return (
    <TableBody>
      {appList.map((app) => (
        <AppListTableRow key={app?.authorizationApp.id}>
          <TableCell>{app?.authorizationApp.appName}</TableCell>
          <AppListTableCell>
          <Box className={flexCenterBetween}>
              {app?.authorizationApp.clientId}
              <Tooltip title="Copy">
                 <Button
                onClick={() => handleCopy(app?.authorizationApp.clientId, "clientId")}
              >
                {copiedClientId === app?.authorizationApp.clientId ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <FileCopyIcon />
                )}
              </Button>
              </Tooltip>
             
            </Box>
          </AppListTableCell>
          <AppListTableCell>
          <Box className={flexCenterBetween}>
              {app?.authorizationApp.clientSecreet}
              <Tooltip title="Copy">
                  <Button
                onClick={() => handleCopy(app?.authorizationApp.clientSecreet, "clientSecret")}
              >
                {copiedClientSecret === app?.authorizationApp.clientSecreet ? (
                  <CheckCircleIcon style={{ color: "green" }} />
                ) : (
                  <FileCopyIcon />
                )}
              </Button>
              </Tooltip>
            
            </Box>
          </AppListTableCell>
          <AppListTableCell>
            {app?.authorizationApp.redirectUrl}
          </AppListTableCell>

          <AppListTableCell>
            <Box className={alignJustifyCenter}>
              <Button
                className={closeButton}
                variant="outlined"
                disableElevation
                color="primary"
                size="small"
                onClick={() => handleDelete(app?.authorizationApp.id)}
              >
                Delete
              </Button>
            </Box>
          </AppListTableCell>
        </AppListTableRow>
      ))}
    </TableBody>
  );
};

export default AppListTableBody;
