export const CloseIcon = ({width="19", height="19", fill="#546376"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.75 5.3075L13.6925 4.25L9.5 8.4425L5.3075 4.25L4.25 5.3075L8.4425 9.5L4.25 13.6925L5.3075 14.75L9.5 10.5575L13.6925 14.75L14.75 13.6925L10.5575 9.5L14.75 5.3075Z" fill={fill}/>
        </svg>
    );
}
export const CheckCircleIcon = ({width="20", height="20"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55 19.91a1.72 1.72 0 0 1-1.19-.49l-6.2-6.21a.74.74 0 0 1 0-1.06l2.41-2.42a1.73 1.73 0 0 1 2.43 0l2.78 2.79a.18.18 0 0 0 .25 0l7.35-7.35a1.72 1.72 0 0 1 2.38 0l1.45 1.45a1.68 1.68 0 0 1 0 2.37L10.74 19.42a1.72 1.72 0 0 1-1.19.49Zm-5.8-7.23 5.67 5.67a.18.18 0 0 0 .25 0L20.1 7.93a.18.18 0 0 0 0-.25l-1.45-1.45a.19.19 0 0 0-.26 0L11 13.58a1.72 1.72 0 0 1-2.37 0l-2.74-2.79a.16.16 0 0 0-.26 0l-1.88 1.89Z" fill={"rgb(52, 111, 239)"}/>
        </svg>
    );
}

export const EmailCheckedIcon = ({
    height = 20,
    width = 20,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00065 12.6665C8.00065 10.0865 10.0873 7.99984 12.6673 7.99984C13.3873 7.99984 14.0607 8.1665 14.6673 8.45317V3.99984C14.6673 3.2665 14.0673 2.6665 13.334 2.6665H2.66732C1.93398 2.6665 1.33398 3.2665 1.33398 3.99984V11.9998C1.33398 12.7332 1.93398 13.3332 2.66732 13.3332H8.05398C8.02065 13.1132 8.00065 12.8932 8.00065 12.6665ZM2.66732 3.99984L8.00065 7.33317L13.334 3.99984V5.33317L8.00065 8.6665L2.66732 5.33317V3.99984ZM11.5607 14.6665L9.20065 12.3065L10.1407 11.3665L11.554 12.7798L14.3807 9.95317L15.334 10.8932L11.5607 14.6665Z" fill={"rgb(52, 111, 239)"} />
        </svg>
    );
};

export const EmailMarkUnreadIcon = ({
    height = 20,
    width = 20,
    color = '#3c7ef3'
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.554 4.66683H10.8207L7.00065 2.66683L2.66732 4.9335V11.3335C1.93398 11.3335 1.33398 10.7335 1.33398 10.0002V4.78016C1.33398 4.42683 1.54732 4.0535 1.86732 3.88683L7.00065 1.3335L12.0273 3.88683C12.314 4.04016 12.514 4.3535 12.554 4.66683ZM13.334 5.3335H4.66732C3.93398 5.3335 3.33398 5.9335 3.33398 6.66683V12.6668C3.33398 13.4002 3.93398 14.0002 4.66732 14.0002H13.334C14.0673 14.0002 14.6673 13.4002 14.6673 12.6668V6.66683C14.6673 5.9335 14.0673 5.3335 13.334 5.3335ZM13.334 7.78016L9.00065 10.0002L4.66732 7.78016V6.66683L9.00065 8.88683L13.334 6.66683V7.78016Z" fill={color}/>
        </svg>
    );
};

export const ContactMailIcon = ({
    height = 24,
    width = 24,
    opacity = .5,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3265_21935)">
                <path d="M13.625 5.5V4.875L11.75 6.125L9.875 4.875V5.5L11.75 6.75L13.625 5.5ZM14.25 2.375H1.75C1.0625 2.375 0.5 2.9375 0.5 3.625V12.375C0.5 13.0625 1.0625 13.625 1.75 13.625H14.25C14.9375 13.625 15.4938 13.0625 15.4938 12.375L15.5 3.625C15.5 2.9375 14.9375 2.375 14.25 2.375ZM5.5 4.25C6.5375 4.25 7.375 5.0875 7.375 6.125C7.375 7.1625 6.5375 8 5.5 8C4.4625 8 3.625 7.1625 3.625 6.125C3.625 5.0875 4.4625 4.25 5.5 4.25ZM9.25 11.75H1.75V11.125C1.75 9.875 4.25 9.1875 5.5 9.1875C6.75 9.1875 9.25 9.875 9.25 11.125V11.75ZM14.25 8H9.25V4.25H14.25V8Z" fill={'rgb(236, 19, 31)'} fill-opacity={opacity} />
            </g>
            <defs>
                <clipPath id="clip0_3265_21935">
                    <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export const UnsubscribeEmailIcon = ({
    height = 24,
    width = 24,
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 13.001C16.57 13.001 15 14.571 15 16.501C15 18.431 16.57 20.001 18.5 20.001C20.43 20.001 22 18.431 22 16.501C22 14.571 20.43 13.001 18.5 13.001ZM20.5 17.001H16.5V16.001H20.5V17.001ZM13.55 17.001C13.53 16.831 13.5 16.671 13.5 16.501C13.5 13.741 15.74 11.501 18.5 11.501C19.42 11.501 20.26 11.761 21 12.191V5.00098C21 3.90098 20.1 3.00098 19 3.00098H5C3.9 3.00098 3 3.90098 3 5.00098V15.001C3 16.101 3.9 17.001 5 17.001H13.55ZM12 10.501L5 7.00098V5.00098L12 8.50098L19 5.00098V7.00098L12 10.501Z" fill={'rgb(236, 19, 31)'} fill-opacity={opacity} />
        </svg>
    );
};

export const EmailFailedIcon = ({
    width = 24,
    height = 24,
    opacity = .5
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.102 10.377C10.2007 10.477 10.3344 10.5335 10.4741 10.5335H13.5264C13.666 10.5335 13.7998 10.477 13.8985 10.377L16.0567 8.19C16.1554 8.09 16.2111 7.9545 16.2111 7.813V4.72C16.2111 4.5785 16.1554 4.443 16.0567 4.343L13.8985 2.1565C13.7998 2.0565 13.666 2 13.5264 2H10.4741C10.3344 2 10.2007 2.0565 10.102 2.1565L7.9438 4.3435C7.84511 4.4435 7.78936 4.579 7.78936 4.7205V7.8135C7.78936 7.955 7.84511 8.0905 7.9438 8.1905L10.102 10.3775V10.377ZM11.4738 4.1335C11.4738 3.447 12.5262 3.4455 12.5262 4.1335V6.5335C12.5262 7.22 11.4738 7.2215 11.4738 6.5335V4.1335ZM11.4738 8.1335C11.4738 7.447 12.5262 7.4455 12.5262 8.1335V8.4C12.5262 9.0865 11.4738 9.088 11.4738 8.4V8.1335ZM15.3842 15.952L22 21.208V8.4C22 8.1065 21.7632 7.8665 21.4735 7.8665H17.2631V8.255C17.2631 8.3965 17.2074 8.532 17.1087 8.632L14.3341 11.4435C14.2355 11.5435 14.1017 11.6 13.9621 11.6H10.0379C9.89826 11.6 9.76454 11.5435 9.66585 11.4435L6.89132 8.632C6.79264 8.532 6.73688 8.3965 6.73688 8.255V7.8665H2.52648C2.23684 7.8665 2 8.1065 2 8.4V21.208L8.61584 15.952C9.15022 15.529 9.80105 16.3685 9.26569 16.792L2.70807 22H21.2924L14.7343 16.792C14.5054 16.6105 14.4659 16.2745 14.645 16.0425C14.8241 15.813 15.1557 15.7705 15.3847 15.952H15.3842ZM14.3687 15.2455C13.0202 16.418 10.9803 16.418 9.63181 15.2455L3.41564 9.872C3.19458 9.68 3.1709 9.344 3.35791 9.12C3.54738 8.896 3.87896 8.8695 4.10002 9.0615L10.3132 14.435C11.2897 15.2805 12.7108 15.2805 13.6868 14.435L19.8975 9.0615C20.121 8.8695 20.4526 8.896 20.6396 9.12C20.8291 9.344 20.803 9.68 20.5819 9.872L14.3687 15.2455Z" fill={'rgb(236, 19, 31)'} fill-opacity={opacity} />
        </svg>
    );
};

export const BackIcon = ({
    width = 24,
    height = 24,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z" fill="#fff"></path>
        </svg>
    );
};

export const MagnifyGlassIcon = ({
    width = 16,
    height = 16,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10./0629 5.94625 10.0629Z" fill="#133159" fillOpacity="0.65"></path>
        </svg>
    );
};
