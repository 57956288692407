import { combineReducers } from 'redux'
import profileReducer from "./profileReducer";
import leadSourceReducer from "./leadSourceReducer";
import invoicePersonalizeTagReducer from "./invoicePersonalizeTagReducer";
import productReducer from './productReducer';

export default combineReducers({
    profileReducer,
    leadSourceReducer,
    invoicePersonalizeTagReducer,
    productReducer
})