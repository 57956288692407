import { Button, styled, Typography } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import React, { Fragment, useState } from "react";
import Utils from "../../../../helpers/Utils";
import { FREQUENCY } from "../../helper/coreConstant";

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px !important",
  fontSize: "14px !important",
  textTransform: "capitalize !important",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "#346fef !important",
  },
}));

const MonthlyGoalStatusWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: theme.spacing(1),

  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "& .TabsWrapper": {
      backgroundColor: "#f8f8f8",
      display: "flex",
      borderRadius: 20,
      marginLeft: "auto",
    },
    "& .dateSmall": {
      fontSize: "12px"
    },
    "& .dateLabel": {
      margin: 0,
      padding: 0
    }
  },
  "& .trendingStats": {
    padding: 20,
    backgroundColor: "white",
    border: "1px solid #E9EAEC",
    borderRadius: 12,
    boxShadow: "0px 1px 2px 0px #14151A0D",
    textAlign: "center",
    "& h5": {
      marginBottom: "2px",
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "32px",
      color: "#14151A",
    }
  },
  "& .monthlyStats": {
    backgroundColor: "#F7F7F8",
    padding: 20,
    borderRadius: 12,
    marginTop: 10,
    borderTop: "2px solid #9BBDFF",

    display: "flex",
    justifyContent: "space-between",

    "& .leftSide": {
      "& .value": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        "& .icon": {
          backgroundColor: "white",
          borderRadius: 20,
          padding: 2,

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          '& svg': {
            fontSize: 20,

          }
        },
      },
    },

    "& .rightSide": {
      textAlign: "right",
    }
  },
  "& .currentStats": {
    backgroundColor: "#F7F7F8",
    borderTop: "2px solid #FFA488",
    padding: 19,
    borderRadius: 12,
    marginTop: 10,
    "& .textWrapper": {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 6,
      "& .rightSide": {
        textAlign: "right",
      }
    }
  },
  "& p.MuiTypography-root": {
    height: "unset"
  }
}));

const toZero = (val) => {
  try {
    if (val !== undefined && val !== null && typeof val === "number") {
      return val;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};

const numberArt = (val, nullReplacer = "", suffix = "", abs = false) => {
  if (val === null && nullReplacer) {
    return nullReplacer;
  }

  try {
    if (val !== undefined && typeof val === "number") {
      if (abs) {
        return Math.abs(val);
      }

      if (suffix) {
        return val + suffix;
      }

      return val;
    }

    return 0;
  } catch (err) {
    return 0;
  }
};

const MonthlyGoalStatus = ({ data, row }) => {
  const [activeTab, setActiveTab] = useState(1);

  const formatCurrency = (value) => {
    return "$" + Utils.abbreviateNumber(value) || "0.00";
  };

  const frequencyLabel = () => {
    switch (row.frequency) {
      case FREQUENCY.WEEKLY:
        return "WEEK";
      case FREQUENCY.MONTHLY:
        return "MONTH";
      case FREQUENCY.QUARTERLY:
        return "QUARTER";
      case FREQUENCY.YEARLY:
        return "YEAR";
      default:
        return "N/A";
    }
  };

  const statsLabel = () => {
    switch (row.frequency) {
      case FREQUENCY.WEEKLY:
        return <p className="dateLabel">This Week Stats<span className="dateSmall"> [{Utils.formatDate(data.currentFrequencyStartDate, "MMM D")} - {Utils.formatDate(data.currentFrequencyEndDate, "MMM D")}]</span></p>;
      case FREQUENCY.MONTHLY:
        return data.currentMonth + " Stats";
      case FREQUENCY.QUARTERLY:
        return <p className="dateLabel">This Quarter Stats<span className="dateSmall"> [{Utils.formatDate(data.currentFrequencyStartDate, "MMM D")} - {Utils.formatDate(data.currentFrequencyEndDate, "MMM D")}]</span></p>;
      case FREQUENCY.YEARLY:
        return data.currentYear + " Stats";
      default:
        return "N/A";
    }
  };
  
  const handleValue = (key) => {
    return data[key] || <>&nbsp;</>;
  };

  return (
    <MonthlyGoalStatusWrapper>
      <div className="header">
        <Typography variant="body1">{statsLabel()}</Typography>
        <div className="TabsWrapper">
          <CustomButton
            size="small"
            variant={activeTab === 1 ? "contained" : "text"}
            color={activeTab === 1 ? "primary" : "inherit"}
            onClick={() => setActiveTab(1)}
            fullWidth
            disableElevation
          >
            Sales
          </CustomButton>
        </div>
      </div>

      <div className="trendingStats">
        <Typography
          variant="body1"
          style={{
            textTransform: "uppercase",
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#7E869A",
            margin: 0,
            whiteSpace: "pre",
            display:'flex',
            justifyContent:'end'
          }}
        >
          Trending
        </Typography>
        <div style={{display:'grid',justifyContent:"start"}}>
          <Typography variant="h5"style={{display:'flex',justifyContent:'start'}} >
            {formatCurrency(
              toZero(data.currentFrequencyPredictedSale),
              "$"
            )}
          </Typography>
          <Typography variant="body1" color="textSecondary">
              {handleValue("trendingForText")}
          </Typography>
        </div>
      </div>

      <div className="monthlyStats">
        <div className="leftSide"  style={{display:'grid',justifyContent:'center',background:'white',
          padding:'10px',   borderRadius:'12px'}}>
            <div className="value">
            <Typography style={{
              fontSize: 24,
              fontWeight: 600,
              lineHeight: "32px",
              color: "#14151A",
            }}>
              {numberArt(data.currentVsLastSalesGrowth, null, "", true)}%
            </Typography>
            <span className="icon">
              {data.currentVsLastSalesGrowth !== null ? (
                toZero(data.currentVsLastSalesGrowth) > 0 ? (
                  <ArrowUpwardIcon style={{ color: "green" }} />
                ) : (
                  <ArrowDownwardIcon style={{ color: "red" }} />
                )
              ) : null}
            </span>
          </div>
          <Typography
            variant="body1"
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "24px",
              color: "#7E869A",
              margin: 0,
              whiteSpace: "pre",
            }}
          >
            VS LAST {frequencyLabel()}
          </Typography>
        </div>

        <div className="rightSide">
          {data.currentVsLastSalesGrowth !== null && (
            <Fragment>
              <Typography style={{
                fontSize: 24,
                fontWeight: 600,
                lineHeight: "32px",
                color: "#14151A",
                marginRight:"10px"
              }}>
                {formatCurrency(toZero(data.previousGoalTotalSale), "$")}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: "24px",
                  color: "#7E869A",
                  margin: 0,  
                  whiteSpace: "pre",
                  marginRight:"10px"
                }}
              >
                LAST {frequencyLabel()}
              </Typography>
            </Fragment>
          )}
        </div>
      </div>

      <div className="currentStats">
        <div className="textWrapper">
          <div className="leftSide">
            <Typography
              variant="body1"
              style={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                color: "#7E869A",
                margin: 0,
                whiteSpace: "pre",
                marginLeft:"10px"
              }}
            >
              CURRENTLY AT
            </Typography>
            <Typography style={{
              fontSize: 24,
              fontWeight: 600,
              lineHeight: "32px",
              color: "#14151A",
              marginLeft:"10px"
            }}>
              {formatCurrency(
                toZero(data.currentFrequencyTotalSale),
                "$"
              )}
            </Typography>
          </div>

          <div className="rightSide">
            <div style={{ display: "flex" }}>
              <div style={{display:'grid',justifyContent:'center',background:'white',padding:'10px',borderRadius:'12px'}}>
                <Typography style={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: "32px",
                  color: "#14151A",
                  display:'flex'
                }}>
                  {toZero(data.currentGoalPercentage)}%
                {data.currentVsLastSalesGrowth !== null ? (
                  toZero(data.currentVsLastSalesGrowth) > 0 ? (
                    <span
                      className="icon"
                      style={{
                        backgroundColor: "white",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px",
                        width: "18px",
                        height: "18px",
                        marginTop: "7px",
                        marginLeft: "5px",
                      }}
                    >
                      <ArrowUpwardIcon
                        style={{ color: "green", fontSize: "20px" }}
                      />
                    </span>
                  ) : (
                    <span
                      className="icon"
                      style={{
                        backgroundColor: "white",
                        padding: "2px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "20px",
                        width: "18px",
                        height: "18px",
                        marginTop: "7px",
                        marginLeft: "5px",
                      }}
                    >

                      <ArrowDownwardIcon
                        style={{ color: "red", fontSize: "20px" }}
                      />
                    </span>
                )
              ) : null}
                </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "24px",
                color: "#7E869A",
                margin: 0,
                whiteSpace: "pre",
                display:"flex"
              }}
            >
              OF GOAL
            </Typography>
            </div>
              </div>
          </div>
        </div>
      </div>
    </MonthlyGoalStatusWrapper>
  );
};

export default MonthlyGoalStatus;
