import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  _handleDeleteLeadSourceModal,
  _handleLeadSourceEdit,
  _handleLeadSourceModal,
  _handleLeadSourcePagination,
  _handleLeadSourceReload,
  _handleLeadSourceSearch,
  addLeadSource,
  deleteLeadSource,
  editLeadSource,
  getAllLeadSource,
  getLeadSourceByPagination,
} from "../../../actions/leadSourceAction";
import Utils from "../../../helpers/Utils";
import { icons } from "./icon";
import "./lead_source.css";

import AddIcon from '@material-ui/icons/Add';
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../constants/CoreConstants";
import PermissionDeniedModal from "../../products/common/permissionModal/PermissionDeniedModal";
import LeadSourceStyle from "./LeadSourceStyle";
import AddLeadSourceModal from "./modals/AddLeadSourceModal";
import DeleteLeadSourceModal from "./modals/DeleteLeadSourceModal";
import PaginationLeadSrouce from "./modals/PaginationLeadSrouce";
import SearchLeadSource from "./modals/SearchLeadSource";
import Table from "./Table";

const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});

const mapDispatchToProps = (dispatch) => ({
  addLeadSource: (params, callback) =>
    dispatch(addLeadSource(params, callback)),
  editLeadSource: (params, callback) =>
    dispatch(editLeadSource(params, callback)),
  deleteLeadSource: (params, callback) =>
    dispatch(deleteLeadSource(params, callback)),
  getLeadSourceByPagination: (params, callback) =>
    dispatch(getLeadSourceByPagination(params, callback)),
  getAllLeadSource: (params, callback) =>
    dispatch(getAllLeadSource(params, callback)),
  _handleLeadSourceModal: (params) => dispatch(_handleLeadSourceModal(params)),
  _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
  _handleLeadSourceSearch: (params) =>
    dispatch(_handleLeadSourceSearch(params)),
  _handleLeadSourceReload: (params) =>
    dispatch(_handleLeadSourceReload(params)),
  _handleLeadSourcePagination: (params) =>
    dispatch(_handleLeadSourcePagination(params)),
  _handleDeleteLeadSourceModal: (params) =>
    dispatch(_handleDeleteLeadSourceModal(params)),
});

const LeadSourceApp = (props) => {
  const { show_modal, show_delete_modal } = props.leadSourceReducer;
  return (
    <div className="container-fluid mail__inbox">
      <Header />
      <div className="details">
        <SearchLeadSource />
        <Table />
      </div>
      <div style={{background:"#ffffff",padding:"20px 0px"}}>
        <PaginationLeadSrouce />
      </div>
        
      {show_modal && <AddLeadSourceModal />}
      {show_delete_modal && <DeleteLeadSourceModal />}
    </div>
  );
};
const LeadSourceV2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadSourceApp);
export default LeadSourceV2;

const HeaderApp = (props) => {
  const classes = LeadSourceStyle();
  const history = useHistory();
  const [openAddLeadSourceModal, setOpenAddLeadSourceModal] = useState(false);
  const [permissionState, setPermissionState] = useState(null);
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
 
  const handleAddLeadSourceModal = () => {
    if (isUserHasPermission) {
      setOpenAddLeadSourceModal(true);
    } else {
      setPermissionState("create");
    }
  };
  useEffect(() => {
    document.title = `Lead Source | ${Utils.getAccountData(
      "AuthUserAgencyName"
    )}`;
    if (window.setActiveSidebar) {
      window.setActiveSidebar("setting");
    }
  }, []);

  return (
    <>
      <div className="tagBox lead_source_page">
        <div>
          <div className={classes.headerContainer}>
            <div style={{ width: "60px", height: "60px" }}>
              <img
                src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/lead_sources_icon.png`}
                alt="logo"
                className={classes.img}
              />
            </div>
            <div>
              <div className={classes.headerText}>Lead Sources</div>
              <div className={classes.headerDescription}>
                Track where your business is coming from
              </div>
            </div>
          </div>
        </div>
        <div className="add_new_user ">
          <button
            className={`${classes.addLeadSourceButton} "modal-trigger accent--bg--text--color"`}
            onClick={handleAddLeadSourceModal}
          >
            <div className={classes.buttonContainer}>
              <AddIcon />{" "}
              <span style={{ paddingTop: "2px" }}>Add New Lead Source</span>
            </div>
          </button>
          {history.location.state &&
            history.location.state.from === "settingPage" && (
              <Link
                to="/user/settings"
                className={"modal-trigger accent--bg--text--color"}
              >
                <span>{icons.backArrow}</span>
                <span>Back</span>
              </Link>
            )}
          {openAddLeadSourceModal && (
            <AddLeadSourceModal
              open={openAddLeadSourceModal}
              onClose={() => setOpenAddLeadSourceModal(false)}
            />
          )}
          {permissionState && (
            <PermissionDeniedModal
              open={true}
              onClose={() => setPermissionState(null)}
              permissionModule={"Lead Source"}
              action={permissionState}
            />
          )}
        </div>
      </div>
    </>
  );
};
export const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderApp);

