import { styled } from "@material-ui/core";
import React from "react";
import Utils from "../../../../helpers/Utils";
import { TrophyIcon } from "../../common/Icons";
import CustomChart from "./CustomChart";
import GoalItem from "./GoalItem";

const GoalChartWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",

  "& .userSelect": {
    width: 330,
    marginLeft: "auto !important",
    marginRight: "14px !important",
  },

  "& .needleChartWrapper": {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 8,
  },
}));

const GoalChart = ({chartData}) => {
  
  const handleValue = (key) => {
    return chartData[key] || 0;
  };

  return (
    <GoalChartWrapper className="goalChart">
      <div className="needleChartWrapper">
        <CustomChart
           maxValue={handleValue("goalForToday")}
           currentValue={handleValue("achievedToday")}
           remaining={handleValue("stillNeededForToday")}
           startedValue={handleValue("startedTodayAt")}
        />
      </div>

      <GoalItem
        text="ACHIEVED TODAY"
        value={Utils.formatCurrency(chartData.achievedToday, "$")}
        icon={<TrophyIcon />}
        bgColor={"#FFFAF2"}
      />
    </GoalChartWrapper>
  );
};

export default GoalChart;
