import React from 'react';
import { Grid, Box} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const AddOrEditProductSkeleton = () => {
  return (
    <div style={{padding:'16px'}}>
       
        <Grid container>
        <Grid item xs={12} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={50} />
        </Box>
      </Grid>
       <Grid item xs={12} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={100} />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={50} />
        </Box>
      </Grid>
     
      <Grid item xs={12} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={50} />
        </Box>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '16px', marginBottom: '12px' }}>
        <Box mb={1.5}>
        <Skeleton variant="text" width="80%" style={{ marginBottom: 8 }} />
          <Skeleton variant="rect" height={50} />
        </Box>
      </Grid>
        </Grid> 
    </div>
  );
};

export default AddOrEditProductSkeleton;
