import React, { useState } from "react";
import {
  IconButton,
  TableCell,
  TableRow,
  makeStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonIcon from "@material-ui/icons/Person";
import SwitchC from "../customField/subComponents/Switch";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import CustomConfirmAlert from "../team/CustomConfirmAlert";

const useStyles = makeStyles((theme) => ({
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  },
}));

const EachProductTableItem = ({ user, setTransferDataDrawer }) => {
  const {
    // id,
    full_name,
    email,
    status,
    name: permissionLevel,
    collaborators,
    created_at,
  } = user || {};
  const [userStatus, setUserStatus] = useState(status);
  const classes = useStyles();

  const handleUserStatus = () => {
    setUserStatus((prevState) => !prevState);
  };

  const handleDelete = () => {
    CustomConfirmAlert({
      handleConfirm: () => {
        CustomConfirmAlert({
          handleConfirm: () => {
            // setDataToEdit(item);
            setTransferDataDrawer();
          },
          handleOnClose: () => {
            // delete api handler
            // deleteData(item.id);
          },
          title: "Are you sure?",
          description:
            "Do you want to transfer the contacts to other users? If you click No, all contacts of the user will be deleted permanently.",
          icon: "question",
          closeButtonText: "No, delete all",
          confirmButtonText: "Yes, transfer",
        });
      },
      callConfirmLast: true,
      title: "Are you sure?",
      description:
        "Do you want delete this user? You can not restore user information again.",
      type_word: "DELETE USER",
      icon: "question",
      closeButtonText: "Cancel",
    });
  };

  return (
    <TableRow className={classes.tableRowWrapper}>
      <TableCell component="th" scope="row">
        <div className="titleWrap">{full_name}</div>
      </TableCell>
      <TableCell align="left">{email}</TableCell>
      <TableCell align="center">
        <SwitchC
          status={userStatus}
          brandColor={"#3f51b5"}
          onChange={handleUserStatus}
          onText="On"
          offText="Off"
        />
      </TableCell>
      <TableCell align="center">{permissionLevel}</TableCell>
      <TableCell align="center">{collaborators}</TableCell>
      <TableCell align="center">{created_at}</TableCell>
      <TableCell align="center">
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <BootstrapTooltip title="Edit User" placement="top">
            <IconButton size="small">
              <EditIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Load Quick Clone" placement="top">
            <IconButton size="small">
              <FileCopyIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Force Login" placement="top">
            <IconButton size="small">
              <PersonIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Delete" placement="top">
            <IconButton size="small" onClick={handleDelete}>
              <DeleteIcon style={{ color: "rgb(255, 38, 74)" }} />
            </IconButton>
          </BootstrapTooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EachProductTableItem;
