import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import CreateNewFolderModal, {
  BasicInput,
} from "../sidebar/CreateNewFolderModal";
import BasicSelect from "./BasicSelect";
import { policyTypeStyle } from "./PolicyTypesStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyType,
  deletePolicyType,
  resetAddPolicyTypeFolder,
  resetAddPolicyTypeSuccess,
  updatePolicyType,
} from "../../../../actions/ProductAction";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../common/productSelector";
import InsuranceCommonSkeleton from "../../common/InsuranceCommonSkeleton";
import useQueryParam from "../../../../hooks/useQueryParam";
import {markStepComplete} from "../../../../api/onboardApi";
import Utils from "../../../../helpers/Utils";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
  description: {
    border: "1px solid #ced4da !important",
    borderRadius: "4px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-multiline": {
      color: "var(--dark_blue)",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    height: "24px ",
    color: "var(--dark_blue)",
  },
  multipleSelect: {
    "& .MuiSelect-select": {
      // paddingTop: "6px !important",
      display: "flex",
      alignItems: "center",
    },
  },
  restoreText: {
    color: "#0b0be1",
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    userSelect: "none",
  },
});

const AddOrEditNewPolicyTypeModal = ({ open, onClose, edit }) => {
  const [duplicateTrashProduct, setDuplicateTrashProduct] = useState(null);
  const { policyTypeFolders, isAddPolicyTypeFolderSuccessful, selectedFolder } =
    useSelector(policyTypeFolderState);
  const { isAddPolicyTypeSuccessful,isSubmitting } = useSelector(policyTypeState);

  const dispatch = useDispatch();

  const categoryCreatedByUser =
    typeof selectedFolder === "number" ? selectedFolder : null;

  const [formData, setFormData] = useState({
    id: edit?.id || null,
    title: edit?.title || "",
    userProductFolderId: edit?.folders[0]?.id || categoryCreatedByUser || null,
  });

  useEffect(() => {
    if (isAddPolicyTypeFolderSuccessful) {
      const latestFolder = policyTypeFolders.at(-1);
      setFormData({
        ...formData,
        userProductFolderId: latestFolder?.id || categoryCreatedByUser || null,
      });

      dispatch(resetAddPolicyTypeFolder());
    }

    if (isAddPolicyTypeSuccessful) {
      onClose();
      dispatch(resetAddPolicyTypeSuccess());
    }
  }, [isAddPolicyTypeSuccessful, isAddPolicyTypeFolderSuccessful]);

  const [error, setError] = useState({
    title: "",
    description: "",
    closeDate: "",
  });

  const [openAddFolderModal, setOpenAddFolderModal] = useState(false);
  const [openTab] = useQueryParam("open-tab");
  const [from] = useQueryParam("from");

  const handleFilterCategory = (event) => {
    setFormData({ ...formData, userProductFolderId: event.target.value });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
    };

    if (formData?.title.trim() === "") {
      errors.title = "Policy type title is required";
      isValid = false;
    }
    if (formData?.title.length > 100) {
      errors.title = "Policy type title must be less than 100 characters";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      ...formData,
      callback: async (data) => {
        if (data.success) {
          if (openTab === "POLICY_TYPES" && from === "onboard"){
            const onboardComplete = await markStepComplete({ key: "SELECT_PRODUCT" });
            if (onboardComplete?.success) {
              Utils.removeQueryParam(["from", "open-tab"]);
            }
          }
          onClose();
        } else {
          if (data && data.data.status === "TRASH") {
            setDuplicateTrashProduct(data.data);
            setError((prevState) => ({
              ...prevState,
              title: "Product already exists in trash.",
            }));
            window.showNotification(
              "error",
              "Product already exists it trash."
            );
          } else {
            setError((prevState) => ({ ...prevState, title: data.message }));
            window.showNotification("error", data.message);
          }
        }
      },
    };

    if (edit) {
      dispatch(updatePolicyType(payload));
      onClose();
    } else {
      dispatch(addPolicyType(payload));
    }
  };

  const handleRestoreProduct = () => {
    dispatch(
      deletePolicyType({
        id: duplicateTrashProduct.id,
        status: "ACTIVE",

        callback: (data) => {
          if (data && data.success) {
            onClose();
          }
        },
      })
    );
  };

  const classes = useStyles();
  const { topbarNewProductButton, lightBlue } = policyTypeStyle();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        minWidth={500}
        // minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          sx={{ borderBottom: "1px solid #d3d3d3" }}
        >
          <Typography className={classes.titleHeader}>
            {edit ? "Edit Policy Type" : "Add a New Policy Type"}
          </Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
       {isSubmitting ? <InsuranceCommonSkeleton/> : ( <Box padding={2}>
          <Box mb={2}>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Policy Type<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box mt={1}>
              <BasicInput
                fullWidth
                value={formData?.title}
                className={`${error.title ? classes.errorStyle : ""}`}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    title: e.target.value,
                  }));
                  if (error.title) {
                    setError((prevState) => ({ ...prevState, title: "" }));
                  }
                }}
                required
              />
              {error.title && (
                <Typography variant="body2" className={classes.errorText}>
                  {error.title}
                  {duplicateTrashProduct ? (
                    <span
                      className={classes.restoreText}
                      onClick={handleRestoreProduct}
                    >
                      Restore
                    </span>
                  ) : null}
                </Typography>
              )}
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Select Category</Typography>
              <Button
                variant="text"
                size="small"
                startIcon={<AddIcon />}
                style={{ color: "#346FEF" }}
                onClick={() => setOpenAddFolderModal(true)}
              >
                Create
              </Button>
            </Box>
            <Box mt={1}>
              <BasicSelect
                options={policyTypeFolders}
                defaultText="Select Category"
                mapping={{
                  value: "id",
                  label: "title",
                }}
                value={formData?.userProductFolderId || ""}
                onChange={handleFilterCategory}
              />
            </Box>
          </Box>
        </Box>)}

        <Box
          sx={{ borderTop: "1px solid #d3d3d3" }}
          padding={2}
          display={"flex"}
          justifyContent={"end"}
        >
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              color="primary"
              className={lightBlue}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={topbarNewProductButton}
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>

      {openAddFolderModal && (
        <CreateNewFolderModal
          open={openAddFolderModal}
          onClose={() => setOpenAddFolderModal(false)}
        />
      )}
    </Dialog>
  );
};

export default AddOrEditNewPolicyTypeModal;
