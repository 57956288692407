import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { HistoryProvider } from '../../App';
import {SettingIconList, SettingMenuList} from '../../constants/SettingIconList';
import Utils from '../../helpers/Utils';



export const SettingSearch = ({ query,userIsOwner,userIsAgent,user,isTemplateUser }) => {

    const history = useContext(HistoryProvider);

    const goToLink = (e, link, state, search) => {
        e.preventDefault();
        history.push({
            pathname: link,
            state: state,
            search: search
        });
    }

    const renderMenu = () => {
        let result = [];

        let hiddenSection = "";
        const isTempUser = Utils.getAccountData('isTemplateUser');
        if(isTempUser == 1){
            hiddenSection = hiddenSection + "CAN-SPAM, Email Signature, Calendar Sync, Export File, Import File, Integration Applications, First Promoter, My Lists";
        }

        const extra = [];

        if ((Utils.getAccountData("userId") > process.env.REACT_APP_CHANGE_IN_10DLC_FOR_SUBACCOUNT_AFTER_USER_ID || Utils.getAccountData("centralBilling")) && !Utils.getAccountData("userIsOwner")){

        }else {
            extra.push({
                name : 'Trust Center (A2P/10DLC)',
                icon : SettingIconList.trustCenter,
                check : () => true,
                link : '/user/telephony-service/trust-center',
                state: { from:'settingPage', tab : 0 },
                inProfileModule : false,
                keywords: ['Phone Settings', 'Phone Settings', 'Trust Center', 'TrustCenter']
            });
        }

        SettingMenuList.concat(extra).forEach(setting => {
            const matchesKeywords = setting.keywords?.some(keyword => 
                keyword.match(new RegExp(Utils.escapeRegex(query), "gi"))
            );

             if(setting.check({isOwner : userIsOwner,user:user,userIsAgent:userIsAgent, agencyId: Utils.getAccountData('agencyId'),isTemplateUser:isTemplateUser}) && 
                (matchesKeywords || (setting.tag && setting.tag.match(new RegExp(Utils.escapeRegex(query), "gi")))))
                {
                if(hiddenSection.search(setting.name) === -1){
                    if (setting.onClick){
                        result.push(
                            <li className='mb-5'>
                                <a href="#" onClick={setting.onClick}>
                                    <div className={`${setting.className ?? 'my-profile-icon-box'} sitting_icon_box d-flex justify-content-center align-items-center`}>
                                        {setting.icon}
                                    </div>
                                    <h6>{setting.name}</h6>
                                </a>
                            </li>
                        );
                    }else {
                        if(setting.inProfileModule) {
                            result.push(
                                <li className='mb-5'>
                                    <Link to={{pathname : setting.link,state : setting.state}}>
                                        <div className={`${setting.className ?? 'my-profile-icon-box'} sitting_icon_box d-flex justify-content-center align-items-center`}>
                                            {setting.icon}
                                        </div>
                                        <h6>{setting.name}</h6>
                                    </Link>
                                </li>
                            );
                        } else {
                            if (setting.target){
                                result.push(
                                    <li className='mb-5'>
                                        <a href={setting.link} target={setting.target}>
                                            <div className={`${setting.className ?? 'my-profile-icon-box'} sitting_icon_box d-flex justify-content-center align-items-center`}>
                                                {setting.icon}
                                            </div>
                                            <h6>{setting.name}</h6>
                                        </a>
                                    </li>
                                );
                            }else {
                                result.push(
                                    <li className='mb-5'>
                                        <a href={setting.link} onClick={e => goToLink(e, setting.link, setting.state, setting.search)}>
                                            <div className={`${setting.className ?? 'my-profile-icon-box'} sitting_icon_box d-flex justify-content-center align-items-center`}>
                                                {setting.icon}
                                            </div>
                                            <h6>{setting.name}</h6>
                                        </a>
                                    </li>
                                );
                            }
                        }
                    }
                }
                
            }
        });

        if(result.length === 0) {
            result.push(<div className='ml-5'>
                No results in settings
            </div>)
        }

        return result;
    }

    return (
        <div className="row">
            <div className="col s12 m12 l12 xl12 sitting_content">
                <div className="email-sitting">
                    <h3>Search Result</h3>
                    <ul className="d-flex justify-content-start align-content-center flex-wrap">
                        {renderMenu()}
                    </ul>
                </div>
            </div>
        </div>
    )
}
