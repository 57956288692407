// custom field
export const GET_PRODUCT_FOLDERS = "GET_PRODUCT_FOLDERS";
export const GET_PRODUCT_FOLDERS_SUCCESS = "GET_PRODUCT_FOLDERS_SUCCESS";
export const GET_PRODUCT_FOLDERS_FAILURE = "GET_PRODUCT_FOLDERS_FAILURE";

export const ADD_PRODUCT_FOLDER = "ADD_PRODUCT_FOLDER";
export const ADD_PRODUCT_FOLDER_SUCCESS = "ADD_PRODUCT_FOLDER_SUCCESS";
export const ADD_PRODUCT_FOLDER_FAILURE = "ADD_PRODUCT_FOLDER_FAILURE";


export const UPDATE_PRODUCT_FOLDER = "UPDATE_PRODUCT_FOLDER";
export const UPDATE_PRODUCT_FOLDER_SUCCESS = "UPDATE_PRODUCT_FOLDER_SUCCESS";
export const UPDATE_PRODUCT_FOLDER_FAILURE = "UPDATE_PRODUCT_FOLDER_FAILURE";

export const DELETE_PRODUCT_FOLDER = "DELETE_PRODUCT_FOLDER";
export const DELETE_PRODUCT_FOLDER_SUCCESS = "DELETE_PRODUCT_FOLDER_SUCCESS";
export const DELETE_PRODUCT_FOLDER_FAILURE = "DELETE_PRODUCT_FOLDER_FAILURE";

export const SELECT_PRODUCT_FOLDER = "SELECT_PRODUCT_FOLDER";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";
export const RESET_ADD_PRODUCT_SUCCESS = "RESET_ADD_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";
export const RESET_DELETE_PRODUCT_SUCCESS = "RESET_DELETE_PRODUCT_SUCCESS";

export const DELETE_PRODUCT_FOREVER = "DELETE_PRODUCT_FOREVER";
export const DELETE_PRODUCT_FOREVER_SUCCESS = "DELETE_PRODUCT_FOREVER_SUCCESS";
export const DELETE_PRODUCT_FOREVER_FAILURE = "DELETE_PRODUCT_FOREVER_FAILURE";

export const TOGGLE_FAVORITE = "TOGGLE_FAVORITE";
export const TOGGLE_FAVORITE_SUCCESS = "TOGGLE_FAVORITE_SUCCESS";
export const TOGGLE_FAVORITE_FAILURE = "TOGGLE_FAVORITE_FAILURE";

export const DESELECT_ALL_PRODUCTS = "DESELECT_ALL_PRODUCTS";

export const SET_TEAM_USERS = "SET_TEAM_USERS";
export const SET_PIPELINES = "SET_PIPELINES";
export const HANDLE_ASSET_LOADING_STATE = "HANDLE_ASSET_LOADING_STATE";

export const GET_POLICY_TYPE_FOLDERS = "GET_POLICY_TYPE_FOLDERS";
export const GET_POLICY_TYPE_FOLDERS_SUCCESS = "GET_POLICY_TYPE_FOLDERS_SUCCESS";
export const GET_POLICY_TYPE_FOLDERS_FAILURE = "GET_POLICY_TYPE_FOLDERS_FAILURE";

export const ADD_POLICY_TYPE_FOLDER = "ADD_POLICY_TYPE_FOLDER";
export const ADD_POLICY_TYPE_FOLDER_SUCCESS = "ADD_POLICY_TYPE_FOLDER_SUCCESS";
export const ADD_POLICY_TYPE_FOLDER_FAILURE = "ADD_POLICY_TYPE_FOLDER_FAILURE";
export const RESET_ADD_POLICY_TYPE_FOLDER = "RESET_ADD_POLICY_TYPE_FOLDER";


export const UPDATE_POLICY_TYPE_FOLDER = "UPDATE_POLICY_TYPE_FOLDER";
export const UPDATE_POLICY_TYPE_FOLDER_SUCCESS = "UPDATE_POLICY_TYPE_FOLDER_SUCCESS";
export const UPDATE_POLICY_TYPE_FOLDER_FAILURE = "UPDATE_POLICY_TYPE_FOLDER_FAILURE";

export const DELETE_POLICY_TYPE_FOLDER = "DELETE_POLICY_TYPE_FOLDER";
export const DELETE_POLICY_TYPE_FOLDER_SUCCESS = "DELETE_POLICY_TYPE_FOLDER_SUCCESS";
export const DELETE_POLICY_TYPE_FOLDER_FAILURE = "DELETE_POLICY_TYPE_FOLDER_FAILURE";
export const RESET_DELETE_POLICY_TYPE_FOLDER = "RESET_DELETE_POLICY_TYPE_FOLDER";

export const SELECT_POLICY_TYPE_FOLDER = "SELECT_POLICY_TYPE_FOLDER";

export const GET_POLICY_TYPES = "GET_POLICY_TYPES";
export const GET_POLICY_TYPES_SUCCESS = "GET_POLICY_TYPES_SUCCESS";
export const GET_POLICY_TYPES_FAILURE = "GET_POLICY_TYPES_FAILURE";

export const ADD_POLICY_TYPE = "ADD_POLICY_TYPE";
export const ADD_POLICY_TYPE_SUCCESS = "ADD_POLICY_TYPE_SUCCESS";
export const ADD_POLICY_TYPE_FAILURE = "ADD_POLICY_TYPE_FAILURE";
export const RESET_ADD_POLICY_TYPE_SUCCESS = "RESET_ADD_POLICY_TYPE_SUCCESS";

export const UPDATE_POLICY_TYPE = "UPDATE_POLICY_TYPE";
export const UPDATE_POLICY_TYPE_SUCCESS = "UPDATE_POLICY_TYPE_SUCCESS";
export const UPDATE_POLICY_TYPE_FAILURE = "UPDATE_POLICY_TYPE_FAILURE";

export const DELETE_POLICY_TYPE = "DELETE_POLICY_TYPE";
export const DELETE_POLICY_TYPE_SUCCESS = "DELETE_POLICY_TYPE_SUCCESS";
export const DELETE_POLICY_TYPE_FAILURE = "DELETE_POLICY_TYPE_FAILURE";
export const RESET_DELETE_POLICY_TYPE_SUCCESS = "RESET_DELETE_POLICY_TYPE_SUCCESS";

export const DELETE_POLICY_TYPE_FOREVER = "DELETE_POLICY_TYPE_FOREVER";
export const DELETE_POLICY_TYPE_FOREVER_SUCCESS = "DELETE_POLICY_TYPE_FOREVER_SUCCESS";
export const DELETE_POLICY_TYPE_FOREVER_FAILURE = "DELETE_POLICY_TYPE_FOREVER_FAILURE";

export const TOGGLE_POLICY_TYPE_FAVORITE = "TOGGLE_POLICY_TYPE_FAVORITE";
export const TOGGLE_POLICY_TYPE_FAVORITE_SUCCESS = "TOGGLE_POLICY_TYPE_FAVORITE_SUCCESS";
export const TOGGLE_POLICY_TYPE_FAVORITE_FAILURE = "TOGGLE_POLICY_TYPE_FAVORITE_FAILURE";

export const UPDATE_POLICY_TYPE_STATUS = "UPDATE_POLICY_TYPE_STATUS";
export const UPDATE_POLICY_TYPE_STATUS_SUCCESS = "UPDATE_POLICY_TYPE_STATUS_SUCCESS";
export const UPDATE_POLICY_TYPE_STATUS_FAILURE = "UPDATE_POLICY_TYPE_STATUS_FAILURE";

export const GET_LOST_REASONS = "GET_LOST_REASONS";
export const GET_LOST_REASONS_SUCCESS = "GET_LOST_REASONS_SUCCESS";
export const GET_LOST_REASONS_FAILURE = "GET_LOST_REASONS_FAILURE";

export const ADD_LOST_REASON = "ADD_LOST_REASON";
export const ADD_LOST_REASON_SUCCESS = "ADD_LOST_REASON_SUCCESS";
export const ADD_LOST_REASON_FAILURE = "ADD_LOST_REASON_FAILURE";
export const RESET_ADD_LOST_REASON_SUCCESS = "RESET_ADD_LOST_REASON_SUCCESS";

export const UPDATE_LOST_REASON = "UPDATE_LOST_REASON";
export const UPDATE_LOST_REASON_SUCCESS = "UPDATE_LOST_REASON_SUCCESS";
export const UPDATE_LOST_REASON_FAILURE = "UPDATE_LOST_REASON_FAILURE";
export const RESET_UPDATE_LOST_REASON_SUCCESS = "RESET_UPDATE_LOST_REASON_SUCCESS";

export const DELETE_LOST_REASON = "DELETE_LOST_REASON";
export const DELETE_LOST_REASON_SUCCESS = "DELETE_LOST_REASON_SUCCESS";
export const DELETE_LOST_REASON_FAILURE = "DELETE_LOST_REASON_FAILURE";
export const RESET_DELETE_LOST_REASON_SUCCESS = "RESET_DELETE_LOST_REASON_SUCCESS";

export const GET_RECYCLE_EVENT = "GET_RECYCLE_EVENT";
export const GET_RECYCLE_EVENT_SUCCESS = "GET_RECYCLE_EVENT_SUCCESS";
export const GET_RECYCLE_EVENT_FAILURE = "GET_RECYCLE_EVENT_FAILURE";

export const ADD_RECYCLE_EVENT = "ADD_RECYCLE_EVENT";
export const ADD_RECYCLE_EVENT_SUCCESS = "ADD_RECYCLE_EVENT_SUCCESS";
export const ADD_RECYCLE_EVENT_FAILURE = "ADD_RECYCLE_EVENT_FAILURE";
export const RESET_ADD_RECYCLE_EVENT_SUCCESS = "RESET_ADD_RECYCLE_EVENT_SUCCESS";


export const UPDATE_RECYCLE_EVENT = "UPDATE_RECYCLE_EVENT";
export const UPDATE_RECYCLE_EVENT_SUCCESS = "UPDATE_RECYCLE_EVENT_SUCCESS";
export const UPDATE_RECYCLE_EVENT_FAILURE = "UPDATE_RECYCLE_EVENT_FAILURE";
export const RESET_UPDATE_RECYCLE_EVENT_REASON_SUCCESS = "RESET_UPDATE_RECYCLE_EVENT_SUCCESS";

export const DELETE_RECYCLE_EVENT = "DELETE_RECYCLE_EVENT";
export const DELETE_RECYCLE_EVENT_SUCCESS = "DELETE_RECYCLE_EVENT_SUCCESS";
export const DELETE_RECYCLE_EVENT_FAILURE = "DELETE_RECYCLE_EVENT_FAILURE";
export const RESET_DELETE_RECYCLE_EVENT_SUCCESS = "RESET_DELETE_RECYCLE_EVENT_SUCCESS";

