import { InputBase, makeStyles, Typography, withStyles } from "@material-ui/core";

export const insuranceCompanyStyle = makeStyles({
    topbarHeaderTitle: {
        fontSize: '20px',
        fontWeight: 600,

    },
    topbarNewFolderButton: {
        fontWeight: 600,
        "&:focus,&:hover": {
            background: 'inherit'
        }
    },
    topbarNewProductButton: {
        fontWeight: 600,
        background: '#346fef',
        color: 'white',
        "&:focus,&:hover": {
            background: '#346fef'
        }
    },
    newFolderModalCancelButton: {
        fontWeight: 600,
        background: '#f2f4f780',
        color: '#475467',
        "&:focus,&:hover": {
            background: '#e0e0e0'
        }
    },

    productBodyTitle: {
        fontSize: '18px',
        fontWeight: 600,
        margin: '0px'

    },
    subTitle: {
        fontSize: '14px',
        fontWeight: 400,
        marginTop: '0px',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif !important'
    },
    grayColor: {
        color: 'gray'
    },
    favIconColor: {
        color: 'rgb(247, 144, 9)'
    },
    lightBlue: {
        fontWeight: 600,
        background: '#f2f4f780 !important',
        color: '#475467',
        "&:focus,&:hover": {
            background: '#e0e0e0'
        }
    },
    textButton: {
        "&:focus": {
            background: 'white'
        }
    },
    customScrollBar: {
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "#2c3e50",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#706fd3",
            borderRadius: "10px",
            // outline: '1px solid #8e44ad'
        },
    },
});

export const BasicFormLabel = withStyles((theme) => ({
    root: {
        color: "var(--dark_blue) !important",
        fontSize: "14px !important",
        fontWeight: "600 !important",
        marginBottom: "4px !important",
        height: "unset !important"
    }
}))(Typography);

export const BasicInput = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
        "&.MuiInputBase-multiline": {
            padding: "0 !important",
        },
        "& .MuiInputAdornment-positionEnd": {
            position: "absolute",
            right: "12px"
        },
    },


    input: {
        position: "relative",
        // color: "var(--dark_blue)",
        backgroundColor: "white !important",
        fontSize: "15px !important",
        margin: "0 !important",
        height: "2.5rem !important",
        padding: "10px 12px 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
        },

        "&.MuiSelect-select": {
            height: "1rem !important",
        },
        "&::placeholder": {
            color: "#082852 !important",
        },


    },
}))(InputBase);