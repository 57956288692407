import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const InsuranceCommonSkeleton = () => {
    return (
      <Box padding={2}>
          <Box mb={2}>
            <Skeleton width="50%" height={20} />
            <Box mt={1}>
              <Skeleton variant="rect" height={40} />
            </Box>
          </Box>
    
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Skeleton width="30%" />
              <Skeleton variant="rect" width={100} height={36} />
            </Box>
            <Box mt={1}>
              <Skeleton variant="rect" height={40} />
            </Box>
          </Box>
        </Box>
    )
  }
  export default InsuranceCommonSkeleton;