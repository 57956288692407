import React from "react";
import { makeStyles } from "@material-ui/core";
import BodySkeleton from "../BodySkeleton";
import { CustomScrollbar } from "../../../productsStyle";
import InsuranceCompaniesTable from "./InsuranceCompaniesTable";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

const ContentBody = ({
  insuranceCompanyList,
  setInsuranceCompanyList,
  selectedFolder,
  isLoading,
  folderList,
  handleFolderList,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  total,
  folderObject,
  setFilterTrigger,
  filterTrigger,
  setSelectedCompanies,
  selectedCompanies,
}) => {

  const handleUpdateCompanyName = (payload) => {
    setInsuranceCompanyList((prevState) =>
      prevState.map((company) => {
        if (company.id === payload?.insurance_company_id) {
          return {
            ...company,
            company_name: payload.company_name,
            folders:payload.insurance_company_folder_ids
          };
        }
        return company;
      })
    );
  };

 

  const classes = useStyles();
  if (isLoading) {
    return <BodySkeleton />;
  } else {
    return (
      <div className={classes.bodyWrapper}>
        <CustomScrollbar style={{ maxHeight: "calc(100vh - 270px)" }}>
          <div>
            <InsuranceCompaniesTable 
            insuranceCompanyList={insuranceCompanyList}
            folderList={folderList}
            handleFolderList={handleFolderList}
            selectedFolder={selectedFolder}
            setInsuranceCompanyList={setInsuranceCompanyList}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            folderObject={folderObject}
            onUpdateCompanyName={handleUpdateCompanyName}
            setFilterTrigger={setFilterTrigger}
            filterTrigger={filterTrigger}
            setSelectedCompanies={setSelectedCompanies}
            selectedCompanies={selectedCompanies}
            />
          </div>
        </CustomScrollbar>
      </div>
    );
  }
};

export default ContentBody;
