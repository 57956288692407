import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableRow,  } from '@material-ui/core';


const DashboardViewSkeleton = () => {
    const rows = Array.from(new Array(6));

    return (
        <Table  aria-label="collapsible table">
            <TableBody>
                {rows.map((_, index) => (
                    <TableRow key={index}>
                        <TableCell align="center"><Skeleton width="100%" height={50} variant="rect" style={{marginBottom:'20px'}} /></TableCell>
                       
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default DashboardViewSkeleton;
