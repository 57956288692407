import React, { useEffect, useState } from "react";
import { getUserBrandInfo, getUserBrandInfoForUpdate } from "../../api/profileApi";
import Loader from "../globals/Loader";
import Styles from "./RegistrationDetails.module.css";

const GENERATING = "Generating...";

export const RegistrationDetails = ({ update = false }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [brandData, setBrandData] = useState(null);

  useEffect(() => {
    handleDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDetails = () => {
    if (!submitting) {
      setSubmitting(true);

      if(update){
        getUserBrandInfoForUpdate()
        .then((res) => {
          if (res.data.data && res.data.success) {
            setBrandData(res.data.data);
          } else {
            setErrorMsg(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
      }else{
        getUserBrandInfo()
        .then((res) => {
          if (res.data.data && res.data.success) {
            setBrandData(res.data.data);
          } else {
            setErrorMsg(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
      }
    }
  };

  const solePropreitor = () => {
    return (
      <div>
        <div>
          <h1 className={Styles.detailsHeader}>Sole Proprietor Profile info</h1>
        </div>
        <div>
          <div className={Styles.detailsbody}>
            <div className={Styles.detailsInfo}>
              <span className={Styles.strong}>Name: </span>
              {
                brandData.data?.starter_customer_profile_information?.first_name
              }{" "}
              {brandData.data?.starter_customer_profile_information?.last_name}
            </div>
            <div className={Styles.detailsInfo}>
              <span className={Styles.strong}>Email: </span>
              {brandData.data?.starter_customer_profile_information?.email}
            </div>

            <div className={Styles.detailsInfo}>
              <span className={Styles.strong}>Phone Number: </span>
              {
                brandData.data?.starter_customer_profile_information
                  ?.phone_number
              }
            </div>
            {brandData.data?.starter_customer_profile_address && (
              <div>
                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Street: </span>
                  {brandData.data?.starter_customer_profile_address?.street}
                </div>

                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>City: </span>
                  {brandData.data?.starter_customer_profile_address?.city}
                </div>

                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Country: </span>
                  {
                    brandData.data?.starter_customer_profile_address
                      ?.iso_country
                  }
                </div>
              </div>
            )}

            {brandData.data?.customer_profile_address && (
              <div>
                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Street: </span>
                  {brandData.data?.customer_profile_address?.street}
                </div>

                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>City: </span>
                  {brandData.data?.customer_profile_address?.city}
                </div>

                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Country: </span>
                  {
                    brandData.data?.customer_profile_address?.iso_country
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  const others = () => {
    return (
      <div>
        <div className={Styles.detailsOthers}>
          <div className="m-2">
            <div>
              <h1 className={Styles.detailsHeader}>General Information</h1>
            </div>
            <div>
              <div className={Styles.detailsbody}>
                <div>
                  {brandData.data?.customer_profile_address?.customer_name}
                </div>
                <div>{brandData.data?.customer_profile_address?.street}</div>
                <div>{brandData.data?.customer_profile_address?.city}</div>
                <div>
                  {brandData.data?.customer_profile_address?.iso_country}
                </div>
              </div>

              <div className="mt-3">
                <h1 className={Styles.detailsHeader}>Business Information</h1>
              </div>
              <div className={Styles.detailsbody}>
                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Business Type: </span>
                  {
                    brandData.data?.customer_profile_business_information
                      ?.business_type
                  }
                </div>
                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>Industry Type: </span>
                  {
                    brandData.data?.customer_profile_business_information
                      ?.business_industry
                  }
                </div>
                <div className={Styles.detailsInfo}>
                  <span className={Styles.strong}>EIN: </span>
                  {
                    brandData.data?.customer_profile_business_information
                      ?.business_registration_number
                  }
                </div>
                <div className={Styles.detailsInfo}>
                  <span>
                    https://
                    {
                      brandData.data?.customer_profile_business_information
                        ?.website_url
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h1 className={Styles.detailsHeader}>People to Contact</h1>
            </div>

            <div className={Styles.detailsbody}>
              <div>
                {brandData.data?.authorized_representative_1?.job_position}
              </div>
              <span>
                {brandData.data?.authorized_representative_1?.first_name}{" "}
                {brandData.data?.authorized_representative_1?.last_name}
              </span>
              <div>{brandData.data?.authorized_representative_1?.email}</div>
              <div>
                {brandData.data?.authorized_representative_1?.phone_number}
              </div>
            </div>
            {brandData.data?.authorized_representative_2 && (
              <div className={Styles.detailsbody}>
                <div>
                  {brandData.data?.authorized_representative_2?.job_position}
                </div>
                <span>
                  {brandData.data?.authorized_representative_1?.first_name}{" "}
                  {brandData.data?.authorized_representative_1?.last_name}
                </span>
                <div>{brandData.data?.authorized_representative_2?.email}</div>
                <div>
                  {brandData.data?.authorized_representative_2?.phone_number}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {submitting ? (
        <div className={Styles.loaderPage}>
          <h1>{GENERATING}</h1>
          <Loader />
        </div>
      ) : brandData ? (
        brandData["registration-type"] === "SOLE_PROPRIETOR" ? (
          solePropreitor()
        ) : (
          brandData && others()
        )
      ) : (
        <div className={Styles.detailsHeader}>{errorMsg}</div>
      )}
    </div>
  );
};
