const Pagination = (props) => {
    const handleInputPageChange = (e) => {
        if(e.target.value === ""){
            props.pagination.onChangePage(e.target.value)
        }
        else{
            if(onlyNumbers(e.target.value)){
                props.pagination.onChangePage(e.target.value)
            }
        }
    }
    const onlyNumbers = (str) => {
        return /^[0-9]+$/.test(str);
    }

    return (
        <div className={`meis_ctmw_pagination_wrp ${props.pagination.totalRecords === 0 ? 'meis_ctmw_pw_none': ''}`}>
            
            <div className="meis_ctmw_pagination_wrp_top">
                <span className="meis_ctmw_pagination_wrp_top_page">Page </span>
                <input 
                    className="meis_ctmw_pagination_wrp_top_input"
                    defaultValue={props.pagination.currentPage}
                    placeholder="Page"
                    onChange={handleInputPageChange}
                />
                <span className="meis_ctmw_pagination_wrp_top_count">of {Math.ceil(props.pagination.totalRecords / props.pagination.perPage)}</span>
            </div>

            <span className="meis_ctmw_pagination_wrp_total_cotent">Total: <span>{props.pagination.totalRecords}</span></span>
            
        </div>
    );
}
export default Pagination;