import {useState} from "react";
import { getCustomFieldFolderV2Api, reorderCustomFieldDataV2Api } from '../../../../api/CustomFieldApi';
import useDelayCallback from '../helpers/useDelayCallback';
import { handleSelectFolder, handleStates, handleStoreFolders } from '../reducer/Actions';
import { useCFContext } from '../reducer/CustomProvider';
import GroupListItem from './groups-list/GroupListItem';
import Styles from './style.module.css'
import AddCustomField from "../add-field/AddCustomField";
import CustomDrawerPype from "../helpers/drawer/CustomDrawerPype";
import { HintsIcon } from "../helpers/IconList";
import BootstrapTooltip from "../../../common/BootstrapTooltip";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const LeftSideApp = () => {
  const {state, dispatch} = useCFContext();
  const [open, setOpen] = useState(false);

  useDelayCallback(() => {
    getCustomFolders()
  }, [])
  const getCustomFolders = () => {
    getCustomFieldFolderV2Api()
    .then(res => {
      const response = res.data;
      if(response.status === 'success'){
        dispatch(handleStoreFolders(response.data))
        if(response.data.length > 0){
          if(state.selectedFolder !== response.data[0].group_name){
            dispatch(handleSelectFolder(response.data[0].group_name))
          }
        }
      }
      else{
        dispatch(handleStates({gettigFolders: false}))
      }
    })
    .catch(error => {dispatch(handleStates({gettigFolders: false}))})
  }

  const  onDragEnd = (result) => {
    let {destination, draggableId, source, type} = result;
    if(destination == null || destination.index === source.index){
      return;
    }
    if(type === "CUSTOM_GROUP"){
      const custom_field_groups = Reorder(
        state.folders,
        source.index,
        destination.index
      );
      dispatch(handleStoreFolders(custom_field_groups))
      reorderCustomFieldDataV2Api({
        type: 'group',
        group_name: draggableId,
        destination_group_name: state.folders[parseInt(destination.index)].group_name,
        destination_index: destination.index,
        source_index: source.index,
        lastItem: (state.folders.length === (destination.index + 1))
      }).then(res => {
        let response = res.data;
        if(response.status === "success"){
          try{
            let local_data = localStorage.getItem("global__custom__field__data");
            if(local_data){
              localStorage.removeItem("global__custom__field__data")
            }
          }
          catch(error){}
        }else{}
      }).catch(error => {})
    }
  }

  const renderGroups = () => {
    const view = [];
    state.folders.forEach((item, index) => {
      view.push(renderDragContext(index, item))
    })
    return view;
  }
  const renderDragContext = (index, item) => {
    return (
      <Draggable
        key={`${index}-${item.group_name}`}
        draggableId={`${item.group_name}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <GroupListItem 
              key={index}
              item={item}
              index={index}
              provided_ref={provided}
            />
          </div>
        )}
      </Draggable>
    );
  }
  const renderLoading = (emptyScreen=false) => {
    return (
      <div className={Styles.loadingScreenWrp}>
        {emptyScreen ? 'No folder found !' : 'Please wait...'}
      </div>
    );
  }
  const renderDropContext = () => {
    if(state.gettigFolders){
      return renderLoading()
    }
    if(state.folders.length === 0){
      return renderLoading(true)
    }
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="droppable" type="CUSTOM_GROUP">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getGroupListStyle(snapshot.isDraggingOver)}
          >
            {renderGroups()}
            {provided.placeholder}
          </div>
        )}
        </Droppable>
      </DragDropContext>
    );
  }
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.addButtonWrp}>
        <span className={Styles.addButton} onClick={()=>{setOpen(true)}}>Add New Field</span>
        <BootstrapTooltip
          title={
            <>
              <p>Instructions</p>
              <p>1. By default, our system comes with a set of standard data fields. If you wish to add additional data fields you can add them here.</p>
              <p>2. You have to activate the custom field to use it everywhere</p>
            </>
          }
          arrow
          placement="bottom"
        >
          <span className={Styles.hintsIcon}><HintsIcon height="18px" width="18px" /></span>
        </BootstrapTooltip>
      </div>
      <div className={`${Styles.listWrp} cf_scroll_bar`}>{renderDropContext()}</div>
      <span className={`${Styles.addButton} ${Styles.refreshButton} ${state.selectedFolder === "___more_fields___" ? Styles.activeMore : ""}`} onClick={() => state.selectedFolder !== "___more_fields___" ? dispatch(handleSelectFolder('___more_fields___')) : {}}>
        More fields
      </span>
      {/* <span className={`${Styles.addButton} ${Styles.refreshButton} ${state.gettigFolders ? Styles.active : ''}`} onClick={handleRefresh}>Refresh</span> */}

      <CustomDrawerPype
        isShow={open}
        hanldeDrawer={(status) => {}}
        renderChild={() => {
          return <AddCustomField onClose={() => setOpen(false)} />
        }}
      />
    </div>
  );
}
export default LeftSideApp;

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
export const getGroupListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#ebecf0" : 'inherit',
  height: '100%'
});
export const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "inherit",
    borderRadius: '8px',
    // styles we need to apply on draggables
    ...draggableStyle
  };
};