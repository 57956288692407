import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomConfirmAlert from "../../../common/confirm-alert/CustomConfirmAlert";
import AddOrEditNewCompanyModal from "../AddOrEditNewCompanyModal";

const PrimaryCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#3C7EF3",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Wrapper = styled(Grid)(({ theme }) => ({
  "& .eachCompanyItem": {
    position: "relative",
    paddingLeft: 12,

    "&:hover .actions.active": {
      visibility: "visible",
    },

    "& .actions": {
      backgroundColor: "rgb(255 255 255 / 100%)",
      position: "absolute",
      visibility: "hidden",
      top: 0,
      right: 0,
      height: '100%',
      width: '92%',
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      gap: 8,
      [theme.breakpoints.between('lg', 1600)]: {
        width: '89%',
      },
    },

    // "& .checkbox": {
    //   "& .MuiFormControlLabel-label": {
    //     maxWidth: 285,
    //   }
    // }
  },
}));

const EachCompanyItem = ({
  company,
  onSelectCompany,
  onConfirmDelete,
  onUpdateCompanyName,
}) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);

  const checked = company.selected === 1 ? true : false;
  const isDeletable = company?.deletable === 1 ? true : false;

  const handleChange = () => {
    onSelectCompany({
      checked: !checked === true ? "TRUE" : "FALSE",
      id: company.id,
    });
  };

  const confirmDeleteCategory = () => {
    onConfirmDelete(company.id);
  };

  const handleDeleteCategory = () => {
    CustomConfirmAlert({
      onSubmit: () => confirmDeleteCategory(),
      title: "Are you sure you want to delete this company?",
      description: "You cannot undo this action",
    });
  };
  return (
    <Wrapper item xs={12} md={6} lg={4}>
      <div className="eachCompanyItem">
        <div className="d-flex align-items-center">
          <FormControlLabel
            // style={{ color: "gray" }}
            className="checkbox"
            control={
              <PrimaryCheckbox
                checked={checked}
                onChange={handleChange}
                color="primary"
                size="small"
                onMouseEnter={() => setIsCheckboxHovered(true)}
                onMouseLeave={() => setIsCheckboxHovered(false)}
              />
            }
            label={company?.company_name}
          />

          {isDeletable && (
            <Chip
              variant="outlined"
              color="primary"
              size="small"
              label={"Custom"}
              style={{ height: 18 }}
            />
          )}
        </div>

        <div
          className={`actions ${
            isDeletable && !isCheckboxHovered ? "active" : ""
          }`}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              maxWidth: "200px",
              color: "#133159",
            }}
          >
            {company?.company_name}
          </span>

          <IconButton
            style={{ backgroundColor: "rgb(52 111 239 / 20%)" }}
            size="small"
            onClick={() => setOpenEditModal(true)}
          >
            <EditIcon style={{ color: "#346fef", fontSize: 16 }} />
          </IconButton>

          <IconButton
            style={{ backgroundColor: "rgb(236 19 30 / 20%)" }}
            size="small"
            onClick={handleDeleteCategory}
          >
            <DeleteIcon style={{ color: "#ec131f", fontSize: 16 }} />
          </IconButton>
        </div>
      </div>

      {openEditModal && (
        <AddOrEditNewCompanyModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          edit={company}
          onUpdateCompanyName={onUpdateCompanyName}
        />
      )}
    </Wrapper>
  );
};

export default EachCompanyItem;
