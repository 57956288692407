import React from "react";
import './geo-style.css';
import whatLeftImg from './images/geo.png';
import Question from "./Question";
import StepContent from "./StepContent";
import StepLeft from "./StepLeft";
import StepRight from "./StepRight";
import WhatIsGeoFraming from "./WhatIsGeoFraming";


const GeoMain = () => {

    return (
      <div className="geo__main__wrapper__update">
        <div className="geo__top__section__wr">
          <h1 className="geo__heading__h1">Geographic Farming</h1>
          <h4 className="geo__sub__heading">
            Pinpoint your ideal prospects anywhere in the United States
          </h4>
          <div className="geo__top__vidoe__wr">
            <iframe
              width="420"
              height="315"
              title="Geo Framing"
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
            ></iframe>
          </div>
          <a href="#" className="geo__shedule__demo__btn">
            Schedule A Demo
          </a>

          {/* <button className="geo__get__start__btn">
                    <span className="geo__get__start__top"><span>{GeoIcons.arrowRight}</span> <span>Get Started!</span></span>
                    <p className="geo__get__start__bottom">Unlock this feature for $24.99/month</p>
                </button> */}
        </div>
        <WhatIsGeoFraming
          title="What Is Geographic Farming?"
          content1="Geographic farming is known by a few different names…geo farming, circle prospecting, neighbor marketing, farming, etc."
          content2="Geographic farming is a proven method of marketing your business to a specific geographic region to raise awareness and generate leads."
          content3="This strategy allows you to pinpoint your ideal clients so you can focus your marketing efforts and dollars."
          img={whatLeftImg}
        />
        <div className="geo__how__it__wrok__wrapper">
          <h2 className="geo__how__it__word__inner__h2">How Does It Work?</h2>
          <div className="geo__farming__overly__section">
            <div className="what__geo__framing__overly"></div>
            <StepLeft
              step="STEP 1:"
              title="Find Your Ideal Prospects Quickly"
              content="Use our simple mapping tool to target your ideal clients. Target a city, a neighborhood, or even specific homes in that neighborhood!"
              img="https://salespype.com/wp-content/uploads/2022/04/geographic-farming.png"
            />
          </div>

          <div className="geo__farming__setp__2">
            <div className="geo__farming__setp__2__overly"></div>
            <StepRight
              step="STEP 2:"
              title="Click Send!"
              content="Click send and our system will go to work! We make it easy to put your marketing on auto pilot."
              content2="We’ll organize all your client conversations in 1 easy to manage inbox."
              img="https://salespype.com/wp-content/uploads/2022/04/geo-farming-2.png"
            />
          </div>
          <div className="geo__farming__setp__3">
            <div className="geo__farming__setp__3__overly"></div>
            <StepLeft
              step="STEP 3:"
              title="Click Send!"
              content="Click send and our system will go to work! We make it easy to put your marketing on auto pilot."
              content2="We’ll organize all your client conversations in 1 easy to manage inbox."
              img="https://salespype.com/wp-content/uploads/2022/04/geo-farming-screenshot3-.png"
            />
          </div>
        </div>
        <div className="geo__getting__started">
          <h2 className="geo__getting__started__title">Getting Started</h2>
          <h4 className="geo__getting__started__sub__title">
            ACTION REQUIRED!
          </h4>
          <div className="geo__getting__border"></div>

          <StepContent
            title="Step 1: Purchase the Geo Farming Add-On"
            content="The data associated with the geo farming tool is very expensive. As a result this will be an optional add on for our white label partners. The geo farming tool can be added to your white label for an annual fee of $1,000. To get started sign up below."
            btnText="Sign up"
          />
          <div className="padding__top__bottom__50">
            <StepContent
              title="Step 2: Set Your Pricing"
              content="Once you’ve made payment, we will add the geo farming option. This feature will not automatically be activated system wide. Any user who wishes to use this feature will need to sign up and pay an additional fee. This fee will go directly to us at SalesPype to assist with data costs. Our minimum fee is $24.99/mo, but you can increase that cost to add a profit margin for yourself. "
            />
          </div>
          <StepContent
            title="Step 3: Notify Your Users"
            content="Now it’s time to promote this feature to your users. We’ll create a custom sign up page inside your system that is branded with your logo. You can also add your own pre-recorded video demo to the page. The geo farming feature is extremely unique and will separate you from other systems on the market. The geo farming tool sells itself once people see in action."
          />
        </div>
        <div className="geo__question__wr">
          <Question />
        </div>
      </div>
    );
}


export default GeoMain;