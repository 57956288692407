import { Paper, styled } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const MapOrderTableWrapper = styled(Paper)({
  margin: 16,
  border: "none",
  "& .skip-tracing-table": {
    "& th": {
      background: blue[900],
      color: "#fff",
      "&:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "&:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
    },
    "& td, & th": {
      padding: "2px 8px",
      borderRadius: 0,
    },
    "& .stt-address-row": {
      background: "#3c7ef347",
      "& td:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "& td:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
    },
    "& .stt-address-details-row": {
      "& .MuiTableCell-root": { borderBottom: "none" },
      "& td:not(:first-of-type)": {
        borderBottom: "1px solid #d3d3d3",
      },
      "& td:last-of-type": {
        borderRight: "1px solid #d3d3d3",
        borderRadius: "0 8px 8px 0",
      },
      "& td:nth-of-type(2)": {
        borderLeft: "1px solid #d3d3d3",
        borderRadius: "8px 0 0 8px",
      },
      "&.top-contact": {
        "& td:not(:first-of-type)": {
          backgroundColor: "#e5e5e5",
          borderTop: "1px solid #d3d3d3",
        },
      },
    },
  },
  "& .skip-trace-table-container": {
    height: "calc(100vh - 400px)",
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
      scrollbarWidth: "thin"
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "4px",
      background: "#f4f5f7",
      boxShadow: "inset 1px 0px 0px rgba(148, 157, 178, 0.12)"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      backgroundColor: "#949db2",
      boxSizing: "border-box"
    }
  }
});

export default MapOrderTableWrapper;
