
const rootUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/profile`
const containerUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/container`
const backEndBaseUrlForPublicApi = `${process.env.REACT_APP_JAVAEND_URL}/${process.env.REACT_APP_API_VERSION}/`
// const backEndBaseUrlForPublicApi = `${process.env.REACT_APP_PUBLIC_API_TOKEN_END_POINT}`
const tenantServerPowerDialer = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/power-dialer`;
const contactUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/contacts`;
const tenantBackendV1 = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1`
const lookupBackendV1 = `${process.env.REACT_APP_CARRIER_LOOKUP_BACKEND_SERVER}/v1`
const smartWebhookBackendV1 = `${process.env.REACT_APP_SMART_WEBHOOK_BACKEND_SERVER}/v1`
const invoiceBackend = `${process.env.REACT_APP_INVOICE_BACKEND_SERVER}/api/v1`
const a2pRegistrationBackend = `${process.env.REACT_APP_BACKEND_URL}/v1`
const appListApi = process.env.REACT_APP_APP_LIST_API
const mapListV2Url = `${process.env.REACT_APP_BACKEND_URL}/v2/map-list`;
const java17CoreApiV1 = `${process.env.REACT_APP_JAVA17_CORE_URL}/v1`;
const calendarUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar`;
const dealUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/deal`;
const reportUrl = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/report`
const insuranceOnboardUrl = `${process.env.REACT_APP_PYPEPRO_TENANT_BACKEND_SERVER}/api/v1/onboard`

const Api = {
    getUserData: `${rootUrl}/get-user-data`,
    updateUserData: `${rootUrl}/update-user-data`,
    updatePassword: `${rootUrl}/update-password`,
    updateCompanyData: `${rootUrl}/update-company-data`,
    updateFavicon: `${rootUrl}/update-favicon`,
    updateAvatar: `${rootUrl}/update-avatar`,
    getGeneralSettings: `${rootUrl}/get-general-settings`,
    updateGeneralSettings: `${rootUrl}/update-general-settings`,
    getCutoffSettings: `${rootUrl}/get-cutoff-settings`,
    updateCutoffSettings: `${rootUrl}/update-cutoff-settings`,
    updateTimezone: `${rootUrl}/update-timezone`,
    getNotificationSettings: `${rootUrl}/get-notification-settings`,
    updateNotificationSettings: `${rootUrl}/update-notification-settings`,
    getCallOutcomeList: `${rootUrl}/get-call-outcome-list`,
    saveCallOutcome: `${rootUrl}/save-call-outcome`,
    deleteCallOutcome: `${rootUrl}/delete-call-outcome`,
    getInstantTopupSettings: `${rootUrl}/get-instant-topup-settings`,
    rechargeInstantTopup: `${rootUrl}/recharge-instant-topup`,
    getRechargeSettings: `${rootUrl}/get-recharge-settings`,
    updateRechargeSettings: `${rootUrl}/update-recharge-settings`,
    existingCardList: `${rootUrl}/existing-card-list`,
    activateCard: `${rootUrl}/activate-card`,
    deleteCard: `${rootUrl}/delete-card`,
    getCardInfoSettings: `${rootUrl}/get-card-info-settings`,
    saveCard: `${rootUrl}/save-card`,
    subUserList: `${rootUrl}/sub-user/list`,
    snapTeamUserList: `${rootUrl}/snap-team-user/list`,
    snapShotAccountList: `${rootUrl}/snap-shot-account/list`,
    snapShotCategoryList: `${rootUrl}/snapshot-category-list`,
    categorySnapShotList: `${rootUrl}/get-category-snapShot-list`,
    snapShotPublicUpdate: `${rootUrl}/snapshot-public-update`,
    deactivateUser: `${rootUrl}/sub-user/deactivate`,
    activateUser: `${rootUrl}/sub-user/activate`,
    deleteUser: `${rootUrl}/sub-user/delete`,
    cancelUserAccount: `${rootUrl}/cancel-user-account`,
    deleteUserWithoutTransfer: `${rootUrl}/sub-user/delete-without-transfer`,
    userCreationDetails: `${rootUrl}/sub-user/creation-details`,
    saveSubUser: `${rootUrl}/sub-user/save`,
    saveSnapShotAccount: `${rootUrl}/sub-user-snap-shot/save`,
    snapShotAccountDelete: `${rootUrl}/sub-user-snap-shot/delete`,
    syncAccountSnapShot: `${rootUrl}/sub-user-snap-shot/sync`,
    getSnapShotDetails: `${rootUrl}/get-sub-user-snap-shot-details`,
    checkDuplicateSnapShotItems: `${rootUrl}/check-conflict-snap-shot-item`,
    processSnapShotItems: `${rootUrl}/process-snap-shot-item`,
    transferDataDetails: `${rootUrl}/sub-user/transfer-data-details`,
    getSubUserAuthKey: `${rootUrl}/sub-user/auth-key`,
    saveEmailProvider: `${rootUrl}/email-settings/providers/save`,
    getCanSpamAddress: `${rootUrl}/email-settings/get-can-spam-address`,
    saveCanSpamAddress: `${rootUrl}/email-settings/save-can-spam-address`,
    saveSignature: `${rootUrl}/email-settings/save-signature`,
    getConnectedCalendar: `${rootUrl}/email-settings/calendar-sync/connected-calendar`,
    calendarSyncDelete: `${rootUrl}/email-settings/calendar-sync/delete`,
    calendarSyncList: `${rootUrl}/email-settings/calendar-sync/list`,
    calendarSyncStore: `${rootUrl}/email-settings/calendar-sync/store`,
    contactList: `${rootUrl}/contact-list/list`,
    deleteList: `${rootUrl}/contact-list/delete`,
    contactListSave: `${rootUrl}/contact-list/save`,
    tagList: `${rootUrl}/tags/list`,
    saveTag: `${rootUrl}/tags/save`,
    deleteTag: `${rootUrl}/tags/delete`,
    activityLogsList: `${rootUrl}/activity-logs/list`,
    getLeadGroupForDataTable: `${rootUrl}/get-lead-group-for-data-table`,
    getTeamUsers: `${rootUrl}/get-team-users`,
    getTeamUsersWithoutTemplate: `${rootUrl}/get-team-users-without-template`,
    updateOrCreateLeadGroup: `${rootUrl}/update-or-create-lead-group`,
    removeGroup: `${rootUrl}/remove-group`,
    packagesList: `${rootUrl}/packages/list`,
    packageDetails: `${rootUrl}/packages/get-details`,
    packageDowngradeRequest: `${rootUrl}/packages/downgrade-request`,
    packagePayment: `${rootUrl}/packages/payment`,
    packagePaymentNew: `${rootUrl}/packages/payment/new`,
    mapOrdersList: `${rootUrl}/map-orders/list`,
    mapOrdersSaveTitle: `${rootUrl}/map-orders/save-title`,
    mapFetchContactList: `${rootUrl}/map-orders/map-fetched-contact-list`,
    // mapOrderAddToContact: `${rootUrl}/map-orders/add-to-contact`,
    mapOrderAddToContact: `${mapListV2Url}/map-orders/add-to-contact`,
    phoneSettingsDetails: `${rootUrl}/phone-settings/get-details`,
    virtualNumberList: `${rootUrl}/phone-settings/virtual-number-list`,
    virtualSearchNumberList: `${rootUrl}/phone-settings/virtual-number-search-list`,
    buyVirtualNumber: `${rootUrl}/phone-settings/virtual-number-buy-submit`,
    editVirtualNumber: `${rootUrl}/phone-settings/virtual-number-edit-submit`,
    forwardVirtualNumberSubmit: `${rootUrl}/phone-settings/virtual-number-forward-edit-submit`,
    callWhisperVirtualNumberSubmit: `${rootUrl}/phone-settings/virtual-number-whispher-edit-submit`,
    virtualNumberRecordingDisclosureSubmit: `${rootUrl}/phone-settings/virtual-number-recording-disclosure-edit-submit`,
    virtualNumberVoicemailMessageSubmit: `${rootUrl}/phone-settings/virtual-number-voicemail-message-edit-submit`,
    incomingCallTimeoutSubmit: `${rootUrl}/phone-settings/incomming-call-timeout-edit-submit`,
    deleteVirtualNumber: `${rootUrl}/phone-settings/virtual-number-delete`,
    deleteVirtualNumberSetting: `${rootUrl}/phone-settings/virtual-number-setting-delete`,
    virtualNumberChangeDefault: `${rootUrl}/phone-settings/virtual-number-default-change`,
    virtualNumberRequestForShakenStirRegistration: `${rootUrl}/phone-settings/virtual-number-shaken-stir-registration-request`,
    virtualNumberCallRecordSetting: `${rootUrl}/phone-settings/virtual-number-call-record-setting`,
    virtualNumberSaveDialerSetting: `${rootUrl}/phone-settings/virtual-number-save-dialer-setting`,
    virtualNumberUpdateForwardSetting: `${rootUrl}/phone-settings/virtual-number-update-forward-setting`,
    virtualNumberUpdateDialerSetting: `${rootUrl}/phone-settings/virtual-number-update-dialer-setting`,
    mailBoxList: `${rootUrl}/mail-box/list`,
    deleteMailBox: `${rootUrl}/mail-box/delete`,
    tagsApi: `${tenantBackendV1}/tags`,

    /* custom field */
    loadCustomFields: `${rootUrl}/load-custom-field-data`,
    updateCustomField: `${rootUrl}/update-custom-field`,
    updateCustomFieldLength: `${rootUrl}/change-field-max-length`,
    deleteCustomField: `${rootUrl}/delete-custom-field`,
    changeCustomFieldStatus: `${rootUrl}/change-custom-field-status`,
    customFieldAdd: `${rootUrl}/custom-field-add`,
    customFieldCreateOrUpdateApi: `${rootUrl}/custom-field-create-update`,
    changeCustomFieldValue: `${rootUrl}/change-field-value`,
    loadPersonalizedField: `${rootUrl}/load-personalized-field-data`,
    createPersonalizedTag: `${rootUrl}/create-personalized-field-tag`,
    updatePersonalizedFieldValue: `${rootUrl}/update-personalized-field-value`,
    deletePersonalizedTag: `${rootUrl}/delete-personalize-field`,
    createNewPersonalizedField: `${rootUrl}/create-personalized-field`,

    /* lead source api */
    addLeadSource: `${rootUrl}/contact-source-type/form/submit`,
    editLeadSource: `${rootUrl}/contact-source-type/edit/submit`,
    deleteLeadSource: `${rootUrl}/contact-source-type/delete`,
    getLeadSourceByPagination: `${rootUrl}/contact-source-type/list/pagination`,
    getAllLeadSource: `${rootUrl}/contact-source-type/transfer/form`,
    deleteLeadSourceWithoutTransfer: `${rootUrl}/contact-source-type/delete-without-transfer`,

    // Notifications
    getNotifications: `${rootUrl}/get-notifications`,
    getLeadNotifications: `${rootUrl}/get-lead-notifications`,
    changeNotificationStatus: `${rootUrl}/change-notification-status`,
    changeLeadNotificationStatus: `${rootUrl}/change-lead-notification-status`,
    removeNotification: `${rootUrl}/remove-notification`,
    markAllAsSeen: `${rootUrl}/mark-as-all-seen`,
    leadMarkAllAsSeen: `${rootUrl}/lead-mark-as-all-seen`,

    /*backend api for public api token */
    getPublicApiToken: `${backEndBaseUrlForPublicApi}kongconfigs/keys`,
    createOrGeneratePublicApiToken: `${backEndBaseUrlForPublicApi}kongconfigs/keys`,
    getPublicApiList: `${backEndBaseUrlForPublicApi}kongconfigs/route`,

    /* for  */
    getUserCallRecordngSetting: `${rootUrl}/get/user/call-recording/setting`,
    updateUserCallRecordngSetting: `${rootUrl}/update/user/call-recording/setting`,

    /* Notify Account */

    saveNotifyAccount: `${rootUrl}/notify-account/save`,
    notifyAccountList: `${rootUrl}/notify-account/list`,
    deleteNotifyAccount: `${rootUrl}/notify-account/delete`,
    /* for getting user account data */
    getUserAccountData: `${containerUrl}/account-data`,

    /* a2p brand list apis */
    getUserA2pBrandList: `${containerUrl}/user/get-a2p-brand-list`,

    /* map integration api */
    getMapIntegrationPackages: `${rootUrl}/get/map/integration/packages`,
    hasMapIntegration: `${rootUrl}/has/map/integration`,
    submitMapIntegration: `${rootUrl}/submit/map/integration`,
    getAgencySettings: `${rootUrl}/get/agency/settings`,
    getTeamUserList: `${rootUrl}/get/external/package/team/user/list`,
    updateUserExternalServiceAccess: `${rootUrl}/update/user/external/service/access`,
    getUserSelectedPackage: `${rootUrl}/get/user/selected/package`,
    mapYourSalesForceLogin: `${rootUrl}/outside/force/login`,
    externalServiceLoginCheck: `${rootUrl}/outside/map-user-exists`,
    mysDowngradePackage: `${rootUrl}/mys/downgrade-package`,
    mysUpgradePackage: `${rootUrl}/mys/upgrade-package`,

    mysCancelDowngradeRequest: `${rootUrl}/mys/cancel-downgrade-request`,
    getCompanyLogo: `${rootUrl}/mys/get-company-logo`,
    viewUserMapFeature: `${rootUrl}/mys/view-user-map-feature`,

    /* custom field v2 */
    getCustomFieldDataV2: `${rootUrl}/get/user-custom-field-v2`,
    reorderCustomFieldV2: `${rootUrl}/reorder/user-custom-field-v2`,
    updateCustomFieldDataV2: `${rootUrl}/update/custom-field-info`,

    deleteOrderById: `${rootUrl}/map-order/delete`,

    /* custom field reactor api v2 */
    getCustomFieldFolderV2: `${rootUrl}/get-custom-field-folder-v2`,
    getCustomFieldsV2: `${rootUrl}/get-custom-fields-v2`,
    addCustomFieldsV2: `${rootUrl}/add-custom-field-v2`,
    deleteCustomFieldsV2: `${rootUrl}/delete-custom-field-folder-v2`,

    addToPowerDialerMapContact: `${tenantServerPowerDialer}/map-orders/add-to-power-dialer`,
    getPowerDialerVirtualNumbers: `${tenantServerPowerDialer}/virtual/numbers`,
    getPowerDialerLists: `${tenantServerPowerDialer}/all/lists`,

    /* power dialer related */
    createPowerDialerFromOutside: `${tenantServerPowerDialer}/list/created-from-outside`,

    // contact permission requests
    getLeadPermissionRequests: `${contactUrl}/contact-permission/get-all-contact-requests`,
    rejectContactRequest: `${contactUrl}/contact-permission/reject-contact-request`,
    acceptContactRequest: `${tenantBackendV1}/contact-permission/accept-permission-contact-request`,
    subUserListForContactDropdown: `${contactUrl}/sub-users-optimized`,
    bulkActionContactPermissionRequest: `${tenantBackendV1}/contact-permission/bulk-action-permission-request`,

    // view control
    getViewControlsByModule: `${rootUrl}/view-control/get-module-info`,
    updateViewControl: `${rootUrl}/view-control/update`,
    saveCustomMenu: `${rootUrl}/custom-menu/save-custom-menu`,
    getUserCustomMenus: `${rootUrl}/custom-menu/get-user-custom-menus`,
    deleteCustomMenu: `${rootUrl}/custom-menu/delete-user-custom-menu`,
    getContactLookupUsagesReport: `${lookupBackendV1}/lookup/reports/user/usages`,
    getA2pTrustCenterStatus: `${rootUrl}/a2p-trust-center/get-status`,
    cancelGeofarming: `${rootUrl}/cancel-geofarming`,

    getLookupSettings: `${rootUrl}/get-lookup-settings`,
    updateLookupSettings: `${rootUrl}/update-lookup-settings`,

    // invoice settings
    getUserPaymentCredential: `${rootUrl}/user-payment-credentials/get-user-payment-credential`,
    saveUserPaymentCredential: `${rootUrl}/user-payment-credentials/save-user-payment-credential`,
    changeStatusUserPaymentCredential: `${rootUrl}/user-payment-credentials/change-status-user-payment-credential`,


    getUserSmartWebhooksList: `${smartWebhookBackendV1}/smartwebhookstats/list`,
    getUserSmartWebhooksHistoryList: `${smartWebhookBackendV1}/smartwebhooks/histories`,

    getUserA2PRegistrationType: `${containerUrl}/get-user-a2p-registration-type`,
    getCreditSettings: `${contactUrl}/carrier-lookup/get-credit-settings`,
    addContactWithSkipTrace: `${rootUrl}/map-orders/add-contact-with-skip-trace`,

    //invoice module
    saveCompanyInfoInvoice: `${invoiceBackend}/invoice-builder/setting/update/default/data`,
    getCompanyInfoInvoice: `${invoiceBackend}/invoice-builder/setting/get/default/data`,
    addNewProductInvoice: `${invoiceBackend}/invoice-builder/item/create`,
    editProductInvoice: `${invoiceBackend}/invoice-builder/item/update`,
    getProductInvoice: `${invoiceBackend}/invoice-builder/item/lists`,
    deleteProductInvoice: `${invoiceBackend}/invoice-builder/item/delete`,

    //  Taxes Module
    getTaxInvoice: `${invoiceBackend}/invoice-builder/tax/list`,
    addNewTaxInvoice: `${invoiceBackend}/invoice-builder/tax/create`,
    editTaxInvoice: `${invoiceBackend}/invoice-builder/tax/update`,
    deleteTaxInvoice: `${invoiceBackend}/invoice-builder/tax/delete`,

    getUserBrandInfo: `${a2pRegistrationBackend}/a2p-registration/get-user-brand-info`,
    getUserBrandInfoForUpdate: `${a2pRegistrationBackend}/a2p-registration/get-user-brand-info-for-update-brand`,
    // addEmailTemplateInvoice
    getEmailTemplate: `${invoiceBackend}/invoice-builder/default/email/template`,
    addEmailTemplateInvoice: `${invoiceBackend}/invoice-builder/default/email/template/save`,

    getSmsTemplate: `${invoiceBackend}/invoice-builder/default/sms/template`,
    addSmsTemplate: `${invoiceBackend}/invoice-builder/default/sms/template/save`,

    // Reminder Module
    getReminderInvoice: `${invoiceBackend}/invoice-builder/reminder/list`,
    addNewReminderInvoice: `${invoiceBackend}/invoice-builder/reminder/create`,
    editReminderInvoice: `${invoiceBackend}/invoice-builder/reminder/update`,
    deleteReminderInvoice: `${invoiceBackend}/invoice-builder/reminder/delete`,

    getSystemDefaultInfo: `${invoiceBackend}/invoice-builder/invoice/system/default/info`,
    getPersonalizeTags: `${invoiceBackend}/invoice-builder/personalize/tag/list`,

    carrierLookupMap: `${lookupBackendV1}/carrier/lookup/map`,

    // AppList
    addNewApp: `${appListApi}/applications`,
    getAllAppList: `${appListApi}/applications`,
    requestAuthCode: `${appListApi}/request-auth-code`,
    deleteApp: `${appListApi}/applications/`,
    getAppInfo: `${appListApi}/applications/`,

    // map order v2
    getMapListContacts: `${mapListV2Url}/get-map-list-contacts`,
    skipTraceRequest: `${mapListV2Url}/skip-trace-request`,

    mysBuyNewSubscription: `${rootUrl}/mys/buy/new-user-subscription`,
    mysUpgradeSubscription: `${rootUrl}/mys/upgrade/user-subscription`,
    mysDowngradeSubscription: `${rootUrl}/mys/downgrade/user-subscription`,
    hasMapIntegrationV2: `${rootUrl}/mys/has/integration`,
    updateDataAfterMapSkipTrace: `${mapListV2Url}/update-data-after-map-skip-trace`,


    //Calendar Sync

    calendarSyncData: `${rootUrl}/email-settings/calendar-list`,
    calendarSyncDataDelete: `${rootUrl}/email-settings/calendar-delete`,
    emailList: `${rootUrl}/email-settings/email-list-dropdown`,
    emailCalendarList: `${rootUrl}/email-settings/email-calendar-list`,


    getUserConnectedEmailList: `${rootUrl}/user-connected-emails`,
    userConnectedEmailListLimit: `${tenantBackendV1}/user-connected-emails`,
    markEmailAsDefault: `${rootUrl}/user-connected-emails/make-default`,
    deleteUserConnectedEmail: `${rootUrl}/user-connected-emails/delete`,
    disconnectUserConnectedEmail: `${rootUrl}/user-connected-emails/disconnect`,
    connectNylas: `${rootUrl}/email-settings/nylas-connect-url`,
    getUserConnectedEmailReport: `${rootUrl}/user-connected-emails/reports`,
    openAIApiKey: `${rootUrl}/third-party-credentials/open-ai`,
    contactStageSearch: `${java17CoreApiV1}/contact-stage/search`,

    googleAccountApi: `${rootUrl}/third-party-credentials/access-account-list`,
    deleteGoogleAccountApi: `${rootUrl}/third-party-credentials/access-account-remove`,
    contactExport: `${java17CoreApiV1}/contact-stage/export`,

    //products

    productFolders: `${dealUrl}/user-product-folders`,
    userProducts: `${dealUrl}/user-products`,
    contactStageProducts: `${dealUrl}/user-contact-stage-products/contact-stage-products`,
    updateSuccessDeal: `${dealUrl}/user-contact-stage-products/update-success-deal`,


    // sales goal    

    addSalesGoal: `${tenantBackendV1}/user-sale-goals`,
    updateSalesGoal: `${tenantBackendV1}/user-sale-goals`,
    deleteSalesGoal: `${tenantBackendV1}/user-sale-goals`,
    getAllSalesGoalList: `${tenantBackendV1}/user-sale-goals`,
    userSaleGoalStats: `${tenantBackendV1}/user-sale-goals/stats`,
    selesVsGoalReport: `${tenantBackendV1}/user-sale-goals/report`,

    teamSalesReport: `${java17CoreApiV1}/reports/team-sales`,
    productSalesReport: `${java17CoreApiV1}/reports/product-sales`,
    productSalesContact: `${java17CoreApiV1}/reports/product-sold-contacts`,


    //product sales export with email
    productSalesExport: `${java17CoreApiV1}/reports/product-report/export`,
    teamWiseSoldProducts: `${java17CoreApiV1}/reports/user-wise-sold-products`,
    teamWiseSoldProductExport: `${java17CoreApiV1}/reports/user-wise-sold-products/export`,

    getTeamUserListV2: `${calendarUrl}/get-team-users`,
    getAllPipeLine: `${dealUrl}/select-all-pipeline-except`,

    userPolicy: `${dealUrl}/user-policies`,
    userPolicyCategory: `${dealUrl}/user-policy-folders`,
    userInsuranceCompany: `${tenantBackendV1}/insurance-company`,
    userInsuranceCompanyCategory: `${tenantBackendV1}/insurance-company/folders`,
    insuranceStatus: `${tenantBackendV1}/insurance-company/status`,
    insuranceBulkStatus: `${tenantBackendV1}/insurance-company/bulk-status`,
    insuranceBulkDelete: `${tenantBackendV1}/insurance-company/bulk-delete`,
    insuranceAddBulkFolder: `${tenantBackendV1}/insurance-company/assign-to-folders`,
    policyBulkStatus: `${dealUrl}/user-policies/bulk-update`,
    policyBulkDelete: `${dealUrl}/user-policies/bulk-delete`,

    lostReason: `${tenantBackendV1}/deal/lost-reason`,
    recycleEvent: `${tenantBackendV1}/deal/recycle-event`,

    // call logs
    getCallLog: `${reportUrl}/get-call-logs`,
    callOutcomeListForCallLogDropdown: `${reportUrl}/get-call-outcome-list`,
    getUserAndVirtualNumberArray: `${reportUrl}/get-user-virtual-number-data`,
    getOutgoingCallCount: `${reportUrl}/outgoing-call-list-by-date`,
    getOutgoingCallOutcome: `${reportUrl}/outgoing-call-outcome-list-by-date`,
    markStepComplete: `${insuranceOnboardUrl}/mark-step-complete`,

    getUserCredit: `${tenantBackendV1}/users/current-credit`,

    subUserFormData: `${tenantBackendV1}/users/sub-user/form-data`,
    userCustomFieldFolder: `${tenantBackendV1}/user-custom-fields/groups`,
    userCustomField: `${tenantBackendV1}/user-custom-fields`,
    userCustomFieldStatus: `${tenantBackendV1}/user-custom-fields/status`,
    userCustomFieldGroupChange:`${tenantBackendV1}/user-custom-fields/change-group`,
    userCustomFieldOrderChange:`${tenantBackendV1}/user-custom-fields/change-order`,
    userMergeFieldFolder: `${tenantBackendV1}/user-personalized-fields/groups`,
    userMergeField: `${tenantBackendV1}/user-personalized-fields`,
    userMergeFieldPreviewData: `${containerUrl}/user-custom-personalized-fields`,
}


export default Api;