import { useEffect, useRef } from "react";

const useDidUpdateEffect = (fn, inputs) => {
    const isMountingRef = useRef(false);

    useEffect(() => {
        isMountingRef.current = true;
    }, []);

    useEffect(() => {
        if (!isMountingRef.current) {
            return fn();
        } else {
            isMountingRef.current = false;
        }
    }, inputs); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdateEffect;