// Import the modified functions from rootApi.js
import { sendRequest,sendRequestWithBearerToken } from "./rootApi";
import Api from "../constants/Api";


export const getUserPaymentCredential = async (payload) => {
    return await sendRequest("POST", Api.getUserPaymentCredential,payload);
  }
  
  export const saveUserPaymentCredential = async (payload) => {
    return await sendRequest("POST", Api.saveUserPaymentCredential, payload);
  }
  export const changeStatusUserPaymentCredential = async(payload)=>{
    return await sendRequest("POST",Api.changeStatusUserPaymentCredential,payload);
  }
  export const saveCompanyInfoInvoice = async (body) => {
    return await sendRequestWithBearerToken("POST", Api.saveCompanyInfoInvoice, body);
  }
  export const getCompanyInfoInvoice = async () => {
    return sendRequestWithBearerToken("GET", Api.getCompanyInfoInvoice);
  }
  
  export const addNewProductInvoice = async (body)=>{
    return await sendRequestWithBearerToken("POST",Api.addNewProductInvoice,body );
  }
  export const editProductInvoice = async (body)=>{
    return await sendRequestWithBearerToken("PUT",Api.editProductInvoice,body );
  }
  export const getProductInvoice = async (payload)=>{

    return sendRequestWithBearerToken("GET", Api.getProductInvoice,payload);
  }
  export const deleteProductInvoice = async (body)=>{
    return await sendRequestWithBearerToken("DELETE",Api.deleteProductInvoice,body);
  }

  // Taxes Module Apis
  export const getTaxesInvoice = async (payload)=>{
    return sendRequestWithBearerToken("GET", Api.getTaxInvoice,payload);
  }
  export const addNewTaxInvoice = async (body)=>{
    return await sendRequestWithBearerToken("POST",Api.addNewTaxInvoice, body );
  }
  export const editTaxInvoice = async (body)=>{
    return await sendRequestWithBearerToken("PUT",Api.editTaxInvoice, body );
  }
  export const deleteTaxInvoice = async (body)=>{
    return await sendRequestWithBearerToken("DELETE",Api.deleteTaxInvoice,body);
  }
  
  // Invoice Reminder
  export const getReminderInvoice = async (payload)=>{
    return sendRequestWithBearerToken("GET", Api.getReminderInvoice,payload);
  } 
  export const addNewReminderInvoice = async (body)=>{
    return await sendRequestWithBearerToken("POST",Api.addNewReminderInvoice, body );
  }
  export const editReminderInvoice = async (body)=>{
    return await sendRequestWithBearerToken("PUT",Api.editReminderInvoice, body );
  }
  export const deleteReminderInvoice = async (body)=>{
    return await sendRequestWithBearerToken("DELETE",Api.deleteReminderInvoice,body);
  }

  // Email/Text Templates
  export const getEmailTemplate = async (payload)=>{
    return sendRequestWithBearerToken("GET", Api.getEmailTemplate,payload);
  }
  export const addEmailTemplateInvoice = async (body)=>{
    return await sendRequestWithBearerToken("POST",Api.addEmailTemplateInvoice,body );
  }
  export const getSmsTemplate = async (payload)=>{
    return sendRequestWithBearerToken("GET", Api.getSmsTemplate,payload);
  }
  export const addSmsTemplate = async (body)=>{
    return await sendRequestWithBearerToken("POST",Api.addSmsTemplate,body );
  }

  export const getSystemDefaultInfo = async ()=>{
    return sendRequestWithBearerToken("GET", Api.getSystemDefaultInfo);
  }

  export const getPersonalizeTags = async ()=> {
    return sendRequestWithBearerToken("GET", Api.getPersonalizeTags);
  }

