import { makeStyles } from "@material-ui/core";

export const permissionDeniedModalStyle = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus-visible": {
      outline: "none !important",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(1),
    "&:focus-visible": {
      outline: "none !important",
    },
  },
  bodyWrapper: {
    padding: "20px",
    textAlign: "center",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "400px",
    color: "var(--dark_blue)",
    "&: focus-visible": {
      outline: "none !important",
    },
  },
  closeButton: {
    backgroundColor: "var(--light_blue)",
    "&:focus, &:hover": {
      backgroundColor: "var(--light_blue)",
    },
  },
}));