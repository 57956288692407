import React from 'react';
import BasicModal from "./BasicModal";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import {Divider, IconButton, Typography} from "@material-ui/core";
import modalUseStyles from "./modalUseStyles";

const BasicModalWithHeader = ({title, open, onClose, width = "auto", children}) => {
    const classes = modalUseStyles();

    return (
        <BasicModal open={open}>
            <Box sx={{maxWidth: width}}>
                <Box className={classes.modalHeader}>
                    <Typography variant={"h5"}>{title}</Typography>
                    <IconButton size={"small"} onClick={onClose}>
                        <CloseIcon color="error" />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{maxHeight: "80vh", overflowY: "auto", p: 3}}>
                    {children}
                </Box>
            </Box>
        </BasicModal>
    );
};

export default BasicModalWithHeader;