export const ITEM_UNIT = {
  BOX: "BOX",
  PIECE: "PIECE",
  DOZEN: "DOZEN",
  INCH: "INCHI",
  FOOT: "FEET",
  CM: "CM",
  METER: "METER",
  KM: "KM",
  MG: "MG",
  GRAM: "GRAM",
  KG: "KG",
  LB: "LB",
  HOUR: "HOUR",
};




export const ITEM_TYPE = {
  SERVICE: "SERVICE",
  PRODUCT: "PRODUCT",
};


export const ITEM_TYPE_OPTION = [
  { label: "Service", value: ITEM_TYPE.SERVICE },
  { label: "Product", value: ITEM_TYPE.PRODUCT }
];

export const ITEM_UNIT_OPTION = [ 
  { label: "Box (box)", unit: "box", value: ITEM_UNIT.BOX },
   { label: "Piece (pcs)", unit: "pcs", value: ITEM_UNIT.PIECE },
   { label: "Dozen (dozen)", unit: "dozen", value: ITEM_UNIT.DOZEN },
 { label: "Inches (inch)", unit: "inch", value: ITEM_UNIT.INCH },
 { label: "Foot (ft)", unit: "ft", value: ITEM_UNIT.FOOT } ,
 { label: "Centimeter (cm)", unit: "cm", value: ITEM_UNIT.CM },
   { label: "Meter (m)", unit: "m", value: ITEM_UNIT.METER },
    { label: "Kilometer (km)", unit: "km", value: ITEM_UNIT.KM },
     { label: "Milligram (mg)", unit: "mg", value: ITEM_UNIT.MG },
 { label: "Gram (g)", unit: "g", value: ITEM_UNIT.GRAM },
  { label: "Kilogram (kg)", unit: "kg", value: ITEM_UNIT.KG },
   { label: "Pound (lb)", unit: "lb", value: ITEM_UNIT.LB },
 { label: "Hour (hr)", unit: "hr", value: ITEM_UNIT.HOUR },
];