import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUser } from "../../actions/profileAction";
import { deleteOrderById, getMapOrdersList } from "../../api/profileApi";
import { HistoryProvider } from "../../App";
import { getCookie } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import { useIsMounted } from "../../hooks/IsMounted";
import GlobalSearchBox from "../globals/GlobalSearchBox";
import CustomConfirmAlert from "../team/CustomConfirmAlert";
import './MapOrderList.css';
import MapOrderSkeleton from "./MapOrderSkeleton";
import MapSkeleton from "./MapSkeleton";
import TitlePopup from "./TitlePopup";

const MapOrderList = (props) => {

    const isMounted = useIsMounted();
    const history = useContext(HistoryProvider);;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = 10;
    const [sortType, setSortType] = useState('desc');
    const [sortBy, setSortBy] = useState('created_at');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [query, setQuery] = useState('');
    const [reset, setReset] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        document.title = `Map order | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('map_finder');
        }
        if (!props.user) {
            props.getUser({
                'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
            });
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        loadData();
    }, [currentPage, reset, perPage, sortType, debouncedQuery]); // eslint-disable-line react-hooks/exhaustive-deps


    // debouncing
    useEffect(() => {
        if (isMounted) {
            setCurrentPage(0)
            const timer = setTimeout(() => {
                setDebouncedQuery(query);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps


    const loadData = () => {
        // setLoading(true);

        getMapOrdersList({
            "form_data": {
                page: currentPage + 1,
                per_page: perPage,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery,
                action_from:"from_map_finders"
            }
        })
        .then(response => {
            setLoading(false);
            if(response.data.status === "success"){
                if(debouncedQuery === ''){
                    let datas = []
                    if(response.data.data.length === perPage){
                        setHasMore(true)
                    }else{
                        setHasMore(false)
                    }
                    if(currentPage === 0){
                        datas = [...response.data.data];
                    }else{
                        datas = [...data,...response.data.data];
                    }
                    setData(datas);
                }else{
                    let datas = []
                    if(response.data.data.length === perPage){
                        setHasMore(true)
                    }else{
                        setHasMore(false)
                    }
                    if(currentPage === 0){
                        datas = [...response.data.data];
                    }else{
                        datas = [...data,...response.data.data];
                    }
                     datas = [...response.data.data];
                    setData(datas);
                }
              
                // setTotal(response.data.data.total);
            }
        }).finally(() => {
            setLoading(false);
            setIsLoadMore(false)
        });
    }

    const loadMoreData = () => {
        if(hasMore && !isLoadMore){
            setIsLoadMore(true)
            setCurrentPage(currentPage+1)
        }
    }

    const deleteOrderItem = (orderId) => {
        CustomConfirmAlert({
            handleConfirm: () => {
                setLoading(true);
                deleteOrderById({
                    "form_data": {
                        order_id: orderId
                    }
                })
                    .then(response => {
                        let updatedData = data.filter(item => item.id !== orderId);
                        setData(updatedData);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            },
            callConfirmLast: true,
            title: 'Are you sure?',
            description: 'Do you want to delete this order?',
            type_word: 'DELETE MAP ORDER',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Yes'
        });
    };

    const renderData = () => {  
        if (loading) {
            return (
                <tr>
                    <td colspan="7">  
                     <MapOrderSkeleton/>
                    </td>
                </tr>
            )
        } else if (!loading && !data.length) {
            return (
                <tr>
                    <td colspan="7">
                        <div style={{paddingTop: 50}}>
                            NO DATA AVAILABLE
                        </div>
                    </td>
                </tr>
            )
        } else if (!loading && data.length) {
            return data.map((item,index) => {
                let status = 'pending';
                let title = item.order_no;

                if (typeof item.title !== 'undefined' && item.title) {
                    title = item.title;
                }

                if (item.status === 1) {
                    status = 'pending';
                } else if (item.status === 3) {
                    status = 'completed';
                } else if (item.status === 2) {
                    status = 'completed';
                }

                return (
                    <tr class={status}>
                        <td>
                            <svg className="pend" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.961 29.9727C23.2086 29.9727 29.8946 23.2867 29.8946 15.0391C29.8946 6.79148 23.2086 0.105469 14.961 0.105469C6.71336 0.105469 0.0273438 6.79148 0.0273438 15.0391C0.0273438 23.2867 6.71336 29.9727 14.961 29.9727Z" fill="#FFB053"></path>
                                <path d="M14.4051 12.9952C14.4051 13.9244 14.4317 14.8536 14.3918 15.7828C14.3786 16.1943 14.5379 16.42 14.8963 16.5926C16.0246 17.1634 17.1263 17.7474 18.2281 18.3581C18.9317 18.743 19.2635 19.3536 19.1706 20.1766C19.0909 20.8935 18.5069 21.6235 17.7502 21.7297C17.3255 21.7961 16.821 21.7032 16.4361 21.5041C14.8564 20.7076 13.3166 19.8581 11.7635 19.0218C11.06 18.6368 10.7148 18.0527 10.7148 17.243C10.7281 14.9067 10.7148 12.5572 10.7148 10.2209C10.7148 9.0262 11.3918 8.33594 12.5334 8.33594C13.6617 8.33594 14.3653 9.03948 14.3786 10.2076C14.4184 11.1368 14.4051 12.066 14.4051 12.9952Z" fill="white"></path>
                            </svg>
                            <svg className="comp" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 6.11738e-05C23.2843 6.11738e-05 30 6.7158 30 15C30 23.2843 23.2843 30 15 30C6.7158 30 6.2218e-05 23.2843 6.2218e-05 15C-0.0235101 6.73929 6.65397 0.0236335 14.9146 6.11738e-05C14.9431 -2.03913e-05 14.9716 -2.03913e-05 15 6.11738e-05Z" fill="#3BB54A"></path>
                                <path d="M23.3107 10.8668L12.6573 21.5202L6.69141 15.5969L9.12041 13.2106L12.6573 16.7049L20.8818 8.48047L23.3107 10.8668Z" fill="white"></path>
                            </svg>																											
                            {title}
                            <a href="#order_modal" className="edit modal-trigger" onClick={(e) => {
                                e.preventDefault();
                                setDataToEdit(item);
                                setModalVisible(true);
                            }}>
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.83963 2.91356L13.8402 6.91425L5.15319 15.6016L1.58641 15.9953C1.10892 16.0482 0.705497 15.6444 0.758621 15.1669L1.15549 11.5975L9.83963 2.91356ZM16.3145 2.31793L14.4361 0.43946C13.8502 -0.146487 12.8999 -0.146487 12.3139 0.43946L10.5468 2.20668L14.5473 6.20737L16.3145 4.44015C16.9004 3.85389 16.9004 2.90388 16.3145 2.31793Z" fill="#133159"></path>
                                </svg>											
                            </a>
                        </td>
                        <td>
                            <a href="#!" className="pen" onClick={e => e.preventDefault()} style={{cursor: 'default'}}>Pending</a>
                            <a href="#!" className="com" onClick={e => e.preventDefault()} style={{cursor: 'default'}}>Completed</a>
                        </td>
                        <td>
                            <span>{item.created_at}</span>
                        </td>
                        <td><input type="text" disabled value={item.total_contacts}/></td>
                        <td><input type="text" disabled value={item.processed_contacts}/></td>
                        <td><input type="text" disabled value={item.total_added_contacts}/></td>
                        <td className="action__type">
                            <Link to={`/user/map-orders/v2/${item.id}`} className="view">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.1388 13.526L10.323 11.7091C9.62082 11.9602 8.86179 12.0067 8.13422 11.8432C7.40665 11.6797 6.74047 11.313 6.21317 10.7857C5.68588 10.2584 5.31916 9.59222 5.15568 8.86465C4.99221 8.13708 5.0387 7.37805 5.28975 6.67587L2.97225 4.35838C1.05525 6.06275 0 8 0 8C0 8 3.375 14.1875 9 14.1875C10.0805 14.1837 11.1487 13.9586 12.1388 13.526ZM5.86125 2.474C6.85131 2.04135 7.91954 1.81622 9 1.8125C14.625 1.8125 18 8 18 8C18 8 16.9436 9.93612 15.0289 11.6427L12.7091 9.323C12.9602 8.62082 13.0067 7.86179 12.8432 7.13422C12.6797 6.40665 12.313 5.74047 11.7857 5.21317C11.2584 4.68588 10.5922 4.31916 9.86465 4.15568C9.13708 3.99221 8.37805 4.0387 7.67587 4.28975L5.86125 2.47512V2.474Z" fill="#133159"></path>
                                    <path d="M6.21544 7.60156C6.15355 8.03391 6.19321 8.47473 6.33127 8.88909C6.46933 9.30345 6.70199 9.67997 7.01083 9.9888C7.31966 10.2976 7.69617 10.5303 8.11053 10.6684C8.52489 10.8064 8.96571 10.8461 9.39806 10.7842L6.21431 7.60156H6.21544ZM11.7842 8.39806L8.60156 5.21431C9.03391 5.15243 9.47473 5.19209 9.88909 5.33015C10.3035 5.4682 10.68 5.70087 10.9888 6.0097C11.2976 6.31853 11.5303 6.69505 11.6684 7.10941C11.8064 7.52377 11.8461 7.96459 11.7842 8.39694V8.39806ZM15.3516 15.1481L1.85156 1.64806L2.64806 0.851562L16.1481 14.3516L15.3516 15.1481Z" fill="#133159"></path>
                                </svg>											
                                <span>View</span>
                            </Link>
                            <a href="#" onClick={(e)=>{
                                 e.preventDefault();
                                 deleteOrderItem(item.id)
                            }}>
                              <span className="completed__task__btn" style={{backgroundColor:"red",color:"white"}} title="Delete Order">Delete</span>
                            </a>
                        </td>
                        
                    </tr>
                )
            })
        }
    }

    return (
        <Fragment>
            <div className="maporder_section">
                {
                    !props.user ? (
                        <MapSkeleton/>
                    ) : (
                        <Fragment>
                        <div className="page_heading">
                            <div className="title">
                                <h4>Map Orders</h4>
                                <p>**The leads below will be deleted in <span>2 hours</span>. If you wish to keep them you can add them as a contact or download the list as a csv file.</p>
                            </div>
                            <div className="action">
                                <a href="#!" className="refresh" onClick={(e) => {
                                    e.preventDefault();
                                    setReset(reset + 1);
                                }}>
                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.8271 1.25633C13.7117 1.14102 13.5749 1.08317 13.4169 1.08317C13.2589 1.08317 13.1223 1.14102 13.0067 1.25633L11.8219 2.43229C11.1718 1.8185 10.4273 1.34292 9.58883 1.00576C8.75041 0.668564 7.88732 0.5 7.00013 0.5C5.32905 0.5 3.87078 1.00579 2.62506 2.01759C1.37932 3.02926 0.559038 4.34923 0.164062 5.97782V6.04157C0.164062 6.12055 0.192891 6.18897 0.250643 6.24662C0.308395 6.30438 0.376715 6.33321 0.455697 6.33321H2.26948C2.40322 6.33321 2.4943 6.26329 2.54295 6.12362C2.79813 5.51602 2.95909 5.16057 3.02601 5.05723C3.45131 4.36452 4.01642 3.82065 4.72132 3.42567C5.42629 3.03073 6.18582 2.83317 7 2.83317C8.22148 2.83317 9.28177 3.24944 10.1812 4.08192L8.92313 5.33967C8.80797 5.45514 8.75006 5.59194 8.75006 5.74994C8.75006 5.9079 8.80794 6.04464 8.92313 6.16008C9.03873 6.27555 9.1755 6.33324 9.33349 6.33324H13.4169C13.5749 6.33324 13.7117 6.27555 13.8271 6.16008C13.9424 6.04464 14.0001 5.90793 14.0001 5.74994V1.66651C14.0001 1.50851 13.9428 1.37184 13.8271 1.25633Z" fill="#133159" fill-opacity="0.65"></path>
                                        <path d="M13.4804 8.66835H11.7304C11.5967 8.66835 11.5056 8.73817 11.4571 8.87797C11.202 9.48563 11.0408 9.84092 10.9741 9.94426C10.5487 10.6368 9.98352 11.1809 9.27881 11.5758C8.57381 11.9709 7.81428 12.1682 7.00006 12.1682C6.4107 12.1682 5.8425 12.0589 5.29568 11.8403C4.74881 11.6213 4.25959 11.3114 3.82822 10.9103L5.07697 9.66163C5.19238 9.54629 5.25013 9.40939 5.25013 9.25149C5.25013 9.09353 5.19238 8.95663 5.07697 8.84135C4.96149 8.72578 4.82482 8.66797 4.66683 8.66797H0.583397C0.425305 8.66797 0.28857 8.72578 0.173129 8.84135C0.0576884 8.95667 0 9.09356 0 9.25152V13.335C0 13.4928 0.0576884 13.6297 0.173161 13.7451C0.288698 13.8603 0.425432 13.9184 0.583429 13.9184C0.741394 13.9184 0.878065 13.8604 0.993569 13.7451L2.16933 12.5692C2.81955 13.1889 3.55941 13.666 4.38886 14.0002C5.2183 14.3345 6.0766 14.5014 6.9637 14.5014C8.62875 14.5014 10.081 13.9956 11.3205 12.9839C12.5601 11.9723 13.3744 10.652 13.7634 9.02361C13.7695 9.01148 13.7724 8.99019 13.7724 8.95992C13.7724 8.88081 13.7435 8.81269 13.6857 8.75474C13.6279 8.69731 13.5595 8.66835 13.4804 8.66835Z" fill="#133159" fill-opacity="0.65"></path>
                                    </svg>								
                                    Refresh
                                </a>
                                <a href="/map-finder" onClick={e => {e.preventDefault();history.push('/map-finder')}} className="order">
                                    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="10" cy="10.4754" r="10" fill="white"></circle>
                                        <path d="M13.8461 10.4754C13.8461 10.8299 13.8153 11.1165 13.4609 11.1165H10.641V13.9363C10.641 14.2902 10.3544 14.3216 9.99996 14.3216C9.64548 14.3216 9.35894 14.2902 9.35894 13.9363V11.1165H6.53906C6.18522 11.1165 6.15381 10.8299 6.15381 10.4754C6.15381 10.1209 6.18522 9.8344 6.53906 9.8344H9.35894V7.01453C9.35894 6.66004 9.64548 6.62927 9.99996 6.62927C10.3544 6.62927 10.641 6.66004 10.641 7.01453V9.8344H13.4609C13.8153 9.8344 13.8461 10.1209 13.8461 10.4754Z" fill="#3C7EF3"></path>
                                    </svg>
                                    New Order
                                </a>
                            </div>
                        </div>
                        <div className="table_heading">
                            <div className="search_form">
                                <GlobalSearchBox
                                    wrapperClass="search-box-order-list" 
                                    type="search" 
                                    autocomplete="off" 
                                    placeholder="Search Order" 
                                    onChange={(e) =>{
                                        setQuery(e.target.value)
                                    }}/>
                            </div>
                        </div>

                        <div className="responsive-table">
                            <table className="data_table">
                                <thead>
                                    <tr>
                                        <th>
                                            Map Order Name
                                            <svg 
                                            onClick={() => {
                                                setSortBy('title');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th>
                                            Status
                                            <svg 
                                            onClick={() => {
                                                setSortBy('status');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th>
                                            Created
                                            <svg 
                                            onClick={() => {
                                                setSortBy('created_at');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th>
                                            Total Fetched Contacts
                                            <svg 
                                            onClick={() => {
                                                setSortBy('total_contacts');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th>
                                            Total Processed Contacts
                                            <svg 
                                            onClick={() => {
                                                setSortBy('processed_contacts');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th>
                                            Total Added Contacts
                                            <svg 
                                            onClick={() => {
                                                setSortBy('total_added_contacts');
                                                if (sortType === 'asc') {
                                                    setSortType('desc');
                                                } else {
                                                    setSortType('asc');
                                                }
                                            }}
                                            width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
                                                <path d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z" fill="#546376"></path>
                                            </svg>												
                                        </th>
                                        <th style={{"textAlign":'center'}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>

                        <div className="table_footer">
                            <div className="mt-3 pagination__button__section__area">
                                {hasMore && 
                                    <button className="btn btn-primary" onClick={loadMoreData}>{isLoadMore ? "Loading" : "Load"} More</button>
                                    
                                }
                                {
                                     currentPage>0 &&
                                    <button className="btn btn-primary refresh__button__for__pagination" onClick={()=>setCurrentPage(0)}>Refresh</button>
                                }
                               
                                {/* <TablePagination
                                    component="div"
                                    count={total}
                                    page={currentPage}
                                    onChangePage={handleChangePage}
                                    rowsPerPage={perPage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                /> */}
                            </div>
                        </div>
                    </Fragment>
                    )
                }
			</div>
            <TitlePopup
                open={modalVisible}
                hideModal={(title) => {
                    let oldData = [...data];
                    let objIndex = oldData.findIndex((obj => obj.id == dataToEdit.id));
                    oldData[objIndex] = {...dataToEdit,title:title}
                    setData(oldData)
                    // setReset(reset + 1);
                    setDataToEdit(null);
                    setModalVisible(false);
                }}
                title={'Order Name'}
                customClassName="map__order__name__modal__wrapper"
                data={dataToEdit}
            />
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapOrderList);