import {useEffect, useState} from "react"
import {checkDuplicateSnapShotItems} from "../../api/profileApi";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const Step3 = ({handleBack, handleStep, globalTemplatesObj, snapShotDetailsInStep3, subUser, activeSnapShot}) => {


    const [overWriteTempConflict, setOverWriteTempConflict] = useState("0");
    const [isConflict, setIsConflict] = useState(false);
    const [snapShotDetailsAfterConflict, setSnapShotDetailsAfterConflict] = useState(snapShotDetailsInStep3);
    const [conflictTemplates, setConflictTemplates] = useState({});
    const [assetSelector, setAssetSelector] = useState({});

    /*campaign temp states start*/
    const [campaignTempValue, setCampaignTempValue] = useState("0");
    const [campaignTempOptionValue, setCampaignTempOptionValue] = useState({});
    const [campaignLoaded, setCampaignLoaded] = useState(false);
    const [showCampaignConflict, setShowCampaignConflict] = useState(true);
    /*campaign temp states end*/

    /*custom field temp states start*/
    const [customFieldTempValue, setCustomFieldTempValue] = useState("0");
    const [customFieldTempOptionValue, setCustomFieldTempOptionValue] = useState({});
    const [customFieldLoaded, setCustomFieldLoaded] = useState(false);
    const [customFieldConflict, setCustomFieldConflict] = useState(true);
    /*custom field temp states end*/

    /*personalized field temp states start*/
    const [personalizedFieldTempValue, setPersonalizedFieldTempValue] = useState("0");
    const [personalizedFieldTempOptionValue, setPersonalizedFieldTempOptionValue] = useState({});
    const [personalizedFieldLoaded, setPersonalizedFieldLoaded] = useState(false);
    const [personalizedFieldConflict, setPersonalizedFieldConflict] = useState(true);
    /*personalized field temp states end*/

    /*Calendar field states start*/
    const [calendarTempValue, setCalendarTempValue] = useState("1");
    const [calendarTempOptionValue, setCalendarTempOptionValue] = useState({});
    const [calendarFieldLoaded, setCalendarFieldLoaded] = useState(false);
    const [calendarConflict, setCalendarFieldConflict] = useState(true);
    /*Calenda field states end*/

    /*form temp states start, form_info*/
    const [formTempValue, setFormTempValue] = useState("0");
    const [formTempOptionValue, setFormTempOptionValue] = useState({});
    const [formFieldLoaded, setFormFieldLoaded] = useState(false);
    const [formTempConflict, setFormTempConflict] = useState(true);
    /*form temp states end*/

    /*lead flow temp states start, leadflow_info*/
    const [leadFlowTempValue, setLeadFlowTempValue] = useState("0");
    const [leadFlowTempOptionValue, setLeadFlowTempOptionValue] = useState({});
    /*lead flow temp states end*/

    /*Tags temp states start, tag_info*/
    const [tagFieldTempValue, setTagFieldTempValue] = useState("0");
    const [tagFieldTempOptionValue, setTagFieldTempOptionValue] = useState({});
    const [tagFieldLoaded, setTagFieldLoaded] = useState(false);
    const [tagTempConflict, setTagTempConflict] = useState(true);
    /*Tags temp states end*/

    /*Triggers temp states start, trigger_info*/
    const [triggerTempValue, setTriggerTempValue] = useState("0");
    const [triggerTempOptionValue, setTriggerTempOptionValue] = useState({});
    const [triggerLoaded, setTriggerLoaded] = useState(false);
    const [triggerConflict, setTriggerConflict] = useState(true);
    /*Tags temp states end*/

    /*Custom forms temp states start, custom_form_info*/
    const [customFormTempValue, setCustomFormTempValue] = useState("0");
    const [customFormTempOptionValue, setCustomFormTempOptionValue] = useState({});
    const [customFormLoaded, setCustomFormLoaded] = useState(false);
    const [customFormConflict, setCustomFormConflict] = useState(true);
    /*Custom forms states end*/

    /*Quick Replies temp states start, quick_reply_info*/
    const [quickReplyTempValue, setQuickReplyTempValue] = useState("0");
    const [quickReplyTempOptionValue, setQuickReplyTempOptionValue] = useState({});
    const [quickReplyLoaded, setQuickReplyLoaded] = useState(false);
    const [quickReplyConflict, setQuickReplyConflict] = useState(true);
    /*Quick Replies states end*/

    /*Pipelines & Stages temp states start, pipeline_info*/
    const [pipelineTempValue, setPipelineTempValue] = useState("0");
    const [pipelineTempOptionValue, setPipelineTempOptionValue] = useState({});
    const [pipelineConflict, setPipelineConflict] = useState(true);
    /*Pipeline & Stages forms states end*/

    /*Multi page forms temp states start, custom_form_info*/
    const [multiPageFormTempValue, setMultiPageFormTempValue] = useState("0");
    const [multiPageFormTempOptionValue, setMultiPageFormTempOptionValue] = useState({});
    const [multiPageFormLoaded, setMultiPageFormLoaded] = useState(false);
    const [multiPageFormConflict, setMultiPageFormConflict] = useState(true);
    /*Multi page forms states end*/

    const [loaded, setLoaded] = useState(false)

    useEffect(()=>{
        campaignTempGlobalChange();
        customFieldTempGlobalChange();
        formTempGlobalChange();
        leadFlowTempGlobalChange();
        tagFieldTempGlobalChange();
        triggerTempGlobalChange();
        customFormTempGlobalChange();
        quickReplyTempGlobalChange();
        pipeLineTempGlobalChange();
        personalizedFieldTempGlobalChange();
        calendarTempGlobalChange();
        multiPageFormTempGlobalChange();
    }, [snapShotDetailsAfterConflict])

    useEffect(()=>{
        checkSnapShotDuplicates(globalTemplatesObj);
        setSnapShotDetailsAfterConflict(snapShotDetailsInStep3);
    }, []);

    useEffect(()=>{
        setCampaignTempValue(overWriteTempConflict);
        setCustomFieldTempValue(overWriteTempConflict);
        setFormTempValue(overWriteTempConflict);
        setLeadFlowTempValue(overWriteTempConflict);
        setTagFieldTempValue(overWriteTempConflict);
        setTriggerTempValue(overWriteTempConflict);
        setCustomFormTempValue(overWriteTempConflict);
        setQuickReplyTempValue(overWriteTempConflict);
        setPipelineTempValue(overWriteTempConflict);
        setMultiPageFormTempValue(overWriteTempConflict);
    }, [overWriteTempConflict]);

    /*Start campaign temp*/
    useEffect(()=>{
        campaignTempGlobalChange();
    }, [campaignTempValue]);

    const campaignTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.campaign_info !== undefined){
            setCampaignLoaded(false);
            let campaignTemp = JSON.parse(snapShotDetailsAfterConflict.campaign_info);
            const obj = {};
            campaignTemp.forEach(e => {
                obj['camp-'+e['id']] = campaignTempValue;
            })

            setCampaignTempOptionValue({ ...campaignTempOptionValue, ...obj});
            setCampaignLoaded(true);
        }
    }

    const changeCampaignTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'camp-'+itemId;
        setCampaignTempOptionValue({ ...campaignTempOptionValue, [objProperty] : value});
    }

    const snapShotCampaigns = () => {

        let conflictTempInfos = conflictTemplates.campaign_info !== undefined ? conflictTemplates.campaign_info : [];

        if(snapShotDetailsAfterConflict.campaign_info !== undefined){
            let campaignTemp = JSON.parse(snapShotDetailsAfterConflict.campaign_info);

            return campaignTemp.forEach((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = campaignTempOptionValue['camp-'+item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id+'+'+item.title.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={()=>changeCampaignTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                <FormControlLabel onClick={()=>changeCampaignTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }


            })
        }

    }
    /*End campaign temp*/

    /*Start campaign temp*/
    useEffect(()=>{
        calendarTempGlobalChange();
    }, [calendarTempValue]);

    const calendarTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.calendar_info !== undefined){
            setCalendarFieldLoaded(false);
            let calendarTemp = JSON.parse(snapShotDetailsAfterConflict.calendar_info);
            const obj = {};
            calendarTemp.forEach(e => {
                obj['calendar-'+e['id']] = calendarTempValue;
            })

            setCalendarTempOptionValue({ ...calendarTempOptionValue, ...obj});
            setCalendarFieldLoaded(true);
        }
    }

    const changeCalendarTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'calendar-'+itemId;
        setCalendarTempOptionValue({ ...calendarTempOptionValue, [objProperty] : value});
    }

    const snapShotCalendars = () => {

        let conflictTempInfos = conflictTemplates.calendar_info !== undefined ? conflictTemplates.calendar_info : [];

        if(snapShotDetailsAfterConflict.calendar_info !== undefined){
            let calendarTemp = JSON.parse(snapShotDetailsAfterConflict.calendar_info);

            return calendarTemp.forEach((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = calendarTempOptionValue['calendar-'+item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id+'+'+item.title.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={()=>changeCalendarTempIndividualOptionValue(item.id, "0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                <FormControlLabel onClick={()=>changeCalendarTempIndividualOptionValue(item.id, "1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }


            })
        }

    }
    /*End campaign temp*/

    /*Start custom field temp*/
    useEffect(()=>{
        customFieldTempGlobalChange();
    }, [customFieldTempValue]);

    const customFieldTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.custom_field_info !== undefined){
            setCustomFieldLoaded(false);
            let customFieldTemp = JSON.parse(snapShotDetailsAfterConflict.custom_field_info);
            const obj = {};
            customFieldTemp.forEach(e => {
                obj['custom-field-'+e['id']] = customFieldTempValue;
            })

            setCustomFieldTempOptionValue({ ...customFieldTempOptionValue, ...obj});
            setCustomFieldLoaded(true);
        }
    }

    const changeCustomFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'custom-field-'+itemId;
        setCustomFieldTempOptionValue({ ...customFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotCustomFields = () => {

        let conflictTempInfos = conflictTemplates.custom_field_info !== undefined ? conflictTemplates.custom_field_info : [];

        if(snapShotDetailsAfterConflict.custom_field_info !== undefined){
            let customFieldTemp = JSON.parse(snapShotDetailsAfterConflict.custom_field_info);

            return customFieldTemp.forEach((item)=>{

                if(conflictTempInfos.includes(item.id)){
                    let optionValue = customFieldTempOptionValue['custom-field-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel
                                    onClick={() => changeCustomFieldTempIndividualOptionValue(item.id, "0")}
                                    className="snap-shot-radio-inline" value="0" control={<Radio/>} label="Skip"/>
                                <FormControlLabel
                                    onClick={() => changeCustomFieldTempIndividualOptionValue(item.id, "1")}
                                    className="snap-shot-radio-inline" value="1" control={<Radio/>} label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }
    /*End Custom field temp*/

    /*Start personalized field temp*/
    useEffect(()=>{
        personalizedFieldTempGlobalChange();
    }, [personalizedFieldTempValue]);

    const personalizedFieldTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.personalized_field_info !== undefined){
            setPersonalizedFieldLoaded(false);
            let personalizedFieldTemp = JSON.parse(snapShotDetailsAfterConflict.personalized_field_info);
            const obj = {};
            personalizedFieldTemp.forEach(e => {
                obj['personalized-field-'+e['id']] = personalizedFieldTempValue;
            })

            setPersonalizedFieldTempOptionValue({ ...personalizedFieldTempOptionValue, ...obj});
            setPersonalizedFieldLoaded(true);
        }
    }

    const changePersonalizedFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'personalized-field-'+itemId;
        setPersonalizedFieldTempOptionValue({ ...personalizedFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotPersonalizedFields = () => {

        let conflictTempInfos = conflictTemplates.personalized_field_info !== undefined ? conflictTemplates.personalized_field_info : [];

        if(snapShotDetailsAfterConflict.personalized_field_info !== undefined){
            let personalizedFieldTemp = JSON.parse(snapShotDetailsAfterConflict.personalized_field_info);

            return personalizedFieldTemp.forEach((item)=>{

                if(conflictTempInfos.includes(item.id)){
                    let optionValue = personalizedFieldTempOptionValue['personalized-field-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel
                                    onClick={() => changePersonalizedFieldTempIndividualOptionValue(item.id, "0")}
                                    className="snap-shot-radio-inline" value="0" control={<Radio/>} label="Skip"/>
                                <FormControlLabel
                                    onClick={() => changePersonalizedFieldTempIndividualOptionValue(item.id, "1")}
                                    className="snap-shot-radio-inline" value="1" control={<Radio/>} label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }
    /*End Personalized field temp*/

    /*Start form field temp*/
    useEffect(()=>{
        formTempGlobalChange();
    }, [formTempValue]);

    const formTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.form_info !== undefined){
            setFormFieldLoaded(false);
            let formTemp = JSON.parse(snapShotDetailsAfterConflict.form_info);
            const obj = {};
            formTemp.forEach(e => {
                obj['form-'+e['id']] = formTempValue;
            })

            setFormTempOptionValue({ ...formTempOptionValue, ...obj});
            setFormFieldLoaded(true);
        }
    }

    const changeFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'form-'+itemId;
        setFormTempOptionValue({ ...formTempOptionValue, [objProperty] : value});
    }

    const snapShotForms = () => {

        let conflictTempInfos = conflictTemplates.form_info !== undefined ? conflictTemplates.form_info : [];

        if(snapShotDetailsAfterConflict.form_info !== undefined){
            let formTemp = JSON.parse(snapShotDetailsAfterConflict.form_info);

            return formTemp.forEach((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = formTempOptionValue['form-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={() => changeFormTempIndividualOptionValue(item.id, "0")}
                                                  className="snap-shot-radio-inline" value="0" control={<Radio/>}
                                                  label="Skip"/>
                                <FormControlLabel onClick={() => changeFormTempIndividualOptionValue(item.id, "1")}
                                                  className="snap-shot-radio-inline" value="1" control={<Radio/>}
                                                  label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }
    /*End Form field temp*/

    /*Start lead flow temp*/
    useEffect(()=>{
        leadFlowTempGlobalChange();
    }, [leadFlowTempValue]);

    const leadFlowTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.leadflow_info !== undefined){
            let leadFlowTemp = JSON.parse(snapShotDetailsAfterConflict.leadflow_info);
            const obj = {};
            leadFlowTemp.forEach(e => {
                obj['lead-flow-'+e['id']] = leadFlowTempValue;
            })

            setLeadFlowTempOptionValue({ ...leadFlowTempOptionValue, ...obj});
        }
    }

    const changeLeadFlowTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'lead-flow-'+itemId;
        setLeadFlowTempOptionValue({ ...leadFlowTempOptionValue, [objProperty] : value});
    }

    // const snapShotLeadFlows = () => {
    //     let conflictTempInfos = conflictTemplates.leadflow_info !== undefined ? conflictTemplates.leadflow_info : [];
    //     if(snapShotDetailsAfterConflict.leadflow_info !== undefined){
    //         let leadFlowTemp = JSON.parse(snapShotDetailsAfterConflict.leadflow_info);

    //         return leadFlowTemp.forEach((item)=>{
    //             if(conflictTempInfos.includes(item.id)){
    //                 let optionValue = leadFlowTempOptionValue['lead-flow-' + item.id];
    //                 return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
    //                     {item.title}
    //                     <FormControl>
    //                         <RadioGroup
    //                             aria-labelledby="demo-radio-buttons-group-label"
    //                             value={optionValue}
    //                             name={item.id + '+' + item.title.replace(' ', '-')}
    //                         >
    //                             <FormControlLabel onClick={() => changeLeadFlowTempIndividualOptionValue(item.id, "0")}
    //                                               className="snap-shot-radio-inline" value="0" control={<Radio/>}
    //                                               label="Skip"/>
    //                             <FormControlLabel onClick={() => changeLeadFlowTempIndividualOptionValue(item.id, "1")}
    //                                               className="snap-shot-radio-inline" value="1" control={<Radio/>}
    //                                               label="Overwrite"/>
    //                         </RadioGroup>
    //                     </FormControl>
    //                 </li>)
    //             }
    //         })
    //     }

    // }
    /*End lead flow temp*/

    /*Start tags temp*/
    useEffect(()=>{
        tagFieldTempGlobalChange();
    }, [tagFieldTempValue]);

    const tagFieldTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.tag_info !== undefined){
            setTagFieldLoaded(false);
            let tagFieldTemp = JSON.parse(snapShotDetailsAfterConflict.tag_info);
            const obj = {};
            tagFieldTemp.forEach(e => {
                obj['tag-'+e['id']] = tagFieldTempValue;
            })

            setTagFieldTempOptionValue({ ...tagFieldTempOptionValue, ...obj});
            setTagFieldLoaded(true);
        }
    }

    const changeTagFieldTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'tag-'+itemId;
        setTagFieldTempOptionValue({ ...tagFieldTempOptionValue, [objProperty] : value});
    }

    const snapShotTags = () => {
        let conflictTempInfos = conflictTemplates.tag_info !== undefined ? conflictTemplates.tag_info : [];

        if(snapShotDetailsAfterConflict.tag_info !== undefined){
            let tagFieldTemp = JSON.parse(snapShotDetailsAfterConflict.tag_info);

            return tagFieldTemp.forEach((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = tagFieldTempOptionValue['tag-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.name}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.name.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={() => changeTagFieldTempIndividualOptionValue(item.id, "0")}
                                                  className="snap-shot-radio-inline" value="0" control={<Radio/>}
                                                  label="Skip"/>
                                <FormControlLabel onClick={() => changeTagFieldTempIndividualOptionValue(item.id, "1")}
                                                  className="snap-shot-radio-inline" value="1" control={<Radio/>}
                                                  label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }
    /*End tags temp*/

    /*Start trigger temp*/
    useEffect(()=>{
        triggerTempGlobalChange();
    }, [triggerTempValue]);

    const triggerTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.trigger_info !== undefined){
            setTriggerLoaded(false);
            let triggerTemp = JSON.parse(snapShotDetailsAfterConflict.trigger_info);
            const obj = {};
            triggerTemp.forEach(e => {
                obj['trigger-'+e['id']] = triggerTempValue;
            })

            setTriggerTempOptionValue({ ...triggerTempOptionValue, ...obj});
            setTriggerLoaded(true);
        }
    }

    const changeTriggerTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'trigger-'+itemId;
        setTriggerTempOptionValue({ ...triggerTempOptionValue, [objProperty] : value});
    }

    const snapShotTriggers = () => {
        let conflictTempInfos = conflictTemplates.trigger_info !== undefined ? conflictTemplates.trigger_info : [];

        if(snapShotDetailsAfterConflict.trigger_info !== undefined){
            let triggerTemp = JSON.parse(snapShotDetailsAfterConflict.trigger_info);

            return triggerTemp.map((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = triggerTempOptionValue['trigger-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={() => changeTriggerTempIndividualOptionValue(item.id, "0")}
                                                  className="snap-shot-radio-inline" value="0" control={<Radio/>}
                                                  label="Skip"/>
                                <FormControlLabel onClick={() => changeTriggerTempIndividualOptionValue(item.id, "1")}
                                                  className="snap-shot-radio-inline" value="1" control={<Radio/>}
                                                  label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }
    /*End trigger temp*/

    /*Start custom form temp*/

    useEffect(()=>{
        customFormTempGlobalChange();
    }, [customFormTempValue]);

    const customFormTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.custom_form_info !== undefined){
            setCustomFormLoaded(false);
            let customFormTemp = JSON.parse(snapShotDetailsAfterConflict.custom_form_info);
            const obj = {};
            customFormTemp.forEach(e => {
                obj['custom-form-'+e['id']] = customFormTempValue;
            })

            setCustomFormTempOptionValue({ ...customFormTempOptionValue, ...obj});
            setCustomFormLoaded(true);
        }
    }

    const changeCustomFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'custom-form-'+itemId;
        setCustomFormTempOptionValue({ ...customFormTempOptionValue, [objProperty] : value});
    }

    const snapShotCustomForm = () => {

        let conflictTempInfos = conflictTemplates.custom_form_info !== undefined ? conflictTemplates.custom_form_info : [];

        if(snapShotDetailsAfterConflict.custom_form_info !== undefined){
            let customFormTemp = JSON.parse(snapShotDetailsAfterConflict.custom_form_info);

            return customFormTemp.forEach((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = customFormTempOptionValue['custom-form-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel
                                    onClick={() => changeCustomFormTempIndividualOptionValue(item.id, "0")}
                                    className="snap-shot-radio-inline" value="0" control={<Radio/>} label="Skip"/>
                                <FormControlLabel
                                    onClick={() => changeCustomFormTempIndividualOptionValue(item.id, "1")}
                                    className="snap-shot-radio-inline" value="1" control={<Radio/>} label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }

    /*End custom form*/

    /*Start quick reply temp*/

    useEffect(()=>{
        quickReplyTempGlobalChange();
    }, [quickReplyTempValue]);

    const quickReplyTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.quick_reply_info != undefined){
            setQuickReplyLoaded(false);
            let quickReplyTemp = JSON.parse(snapShotDetailsAfterConflict.quick_reply_info);
            const obj = {};
            quickReplyTemp.forEach(e => {
                obj['quick-reply-'+e['id']] = quickReplyTempValue;
            })

            setQuickReplyTempOptionValue({ ...quickReplyTempOptionValue, ...obj});
            setQuickReplyLoaded(true);
        }
    }

    const changeQuickReplyTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'quick-reply-'+itemId;
        setQuickReplyTempOptionValue({ ...quickReplyTempOptionValue, [objProperty] : value});
    }

    const snapShotQuickReply = () => {

        let conflictTempInfos = conflictTemplates.quick_reply_info != undefined ? conflictTemplates.quick_reply_info : [];

        if(snapShotDetailsAfterConflict.quick_reply_info != undefined){
            let quickReplyTemp = JSON.parse(snapShotDetailsAfterConflict.quick_reply_info);

            return quickReplyTemp.map((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = quickReplyTempOptionValue['quick-reply-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel
                                    onClick={() => changeQuickReplyTempIndividualOptionValue(item.id, "0")}
                                    className="snap-shot-radio-inline" value="0" control={<Radio/>} label="Skip"/>
                                <FormControlLabel
                                    onClick={() => changeQuickReplyTempIndividualOptionValue(item.id, "1")}
                                    className="snap-shot-radio-inline" value="1" control={<Radio/>} label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }

    /*End quick reply temp*/

    /*Start pipeline temp*/

    useEffect(()=>{
        pipeLineTempGlobalChange();
    }, [pipelineTempValue]);

    const pipeLineTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.pipeline_info != undefined){
            let pipelineTemp = JSON.parse(snapShotDetailsAfterConflict.pipeline_info);
            const obj = {};
            pipelineTemp.forEach(e => {
                obj['pipeline-'+e['id']] = pipelineTempValue;
            })

            setPipelineTempOptionValue({ ...pipelineTempOptionValue, ...obj});
        }
    }

    const changePipelineTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'pipeline-'+itemId;
        setPipelineTempOptionValue({ ...pipelineTempOptionValue, [objProperty] : value});
    }

    const snapShotPipelines = () => {

        let conflictTempInfos = conflictTemplates.pipeline_info !== undefined ? conflictTemplates.pipeline_info : [];

        if(snapShotDetailsAfterConflict.pipeline_info !== undefined){
            let pipelineTemp = JSON.parse(snapShotDetailsAfterConflict.pipeline_info);

            return pipelineTemp.map((item)=>{
                if(conflictTempInfos.includes(item.id)){
                    let optionValue = pipelineTempOptionValue['pipeline-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel onClick={() => changePipelineTempIndividualOptionValue(item.id, "0")}
                                                  className="snap-shot-radio-inline" value="0" control={<Radio/>}
                                                  label="Skip"/>
                                <FormControlLabel onClick={() => changePipelineTempIndividualOptionValue(item.id, "1")}
                                                  className="snap-shot-radio-inline" value="1" control={<Radio/>}
                                                  label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }

    /*End pipeline temp*/

    /*Start multi page form temp*/

    useEffect(()=>{
        multiPageFormTempGlobalChange();
    }, [multiPageFormTempValue]);

    const multiPageFormTempGlobalChange = () => {
        if(snapShotDetailsAfterConflict && snapShotDetailsAfterConflict.multi_page_form_info != undefined){
            setMultiPageFormLoaded(false);
            let multiPageFormTemp = JSON.parse(snapShotDetailsAfterConflict.multi_page_form_info);
            const obj = {};
            multiPageFormTemp.forEach(e => {
                obj['multi-page-form-'+e['id']] = multiPageFormTempValue;
            })

            setMultiPageFormTempOptionValue({ ...multiPageFormTempOptionValue, ...obj});
            setMultiPageFormLoaded(true);
        }
    }

    const changeMultiPageFormTempIndividualOptionValue= (itemId, value) => {
        let objProperty = 'multi-page-form-'+itemId;
        setMultiPageFormTempOptionValue({ ...multiPageFormTempOptionValue, [objProperty] : value});
    }

    const snapShotMultiPageForm = () => {

        let conflictTempInfos = conflictTemplates.multi_page_form_info != undefined ? conflictTemplates.multi_page_form_info : [];

        if(snapShotDetailsAfterConflict.multi_page_form_info != undefined){
            let multiPageFormTemp = JSON.parse(snapShotDetailsAfterConflict.multi_page_form_info);

            return multiPageFormTemp.map((item)=>{
                if (Array.isArray(conflictTempInfos) && conflictTempInfos.includes(item.id)) {
                    let optionValue = multiPageFormTempOptionValue['multi-page-form-' + item.id];
                    return (<li key={item.id} className="snapshot--list-item d-flex align-items-center">
                        {item.title}
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={optionValue}
                                name={item.id + '+' + item.title.replace(' ', '-')}
                            >
                                <FormControlLabel
                                    onClick={() => changeMultiPageFormTempIndividualOptionValue(item.id, "0")}
                                    className="snap-shot-radio-inline" value="0" control={<Radio/>} label="Skip"/>
                                <FormControlLabel
                                    onClick={() => changeMultiPageFormTempIndividualOptionValue(item.id, "1")}
                                    className="snap-shot-radio-inline" value="1" control={<Radio/>} label="Overwrite"/>
                            </RadioGroup>
                        </FormControl>
                    </li>)
                }
            })
        }

    }

    /*End multi page form*/

    useEffect( () => {

        if(conflictTemplates.campaign_info !== undefined && !conflictTemplates.campaign_info.length){
                setShowCampaignConflict(false);
        }

        if(conflictTemplates.custom_field_info !== undefined && !conflictTemplates.custom_field_info.length){
            setCustomFieldConflict(false);
        }

        if(conflictTemplates.personalized_field_info !== undefined && !conflictTemplates.personalized_field_info.length){
            setPersonalizedFieldConflict(false);
        }

        if(conflictTemplates.calendar_info !== undefined && !conflictTemplates.calendar_info.length){
            setCalendarFieldConflict(false);
        }

        if(conflictTemplates.form_info !== undefined && !conflictTemplates.form_info.length){
            setFormTempConflict(false);
        }

        if(conflictTemplates.tag_info !== undefined && !conflictTemplates.tag_info.length){
            setTagTempConflict(false);
        }

        if(conflictTemplates.trigger_info !== undefined && !conflictTemplates.trigger_info.length){
            setTriggerConflict(false);
        }

        if(conflictTemplates.custom_form_info !== undefined && !conflictTemplates.custom_form_info.length){
            setCustomFormConflict(false);
        }

        if(conflictTemplates.quick_reply_info !== undefined && !conflictTemplates.quick_reply_info.length){
            setQuickReplyConflict(false);
        }
    }, [conflictTemplates])


    const handleNextClick = () => {
        let globalTemplatesObjAfterConflict = {
            campaignTempOptionValue,
            customFieldTempOptionValue,
            formTempOptionValue,
            leadFlowTempOptionValue,
            tagFieldTempOptionValue,
            triggerTempOptionValue,
            customFormTempOptionValue,
            multiPageFormTempOptionValue,
            quickReplyTempOptionValue,
            pipelineTempOptionValue,
            personalizedFieldTempOptionValue,
            calendarTempOptionValue
        };

        handleStep(globalTemplatesObjAfterConflict, assetSelector);
    }

    const convertTempOptionValue = (mainObj, optionValue, replaceString) => {
        Object.entries(globalTemplatesObj[optionValue]).forEach(([key, value]) => {
            let newKey = key.replace(replaceString, '');
            if(value === 1 || value === '1'){
                let obj = {
                    [newKey] : value
                };
                mainObj = { ...mainObj, ...obj };
            }
        });

        return mainObj;
    }

    const checkSnapShotDuplicates = (globalTemplatesObj) => {

        let campaign_info = {};
        let form_info = {};
        let custom_field_info = {};
        let leadflow_info = {};
        let tag_info = {};
        let trigger_info = {};
        let custom_form_info = {};
        let quick_reply_info = {};
        let pipeline_info = {};
        let calendar_info = {};
        let personalized_field_info = {};
        let multi_page_form_info = {};


        campaign_info = convertTempOptionValue(campaign_info, 'campaignTempOptionValue', 'camp-');

        custom_field_info = convertTempOptionValue(custom_field_info, 'customFieldTempOptionValue', 'custom-field-');

        form_info = convertTempOptionValue(form_info, 'formTempOptionValue', 'form-');

        leadflow_info = convertTempOptionValue(leadflow_info, 'leadFlowTempOptionValue', 'lead-flow-');

        tag_info = convertTempOptionValue(tag_info, 'tagFieldTempOptionValue', 'tag-');

        trigger_info = convertTempOptionValue(trigger_info, 'triggerTempOptionValue', 'trigger-');

        custom_form_info = convertTempOptionValue(custom_form_info, 'customFormTempOptionValue', 'custom-form-');

        quick_reply_info = convertTempOptionValue(quick_reply_info, 'quickReplyTempOptionValue', 'quick-reply-');

        pipeline_info = convertTempOptionValue(pipeline_info, 'pipelineTempOptionValue', 'pipeline-');

        calendar_info = convertTempOptionValue(calendar_info, 'calendarTempOptionValue', 'calendar-');

        personalized_field_info = convertTempOptionValue(personalized_field_info, 'personalizedFieldTempOptionValue', 'personalized-field-');

        multi_page_form_info = convertTempOptionValue(multi_page_form_info, 'multiPageFormTempOptionValue', 'multi-page-form-');

        let asset_selector = {campaign_info, form_info, custom_field_info, leadflow_info, tag_info, trigger_info, custom_form_info, quick_reply_info, pipeline_info, calendar_info, personalized_field_info, multi_page_form_info};

        setAssetSelector(asset_selector);

        checkDuplicateSnapShotItems({
            "form_data": {
                asset_selector,
                userId: subUser.id,
                snapshotId : activeSnapShot.value
            }
        })
            .then(response => {
                let apiResponse = response.data;

                if(apiResponse.status == 'success'){
                    if(apiResponse.data !== undefined &&  apiResponse.data.isConflict !== undefined ){
                        if(apiResponse.data.isConflict === true){
                            setIsConflict(true);
                            let conflictData = apiResponse.data.conflictedData;
                            setConflictTemplates(conflictData);
                        }else{
                            console.log('NO Conflict')
                        }
                    }
                }else{
                    console.log('ERROR');
                }
            }).finally(() => {
                setLoaded(true);
        });
    }

    return(
        <div className="twilio___migration__gerenal_step twili__migration___container">

            {
                !loaded ?
                    <>
                        <p className={"no-conflict-message"}>Checking for conflicts...</p>
                    </> :
                    <>
                        {
                            !isConflict &&
                            <div className="row">
                                <p className={"no-conflict-message"}>There are no conflict found.</p>
                            </div>
                        }

                        {
                            isConflict &&
                            (
                                <>
                                    <div className="row">
                                        <p>There are few conflicts - Please choose which assets to overwrite</p>
                                        <p><small>(Recommended action : SKIP)</small></p>

                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue={overWriteTempConflict}
                                            name="campaign-temp-radio-all"
                                            className="snap-shot--inline-radio"
                                        >
                                            <FormControlLabel onClick={()=>setOverWriteTempConflict("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                            <FormControlLabel onClick={()=>setOverWriteTempConflict("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                        </RadioGroup>

                                    </div>
                                    <div className="row">
                                        {
                                            showCampaignConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography>Campaigns</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={campaignTempValue}
                                                                value={campaignTempValue}
                                                                name="campaign-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setCampaignTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setCampaignTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {campaignLoaded && snapShotCampaigns()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            customFieldConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Custom Fields</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={customFieldTempValue}
                                                                value={customFieldTempValue}
                                                                name="custom-field-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setCustomFieldTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setCustomFieldTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>{customFieldLoaded && snapShotCustomFields()}</ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            personalizedFieldConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Personalized Fields</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={personalizedFieldTempValue}
                                                                value={personalizedFieldTempValue}
                                                                name="custom-field-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setPersonalizedFieldTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setPersonalizedFieldTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>{personalizedFieldLoaded && snapShotPersonalizedFields()}</ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            calendarConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Calendars</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={calendarTempValue}
                                                                value={calendarTempValue}
                                                                name="custom-field-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setCalendarTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setCalendarTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>{calendarFieldLoaded && snapShotCalendars()}</ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            formTempConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Forms</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={formTempValue}
                                                                value={formTempValue}
                                                                name="form-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setFormTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setFormTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {formFieldLoaded && snapShotForms()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {/*{*/}
                                        {/*    leadFlowConflict &&*/}
                                        {/*    <Accordion>*/}
                                        {/*        <AccordionSummary*/}
                                        {/*            expandIcon={<ExpandMoreIcon/>}*/}
                                        {/*            aria-controls="panel2a-content"*/}
                                        {/*            id="panel2a-header"*/}
                                        {/*        >*/}
                                        {/*            <Typography>Leadflows</Typography>*/}
                                        {/*        </AccordionSummary>*/}
                                        {/*        <AccordionDetails>*/}
                                        {/*            <Typography>*/}
                                        {/*                <FormControl className=" d-flex align-items-end">*/}
                                        {/*                    <RadioGroup*/}
                                        {/*                        aria-labelledby="demo-radio-buttons-group-label"*/}
                                        {/*                        defaultValue={leadFlowTempValue}*/}
                                        {/*                        value={leadFlowTempValue}*/}
                                        {/*                        name="lead-flow-temp-radio-all"*/}
                                        {/*                        className="snap-shot--inline-radio"*/}
                                        {/*                    >*/}
                                        {/*                        <FormControlLabel onClick={() => setLeadFlowTempValue("0")}*/}
                                        {/*                                          className="snap-shot-radio-inline" value="0"*/}
                                        {/*                                          control={<Radio/>} label="Skip"/>*/}
                                        {/*                        <FormControlLabel onClick={() => setLeadFlowTempValue("1")}*/}
                                        {/*                                          className="snap-shot-radio-inline" value="1"*/}
                                        {/*                                          control={<Radio/>} label="Overwrite"/>*/}
                                        {/*                    </RadioGroup>*/}
                                        {/*                </FormControl>*/}
                                        {/*                <ul>*/}
                                        {/*                    {leadFlowLoaded && snapShotLeadFlows()}*/}
                                        {/*                </ul>*/}
                                        {/*            </Typography>*/}
                                        {/*        </AccordionDetails>*/}
                                        {/*    </Accordion>*/}
                                        {/*}*/}

                                        {
                                            tagTempConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Tags</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={tagFieldTempValue}
                                                                value={tagFieldTempValue}
                                                                name="tag-field-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={() => setTagFieldTempValue("0")}
                                                                                  className="snap-shot-radio-inline" value="0"
                                                                                  control={<Radio/>} label="Skip"/>
                                                                <FormControlLabel onClick={() => setTagFieldTempValue("1")}
                                                                                  className="snap-shot-radio-inline" value="1"
                                                                                  control={<Radio/>} label="Overwrite"/>
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {tagFieldLoaded && snapShotTags()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {

                                            triggerConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Triggers</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={triggerTempValue}
                                                                value={triggerTempValue}
                                                                name="custom-field-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setTriggerTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setTriggerTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>{triggerLoaded && snapShotTriggers()}</ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            customFormConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Custom Forms</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={customFormTempValue}
                                                                value={customFormTempValue}
                                                                name="custom-form-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setCustomFormTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setCustomFormTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {customFormLoaded && snapShotCustomForm()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            quickReplyConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Quick Replies</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={quickReplyTempValue}
                                                                value={quickReplyTempValue}
                                                                name="quick-reply-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setQuickReplyTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setQuickReplyTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {quickReplyLoaded && snapShotQuickReply()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {
                                            multiPageFormConflict &&
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <Typography>Multi Page Forms</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>
                                                        <FormControl  className=" d-flex align-items-end">
                                                            <RadioGroup
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue={multiPageFormTempValue}
                                                                value={multiPageFormTempValue}
                                                                name="custom-form-temp-radio-all"
                                                                className="snap-shot--inline-radio"
                                                            >
                                                                <FormControlLabel onClick={()=>setMultiPageFormTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />
                                                                <FormControlLabel onClick={()=>setMultiPageFormTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ul>
                                                            {multiPageFormLoaded && snapShotMultiPageForm()}
                                                        </ul>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        }

                                        {/*{*/}
                                        {/*    pipelineConflict &&*/}
                                        {/*    <Accordion>*/}
                                        {/*        <AccordionSummary*/}
                                        {/*            expandIcon={<ExpandMoreIcon />}*/}
                                        {/*            aria-controls="panel2a-content"*/}
                                        {/*            id="panel2a-header"*/}
                                        {/*        >*/}
                                        {/*            <Typography>Pipeline & Stages</Typography>*/}
                                        {/*        </AccordionSummary>*/}
                                        {/*        <AccordionDetails>*/}
                                        {/*            <AccordionDetails>*/}
                                        {/*                <Typography>*/}
                                        {/*                    <FormControl  className=" d-flex align-items-end">*/}
                                        {/*                        <RadioGroup*/}
                                        {/*                            aria-labelledby="demo-radio-buttons-group-label"*/}
                                        {/*                            defaultValue={pipelineTempValue}*/}
                                        {/*                            value={pipelineTempValue}*/}
                                        {/*                            name="pipeline-temp-radio-all"*/}
                                        {/*                            className="snap-shot--inline-radio"*/}
                                        {/*                        >*/}
                                        {/*                            <FormControlLabel onClick={()=>setPipelineTempValue("0")} className="snap-shot-radio-inline" value="0" control={<Radio />} label="Skip" />*/}
                                        {/*                            <FormControlLabel onClick={()=>setPipelineTempValue("1")} className="snap-shot-radio-inline" value="1" control={<Radio />} label="Overwrite" />*/}
                                        {/*                        </RadioGroup>*/}
                                        {/*                    </FormControl>*/}
                                        {/*                    <ul>*/}
                                        {/*                        {pipelineLoaded && snapShotPipelines()}*/}
                                        {/*                    </ul>*/}
                                        {/*                </Typography>*/}
                                        {/*            </AccordionDetails>*/}
                                        {/*        </AccordionDetails>*/}
                                        {/*    </Accordion>*/}
                                        {/*}*/}

                                    </div>
                                </>
                            )

                        }

                        <div className="btn-group justify-content-between d-flex mx-0">


                            <div
                                className="btn twilio__migration__submit__btn"
                                onClick={handleBack}
                            >
                                Back
                            </div>

                            <div
                                className="btn twilio__migration__submit__btn"
                                onClick={handleNextClick}
                            >
                                Next
                            </div>
                        </div>
                    </>

            }


        </div>
    )
}
export default Step3;