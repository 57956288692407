import { Box, Button, Popover } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as React from "react";

const CustomPopOver = ({ buttonText, children, color = "secondary" }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button
        variant={"contained"}
        color={'primary'}
        aria-describedby={id}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        style={{ minWidth: 153, boxShadow:'none', backgroundColor:color }}
      >
        {buttonText}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ "& .MuiPopover-paper": { maxHeight: "245px", height: "100%" } }}
      >
        <Box className={"ib_scroll_bar"}>{children}</Box>
      </Popover>
    </>
  );
};
export default CustomPopOver;
