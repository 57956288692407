import React from "react";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { Cancel, CheckCircle, DeleteForever } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkDeletePolicyType,
  updatePolicyTypeBulkStatus,
} from "../../../../actions/ProductAction";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../common/productSelector";
import { POLICY_TYPES_STATUS } from "../../helper/coreConstant";
import CustomConfirmAlert from "../../common/confirm-alert/CustomConfirmAlert";

const SelectedPoliciesActions = () => {
  const dispatch = useDispatch();
  const { selectedPolicyList } = useSelector(policyTypeState);
  const { selectedFolder } = useSelector(policyTypeFolderState);
  const handleActiveStatus = () => {
    dispatch(
      updatePolicyTypeBulkStatus({
        userPolicyIds: selectedPolicyList,
        status: POLICY_TYPES_STATUS.ACTIVE,
      })
    );
  };

  const handleInactiveStatus = () => {
    dispatch(
      updatePolicyTypeBulkStatus({
        userPolicyIds: selectedPolicyList,
        status: POLICY_TYPES_STATUS.INACTIVE,
      })
    );
  };

  const confirmDeletePolicyType = () => {
    if (selectedFolder === "trash") {
      dispatch(
        bulkDeletePolicyType({
          userPolicyIds: selectedPolicyList,
        })
      );
    } else {
      dispatch(
        updatePolicyTypeBulkStatus({
          userPolicyIds: selectedPolicyList,
          status: POLICY_TYPES_STATUS.TRASH,
          callback: (data) => {
            if (data && data?.message) {
              CustomConfirmAlert({
                onSubmit: () =>
                  dispatch(
                    updatePolicyTypeBulkStatus({
                      userPolicyIds: selectedPolicyList,
                      status: POLICY_TYPES_STATUS.TRASH,
                      force_delete: "TRUE",
                    })
                  ),
                title: data?.message,
                description: "You cannot undo this action",
                submitText: "Force Delete",
              });
            }
          },
        })
      );
    }
  };

  const handleDeletePolicyType = () => {
    CustomConfirmAlert({
      onSubmit: () => confirmDeletePolicyType(),
      title: "Are you sure you want to delete this policy type?",
      description: "You cannot undo this action",
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <BootstrapTooltip arrow placement="top" title={"ACTIVE"}>
        <CheckCircle
          onClick={handleActiveStatus}
          style={{
            fontSize: 28,
            color: "rgb(148, 157, 178)",
            cursor: "pointer",
          }}
        />
      </BootstrapTooltip>
      <BootstrapTooltip arrow placement="top" title={"INACTIVE"}>
        <Cancel
          onClick={handleInactiveStatus}
          style={{
            fontSize: 28,
            color: "rgb(148, 157, 178)",
            cursor: "pointer",
          }}
        />
      </BootstrapTooltip>
      <BootstrapTooltip arrow placement="top" title={"DELETE"}>
        <DeleteForever
          onClick={handleDeletePolicyType}
          style={{
            fontSize: 28,
            color: "rgb(148, 157, 178)",
            cursor: "pointer",
          }}
        />
      </BootstrapTooltip>
    </div>
  );
};

export default SelectedPoliciesActions;
