import React, { Fragment, useState, useEffect } from "react";
import {Link} from "react-router-dom";
import Loader from "../globals/Loader";
import Utils from "../../helpers/Utils";
import { categorySnapShotList, snapShotCategoryList } from "../../api/profileApi";
import { MenuItem, Select } from "@material-ui/core";
import Styles from './snap.module.css'
import SnapShotPopup from "../team/SnapShotPopup";
import SnapShotPopupNew from "../team/SnapShotPopupNew";
const SnapShotCategory = (props) => {
    const [loading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [snapList, setSnapList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [step, setStep] = useState(1);
    const [categoryName, setCategoryName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [snapShotModalVisible, setSnapShotModalVisible] = useState(false);
    const [snapShotModalStepVisible, setSnapShotModalStepVisible] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(null);
    const [snapShot, setSnapShot] = useState(null);


    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(selectedCategory !== ""){
           loadCategorySnap();  
        }
       
    }, [selectedCategory])

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
        categoryList.forEach((item)=>{
            if(item.value == event.target.value){
                setCategoryName(item.label)
            }
        })
        
        if(step==1){
             setStep(2)
        }
    }

    const handleBackButton=()=>{
        setStep(1)
        setSelectedCategory('')
    }

    const handleSearchText = (event) => {
        setSearchText(event.target.value)
    }

    const loadData = () => {
        setLoading(true)
        snapShotCategoryList({})
        .then(response => {
                setCategoryList(response.data.data);
            }).finally(() => {
                setLoading(false);
        });
    }

    const loadCategorySnap = () => {
        setLoading(true)
        categorySnapShotList({category_id: selectedCategory})
        .then(response => {
                setSnapList(response.data.data);
                // console.log(response.data.data)
            }).finally(() => {
                setLoading(false);
        });

    }

    const handleSnapShotPopupNew = (e, item) =>{
        e.preventDefault(); 
        setSnapShotModalVisible(true)
        setSnapShot({value: item.id, label: item.title})
    }

    const handleSnapShotPopup = (e, item) =>{
        e.preventDefault(); 
        setSnapShotModalStepVisible(true)
        setSnapShot({value: item.id, label: item.title})
    }

    // console.log(selectedCategory)
    const renderSnapData = () => {
        const filterData = snapList.filter((item) => {
            const keyword = searchText.trim();
            return keyword ? item.title.toUpperCase().includes(keyword.toUpperCase()) : true;
        });

        if(filterData.length){
        return filterData.map((item,index) => (
            <div className="col l6">
                <div className={Styles.snapBox}>
                   <h5>{item.title}</h5> 
                   <hr className={Styles.ashColor} />
                   <p>Category: <span>{categoryName}</span></p>

                   <div className={Styles.snapBoxActionDiv}>
                        <a href="#!" onClick={(e)=>handleSnapShotPopupNew(e, item)} className="snapLink" >
                            <div  className={Styles.snapBoxAction}>
                                <div className="flexAction">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" rx="15" fill="#6258FF" fill-opacity="0.5"></rect><rect x="2.5" y="2.5" width="25" height="25" rx="12.5" fill="#6258FF"></rect><g clip-path="url(#clip0)"><path d="M15 16.6094C14.112 16.6094 13.3906 15.888 13.3906 15C13.3906 14.1121 14.112 13.3907 15 13.3907C15.8879 13.3907 16.6093 14.1121 16.6093 15C16.6093 15.888 15.8879 16.6094 15 16.6094ZM14.8779 15.4773C14.6781 15.4773 14.5116 15.3108 14.5116 15.111H13.9789C13.99 15.6105 14.3895 16.01 14.8779 16.01V15.4773Z" fill="white"></path><path d="M19.8835 15.3329C19.3396 16.0099 17.3973 18.2186 15 18.2186C12.6027 18.2186 10.6604 16.0099 10.1165 15.3329C9.96115 15.1442 9.96115 14.8668 10.1165 14.667C10.6604 13.99 12.6027 11.7813 15 11.7813C17.3973 11.7813 19.3396 13.99 19.8835 14.667C20.0388 14.8557 20.0388 15.1331 19.8835 15.3329ZM15 12.6692C13.7125 12.6692 12.6693 13.7125 12.6693 15C12.6693 16.2874 13.7125 17.3307 15 17.3307C16.2875 17.3307 17.3307 16.2874 17.3307 15C17.3307 13.7125 16.2875 12.6692 15 12.6692Z" fill="white"></path></g><rect x="2.5" y="2.5" width="25" height="25" rx="12.5" stroke="#F6F7FB"></rect><defs><clipPath id="clip0"><rect width="10" height="10" fill="white" transform="matrix(1 0 0 -1 10 20)"></rect></clipPath></defs></svg>
                                    <div>Preview</div>     
                                </div>
                                
                            </div>
                            
                        </a>

                        <a href="#!" onClick={(e)=>handleSnapShotPopup(e, item)} className="snapLink">
                            <div className={Styles.snapBoxAction}>
                                <div className="flexAction">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="30" height="30" rx="15" fill="#3C7EF3" fill-opacity="0.5"></rect><rect x="2.5" y="2.5" width="25" height="25" rx="12.5" fill="#3C7EF3"></rect><path d="M19.7102 15C19.7102 15.4341 19.6725 15.7851 19.2384 15.7851H15.7851V19.2384C15.7851 19.6717 15.4342 19.7102 15.0001 19.7102C14.5659 19.7102 14.215 19.6717 14.215 19.2384V15.7851H10.7617C10.3284 15.7851 10.2899 15.4341 10.2899 15C10.2899 14.5659 10.3284 14.215 10.7617 14.215H14.215V10.7617C14.215 10.3276 14.5659 10.2899 15.0001 10.2899C15.4342 10.2899 15.7851 10.3276 15.7851 10.7617V14.215H19.2384C19.6725 14.215 19.7102 14.5659 19.7102 15Z" fill="white"></path><rect x="2.5" y="2.5" width="25" height="25" rx="12.5" stroke="#F6F7FB"></rect></svg>
                                    <div>Load Quick Clone</div>   
                                </div>    
                            </div>
                            
                        </a>
                   </div>
                </div>
            </div>
        ))    
        }else{
            return (
                <div className="center">
                    No Quick Clone Found
                </div>
            )
        }
        
    }


    return (
        <Fragment>
            <div class="settings_section">
                <Fragment>
                    {step == 1 &&
                    <div className="container-fluid mail__inbox">
                        <div className="tagBox">
                            <div className="tag_list">
                                <h5>Quick Clone </h5>
                            </div>
                            <div className="add_new_user">
                                <Link className={'modal-trigger accent--bg--text--color'} to="/user/settings"><span><svg
                                    width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg"><path
                                    d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                            </div>
                        </div>
                        <div className="details snapshot-details">
                            {loading && <Loader/>}
                            {!loading && 
                            <div className="page_content">
                                <div className="select_content category_select pt-0 center">
                                    <h5>Take Advantage of pre-build quick clones</h5>
                                    <p className="mb-5">Select a category from the drop-down menu Bellow to see all of the ready to go quick clones </p>

                                        <Select 
                                            className={Styles.categorySelectList}
                                            classes=""
                                            displayEmpty  
                                            value={selectedCategory} 
                                            onChange={handleCategoryChange}
                                            anchorEl={null}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin:{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                },
                                                getContentAnchorEl: null
                                            }}>
                                            <MenuItem value="" disabled>Select A Category</MenuItem>
                                            {categoryList.map((item, index) => {
                                                return(
                                                    <MenuItem className="menuitem-li-hover-color-with-border" value={item.value} key={index}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select> 

                                </div>
                            </div>
                            }
                        </div>
                
                    </div>
                    }

                    {step == 2 &&
                    <div className="">
                        <div className="container-fluid mail__inbox">
                            <div className="tagBox">
                                <div className="tag_list">
                                    <h5>Quick Clone List For {categoryName}</h5>
                                </div>
                                <div className={`add_new_user mt-5 ${Styles.displayHeadDesign} `}>
                                        <Select 
                                            className={Styles.categoryFilterSelectList}
                                            classes=""
                                            displayEmpty  
                                            value={selectedCategory} 
                                            onChange={handleCategoryChange}
                                            anchorEl={null}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                },
                                                transformOrigin:{
                                                vertical: 'top',
                                                horizontal: 'center',
                                                },
                                                getContentAnchorEl: null
                                            }}>
                                            <MenuItem value="" disabled>Select A Category</MenuItem>
                                            {categoryList.map((item, index) => {
                                                return(
                                                    <MenuItem className="menuitem-li-hover-color-with-border" value={item.value} key={index}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select> 
                                        <div className={Styles.pageHeadingSearch}>
                                            <input className={Styles.removeInputBorder} name="search" placeholder="Search" type="text" onChange={handleSearchText}  autocomplete="off"/>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#000000"/>
                                            </svg>
                                        </div>
                                    <Link className={'modal-trigger accent--bg--text--color'} to="#!" onClick={handleBackButton}><span><svg
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"><path
                                        d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg></span><span>Back</span></Link>
                                </div>
                            </div>
                            <div className="details snapshot-details">
                              
                                <div className="page_content">
                                    <div className="select_content category_select pt-0">
                                    {loading && <Loader/>}
                                    {!loading && 
                
                                        <div className="row">
                                            {renderSnapData()}
                                        </div>
                                        
                                    }
                                    </div>
                                </div>
                              
                            </div>
                    
                        </div>

                        {
                            snapShotModalVisible && (
                                <SnapShotPopupNew
                                    hideModal={() => {
                                        setDataToEdit(null);
                                        setSnapShotModalVisible(false);
                                    }}
                                    title={'Quick Clone'}
                                    snapShot={snapShot}
                                    user={null}
                                    data={{id:Utils.getAccountData('userId')}}
                                />
                            )
                        }
                        {/* activeStep */}
                        {
                            snapShotModalStepVisible && (
                                <SnapShotPopup
                                    hideModal={() => {
                                        setDataToEdit(null);
                                        setSnapShotModalStepVisible(false);
                                    }}
                                    title={'Quick Clone'}
                                    snapShot={snapShot}
                                    user={null}
                                    data={{id:Utils.getAccountData('userId')}}
                                    activeStep={1}
                                    removePreve={true} 
                                    removeNext={false}
                                />
                            )
                        }

                    </div>
                    }
                </Fragment>
            </div>
        </Fragment>
    )
}

export default (SnapShotCategory);
