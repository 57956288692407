import { IconButton, TableCell, TableRow, makeStyles } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import BootstrapTooltip from "../globals/BootstrapTooltip";

const useStyles = makeStyles((theme) => ({
  tableRowWrapper: {
    color: theme.palette.text.primary,

    "& .titleWrap": {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .folderWrap": {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      marginTop: 2,

      "& .folderItem": {
        display: "flex",
        alignItems: "center",
        gap: 8,
        // border: "1px solid #D0D5DD",
        borderRadius: "4px",
        padding: "2px 4px",
        marginRight: 10,

        "& .folderName": {
          fontSize: "12px !important",
          color: "#475467",
          lineHeight: 1,
        },
      },
    },

    "& .iconFavorite": {
      fontSize: 16,
      color: "gray",
      cursor: "pointer",
      "&.active": {
        color: "#f79009",
      },
    },

    "& .MuiTableCell-root": {
      fontSize: "15px",
      fontWeight: 500,
    },

    "& .MuiIconButton-root": {
      "&:focus": {
        background: "transparent",
      },
    },
  },
}));

const EachTableItem = ({ user }) => {
  const { full_name, email, name: permissionLevel, created_at } = user || {};
  const classes = useStyles();

  const handleDelete = () => {
    alert("Delete");
  };

  return (
    <TableRow className={classes.tableRowWrapper}>
      <TableCell component="th" scope="row">
        <div className="titleWrap">{full_name}</div>
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{permissionLevel}</TableCell>
      <TableCell>{created_at}</TableCell>
      <TableCell align="center">
        <div style={{ display: "flex", justifyContent: "center", gap: 8 }}>
          <BootstrapTooltip title="Start Dialing Session" placement="top">
            <IconButton size="small">
              <CallIcon />
            </IconButton>
          </BootstrapTooltip>
          <BootstrapTooltip title="Edit Tag" placement="top">
            <IconButton size="small">
              <EditIcon />
            </IconButton>
          </BootstrapTooltip>

          <BootstrapTooltip title="Delete" placement="top">
            <IconButton size="small" onClick={handleDelete}>
              <DeleteIcon style={{ color: "rgb(255, 38, 74)" }} />
            </IconButton>
          </BootstrapTooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EachTableItem;
