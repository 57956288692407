import { Save } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { getsnapTeamUserList, saveSnapShotAccount } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import BasicInput from '../custom/BasicInput';
import BasicSelect from '../custom/BasicSelect';
import GlobalModal from "../globals/Modal/GlobalModal";
import SkeletonLoader from "./SkeletonLoader";

const isValid = (value, key) => { 
    if(value !== undefined && value !== null){
        return value[key];
    }
    return '';
};

const Popup = (props) => {
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const sortType = 'asc';
    const sortBy = 'full_name';
    const debouncedQuery = '';

    const [account, setAccount] = useState(null);
    const [category, setCategory] = useState(null);
    const [isPublic, setIsPublic] = useState({value: 1, label:'Public'});
    const [categoryList, setCategoryList] = useState([]);

    const [accountList, setAccountList] = useState([]);
    const [name, setName] = useState('');

    //validator
    const [, forceUpdate] = useState() //this is a dummy state, when form submitted, change the state so that message is rendered
    const simpleValidator = useRef(new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: forceUpdate},
            className: 'small text-danger mdi mdi-alert pt-1 pl-1'
    }));

    const isPublicList = [
        { value: 1, label:'Public'},
        {value: 0, label:'Private'}
    ]


    useEffect(()=>{
        if(!props.data){
            loadAccount();
        }else{
            setName(props.data.title);
            setIsPublic((props.data.is_public == 1)?{ value: 1, label:'Public'}: {value: 0, label:'Private'});
            setComponentLoading(false);
        }

        if(props.categoryList){
            setCategoryList(props.categoryList)

            if(props.data?.category_id){
                props.categoryList.forEach((item)=>{
                    if(props.data.category_id == item.value){
                        setCategory(item)
                    }
                })    
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    

    const loadAccount = () =>{
        getsnapTeamUserList({
            "form_data": {
                page: 1,
                per_page: 8,
                sort_by: sortBy,
                sort_type: sortType,
                query: debouncedQuery
            }
        })
            .then(response => {

                let accountOption = [];
                let object = response.data.data;

                object.forEach((item)=>{
                    accountOption.push({
                        value: item.id,
                        label: item.full_name
                    })
                })

                setAccountList(accountOption);


            }).finally(() => {
            setComponentLoading(false);
        });
    };

    const toggle = (reset = false) => {
        props.hideModal(reset);
    }

    const onSubmitHandle = (e) =>{
        e.preventDefault();

        if (simpleValidator.current.allValid()) {


            let formData;

            formData = {
                title: name,
                user_id: props.data?.userId ? props.data.userId: account.value,
                snapshotId: props.data?.id ? props.data.id : null,
                category_id: category.value,
                is_public: isPublic.value
            }

            setLoading(true);

            saveSnapShotAccount({
                "form_data": formData
            }).then((response) => {
                if (response.data.status === 'success') {
                    Utils.showNotification(response.data.message, 'success');
                    toggle(true);
                } else if (response.data.status === 'validation-error') {
                    for (var key of Object.keys(response.data.message)) {
                        let errors = response.data.message[key];
                        errors.forEach(error => {
                            Utils.showNotification(error, 'error');
                        });
                    }
                } else {
                    Utils.showNotification(response.data.message, 'error');
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }




    return (
        <GlobalModal
            title={props.title}
            open={true}
            onClose={toggle}
            onSubmit={onSubmitHandle}
            buttonText={loading ? 'Saving..' : 'Save'}
            buttonIcon={<Save/>}
            
        >

        {
            componentLoading ? (
                <div style={{paddingTop: 50}}>
                    <SkeletonLoader/>
                </div>
            ) : (
                <>
                    <div>
                        <div style={{padding:"20px"}}>
                            <div>
                                <h5 className="label_text">Quick Clone Name<span>*</span></h5>
                                {/* <input placeholder="Enter Full Name" 
                                type="text" className="validate" 
                                value={name} 
                                onChange={e => setName(e.target.value)}/> */}

                                <BasicInput
                                     style={{color: '#36454f'}}
                                    value={name} 
                                    onChange={e => setName(e.target.value)} 
                                    placeholder="Enter Full Name" 
                                    fullWidth
                                />

                                {simpleValidator.current.message('name', name, 'required')}
                            </div>

                            {
                                !props.data &&
                                    <div className="input-field">
                                        <h5 className="label_text">Account<span>*</span></h5>
                                        {/* <Select
                                            // styles={{width: '100%'}}
                                            options={accountList}
                                            isClearable={false}
                                            value={account}
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999999 }),
                                                valueContainer: provided => ({
                                                    ...provided,
                                                    width: '50px',
                                                })
                                            }}
                                            onChange={(_selectedOption) => {setAccount(_selectedOption)}}

                                        /> */}
                                        <BasicSelect
                                                options={accountList}
                                                mapping={{
                                                    label: "label",
                                                    value: "value"
                                                }}
                                                defaultText="Select account"
                                                value={isValid(account,"value")}
                                                onChange={(e) => {

                                                }                                 
                                            }
                                            selectedOption={(data)=>{
                                                setAccount(data);
                                            }}   
                                        />
                                        {simpleValidator.current.message('account', account, 'required')}
                                    </div>
                            }

                    
                            <div>
                                <h5 className="label_text">Category<span>*</span></h5>
                                    {/* <Select
                                        // styles={{width: '100%'}}
                                        options={categoryList}
                                        isClearable={false}
                                        value={category}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999999 }),
                                            valueContainer: provided => ({
                                                ...provided,
                                                width: '50px',
                                            })
                                        }}
                                        onChange={(_selectedOption) => {setCategory(_selectedOption)}}
                                    /> */}
                                 <BasicSelect
                                    options={categoryList}
                                    mapping={{
                                        label: "label",
                                        value: "value"
                                    }}
                                    defaultText="Select account"
                                    value={isValid(category,"value")}
                                    onChange={(e) => {

                                    }                                 
                                 }
                                 selectedOption={(data)=>{
                                    setCategory(data);
                                 }}   
                                />
                                
                                {simpleValidator.current.message('category', category, 'required')}
                            </div>
                         

                    
                            <div style={{paddingTop:"18px"}}>
                                <h5 className="label_text">Is Public<span>*</span></h5>
                                <small style={{color: 'red'}}>keeping quick clone in public will be visible for all global user in <a href="/user/profile/snapshot-marketplace">marketplace</a>.</small>
                                {/* <Select
                                    // styles={{width: '100%'}}
                                    options={isPublicList}
                                    isClearable={false}
                                    value={isPublic}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999999 }),
                                        valueContainer: provided => ({
                                            ...provided,
                                            width: '50px',
                                        })
                                    }}
                                    onChange={(_selectedOption) => {setIsPublic(_selectedOption)}}
                                /> */}
                                <BasicSelect
                                    options={isPublicList}
                                    mapping={{
                                        label: "label",
                                        value: "value"
                                    }}
                                    defaultText="Select account"
                                    value={isValid(isPublic, "value")}
                                    onChange={(e) => {

                                    }                                 
                                 }
                                 selectedOption={(data)=>{
                                    setIsPublic(data);                                    
                                 }}   
                                />
                                {simpleValidator.current.message('isPublic', isPublic, 'required')}
                            </div>
                          

                        </div>
                    </div>
                </>
            )
        }

        </GlobalModal>
    );
}

export default Popup;