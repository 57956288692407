import React, {useEffect, useState} from "react";
import {
    Box, IconButton,
    Modal,
    Paper,
    Typography
} from "@material-ui/core";
import {
    Close as CloseIcon
} from "@material-ui/icons";
import {
    getCookie,
    getCookieLogin
} from "../../helpers/Cookie";
import Styles from "./UserInfos.module.css";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const UserInfosDevtool = (props) => {
    const [userData, setUserData] = useState({});

    useEffect(() => {
        setUserData(getUserInfos());
    }, []);

    const getUserInfos = () => {
        try{
            let userInfos;

            let checkNew = getCookie("encode__process__new")
            if(checkNew !== undefined){
                userInfos = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
            }else{
                userInfos = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
            }

            return JSON.parse(userInfos);
        }catch (err){
            return {};
        }
    };

    const parseValue = (value) => {
        try{
            if (!value) {
                return "N/A";
            }

            if (typeof value === "object"){
                return <div>
                  <pre>
                      <code>
                          {JSON.stringify(value, null, 4)}
                      </code>
                  </pre>
                </div>;
            }

            return `${value}`;
        }catch (err){
            return "Error";
        }
    };

    const handleCopy = async (textToCopy) => {
        try {
            if (typeof textToCopy === 'object'){
                textToCopy = JSON.stringify(textToCopy, null, 2);
            }
            await navigator.clipboard.writeText(textToCopy);
            window.showNotification('success', 'Copied to clipboard!');
        }catch (err){
            window.showNotification('error', 'Failed to copy');
        }
    };

    return(
        <Modal
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
        >
            <Paper className={Styles.modalInner}>
                <Box className={Styles.headerWrapper}>
                    <Typography variant="h5" className={Styles.headerTitle}>Devtool</Typography>
                    <Box className={Styles.headerClose} onClick={props.onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>

                <Box className={Styles.paddingWrp}>
                    <Box className={Styles.modalBody}>
                        <Box className={Styles.list}>
                            {
                                Object.entries(userData).map(([key, value])=>(
                                    <Box className={Styles.codePreview}>
                                        <IconButton title={'Copy value'} style={{ position: 'absolute', right: 0, top: 0 }} onClick={async ()=>{ await handleCopy(value) }}>
                                            <FileCopyIcon style={{ color: 'wheat' }}/>
                                        </IconButton>
                                        <code>
                                            <Typography>Key: {key}</Typography>
                                            <Typography>Value: {parseValue(value)}</Typography>
                                            <Typography>Data Type: {typeof value}</Typography>
                                        </code>
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

export default UserInfosDevtool;