import React, { Fragment, useEffect, useRef, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom";
import { getPackageDetails, packageDowngradeRequest, packagePaymentNew } from "../../api/profileApi";
import Utils from "../../helpers/Utils";
import If, {Else} from 'if-else-react';
import { connect } from "react-redux";
import { getUser } from "../../actions/profileAction";
import { getCookie } from "../../helpers/Cookie";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Payment from "../team/Payment";
import PackageSkeleton from "./PackageSkeleton";

const SUBSCRIPTION_TYPE = {
    'price' : 0,
    'yearly_amount' : 1,
    'monthly_amount' : 2,
    'custom_amount' : 3,
}

const PERIOD = {
    'price' : 'life_line',
    'yearly_amount' : 365,
    'monthly_amount' : 30,
    'custom_amount' : 'custom_period',
}

const RECURRING_COLUMN_MAP = {
    'price' : 'credit_amount',
    'yearly_amount' : 'yearly_recurring_amount',
    'monthly_amount' : 'monthly_recurring_amount',
    'custom_amount' : 'custom_recurring_amount',
}

const Package = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const formRef = useRef(null);
    const [paymentFormVisible, setPaymentFormVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(true);
    const [data, setData] = useState(null);
    const [cardOption, setCardOption] = useState('new');
    const [stripeToken, setStripeToken] = useState(null);
    const [virtualNumbers, setVirtualNumbers] = useState([]);
    const [teamMembers, setTeamMembers] = useState([]);
    const [virtualNumbersToRemove, setVirtualNumbersToRemove] = useState([]);
    const [teamMembersToRemove, setTeamMembersToRemove] = useState([]);
    const [subscriptionPack, setSubscriptionPack] = useState('old_pack');

    useEffect(() => {
        document.title = `Package | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if(window.setActiveSidebar) {
            window.setActiveSidebar('setting');
        }
        console.log(props.history.location.state)
        loadData();
        if (!props.user) {
            props.getUser({
                'api-key': getCookie(process.env.REACT_APP_ACCESS_TOKEN)
            })
        }
        loadSubscriptionType();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const loadSubscriptionType = () => {
        try {
            const columnName = '';
            if(columnName.match( /yearly_amount|monthly_amount|custom_amount/g )) {
                setSubscriptionPack(columnName);
            } else {
                setSubscriptionPack('price');
            }
        } catch (ex) {
            setSubscriptionPack('price');
        }
    }


    const loadData = () => {
        setComponentLoading(true);

        getPackageDetails({
            "form_data": {
                package_id: parseInt(props.history.location.state.packageId)
            }
        }).then(response => {
            setData(response.data.data);
        }).catch((error) => {
            Utils.showNotification(error.response.data.message);
        }).finally(() => {
            setComponentLoading(false);
        })
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();

        let formData = {
            packageId: props.history.location.state.packageId,
            pricing_id: props.history.location.state.selectedPricing.id
        }
        
        setLoading(true);
        
        packageDowngradeRequest({
            "form_data": formData
        }).then(response => {
            if (response.data.status === 'success' || response.data.success === true) {
                Utils.showNotification(response.data.message, 'SUCCESS');
                setTimeout(() => {
                    history.push("/user/profile");
                }, 1000);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, 'ERROR');
                    });
                }
            } else {
                Utils.showNotification(response.data.message, 'ERROR');
            }
        }).finally(() => {
            setLoading(false);
        });  
    }

    const buyNewPackage = () => {
        if(loading){
            return;
        }
        if ((cardOption === 'new')) {
            if (!stripeToken) {
                Utils.showNotification('Please Enter Correct Card Information', 'error');
                return false;
            }
        }

        let formData = {
            'virtual-number': virtualNumbers,
            'team-member': teamMembers,
            package_id: props.history.location.state.packageId,
            pricing_id: props.history.location.state.selectedPricing.id,
            customer_id: (typeof data.customer !== 'undefined' && data.customer) ? data.customer.customer_id : null,
            existingCard: (cardOption === 'existing') ? 1 : 0,
            email: props.user.email,
            stripeToken: stripeToken
        }

        setLoading(true);
            
        packagePaymentNew({
            "form_data": formData
        }).then(response => {
            if (response.data.status === 'success' || response.data.success === true) {
                Utils.showNotification(response.data.message, 'SUCCESS');
                window.globalFetchAccountData();
                setTimeout(() => {
                    history.push("/user/profile");
                }, 1000);
            } else if (response.data.status === 'validation-error') {
                for (var key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        Utils.showNotification(error, 'ERROR');
                    });
                }
            } else {
                Utils.showNotification(response.data.message, 'ERROR');
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        }).catch(error => {
            console.log("Package > buyNewPackage > packagePayment",JSON.stringify(error))
        });
    }

    return (
        <Fragment>
            {
                    componentLoading || !props.user ? (
                        <PackageSkeleton/>
                    ) : (
                        <div className="packages_page_content center-align">
                            <If condition={data !== null}>
                                <div className="payment_page">
                                    <div className="payment_topbar d-flex align-items-center justify-content-between">
                                        <div className="payment_title">
                                            <h3>Payment</h3>
                                        </div>
                                        <div className="back_button_to_account has_gap mt-3">
                                            <Link to={{pathname : '/user/packages',state : history.location.state}} className="accent--bg--text--color">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path></svg>
                                                <span>Back</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="package_payment_content update_package">
                                            <If condition={true}>
                                                <If condition={typeof data.virtualNumber !== 'undefined' && (data.virtualNumber.length !== 0)}>
                                                    <If condition={!paymentFormVisible}>
                                                        <form onSubmit={(e) => onSubmitHandler(e)} ref={formRef}>
                                                            <div className="package_payment">
                                                                <div className="package_payment_title text-capital">
                                                                    {/* <h2>{data.status.resultInText} your package</h2> */}
                                                                    {
                                                                        // (data.status.changeType === 2) && (
                                                                        //     <p className="mb-3"><Alert severity="error">Package change will be applicable in next autobilling</Alert></p>
                                                                        // )
                                                                    }
                                                                    <p>Do you want to keep your previous phone number</p>
                                                                </div>
                                                                <div className="people_info">
                                                                    {
                                                                        data.virtualNumber.map((user, index) => (
                                                                            <div className="person">
                                                                                <h6>{user.full_name}</h6>
                                                                                {
                                                                                    (typeof user.virtual_numbers !== 'undefined' && user.virtual_numbers) ? user.virtual_numbers.map((each, index2) => (
                                                                                        <Fragment>
                                                                                            {
                                                                                                (each.is_default === 1 && props.user.id === user.id) ? (
                                                                                                    <p>
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="filled-in"
                                                                                                                checked="checked"
                                                                                                                disabled="disabled"
                                                                                                                value={each.virtual_number}
                                                                                                            />
                                                                                                            <span>{each.virtual_number} (Default)</span>
                                                                                                        </label>
                                                                                                    </p>
                                                                                                ) : (
                                                                                                    <p>
                                                                                                        <label>
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                className="filled-in"
                                                                                                                defaultChecked="checked" name="virtualNumber[]"
                                                                                                                value={each.virtual_number}
                                                                                                                onChange={(e) => {
                                                                                                                    let array = [...virtualNumbersToRemove];

                                                                                                                    if (array.includes(each.virtual_number)) {
                                                                                                                        array = array.filter(function(item) {
                                                                                                                            return item !== each.virtual_number
                                                                                                                        });
                                                                                                                    } else {
                                                                                                                        array.push(each.virtual_number);
                                                                                                                    }

                                                                                                                    setVirtualNumbersToRemove(array);
                                                                                                                }}
                                                                                                            />
                                                                                                            <span>{each.virtual_number}</span>
                                                                                                        </label>
                                                                                                    </p>
                                                                                                )
                                                                                            }
                                                                                        </Fragment>
                                                                                    )) : (
                                                                                        <p>
                                                                                            <label>No numbers</label>
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                                {/* <If condition={data.status.changeType === 2}> */}
                                                                    <Fragment>
                                                                        <div className="package_payment_title">
                                                                            <p>Do you want to keep your team member</p>
                                                                        </div>
                                                                        <div className="people_info">
                                                                            {
                                                                                (typeof data.subUsers !== 'undefined' && data.subUsers) ? data.subUsers.map((subUser, index) => (
                                                                                    <p>
                                                                                        <label>
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="filled-in"
                                                                                                defaultChecked="checked"
                                                                                                value={subUser.id}
                                                                                                name="teamMembers"
                                                                                                onChange={(e) => {
                                                                                                    let array = [...teamMembersToRemove];

                                                                                                    if (array.includes(subUser.id)) {
                                                                                                        array = array.filter(function(item) {
                                                                                                            return item !== subUser.id
                                                                                                        });
                                                                                                    } else {
                                                                                                        array.push(subUser.id);
                                                                                                    }

                                                                                                    setTeamMembersToRemove(array);
                                                                                                }}
                                                                                            />
                                                                                            <span>{subUser.full_name}</span>
                                                                                        </label>
                                                                                    </p>
                                                                                )) : (
                                                                                    <p>
                                                                                        <label>No Team Member</label>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </Fragment>
                                                                {/* </If> */}
                                                                <div className="package_next_btn">
                                                                    <button type="submit" className="btn accent--bg--text--color" loading={loading}>
                                                                        { loading ? 'Please Wait..' : 'Next' }
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </If>
                                                    <If condition={paymentFormVisible}>
                                                        <div className="package_payment">
                                                            <div className="package_payment_title">
                                                                <h2>Payment Details</h2>
                                                            </div>
                                                            <div className="select_payment">
                                                                <div>
                                                                    {
                                                                        (typeof data.customer !== 'undefined' && typeof data.customer.customer_id !== 'undefined' && data.customer.customer_id) && (
                                                                            <div className="mb-3">
                                                                                <FormControl component="fieldset">
                                                                                    <RadioGroup aria-label="gender" name="gender1" value={cardOption} onChange={e => setCardOption(e.target.value)} row>
                                                                                        <FormControlLabel value="existing" control={<Radio />} label="Existing" />
                                                                                        <FormControlLabel value="new" control={<Radio />} label="New" />
                                                                                    </RadioGroup>
                                                                                </FormControl>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        (cardOption === 'existing' && typeof data.cardInfo !== 'undefined' && typeof data.cardInfo.customer_id !== 'undefined' && data.cardInfo.customer_id) && (
                                                                            <Fragment>
                                                                                <div className="defult_card d-flex align-items-center">
                                                                                    <a href="#!" onClick={e => e.preventDefault()} className="defult_button">Default</a>
                                                                                    <span className="card_no">**** **** **** {data.cardInfo.card_number}</span>
                                                                                </div>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                    {
                                                                        (cardOption === 'new') && (
                                                                            <Fragment>
                                                                                {
                                                                                    data && (
                                                                                        <Payment
                                                                                            stripePublicKey={data.stripePublicKey}
                                                                                            callback={setStripeToken}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="package_next_btn">
                                                                <button type="button" className="btn accent--bg--text--color" loading={loading} onClick={buyNewPackage}>
                                                                    { loading ? 'Please Wait..' : 'Pay and Buy Package Now' }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </If>
                                                </If>
                                            <Else />
                                                <If condition={paymentFormVisible}>
                                                    <div className="package_payment">
                                                        <div className="package_payment_title">
                                                            <h2>Payment Details</h2>
                                                        </div>
                                                        <div className="select_payment">
                                                            <div>
                                                                {
                                                                    (typeof data.customer !== 'undefined' && typeof data.customer.customer_id !== 'undefined' && data.customer.customer_id) && (
                                                                        <div className="mb-3">
                                                                            <FormControl component="fieldset">
                                                                                <RadioGroup aria-label="gender" name="gender1" value={cardOption} onChange={e => setCardOption(e.target.value)} row>
                                                                                    <FormControlLabel value="existing" control={<Radio />} label="Existing" />
                                                                                    <FormControlLabel value="new" control={<Radio />} label="New" />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                (cardOption === 'existing' && typeof data.cardInfo !== 'undefined' && typeof data.cardInfo.customer_id !== 'undefined' && data.cardInfo.customer_id) && (
                                                                    <Fragment>
                                                                        <div className="defult_card d-flex align-items-center">
                                                                            <a href="#!" onClick={e => e.preventDefault()} className="defult_button">Default</a>
                                                                            <span className="card_no">**** **** **** {data.cardInfo.card_number}</span>
                                                                        </div>
                                                                    </Fragment>
                                                                )
                                                                }
                                                            {
                                                                (cardOption === 'new') && (
                                                                    <Fragment>
                                                                        {
                                                                            data && (
                                                                                <Payment 
                                                                                    stripePublicKey={data.stripePublicKey}
                                                                                    callback={setStripeToken}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                )
                                                            }
                                                            </div>
                                                        </div>
                                                        <div className="package_next_btn">
                                                            <button type="button" className="btn accent--bg--text--color" loading={loading} onClick={buyNewPackage}>
                                                                { loading ? 'Please Wait..' : 'Pay and Buy Package Now' }
                                                            </button>
                                                        </div>
                                                    </div>
                                                </If>
                                            </If>
                                            <div className="modal payment_successfull-modal" id="payment_successfull" tabindex="0">
                                                <span className="modal-close d-flex justify-content-end close_icon">
                                                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13 26.9453C20.1797 26.9453 26 21.125 26 13.9453C26 6.76561 20.1797 0.945312 13 0.945312C5.8203 0.945312 0 6.76561 0 13.9453C0 21.125 5.8203 26.9453 13 26.9453Z" fill="#FF264A"></path>
                                                        <path d="M17.1262 20.0822L19.1367 18.0703L8.87897 7.80583L6.86845 9.81768L17.1262 20.0822Z" fill="white"></path>
                                                        <path d="M8.87898 20.0848L19.1367 9.82031L17.1262 7.80846L6.86845 18.0729L8.87898 20.0848Z" fill="white"></path>
                                                    </svg>
                                                </span>
                                                <div className="payment_successfull_content">
                                                    <span className="successfull_img">
                                                        <svg width="160" height="166" viewBox="0 0 160 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M85.752 113.91C102.033 113.91 115.231 100.712 115.231 84.4317C115.231 68.1511 102.033 54.9531 85.752 54.9531C69.4714 54.9531 56.2734 68.1511 56.2734 84.4317C56.2734 100.712 69.4714 113.91 85.752 113.91Z" fill="#3C7EF3"></path>
                                                            <path d="M78.2188 97.729L93.3765 112.887C105.93 109.539 115.23 98.101 115.23 84.4311C115.23 84.1521 115.23 83.8732 115.23 83.5942L103.327 72.6211L78.2188 97.729Z" fill="#356DD0"></path>
                                                            <path d="M86.4946 91.0357C87.7965 92.3376 87.7965 94.5694 86.4946 95.8713L83.7978 98.568C82.4959 99.8699 80.2641 99.8699 78.9622 98.568L67.1522 86.665C65.8503 85.3631 65.8503 83.1313 67.1522 81.8294L69.849 79.1327C71.1509 77.8308 73.3827 77.8308 74.6846 79.1327L86.4946 91.0357Z" fill="white"></path>
                                                            <path d="M96.8175 70.4842C98.1194 69.1823 100.351 69.1823 101.653 70.4842L104.35 73.181C105.652 74.4829 105.652 76.7147 104.35 78.0166L83.8916 98.3819C82.5897 99.6838 80.3579 99.6838 79.056 98.3819L76.3592 95.6851C75.0573 94.3832 75.0573 92.1514 76.3592 90.8495L96.8175 70.4842Z" fill="white"></path>
                                                            <path d="M103.297 48.0108C103.297 47.422 103.525 46.8343 103.981 46.3921L128.924 22.1806C129.818 21.3128 131.246 21.3331 132.113 22.2271C132.981 23.1212 132.959 24.5492 132.066 25.417L107.123 49.6296C106.23 50.4974 104.801 50.476 103.934 49.5819C103.509 49.1433 103.297 48.5771 103.297 48.0108Z" fill="#FF4E61"></path>
                                                            <path d="M107.02 119.579C107.142 119.026 107.47 118.519 107.98 118.183C109.019 117.496 110.419 117.783 111.105 118.822L130.257 147.831C130.944 148.871 130.658 150.27 129.618 150.956C128.578 151.643 127.179 151.357 126.492 150.317L107.341 121.307C106.991 120.777 106.894 120.154 107.02 119.579Z" fill="#FFAA00"></path>
                                                            <path d="M92.4125 133.026C92.4125 132.442 92.6378 131.859 93.0872 131.418L93.1123 131.393C94.0015 130.519 95.4296 130.533 96.3022 131.421C97.1759 132.31 97.1628 133.738 96.2747 134.61L96.2497 134.635C95.3604 135.508 93.9324 135.496 93.0598 134.607C92.6283 134.168 92.4125 133.597 92.4125 133.026Z" fill="#FF4E61"></path>
                                                            <path d="M119.157 102.542C119.157 102.163 119.253 101.779 119.454 101.428C120.072 100.345 121.45 99.9685 122.531 100.586L152.941 117.938C154.022 118.556 154.399 119.933 153.781 121.015C153.164 122.097 151.786 122.474 150.705 121.857L120.295 104.504C119.566 104.088 119.157 103.326 119.157 102.542Z" fill="#62D38F"></path>
                                                            <path d="M124.36 70.1271C124.36 69.3428 124.769 68.581 125.499 68.165L155.907 50.8126C156.99 50.1951 158.367 50.5718 158.984 51.653C159.602 52.7353 159.226 54.1133 158.144 54.7308L127.735 72.0832C126.652 72.7007 125.274 72.324 124.657 71.2429C124.456 70.89 124.36 70.5062 124.36 70.1271Z" fill="#5C73BC"></path>
                                                            <path d="M128.072 93.6332C128.072 92.8679 128.462 92.1205 129.165 91.6973C130.233 91.056 131.62 91.4017 132.261 92.4685L132.279 92.4995C132.92 93.5676 132.576 94.9527 131.508 95.5941C130.44 96.2366 129.055 95.8909 128.412 94.8228L128.394 94.793C128.176 94.4294 128.072 94.0289 128.072 93.6332Z" fill="#FF4E61"></path>
                                                            <path d="M60.3558 48.0108C60.3558 47.422 60.127 46.8343 59.6716 46.3921L34.7283 22.1806C33.8342 21.3128 32.4062 21.3331 31.5396 22.2271C30.6718 23.1212 30.6932 24.5492 31.5861 25.417L56.5294 49.6296C57.4222 50.4974 58.8515 50.476 59.7181 49.5819C60.1436 49.1433 60.3558 48.5771 60.3558 48.0108Z" fill="#FF4E61"></path>
                                                            <path d="M84.2743 39.5966C84.7082 39.1984 84.9864 38.6325 85.0044 37.998L85.9801 3.25016C86.0151 2.00471 85.0346 0.966186 83.79 0.932088C82.5446 0.897112 81.5068 1.87841 81.4711 3.12298L80.4945 37.8716C80.4587 39.1162 81.4408 40.1548 82.6855 40.1889C83.2964 40.2058 83.8571 39.9794 84.2743 39.5966Z" fill="#27AE60"></path>
                                                            <path d="M68.7828 56.1659C68.7828 55.5973 68.5695 55.0287 68.1403 54.5901C67.2701 53.6984 65.8421 53.6817 64.9504 54.5519L64.9254 54.577C64.035 55.4471 64.0171 56.8752 64.8885 57.7668C65.7586 58.6585 67.1867 58.6752 68.0783 57.8038L68.1034 57.78C68.5552 57.3377 68.7828 56.7524 68.7828 56.1659Z" fill="#FFAA00"></path>
                                                            <path d="M67.806 122.509C67.6451 121.966 67.282 121.483 66.7497 121.184C65.6636 120.574 64.2883 120.959 63.6777 122.045L46.642 152.345C46.0313 153.431 46.4165 154.806 47.5025 155.417C48.5886 156.027 49.9639 155.642 50.5745 154.556L67.6099 124.255C67.9208 123.702 67.9733 123.073 67.806 122.509Z" fill="#FFAA00"></path>
                                                            <path d="M33.8062 83.9989C33.8062 83.4148 33.5809 82.8319 33.1315 82.3908L33.1065 82.3658C32.2172 81.4921 30.7892 81.5052 29.9166 82.3932C29.0428 83.2825 29.0559 84.7105 29.944 85.5831L29.969 85.6081C30.8583 86.4807 32.2864 86.4688 33.1589 85.5795C33.5904 85.1409 33.8062 84.5699 33.8062 83.9989Z" fill="#FF4E61"></path>
                                                            <path d="M48.5888 102.542C48.5888 102.163 48.4934 101.779 48.2919 101.428C47.6745 100.345 46.2965 99.9685 45.2153 100.586L14.8053 117.938C13.7241 118.556 13.3475 119.933 13.9649 121.015C14.5824 122.097 15.9604 122.474 17.0416 121.857L47.4516 104.504C48.1799 104.088 48.5888 103.326 48.5888 102.542Z" fill="#62D38F"></path>
                                                            <path d="M38.2562 124.116C38.2562 123.719 38.1513 123.317 37.9319 122.953L37.9141 122.923C37.2704 121.857 35.884 121.513 34.8172 122.156C33.7503 122.8 33.4082 124.186 34.0507 125.253L34.0686 125.283C34.7123 126.349 36.0986 126.693 37.1655 126.049C37.8676 125.626 38.2562 124.88 38.2562 124.116Z" fill="#2DC471"></path>
                                                            <path d="M47.1046 74.5842C47.1046 73.7998 46.6958 73.0381 45.9662 72.6221L15.5575 55.2696C14.4751 54.6521 13.0983 55.0288 12.4808 56.11C11.8633 57.1924 12.2388 58.5704 13.3212 59.1878L43.73 76.5403C44.8124 77.1578 46.1903 76.7811 46.8078 75.6999C47.0093 75.3471 47.1046 74.9632 47.1046 74.5842Z" fill="#5C73BC"></path>
                                                            <path d="M86.3239 164.071C87.0249 163.719 87.5221 163.011 87.5665 162.173L89.4283 127.211C89.4944 125.966 88.5399 124.905 87.2967 124.838C86.0523 124.772 84.9893 125.726 84.9232 126.971L83.0615 161.933C82.9954 163.177 83.9503 164.239 85.1936 164.306C85.5994 164.328 85.9852 164.241 86.3239 164.071Z" fill="#5C73BC"></path>
                                                            <path d="M36.0254 55.7464C36.0254 54.9812 35.6356 54.2337 34.9323 53.8106C33.8642 53.1693 32.4779 53.515 31.8365 54.5818L31.8187 54.6128C31.1773 55.6809 31.5218 57.066 32.5899 57.7073C33.658 58.3498 35.0431 58.0042 35.6856 56.9361L35.7035 56.9063C35.9216 56.5427 36.0254 56.1422 36.0254 55.7464Z" fill="#FF4E61"></path>
                                                            <path d="M6.95428 103.458C3.51049 103.458 0.71875 100.747 0.71875 97.4047C0.71875 94.0611 3.51049 91.3516 6.95428 91.3516C10.3981 91.3516 13.191 94.0611 13.191 97.4047C13.191 100.747 10.3981 103.458 6.95428 103.458Z" fill="#FFD02F"></path>
                                                            <path d="M109.794 27.7079C106.35 27.7079 103.559 24.9972 103.559 21.6547C103.559 18.3111 106.35 15.6016 109.794 15.6016C113.238 15.6016 116.031 18.3111 116.031 21.6547C116.031 24.9972 113.238 27.7079 109.794 27.7079Z" fill="#3C7EF3"></path>
                                                            <path d="M142.929 134.462C140.878 134.462 139.215 136.125 139.215 138.176C139.215 140.228 140.878 141.891 142.929 141.891C144.98 141.891 146.644 140.228 146.644 138.176C146.644 136.125 144.98 134.462 142.929 134.462Z" fill="#FF4E61"></path>
                                                        </svg>
                                                    </span>
                                                    <h1>Payment Successful !</h1>
                                                    <p>
                                                        The payment of <span>$1059 </span>has Successfully.
                                                        Transportation Id : <span>1243567899</span>
                                                    </p>
                                                    <a href="#Worning" className="download_invoice modal-trigger">Download Invoice</a>
                                                </div>
                                            </div>

                                            <div className="modal payment_successfull-modal Worning_modal" id="Worning" tabindex="0">
                                                <span className="modal-close d-flex justify-content-end close_icon">
                                                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13 26.9453C20.1797 26.9453 26 21.125 26 13.9453C26 6.76561 20.1797 0.945312 13 0.945312C5.8203 0.945312 0 6.76561 0 13.9453C0 21.125 5.8203 26.9453 13 26.9453Z" fill="#FF264A"></path>
                                                        <path d="M17.1262 20.0822L19.1367 18.0703L8.87897 7.80583L6.86845 9.81768L17.1262 20.0822Z" fill="white"></path>
                                                        <path d="M8.87898 20.0848L19.1367 9.82031L17.1262 7.80846L6.86845 18.0729L8.87898 20.0848Z" fill="white"></path>
                                                    </svg>
                                                </span>
                                                <div className="payment_successfull_content">
                                                    <span className="successfull_img">
                                                        <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M60.5 0C27.4163 0 0.5 26.9163 0.5 60C0.5 93.0837 27.4163 120 60.5 120C93.5837 120 120.5 93.0837 120.5 60C120.5 26.9163 93.5837 0 60.5 0ZM66.2692 92.2817H54.7308V80.7433H66.2692V92.2817ZM65.1154 73.8462H55.8846L54.1538 27.6923H66.8462L65.1154 73.8462Z" fill="#FF264A"></path>
                                                        </svg>
                                                    </span>
                                                    <h1>Something Went Wrong</h1>
                                                    <p>We aren't able to process your payment. Your Balance Low . Please Try Again </p>
                                                    <a href="#!" onClick={e => e.preventDefault()} className="try_again">Try again</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="order_summery_content">
                                            <div className="order_summery">
                                                <div className="order_title">
                                                    <h2>Order Summary</h2>
                                                </div>
                                                <div className="order_price_list">
                                                    <div className="packages">
                                                        <ul>
                                                            <li>Package</li>
                                                            <li>{data.package.name}</li>
                                                        </ul>
                                                        {/* <ul>
                                                            <li>Total Recurring USD</li>
                                                            <li>{data.package[RECURRING_COLUMN_MAP[subscriptionPack]]}</li>
                                                        </ul> */}
                                                        <ul>
                                                            <li>Validity</li>
                                                            <li>{props.history.location.state.selectedPricing.validity} day</li>
                                                        </ul>
                                                        {/* <ul>
                                                            <li>Team member</li>
                                                            <li>Unlimited</li>
                                                        </ul> */}
                                                    </div>
                                                    <div className="tax_subtotal">
                                                        <ul>
                                                            <li>Price  </li>
                                                            <li>{props.history.location.state.selectedPricing.price} USD</li>
                                                        </ul>
                                                        {/* <ul>
                                                            <li className="subtotal">Need to pay</li>
                                                            <li className="total_price">{data.needToPay}</li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                                {
                                                    // (typeof data.userPackage !== 'undefined' && data.userPackage && data.status.changeType !== 2) && (
                                                    //     <div className="order_summery_bottom_content">
                                                    //         <h1>You'll be Charged<span>${data.needToPay}</span>Today</h1>
                                                    //         {/* <p>Your monthly plan will be <span>$59</span> a will automatically Renew until you cancel. Your Next Billing on <span>21 Apr 2021, 05:00 PM </span>. Next Bill amount will be <span>$59</span> </p> */}
                                                    //     </div>
                                                    // )
                                                }
                                           
                                            </div>
                                        </div>         
                                    </div>	
                                </div>
                            </If>
                            <If condition={!data}>
                                <h3>No data is found</h3>
                            </If>
                        </div>
                    )
                }
        </Fragment>
    )
}


const mapStateToProps = state => {
    return {
        user: state.profileReducer.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getUser: (params) => dispatch(getUser(params)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Package);