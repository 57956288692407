import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Popover,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomScrollbar } from "../../products/productsStyle";
import { useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../products/common/productSelector";
import PreviewMergeFieldDataStyles from "./PreviewMergeFieldDataStyles";

const PreviewMergeFieldData = () => {
  const classes = PreviewMergeFieldDataStyles();
  const buttonRef = useRef(null);
  const { userMergeFieldPreviewData } = useSelector(customFieldInsuranceState);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [formattedUserMergeFieldData, setFormattedUserMergeFieldData] = useState([]);

  useEffect(() => {
    if (buttonRef.current) {
      setAnchorEl(buttonRef.current);
    }
  }, []);

  useEffect(()=>{
    if(userMergeFieldPreviewData[0]){
      setFormattedUserMergeFieldData(groupMergeFieldData(userMergeFieldPreviewData))
    }
  },[userMergeFieldPreviewData]); // eslint-disable-line react-hooks/exhaustive-deps


  const groupMergeFieldData = (data) => {
    try {
        if (!Array.isArray(data)) {
        return [];  
        };
  
        const groupedData = data.reduce((acc, item) => {
            const groupName = item.group_name || 'user-personalized-field}';
            if (!acc[groupName]){
               acc[groupName] = { group_name: groupName, fields: [] }
              };
  
            acc[groupName].fields.push({ ...item, group_name: groupName });
            return acc;
        }, {});
  
        return Object.values(groupedData);
    } catch (error) {
        console.error("Error in groupMergeFieldData:", error);
        return [];
    }
  };


  return (
    <div className={classes.container}>
      <div className={classes.popoverHeader}>Preview of Merge Fields</div>
      <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', width:"100%"}}>
      <Button
        ref={buttonRef}
        variant="contained"
        className={classes.personalizeButton}
        color="primary"
        aria-describedby="personalized-popover"
        endIcon={<ExpandMoreIcon />}
      >
        Merge Field
      </Button>
      </div>
      <Popover
        id="personalized-popover"
        open={Boolean(anchorEl)} 
        anchorEl={anchorEl} 
        anchorOrigin={{
          vertical: "bottom", 
          horizontal: "left", 
        }}
        transformOrigin={{
          vertical: "top", 
          horizontal: "left", 
        }}
        disableEnforceFocus 
        disableAutoFocus 
        disablePortal 
        className={classes.popoverWrapper}
      >
        <CustomScrollbar className={classes.popoverContent}>
          {(Array.isArray(formattedUserMergeFieldData) && formattedUserMergeFieldData.length > 0) ? (
            formattedUserMergeFieldData.map((groups, index) => (
              <Accordion key={index} className={classes.accordionRoot}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <div>{`${groups.group_name} (${groups?.fields?.length})`}</div>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "rgba(62, 80, 247, 0.1)" }}>
                  <div>
                    { (Array.isArray(groups?.fields) ) &&
                      groups.fields.map((item) => (
                        <div key={item.id} className={classes.folderItem}>
                          <div style={{ marginBottom: "6px" }}>
                            <strong>{item.title}</strong>
                          </div>
                          <Divider />
                        </div>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className={classes.noDataText}>No data found</div>
          )}
        </CustomScrollbar>
      </Popover>
    </div>
  );
};

export default PreviewMergeFieldData;
