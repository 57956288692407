import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Modal, Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Close } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    creditCardModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '0',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "10px",
        width: '822px',
    },
}));


export const CreditCardModal = (props) => {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`${classes.creditCardModal} creditCardModal ${props.packageInformation.submitModal === 'error' ? 'errorModal' : ''}`}
            open={true}
            onClose={()=>props.closeAction()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={true}>
                <div className={`${classes.paper} ccm__modalPaper`}>

                    <IconButton>
                        <Close onClick={()=>props.closeAction()}/>
                    </IconButton>

                    {(props.packageInformation.submitModal === 'success' || props.packageInformation.submitModal === 'exists') ? (<img src="https://d24gujj67p1uc1.cloudfront.net/asset/images/map/releases/1.0.0/success.png" alt="Success" />) : (<img src="https://d24gujj67p1uc1.cloudfront.net/asset/images/map/releases/1.0.0/warning.png" alt="Warning" />)
                    }


                    <Typography className='ccm__modal_title' variant='h4'>
                        {props.packageInformation.submitModal === 'success' && 'Payment Successful !'} 
                        {props.packageInformation.submitModal === 'success-downgrade' && 'Downgrade request accepted !'} 
                        {props.packageInformation.submitModal === 'exists' && 'Package already exists !'} 
                        {props.packageInformation.submitModal === 'error' && 'Something Went Wrong '} </Typography>

                    <Typography className='ccm__modal_subtitle' >
                        {props.packageInformation.submitModal !== 'success-downgrade' ? props.packageInformation.message : ''}</Typography>


                    {/* {props.submitModal === 'success' ?
                        <Button className='ccm__modal_button'>Download Invoice</Button> :
                        <Button className='ccm__modal_button' variant="outlined">Try again </Button>
                    } */}
                </div>
            </Fade>
        </Modal>
    )
}
