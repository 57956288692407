import React, { Fragment, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { KeyboardArrowDown } from "@material-ui/icons";
import {
    Button,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    withStyles
} from "@material-ui/core";
import {
    AddToContactIcon,
    ExportContactIcon
} from "./Icons";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import { mapOrderAddToContact } from "../../../api/profileApi";
import PowerDialerModal from "../../addPowerDialerModal/PowerDialerModal";
import SkipTraceModal from "./SkipTraceModal";

const actions = [
    {
        text: "Add Contacts To The CRM",
        icon: <AddToContactIcon />,
        id: "ADD_SELECTED_TO_CRM"
    },
    {
        text: "Create A Power Dialer List",
        icon: <AddToContactIcon />,
        id: "CREATE_POWER_DIALER"
    },
    {
        text: "Skiptrace",
        icon: <AddToContactIcon />,
        id: "SKIP_TRACE"
    },
    {
        text: "Export Contacts",
        icon: <ExportContactIcon />,
        id: "EXPORT_SELECTED"
    }
];

const StyledListItemIcon = withStyles((theme) => ({
    root: {
        minWidth: "35px",
        "& svg": {
            height: "20px",
            width: "20px"
        },
    },
}))(ListItemIcon);

const StyledListItemText = withStyles((theme) => ({
    root: {
        fontStyle: "italic",
    },
}))(ListItemText);

const StyledButton = withStyles((theme) => ({
    root: {
        backgroundColor: '#00ff91',
        color: 'black',
        '&:hover': {
            backgroundColor: '#80ffbf',
        },
        '&:active': {
            backgroundColor: '#00cc68',
        },
    },
}))(Button);

const ActionDropDown = ({ orderId, selectedIds, selectedContacts, orderDetails, updateSkipTraceStatus, setReset, startPolling }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const exportButton = useRef(null);
    const [openPwdModal, setOpenPwdModal] = useState(false);
    const [openSkTrcModal, setOpenSkTrcModal] = useState(false);
    const [isActiveActionBtn, setIsActiveActionBtn] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setIsActiveActionBtn(!isActiveActionBtn);
    };

    const handleBlur = () => {
        setIsActiveActionBtn(false);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const makeAddToContactRequest = async () => {
        try {
            let response = await mapOrderAddToContact({
                "form_data": {
                    orderId: orderId,
                    contactIds: selectedIds
                }
            });
            if (response.data.status === "success") {
                setReset((prevState) => (prevState + 1));
                window.showNotification("success", response.data.message);
            } else if (response.data.status === "validation-error") {
                for (const key of Object.keys(response.data.message)) {
                    let errors = response.data.message[key];
                    errors.forEach(error => {
                        window.showNotification("error", error);
                    });
                }
            } else {
                window.showNotification("error", response.data.message);
            }
        } catch (err) {
            window.showNotification("error", "Unable to send request. Please contact with system administrator");
        }
    }

    const handleSelect = (action) => {
        if (!selectedIds[0]) {
            window.showNotification("error", "No contact selected!");
            setAnchorEl(null);
            return;
        }
        switch (action.id) {
            case "ADD_SELECTED_TO_CRM":
                NewConfirmAlert({
                    onSubmit: async () => {
                        await makeAddToContactRequest();
                    },
                    title: "Are you sure?",
                    description: "Selected contacts will be added to the CRM",
                    cancelText: "No",
                    submitText: "Yes, Add",
                    width: "480px",
                });
                setAnchorEl(null);
                break;
            case "CREATE_POWER_DIALER":
                setOpenPwdModal(true);
                setAnchorEl(null);
                break;
            case "SKIP_TRACE":
                setOpenSkTrcModal(true);
                setAnchorEl(null);
                break;
            case "EXPORT_SELECTED":
                NewConfirmAlert({
                    onSubmit: () => {
                        if (exportButton.current) {
                            exportButton.current.link.click();
                        }
                    },
                    title: "Are you sure?",
                    description: "Selected contacts will be exported to the csv",
                    cancelText: "No",
                    submitText: "Yes, Export",
                    width: "480px",
                });
                setAnchorEl(null);
                break;
            default:
                window.showNotification("error", "No action found!");
                setAnchorEl(null);
                return;
        }
    };

    const renderActionItem = () => {
        let item = [];

        for (let i = 0; i < actions.length; i++) {
            item.push(
                <MenuItem className="dropdownhelper-menuitem-class" onClick={() => { handleSelect(actions[i]) }}>
                    <StyledListItemIcon>
                        {actions[i].icon}
                    </StyledListItemIcon>
                    <StyledListItemText>
                        {actions[i].text}
                    </StyledListItemText>
                </MenuItem>
            );
        }

        return item;
    };

    return (
        <Fragment>
            <StyledButton
                className={isActiveActionBtn ? 'active' : ''}
                variant="contained"
                color="primary"
                aria-controls="action-menu"
                aria-haspopup="true"
                onClick={handleClick}
                onBlur={handleBlur}
                endIcon={<KeyboardArrowDown />}
            >
                Actions
            </StyledButton>

            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                {renderActionItem()}
            </Menu>

            {
                openPwdModal &&
                <PowerDialerModal
                    selectedIds={selectedIds}
                    open={openPwdModal}
                    setOpen={setOpenPwdModal}
                    onClose={() => {
                        setOpenPwdModal(false);
                    }}
                />
            }

            {
                openSkTrcModal &&
                <SkipTraceModal
                    selectedIds={selectedIds}
                    orderId={orderId}
                    isOpen={openSkTrcModal}
                    onClose={() => {
                        setOpenSkTrcModal(false);
                    }}
                    updateSkipTraceStatus={updateSkipTraceStatus}
                    startPolling={startPolling}
                />
            }

            {
                selectedContacts && orderDetails &&
                <div style={{ display: "none" }}>
                    <CSVLink filename={`${orderDetails.title ? orderDetails.title : orderDetails.order_no}.csv`} ref={exportButton} data={selectedContacts} />
                </div>
            }
        </Fragment>
    );
};

export default ActionDropDown;