import { Fragment, useEffect, useState } from "react"
import GlobalModal from "../../components/globals/Modal/GlobalModal";
import BasicInput from "../custom/BasicInput";


const Popup = (props) => {
    
    const [sender, setSender] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');

    useEffect(() => {
        if (props.data) {
            setSender(props.data.sender);
            setSubject(props.data.email_subject);
            setBody(props.data.email_body);
        }
    }, [props.data])

    const toggle = () => {
        props.hideModal();
    }

    return (
        <Fragment>
            <GlobalModal
                open={props.isOpen}
                onClose={() => toggle()}
                title={props.title}
                hideFooter={true}
                >
                <div className="modal_body">
                    <div className="input-group">
                        <p htmlFor="">Sender</p>
                        {/* <input className="modal_input" type="text" placeholder="Tag Name"
                               value={sender} readOnly={true}/> */}
                        <BasicInput 
                            value={sender} 
                            placeholder="Tag Name"
                            readOnly={true} 
                            fullWidth
                        />
                    </div>
                    <div className="input-group">
                        <p htmlFor="">Email Subject</p>
                        {/* <input className="modal_input" type="text" placeholder="Tag Name"
                               value={subject} readOnly={true}/> */}
                        <BasicInput 
                            value={subject} 
                            placeholder="Tag Name"
                            readOnly={true} 
                            fullWidth
                        />
                    </div>
                    <div className="input-group">
                        <p htmlFor="">Email Body</p>
                        <div style={{paddingLeft:"5px"}}> 
                            <span className="__message_text" dangerouslySetInnerHTML={{__html: body}} />
                        </div>


                    </div>
                </div>
            </GlobalModal>
        </Fragment>
    )
}

export default Popup;