import {
  Dialog,
  TableCell
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteLostReason } from "../../../../../actions/ProductAction";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../../constants/PermissionConst";
import { Delete, EditLead } from "../../../../insurance/leadSourceV2/icon";
import CustomDeleteModal from "../../../../settings/CustomDeleteModal";
import PermissionDeniedModal from "../../../common/permissionModal/PermissionDeniedModal";
import AddOrEditLostReasonModal from "../AddOrEditLostReasonModal";
import { LostReasonStyle } from './LostReasonStyle';

const EachLostReasonTableItem = ({ policy }) => {
  const { id, reason, deletable } = policy || {};
  const dispatch = useDispatch();
  const [openAddOrEditModal, setOpenAddOrEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const DescriptionForDelete = "You are attempting to delete a global lost deal reason.\nThis change will be applied to all users on your team.";
  const ImgSrcForDeleteModal = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`;
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
  const [permissionState, setPermissionState] = useState(null);

  const handleEditLostReason = () => {
    if (isUserHasPermission) {
      setOpenAddOrEditModal(true);
    } else {
      setPermissionState("edit");
    }
  };

  const confirmDeleteLostReason = async () => {
    dispatch(deleteLostReason({ lost_deal_reason_id: id }));
  };

  const handleDeleteLostReason = () => {
    if (isUserHasPermission) {
      setOpenDeleteModal(true);
      } else {
      setPermissionState("delete");
    }
  };

  return (
    <LostReasonStyle>
      <TableCell style={{width:'60%'}}>
        <div className="titleWrap">{reason}</div>
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <SwitchC*/}
      {/*    status={status}*/}
      {/*    brandColor={"#346fef"}*/}
      {/*    onChange={() => setStatus(!status)}*/}
      {/*  />*/}
      {/*</TableCell>*/}
      
      <TableCell style={{width:'40%'}}>
        {
          deletable &&
          ( 
            <div className='actionContainer'>
              <button className="editButton" onClick={handleEditLostReason}>
                {isUserHasPermission ? <EditLead /> : <EditLead fill={"gray"} />}
              </button>
          
                <button className="deleteButton" onClick={handleDeleteLostReason}>
                  {isUserHasPermission ? <Delete /> : <Delete fill={"gray"} />}
                </button>
            </div>
          )}
        
      </TableCell>
      <Dialog
        open={openDeleteModal}
        PaperProps={{
          style: {
            borderRadius: '14px',
          },
        }}
      >
          <CustomDeleteModal
            title="Notice"
            onClose={() => setOpenDeleteModal(false)}
            handleConfirm={confirmDeleteLostReason}
            closeButtonText="Close"
            confirmButtonText="Delete"
            showConfirmButton={true}
            showCloseButton={true}
            description={DescriptionForDelete}
            placeholder="Type lost deal reason"
            imgSrc={ImgSrcForDeleteModal}
            confirmationText={reason}
          />
      </Dialog>

      {openAddOrEditModal && (
        <AddOrEditLostReasonModal
          open={openAddOrEditModal}
          onClose={() => setOpenAddOrEditModal(false)}
          edit={policy}
        />
      )}
      {permissionState && (
          <PermissionDeniedModal
            open={true}
            onClose={() => setPermissionState(null)}
            permissionModule={"Lost Deal Reason"}
            action={permissionState}
          />
        )}
    </LostReasonStyle>
  );
};

export default EachLostReasonTableItem;
