import React, { useEffect, useState } from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddOrEditNewPolicyTypeModal from "./AddOrEditNewPolicyTypeModal";
import { policyTypeStyle } from "./PolicyTypesStyle";
import { CustomButton } from "./policyTypesBody/CustomButton";
import { BasicInput } from "../../productsStyle";
import SearchIcon from "@material-ui/icons/Search";
import { policyTypeState } from "../../common/productSelector";
import { useDispatch, useSelector } from "react-redux";
import SelectedPoliciesActions from "./SelectedPoliciesActions";
import useDebounce from "../../../../hooks/useDebounce";
import { getPolicyTypes } from "../../../../actions/ProductAction";
import { InsuranceCompanyIcon } from "../../../ten-dlc/helpers/Icon";

const PolicyTypesHeader = () => {
  const {
    selectedPolicyList,search
  } = useSelector(policyTypeState);

  const { topbarHeaderTitle,productBodySubTitle } = policyTypeStyle();
  const [openAddPolicyTypeModal, setOpenAddPolicyTypeModal] = useState(false);
  const [searchValue, setSearchValue] = useState(search ? search : "");
  const debouncedSearch = useDebounce(searchValue, 500);
  const dispatch = useDispatch();

  
  useEffect(() => {
    dispatch(getPolicyTypes({ page: 1, perPage: 25,search:debouncedSearch }));
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  
  const handleCloseModal = () => {
    setOpenAddPolicyTypeModal(false);
  };
  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
       <div style={{display:"flex",alignItems:"center", gap:'16px'}}>
       <div style={{marginRight:"5px"}}><InsuranceCompanyIcon /></div>
        <div >
        <Typography style={{height:'unset !important'}} variant="h5" className={topbarHeaderTitle}>
          Manage Policy Types
        </Typography>
        <Typography style={{height:'unset !important'}} variant="body2" className={productBodySubTitle}>
        Select the policy types that you represent in your agency.
        </Typography>
        </div>
        
       </div>
        
        <Box display={"flex"} alignItems={"center"} gridGap={8} padding={0}>
       {(
          selectedPolicyList && selectedPolicyList[0]) ? 
          <SelectedPoliciesActions/> :
          <BasicInput
              value={searchValue}
              placeholder="Search for existing policy"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon style={{ color: "#656565" }} />
                </InputAdornment>
              }
              style={{ width: "300px" }}
              onChange={handleSearch}
            />
        }
          <CustomButton
            variant="contained"
            onClick={() => setOpenAddPolicyTypeModal(true)}
            startIcon={<AddIcon />}
            disableElevation
          >
            New Policy Type
          </CustomButton>
        </Box>
      </Box>

      {openAddPolicyTypeModal && (
        <AddOrEditNewPolicyTypeModal
          open={openAddPolicyTypeModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default PolicyTypesHeader;
