export const REMINDER = {
    TYPE: {
        EMAIL: 1,
        SMS: 2
    },
    ADDRESS_TYPE: {
        ME: 1,
        CUSTOMER: 2,
        CUSTOMER_AND_ME: 3
    },
    REMIND_TYPE: {
        AFTER_DUE_DATE: 1,
        BEFORE_DUE_DATE: 2
    },
    ENABLE: {
        ON: 1,
        OFF: 2
    }
}

export const ADDRESS_TYPE = {
    ME: "ME",
    CUSTOMER: "CUSTOMER",
    CUSTOMER_AND_ME: "CUSTOMER_AND_ME",
}

export const ADDRESS_TYPE_LIST = [
     {label: "me", value: ADDRESS_TYPE.ME},
     {label: "customer", value: ADDRESS_TYPE.CUSTOMER},
     {label: "customer and copy me", value: ADDRESS_TYPE.CUSTOMER_AND_ME},
]

export const REMIND_TYPE = {
    AFTER_DUE_DATE: "AFTER_DUE_DATE",
    BEFORE_DUE_DATE: "BEFORE_DUE_DATE",
}

export const REMIND_TYPE_LIST = [
    {label: "after", value: REMIND_TYPE.AFTER_DUE_DATE},
    {label: "before", value: REMIND_TYPE.BEFORE_DUE_DATE},
    ]

export const STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
}

export const TYPE = {
    EMAIL: "EMAIL",
    SMS: "SMS"
}