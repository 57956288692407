import {
  BrushOutlinedIcon,
  CopyIcon,
  DollarIcon,
  FilterIcon,
  FolderFilledIcon,
  LockIcon,
  MailIcon,
  MonitorIcon,
  RadioIcon,
  StarIcon,
  WebsiteIcon,
} from "../common/Icons";
import Utils from "../../../helpers/Utils";
import {USER_SELECTOR} from "./coreConstant";

export const renderFolderIcon = (icon, color, size = 24) => {
  switch (icon) {
    case "FolderFilledIcon":
      return <FolderFilledIcon color={color} size={size} />;
    case "WebsiteIcon":
      return <WebsiteIcon color={color} size={size} />;
    case "MonitorIcon":
      return <MonitorIcon color={color} size={size} />;
    case "MailIcon":
      return <MailIcon color={color} size={size} />;
    case "FilterIcon":
      return <FilterIcon color={color} size={size} />;
    case "DollarIcon":
      return <DollarIcon color={color} size={size} />;
    case "StarIcon":
      return <StarIcon color={color} size={size} />;
    case "LockIcon":
      return <LockIcon color={color} size={size} />;
    case "RadioIcon":
      return <RadioIcon color={color} size={size} />;
    case "CopyIcon":
      return <CopyIcon color={color} size={size} />;
    case "BrushOutlinedIcon":
      return <BrushOutlinedIcon color={color} size={size} />;

    default:
      return <FolderFilledIcon color={color} size={size} />;
  }
};

export const userTimeSelectConverter = (date) => {
  return window.globalTimezoneConversionToDifferentTimezone(date, Utils.getAccountData('userTimezoneRaw'), "UTC", "YYYY-MM-DD HH:mm:ss");
};

export const onSelectUser = (item, remove, users, ids, callback) => {
  let tempUsers = [];
  let tempUserIds = [];

  if (item.value === USER_SELECTOR.CLEAR_ALL) {
    tempUserIds = [];
    return;
  }
  if (item.value === USER_SELECTOR.ALL) {
    if (remove) {
      tempUsers = [];
      tempUserIds = [];
    } else {
      tempUsers = [item];
      tempUserIds = [USER_SELECTOR.ALL];
    }
  } else {
    if (ids.length > 0 && ids[0] === USER_SELECTOR.ALL) {
      tempUsers = [item];
      tempUserIds = [item.value];
    } else {
      let old_data_ids = [...ids];
      let old_data_full = [...users];

      if (remove) {
        tempUserIds = old_data_ids.filter(
            (list) => item.value !== list
        );

        tempUsers = old_data_full.filter(
            (list) => item.value !== list.value
        );
      } else {
        old_data_ids.push(item.value);
        old_data_full.push(item);
        tempUsers = old_data_full;
        tempUserIds = old_data_ids;
      }
    }
  }

  callback(tempUsers, tempUserIds);
};