import { makeStyles } from "@material-ui/core";

export const ModalStylesLeadSource = makeStyles({
    errorMessage: {
      color: "red",
      fontSize: "12px",
    },
    actionContainer:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    actions: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
    editButton: {
      cursor: "pointer",
      border: "none",
      background: "#fff",
      borderRadius: "50px",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#E5E8EF",
        color: "#466fef",
      },
      "&:focus": {
        backgroundColor: "none",
      },
    },
    deleteButton: {
      cursor: "pointer",
      border: "none",
      background: "#fff",
      borderRadius: "50px",
      padding: "8px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#E5E8EF",
        color: "#466fef",
      },
      "&:focus": {
        backgroundColor: "none",
      },
    },
    button: {
      padding: "8px 16px",
      border: "none", 
      borderRadius: "20px",
      cursor: "default",
      "&:focus, &:hover": {
        backgroundColor: "#efefef",
      },
    },
    buttontext: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
    headerText: {
      fontSize: "18px !important",
    },
    text: {
      fontSize: "16px !important",
    },
  });