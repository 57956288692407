import {
    Box,
    Button,
    Dialog,
    Divider,
    FormHelperText,
    Grid,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import BasicSelect from "../common/BasicSelect";
import { BasicInput, CustomScrollbar } from "../productsStyle";
import { productStyle } from "../productService/content/ProductStyle";
import { addSalesGoalApi, updateSealsGoalApi } from "../../../api/productsApi";
import { useSalesGoalContext } from "./goalsBody/SalesGoalsContext";
import moment from "moment";
import {FREQUENCY} from "../helper/coreConstant";
import {useSelector} from "react-redux";
import Utils from "../../../helpers/Utils";
import AddGoalSkeleton from "./AddGoalSkeleton";
import useQueryParam from "../../../hooks/useQueryParam";
import {markStepComplete} from "../../../api/onboardApi";

const useStyles = makeStyles({
    titleHeader: {
        fontSize: "20px",
        color:'white'
    },
    errorStyle: {
        border: "1px solid red !important",
        borderRadius: "4px",
    },
    errorText: {
        color: "red",
    },
    description: {
        border: "1px solid #ced4da !important",
        borderRadius: "4px !important",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none !important",
        },
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
        height: "24px ",
    },
    multipleSelect: {
        "& .MuiSelect-select": {
            height: "1.5rem !important",
            // paddingTop: "6px !important",
            display: "flex",
            alignItems: "center",
        },
    },
    productCount: {
        '& .MuiInputBase-root': { paddingInline: 14 }
    },
    closeIconBox: {
        width:'25px',
        height:'25px',
        borderRadius:'50%',
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor:"#FF264A",
        cursor:'pointer'
        
        
    }
});


const AddOrEditSalesGoalModal = ({ open, onClose, edit, initialData, ...props }) => {
    const classes = useStyles();
    const { topbarNewProductButton, lightBlue } = productStyle();
    const [pipelineIds, setPipelineIds] = useState([''])
    const [userId, setUserId] = useState('');
    const [frequency, setFrequency] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [salesGoal, setSalesGoal] = useState(0);
    const [productCount, setProductCount] = useState(0);
    const [errors, setErrors] = useState({});
    const [id, setId] = useState(null);
    const [isSubmit,setIsSubmit] = useState(false);
    const { teamUsers, pipelines } = useSelector((state) => state.productReducer);
    const { fetchData } = useSalesGoalContext();
    const placeholderColor =  "#929da9";
    const activeColor = "#36454F";
    const [openTab] = useQueryParam("open-tab");
    const [from] = useQueryParam("from");

    useEffect(() => {
        if (edit && initialData) {
            setId(initialData.id);
            setUserId(initialData.userId);
            setPipelineIds(initialData.pipelineIds);
            setFrequency(initialData.frequency);
            setStartDate(initialData.startDate);
            setEndDate(initialData.endDate);
            setSalesGoal(initialData.salesGoal);
            setProductCount(initialData.productCount);
        }
    }, [edit, initialData]);

    const pipeOptions = pipelines.map((pipeline) => ({ value: pipeline.id, label: pipeline.title, }));
    const userOptions = teamUsers.map((user) => ({ value: user.id, label: user.full_name }));

    const frequencyOptions = [
        { value: FREQUENCY.WEEKLY, label: 'Weekly' },
        { value: FREQUENCY.MONTHLY, label: 'Monthly' },
        { value: FREQUENCY.QUARTERLY, label: 'Quarterly' },
        { value: FREQUENCY.YEARLY, label: 'Yearly' }
    ];

    const handleSubmit = async () => {
        try{
            const validationErrors = {};
            if (!userId) validationErrors.user_id = 'User is required';
            if (!pipelineIds?.[0]) validationErrors.pipeline_id = 'Pipeline is required';
            if (!frequency) validationErrors.frequency = 'Frequency is required';
            if (!startDate) validationErrors.start_date = 'Start date is required';
            if (!endDate) validationErrors.end_date = 'End date is required';
            if (!salesGoal) validationErrors.sales_goal = 'Sales goal is required';
            if (salesGoal <= 0) validationErrors.sales_goal = 'Sales goal must be greater than 0';
            if (!productCount) validationErrors.product_count = 'Deal count is required';
            if (productCount <= 0) validationErrors.product_count = 'Deal count must be greater than 0';

            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                return;
            }
            setErrors({});
            setIsSubmit(true);
            const payload = {
                userId,
                pipelineIds,
                frequency,
                startDate,
                endDate,
                salesGoal,
                productCount
            };
            const editPayload = {
                id,
                userId,
                pipelineIds,
                frequency,
                startDate,
                endDate,
                salesGoal,
                productCount
            };

            const resp = edit ? await updateSealsGoalApi(editPayload) : await addSalesGoalApi(payload);

            if (resp?.success) {
                window.showNotification("success", resp.message);
                if (openTab === "GOALS" && from === "onboard"){
                    const onboardComplete = await markStepComplete({ key: "SET_SALES_GOAL" });
                    if (onboardComplete?.success) {
                        Utils.removeQueryParam(["from", "open-tab"]);
                    }
                }
                onClose();
                fetchData();
                setUserId('');
                setPipelineIds(['']);
                setFrequency('');
                setStartDate('');
                setEndDate('');
                setSalesGoal(0);
                setProductCount(0);
                if (props.openCollapseModal){
                    props.handleSalesGoalStats(props.openCollapseModal)
                }
            }else {
                window.showNotification("error", resp?.message || "Something went wrong");
            }
        }catch (err){
            console.log(err);
            window.showNotification("error", "Something went wrong");
        } finally {
            setIsSubmit(false);
        }
    };
    const handleNumberInput = (e, setValue) => {
        const value = e.target.value;
        setValue(parseFloat(value));
    }

    const handlePipeline = (event) => {
        const pipeline_id = event.target.value
        const updatedPipelineId = pipeline_id.filter(id => id !== '');
        setPipelineIds(updatedPipelineId);
        setErrors((prevState)=>({ ...prevState, pipeline_id: "" }));
    };

    const handleFrequency = (e) => {
      const frequency = e.target.value;
      setFrequency(frequency);
    
      const startDate = moment().format('YYYY-MM-DD'); 
       const endDate = moment().add(1, 'years').format('YYYY-MM-DD');
      
      setStartDate(startDate);
      setEndDate(endDate);

      setErrors((prevState)=>({ ...prevState, frequency: "", start_date: "", end_date: "" }));
    };

    const handleClose = () => {
        onClose();
        setErrors({});
        setUserId('');
        setPipelineIds(['']);
        setFrequency('');
        setStartDate('');
        setEndDate('');
        setSalesGoal(0);
        setProductCount(0);
    }

    return (
        <Dialog  
        PaperProps={{
                style: {
                minWidth: '950px',
                },
            }}
        open={open} onClose={handleClose}>
            <Box
                minWidth={930}
                minHeight={300}
                sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
            >
                {
                    edit ?
                    <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            padding={2}
                            style={{backgroundColor:'#346FEF',marginBottom:'20px'}}
                        >
                            <Typography
                                variant="body1"
                                style={{ marginBottom: 4 }}
                                className={classes.titleHeader}
                            >
                                Edit Goal
                            </Typography>
                            <Divider style={{height:'1px', width:'85%',backgroundColor:'white'}}/>

                            <Box onClick={handleClose} className={classes.closeIconBox} >
                                <Close style={{color:'white'}} />
                            </Box>
                        </Box>
                        :
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            padding={2}
                            style={{backgroundColor:'#346FEF',marginBottom:'20px'}}
>
                            <Typography
                                variant="body1"
                                style={{ marginBottom: 4 }}
                                className={classes.titleHeader}
                            >
                                Add a New Goal
                            </Typography>
                            <Divider style={{height:'1px', width:'77%',backgroundColor:'white'}}/>

                            <Box onClick={handleClose} className={classes.closeIconBox}>
                                <Close style={{color:'white'}} />
                            </Box>
                        </Box>
                }

               {isSubmit ? <AddGoalSkeleton/> :  ( 
                <CustomScrollbar style={{ maxHeight: 650 }}>
                    <Grid  container>
                        <Grid item xs={6} style={{paddingLeft:'16px',marginBottom:'12px'}}>
                        
                            
                            <Box mb={1.5}>
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Which user would you like to set a sales goal for?
                            </Typography>

                            <BasicSelect
                                value={userId}
                                defaultText="Select User"
                                options={userOptions}
                                style={{color:`${(edit || !userId) ? placeholderColor : activeColor}`}}
                                onChange={(e) => {
                                    setErrors((prevState)=>({ ...prevState, user_id: "" }));
                                    setUserId(e.target.value);
                                }}
                                disabled = {edit ? true : false}
                            />
                            {errors.user_id && <FormHelperText style={{ color: 'red' }}>{errors.user_id}</FormHelperText>}
                        </Box>

                        <Box mb={1.5}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography variant="body1">
                                    Which pipelines should count towards the sales goal?
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{ color: "rgb(84, 99, 118)", marginBottom: 4 }}
                                >
                                    (Select all the apply)
                                </Typography>
                            </Box>

                            <BasicSelect
                                defaultText="Select Pipeline"
                                multiple={true}
                                value={pipelineIds}
                                options={pipeOptions}
                                style={{color:`${!pipelineIds?.[0] ? placeholderColor : activeColor}`}}
                                onChange={(e) => { handlePipeline(e) }}
                            />
                            {errors.pipeline_id && <FormHelperText style={{ color: 'red' }}>{errors.pipeline_id}</FormHelperText>}
                        </Box>
                        
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} style={{paddingLeft:'16px',marginBottom:'12px'}}>
                        <Box mb={1.5} >
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Select your goal frequency
                            </Typography>

                            <BasicSelect
                                value={frequency}
                                defaultText="Select Frequency"
                                options={frequencyOptions}
                                style={{
                                    color: (edit || !frequency) ? placeholderColor : activeColor,
                                  }}
                                onChange={handleFrequency}
                                disabled={edit ? true : false}
                            />
                            {errors.frequency && <FormHelperText style={{ color: 'red' }}>{errors.frequency}</FormHelperText>}
                        </Box>
                        </Grid>
                        <Grid item xs={6}  style={{paddingRight:'16px',paddingLeft:'12px',marginBottom:'12px'}}>
                        <Box mb={1.5}>
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Goal duration
                            </Typography>

                            <Box display="flex" alignItems="center" sx={{ gap: 10, mb: 1.5 }}>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <BasicInput
                                        value={startDate}
                                        type="date"
                                        fullWidth
                                        disabled={edit ? true : false}
                                        style={{color:`${(!startDate)? placeholderColor : activeColor}`,cursor: edit ? 'not-allowed' : 'pointer'}}
                                        onChange={(e) => {
                                            setStartDate(e.target.value);
                                            setErrors((prevState)=>({ ...prevState, start_date: "" }));
                                        }}
                                    />
                                    {errors.start_date && (
                                        <FormHelperText style={{ color: 'red' }}>
                                            {errors.start_date}
                                        </FormHelperText>
                                    )}
                                </Box>

                                <Typography variant="body1" style={{ marginBottom: 4 }}>
                                    To
                                </Typography>

                                <Box display="flex" flexDirection="column" width="100%">
                                    <BasicInput
                                        value={endDate}
                                        type="date"
                                        fullWidth
                                        disabled={edit ? true : false}
                                        style={{color:`${(!endDate)? placeholderColor : activeColor}`}}
                                        onChange={(e) => {
                                            setEndDate(e.target.value);
                                            setErrors((prevState)=>({ ...prevState, end_date: "" }));
                                        }}
                                    />
                                    {errors.end_date && (
                                        <FormHelperText style={{ color: 'red' }}>
                                            {errors.end_date}
                                        </FormHelperText>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft:'16px',marginBottom:'12px'}}>
                        <Box mb={1.5} style={{position:'relative'}}>
                           { (Utils.getAccountData("agencyBusinessType") === undefined || 
                             Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Set the sales goal
                            </Typography> : 
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                            Set the premium sales goal
                        </Typography>}
                            <BasicInput
                            value={salesGoal}
                                name="goalValue"
                                type="number"
                                fullWidth
                                variant="outlined"
                                inputProps={{ min: 0, max: 999999, className: "pricePadding"  }}
                                style={{color:`${!salesGoal ? placeholderColor : activeColor}`}}
                                onChange={(e) => {
                                    handleNumberInput(e, setSalesGoal);
                                    setErrors((prevState)=>({ ...prevState, sales_goal: "" }));

                                }}

                                />
                                <span 
                                style={{color:`${!salesGoal ? placeholderColor : activeColor}`,position:'absolute',top:'35px', left:'12px'}}>$</span>

                            {errors.sales_goal && <FormHelperText style={{ color: 'red' }}>{errors.sales_goal}</FormHelperText>}
                        </Box>
                        </Grid>
                        <Grid item xs={6} style={{paddingRight:'16px',paddingLeft:'12px',marginBottom:'12px'}}>
                        <Box>
                            { (Utils.getAccountData("agencyBusinessType") === undefined || 
                             Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                                Set the product count goal
                            </Typography> :
                            <Typography variant="body1" style={{ marginBottom: 4 }}>
                            Set the policy count goal
                         </Typography>
                             }
                            <BasicInput
                            value={productCount}
                                name="goalCount"
                                type="number"
                                fullWidth
                                variant="outlined"
                                className={classes.productCount} 
                                style={{color:`${!productCount ? placeholderColor : activeColor}`}}
                                inputProps={{ min: 0, max: 999999 }}
                                onChange={(e) => {
                                    handleNumberInput(e, setProductCount);
                                    setErrors((prevState)=>({ ...prevState, product_count: "" }));
                                }}
                                    
                                />
                            {errors.product_count && <FormHelperText style={{ color: 'red' }}>{errors.product_count}</FormHelperText>}
                        </Box>
                        </Grid>
                    </Grid>
                </CustomScrollbar>
            )}

                <Box
                    
                    padding={2}
                    display={"flex"}
                    justifyContent={"end"}
                >
                    <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            className={lightBlue}
                            variant="text"
                            disableElevation
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            className={topbarNewProductButton}
                            variant="contained"
                            disableElevation
                            disabled={isSubmit}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export default AddOrEditSalesGoalModal;
