import React, {useState} from "react";
import {
    CustomLinearProgress,
    CustomStyledTable,
    StyledTable,
    StyledTableBody,
    StyledTableCell,
    StyledTableRow
} from "./ConnectedEmailStyle";
import GlobalEmptyPage from "../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import {EmptyInboxIcon} from "../../invoiceSettings/common/icons/invoicePageIcons";
import {
    IconButton,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Menu
} from "@material-ui/core";
import {IconList} from "../../../constants/IconList";
import {
    EMAIL_PROVIDER,
    EMAIL_PROVIDER_DEFAULT,
    EMAIL_STATUS
} from "../../../constants/CoreConstants";
import {StyledChip} from "../connectedEmails/ConnectedEmailsStyles";
import {DefaultIcon} from "../../customField/custom-field-v2/helpers/IconList";
import {
   MoreVertOutlined as MoreVertOutlinedIcon,
    Settings as SettingsIcon
} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import EmailConfirmAlert from "../../activity/new-alert/EmailConfirmAlert";

import {
    connectNylas,
    deleteUserConnectedEmail,
    disconnectUserConnectedEmail,
    markEmailAsDefault
} from "../../../api/profileApi";
import Utils from "../../../helpers/Utils";
import CustomConfirmAlert from "../../team/CustomConfirmAlert";
import SkeletonLoader from "../../snapshot/SkeletonLoader";

const statusChipColor = (status) => {
    switch (status) {
        case EMAIL_STATUS.ACTIVE:
            return { backgroundColor: "#27ae60", color: "#fff" };
        case EMAIL_STATUS.INACTIVE:
            return { backgroundColor: "#f39c12", color: "#fff" };
        case EMAIL_STATUS.FAILED:
            return { backgroundColor: "#e74c3c", color: "#fff" };
        case EMAIL_STATUS.WARNING:
            return { backgroundColor: "#C58D09", color: "#fff" };
        default:
            return { backgroundColor: "#3498db", color: "#fff" };
    }
};

const icon = (
    <svg
        width="14"
        height="14"
        viewBox="0 -6 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9 0C4.486 0 0 4.48606 0 9.0001C0 13.5141 4.486 18 9 18C13.514 18 18 13.5141 18 9.0001C18 4.48606 13.514 0 9 0ZM9 16.1818C4.48848 16.1818 1.81818 12.5115 1.81818 9.0001C1.81818 5.48861 4.48848 1.81818 9 1.81818C13.5115 1.81818 16.1818 5.48861 16.1818 9.0001C16.1818 12.5115 13.5115 16.1818 9 16.1818Z"
            fill="#ffffff"
            fillOpacity="5"
        />
        <path
            d="M9.0009 3.24219C8.3327 3.24219 7.78906 3.78619 7.78906 4.45485C7.78906 5.12292 8.3327 5.66643 9.0009 5.66643C9.6692 5.66643 10.2128 5.12292 10.2128 4.45485C10.2128 3.78619 9.6692 3.24219 9.0009 3.24219Z"
            fill="#ffffff"
            fillOpacity="5"
        />
        <path
            d="M8.99893 7.48438C8.49687 7.48438 8.08984 7.89141 8.08984 8.39347V13.848C8.08984 14.3501 8.49687 14.7571 8.99893 14.7571C9.501 14.7571 9.908 14.3501 9.908 13.848V8.39347C9.908 7.89141 9.501 7.48438 8.99893 7.48438Z"
            fill="#ffffff"
            fillOpacity="5"
        />
    </svg>
);

const useStyles = makeStyles((theme) => ({
    iconButton: {
        width: "30px",
        height: "30px",
        background: "rgb(214, 226, 251)",
        marginRight: "8px",
        padding: "4px",
        color: "rgb(52, 111, 239)",
    },
    iconButtonError: {
        width: "30px",
        height: "30px",
        background: "rgb(251, 207, 210)",
        marginRight: "8px",
        padding: "4px",
        color: "rgb(236, 19, 31)",
        "&:focus": {
            background: "rgb(251, 207, 210)",
        },
    },
    stack: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        padding: "8px",
    },
    paper: {
        padding: "8px",
        borderRadius: "8px",
        marginBottom: "8px",
    },
    container: {
        height: "65vh",
        overflowY: "auto",
    },
    tooltip: {
        width: 220,
        maxWidth: 200,
        fontSize: 12,
        padding: 10,
        textAlign: "center",
    },
    radioButtonTextColor:{
        color:'#9e9e9e'
    }
}));

const SUBMIT_ACTION = {
    DISCONNECT: "DISCONNECT",
    RECONNECT: "RECONNECT",
    DELETE: "DELETE",
    MAKE_DEFAULT: "MAKE_DEFAULT"
};

const ConnectedEmailList = ({ isLoading, connectedEmails, setEditEmailLimitModal, setConnectedEmails, fetchConnectedEmails }) => {
    const classes = useStyles();
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event, elem) => {
        setAnchorEl(event.currentTarget);
        setSelectedEmail(elem);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sortedEmails = () => {
        let uSortedEmails = [...connectedEmails];

        return uSortedEmails.sort((a, b) => {
            if (a.isDefault === b.isDefault) return 0;
            if (a.isDefault === EMAIL_PROVIDER_DEFAULT.YES) return -1;
            return 1;
        });
    };

    const handleMakeDefault = (uid, index) => {
        EmailConfirmAlert({
            onSubmit: () => {
                markEmailAsDefault({
                    userConnectedEmailUid: uid,
                })
                    .then((response) => {
                        if (response.data.success) {
                            processAfterDefault(uid);
                            Utils.showNotification(response.data.message, "success");
                        } else {
                            Utils.showNotification(response.data.message, "error");
                        }
                    })
                    .finally(() => {
                        setSelectedEmail(null);
                    });
            },
            title: "Are You Sure?",
            description:"If you select this as the default email, the previous email will no longer be the default.",
            cancelText: "Cancel",
            submitText: "Confirm",
            width: "570px",
        });
    };

    const handleDelete = (uid, isDefault) => {
        CustomConfirmAlert({
            handleConfirm: () => {
                deleteUserConnectedEmail({
                    userConnectedEmailUid: uid,
                })
                    .then((response) => {
                        if (response.data.success) {
                            processAfterDelete(uid);
                            if (isDefault === EMAIL_PROVIDER_DEFAULT.YES) {
                                fetchConnectedEmails().then();
                            }
                            Utils.showNotification(response.data.message, "success");
                        } else {
                            Utils.showNotification(response.data.message, "error");
                        }
                    })
                    .finally(() => {
                        setSelectedEmail(null);
                    });
            },
            callConfirmLast: true,
            title: "Do you want to delete this?",
            description: "If you delete this email you won't be able to restore it again",
            type_word: "DELETE EMAIL",
            icon: "question",
            closeButtonText: "No",
            confirmButtonText: "Yes",
        });
    };

    const handleDisconnect = (uid, isDefault) => {
        EmailConfirmAlert({
            onSubmit: () => {
                disconnectUserConnectedEmail({
                    userConnectedEmailUid: uid,
                })
                    .then((response) => {
                        if (response.data.success) {
                            processAfterDisconnect(uid);
                            if (isDefault === EMAIL_PROVIDER_DEFAULT.YES) {
                               fetchConnectedEmails().then();
                            }
                            Utils.showNotification(response.data.message, "success");
                        } else {
                            Utils.showNotification(response.data.message, "error");
                        }
                    })
                    .finally(() => {
                        setSelectedEmail(null);
                    });
            },
            title: "Are You Sure?",
            description: "Once disconnected, any emails sent to this email address will no longer appear inside the platform.",
            cancelText: "Cancel",
            submitText: "Disconnect",
            width: "570px",
        });
    };

    const initReConnectionProcess = (connectingEmail) => {
        EmailConfirmAlert({
            onSubmit: async () => {
                try {
                    const resp = await connectNylas({ email: connectingEmail });
                    if (resp && resp.success && resp.data && resp.data.redirect) {
                        window.showNotification(
                            "success",
                            "Please wait while redirecting..."
                        );
                        setTimeout(() => {
                            window.open(resp.data.redirect, "_self");
                        }, 2000);
                    } else {
                        window.showNotification(
                            "error",
                            resp?.message || "Unable to connect. Please try again later!",
                            3000
                        );
                    }
                } catch (error) {
                    window.showNotification(
                        "error",
                        "Unable to connect. Please try again later!"
                    );
                } finally {
                    setSelectedEmail(null);
                }
            },
            title: "Are You Sure?",
            description: "Would you like to reconnect this email? This will re-establish the connection and allow you to use it  inside our platform.",            
            cancelText: "Cancel",
            submitText: "Reconnect",
            width: "570px",
        });
    };

    const processAfterDefault = (uid) => {
        setConnectedEmails((prevState) =>
            prevState.map((item) => {
                if (item.uid === uid) {
                    return { ...item, ...{ isDefault: EMAIL_PROVIDER_DEFAULT.YES } };
                } else {
                    return { ...item, ...{ isDefault: EMAIL_PROVIDER_DEFAULT.NO } };
                }
            })
        );
    };

    const processAfterDelete = (uid) => {
        setConnectedEmails((prevState) =>
            prevState.filter((elem) => {
                return elem.uid !== uid;
            })
        );
    };

    const processAfterDisconnect = (uid) => {
        setConnectedEmails((prevState) =>
            prevState.map((item) => {
                if (item.uid === uid) {
                    return { ...item, ...{ status: "INACTIVE" } };
                } else {
                    return { ...item };
                }
            })
        );
    };

    const handleSubmit = async (key) => {
        switch (key) {
            case SUBMIT_ACTION.DISCONNECT:
                handleDisconnect(selectedEmail.uid, selectedEmail.isDefault);
                handleClose();
                break;
            case SUBMIT_ACTION.RECONNECT:
                await initReConnectionProcess(selectedEmail.email);
                handleClose();
                break;
            case SUBMIT_ACTION.DELETE:
                handleDelete(selectedEmail.uid, selectedEmail.isDefault);
                handleClose();
                break;
            case SUBMIT_ACTION.MAKE_DEFAULT:
                handleMakeDefault(selectedEmail.uid);
                handleClose();
                break;
            default:
                console.log("No settings");
        }
    };

    return(
        <div>
            <StyledTable>
                <StyledTableBody>
                    {isLoading && (
                        <SkeletonLoader/>
                    )}

                    {!isLoading && Array.isArray(connectedEmails) && !connectedEmails[0] && (
                        <StyledTableRow>
                            <StyledTableCell colSpan={4}>
                                <GlobalEmptyPage
                                    icon={<EmptyInboxIcon />}
                                    title={
                                        "No email connected. Click 'Add New Personal Email' button to add new email"
                                    }
                                />
                            </StyledTableCell>
                        </StyledTableRow>
                    )}

                    {!isLoading && connectedEmails[0] && (
                        <TableContainer>
                            <CustomStyledTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={"30%"}>Email Accounts</TableCell>
                                        <TableCell align="center">Status</TableCell>
                                        <TableCell align="center">Provider</TableCell>
                                        <TableCell align="center">
                                            <span className="label__name1">Running Bulk Campaigns</span>
                                        </TableCell>
                                        <TableCell>
                                            <span className="label__name1">Sending Limit</span>
                                            <span className="lable__icon1">{IconList.info}</span>
                                        </TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {!isLoading &&
                                        connectedEmails[0] &&
                                        sortedEmails().map((connectedEmail, index) => {
                                            const sendingLimits = connectedEmail?.sendingLimits;
                                            let convertCampaignLimit;
                                            if (
                                                sendingLimits?.total_send == null ||
                                                sendingLimits?.limit == null ||
                                                sendingLimits?.limit === 0 ||
                                                sendingLimits?.total_send === 0
                                            ) {
                                                convertCampaignLimit = 0;
                                            } else {
                                                convertCampaignLimit =
                                                    (sendingLimits.total_send / sendingLimits.limit) * 100;
                                            }

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {connectedEmail?.email}
                                                        {connectedEmail.isDefault ===
                                                            EMAIL_PROVIDER_DEFAULT.YES && (
                                                                <IconButton title={"Default"}>
                                                                    <DefaultIcon
                                                                        height="15px"
                                                                        width="15px"
                                                                        color="#3498db"
                                                                    />
                                                                </IconButton>
                                                            )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <StyledChip
                                                            style={statusChipColor(
                                                                connectedEmail.status.toUpperCase()
                                                            )}
                                                        >
                                                            {connectedEmail.status.toUpperCase()}
                                                        </StyledChip>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {connectedEmail.credentialInfoProvider.toUpperCase() ===
                                                        "IMAP" ? (
                                                            <BootstrapTooltip
                                                                arrow
                                                                title={
                                                                    <span>
                                                                        {icon} IMAP provider support emails incoming and
                                                                        outgoing, not calendar functionalities
                                                                      </span>
                                                                }
                                                                placement="top"
                                                                classes={{ tooltip: classes.tooltip }}
                                                            >
                                                                <StyledChip style={statusChipColor("WARNING")}>
                                                                    <span>{icon} </span>
                                                                    {connectedEmail.credentialInfoProvider.toUpperCase()}
                                                                </StyledChip>
                                                            </BootstrapTooltip>
                                                        ) : (
                                                            <StyledChip style={statusChipColor("WARNING")}>
                                                                {connectedEmail.credentialInfoProvider.toUpperCase()}
                                                            </StyledChip>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {connectedEmail?.count || 0}
                                                    </TableCell>
                                                    <TableCell>
                                                        <CustomLinearProgress
                                                            variant="determinate"
                                                            value={convertCampaignLimit}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <BootstrapTooltip
                                                            arrow
                                                            title={<span>Email Limit Setting</span>}
                                                            placement="top"
                                                            classes={{ tooltip: classes.tooltip }}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => {
                                                                    setEditEmailLimitModal(connectedEmail);
                                                                }}
                                                            >
                                                                <SettingsIcon title={"Setting"} />
                                                            </IconButton>
                                                        </BootstrapTooltip>

                                                        {connectedEmail.provider === EMAIL_PROVIDER.NYLAS && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => {
                                                                    handleClick(event, connectedEmail);
                                                                }}
                                                            >
                                                                <MoreVertOutlinedIcon title={"Action"} />
                                                            </IconButton>
                                                        )}
                                                        {selectedEmail && (
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                keepMounted
                                                            >
                                                                {selectedEmail.isDefault ===
                                                                    EMAIL_PROVIDER_DEFAULT.NO &&
                                                                    selectedEmail.status === EMAIL_STATUS.ACTIVE && (
                                                                        <MenuItem
                                                                            onClick={async () => {
                                                                                await handleSubmit(SUBMIT_ACTION.MAKE_DEFAULT);
                                                                            }}
                                                                        >
                                                                            Make Default
                                                                        </MenuItem>
                                                                    )}
                                                                {selectedEmail.status === EMAIL_STATUS.ACTIVE && (
                                                                    <MenuItem
                                                                        onClick={async () => {
                                                                            await handleSubmit(SUBMIT_ACTION.DISCONNECT);
                                                                        }}
                                                                    >
                                                                        Disconnect
                                                                    </MenuItem>
                                                                )}
                                                                {(selectedEmail.status === EMAIL_STATUS.INACTIVE ||
                                                                    selectedEmail.status === EMAIL_STATUS.FAILED) && (
                                                                    <MenuItem
                                                                        onClick={async () => {
                                                                            await handleSubmit(SUBMIT_ACTION.RECONNECT);
                                                                        }}
                                                                    >
                                                                        Reconnect
                                                                    </MenuItem>
                                                                )}
                                                                <MenuItem
                                                                    onClick={async () => {
                                                                        await handleSubmit(SUBMIT_ACTION.DELETE);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </MenuItem>
                                                            </Menu>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </CustomStyledTable>
                        </TableContainer>
                    )}
                </StyledTableBody>
            </StyledTable>
        </div>
    );
};

export default ConnectedEmailList;