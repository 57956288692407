import React from "react";
import { icons } from "./icon";

const Pagination = (props) => {
  const handlePaginationChange = (event, page) => {
    event.preventDefault();
    if (page != null) {
      props.getCallLogs({ currentPage: page, perPage: props.paginationData.perPage, ...props.paginationData.filterQuery });
      props.setPaginationChange(true);
    }
  };

  const handleChangeSearchPage = (pageNo) => {

    pageNo = pageNo.replace(/[a-z|A-Z|!@#$%^&* ]/g,'')
    if (pageNo !== "") {
      if (parseInt(props.paginationData.totalPages) >= pageNo) {

        props.getCallLogs({ currentPage: pageNo, perPage: props.paginationData.perPage, ...props.paginationData.filterQuery });
        props.setPaginationChange(true);
      }
    }else{
      props.setCurrentPage('')
    }
  };

  let previous_page = null,
    next_page = null;
  
  //let current_page = parseInt(props.paginationData.currentPage);
    
  if (props.paginationData.currentPage > 1) {
    previous_page = props.paginationData.currentPage - 1;
  }

  if (props.paginationData.currentPage !== props.paginationData.totalPages) {
    next_page = props.paginationData.currentPage + 1;
  }

  if (props.paginationData.totalRecords === 0) {
    return null;
  }

  return (
    <div className="pagination_bar">
      <ul className="user__report__pagination__ul">
        <li>
          <a
            href="#!"
            onClick={(event) => handlePaginationChange(event, previous_page)}
            style={
              previous_page == null
                ? {
                    cursor: "not-allowed",
                    backgroundColor: "#9daac1",
                    borderColor: "#9daac1",
                  }
                : {}
            }
          >
            {icons.previous}
          </a>
        </li>
        <li className="active">
          <div className="user-report-pagination-text">
            <input
              className="user__report-pagination-no"
              type="text"
              placeholder="Search Report Page"
              value={props.paginationData.currentPage}
              onChange={(event) => handleChangeSearchPage(event.target.value)}
            />
          </div>
        </li>
        <li>
          <a className="d-flex align-items-center"> {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
            <span>{icons.backwardSlash}</span>
            {props.paginationData.totalPages}
          </a>
        </li>
        <li>
          <a
            href="#!"
            onClick={(event) => handlePaginationChange(event, next_page)}
            style={
              next_page == null
                ? {
                    cursor: "not-allowed",
                    backgroundColor: "#9daac1",
                    borderColor: "#9daac1",
                  }
                : {}
            }
          >
            {icons.next}
          </a>
        </li>
        <li style={{ color: "#9daac1", marginLeft: 20 }}>
          <small className="paginations_text">
            Total Entries : {props.paginationData.totalRecords}
          </small>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
