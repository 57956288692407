import { Box, InputAdornment, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyTypes } from "../../../../actions/ProductAction";
import { PERMISSION_LEVELS, PERMISSION_MODULES } from "../../../../constants/CoreConstants";
import useDebounce from "../../../../hooks/useDebounce";
import { InsuranceCompanyIcon } from "../../../ten-dlc/helpers/Icon";
import PermissionDeniedModal from "../../common/permissionModal/PermissionDeniedModal";
import {
  policyTypeFolderState,
  policyTypeState,
} from "../../common/productSelector";
import { BasicInput } from "../../productsStyle";
import AddOrEditNewPolicyTypeModal from "./AddOrEditNewPolicyTypeModal";
import BasicSelect from "./BasicSelect";
import { policyTypeStyle } from "./PolicyTypesStyle";
import SelectedPoliciesActions from "./SelectedPoliciesActions";
import { CustomButton } from "./policyTypesBody/CustomButton";

const POLICY_TYPE_STATUS = [
  {
    value: "ALL",
    label: "Show All",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

const PolicyTypesHeader = () => {
  const { selectedPolicyList, search } = useSelector(policyTypeState);
  const { selectedFolder } = useSelector(policyTypeFolderState);

  const { topbarHeaderTitle, productBodySubTitle } = policyTypeStyle();
  const [openAddPolicyTypeModal, setOpenAddPolicyTypeModal] = useState(false);
  const [searchValue, setSearchValue] = useState(search ? search : "");
  const debouncedSearch = useDebounce(searchValue, 500);
  const dispatch = useDispatch();
  const [policyStatus, setPolicyStatus] = useState("");
  const [permissionState, setPermissionState] = useState(null);
  const isUserHasPermission = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

  useEffect(() => {
    setPolicyStatus("");
  }, [selectedFolder]);

  const handleFilterStatus = (event) => {
    setPolicyStatus(event.target.value);
    dispatch(
      getPolicyTypes({
        page: 1,
        perPage: 10,
        status: event.target.value,
        userProductFolderId: selectedFolder === "all" ? null : selectedFolder,
        search: search,
      })
    );
  };

  useEffect(() => {
    dispatch(getPolicyTypes({ page: 1, perPage: 25, search: debouncedSearch }));
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleCloseModal = () => {
    setOpenAddPolicyTypeModal(false);
  };

  const handleAddPolicyType = () => {
    if (isUserHasPermission) {
      setOpenAddPolicyTypeModal(true);
    } else {
       setPermissionState("create");
    }
  };

  return (
    <div style={{ borderBottom: "1px solid #d3d3d3" }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"8px 16px"}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <div style={{ marginRight: "5px" }}>
            <InsuranceCompanyIcon />
          </div>
          <div>
            <Typography
              style={{ height: "unset !important" }}
              variant="h5"
              className={topbarHeaderTitle}
            >
              Manage Policy Types
            </Typography>
            <Typography
              style={{ height: "unset !important" }}
              variant="body2"
              className={productBodySubTitle}
            >
              In this section, select the different types of insurance policies
              you offer in your agency. In the status column, click the toggle
              button “on” for each policy type you offer.
            </Typography>
          </div>
        </div>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            gap: 8,
          }}
        >
          <CustomButton
            variant="contained"
            onClick={handleAddPolicyType}
            startIcon={<AddIcon />}
            disableElevation
            style={{ fontSize: 14, paddingBlock: 5 }}
          >
            New Policy Type
          </CustomButton>

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {selectedPolicyList && selectedPolicyList[0] ? (
              <SelectedPoliciesActions />
            ) : (
              <BasicInput
                value={searchValue}
                placeholder="Search for existing policy"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon style={{ color: "#656565" }} />
                  </InputAdornment>
                }
                style={{ width: "265px" }}
                onChange={handleSearch}
              />
            )}
            <div style={{ width: "170px" }}>
              <BasicSelect
                options={POLICY_TYPE_STATUS}
                defaultText="Filter By Status"
                mapping={{
                  value: "value",
                  label: "label",
                }}
                value={policyStatus}
                onChange={handleFilterStatus}
                style={{ width: "170px" }}
              />
            </div>
          </div>
        </Box>
      </Box>

      {openAddPolicyTypeModal && (
        <AddOrEditNewPolicyTypeModal
          open={openAddPolicyTypeModal}
          onClose={handleCloseModal}
        />
      )}

      {permissionState && (
        <PermissionDeniedModal
          open={true}
          onClose={() => setPermissionState(null)}
          permissionModule={"Policy Type"}
          action={permissionState}
        />
      )}
    </div>
  );
};

export default PolicyTypesHeader;