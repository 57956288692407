import {all, call, put, takeEvery} from 'redux-saga/effects';
import * as ACTION from '../constants/profileActionTypes'
import {getPersonalizeTags} from "../api/InvoiceApi";

function* invoicePersonalizeTagWatcher() {
    yield takeEvery(ACTION.GET_INVOICE_PERSONALIZE_TAGS, getInvoicePersonalizeTagsSaga)
}

function* getInvoicePersonalizeTagsSaga(action) {
    try {
        const response = yield call(getPersonalizeTags, action.payload);
        let responseData = response.data;

        if (responseData.success) {
            yield put({type: ACTION.GET_INVOICE_PERSONALIZE_TAGS_SUCCESS, payload: responseData.data});
        } else {
            yield put({type: ACTION.GET_INVOICE_PERSONALIZE_TAGS_ERROR});
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* invoicePersonalizeTagMiddleware() {
    yield all([
            invoicePersonalizeTagWatcher()
        ])
}