import { useState } from 'react';
import { updateCustomFieldDataApi } from "../../../../api/CustomFieldApi";
import { updateLocalStorage } from "../CustomFieldV2App";
import { CloseIcon, EditIcons } from '../helpers/IconList';
import { handleStates, handleStoreFolders } from "../reducer/Actions";
import { useCFContext } from '../reducer/CustomProvider';
import Styles from './style.module.css';
const EditGroup = ({onClose=()=>{}, item, dataIndex }) => {
  const {state, dispatch} = useCFContext();
  const [states, setStates] = useState({
    submitting: false,
    groupName: item?.group_name
  });
  const handleSaveGroupName = () => {
    if(states.submitting){
      return
    }
    const validationCheck = handleFormValidation(states);
    if(validationCheck !== ""){
      window.showNotification('warning', validationCheck);
      setStates({...states, submitting: false})
      return;
    }
    if(item.group_name.toLowerCase() === states.groupName.toLowerCase()){
      window.showNotification('info', "Nothing to update !");
      onClose();
      return
    }
    /* check with all */
    let name_exist = false;
    const folderLength = state.folders.length;
    for(let i = 0; i < folderLength; i++){
      if(state.folders[i].group_name.toLowerCase() === states.groupName.toLowerCase()){
        name_exist = true;
        break;
      }
    }
    if(name_exist){
      window.showNotification('warning', "Folder name already exist !");
      return
    }
    try{
      setStates({...states, submitting: true});
      const previoud_name = item.group_name;
      updateCustomFieldDataApi({
        'field_type' : 'group_name',
        'previous_group_name' : previoud_name,
        'new_group_name': states.groupName
      }).then(res => {
          let response = res.data;
          if(response.status === 'success'){
            onClose();
            window.showNotification('success', response.html);
            let old_data = [...state.folders];
            old_data[dataIndex].group_name = states.groupName
            dispatch(handleStoreFolders(old_data))
            if(previoud_name === state.selectedFolder){
              dispatch(handleStates({
                selectedFolder: states.groupName,
                blockRefresh: true
              }));
            }

            updateLocalStorage()
          }else{
            window.showNotification('error', response.html);
          }
          setStates({...states, submitting: false});
      }).catch(error => {
        setStates({...states, submitting: false});
      })
    }catch(error){}
  }
  return(
    <div className={Styles.mainWrp}>
        <div className={Styles.header}>
          <span className={Styles.headerText}>Rename Folder</span>
        </div>

        <div className={Styles.body}>
          <input className={Styles.folderInput} onChange={(event)=>{setStates({ ...states, groupName: event.target.value })}} value={states.groupName} placeholder='Enter folder name' />
        </div>

        <div className={Styles.footer}>
          <div className={`${Styles.button} ${Styles.cancelButton}`} onClick={onClose}>
            <span className={Styles.icon}><CloseIcon color={'#fff'} height="18px" width='18px' /></span>
            <span className={Styles.buttonText}>Cancel</span>
          </div>

          <div className={`${Styles.button} ${Styles.submitButton}`} onClick={handleSaveGroupName}>
            <span className={Styles.icon}><EditIcons  height='18px' width='18px' color="#fff"/></span>
            <span className={Styles.buttonText}>{states.submitting ? 'Saving...' : 'Save'}</span>
          </div>
        </div>
    </div>
  );
}
export default EditGroup;
const handleFormValidation = (states) => {
  if(states.groupName.trim() === ''){
    return "Please enter folder name !";
  }
  return ""
}