import { useState } from "react";

import Styles from "./style.module.css";
import {
  AddIcon,
  CloseIcon,
} from "../../../../customField/custom-field-v2/helpers/IconList";
import BasicSelect from "../../../../custom/BasicSelect";
import { useDispatch, useSelector } from "react-redux";
import { customFieldInsuranceState } from "../../../../products/common/productSelector";
import { CHECKBOX, FieldTypes, PERMISSION_LEVELS, PERMISSION_MODULES, PRIVACY_LEVEL, PRIVACY_OPTION_CUSTOM_FIELD, PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT, RADIO, SELECT } from "../../../../../constants/CoreConstants";
import SelectWithIcon from "./SelecteWithIcon";
import BasicInput from "../../../../custom/BasicInput";
import { addCustomField, handleSelectedCustomFieldFolder, updateCustomFieldInsurance } from "../../../../../actions/CustomFieldInsuranceAction";
const parseDefaultValues = (defaultValues) => {
  try {
    return JSON.parse(defaultValues).join(", ");
  } catch (error) {
    console.error("Invalid JSON in defaultValues:", error);
    return ''; 
  }
};
const AddCustomField = ({
  title = "Add New Custom Field",
  onClose = () => {},
  edit=null
}) => {
  const { folderList, customFieldParams, isAddingCustomField, isUpdatingField } = useSelector(customFieldInsuranceState);
const folderObj = {};
folderList.forEach((folder) => {
  folderObj[folder.id] = folder.title;
});

const getGroupNameById = (id) => folderObj[id] || " ";
const canUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);

  const [states, setStates] = useState({
    field_type: edit? edit.type_id : FieldTypes[0].value,
    field_name: edit ? edit.title : "",
    field_value: edit ? (edit.default_values ? parseDefaultValues(edit.default_values): '') : "",
    group_id: edit ? edit.user_custom_field_group_id : customFieldParams.group_id || folderList[0]?.id,
    group_name: getGroupNameById(edit ? edit.user_custom_field_group_id : customFieldParams.group_id || folderList[0]?.id),
    privacy_level: !canUserManageGlobalItem ?  PRIVACY_LEVEL.PRIVATE : PRIVACY_LEVEL.GLOBAL
  });
  const [errors, setErrors] = useState({
    field_name: "",
    field_type:"",
    field_value:"",
  });
  const isGroupIdChanged = states.group_id !== edit?.user_custom_field_group_id;
  const groupId = isGroupIdChanged ?  states.group_id : customFieldParams.group_id;

  const isFieldUpdated = states.field_name !== edit?.title || 
  states.field_value !== parseDefaultValues(edit?.default_values) || 
  isGroupIdChanged;
  
  const dispatch = useDispatch();
  const handleFieldType = (type) => {
    setErrors({...errors,  field_type: "" });
    setStates({ ...states, field_type: type });
  };
  const handleFieldName = (e) => {
    setErrors({...errors,  field_name: "" });
    setStates({ ...states, field_name: e.target.value });
  };
  const handleFieldValue = (e) => {
    setErrors({...errors,  field_value: "" });
    setStates({ ...states, field_value: e.target.value });
  };
  const handleGroupName = (id) => {
    setStates({ ...states,  group_id: id, group_name: getGroupNameById(id) });
  };
  const handlePrivacyLevel = (e) => {
    setStates({ ...states, privacy_level: e.target.value });
  }

  const validationForm = () => {
    const errors = {};
    const fieldName = states.field_name?.trim();

    if (!fieldName) {
      errors.field_name = "Field name is required.";
    } else if (fieldName.length > 180) {
      errors.field_name = "Field name must not exceed 180 characters.";
    }

    if (!states.field_type) {
      errors.field_type = "Field is required.";
    }

    if(((states.field_type === CHECKBOX ||
      states.field_type === SELECT ||
      states.field_type === RADIO ) && !states.field_value?.trim())) {
        errors.field_value = "Field value is required.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validationForm() || isAddingCustomField || isUpdatingField || !isFieldUpdated) {
      return;
    }

    if(edit){

      const payload = {
        id: edit.id,
        title: states.field_name,
        field_value: states.field_value,
        group_id: states.group_id,
        group_name: states.group_name,
      };
      dispatch(
        updateCustomFieldInsurance({
          ...payload,
          callback: () => {
            onClose();
             dispatch(handleSelectedCustomFieldFolder(groupId));
          },
        })
      );
    }
    else {
      const payload = {
        title: states.field_name,
        field_type: states.field_type,
        field_value: states.field_value,
        group_id: states.group_id,
        group_name: states.group_name,
        privacy_level: states.privacy_level,
      };
      dispatch(
        addCustomField({
          ...payload,
          callback: () => {
            onClose();
             dispatch(handleSelectedCustomFieldFolder(states.group_id));
          },
        })
      );
    }
  };
  const renderFieldTypes = () => {
    const view = [];
    FieldTypes.forEach((item, index) => {
      view.push(
        <span
          onClick={() => handleFieldType(item.value)}
          key={index}
          className={`${Styles.fieldTypeWrp} ${
            states.field_type === item.value ? Styles.selected : ""
          }`}
        >
          {item.title}
          {item.new && <small className={Styles.new}>new</small>}
        </span>
      );
    });
    return view;
  };
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.title}>{title}</span>
        <span className={Styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
       {!edit &&  ( 
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            What kind of field would you like to add?
            <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.contentBlock}>{renderFieldTypes()}</div>
          {errors.field_type && (
            <div className={Styles.error}>{errors.field_type}</div>
          )}
        </div>
      )}

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Field Name <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <BasicInput
              onChange={handleFieldName}
              value={states.field_name}
              placeholder="Enter field name"
              fullWidth
            />
          </div>
          {errors.field_name && (
            <div className={Styles.error}>{errors.field_name}</div>
          )}
        </div>

       {!edit && ( 
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Field Type</span>
          <div className={Styles.inputFieldWrp}>
            <BasicSelect
              options={canUserManageGlobalItem ? PRIVACY_OPTION_CUSTOM_FIELD : PRIVACY_OPTION_CUSTOM_FIELD_FOR_AGENT}
              mapping={{ label: "label", value: "value" }}
              defaultText="Select Field Type"
              onChange={handlePrivacyLevel}
              value={states.privacy_level}
            />
          </div>
        </div>
      )}

        {/* for value: check box, radio button, drop down */}
        {(states.field_type === CHECKBOX ||
          states.field_type === SELECT ||
          states.field_type === RADIO ) && (
          <div className={Styles.inputWrp}>
            <span className={Styles.lebelText}>
              Set value <code style={{ color: "red" }}>*</code>
            </span>
            <div className={Styles.inputFieldWrp}>
              <BasicInput
                onChange={handleFieldValue}
                value={states.field_value}
                fullWidth
                placeholder="Enter field value"
              />
              <span className={Styles.hintText}>
                Multiple values separated by <code>,</code> sign. For example:
                value1 , value2 , value3
              </span>
            </div>
            {
              errors.field_value && (
                <div className={Styles.error}>{errors.field_value}</div>
              )
            }
          </div>
        )}

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Folder Group</span>
          <div className={Styles.inputFieldWrp}>
            <SelectWithIcon
              options={folderList}
              mapping={{ label: "title", value: "id" }}
              defaultText="Select Folder Group"
              onChange={(e) => handleGroupName(e.target.value)}
              value={states.group_id}
            />
          </div>
        </div>
      </div> 

      <div className={Styles.footerWrp}>
        <div className={Styles.buttonWrp}>
          <div className={(isAddingCustomField || isUpdatingField || !isFieldUpdated) ? Styles.disabledButton : Styles.button} onClick={handleSubmit}>
            <span className={Styles.submitIcon}>
              <AddIcon />{" "}
            </span>
            <span className={Styles.submitText}>
              {edit ? ( isUpdatingField ? "Updating Field" : "Update Field") : (isAddingCustomField ? "Adding Field..." : "Add Field")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCustomField;