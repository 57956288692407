import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginBottom: '20px',
  },
  pageTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  pageSubtitle: {
    fontSize: '14px',
    color: '#666',
  },
  pageBody: {
    marginTop: '20px',
  },
  tableContainer: {
    marginTop: '20px',
  },
  skeletonText: {
    marginBottom: theme.spacing(1),
  },
  skeletonButton: {
    marginTop: '20px',
  },
  skeletonTableCell: {
    width: '100%',
  },
}));

const ShakenStirCnamSkeleton = () => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.pageHeader}>
        <Skeleton variant="text" width={250} height={40} className={classes.skeletonText} />
        <Skeleton variant="text" width={600} height={20} className={classes.skeletonText} />
        <div style={{  marginTop: '20px' }}>
          <Skeleton variant="text" width={600} height={20} className={classes.skeletonText} />
          <Skeleton variant="text" width={600} height={20} className={classes.skeletonText} />
          <Skeleton variant="text" width={600} height={20} className={classes.skeletonText} />
        </div>
      </div>

      <div className={classes.pageBody}>
        <Skeleton variant="rect" width="100%" height={40} className={classes.skeletonText} />
        <div className={classes.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width={100} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton variant="text" width={150} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} className={classes.skeletonTableCell} />
                  </TableCell>
                  <TableCell align="center">
                    <Skeleton variant="rect" width={100} height={40} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Skeleton variant="text" width="100%" height={40} className={classes.skeletonText} />
      </div>
    </Fragment>
  );
};

export default ShakenStirCnamSkeleton;
