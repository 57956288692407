import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/Paper";
import {
  Button,
  FormControl,
  makeStyles,
  Typography,
  Box,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import Styles from "./SkipTraceModal.module.css";
import {addContactWithSkipTrace, carrierLookupMapApi} from '../../api/profileApi';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "&.MuiButton-root": {
          backgroundColor: '#008945'
        },
        "&.MuiButton-text": {
          color: "grey"
        },
        "&.MuiButton-contained": {
          color: "white"
        },
        "&.MuiButton-outlined": {
          color: "brown"
        }
      }
    };
  });

const SkipTraceModal = (props) => {
  const { id } = useParams();
  const [CURRENT_RESIDENT,CURRENT_OWNER] = ["HOUSE_CURRENT_RESIDENT","HOUSE_CURRENT_OWNER"];
  const [NEW_CONTACT, ADDITIONAL_CONTACT] = ["ADD_NEW_CONTACT", "ADD_ADDITIONAL_CONTACT"];
  const [selectedOption, setSelectedOption] = useState(CURRENT_OWNER);
  const [selectedAction, setSelectedAction] = useState(NEW_CONTACT);
  const [loading,setLoading]=useState(false);


  const onSubmit = () => { 
    setLoading(true);
    carrierLookupMapApi({
      'mapFetchContactIds': props.selectedIds,
      'searchCategory': [selectedOption],
      'lookupType': ['SKIP_TRACING_LOOKUP'],
      'requestModule': 'MAP'
    })
      .then(response => {
        if(response.success && response.status){
          props.setOpen(false);
          props.setReset(props.reset + 1);
          window.showNotification("SUCCESS", response.message);
        }else{
          window.showNotification("ERROR", response.message);
        }
        
      })
      .catch(error => {
        console.log(error);
      }).finally(()=>{
        setLoading(false);
      });
  }

  const classes = useStyles();

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${Styles.modalWrapper} global-xs-modal add_list_modal`}
    >
      <Paper className={Styles.modalInner}>
        <div className={Styles.headerWrapper}>
          <Typography variant="h5">Skip trace</Typography>
          <Box className={Styles.headerClose} onClick={() => { props.setOpen(false) }}>
            <CloseIcon />
          </Box>
        </div>
        <div className={Styles.paddingWrp}>
          <div className={Styles.modalBody}>
            <FormControl className={Styles.formControl}>
              <span className={Styles.label}>
                Select one that apply
                <span className={Styles.required}> *</span>
              </span>
              <div>
                <Radio
                  checked={selectedOption === CURRENT_OWNER}
                  onClick={() => {
                    setSelectedOption(CURRENT_OWNER);
                  }}
                />
                Current Owner
              </div>
              <div>
                <Radio
                  checked={selectedOption === CURRENT_RESIDENT}
                  onClick={() => {
                    setSelectedOption(CURRENT_RESIDENT);
                  }}
                />
                Current Resident
              </div>
            </FormControl>
          </div>

          {/*<p>What do you want to do with Skip Trace Data?</p>*/}
          {/*  <FormControl onChange={(event)=>{*/}
          {/*        setSelectedAction(event.target.value);*/}
          {/*    }} style={{ flexDirection: "row" }}>*/}
          {/*      <FormControlLabel*/}
          {/*          name="action"*/}
          {/*          value={NEW_CONTACT}*/}
          {/*          checked={selectedAction === NEW_CONTACT}*/}
          {/*          control={<Radio color="secondary" />}*/}
          {/*          label="Add as New Contact"*/}
          {/*      />*/}
          {/*      <FormControlLabel*/}
          {/*          name="action"*/}
          {/*          value={ADDITIONAL_CONTACT}*/}
          {/*          checked={selectedAction === ADDITIONAL_CONTACT}*/}
          {/*          control={<Radio color="secondary" />}*/}
          {/*          label="Add as Additional Contact"*/}
          {/*      />*/}
          {/*    </FormControl>*/}
          <div className={Styles.modalFooter}>
            <Button variant="contained" onClick={() => { props.setOpen(false) }} size="large">Cancel</Button>
            <Button className={classes.root} variant="contained" size="large" disable={loading} onClick={() => { onSubmit() }}>
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}

export default SkipTraceModal;
