import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import BodySkeleton from "../BodySkeleton";
import { CustomScrollbar } from "../../../productsStyle";
import InsuranceCompaniesTable from "./InsuranceCompaniesTable";
import BasicSelect from "../../../policyTypes/content/BasicSelect";
import { policyTypeStyle } from "../../../policyTypes/content/PolicyTypesStyle";

const  INSURANCE_COMPANIES_STATUS = [
  {
    value: "ALL",
    label: "Show All",
  },
  {
    value: "ACTIVE",
    label: "Active",
  },
  {
    value: "INACTIVE",
    label: "Inactive",
  },
];

const useStyles = makeStyles({
  bodyWrapper: { padding: "10px 16px 10px 16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  filterStatus: {
    width: "202px !important",
  }
});

const ContentBody = ({
  insuranceCompanyList,
  setInsuranceCompanyList,
  selectedFolder,
  isLoading,
  folderList,
  handleFolderList,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  page,
  total,
  folderObject,
  setFilterTrigger,
  filterTrigger,
  setSelectedCompanies,
  selectedCompanies,
  status,
  setStatus,
}) => {

  const classes = useStyles();
  const { productBodyTitle, grayColor } = policyTypeStyle();  
  useEffect(()=>{
      setStatus('');
    },[selectedFolder]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterStatus = (e) => {
    setStatus(e.target.value);
    setFilterTrigger(filterTrigger+1);
  };


  const handleUpdateCompanyName = (payload) => {
    setInsuranceCompanyList((prevState) =>
      prevState.map((company) => {
        if (company.id === payload?.insurance_company_id) {
          return {
            ...company,
            company_name: payload.company_name,
            folders:payload.insurance_company_folder_ids
          };
        }
        return company;
      })
    );
  };

 


    return (
      <div className={classes.bodyWrapper}>
         <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography 
           className={productBodyTitle}
           variant="h6">
            All Insurance Companies
          </Typography>
          <Typography variant="body2" 
           className={grayColor}
          >
            In this section, list all the types of insurance companies available through your agency.<br />
            If you offer the insurance company click the toggle button on. If you don't click the toggle button off.
          </Typography>
        </div>

        <div>
          <BasicSelect
            options={INSURANCE_COMPANIES_STATUS}
            defaultText="Filter By Status"
            mapping={{
              value: "value",
              label: "label",
            }}
            value={status}
             onChange={handleFilterStatus}
            className={classes.filterStatus}
          />
          
        </div>
      </div>

       { isLoading ? <BodySkeleton length={8}/> :
       ( <CustomScrollbar style={{ maxHeight: "calc(100vh - 270px)" }}>
          <div>
            <InsuranceCompaniesTable 
            insuranceCompanyList={insuranceCompanyList}
            folderList={folderList}
            handleFolderList={handleFolderList}
            selectedFolder={selectedFolder}
            setInsuranceCompanyList={setInsuranceCompanyList}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            folderObject={folderObject}
            onUpdateCompanyName={handleUpdateCompanyName}
            setFilterTrigger={setFilterTrigger}
            filterTrigger={filterTrigger}
            setSelectedCompanies={setSelectedCompanies}
            selectedCompanies={selectedCompanies}
            />
          </div>
        </CustomScrollbar>
      )}
      </div>
    );
};

export default ContentBody;
