import React, {useEffect, useState} from "react";
import {getCardInfoSettings} from "../../../api/profileApi";
import CardWrapper from "./CardWrapper";
import Loader from "../../globals/Loader";

const CardEnv = (props) => {
    const [stripePublicKey, setStripePublicKey] = useState("");
    const [searchingMapUser, setSearchingMapUser] = useState(true);

    useEffect(() => {
        getCardInfoSettings({})
            .then(response => {
                if (response.data && response.data.data && response.data.data.customer){
                    props.updateReducerInformations({ mapUserCardInfo: response.data.data.customer, useExisitngCard: true });
                }else {
                    props.updateReducerInformations({ mapUserCardInfo: response.data.data.customer, useExisitngCard: false });
                }
                setStripePublicKey(response.data.data.stripePublicKey);
            })
            .catch((err)=>{
                console.log(err);
            })
            .finally(() => {
            setSearchingMapUser(false);
        });
    }, []);

    return(searchingMapUser ? <Loader/> : <CardWrapper searchingMapUser={searchingMapUser} updateReducerInformations={props.updateReducerInformations} packageInformation={props.packageInformation} getTeamList={props.getTeamList} stripePublicKey={stripePublicKey}/>);
};

export default CardEnv;