import React from "react";
import {
    Modal
} from "@material-ui/core";
import {EventNote} from "@material-ui/icons";
import {
    StyledCancelButton,
    StyledConnectEmailButton,
    StyledConnectEmailContent,
    StyledConnectEmailIconWrapper,
    StyledConnectEmailWrapper
} from "./ConnectedEmailStyle";
import {useHistory} from "react-router-dom";

const ConnectEmailCalenderSyncModal = ({ open, onClose }) => {
    const history = useHistory();

    return(
        <Modal
            open={open}
            onClose={onClose}
            className={"global-xs-modal add_list_modal"}
            style={{ display: "grid", placeContent: "center" }}
        >
            <StyledConnectEmailWrapper>
                <StyledConnectEmailContent>
                    <StyledConnectEmailIconWrapper>
                        <EventNote />
                    </StyledConnectEmailIconWrapper>
                    <h1>Sync your calendar events</h1>
                    <p>Synchronize your connected email calendar events with your account calendar</p>
                    <div>
                        <StyledCancelButton onClick={onClose}>Maybe later</StyledCancelButton>
                        <StyledConnectEmailButton onClick={() => { history.push("/user/profile/calendar-sync") }}>Connect calendar</StyledConnectEmailButton>
                    </div>
                </StyledConnectEmailContent>
            </StyledConnectEmailWrapper>
        </Modal>
    );
};

export default ConnectEmailCalenderSyncModal;