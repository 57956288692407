import { Button, makeStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from "react";
import AddTagModal from "./AddTagModal";
// import AddNewUserModal from "./AddNewUserModal";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #3c7ef31a",

    "& .headerLeft": {
      display: "flex",
      alignItems: "center",
      gap: 20,

      "& .headerText": {
        "& .title": {
          fontSize: 20,
          fontWeight: "bold",
          margin: "0 0 8px 0",
          color: "var(--dark_blue)",
        },

        "& .description": {
          fontSize: 14,
          color: "#525252",
          margin: 0,
        },
      },
    },

    "& .headerRight": {
      display: "flex",
      alignItems: "flex-start",
      gap: 8,

      "& .backButton": {
        width: 110,
      }
    },

    "& .addNewUserButton": {
      backgroundColor: "#3f51b5",
      "&:hover, &:focus": {
        backgroundColor: "#3f51b5",
      },
    },
  },
}));

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className="headerLeft">
        <img
          src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
          alt=""
          height={70}
        />

        <div className="headerText">
          <h5 className="title">Tags</h5>
          <p className="description">
          Tags are identifiers that help organize contacts into groups based on shared attributes, such as interests, prefernces, or <br /> demographics. Tags can help you segment your customer base into groups for targeted marketing.
          </p>
        </div>
      </div>

      <div className="headerRight">
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          className="backButton"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          disableElevation
          className="addNewUserButton"
          onClick={() => setIsModalOpen(true)}
        >
          Add Tag
        </Button>
      </div>

      {isModalOpen && (
        <AddTagModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Header;