import * as ACTION from "../constants/profileActionTypes";

const initialState = {
    data : [],
    isLoading: true,
    isSuccess: false,
};

const invoicePersonalizeTagReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_INVOICE_PERSONALIZE_TAGS:
            return {...state, isLoading: true}
        case ACTION.GET_INVOICE_PERSONALIZE_TAGS_SUCCESS:
            return {...state, isLoading: false, isSuccess: true, data: action.payload}
        case ACTION.GET_INVOICE_PERSONALIZE_TAGS_ERROR:
            return {...state, isLoading: false, isSuccess: false, data: []}

        default:
            return state;
    }
}

export default invoicePersonalizeTagReducer;
