
import { Box, Grid, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import "./CardPayment.css";
import { createRef, useEffect, useState } from "react";
import Utils from "../../helpers/Utils";
import { externalServiceLoginCheckApi, getCompanyLogoApi, mysDowngradePackageApi, mysUpgradePackageApi, submitMapIntegrationApi } from "../../api/profileApi";
import moment from "moment";
import { CircularProgress } from '@material-ui/core';
import BootstrapTooltip from "../globals/BootstrapTooltip";
import CustomConfirmAlert from "../team/CustomConfirmAlert";


const CardPayment = (props) => {

    const numberRef = createRef();
    const expiryRef = createRef();
    const cvcrRef = createRef();

    const [searchingMapUser, setSearchingMapUser] = useState(true)
    const [radioValue, setRadioValue] = useState('exists')

    useEffect(() => {
        externalServiceLoginCheckApi().then((res) => {

            if (res.data.status === 'success' && res.data.data && res.data.data.html.user) {

                if (res.data.data.html.card) {
                    props.updateReducerInformations({ mapUserCardInfo: res.data.data.html.card, mapUserInfo: res.data.data.html.user })

                } else {
                    props.updateReducerInformations({ mapUserInfo: res.data.data.html.user, useExisitngCard: false })

                }
            }
            setSearchingMapUser(false)

        }).catch((e) => {
            setSearchingMapUser(false)
        })
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!props.packageInformation.companyLogo) {

            getCompanyLogoApi().then((res) => {

                let response = res.data;

                if (response.status === 'success') {
                    props.updateReducerInformations({ 'companyLogo': response.companyLogo })

                }
            })
        }

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    const updateCardInfo = (value, key) => {
        if (key === 'expiry') {

            expiryCheck(value, key)
        } else if (key === 'cardNumber') {
            checkCardNumber(value, key)
        } else if (key === 'cvc') {
            checkCvc(value, key)
        }
        else {
            props.updateReducerInformations({ [key]: value })

        }
    }

    const checkCardNumber = (value, key) => {

        value = Utils.returnFilteredText(value, 'number')
        let length = value.length;
        if (length <= props.packageInformation.cardmaxLength) {
            if (length <= 4) {
                props.updateReducerInformations({ [key]: value })

            } else if (length <= 8) {
                props.updateReducerInformations({ [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) })

            }
            else if (length <= 12) {
                props.updateReducerInformations({ [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) })

            } else if (length === 16) {

                props.updateReducerInformations({ [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12) })
                // setFocusUpdate('expiryRef')
                expiryRef.current.focus()
            } else {
                props.updateReducerInformations({ [key]: value.substr(0, 4) + ' ' + value.substr(4, 4) + ' ' + value.substr(8, 4) + ' ' + value.substr(12) })

            }

        }

    }

    const checkCvc = (value, key) => {
        value = Utils.returnFilteredText(value, 'number')
        if (value.length <= 4) {
            props.updateReducerInformations({ [key]: value })

        }
    }

    const expiryCheck = (value, key) => {
        value = Utils.returnFilteredText(value, 'number')
        if (value.length <= 2) {

            if (value > 12) {
                props.updateReducerInformations({ [key]: 12 + '/' })
            }
            else {
                if (value.length === 1) {
                    props.updateReducerInformations({ [key]: value })

                } else {
                    props.updateReducerInformations({ [key]: value + '/' })

                }
            }

        } else if (value.length === 3) {

            if (value[2] > 1)
                props.updateReducerInformations({ [key]: value[0] + value[1] + '/' + value[2] })

        } else if (value.length === 4) {

            if ((value[2] > 1 && value[2] < 3 && value[3] > 1) || value[2] > 3) {
                cvcrRef.current.focus()
                props.updateReducerInformations({ [key]: value[0] + value[1] + '/' + value[2] + value[3] })
            }


        }


    }

    let totalPrice = `${(parseFloat(props.packageInformation.selectedPackage?.subscription_fee) + parseFloat(props.packageInformation.selectedPackage?.additional_charge)).toFixed(2)}`

    const cardCheck = (month, year) => {
        if (props.packageInformation.cardNumber.trim() === "" || month?.trim() === "" || year?.trim() === "" || props.packageInformation.cvc.trim() === "" || props.packageInformation.selectedPackage == null) {
            window.showNotification("WARNING", "Fill up the form correctly. Every field is required");
            props.updateReducerInformations({ 'submitting': false });
            return false;
        }

        if (month === 0 || month === "00" || month === "0") {
            window.showNotification("WARNING", "Month is wrong !");
            props.updateReducerInformations({ 'submitting': false });
            return false;
        }

        if (year.trim().length < 2) {
            window.showNotification("WARNING", "Year is wrong !");
            props.updateReducerInformations({ 'submitting': false });
            return false;
        }

        if (props.packageInformation.cvc.trim().length < 3 || props.packageInformation.cvc.trim().length > 4) {
            props.updateReducerInformations({ 'submitting': false });
            window.showNotification("WARNING", "CVC is wrong !");
            return false;
        }
        return true
    }

    const newUserCreate = (month, year) => {
        let fullName = Utils.getAccountData('fullName');
        let names = fullName.split(' ', 1)
        let firstName = names[0];
        let lastName = fullName.replace(firstName, '')

        let form_data = {
            'email': Utils.getAccountData('email'),
            'phone': props.packageInformation.userNumber,
            'first_name': firstName.trim(),
            'last_name': lastName != null ? lastName.trim() : ' ',
            'number': props.packageInformation.cardNumber.replace(/\s/g, ''),
            'exp_month': month,
            'exp_year': year,
            'cvc': props.packageInformation.cvc,
            'agency_id': Utils.getAccountData('agencyId'),
            'from': 1,
            'user_id': Utils.getAccountData('userId'),
            'package_id': props.packageInformation.selectedPackage.package_id,
            'additional_charge': parseFloat(props.packageInformation?.additionalCharge),
            'total_usages': parseInt(props.packageInformation.selectedPackage?.usage_limit),
            'total_users': parseInt(props.packageInformation.selectedPackage?.max_user_count),
            'new_card': props.packageInformation.useExisitngCard ? false : true
        }

        submitMapIntegrationApi(form_data).then(res => {
            let response = res.data;
            if (response.status) {

                if (response.html === 'User already exists!') {
                    let info = response.data;
                    if (info.renew_date && info.renew_date.date) {
                        info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
                    }
                    props.updateReducerInformations({
                        'submitModal': 'exists', 'hasPackage': true, 'packageInfo': info, 'message':
                            response.html, 'submitting': false
                    })

                } else {
                    let info = response.data;
                    let successMessage = <>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.<br />Transaction Id : <b>{info.transaction_id}</b></>
                    if (info.renew_date && info.renew_date.date) {
                        info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
                    }
                    info['subscription_fee'] = props.packageInformation.selectedPackage.subscription_fee;
                    props.updateReducerInformations({
                        'submitModal': 'success', 'hasPackage': true, 'packageInfo': info, 'message': successMessage, 'transactionId': info.transaction_id
                        , 'submitting': false, 'paymentPage': false,
                    })

                }

                sessionStorage.setItem('mapPackage' + Utils.getAccountData('userId'), 'true');
                props.getTeamList();
            } else {
                if (response.data === "Can not create stripe token !") {
                    window.showNotification("ERROR", "We aren't able to process your payment with this card. Please check your card.");
                } else {
                    if (response.data !== undefined && response.data !== null) {
                        if (response.data.html !== undefined && response.data.html !== null) {
                            window.showNotification("ERROR", response.data.html);
                        } else {
                            window.showNotification("ERROR", "Something went wrong !");
                        }
                    } else {
                        window.showNotification("ERROR", "Something went wrong !");
                    }
                }
                props.updateReducerInformations({
                    'submitModal': 'error', message:
                        'We aren\'t able to process your payment. Your Balance Low . Please Try Again', 'submitting': false
                })
            }
            // updateReducerInformation(false,'submitting');
        }).catch(error => {
            props.updateReducerInformations({ 'submitting': false });
        })
    }

    const downgradePackage = (month, year) => {

        let form_data = {
            'exp_month': month,
            'exp_year': year,
            'cvc': props.packageInformation.cvc,
            'package_id': props.packageInformation.selectedPackage.package_id,
            'new_card': props.packageInformation.useExisitngCard ? false : true,
            'number': props.packageInformation.cardNumber.replace(/\s/g, ''),
            'map_api_key': props.packageInformation.mapUserInfo.auth_token,
        }

        mysDowngradePackageApi(form_data).then(res => {
            let response = res.data;

            if (response.status === 'success') {

                let info = props.packageInformation.packageInfo;
                info['downgrade_package_id'] = props.packageInformation.selectedPackage.package_id;
                info['is_downgrade'] = 1;
                props.updateReducerInformations({
                    'submitModal': 'success-downgrade', 'hasPackage': true, 'packageInfo': info, 'message': response.message
                    , 'submitting': false, 'paymentPage': false, 'changePackage': false
                })

                window.showNotification('SUCCESS', response.message);
            } else {
                props.updateReducerInformations({
                    'submitting': false
                });
                window.showNotification('ERROR', response.message);
            }
            // if (response.status) {

            //     if (response.html === 'User already exists!')  {
            //         let info = response.data;
            //         let successMessage = <>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.<br />Transaction Id : <b>{info.transaction_id}</b></>
            //         if (info.renew_date && info.renew_date.date) {
            //             info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
            //         }
            //         props.updateReducerInformations({
            //             'submitModal': 'success', 'hasPackage': true, 'packageInfo': info, 'message': successMessage, 'transactionId': info.transaction_id
            //             , 'submitting': false
            //         })

            //     }

            //     sessionStorage.setItem('mapPackage' + Utils.getAccountData('userId'), 'true');
            //     props.getTeamList();
            // } else {
            //     if (response.data === "Can not create stripe token !") {
            //         window.showNotification("ERROR", "We aren't able to process your payment with this card. Please check your card.");
            //     } else {
            //         if (response.data !== undefined && response.data !== null) {
            //             if (response.data.html !== undefined && response.data.html !== null) {
            //                 window.showNotification("ERROR", response.data.html);
            //             } else {
            //                 window.showNotification("ERROR", "Something went wrong !");
            //             }
            //         } else {
            //             window.showNotification("ERROR", "Something went wrong !");
            //         }
            //     }
            //     props.updateReducerInformations({
            //         'submitModal': 'error', message:
            //             'We aren\'t able to process your payment. Your Balance Low . Please Try Again', 'submitting': false
            //     })
            // }
        }).catch(error => {
            props.updateReducerInformations({ 'submitting': false });
        })
    }

    const upgradePackage = (month, year) => {

        let form_data = {
            'package_id': props.packageInformation.selectedPackage.package_id,
            'additional_charge': parseFloat(props.packageInformation.additionalCharge),
            'new_card': props.packageInformation.useExisitngCard ? false : true,
            'number': props.packageInformation.cardNumber.replace(/\s/g, ''),
            'exp_month': month,
            'exp_year': year,
            'cvc': props.packageInformation.cvc,
            'map_api_key': props.packageInformation.mapUserInfo.auth_token,
            'from': 1,
            'total_users': props.packageInformation.selectedPackage.max_user_count,
            'total_usages': props.packageInformation.selectedPackage.usage_limit,

        }

        mysUpgradePackageApi(form_data).then(res => {
            let response = res.data;

            if (response.status) {

                let info = response.data;
                let successMessage = <>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.<br />Transaction Id : <b>{info.transaction_id}</b></>
                if (info.renew_date && info.renew_date.date) {
                    info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
                }
                props.updateReducerInformations({
                    'submitModal': 'success', 'hasPackage': true, 'packageInfo': info, 'message': successMessage, 'transactionId': info.transaction_id
                    , 'submitting': false, 'paymentPage': false, 'changePackage': false
                })

                sessionStorage.setItem('mapPackage' + Utils.getAccountData('userId'), 'true');
                props.getTeamList();
                // if (response.html === 'User already exists!') {
                //     let info = response.data;
                //     if (info.renew_date && info.renew_date.date) {
                //         info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
                //     }
                //     props.updateReducerInformations({
                //         'submitModal': 'exists', 'hasPackage': true, 'packageInfo': info, 'message':
                //             response.html, 'submitting': false
                //     })

                // } else {
                //     let info = response.data;
                //     let successMessage = <>The payment of <b>${(parseFloat(props.packageInformation.selectedPackage.subscription_fee) + parseFloat(props.packageInformation.additionalCharge)).toFixed(2)}</b> is successful.<br />Transaction Id : <b>{info.transaction_id}</b></>
                //     if (info.renew_date && info.renew_date.date) {
                //         info.renew_date = moment(info.renew_date.date).format('MM-DD-YYYY')
                //     }
                //     props.updateReducerInformations({
                //         'submitModal': 'success', 'hasPackage': true, 'packageInfo': info, 'message': successMessage, 'transactionId': info.transaction_id
                //         , 'submitting': false
                //     })

                // }

                // sessionStorage.setItem('mapPackage' + Utils.getAccountData('userId'), 'true');
                // props.getTeamList();
            } else {
                if (response.data === "Can not create stripe token !") {
                    window.showNotification("ERROR", "We aren't able to process your payment with this card. Please check your card.");
                }
                else {
                    if (response.data && response.data.html) {
                        window.showNotification("ERROR", response.data.html);
                        
                    } else {
                        console.log('payemnt fail ',[response]);
                        window.showNotification("ERROR", "Something went wrong !");
                    }
                }
                props.updateReducerInformations({
                    'submitModal': 'error', message:
                        'We aren\'t able to process your payment. Your Balance Low . Please Try Again', 'submitting': false
                })
            }
            props.updateReducerInformation(false, 'submitting');
        }).catch(error => {
            props.updateReducerInformations({ 'submitting': false });
        })
    }

    const handleSubmit = () => {
        if (props.packageInformation.submitting) {
            return;
        }

        if (props.packageInformation.packageLoading) {
            window.showNotification('Geofarming package updating...')
        }
        /* check validation */

        let splitExpiry = props.packageInformation.expiry.split('/');
        let month = splitExpiry[0];
        let year = splitExpiry[1]

        if (props.packageInformation.hasPackage && !props.packageInformation.useExisitngCard) {
            if (!cardCheck(month, year)) {
                return;
            }
        }
        else if (!props.packageInformation.hasPackage && !props.packageInformation.useExisitngCard) {

            if (!cardCheck(month, year)) {
                return;
            }
        }
        let type = 'Buy'

        if (props.packageInformation.hasPackage && props.packageInformation.packageInfo) {
            if (props.packageInformation.packageInfo.subscription_fee > props.packageInformation.selectedPackage.subscription_fee) {
                type = 'downgrade'

            } else {
                type = 'upgrade'


            }
        }

        CustomConfirmAlert({
            handleConfirm: () => {
                props.updateReducerInformations({ 'submitting': true });
                if (type === 'downgrade') {
                    downgradePackage(month, year)
                } else if (type === 'upgrade' || (props.packageInformation.useExisitngCard && 
                    props.packageInformation.mapUserInfo && props.packageInformation.mapUserCardInfo)) {
                    upgradePackage(month, year)
                } else {
                    newUserCreate(month, year)
                }
            },
            handleOnClose: () => { },
            title: `Please confirm your order below.`,
            description: '',
            icon: 'question',
            closeButtonText: 'No',
            confirmButtonText: 'Buy Now',
            minHeight: "221px",
            maxWidth: "384px"
        });
    }

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
        if (e.target.value === 'exists') {
            props.updateReducerInformations({ 'useExisitngCard': true });
        } else {
            props.updateReducerInformations({ 'useExisitngCard': false });
        }
    }

    const packageBuyType = () => {

        if (props.packageInformation.hasPackage && props.packageInformation.packageInfo) {
            if (props.packageInformation.packageInfo.subscription_fee > props.packageInformation.selectedPackage.subscription_fee) {
                return 'Downgrade package'
            } else if (props.packageInformation.packageInfo.subscription_fee < props.packageInformation.selectedPackage?.subscription_fee) {
                return 'Upgrade package'
            } else {
                return 'Buy Package now!'

            }
        } else {
            return 'Buy Package now!'
        }
    }

    const paymentMessage = () => {

        if (props.packageInformation.selectedPackage && props.packageInformation.selectedPackage.life_line) {
            if (props.packageInformation.selectedPackage.life_line <= 31) {
                return 'Monthly Payment'
            } else if (props.packageInformation.selectedPackage.life_line === 365) {
                return 'Annual Payment'
            } else {
                return `Payment ${props.packageInformation.selectedPackage.life_line} days`
            }
        } else {
            return 'Payment'
        }
    }


    return (
        <Box className="cardmainPage__wrapper mip_cardPayment_page">
            <div className="cardmainPage__wrapper__toper">
                <h1 className="cardHeadingTitle__title">Activate your subscription</h1>
                <button onClick={() => updateCardInfo(false, 'paymentPage')}>Back to packages</button>
            </div>
            <Grid container className="mx-20 mip_two_columns" spacing={3}>
                <Grid item xs={7}>
                    <div className="mip_customPaper pip_billing_cycle">
                        <h1 className="cardHeadingTitle"><img src="https://i.ibb.co/6gnrXnQ/Frame.png" alt="billing icon"/>{props.packageInformation.selectedPackage.name}</h1>
                        <h4>Billing Cycle</h4>
                        <div className="mip_payment_type">
                            <label><input type="radio" checked={true} />{paymentMessage()}</label>
                            <span>${totalPrice}</span>
                        </div>
                    </div>

                    <div className="mip_customPaper mip_form mt-20">
                        <h1 className="cardHeadingTitle">
                            <img src="https://i.ibb.co/wWCkhrH/Group-10034-1.png" alt="Credit Card Info"/> </h1>
                        <h4 className="mtl">Credit Card Information</h4>
                        {!searchingMapUser && props.packageInformation.mapUserInfo && props.packageInformation.mapUserCardInfo &&

                            <RadioGroup aria-label="gender" name="gender1" value={radioValue} onChange={handleRadioChange} className="cardmainPage__radio__wrapper">
                                <FormControlLabel size="small" value="exists" control={<Radio />} label="Use existing Card" />
                                <FormControlLabel size="small" value="new" control={<Radio />} label="New Card" />
                            </RadioGroup>
                        }
                        {!searchingMapUser && (props.packageInformation.useExisitngCard && props.packageInformation.mapUserCardInfo) &&
                            <Grid className="mip_form_wrapper alt2" container spacing={2}>
                                <Grid className="formItem" item xs={4}>
                                    <label>Exisitng Card Number </label>
                                    {/* <input type="text" placeholder="4109 4109 4109 4242" /> */}
                                    <input
                                        type="text"
                                        name="number"
                                        placeholder="Enter Card Number"
                                        value={`**** **** **** ${props.packageInformation.mapUserCardInfo.card_number}`}
                                        readOnly
                                    />
                                    <img src="https://i.ibb.co/Twd3Crg/Vector-2.png" className="inputIcon" alt="Card Icon"/>
                                </Grid>
                            </Grid>
                        }
                        {!searchingMapUser && ((!props.packageInformation.useExisitngCard && props.packageInformation.mapUserInfo) ||
                            !props.packageInformation.mapUserInfo) &&

                            <Grid className="mip_form_wrapper alt2" container spacing={2}>
                                <Grid className="formItem" item xs={4}>
                                    <label>Card Number *</label>
                                    {/* <input type="text" placeholder="4109 4109 4109 4242" /> */}
                                    <input
                                        type="tel"
                                        name="number"
                                        placeholder="Enter Card Number"
                                        value={props.packageInformation.cardNumber}
                                        onChange={(e) => updateCardInfo(e.target.value, 'cardNumber')}
                                        onFocus={() => updateCardInfo('number', 'focus')}
                                        ref={numberRef}
                                    />
                                    <img src="https://i.ibb.co/Twd3Crg/Vector-2.png" className="inputIcon" alt="Card Icon"/>
                                </Grid>
                                <Grid className="formItem" item xs={4}>
                                    <label>Expiration Date *</label>
                                    {/* <input type="text" placeholder="Expiry Date" /> */}
                                    <input
                                        type="tel"
                                        name="number"
                                        placeholder="Expiry Date"
                                        ref={expiryRef}
                                        value={`${props.packageInformation.expiry}`}
                                        onChange={(e) => updateCardInfo(e.target.value, 'expiry')}
                                        onFocus={() => updateCardInfo('expiry', 'focus')}
                                    />
                                    <img src="https://i.ibb.co/3fH3n30/Vector-3.png" className="inputIcon" alt="Input Icon"/>
                                </Grid>
                                <Grid className="formItem" item xs={4}>
                                    CVC/CVV Code

                                    <BootstrapTooltip title="The Card Verification Code, or CVC*, is an extra code printed on your debit or credit card. With most cards (Visa, MasterCard, bank cards, etc.) it is the final three digits of the number printed on the signature strip on the reverse of your card. On American Express (AMEX) cards, it is usually a four digit code on the front." arrow placement="top">
                                        {/* <label> */}
                                        <img src="https://i.ibb.co/mRpqWwY/Icon.png" className="inputIcon alt" alt="Question Icon"/>
                                        {/* </label> */}

                                    </BootstrapTooltip>
                                    <input
                                        type="number"
                                        name="number"
                                        placeholder="123"
                                        ref={cvcrRef}
                                        value={props.packageInformation.cvc}
                                        onChange={(e) => updateCardInfo(e.target.value, 'cvc')}
                                        maxLength='3'
                                        onFocus={() => updateCardInfo('cvc', 'focus')}
                                    />
                                </Grid>
                            </Grid>
                        }



                        <hr />
                        {/* Not Necessary now */}
                        <h4 className="mtl">Billing Information</h4>
                        <Grid className="mip_form_wrapper alt" container spacing={2}>
                            <Grid className="formItem" item xs={6}>
                                <label>Name</label>
                                <input type="text" value={Utils.getAccountData('fullName')} />
                            </Grid>
                            <Grid className="formItem" item xs={6}>
                                <label>Email</label>
                                <input type="text" placeholder="Email" value={Utils.getAccountData('email')} />
                            </Grid>
                            <Grid className="formItem" item xs={6}>
                                <label>Phone</label>
                                <input type="text" placeholder="Email" value={props.packageInformation.userNumber} />
                            </Grid>
                        </Grid>

                        {/*<Grid className="mip_form_wrapper alt" container spacing={2}>
                            <Grid className="formItem" item xs={4}> 
                                <label>Country</label>
                                <FormControl className="mip_select_wrap">
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        className="mip_select"
                                    >
                                        <MenuItem value="">
                                        United States
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select> 
                                </FormControl>
                            </Grid>
                            <Grid className="formItem" item xs={4}>
                                <label>City</label>
                                <FormControl className="mip_select_wrap">
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        className="mip_select"
                                    >
                                        <MenuItem value="">
                                        State
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select> 
                                </FormControl>
                            </Grid> 
                            <Grid className="formItem" item xs={4}>
                                <label>Zip Code </label>
                                <input type="text" placeholder="123" />
                            </Grid>
                        </Grid>

                        <Grid className="mip_form_wrapper alt" container spacing={2}>
                            <Grid className="formItem" item xs={6}>
                                <label>Address</label>
                                <input type="text" placeholder="Address" />
                            </Grid>
                            <Grid className="formItem" item xs={6}>
                                <label>City</label>
                                <input type="text" placeholder="City" />
                            </Grid>
                        </Grid> */}

                    </div>

                </Grid>
                <Grid item xs={5}>

                    {props.packageInformation.companyLogo &&
                        <Box className="mip_company_info">
                            <div className="mip_company_logo">
                                <img src={`${props.packageInformation.companyLogo}?compress=1&resize=400x300`} alt="Logo" />
                                {/* <div className="mip_company_logo_info">
                            <p>Change your image or Browse</p>
                            <IconButton><CloudUpload /></IconButton>
                        </div> */}
                            </div>
                            {/* <div className="mip_company_data">
                        <h3>Company Logo</h3>
                        <p>
                            The proposed size is 512*512px no bigger than 2.5mb
                        </p>
                        <div>
                            <Button variant="contained" className="mip_cardPayment_btn mip_cardPayment_btn_remove">Remove</Button>
                            <Button variant="contained" className="mip_cardPayment_btn mip_cardPayment_btn_save">Save</Button>
                        </div>
                    </div> */}
                        </Box>
                    }

                    <div className="mip_customPaper">
                        <h1 className="cardHeadingTitle">Order Summary</h1>

                        <div className="mip_cardHeaderBox">
                            <h4>Today’s Payment</h4>
                            <p>Geofarming Tool	<span>  ${totalPrice}</span></p>
                        </div>
                        <div className="mip_cardHeaderBox alt">
                            <h4>Future Payment</h4>
                            <p>{paymentMessage()}	<span>  ${totalPrice}<span>All Prices in USD</span></span></p>
                        </div>
                        {!searchingMapUser &&
                            <div className="mip_payment_order_summery">
                                <Button onClick={() => handleSubmit()} variant="contained" className="mip_cardPayment_btn mip_cardPayment_btn_buy" startIcon={<LockIcon />}>
                                    {packageBuyType()} {props.packageInformation.submitting ? <CircularProgress /> : ''}
                                </Button>
                                <p><i>Charges will appear on your statement from: CRM - Geofarming Tool</i></p>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    )

}

export default CardPayment;