import React, {useEffect, useState} from "react";
import {
    Typography,
    IconButton,
    RadioGroup,
    FormControlLabel,
    Button,
    Radio
} from "@material-ui/core";
import {
    CustomButton,
    CustomEmailLimitTextField,
    EmailLimitModalWrapper
} from "./ConnectedEmailStyle";
import {makeStyles} from "@material-ui/core/styles";
import {
    HighlightOff as HighlightOffIcon
} from "@material-ui/icons";
import {updateUserConnectedEmailsLimits} from "../../../api/profileApi";

const useStyles = makeStyles((theme) => ({
    iconButton: {
        width: "30px",
        height: "30px",
        background: "rgb(214, 226, 251)",
        marginRight: "8px",
        padding: "4px",
        color: "rgb(52, 111, 239)",
    },
    iconButtonError: {
        width: "30px",
        height: "30px",
        background: "rgb(251, 207, 210)",
        marginRight: "8px",
        padding: "4px",
        color: "rgb(236, 19, 31)",
        "&:focus": {
            background: "rgb(251, 207, 210)",
        },
    },
    stack: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        padding: "8px",
    },
    paper: {
        padding: "8px",
        borderRadius: "8px",
        marginBottom: "8px",
    },
    container: {
        height: "65vh",
        overflowY: "auto",
    },
    tooltip: {
        width: 220,
        maxWidth: 200,
        fontSize: 12,
        padding: 10,
        textAlign: "center",
    },
    radioButtonTextColor:{
        color:'#9e9e9e'
    }
}));

const ConnectedEmailLimitUpdateModal = ({ open, onClose, editEmailLimitModal, setConnectedEmails, setEditEmailLimitModal }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState(null);
    const [limitWarningMessage, setLimitWarningMessage] = useState(null);
    const [formData, setFormData] = useState({
        uid: "",
        limit: null,
        minimum_interval: null,
        maximum_interval: null,
        email_limit_scope: "ALL",
    });
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (editEmailLimitModal){
            setFormData({
                uid: editEmailLimitModal?.userConnectedEmailId,
                limit:
                editEmailLimitModal?.sendingLimits?.limit,
                minimum_interval:
                    editEmailLimitModal?.sendingLimits
                        ?.minimum_interval || 30,
                maximum_interval:
                    editEmailLimitModal?.sendingLimits
                        ?.maximum_interval || 60,
                email_limit_scope:
                    editEmailLimitModal?.sendingLimits
                        ?.email_limit_scope || "ALL",
            });
        }
    }, [editEmailLimitModal]);

    const handleFormChange = (event) => {
        const { name, value } = event.target;

        if (name === "limit" && +value > 300) {
            setLimitWarningMessage(
                "Sending more than 300 emails per day is quite a lot. You might be blocked by your provider."
            );
        } else if (name === "limit" && +value < 300) {
            setLimitWarningMessage(null);
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const perHour = 3600 / formData?.maximum_interval || 0;

    const inputProps = {
        min: 0,
    };

    const handleEmailLimitSubmit = async () => {
        if (formData.minimum_interval > formData.maximum_interval) {
            setErrorMessage(
                "Minimum time limit cannot be greater than maximum time limit."
            );
            return;
        } else if (formData.minimum_interval < 0) {
            setErrorMessage("Minimum time limit cannot be less than 0.");
            return;
        } else if (formData.maximum_interval < 0) {
            setErrorMessage("Maximum time limit cannot be less than 0.");
            return;
        } else if (formData.limit < 0) {
            setErrorMessage("Email limit cannot be less than 0.");
            return;
        }

        setErrorMessage(null);

        const data = {
            userConnectedEmailId: formData?.uid,
            limit: formData.limit,
            minimumInterval: formData.minimum_interval,
            maximumInterval: formData.maximum_interval,
            emailLimitScope: formData.email_limit_scope,
        };

        try {
            setSubmitting(true);
            const response = await updateUserConnectedEmailsLimits(data);

            if (response.success) {
                window.showNotification("success", response.message);
                setConnectedEmails((prevState) =>
                    prevState.map((email) => {
                        if (email?.userConnectedEmailId === formData?.uid) {
                            return {
                                ...email,
                                sendingLimits: {
                                    ...email.sendingLimits,
                                    limit: formData.limit,
                                    minimum_interval: formData.minimum_interval,
                                    maximum_interval: formData.maximum_interval,
                                    email_limit_scope: formData.email_limit_scope,
                                },
                            };
                        } else {
                            return email;
                        }
                    })
                );
                setEditEmailLimitModal(null);
            } else {
                window.showNotification("error", response.message);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }finally {
            setSubmitting(false);
        }
    };

    return(
        <EmailLimitModalWrapper
            width={600}
            open={open}
            onClose={onClose}
        >
            <div className="modalWrapper">
                <div className="modalHeaderWrapper">
                    <div className="modalHeaderTitle">
                        <Typography variant="body1" style={{ fontWeight: 700 }}>
                            Account:
                        </Typography>

                        <Typography variant="body1">
                            {editEmailLimitModal?.email}
                        </Typography>
                    </div>

                    <IconButton
                        size="small"
                        sx={{ "&:focus": { bgcolor: "transparent" } }}
                        onClick={onClose}
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </div>

                <div className="modalBodyWrapper">
                    <Typography
                        variant="h6"
                        style={{ fontWeight: 700, marginBottom: 16 }}
                        fontWeight={700}
                    >
                        SENDING LIMITS
                    </Typography>

                    <Typography
                        variant="body2"
                        component={"div"}
                        style={{ lineHeight: "27px", marginBottom: 16 }}
                        lineHeight={"27px"}
                    >
                        I want this mailbox to send no more than
                        <CustomEmailLimitTextField
                            value={formData?.limit}
                            size="small"
                            name="limit"
                            type="number"
                            onChange={handleFormChange}
                            inputProps={inputProps}
                            style={{
                                marginInline: 8,
                                width: 68,
                                "& .MuiInputBase-input": {
                                    textAlign: "center",
                                    paddingBlock: "4px !important",
                                },
                            }}
                        />
                        emails a day at random times, between every
                        <CustomEmailLimitTextField
                            value={formData?.minimum_interval}
                            disabled={(!formData?.limit) }
                            size="small"
                            type="number"
                            name="minimum_interval"
                            onChange={handleFormChange}
                            inputProps={inputProps}
                            style={{
                                marginInline: 8,
                                width: 68,
                                "& .MuiInputBase-input": {
                                    paddingBlock: "4px !important",
                                    textAlign: "center",
                                },
                            }}
                        />
                        and
                        <CustomEmailLimitTextField
                            value={formData?.maximum_interval}
                            disabled={(!formData?.limit) }
                            size="small"
                            type="number"
                            name="maximum_interval"
                            onChange={handleFormChange}
                            inputProps={inputProps}
                            style={{
                                marginInline: 8,
                                width: 68,
                                "& .MuiInputBase-input": {
                                    textAlign: "center",
                                    paddingBlock: "4px !important",
                                },
                            }}
                        />
                        sec <b>(up to {Math.round(perHour)} emails per hour).</b> The
                        number of emails sent will also depend on campaign settings.
                    </Typography>

                    {errorMessage && (
                        <Typography
                            variant="body2"
                            color="error"
                            style={{ marginBottom: 16 }}
                        >
                            {errorMessage}
                        </Typography>
                    )}
                    {limitWarningMessage && (
                        <Typography
                            variant="body2"
                            color="error"
                            style={{ marginBottom: 16 }}
                        >
                            {limitWarningMessage}
                        </Typography>
                    )}

                    <div className="limitScopeWrapper">
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 700 }}
                            fontWeight={700}
                        >
                            HOW WOULD YOU LIKE O APPLY THE SENDING LIMITS?
                        </Typography>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginBottom: 16 }}
                        >
                            (Select an option)
                        </Typography>

                        <RadioGroup
                            name="email_limit_scope"
                            value={formData?.email_limit_scope}
                            onChange={handleFormChange}
                            color="primary"
                            className="limitScopeRadioGroup"
                        >
                            <FormControlLabel
                                value="BULK_EMAIL"
                                control={<Radio color={formData?.limit?'primary':''} disabled={!formData?.limit}/>}
                                label={<Typography className={!formData?.limit?classes.radioButtonTextColor:''}>Apply limits to bulk email campaigns only</Typography>}
                            />
                            <FormControlLabel
                                value="ALL"
                                control={<Radio color={formData?.limit?'primary':''} disabled={!formData?.limit}/>}
                                label={<Typography className={!formData?.limit?classes.radioButtonTextColor:''}>Apply limits system-wide</Typography>}

                            />
                        </RadioGroup>
                    </div>
                </div>

                <div className="modalFooterWrapper">
                    <Button
                        onClick={onClose}
                        style={{ "&:focus": { bgcolor: "transparent" } }}
                    >
                        Cancel
                    </Button>

                    <CustomButton
                        style={{ margin: 0 }}
                        onClick={handleEmailLimitSubmit}
                        disabled={submitting}
                    >
                        Save
                    </CustomButton>
                </div>
            </div>
        </EmailLimitModalWrapper>
    );
};

export default ConnectedEmailLimitUpdateModal;