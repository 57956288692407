import {
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecycleEvent } from "../../../../../actions/ProductAction";
import { EMPTY_DATA_IMG } from "../../../../../constants/CoreConstants";
import GlobalEmptyPage from "../../../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import { recycleEventState } from "../../../common/productSelector";
import TableSkeleton from "../../../productService/content/productBody/TableSkeleton";
import { CustomScrollbar } from "../../../productsStyle";
import EachRecycleEventTableItem from "./EachRecycleEventTableItem";

const PolicyTypeListWrapper = styled("div")({
  marginTop: 18,
  "& .policyTypeItem": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    border: "1px solid #E5E8EF",
    borderRadius: 6,
  },
  "& .policyCategoryItem": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    border: "1px solid #346fef",
    borderRadius: 6,
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#fafafa",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color:'var( --deep_dark_blue)',
    padding:'10px'
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});


const RecycleEventTable = () => {
  const dispatch = useDispatch();
  const { recycleEventList, isLoading } = useSelector(recycleEventState);

  // useEffect(()=>{
  //   getRecycleData().then();
  // },[])
  // const getRecycleData = async () => {
  //   try{
  //     setIsLoading(true);
  //     const response = await getRecycle();
  //   if(response.sucess && response.data[0]){
  //       setData(response.data)
  //   } else {
  //       setData([]);
  //   }
  //   }  catch (error) {
  //     console.error('Error:', error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  useEffect(()=>{
    dispatch(getRecycleEvent({}));
  },[]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : recycleEventList && !recycleEventList[0] && !isLoading ? (
        <GlobalEmptyPage
          image={EMPTY_DATA_IMG}
          title={"No Recycle Event Found"}
        />
      ) : (
        <PolicyTypeListWrapper>
          <CustomScrollbar className={`${classes.tableBodyHeight}`}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeader} style={{width:'80%'}}>
                      Recycle Events
                    </TableCell>
                    <TableCell   style={{width:'20%',paddingLeft:'28px'}} className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {recycleEventList.map((event) => (
                    <EachRecycleEventTableItem
                      key={event.id}
                      event={event}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>
          {/*<TablePagination*/}
          {/*  rowsPerPageOptions={[10, 25, 100]}*/}
          {/*  component="div"*/}
          {/*  count={policyTypeTableList.length}*/}
          {/*  rowsPerPage={rowsPerPage}*/}
          {/*  page={page}*/}
          {/*  onPageChange={handleChangePage}*/}
          {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*/>*/}
        </PolicyTypeListWrapper>
      )}
    </>
  );
};

export default RecycleEventTable;
