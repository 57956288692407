import * as ACTION from "../constants/customFieldInsuranceActionTypes";
export const handleUserCustomFieldTabChange = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_TAB_CHANGE,
  payload: payload,
  });
export const handleNewFolderCreateModal = (payload) => ({
  type: ACTION.HANDLE_NEW_FOLDER_CREATE_MODAL,
  payload: payload,
});

export const addCustomFieldFolder = (payload) => ({
  type: ACTION.ADD_CUSTOM_FIELD_FOLDER,
  payload: payload,
});
export const addCustomFieldFolderSuccess = (payload) => ({
  type: ACTION.ADD_CUSTOM_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const addCustomFieldFolderFailure = (error) => ({
  type: ACTION.ADD_CUSTOM_FIELD_FOLDER_FAILURE,
  payload: error,
});

export const getCustomFieldFolder = (payload) => ({
  type: ACTION.GET_CUSTOM_FIELD_FOLDER,
  payload: payload,
});
export const getCustomFieldFolderSuccess = (payload) => ({
  type: ACTION.GET_CUSTOM_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const getCustomFieldFolderFailure = (error) => ({
  type: ACTION.GET_CUSTOM_FIELD_FOLDER_FAILURE,
  payload: error,
});

export const updateCustomFieldFolder = (payload) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_FOLDER,
  payload: payload,
});
export const updateCustomFieldFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const updateCustomFieldFolderFailure = (error) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_FOLDER_FAILURE,
  payload: error,
});

export const deleteCustomFieldFolder = (payload) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_FOLDER,
  payload: payload,
});
export const deleteCustomFieldFolderSuccess = (payload) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const deleteCustomFieldFolderFailure = (error) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_FOLDER_FAILURE,
  payload: error,
});

export const handleSelectedCustomFieldFolder = (payload) => ({
  type: ACTION.HANDLE_SELECTED_CUSTOM_FIELD_FOLDER,
  payload: payload,
});

export const addCustomField = (payload) => ({
  type: ACTION.ADD_CUSTOM_FIELD,
  payload: payload,
});
export const addCustomFieldSuccess = (payload) => ({
  type: ACTION.ADD_CUSTOM_FIELD_SUCCESS,
  payload: payload,
});
export const addCustomFieldFailure = (error) => ({
  type: ACTION.ADD_CUSTOM_FIELD_FAILURE,
  payload: error,
});

export const getCustomField = (payload) => ({
  type: ACTION.GET_CUSTOM_FIELD,
  payload: payload,
});
export const getCustomFieldSuccess = (payload) => ({
  type: ACTION.GET_CUSTOM_FIELD_SUCCESS,
  payload: payload,
});
export const getCustomFieldFailure = (error) => ({
  type: ACTION.GET_CUSTOM_FIELD_FAILURE,
  payload: error,
});

export const handlePageChange = (payload) => ({
  type: ACTION.HANDLE_PAGE_CHANGE,
  payload: payload,
});

export const handleRowChange = (payload) => ({
  type: ACTION.HANDLE_ROW_CHANGE,
  payload: payload,
});

export const handleSearch = (payload) => ({
  type: ACTION.HANDLE_SEARCH,
  payload: payload,
});

export const updateCustomFieldInsurance = (payload) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_INSURANCE,
  payload: payload,
});
export const updateCustomFieldSuccess = (payload) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_INSURANCE_SUCCESS,
  payload: payload,
});
export const updateCustomFieldFailure = (error) => ({
  type: ACTION.UPDATE_CUSTOM_FIELD_INSURANCE_FAILURE,
  payload: error,
});

export const deleteCustomFieldInsurance = (payload) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_INSURANCE,
  payload: payload,
});
export const deleteCustomFieldSuccess = (payload) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_INSURANCE_SUCCESS,
  payload: payload,
});
export const deleteCustomFieldFailure = (error) => ({
  type: ACTION.DELETE_CUSTOM_FIELD_INSURANCE_FAILURE,
  payload: error,
});

export const customFieldStatus = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_STATUS,
  payload: payload,
});
export const customFieldStatusSuccess = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_STATUS_SUCCESS,
  payload: payload,
});
export const customFieldStatusFailure = (error) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_STATUS_FAILURE,
  payload: error,
});

export const userCustomFieldGroupChange = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE,
  payload: payload,
  });

export const userCustomFieldGroupChangeSuccess = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE_SUCCESS,
  payload: payload,
  });

  export const userCustomFieldGroupChangeFailure = (error) => ({
    type: ACTION.HANDLE_CUSTOM_FIELD_GROUP_CHANGE_FAILURE,
    payload: error,
  });
export const userCustomFieldsOrderChange = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE,
  payload: payload,
  });

export const userCustomFieldsOrderChangeSuccess = (payload) => ({
  type: ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE_SUCCESS,
  payload: payload,
});

export const userCustomFieldsOrderChangeFailure = (error) => ({
    type: ACTION.HANDLE_CUSTOM_FIELD_ORDER_CHANGE_FAILURE,
    payload: error,
  });

export const resetUserCustomFieldState = () => ({
  type: ACTION.RESET_STATE,
  });

          // MERGE FIELD 

export const addUserMergeFieldFolder = (payload) => ({
  type: ACTION.ADD_USER_MERGE_FIELD_FOLDER,
  payload: payload,
});
export const addUserMergeFieldFolderSuccess = (payload) => ({
  type: ACTION.ADD_USER_MERGE_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const addUserMergeFieldFolderFailure = (error) => ({
  type: ACTION.ADD_USER_MERGE_FIELD_FOLDER_FAILURE,
  payload: error,
});

export const getUserMergeFieldFolders = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD_FOLDER,
  payload: payload,
});
export const getUserMergeFieldFoldersSuccess = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const getUserMergeFieldFoldersFailure = (error) => ({
  type: ACTION.GET_USER_MERGE_FIELD_FOLDER_FAILURE,
  payload: error,
  });

export const updateUserMergeFieldFolder = (payload) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD_FOLDER,
  payload: payload,
});
export const updateUserMergeFieldFolderSuccess = (payload) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const updateUserMergeFieldFolderFailure = (error) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD_FOLDER_FAILURE,
  payload: error,
});
export const deleteUserMergeFieldFolder = (payload) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD_FOLDER,
  payload: payload,
});
export const deleteUserMergeFieldFolderSuccess = (payload) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD_FOLDER_SUCCESS,
  payload: payload,
});
export const deleteUserMergeFieldFolderFailure = (error) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD_FOLDER_FAILURE,
  payload: error,
});
export const handleSelectedUserMergeFieldFolder = (payload) => ({
  type: ACTION.HANDLE_SELECTED_USER_MERGE_FIELD_FOLDER,
  payload: payload,
});

export const addUserMergeField = (payload) => ({
  type: ACTION.ADD_USER_MERGE_FIELD,
  payload: payload,
});
export const addUserMergeFieldSuccess = (payload) => ({
  type: ACTION.ADD_USER_MERGE_FIELD_SUCCESS,
  payload: payload,
});
export const addUserMergeFieldFailure = (error) => ({
  type: ACTION.ADD_USER_MERGE_FIELD_FAILURE,
  payload: error,
});
export const getUserMergeField = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD,
  payload: payload,
});
export const getUserMergeFieldSuccess = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD_SUCCESS,
  payload: payload,
});
export const getUserMergeFieldFailure = (error) => ({
  type: ACTION.GET_USER_MERGE_FIELD_FAILURE,
  payload: error,
});


export const updateUserMergeField = (payload) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD,
  payload: payload,
});
export const updateUserMergeFieldSuccess = (payload) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD_SUCCESS,
  payload: payload,
});
export const updateUserMergeFieldFailure = (error) => ({
  type: ACTION.UPDATE_USER_MERGE_FIELD_FAILURE,
  payload: error,
});


export const handleUserMergeFieldPageChange = (payload) => ({
  type: ACTION.HANDLE_USER_MERGE_FIELD_PAGE_CHANGE,
  payload: payload,
});

export const handleUserMergeFieldRowChange = (payload) => ({
  type: ACTION.HANDLE_USER_MERGE_FIELD_ROW_CHANGE,
  payload: payload,
});

export const handleUserMergeFieldSearch = (payload) => ({
  type: ACTION.HANDLE_USER_MERGE_FIELD_SEARCH,
  payload: payload,
});

export const deleteUserMergeField = (payload) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD,
  payload: payload,
});
export const deleteUserMergeFieldSuccess = (payload) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD_SUCCESS,
  payload: payload,
});
export const deleteUserMergeFieldFailure = (error) => ({
  type: ACTION.DELETE_USER_MERGE_FIELD_FAILURE,
  payload: error,
});

export const getMergeFieldPreviewData = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD_PREVIEW_DATA,
  payload: payload,
});
export const getMergeFieldPreviewDataSuccess = (payload) => ({
  type: ACTION.GET_USER_MERGE_FIELD_PREVIEW_DATA_SUCCESS,
  payload: payload,
});
export const getMergeFieldPreviewDataFailure = (error) => ({
  type: ACTION.GET_USER_MERGE_FIELD_PREVIEW_DATA_FAILURE,
  payload: error,
});
