import React from 'react';
import { Box } from '@material-ui/core';
import PolicyTypesHeader from './PolicyTypesHeader';
import PolicyTypesBody from './policyTypesBody';

const Content = () => {
    return (
        <Box flex={1} sx={{background:'white',borderRadius:'8px'}}>
            <PolicyTypesHeader />
            <PolicyTypesBody />
        </Box>
    );
};

export default Content;