import { styled } from "@material-ui/core";
import React from "react";
import SideBar from "./sidebar/SideBar";
import Content from "./content";

const PolicyTypeWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
}));
const PolicyTypes = () => {
  return (
    <PolicyTypeWrapper>
      <SideBar />
      <Content/>
    </PolicyTypeWrapper>
  );
};

export default PolicyTypes;
