import React, { useEffect, useState } from "react";
import {
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EachLostReasonTableItem from "./EachLostReasonTableItem";
import { CustomScrollbar } from "../../../productsStyle";
import TableSkeleton from "../../../productService/content/productBody/TableSkeleton";
import GlobalEmptyPage from "../../../../invoiceSettings/common/emptyPage/GlobalEmptyPage";
import { EMPTY_DATA_IMG } from "../../../../../constants/CoreConstants";
import {useDispatch, useSelector} from "react-redux";
import {getLostReasons} from "../../../../../actions/ProductAction";
import {lostReasonState} from "../../../common/productSelector";

const PolicyTypeListWrapper = styled("div")({
  marginTop: 18,
  "& .policyTypeItem": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    border: "1px solid #E5E8EF",
    borderRadius: 6,
  },
  "& .policyCategoryItem": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 18,
    border: "1px solid #346fef",
    borderRadius: 6,
  },
});

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#fafafa",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color:'var( --deep_dark_blue)',
  },
  tableBodyHeight: {
    height: `calc(100vh - 445px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const LostReasonTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { lostReasonList, isLoading } = useSelector(lostReasonState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(()=>{
    dispatch(getLostReasons({}));
  },[]);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : lostReasonList && !lostReasonList[0] && !isLoading ? (
        <GlobalEmptyPage
          image={EMPTY_DATA_IMG}
          title={"No Lost Reason Found"}
        />
      ) : (
        <PolicyTypeListWrapper>
          <CustomScrollbar className={`${classes.tableBodyHeight}`}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeader} style={{width:'80%'}}>
                      Reasons
                    </TableCell>
                    {/*<TableCell align={"right"} className={classes.tableHeader}>*/}
                    {/*  Status*/}
                    {/*</TableCell>*/}
                    <TableCell style={{width:'20%',paddingLeft:'28px'}} className={classes.tableHeader}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lostReasonList.map((policy) => (
                    <EachLostReasonTableItem
                        key={policy.id}
                        policy={policy}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>
          {/*<TablePagination*/}
          {/*  rowsPerPageOptions={[10, 25, 100]}*/}
          {/*  component="div"*/}
          {/*  count={lostReasonList.length}*/}
          {/*  rowsPerPage={rowsPerPage}*/}
          {/*  page={page}*/}
          {/*  onPageChange={handleChangePage}*/}
          {/*  onRowsPerPageChange={handleChangeRowsPerPage}*/}
          {/*/>*/}
        </PolicyTypeListWrapper>
      )}
    </>
  );
};

export default LostReasonTable;
