import { makeStyles } from "@material-ui/core";

const PreviewMergeFieldDataStyles = makeStyles(() => ({
    accordionRoot: {
      "&::before": { display: "none" },
      marginBottom: "10px",
      borderRadius: "8px",
      overflow: "hidden",
    },
    accordionSummary: {
      fontSize: "13px",
      backgroundColor: "rgba(62, 80, 247, 0.1)",
      borderBottom: "none",
      "& .MuiAccordionSummary-content": { margin: 0 },
    },
    personalizeButton: {
      fontSize: "13px",
      textTransform: "capitalize",
      backgroundColor: "var(--light_blue)",
      "&:hover, &:focus": { backgroundColor: "var(--light_blue)" },
    },
    folderItem: {
      fontSize: "12px",
      width: "190px",
      padding: "8px",
      marginBottom: "6px",
      transition: "background-color 0.3s ease",
      "&:hover": {
        borderRadius: "6px",
        width: "190px",
        backgroundColor: "rgba(62, 80, 247, 0.1)",
      },
    },
    popoverContent: {
      width: "250px",
      maxHeight: "420px",
      overflowY: "auto",
      padding: "16px",
      background: "white",
      pointerEvents: "auto", 
    },
    popoverHeader: {
      textAlign: "center",
      margin: "32px 0",
      fontWeight: "600px",
      fontSize: "14px",
    },
    noDataText: {
      textAlign: "center",
      marginTop: "20px",
      color: "gray",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      position: "relative",
    },
    popoverWrapper: {
      pointerEvents: "none", 
    },
  }));
export default PreviewMergeFieldDataStyles;