import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import {useSelector} from "react-redux";
import Utils from "../../../helpers/Utils";
import BootstrapTooltip from '../../common/BootstrapTooltip';
import { AGENCY_BUSINESS_TYPE } from '../../../constants/CoreConstants';

const COLORS = [/* '#BB6BD9','#56CCF2', */ '#181f48', '#68EBCA', '#6258FF'];

const ProductBarChart = ({activeProduct,data,setActiveProduct}) => {

  const handleActiveProduct = (id) => {
    if (activeProduct === id) {
      setActiveProduct(null);
      return;
    }
    setActiveProduct(id);
  };

  const DataFormater = (number) => {
    if(number > 1000000000){
      return '$' + (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      return '$' + (number/1000000).toString() + 'M';
    }else if(number > 1000){
      return '$' + (number/1000).toString() + 'K';
    }else{
      return '$' + number.toString();
    }
  }


  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload[0]) {
      const { product, sold, count } = payload[0].payload;
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '16px',
          borderRadius: '4px',
          textAlign: 'center',
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        }}>
          <p style={{ margin: 0, fontWeight: '600', color: '#133159',marginBottom:'6px' }}>{product}</p> 
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',alignItems:'center',gap:'20px'}}>
          <div style={{paddingRight:'20px', borderRight:'1px solid #E5E8EF'}}>
          { (Utils.getAccountData("agencyBusinessType") === undefined || 
                Utils.getAccountData("agencyBusinessType") !== "INSURANCE") ?
            <p style={{ color: '#133159', fontWeight: '500',marginBottom:'6px' }}>SALES</p> :
            <p style={{ color: '#133159', fontWeight: '500',marginBottom:'6px' }}>Premium</p> 
              }
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>
                {`${Utils.formatCurrency(sold, "$")}`}
              </p>
            </div>
            <div>
              {
                (Utils.getAccountData("agencyBusinessType") === undefined || 
                Utils.getAccountData("agencyBusinessType") !== AGENCY_BUSINESS_TYPE.INSURANCE) ?
            <p style={{ color: '#133159', fontWeight: '500', marginBottom:'6px' }}>PRODUCTS/SERVICES</p> :
            <p style={{ color: '#133159', fontWeight: '500', marginBottom:'6px' }}>Policy Count</p>}
            <p style={{ margin: 0, fontWeight: '600', color: '#346FEF',textAlign:'center' }}>{count}</p>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  const CustomTickWithArrow = ({ x, y, payload, index,data }) => {

    const offset = index % 2 === 0 ? 25 : 45;
    const arrowStart = -2; 
    const arrowEnd = offset-10 ; 
   
  
    return (
      <g transform={`translate(${x}, ${y})`}>
        <line x1={0} y1={arrowStart} x2={0} y2={arrowEnd} stroke="#666" strokeWidth={1} />
        <polygon
          points="0,0 -3,-5 3,-5"
          transform={`translate(0, ${arrowEnd})`} 
          fill="#666"
        />
        <BootstrapTooltip title={payload.value} placement="top">
        <text
          x={0}
          y={offset}
          textAnchor="middle"
          fontSize={8}
          fill="#666"
        >
          {payload.value.length > 20 ? `${payload.value.substring(0, 12)}...` : payload.value}
        </text>
        </BootstrapTooltip>
      </g>
    );
  };
  const setCustomInterval = () => {
    if (data?.length < 20) {
      return 0;  
    } else if (data?.length < 30) {
      return 2;  
    } else {
      return 4; 
    }
  }

 
   const formatData = data.map((item)=>({ sold: item.totalSoldProductValue, product: item.userProductTitle, user_product_id: item.userProductId ,count: item.totalSoldProductCount,}));

  return (
    <ResponsiveContainer width="100%" height={370}>
      <BarChart
        data={formatData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <CartesianGrid strokeDasharray="none" stroke="#fff" />
        <XAxis dataKey="product" 
         tick={<CustomTickWithArrow />} 
         interval={setCustomInterval()}
         height={30}
         tickLine={{ stroke: "#666", strokeWidth: 1, length: 10 }}
        />
        <YAxis tickFormatter={DataFormater}/>
        <Tooltip content={CustomTooltip}/>
        <Bar
          maxBarSize={150}
          dataKey="sold"
          fill="#8884d8"
          isAnimationActive={false}
        >
        {
          formatData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={activeProduct === entry.user_product_id ? "#346fef" : COLORS[index % COLORS.length]}
              onClick={()=>handleActiveProduct(entry.user_product_id)}
            />
          ))
        }
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ProductBarChart;
