import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import { LostReasonStyle } from "./RecycleEventStyle";
import { BasicInput } from "../../productsStyle";
import {
  addRecycleEvent,
  resetUpdateRecycleEventSuccess,
  updateRecycleEvent
} from "../../../../actions/ProductAction";
import {useDispatch, useSelector} from "react-redux";
import {recycleEventState} from "../../common/productSelector";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
  },
  errorStyle: {
    border: "1px solid red !important",
    borderRadius: "4px",
  },
  errorText: {
    color: "red",
  },
  description: {
    border: "1px solid #ced4da !important",
    borderRadius: "4px !important",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiInputBase-multiline": {
      color: "var(--dark_blue)",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    height: "24px ",
    color: "var(--dark_blue)",
  },
  multipleSelect: {
    "& .MuiSelect-select": {
      // paddingTop: "6px !important",
      display: "flex",
      alignItems: "center",
    },
  },
  restoreText: {
    color: "#0b0be1",
    textDecoration: "underline",
    fontWeight: 600,
    cursor: "pointer",
    userSelect: "none",
  },
});

const AddOrEditRecycleEventModal = ({ open, onClose, edit }) => {
  const dispatch = useDispatch();
  const { isAddRecycleEventSuccessful,isSubmitting } = useSelector(recycleEventState);
  const [formData, setFormData] = useState({
    title: edit?.event_title || "",
  });
  const [error, setError] = useState({
    title: "",
    description: "",
    closeDate: "",
  });

  useEffect(()=>{
    if (isAddRecycleEventSuccessful){
      onClose();
      dispatch(resetUpdateRecycleEventSuccess());
    }
  },[isAddRecycleEventSuccessful]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: "",
    };

    if (formData?.title.trim() === "") {
      errors.title = "Recycle Event is required";
      isValid = false;
    }
    if (formData?.title.length > 100) {
      errors.title = "Recycle Event must be less than 20 characters";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    if (!validateForm()) {
      return;
    }

    if (edit) {
      dispatch(updateRecycleEvent({ event_title: formData.title, deal_recycle_event_id:edit.id }));
    } else {
       dispatch(addRecycleEvent({ event_title: formData.title }));
      
    }
  };

  const classes = useStyles();
  const { topbarNewProductButton, lightBlue } = LostReasonStyle();
  const handleOnChange = (e) => {
    const newValue = e.target.value;
    setFormData((prevState) => ({
        ...prevState,
        title: newValue,
    }));

    if (newValue.length > 50) {
        setError((prevState) => ({
            ...prevState,
            title: "Recycle Event must be less than 50 characters",
        }));
    } else {
        setError((prevState) => ({
            ...prevState,
            title: "",
        }));
    }
};

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        minWidth={500}
        // minHeight={300}
        sx={{ "& .MuiTypography-root": { height: "unset !important" } }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          sx={{ borderBottom: "1px solid #d3d3d3" }}
        >
          <Typography className={classes.titleHeader}>
            {edit ? "Edit Recycle Event" : "Add a New Recycle Event"}
          </Typography>
          <Box
            onClick={onClose}
            border={"1px solid #d3d3d3"}
            display={"flex"}
            padding={"3px"}
            borderRadius={"25%"}
            sx={{ cursor: "pointer" }}
          >
            <Close />
          </Box>
        </Box>
        <Box padding={2}>
          <Box mb={2}>
            <Typography className={`${error.title ? classes.errorText : ""}`}>
              Recycle Event<span style={{ color: "red" }}>*</span>
            </Typography>
            { isSubmitting ? <Skeleton width={'100%'} height={'48px'}/> :
           ( <Box mt={1}>
              <BasicInput
                fullWidth
                value={formData?.title}
                className={`${error.title ? classes.errorStyle : ""}`}
                onChange={handleOnChange}
                required
              />
              {error.title && (
                <Typography variant="body2" className={classes.errorText}>
                  {error.title}
                </Typography>
              )}
            </Box>)}
          </Box>
        </Box>

        <Box
          sx={{ borderTop: "1px solid #d3d3d3" }}
          padding={2}
          display={"flex"}
          justifyContent={"end"}
        >
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button
              onClick={onClose}
              color="primary"
              className={lightBlue}
              variant="text"
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={topbarNewProductButton}
              variant="contained"
              disableElevation
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddOrEditRecycleEventModal;
