import React, { useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FolderIcon from "@material-ui/icons/Folder";
import { Skeleton } from "@material-ui/lab";
import { SidebarLeftIcon, SidebarRightIcon } from "../../products/common/Icons";
import { CustomScrollbar } from "../../products/productsStyle";
import EachFolderItem from "./EachFolderItem";
import CreateNewFolderModal from "./modal/CreateNewFolderModal";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  width: isSidebarCollapsed ? 56 : 268,
  height: "calc(100vh - 255px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));
const productFolders = [
  {
    id: 2,
    title: "Live Test Category",
    description: null,
    folder_icon: "LockIcon",
    folder_color: "#008951",
  },
  {
    id: 3,
    title: "Test - 3.1",
    description: null,
    folder_icon: "DollarIcon",
    folder_color: "#008951",
  },
  {
    id: 4,
    title: "Electronics",
    description: null,
    folder_icon: "MonitorIcon",
    folder_color: "#008951",
  },
  {
    id: 11,
    title: "test by mehedi 2",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#00bec5",
  },
  {
    id: 19,
    title: "test by sir v2",
    description: null,
    folder_icon: "WebsiteIcon",
    folder_color: "#924cff",
  },
  {
    id: 26,
    title: "Nakib Pro",
    description: null,
    folder_icon: "FilterIcon",
    folder_color: "#00bec5",
  },
  {
    id: 30,
    title: "hhhh",
    description: null,
    folder_icon: "MonitorIcon",
    folder_color: "#924cff",
  },
  {
    id: 127,
    title: "aa",
    description: null,
    folder_icon: "MonitorIcon",
    folder_color: "#f79009",
  },
  {
    id: 135,
    title: "asdf",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
  {
    id: 136,
    title: "zxcz",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
  {
    id: 137,
    title: "134152515",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
  {
    id: 138,
    title: "arhqetheath",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
  {
    id: 139,
    title: "we4tq22234234234",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
  {
    id: 140,
    title: "sefhsdgsghs",
    description: null,
    folder_icon: "FolderFilledIcon",
    folder_color: "#346fef",
  },
];

const SideBar = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [openNewFolderModal, setOpenNewFolderModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const showContent = isSidebarCollapsed ? "none" : "block";

  const renderFolderList = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (productFolders?.length > 0 && !isLoading) {
      return productFolders.map((folder) => (
        <EachFolderItem
          key={folder.id}
          folder={folder}
          selectedFolder={selectedFolder === folder.id}
        />
      ));
    }
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
        <Typography className="sidebarTitle" variant="body2">
          My Custom Fields
        </Typography>

        <IconButton color="primary" onClick={handleCollapsed} size="small">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
        <ListItem
          button
          // selected={selectedFolder === "all"}
          // onClick={handleShowAllProducts}
          style={{ marginBottom: 4 }}
        >
          <ListItemIcon>
            <FolderIcon
              color="primary"
              fontSize="small"
              style={{ color: "#346fef" }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={"text.primary"}
              variant="body2"
              fontWeight={600}
              minWidth={170}
            >
              All Custom Fields
            </Typography>
          </ListItemText>
        </ListItem>

        <CustomScrollbar style={{ maxHeight: "calc(100vh - 420px)" }}>
          <div className="folderList">{renderFolderList()}</div>
        </CustomScrollbar>

        <Divider light style={{ marginTop: 16, marginBottom: 8 }} />
        <ListItem button onClick={() => setOpenNewFolderModal(true)}>
          <ListItemIcon>
            <Tooltip title={showContent === "none" ? " Create Category" : ""}>
              <AddIcon style={{ color: "#346fef" }} fontSize="small" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body2"
              fontWeight={600}
              minWidth={170}
              style={{ color: "#346fef" }}
            >
              Create Category
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {openNewFolderModal && (
        <CreateNewFolderModal
          open={openNewFolderModal}
          onClose={() => setOpenNewFolderModal(false)}
        />
      )}
    </SidebarWrapper>
  );
};

export default SideBar;
