import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid, Paper } from "@material-ui/core";

const PackageSkeleton = () => {
  return (
    <Paper
      style={{
        marginTop: "50px",
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Skeleton width="30%" height={40} />
        </Grid>
        <Grid
          item
          xs={1}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Skeleton variant="circle" width={40} height={40} />
        </Grid>

        <Grid item xs={9}>
          <Skeleton variant="rect" width="100%" height={80} />
        </Grid>
        <Grid item xs={3}>
          <Skeleton width="80%" height={40} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="rect" width="40%" height={30} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PackageSkeleton;
