import { FormControl } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { useHistory } from "react-router-dom";
import { callOutcomeListForCallLogDropdown, getOutgoingCallCount, getOutgoingCallOutcome, getUserAndVirtualNumberArray } from "../../api/reportApi";
import Utils from '../../helpers/Utils';
import { DropdownHelper } from "../globals/DropdownHelper";
import { formatDateWithoutZone } from "../globals/TimezoneConversion";
import { icons } from "./icon";
import "./styles.css";
import "./theme/default.css";

const startDate = new Date();
const endDate = new Date();

const formattedStartDate = formatDateWithoutZone(startDate, "MM/DD/YYYY");
const formattedEndDate = formatDateWithoutZone(endDate, "MM/DD/YYYY");
const userTimezoneRaw = Utils.getAccountData('userTimezoneRaw');

const inOut = [
    {
        id: -1,
        value: "All Calls"
    },
    {
        id: 1,
        value: "Incoming Calls"
    },
    {
        id: 2,
        value: "Outgoing Calls"
    }
];

const CheckFullInfo = () => {
    const history = useHistory();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [datePickerActive, setDatePickerActive] = useState(false);
    const [datePickerFormattedDate, setDatePickerFormattedDate] = useState({
       startDate: formattedStartDate,
       endDate: formattedEndDate
    });
    const [searchTimeoutToken, setSearchTimeoutToken] = useState(null);
    const [callOutcomeArray, setCallOutcomeArray] = useState(null);
    const [callOutcomeList, setCallOutcomeList] = useState(null);
    const [selectUserCallOutcomeInfo, setSelectUserCallOutcomeInfo] = useState(null);
    const [state, setState] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection"
        }
    ]);
    const [outgoingCounts, setOutgoingCounts] = useState({});
    const [userList, setUserList] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedInOut, setSelectedInOut] = useState(-1);

    useEffect(()=>{
        if(history.location.state){
            if(history.location.state.subUserList){
                let tmpData = history.location.state.subUserList
                tmpData.splice(0,1)
                if (Array.isArray(tmpData)){
                    setSelectedUser(tmpData[0]);
                    setUserList(tmpData)
                    let userTemp = tmpData.map(user=> user.id);
                    console.log(userTemp)
                    setUserIds(userTemp);
                    getOutgoingCallCounts({
                        userIds: userTemp,
                        startDate: formattedStartDate + " 00:00:01",
                        endDate: formattedEndDate + " 23:59:59"
                    });
                    // getOutcomeReport(tmpData[0].id)
                }
            }else{
                getUserInfos()
            }
            if(history.location.state.callOutcomeArray && history.location.state.callOutcomeList){
                setCallOutcomeArray(history.location.callOutcomeArray)
                let tmpData = history.location.state.callOutcomeList;
                tmpData.splice(0,1)
                setCallOutcomeList(tmpData);

            }else{
                getCallOutcomeArray()
            }
        }else{
            getUserInfos()
            getCallOutcomeArray()
        }
        
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(selectedUser){
            getOutcomeReport(selectedUser.id, datePickerFormattedDate.startDate, datePickerFormattedDate.endDate)

        }
    },[selectedUser]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(selectedUser){
            getOutgoingCallCounts({
                userIds: userIds,
                startDate: datePickerFormattedDate.startDate + " 00:00:01",
                endDate: datePickerFormattedDate.endDate + " 23:59:59"
            });
            getOutcomeReport(selectedUser.id, datePickerFormattedDate.startDate, datePickerFormattedDate.endDate)

        }
    },[selectedInOut]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCallOutcomeArray=()=>{
        callOutcomeListForCallLogDropdown()
        .then(response => {
          if (response && response.data && response.data.data) {
            let datas= {};
            let tempData = response.data.data;
            for(let i=0; i< tempData.length; i++){
                datas['outcome_'+tempData[i].id] = tempData[i].outcome;
            }
            setCallOutcomeArray(datas);
            setCallOutcomeList([...tempData]);
          }else{
            setCallOutcomeArray({})
            setCallOutcomeList([])
          }
        }).catch((error)=>{
            setCallOutcomeArray({})
            setCallOutcomeList([])
            console.log(error)
    });
    }

    const getUserInfos=()=>{
        getUserAndVirtualNumberArray()
        .then(response => {
            if(response.data.users){
                const tmpUser = response.data.users;
                setUserList(tmpUser);
                if (Array.isArray(tmpUser)){
                    setSelectedUser(tmpUser[0]);
                    setUserIds(tmpUser.map(user=>user.id));
                    getOutgoingCallCounts({
                        userIds: tmpUser,
                        startDate: formattedStartDate + " 00:00:01",
                        endDate: formattedEndDate + " 23:59:59"
                    });
                    // getOutcomeReport(tmpUser[0].id)

                }
            }
        }).catch((error)=>{
            setUserList([]);
            console.log(error)
        });
    }

    const resetDateRange = () => {
        setDatePickerActive(false);
        setState([
            {
                startDate: startDate,
                endDate: endDate,
                key: "selection"
            }
        ]);
        setDatePickerFormattedDate({
            startDate: formattedStartDate,
            endDate: formattedEndDate
        });

        getOutgoingCallCounts({
            userIds: userIds,
            startDate: formattedStartDate + " 00:00:01",
            endDate: formattedEndDate + " 23:59:59"
        });
        getOutcomeReport(selectedUser.id, formattedStartDate, formattedEndDate)


    };

    const selectDateRange = (selectedDateRange) => {
        clearTimeout(searchTimeoutToken);
        const startDate = formatDateWithoutZone(selectedDateRange.startDate,"MM/DD/YYYY");
        const endDate =  formatDateWithoutZone(selectedDateRange.endDate,"MM/DD/YYYY");
        setDatePickerFormattedDate({
            startDate: startDate,
            endDate: endDate
        });
        setState([selectedDateRange]);
        setDatePickerActive(true);

        const timoutToken = setTimeout(()=>{
            getOutgoingCallCounts({
                userIds: userIds,
                startDate: startDate + " 00:00:01",
                endDate: endDate + " 23:59:59"
            });
            getOutcomeReport(selectedUser.id, startDate, endDate)
            setShowDatePicker(false);
        },1000);

        setSearchTimeoutToken(timoutToken);
    };

    const getOutgoingCallCounts = (params) => {
      try{
          getOutgoingCallCount({
              userIds: params.userIds,
              startDate: params.startDate,
              endDate: params.endDate,
              userTimezoneRaw: userTimezoneRaw,
              type: selectedInOut,

          }).then((response)=> {
              const resOutgoingCounts = response.data.data;
              if (resOutgoingCounts && !Array.isArray(resOutgoingCounts)){
                  setOutgoingCounts(resOutgoingCounts);
              }
          }).catch((err)=>{
              setOutgoingCounts({});
          });
      }catch (err){
          setOutgoingCounts({});
      }
    };

    const getOutcomeReport=(userId, startDate,endDate)=>{

        let params = {
            startDate: startDate + " 00:00:01",
            endDate: endDate + " 23:59:59",
            type: selectedInOut,
            userId: userId
        }
        getOutgoingCallOutcome((params))
        .then(res=>{
            if(res.data.status === 'success'){
                setSelectUserCallOutcomeInfo(res.data.data)
            }else{
                setSelectUserCallOutcomeInfo(null)
            }
        })
        .catch(err=>{
            setSelectUserCallOutcomeInfo(null)
        });
    }

    return (
      <div className="cal_log_details_wrapper">
         <div className="cal_log_details_heading">
            <div className="cal_log_details_title">
                <h5 >Call Logs Details</h5>
                <div className="cal_log_details_heading_back_btn" onClick={()=>{ history.push("/user/telephony-service/call-logs") }}>
                   <ArrowBack/> <span>Back to Call Logs</span>
                </div>
            </div>
            <div className="cal_log_details_top_right">
                {/* <div className="select-wrapper call_logs_select_wrapper_v2">
                    <DropdownHelper
                        datalist={callOutcomeList}
                        noneFieldRequired={true}
                        noneFieldTitle="Filter Outcome"
                        className=""
                        mapping={{ title: "outcome" }}
                        menuItemClassName=""
                        updateSelectedData={(params)=>{ setSelectedOutCome(params) }}
                        selectedValue={selectedOutCome}
                        dropdownHeader={"callOutcomeId"}
                        parentDivClassName="call_log_select_parent_v2"
                    />
                </div> */}


                <div className="select-wrapper call_logs_select_wrapper_v2">
                    <DropdownHelper
                        datalist={inOut}
                        noneFieldRequired={true}
                        noneFieldTitle="Filter Call type"
                        className=""
                        mapping={{ title: "value" }}
                        menuItemClassName=""
                        updateSelectedData={(params)=>{ setSelectedInOut(params) }}
                        selectedValue={selectedInOut}
                        dropdownHeader={"inOut"}
                        parentDivClassName="call_log_select_parent_v2"
                    />
                </div>
              <div className="cal_log_details_top_date">
                  
                 <div className="custom-input">
                          <div className="select-wrapper filter__action_select" >
                              <FormControl onClick={() => setShowDatePicker(true)}>
                                  <input className={`select-dropdown dropdown-trigger text_align_left_v2`} type="text" readOnly={true} value={datePickerFormattedDate.startDate === formattedStartDate &&  datePickerFormattedDate.endDate === formattedEndDate ? "Today" : datePickerFormattedDate.startDate + "-" + datePickerFormattedDate.endDate} />
                              </FormControl>
                              {
                                  datePickerActive && (
                                      <span
                                          className="date_picker_reset"
                                          onClick={resetDateRange}
                                      >
                                         <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#000" stroke-width="2" d="m3 3 18 18M3 21 21 3"/></svg>
                                      </span>
                                  )
                              }
                          </div>
                      </div>
              </div>
              {
                showDatePicker && 
                <div className="custom-input call__log_datepicker outcome-filter">
                
                    <div className="select-wrapper">
                    <span
                            className="cal_log_details_top_date_close_btn"
                            onClick={() => setShowDatePicker(false)}
                        >
                            {icons.modalCloseIcon}
                        </span>
                        
                                <DateRangePicker
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={true}
                                    onChange={item => selectDateRange(item.selection)}
                                    months={1}
                                    minDate={addDays(new Date(), -600)}
                                    maxDate={addDays(new Date(), 1)}
                                    dateDisplayFormat="MM/dd/yyyy"
                                    calendarFocus="backwards"
                                    direction="vertical"
                                    scroll={{ enabled: true }}
                                    ranges={state}
                                />
                            
                        
                    </div>
                </div>
            }
          </div>
         </div>
         <div className="cal_log_details_container">
            <div className="cal_log_details_container_left awesome__scroll_bar">
                {
                    userList.map((user, index)=>(
                        <div className={ "cal_log_dl_single" + ((selectedUser && selectedUser.id === user.id) ? " cal_log_dl_single_active" : "")} key={index} onClick={()=>{ setSelectedUser(user) }}>
                            <div className="cal_log_dl_single_inner">
                                <h5 className="cal_log_dl_single_title">{user.full_name ? user.full_name : "-"}</h5>
                                <div className="cal_log_dl_single_outgoing">
                                    <span className="cal_log_dl_single_outgoing_count">{outgoingCounts[user.id] ? outgoingCounts[user.id] : 0}</span>
                                    <span> - {selectedInOut === -1 ? 'all' : selectedInOut === 1 ? 'incoming' : 'outgoing'} Calls</span>
                                </div>
                            </div>
                            <span className="cal_log_dl_single_arrow">
                      <ArrowForwardIcon/>
                      </span>
                        </div>
                    ))
                }
            </div>
            <div className={`cal_log_details_container_right awesome__scroll_bar ${selectedUser? "cal_log_details_container_right_grid" : ""}`}>
                {
                    selectedUser && selectUserCallOutcomeInfo && callOutcomeList ? (
                        <>
                            {callOutcomeList.map((item)=>{

                                let key = selectedInOut === -1 ? 'outcome_' : selectedInOut === 1 ? 'incoming_' : 'outgoing_'
                                if(item){
                                    return <div className="cal_log_dr_single">
                                    <h4 className="cal_log_dr_single_top_title">{item.outcome ?? ''}</h4>
                                    <h3 className="cal_log_dr_single_bottom_count">{selectUserCallOutcomeInfo[key+item.id] ?? 0}</h3>
                                    </div>
                                }
                                return null
                                
                            })}
                        </>
                    ):  <div className="cal_log_right_empty_container">
                            <span>Select an user to see details</span>
                        </div>
                }
            </div>
         </div>
      </div>
    );
}

export default CheckFullInfo;