import { makeStyles } from "@material-ui/core";

export const productStyle=makeStyles({
topbarHeaderTitle:{
    fontSize:'20px',
    fontWeight:600,

},
topbarNewFolderButton:{
    fontWeight:600,
    "&:focus,&:hover":{
        background:'inherit'
    }
},
topbarNewProductButton:{
    fontWeight:600,
    background:'#346fef',
    color:'white',
    "&:focus,&:hover":{
        background:'#346fef'
    }
},
newFolderModalCancelButton:{
    fontWeight:600,
    background:'#f2f4f780',
    color:'#475467',
    "&:focus,&:hover":{
        background:'#e0e0e0'
    }
},

productBodyTitle:{
    fontSize:'18px',
    fontWeight:600,
    // marginBottom:'8px'

},
grayColor:{
    color:'gray'
},
favIconColor:{
   color:'rgb(247, 144, 9)' 
},
lightBlue:{
    fontWeight:600,
    background:'#f2f4f780 !important',
    color:'#475467',
    "&:focus,&:hover":{
        background:'#e0e0e0'
    }
},
textButton:{
    "&:focus":{
        background:'white'
    }
},
customScrollBar: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#706fd3",
      borderRadius: "10px",
      // outline: '1px solid #8e44ad'
    },
  },
});