import { makeStyles } from "@material-ui/core";

const ContentStyles = makeStyles((theme) => ({
    root: {
      background: "white",
      borderRadius: "8px",
      padding: "16px",
      flex: 1,
      height: "calc(100vh - 275px)",
  
      "& .headWrapper": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBottom: 16,
        marginBottom: 16,
        borderBottom: "1px solid #cdcdcd",
      },
  
      "& .newFieldButton": {
        "&:hover, &:focus": {
          backgroundColor: "#3f51b5",
        },
      },
  
      "& .record_search": {
        display: "flex",
        justifyContent: "flex-end",
        width: '250px !important',
        "& .search": {
          border: "1px solid rgb(167 167 167 / 65%) !important",
          margin: 0,
          "&:focus": {
            boxShadow: "none !important",
          },
        },
  
        "& .search_icon": {
          right: 12,
          top: 12,
        },
      },
  
      "& .customFieldList": {
        "& .listItem": {
          padding: 8,
          border: "1px solid #e1e1e1",
          borderRadius: 4,
          marginBottom: 8,
  
          "&:not(:last-child)": {
            marginBottom: 8,
          },
  
          "& .itemHeader": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 8,
            marginBottom: 8,
            borderBottom: "1px solid #e1e1e1",
  
            "& .title": {
              fontSize: 15,
              fontWeight: 600,
              color: "var(--dark_blue)",
            },
  
            "& .privateChip": {
              marginRight: 40,
              backgroundColor: "#00ff91",
            },
            "& .globalChip": {
              marginRight: 40,
              backgroundColor: "#6258FF",
              color: "#FFF",
            },
            "& .actions": {
              display: "flex",
              alignItems: "center",
              gap: 16,
              width: "300px",
            },
          },
  
          "& .itemDetails": {
            paddingInline: 16,
            "& .title": {
              fontSize: 15,
              fontWeight: 600,
              color: "var(--dark_blue)",
            },
            "& .sub": {
              fontSize: 14,
              color: "#546376",
            },
          },
        },
      },
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      background: "#fafafa",
      zIndex: 3,
      position: "sticky",
      top: 0,
    },
    tableHeader: {
      fontSize: "16px",
      fontWeight: 600,
      whiteSpace: "nowrap",
      color: "var( --deep_dark_blue)",
      padding: "20px 10px",
    },
    tableBodyHeight: {
      height: `calc(100vh - 445px) !important`,
      overflowY: "auto",
      marginTop: "16px",
    },
  }));
  export default ContentStyles;