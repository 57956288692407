import { useEffect, useRef, useState } from "react";
import { saveCallOutcome } from "../../api/profileApi";
import GlobalModal from "../globals/Modal/GlobalModal";
import Save from "@material-ui/icons/Save";
import { useSimpleValidator } from "../../hooks/useSimpleValidator";
import { BasicInput } from "../products/productsStyle";

const CallOutcomePopup = (props) => {

    const inputRef = useRef();
    const simpleValidator = useSimpleValidator();
    const [value, setValue] = useState(props.editCallOutcome ? props.editCallOutcome.outcome : '');
    const [id, setId] = useState(props.editCallOutcome ? props.editCallOutcome.id : null); // eslint-disable-line no-unused-vars
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            inputRef.current.focus();
        }, 500);
    },[]);

    const toggle = (reset = false) => {
        props.hideModal(reset);
    }

    const submitData = () => {
        if(!simpleValidator.validate()) {
            return;
        }

        setLoading(true);

        saveCallOutcome({
            "form_data": {
                id: id,
                outcome: value,
            }
        }).then((response) => {
            toggle(true);
        });
    }

    return (
        <GlobalModal
            open={true}
            className="global-750-modal global-xs-modal global-modal-avoid-header call-outcome-modal"
            title={props.title}
            onClose={toggle}
            onSubmit={submitData}
            buttonText={loading ? 'Saving..' : 'Save'}
            buttonIcon={<Save/>}
        >
            <div className="p-3">
                <h3 className="call_outcome_label">Call Outcome <span className="red-text">*</span></h3>
                <BasicInput fullWidth ref={inputRef} type="text" value={value} onChange={e => setValue(e.target.value)} placeholder="Enter Call Outcome"/>
                {simpleValidator.message('outcome', value, 'required')}
            </div>
        </GlobalModal>
    )
}

export default CallOutcomePopup;