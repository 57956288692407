import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import {
  calendarSyncList,
  calendarSyncStore,
  emailCalendarListData,
  emailListData,
} from "../../api/profileApi";
import If from "if-else-react";
import Utils from "../../helpers/Utils";
import Drawer from "@material-ui/core/Drawer";
import { FormControl, Grid, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../globals/Loader";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e8ef;
`;

const Wrapper = styled.div`
  margin: 10px;
  max-width: 100%;
  width: 500px;
`;

const StyledOptionFormatter = styled.div`
  .input-select__icon {
    margin-right: 10px;

    svg {
      vertical-align: middle;
    }
  }
`;

const SyncTypeOption = styled.div`
  flex: 1 1 auto;
  min-width: 0;

  input[type="radio"] {
    width: auto;
    border: none;
    padding: 0;
    box-shadow: none;
    margin-right: 3px;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
`;

const SyncTypeOptionLabel = styled.label`
  border-radius: 0 0 3px 3px;
  padding: 16px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  background: #fff;
  box-shadow: ${(props) =>
    props.checked ? "inset 0 0 0 1px #317ae2" : "inset 0 0 0 1px #e5e5e5"};

  .radio__dot {
    display: inline-block;
    flex: 0 0 auto;
    margin-top: 2px;
    margin-right: 8px;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const events = [
  {
    value: 2,
    label: "Appointment",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-calendar h-6 w-6 stroke-current"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
    ),
  },
  {
    value: 1,
    label: "Call",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-phone-call"
      >
        <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
      </svg>
    ),
  },
  {
    value: 3,
    label: "Task",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-check-square h-6 w-6 stroke-current"
      >
        <polyline points="9 11 12 14 22 4"></polyline>
        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
      </svg>
    ),
  },
  {
    value: 4,
    label: "Deadline",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-clock"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
    ),
  },
  {
    value: 5,
    label: "Email",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-mail"
      >
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline points="22,6 12,13 2,6"></polyline>
      </svg>
    ),
  },
  {
    value: 6,
    label: "Followup",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-user-plus"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="8.5" cy="7" r="4"></circle>
        <line x1="20" y1="8" x2="20" y2="14"></line>
        <line x1="23" y1="11" x2="17" y2="11"></line>
      </svg>
    ),
  },
  {
    value: 7,
    label: "Others",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-more-horizontal"
      >
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    ),
  },
];

const SyncPopupNew = (props) => {
  const [loading, setLoading] = useState(
    typeof props.loading !== "undefined" ? props.loading : false
  );
  const [componentLoading, setComponentLoading] = useState(
    typeof props.componentLoading !== "undefined"
      ? props.componentLoading
      : true
  );
  const [calendarList, setCalendarList] = useState([]);
  const [syncType, setSyncType] = useState(2);
  const [incomingEvent, setIncomingEvent] = useState(events[0]);
  const [saveAsContact, setSaveAsContact] = useState(false);
  const [outgoingEvents, setOutgoingEvents] = useState(events);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailCalendarList, setEmailCalendarList] = useState([]);
  const [selectedCalendarName, setSelectedCalendarName] = useState(null);

  useEffect(() => {
    // loadData(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    emailListData().then((resp) => {
      if (resp.data.status === "success") {
        const calendarEmails = resp.data.data;
        const filteredEmailList = [];
        calendarEmails.map((email) => {
          let singleEmail = {
            value: email.value,
            label: email.value,
            id: email.id,
          };
          filteredEmailList.push(singleEmail);
        });
        setEmailList(filteredEmailList);
      }
    })
    .catch((error)=>{

    }).finally(() => {
            setComponentLoading(false);
          });
  }, [selectedEmail]);

  useEffect(() => {
    
    if (selectedEmail?.id) {
      emailCalendarListData({ email_id: selectedEmail?.id }).then((resp) => {
        if (resp.data.status === "success") {
         
          const calendarEmailsList = resp.data.data;
          const filteredEmailList = [];
          calendarEmailsList.map((calendar) => {
            let singleEmail = {
              value: calendar.calendar_name,
              label: calendar.calendar_name,
              id: calendar.calendar_id,
            };
            filteredEmailList.push(singleEmail);
          });
          
          setEmailCalendarList(filteredEmailList);
          setSelectedCalendarName("");
          // setSelectedCalendarName(filteredEmailList[0]);
        }
      });
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (props.calendarSetting) {
      if (typeof props.calendarSetting.sync_type !== "undefined") {
        setSyncType(props.calendarSetting.sync_type);
      }

      if (
        typeof props.calendarSetting.incoming_event_task_id !== "undefined" &&
        props.calendarSetting.incoming_event_task_id
      ) {
        setIncomingEvent(
          events.filter(
            (singleEvent) =>
              singleEvent.value === props.calendarSetting.incoming_event_task_id
          )[0]
        );
      }

      if (
        typeof props.calendarSetting.does_fetch_lead !== "undefined" &&
        props.calendarSetting.does_fetch_lead
      ) {
        setSaveAsContact(
          props.calendarSetting.does_fetch_lead === 1 ? true : false
        );
      }

      if (
        typeof props.calendarSetting.outgoing_event_task_ids !== "undefined" &&
        props.calendarSetting.outgoing_event_task_ids
      ) {
        setOutgoingEvents(
          events.filter((item) =>
            JSON.parse(props.calendarSetting.outgoing_event_task_ids).includes(
              item.value
            )
          )
        );
      }
    }
  }, [props.calendarSetting]);

  useEffect(() => {}, [props.visible]);

  useEffect(() => {
    if (typeof props.loading !== "undefined") {
      setLoading(props.loading);
    }
  }, [props.loading]);

  useEffect(() => {
    if (typeof props.componentLoading !== "undefined") {
      setComponentLoading(props.componentLoading);
    }
  }, [props.componentLoading]);

  const closeModal = () => {
    setTimeout(() => {
      props.handleCancel();
    }, 400);
  };

  // const loadData = (_componentLoading) => {
  //   setComponentLoading(_componentLoading);

  //   calendarSyncList({})
  //     .then((response) => {
  //       if (response.data.status === "success") {
  //         let calendars = response.data.data;
  //         let filteredCalendars = [];

  //         calendars.forEach((calendar) => {
  //           if (!calendar.read_only) {
  //             let singleOption = {};

  //             if (calendar.is_primary) {
  //               singleOption = {
  //                 value: calendar.id,
  //                 label: calendar.name + "(Primary)",
  //                 name: calendar.name,
  //               };

  //               if (!selectedCalendarName) {
  //                 setSelectedCalendarName(singleOption);
  //               }
  //             } else {
  //               singleOption = {
  //                 value: calendar.id,
  //                 label: calendar.name,
  //                 name: calendar.name,
  //               };
  //             }

  //             if (
  //               props.calendarSetting &&
  //               typeof props.calendarSetting.calendar_id !== "undefined"
  //             ) {
  //               if (props.calendarSetting.calendar_id === calendar.id) {
  //                 setSelectedCalendarName(singleOption);
  //               }
  //             }

  //             filteredCalendars.push(singleOption);
  //           }
  //         });

  //         setCalendarList(filteredCalendars);
  //       } else {
  //         Utils.showNotification(response.data.data, "Error");
  //       }
  //     })
  //     .catch((error) => {
  //       Utils.showNotification("Something went wrong", "Error");
  //     })
  //     .finally(() => {
  //       setComponentLoading(false);
  //     });
  // };

  const handleSyncOnchange = (e) => {
    setSyncType(parseInt(e.target.value));
  };

  const handleSubmit = (_loading) => {
    if (!selectedCalendarName) {
      Utils.showNotification("No calendar is selected", "Error");
      return;
    }

    if (!outgoingEvents.length) {
      Utils.showNotification(
        "No Sync activities to calendar is selected",
        "Error"
      );
      return;
    }

    setLoading(_loading);

    let filteredOutgoingEvents = outgoingEvents.map(
      (singleEvent) => singleEvent.value
    );

    calendarSyncStore({
      form_data: {
        // calendar_name: selectedCalendarName.value,
        calendar_id: selectedCalendarName.id,
        name: selectedCalendarName.value,
        save_as_contact: saveAsContact,
        sync_type: syncType,
        incoming_event: syncType === 2 ? incomingEvent.value : null,
        outgoing_events: filteredOutgoingEvents,
        email_id: selectedEmail.id,
      },
    })
      .then((response) => {
        Utils.showNotification(response.data.data.html, "SUCCESS");
        props.callback(selectedCalendarName.value);
      })
      .catch((error) => {
        Utils.showNotification("Something went wrong", "ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const emaildata = (data) => {
    setSelectedCalendarName("");
    setSelectedEmail(data);
  };
console.log(selectedCalendarName)
  
  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={true} onClose={closeModal}>
        <Wrapper className={"manage-tag-modal create-collaborate-modal"}>
          <Grid container>
            <Grid item xs={12}>
              <Header>
                <h5>Set up Calendar Sync</h5>
                <div
                  class="modal-close cross-icon d-flex justify-content-center align-items-center"
                  onClick={closeModal}
                >
                  <CloseIcon />
                </div>
              </Header>
              <Grid item xs={12} className={"p-4"}>
                <If condition={componentLoading}>
                  <div className="text-center py-5">
                    <Loader />
                  </div>
                </If>
                <If condition={!componentLoading}>
                  <React.Fragment>
                    <div className="card-body">
                      <div className="form-group form-group-last">
                        {/* <div
                            style={{
                              backgroundColor: "#F3F6F9",
                              borderColor: "#F3F6F9",
                              padding: ".85rem 1.5rem",
                              borderRadius: "4px",
                            }}
                          >
                            <div className="alert-text">
                              You’re syncing the calendar associated with{" "}
                              <code>{props.email}</code> .
                            </div>
                          </div> */}

                        <FormControl style={{ width: "100%" }}>
                          <label className="font-weight-bold text-muted">
                            Select Email
                          </label>
                          <Select
                            options={emailList}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(selectedOption) => {
                              // setSelectedEmail(selectedOption);
                              emaildata(selectedOption);
                            }}
                            value={selectedEmail}
                            placeholder="Select a Email"
                          ></Select>
                        </FormControl>
                      </div>
                      <div className="form-group mt-2">
                        <label className="font-weight-bold text-muted">
                          Select calendar
                        </label>
                        <Select
                          options={emailCalendarList}
                          onChange={(selectedOption) => {
                            setSelectedCalendarName(selectedOption);
                          }}
                          value={selectedCalendarName}
                          placeholder="Select calendar to sync"
                        />
                      </div>
                      <div className="form-group">
                        <label className="font-weight-bold text-muted">
                          Select sync type
                        </label>
                        <SyncTypeOption>
                          <SyncTypeOptionLabel checked={syncType === 2}>
                            <input
                              type="radio"
                              checked={syncType === 2}
                              value={2}
                              onChange={handleSyncOnchange}
                            />
                            <span className="radio__dot"></span>
                            <span
                              style={syncType === 2 ? { color: "#000" } : {}}
                            >
                              Two-way Sync
                              <ul>
                                <li>System activities sync to calendar</li>
                                <li>
                                  Calendar events sync to system as activities
                                  <span className="text-muted">
                                    <small> (sync may take some time)</small>
                                  </span>
                                </li>
                              </ul>
                            </span>
                          </SyncTypeOptionLabel>
                          <SyncTypeOptionLabel checked={syncType === 1}>
                            <input
                              type="radio"
                              checked={syncType === 1}
                              value={1}
                              onChange={handleSyncOnchange}
                            />
                            <span className="radio__dot"></span>
                            <span
                              style={syncType === 1 ? { color: "#000" } : {}}
                            >
                              One-way Sync
                              <ul>
                                <li>System activities sync to calendar</li>
                                <li>
                                  Calendar events do not sync to system as
                                  activities
                                </li>
                              </ul>
                            </span>
                          </SyncTypeOptionLabel>
                        </SyncTypeOption>
                      </div>
                      <If condition={parseInt(syncType) === 2}>
                        <div className="form-group">
                          <label className="font-weight-bold text-muted">
                            Save calendar event as
                          </label>
                          <Select
                            options={events}
                            onChange={(selectedOption) => {
                              setIncomingEvent(selectedOption);
                            }}
                            value={incomingEvent}
                            formatOptionLabel={({ value, label, icon }) => (
                              <StyledOptionFormatter>
                                {icon && (
                                  <span className="input-select__icon">
                                    {icon}
                                  </span>
                                )}
                                <span>{label}</span>
                              </StyledOptionFormatter>
                            )}
                          />
                        </div>
                      </If>
                      <div className="form-group">
                        <label className="font-weight-bold text-muted">
                          Sync these system activities to calendar
                        </label>
                        <Select
                          options={events}
                          onChange={(selectedOption) => {
                            setOutgoingEvents(selectedOption);
                          }}
                          value={outgoingEvents}
                          formatOptionLabel={({ value, label, icon }) => (
                            <StyledOptionFormatter>
                              {icon && (
                                <span className="input-select__icon">
                                  {icon}
                                </span>
                              )}
                              <span>{label}</span>
                            </StyledOptionFormatter>
                          )}
                          isMulti={true}
                          placeholder={"Select activities"}
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          margin: "10px 0px",
                        }}
                      >
                        <label
                          className="font-weight-bold text-muted"
                          style={{ color: "#000000de" }}
                        >
                          Save participant as a contact
                        </label>
                        <div style={{ marginTop: "3px" }}>
                          <span className="m-switch m-switch--outline m-switch--success">
                            <label>
                              <input
                                type="checkbox"
                                checked={saveAsContact}
                                onChange={() =>
                                  setSaveAsContact(!saveAsContact)
                                }
                              />
                              <span></span>
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="form-group form-group-last">
                        <div
                          style={{
                            backgroundColor: "#F3F6F9",
                            borderColor: "#F3F6F9",
                            padding: ".85rem 1.5rem",
                            borderRadius: "4px",
                          }}
                        >
                          <div className="alert-text">
                            <i className="la la-exclamation-circle"></i> All
                            future events will be synced.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 text-right">
                      <button
                        type="submit"
                        className={"btn btn-primary mr-2"}
                        color={"primary"}
                        disabled={loading}
                        onClick={() => handleSubmit(true)}
                      >
                        <i
                          className={`la la-refresh${
                            loading ? " la-spin" : ""
                          }`}
                        ></i>{" "}
                        Save Settings
                      </button>
                      <button
                        className={"btn btn-default btn-secondary"}
                        color={"secondary"}
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </React.Fragment>
                </If>
              </Grid>
            </Grid>
          </Grid>
        </Wrapper>
      </Drawer>
    </React.Fragment>
  );
};

export default SyncPopupNew;
