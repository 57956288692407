import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const BodySkeleton = () => {
  return (
    <div style={{ width: "100%" }}>
      <Box
        padding={2}
        sx={{
          margin: "16px",
          height: `calc(100vh - 270px) !important`,
          overflow: "hidden",
        }}
      >
        {Array.from({ length: 12 }).map((rowNum, i) => (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ gridGap: "20px" }}
            key={i}
            mb={2}
          >
            <Skeleton variant="text" width={250} height={50} />
            <Skeleton variant="text" width={250} height={50} />
            <Skeleton variant="text" width={250} height={50} />
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default BodySkeleton;
