import React from "react";
import { Tab, Tabs, styled } from "@material-ui/core";
import Utils from "../../helpers/Utils";
import { HOME_PAGE_TABS } from "../products/helper/coreConstant";

const TabWrapper = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& .MuiTabs-flexContainer": {
    gap: "20px !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#346fef",
  },
  "& .Mui-selected": {
    color: "#346FEF !important",
  },
  "& .tabItem": {
    color: "#133159A6",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
}));

const HomePageTab = ({ value, handleChange }) => {
  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === "INSURANCE";
  return (
    <TabWrapper
      value={value}
      onChange={handleChange}
      className="tabWrapper"
      textColor="primary"
      indicatorColor="primary"
    >
      

      <Tab
        style={{ minWidth: "unset !important" }}
        className="tabItem"
        value={HOME_PAGE_TABS.GOALS}
        label="Goals"
      />
      <Tab
        style={{ minWidth: "unset !important" }}
        className="tabItem"
        value={HOME_PAGE_TABS.DEAL_REPORT}
        label="Sales Report"
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.TEAM_SALES_REPORT}
        label="Team Member Sales Report"
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.PRODUCT_SALES_REPORT}
        label={isAgencyTypeInsurance ? "Policy Sales Report" : "Product Sales Report"}
      />

      <Tab
        className="tabItem"
        value={HOME_PAGE_TABS.SALES_VS_GOAL_REPORT}
        label="Sales VS Goal Report"
      />
    </TabWrapper>
  );
};

export default HomePageTab;
