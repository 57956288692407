import React, {useEffect, useRef, useState} from 'react';
import SimpleReactValidator from "simple-react-validator";
import Box from "@material-ui/core/Box";
import {Button, Grid} from "@material-ui/core";
import {ITEM_TYPE_OPTION,ITEM_UNIT_OPTION} from "../dropDownConstant";
import {addNewProductInvoice, editProductInvoice} from "../../../api/InvoiceApi";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Styles from "./AddProductStyles.module.css";
import BasicInput from '../../custom/BasicInput';
import BasicSelect from '../../custom/BasicSelect';


const validateTax = (value) => {
    // Regular expression to allow up to two digits with up to two decimal places
    const regex = /^\d*(\.\d{0,2})?$/;
    return regex.test(value);
};

const AddOrEditProduct = ({isEdit, productInfo, onClose, onRenderCallback}) => {
    const [, setForceUpdate] = useState(false);
    const [loading, setLoading] = useState(false);

    const simpleValidator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: {forceUpdate: setForceUpdate},
            className: "small text-danger",
        })
    );

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        itemType: "",
        unitOfItem: "",
        price: "",
        quantity: 1,
        tax: 0,
    });

    useEffect(() => {
        if (productInfo?.id) {
            setFormData({
                ...formData,
                ...productInfo
            })
        }
    }, []);

    const handleChangeInput = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        if (simpleValidator.current.allValid()) {
            try {
                setLoading(true);
                const requestBody = {
                    id: productInfo?.id,
                    name: formData.name.replace(/\s+/g, ' ').trim(),
                    description: formData.description,
                    itemType: formData.itemType,
                    price: formData.price,
                    unitOfItem: formData.unitOfItem,
                    quantity: 1,
                    tax: 0,
                };

                if (isEdit) {
                    requestBody.id = productInfo?.id;
                    editProductInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                onRenderCallback()
                                window.showNotification("SUCCESS", res.message);
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification(
                                "ERROR",
                                "Error occurred while saving data"
                            );
                        })
                        .finally(() => setLoading(false))
                } else {
                    addNewProductInvoice(requestBody)
                        .then((res) => {
                            res = res.data;
                            if (res.success) {
                                onRenderCallback()
                                window.showNotification("SUCCESS", res.message);
                                onClose();
                            } else {
                                window.showNotification("ERROR", res.message);
                            }
                        })
                        .catch((error) => {
                            window.showNotification(
                                "ERROR",
                                "Error occurred while creating product"
                            );
                        })
                        .finally(() => setLoading(false))
                }
            } catch (error) {
                console.error("An error occurred:", error);
            }
        } else {
            simpleValidator.current.showMessages();
            setForceUpdate(true);
        }
    }

    return (
        <Box style={{maxWidth: 600}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <InputLabel required>Item Name</InputLabel>
                        <BasicInput
                        fullWidth
                        variant={"outlined"}
                        type="text"
                        placeholder="Enter a name for your product or service here"
                        name="name"
                        value={formData.name}
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                        />
                         <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                                    "Name",
                                    formData.name,
                                    "required"
                                )}</FormHelperText>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box>
                        <InputLabel>Description</InputLabel>
                        <BasicInput
                        fullWidth
                        multiline
                            type="text"
                            minRows={3}
                            maxRows={4}
                            placeholder="Write a short description for your product or service here"
                            name="description"
                            value={formData.description}
                            onChange={(e) => {
                                handleChangeInput(e.target.name, e.target.value);
                            }}
                            OutlinedInputProps={{
                                maxLength: 250,
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box className={Styles.labelAndItem}>
                        <InputLabel required>Type</InputLabel>
                        <Box>
                            <BasicSelect
                            fullWidth
                            options={ITEM_TYPE_OPTION}
                            mapping={{value:'value',label:'label'}}
                            value={formData.itemType || ''}
                            displayEmpty
                            name="itemType"
                            defaultText="Select an option"
                            onChange={(e) => {
                                handleChangeInput(e.target.name, e.target.value);
                            }}
                            />

                            <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                                "Type",
                                formData.itemType,
                                "required"
                            )}</FormHelperText>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Box className={Styles.labelAndItem}>
                        <InputLabel>Unit of Measurement (optional)</InputLabel>
                        <BasicSelect
                        fullWidth
                        options={ITEM_UNIT_OPTION}
                        mapping={{value:'value',label:'label'}}
                        variant={"outlined"}
                        value={formData.unitOfItem}
                        onChange={(e) => {
                            handleChangeInput(e.target.name, e.target.value);
                        }}
                        displayEmpty
                        defaultText="Select an option"
                        name="unitOfItem"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box className={Styles.labelAndItem}>
                        <InputLabel required>Selling Price</InputLabel>
                        <Box>
                            <BasicInput
                            fullWidth
                            size={"small"}
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={(e) => {
                                if (validateTax(e.target.value)) {
                                    handleChangeInput(e.target.name, e.target.value);
                                }
                            }}
                            />
                            <FormHelperText className={Styles.helperText}>{simpleValidator.current.message(
                                    "Selling Price",
                                    formData.price,
                                    "required|numeric|min:0,num"
                                )}</FormHelperText>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box className={Styles.buttonStack}>
                        <Button
                            color={"primary"}
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={loading}
                            className={Styles.saveButton}
                        >
                            {loading ? "Saving..." : "Save"}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddOrEditProduct;