import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const VirtualNumberTopSkeleton = () => {
  return (
    <Box p={1} > 
      <Box marginTop={1} borderRadius={2} width={"100%"}>
        <Skeleton variant="rounded" width={'100%'} height={'98px'} sx={{bg:'#F6F7FB'}}/>
      </Box>
      <Box pt={3}>
        <Skeleton variant="rounded" width={'150px'} height={'36px'} sx={{bg:'#F6F7FB'}}/>
      </Box> 
      <Box py={3} display={'flex'}  justifyContent={'right'} alignItems={'end'} sx={{gap:'16px',bg:'#F6F7FB'}}>
        <Skeleton variant="rounded" width={'340px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'225px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'218px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'305px'} height={'50px'}/>
      </Box>
       <Box display={'flex'}  justifyContent={'right'} alignItems={'end'} sx={{gap:'16px',bg:'#F6F7FB'}}>
        <Skeleton variant="rounded" width={'280px'} height={'50px'}/>
      </Box>
      <Box py={3} display={'flex'} mx={'auto'} sx={{gap:'4px'}}>
        <Skeleton variant="rounded" width={'217px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'200px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'200px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'234px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'266px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'284px'} height={'50px'}/>
        <Skeleton variant="rounded" width={'284px'} height={'50px'}/>
      </Box>
    </Box>
  );
};

export default VirtualNumberTopSkeleton;