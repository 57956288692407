import { styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  getInsuranceCompanyApi,
  getInsuranceCompanyFoldersApi,
} from "../../../api/productsApi";
import useDebounce from "../../../hooks/useDebounce";
import Content from "./content";
import SideBar from "./sidebar/SideBar";

const InsuranceCompaniesWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
}));
const InsuranceCompaniesNew = () => {
  const [insuranceCompanyList, setInsuranceCompanyList] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderObject, setFolderObject]= useState({})

  const [isCompanyListLoading, setIsCompanyListLoading] = useState(false);
  const [isFolderListLoading, setIsFolderListLoading] = useState(false);
  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState(null);
  const [filterTrigger, setFilterTrigger] = useState(1);
  const [status,setStatus] = useState('');


  // get folder / category
  useEffect(() => {
    const getFolders = async () => {
      setIsFolderListLoading(true);
      try {
        const response = await getInsuranceCompanyFoldersApi();
        if (response.success && response.data[0]) {
          setFolderList(response.data);
           setSelectedFolder(null);

          const folderObjt = {};
      
          for (let i = 0; i < response.data.length; i++) {
            const folder = response.data[i];
            folderObjt[folder.id] = folder;
          }
          setFolderObject(folderObjt);
        } else {
          setFolderList([]);
        }
      } catch (error) {
      } finally {
        setIsFolderListLoading(false);
      }
    };
    getFolders();
  }, []);

  // get insurance company
  useEffect(() => {
    const getInsuranceCompany = async () => {
      setIsCompanyListLoading(true);
      const payload = {
        insurance_company_folder_id: selectedFolder || null,
        search: debouncedSearch || null,
        page:page+1,
        limit:rowsPerPage,
        status:status==="ALL" ? null : status
      };

      try {
        const response = await getInsuranceCompanyApi(payload);

        if (response.success) {
          setInsuranceCompanyList(response.data);
          if(page===0){
            setTotal(response.total_count);
          }
          
        } else {
          setInsuranceCompanyList([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsCompanyListLoading(false);
      }
    };
   
      getInsuranceCompany();
    
  }, [selectedFolder,debouncedSearch, filterTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFolderList = (value) => {
    const { payload, type } = value;
    if (type === "update") {
      const folderObjt = {}
      folderObjt[payload.id] = payload;
      setFolderObject((prevState) => ({
        ...prevState,
        ...folderObjt,
      }));
      setFolderList((prevState) =>
        prevState.map((folder) => {
          if (folder.id === payload.id) {
            return payload;
          }
          return folder;
        })
      );
    } else {
      const folderObjt = {}
      folderObjt[payload.id] = payload;
      setFolderObject((prevState) => ({
        ...prevState,
        ...folderObjt,
      }));
      setFolderList((prevState) => [...prevState, payload]);
    }
  };

  return (
    <InsuranceCompaniesWrapper>
      <SideBar
        isLoading={isFolderListLoading}
        folderList={folderList}
        setFolderList={setFolderList}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        handleFolderList={handleFolderList}
      />
      <Content
        insuranceCompanyList={insuranceCompanyList}
        setInsuranceCompanyList={setInsuranceCompanyList}
        search={search}
        setSearch={setSearch}
        folderList={folderList}
        handleFolderList={handleFolderList}
        isLoading={isCompanyListLoading}
        selectedFolder={selectedFolder}
        total={total}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        page={page}
        folderObject={folderObject}
        filterTrigger={filterTrigger}
        setFilterTrigger={setFilterTrigger}
        status={status}
        setStatus={setStatus}
      />
    </InsuranceCompaniesWrapper>
  );
};

export default InsuranceCompaniesNew;