import { Button, withStyles } from "@material-ui/core";

  export const CustomButton = withStyles(()=>({
    root: {
      fontWeight: 600,
      fontSize: "16px",
      width:'185px !important',
      backgroundColor: "#346fef !important",
      color: "white !important",
      "&:focus, &:hover": {
        backgroundColor: "#346fef !important",
      },
    }
  }))(Button);

  export const OutlineButton = withStyles(()=>({
    root: {
      fontWeight: 400,
      fontSize: "13px",
      width:'150px !important',
      backgroundColor: "transparent !important",
      color: "#346fef !important",
      border:'1px solid #346FEF',
      padding: '4px 10px',
      "&:focus, &:hover": {
        backgroundColor: "transparent !important",
      },
    }
  }))(Button);