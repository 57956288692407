import { useState } from "react";

import Styles from "./style.module.css";
import { FieldTypes } from "../../../../customField/custom-field-v2/helpers/Constants";
import {
  AddIcon,
  CloseIcon,
} from "../../../../customField/custom-field-v2/helpers/IconList";

const state = [
  { group_name: "Group 1" },
  { group_name: "Group 2" },
  { group_name: "Group 3" },
  { group_name: "Group 4" },
];

const AddCustomField = ({
  title = "Add New Custom Field",
  onClose = () => {},
}) => {
  const [states, setStates] = useState({
    adding: false,
    field_type: null,
    field_name: "",
    field_value: "",
    group_name: state?.selectedFolder,
    new_group: "",
  });
  const handleFieldType = (type) => {
    setStates({ ...states, field_type: type });
  };
  const handleFieldName = (e) => {
    setStates({ ...states, field_name: e.target.value });
  };
  const handleFieldValue = (e) => {
    setStates({ ...states, field_value: e.target.value });
  };
  const handleGroupName = (name) => {
    setStates({ ...states, group_name: name, new_group: "" });
  };
  const handleNewGroupName = (e) => {
    setStates({ ...states, group_name: null, new_group: e.target.value });
  };
  const handleSubmit = (e) => {};
  const renderFieldTypes = () => {
    const view = [];
    FieldTypes.forEach((item, index) => {
      view.push(
        <span
          onClick={() => handleFieldType(item.value)}
          key={index}
          className={`${Styles.fieldTypeWrp} ${
            states.field_type === item.value ? Styles.selected : ""
          }`}
        >
          {item.title}
          {item.new && <small className={Styles.new}>new</small>}
        </span>
      );
    });
    return view;
  };
  const renderFolderGroup = () => {
    const view = [];
    state.forEach((item, index) => {
      view.push(
        <span
          onClick={() => handleGroupName(item.group_name)}
          key={index}
          className={`${Styles.fieldTypeWrp} ${
            states.group_name === item.group_name ? Styles.selected : ""
          }`}
        >
          {item.group_name}
        </span>
      );
    });
    return view;
  };
  return (
    <div className={Styles.mainWrp}>
      <div className={Styles.headerWrp}>
        <span className={Styles.title}>{title}</span>
        <span className={Styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </span>
      </div>
      <div className={`${Styles.bodyWrp} cf_scroll_bar`}>
        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            What kind of field would you like to add?
          </span>
          <div className={Styles.contentBlock}>{renderFieldTypes()}</div>
        </div>

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>
            Field Name <code style={{ color: "red" }}>*</code>
          </span>
          <div className={Styles.inputFieldWrp}>
            <input
              onChange={handleFieldName}
              value={states.field_name}
              className={Styles.inputField}
              placeholder="Enter field name"
            />
          </div>
        </div>

        {/* for value: check box, radio button, drop down */}
        {(states.field_type === 5 ||
          states.field_type === 6 ||
          states.field_type === 7) && (
          <div className={Styles.inputWrp}>
            <span className={Styles.lebelText}>
              Set value <code style={{ color: "red" }}>*</code>
            </span>
            <div className={Styles.inputFieldWrp}>
              <input
                onChange={handleFieldValue}
                value={states.field_value}
                className={Styles.inputField}
                placeholder="Enter field value"
              />
              <span className={Styles.hintText}>
                Multiple values separated by <code>,</code> sign. For example:
                value1 , value2 , value3
              </span>
            </div>
          </div>
        )}

        <div className={Styles.inputWrp}>
          <span className={Styles.lebelText}>Select a folder group</span>
          <div className={Styles.contentBlock}>{renderFolderGroup()}</div>
        </div>

        <div className={Styles.inputWrp}>
          {state.length > 0 && (
            <span className={Styles.lebelText}>
              <small>OR</small> Create A New Folder
            </span>
          )}
          <div className={Styles.inputFieldWrp}>
            <input
              onChange={handleNewGroupName}
              value={states.new_group}
              className={Styles.inputField}
              placeholder="Enter folder name"
            />
          </div>
        </div>
      </div>

      <div className={Styles.footerWrp}>
        <div className={Styles.buttonWrp}>
          <div className={Styles.button} onClick={handleSubmit}>
            <span className={Styles.submitIcon}>
              <AddIcon />{" "}
            </span>
            <span className={Styles.submitText}>
              {states.adding ? "Adding Field..." : "Add Field"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddCustomField;
