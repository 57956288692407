import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
  },
  headerContainer: { marginLeft: "10px" },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "20px",
    fontFamily: "Liberation Sans",
    margin: "5px",
    height: "25px",
  },
  logo: {
    objectFit: "cover",
    height: "60px",
    width: "60px",
  },
  bodyTitle: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Liberation Sans",
    margin: "5px",
  },
  bodyDescription: {
    fontSize: "16px",
    fontFamily: "Liberation Sans",
    lineHeight: "22px",
    fontWeight: 400,
    margin: "5px",
  },
  backButtonArea: {
    padding: "0px 40px 40px 0px",
    display: "flex",
    justifyContent: "end",
  },
  backButton: {
    backgroundColor: "#4550f8",
    color: "white",
    transition: "background-color 0.3s ease",
    padding: "8px 16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover, &:focus": {
      backgroundColor: "#4550f8",
      boxShadow: "0 2px 4px rgba(0,123,255,.2)",
    },
  },
  list: {
    marginTop: "10px",
    marginLeft: 50,
  },
  listItem: {
    lineHeight: "22px",
    fontSize: "15px",
    color: "black",
    fontFamily: "Liberation Sans",
    fontWeight: 400,
    listStyleType: "disc !important",
  },
  contentBody: {
    padding: "10px 30px 10px 40px",
  },
}));

const CollaboratorsDescriptionModal = ({ open, onClose }) => {
  const classes = useStyles();
  const ImgSrc = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/policy/1.0.0/policy_types_icon.png`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={ImgSrc} alt="logo" className={classes.logo} />
          <div className={classes.headerContainer}>
            <p className={classes.dialogTitle}>Understanding Collaborators</p>
            <p className={classes.bodyDescription}>
              Take a moment to learn about collaborators
            </p>
          </div>
        </Box>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <div className={classes.contentBody}>
          <div className={classes.section}>
            <p className={classes.bodyTitle} gutterBottom>
              Collaborators
            </p>
            <p className={classes.bodyDescription}>
              A collaborator is another user on your team that you work closely
              with. If you add another user as a collaborator, they will have
              access to everything inside of your account.
            </p>
          </div>
          <div className={classes.section}>
            <ul className={classes.list}>
              <li className={classes.listItem}>Contacts</li>
              <li className={classes.listItem}>Conversations</li>
              <li className={classes.listItem}>Deal Pipelines</li>
              <li className={classes.listItem}>Campaigns</li>
              <li className={classes.listItem}>Forms</li>
              <li className={classes.listItem}>Triggers</li>
              <li className={classes.listItem}>Automations & More</li>
            </ul>
          </div>
          <div className={classes.section}>
            <p className={classes.bodyTitle} gutterBottom>
              When should you use the collaborator feature?
            </p>
            <p className={classes.bodyDescription}>
              By designating another user as a collaborator, they will now have
              access to all contacts, data, and features inside your account.
              This access will allow them to assist you with your daily
              activities without providing access to the data of other users on
              your team.
              <br />
              <br />
              If a user is marked as an Admin or a Standard User, they already
              have access to contacts and features inside your account. The
              collaborator feature will only be used when you want to share your
              access with a Limited User.
              <br />
              <br />
              Limited users have the lowest permission level and can only access
              information inside their own account. They can't view or access
              anything related to other users on their team.
            </p>
          </div>
        </div>
        <div className={classes.backButtonArea}>
          <button onClick={onClose} className={classes.backButton}>
            <span style={{ paddingLeft: "10px", paddingRight: "10px" }}>
              Back
            </span>
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CollaboratorsDescriptionModal;
