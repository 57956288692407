import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { CustomScrollbar } from "../products/productsStyle";
import EachTableItem from "./EachTableItem";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#f3f3f3",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  tableBodyHeight: {
    maxHeight: `calc(100vh - 355px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
});

const sortIcon = () => {
  return (
    <svg
      style={{ cursor: "pointer", float: "right" }}
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 11.5L5.14286 17.5L10.2857 11.5H0Z" fill="#546376"></path>
      <path
        d="M10.2852 6.5L5.1423 0.5L-0.000557899 6.5L10.2852 6.5Z"
        fill="#546376"
      ></path>
    </svg>
  );
};

const DUMMY_DATA = [
  {
    id: 9777,
    full_name: "Nakib",
    email: "nakib@orangetoolz.com",
    status: 1,
    parent_id: 1,
    is_template_user: 0,
    created_at: "2023-07-24 15:59:16",
    name: "User Admin",
    limit: -1,
    used: 0,
    local_created_at: {
      date: "2023-07-24",
      time: "15:59:16",
      for_human: "1 year ago",
      date_time: "2023-07-24 15:59:16",
    },
    local_updated_at: {
      date: null,
      time: null,
      for_human: null,
      date_time: null,
    },
  },
  {
    id: 6147,
    full_name: "new tech",
    email: "new_tech_snap2@mail.com",
    status: 1,
    parent_id: 1,
    is_template_user: 1,
    created_at: "2022-12-07 10:02:34",
    name: "User Agent",
    limit: null,
    used: null,
    local_created_at: {
      date: "2022-12-07",
      time: "10:02:34",
      for_human: "1 year ago",
      date_time: "2022-12-07 10:02:34",
    },
    local_updated_at: {
      date: null,
      time: null,
      for_human: null,
      date_time: null,
    },
  },
  {
    id: 6148,
    full_name: "snap user5",
    email: "snap_user5@mail.com",
    status: 1,
    parent_id: 1,
    is_template_user: 1,
    created_at: "2022-12-07 10:25:35",
    name: "User Agent",
    limit: null,
    used: null,
    local_created_at: {
      date: "2022-12-07",
      time: "10:25:35",
      for_human: "1 year ago",
      date_time: "2022-12-07 10:25:35",
    },
    local_updated_at: {
      date: null,
      time: null,
      for_human: null,
      date_time: null,
    },
  },
  {
    id: 6153,
    full_name: "snap user9",
    email: "snap_user9@mail.com",
    status: 1,
    parent_id: 1,
    is_template_user: 1,
    created_at: "2022-12-07 10:50:58",
    name: "User Agent",
    limit: null,
    used: null,
    local_created_at: {
      date: "2022-12-07",
      time: "10:50:58",
      for_human: "1 year ago",
      date_time: "2022-12-07 10:50:58",
    },
    local_updated_at: {
      date: null,
      time: null,
      for_human: null,
      date_time: null,
    },
  },
  {
    id: 6037,
    full_name: "Snehasish",
    email: "snehasish@orangetoolz.com",
    status: 1,
    parent_id: 1,
    is_template_user: 0,
    created_at: "2022-11-28 09:55:18",
    name: "User Agent",
    limit: -1,
    used: 0,
    local_created_at: {
      date: "2022-11-28",
      time: "09:55:18",
      for_human: "2 years ago",
      date_time: "2022-11-28 09:55:18",
    },
    local_updated_at: {
      date: null,
      time: null,
      for_human: null,
      date_time: null,
    },
  },
];

const TagsTable = () => {
  const classes = useStyles();

  return (
    <>
      <CustomScrollbar className={`${classes.tableBodyHeight}`}>
        <TableContainer component={"span"}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeader} width={"25%"}>
                  Name
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  System Contacts
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  My Contacts

                  {sortIcon()}
                </TableCell>
                <TableCell className={classes.tableHeader}>
                  Created

                  {sortIcon()}
                </TableCell>
                <TableCell align={"center"} className={classes.tableHeader}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {DUMMY_DATA.map((user) => (
                <EachTableItem key={user.id} user={user} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomScrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        // count={total}
        // rowsPerPage={rowsPerPage}
        // page={page}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default TagsTable;
