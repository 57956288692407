import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles({
  closeButton: {
    color: " white !important",
    padding: "8px 25px 8px 25px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
  loadingArea: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  },
  videoBody: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
  },
});

const HowItWorksModal = ({ open, onClose,videoSrc }) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (!loading) return;
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box minWidth={600} p={"16px"}>
          {loading ? (
            <div className={classes.loadingArea}
            >
              <Skeleton
                width={"100%"}
                height={315}
                variant="rectangular"
                style={{ borderRadius: "8px" }}
              />
            </div>
          ) : (
            <div className={classes.videoBody}>
              <iframe
                width="560"
                height="315"
                src={videoSrc}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          )}

          <Box display={"flex"} justifyContent={"end"} mt={2} style={{paddingRight:"4px"}}>
            <Button
              variant="contained"
              className={classes.closeButton}
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default HowItWorksModal;
