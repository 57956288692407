export const icons = {
  plus: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <path
        d="M13.8447 9.9985C13.8447 10.353 13.8139 10.6395 13.4594 10.6395H10.6395V13.4594C10.6395 13.8132 10.353 13.8447 9.9985 13.8447C9.64401 13.8447 9.35747 13.8132 9.35747 13.4594V10.6395H6.5376C6.18375 10.6395 6.15234 10.353 6.15234 9.9985C6.15234 9.64401 6.18375 9.35747 6.5376 9.35747H9.35747V6.5376C9.35747 6.18311 9.64401 6.15234 9.9985 6.15234C10.353 6.15234 10.6395 6.18311 10.6395 6.5376V9.35747H13.4594C13.8139 9.35747 13.8447 9.64401 13.8447 9.9985Z"
        fill="#3C7EF3"
      />
    </svg>
  ),
  cross: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1252 17.0812L17.1357 15.0693L6.878 4.80485L4.86747 6.8167L15.1252 17.0812Z"
        fill="white"
      ></path>
      <path
        d="M6.878 17.0838L17.1357 6.81934L15.1252 4.80749L4.86747 15.072L6.878 17.0838Z"
        fill="white"
      ></path>
    </svg>
  ),
  crossWhiteBg: (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1016 3.90156C14.2016 0.0015626 7.80156 0.0015626 3.90156 3.90156C0.00156271 7.80156 0.00156271 14.2016 3.90156 18.1016C7.80156 22.0016 14.1016 22.0016 18.0016 18.1016C21.9016 14.2016 22.0016 7.80156 18.1016 3.90156ZM13.8016 15.2016L11.0016 12.4016L8.20156 15.2016L6.80156 13.8016L9.60156 11.0016L6.80156 8.20156L8.20156 6.80156L11.0016 9.60156L13.8016 6.80156L15.2016 8.20156L12.4016 11.0016L15.2016 13.8016L13.8016 15.2016Z"
        fill="white"
      />
    </svg>
  ),
  search: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
        fill="#133159"
        fillOpacity="0.65"
      />
    </svg>
  ),
  edit: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08573 2.91356L13.0862 6.91425L4.39929 15.6016L0.832505 15.9953C0.355018 16.0482 -0.0484094 15.6444 0.00471426 15.1669L0.401579 11.5975L9.08573 2.91356ZM15.5606 2.31793L13.6822 0.43946C13.0962 -0.146487 12.146 -0.146487 11.56 0.43946L9.7929 2.20668L13.7934 6.20737L15.5606 4.44015C16.1465 3.85389 16.1465 2.90388 15.5606 2.31793Z"
        fill="#133159"
      />
    </svg>
  ),
  previous: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50171 0.248609C8.79377 0.248039 9.07682 0.349756 9.30171 0.536108C9.42828 0.641045 9.53291 0.769922 9.6096 0.915356C9.68629 1.06079 9.73353 1.21992 9.74863 1.38364C9.76372 1.54737 9.74637 1.71246 9.69757 1.86946C9.64876 2.02646 9.56946 2.1723 9.46421 2.29861L3.86421 8.99861L9.26421 15.7111C9.36804 15.839 9.44558 15.9861 9.49237 16.144C9.53916 16.3019 9.55427 16.4676 9.53685 16.6313C9.51943 16.7951 9.46981 16.9539 9.39084 17.0984C9.31187 17.2429 9.20511 17.3705 9.07671 17.4736C8.94737 17.5874 8.79592 17.6732 8.63184 17.7257C8.46776 17.7782 8.2946 17.7962 8.12324 17.7786C7.95187 17.761 7.78599 17.7082 7.63602 17.6234C7.48604 17.5387 7.3552 17.4238 7.25171 17.2861L1.21421 9.78611C1.03035 9.56244 0.929847 9.28189 0.929847 8.99236C0.929847 8.70283 1.03035 8.42227 1.21421 8.19861L7.46421 0.69861C7.5896 0.547338 7.7489 0.427757 7.92916 0.349571C8.10942 0.271387 8.30558 0.236795 8.50171 0.248609Z"
        fill="white"
      />
    </svg>
  ),
  backwardSlash: (
    <svg
      width="21"
      height="37"
      viewBox="0 0 21 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="19.7058"
        y1="0.470588"
        x2="0.88231"
        y2="35.7647"
        stroke="#3C7EF3"
        strokeWidth="2"
      />
    </svg>
  ),
  next: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32251 17.7514C1.03045 17.752 0.747402 17.6502 0.522512 17.4639C0.395939 17.359 0.291312 17.2301 0.214622 17.0846C0.137932 16.9392 0.0906866 16.7801 0.0755918 16.6164C0.0604969 16.4526 0.0778495 16.2875 0.126655 16.1305C0.175461 15.9735 0.25476 15.8277 0.360012 15.7014L5.96001 9.00139L0.560013 2.28889C0.456181 2.16103 0.378641 2.01391 0.331851 1.85599C0.285062 1.69806 0.269944 1.53245 0.287368 1.36866C0.304792 1.20488 0.354413 1.04615 0.433381 0.901604C0.512348 0.757058 0.619104 0.629544 0.747513 0.52639C0.876845 0.412595 1.0283 0.32676 1.19238 0.274274C1.35646 0.221788 1.52961 0.203783 1.70098 0.221389C1.87235 0.238995 2.03822 0.291833 2.1882 0.376585C2.33818 0.461337 2.46902 0.576175 2.57251 0.713891L8.61001 8.21389C8.79387 8.43756 8.89437 8.71811 8.89437 9.00764C8.89437 9.29717 8.79387 9.57773 8.61001 9.80139L2.36001 17.3014C2.23462 17.4527 2.07532 17.5722 1.89506 17.6504C1.7148 17.7286 1.51865 17.7632 1.32251 17.7514Z"
        fill="white"
      />
    </svg>
  ),
  sendIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="2"
        d="m3 6 7 7v8h4v-8l7-7V3H3z"
      />
    </svg>
  ),
  pdfIcon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 2h10.62l5.63 5.608V20.75c0 .69-.56 1.25-1.25 1.25h-15C3.56 22 3 21.44 3 20.75V3.25C3 2.56 3.56 2 4.25 2Z"
        fill="#E2574C"
      />
      <path
        d="M20.482 7.625h-4.357c-.69 0-1.25-.56-1.25-1.25V2.012l5.607 5.613Z"
        fill="#B53629"
      />
      <path
        d="M15.811 11.477c.21 0 .312-.183.312-.36 0-.183-.107-.36-.312-.36H14.62c-.233 0-.363.194-.363.407v2.93c0 .262.148.407.35.407.2 0 .35-.145.35-.407v-.804h.72c.224 0 .336-.183.336-.365 0-.178-.112-.355-.336-.355h-.72v-1.093h.855Zm-4.03-.72h-.873a.386.386 0 0 0-.405.404v2.936c0 .3.242.393.415.393h.916c1.084 0 1.8-.713 1.8-1.814-.002-1.164-.675-1.918-1.853-1.918Zm.042 3.01h-.532V11.48h.48c.725 0 1.04.487 1.04 1.159 0 .629-.31 1.126-.988 1.126Zm-3.197-3.01h-.864c-.244 0-.38.162-.38.407v2.93c0 .262.156.407.366.407s.366-.145.366-.407v-.855h.542c.668 0 1.22-.474 1.22-1.236 0-.745-.532-1.245-1.25-1.245Zm-.014 1.794h-.498v-1.105h.498c.307 0 .503.24.503.553 0 .312-.196.552-.503.552Z"
        fill="#fff"
      />
    </svg>
  ),
  restore: (
    <svg
      className="MuiSvgIcon-root jss100"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path>
    </svg>
  ),
  infoIcon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 .4C4.697.4.399 4.698.399 10A9.6 9.6 0 0 0 10 19.601c5.301 0 9.6-4.298 9.6-9.601 0-5.302-4.299-9.6-9.6-9.6zm.896 3.466c.936 0 1.211.543 1.211 1.164 0 .775-.62 1.492-1.679 1.492-.886 0-1.308-.445-1.282-1.182 0-.621.519-1.474 1.75-1.474zM8.498 15.75c-.64 0-1.107-.389-.66-2.094l.733-3.025c.127-.484.148-.678 0-.678-.191 0-1.022.334-1.512.664l-.319-.523c1.555-1.299 3.343-2.061 4.108-2.061.64 0 .746.756.427 1.92l-.84 3.18c-.149.562-.085.756.064.756.192 0 .82-.232 1.438-.719l.362.486c-1.513 1.512-3.162 2.094-3.801 2.094z" />
    </svg>
  ),
  modalCloseIcon: (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6484 7.3499C22.7984 1.4999 13.1984 1.4999 7.34844 7.3499C1.49844 13.1999 1.49844 22.7999 7.34844 28.6499C13.1984 34.4999 22.6484 34.4999 28.4984 28.6499C34.3484 22.7999 34.4984 13.1999 28.6484 7.3499ZM22.1984 24.2999L17.9984 20.0999L13.7984 24.2999L11.6984 22.1999L15.8984 17.9999L11.6984 13.7999L13.7984 11.6999L17.9984 15.8999L22.1984 11.6999L24.2984 13.7999L20.0984 17.9999L24.2984 22.1999L22.1984 24.2999Z"
        fill="#133159"
        fillOpacity="0.35"
      ></path>
    </svg>
  ),
  emailModalIcon:(size = 80)=> (
    <svg
      id="Capa_1"
      enable-background="new 0 0 512 512"
      height={size}
      viewBox="0 0 512 512"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="432.334"
        x2="198.334"
        y1="502.645"
        y2="192.646"
      >
        <stop offset="0" stop-color="#d2deff" stop-opacity="0" />
        <stop offset="1" stop-color="#b7c5ff" />
      </linearGradient>
      <g>
        <path
          d="m501.873 153.583c-22.357-56.888-91.226-91.003-149.556-68.427-66.582 25.77-44.506 131.673-104.983 153.088-32.669 11.568-80.962-31.644-113.969-38.271-60.821-12.21-115.881 30.705-129.439 90.134-13.832 60.63 8.353 141.61 74.967 158.066 79.35 19.602 120.683-51.729 187.249-72.211 68.31-21.019 144.263 28.622 200.814-31.776 41.78-44.62 57.055-134.271 34.917-190.603z"
          fill="url(#SVGID_1_)"
        />
        <g>
          <path
            d="m476.847 178.593c0-39.497-32.019-71.517-71.517-71.517-30.6 0-56.699 19.225-66.91 46.25h-258.608c-11.046 0-20 8.954-20 20v221.41c0 11.046 8.954 20 20 20h352.375c11.046 0 20-8.954 20-20v-162.14c15.099-13.113 24.66-32.435 24.66-54.003z"
            fill="#2626bc"
            opacity=".1"
          />
          <g>
            <g fill="#fff">
              <path d="m432.187 389.736h-352.375c-11.046 0-20-8.954-20-20v-221.41c0-11.046 8.954-20 20-20h352.375c11.046 0 20 8.954 20 20v221.41c0 11.046-8.954 20-20 20z" />
              <path d="m262.092 223.711 190.096 146.025c0 11.046-8.954 20-20 20h-352.376c-11.046 0-20-8.954-20-20l190.096-146.025c3.593-2.76 8.591-2.76 12.184 0z" />
              <path d="m249.908 294.352-190.096-146.026c0-11.046 8.954-20 20-20h352.375c11.046 0 20 8.954 20 20l-190.095 146.026c-3.593 2.759-8.591 2.759-12.184 0z" />
            </g>
            <g>
              <path
                d="m370.947 363.125h-32.134c-2.762 0-5-2.238-5-5v-22.154c0-2.761 2.238-5 5-5h32.134c2.762 0 5 2.239 5 5v22.154c0 2.762-2.238 5-5 5z"
                fill="#6583fe"
              />
              <g>
                <g>
                  <g>
                    <path
                      d="m311.871 342.743h-27.833c-2.762 0-5-2.239-5-5s2.238-5 5-5h27.833c2.762 0 5 2.239 5 5s-2.238 5-5 5z"
                      fill="#2626bc"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="m311.871 363.125h-97.833c-2.762 0-5-2.239-5-5s2.238-5 5-5h97.833c2.762 0 5 2.239 5 5 0 2.762-2.238 5-5 5z"
                      fill="#2626bc"
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <ellipse
                cx="405.33"
                cy="153.593"
                fill="#6583fe"
                rx="71.517"
                ry="71.517"
                transform="matrix(.16 -.987 .987 .16 188.793 529.087)"
              />
              <path
                d="m405.33 230.11c42.191 0 76.517-34.325 76.517-76.517s-34.325-76.517-76.517-76.517-76.517 34.325-76.517 76.517c0 24.544 11.629 46.41 29.653 60.421l-99.42 76.372c-1.791 1.378-4.297 1.379-6.092 0l-187.962-144.386c1.121-7.17 7.341-12.674 14.82-12.674h229.366c2.762 0 5-2.239 5-5s-2.238-5-5-5h-229.366c-13.785 0-25 11.215-25 25v221.41c0 13.785 11.215 25 25 25h352.375c13.785 0 25-11.215 25-25v-132.14c0-2.761-2.238-5-5-5s-5 2.239-5 5v121.994l-130.907-100.559 50.94-39.13c11.23 6.48 24.239 10.209 38.11 10.209zm-209.61 28.921-130.908 100.559v-201.118zm251.27 113.018c-1.118 7.174-7.32 12.687-14.803 12.687h-352.375c-7.483 0-13.685-5.513-14.803-12.687l138.919-106.713 42.934 32.98c2.69 2.066 5.914 3.1 9.138 3.1s6.447-1.033 9.138-3.1l42.934-32.981zm-108.177-218.456c0-36.677 29.839-66.517 66.517-66.517s66.517 29.839 66.517 66.517c0 36.677-29.839 66.517-66.517 66.517s-66.517-29.84-66.517-66.517z"
                fill="#2626bc"
              />
            </g>
            <path
              d="m374.114 129.204 10.206 24.389-10.206 24.389c-1.733 4.142 2.448 8.292 6.577 6.528l61.619-26.319c4.048-1.729 4.048-7.467 0-9.196l-61.619-26.319c-4.129-1.764-8.31 2.386-6.577 6.528z"
              fill="#fff"
            />
          </g>
          <g fill="#6583fe">
            <path d="m330.828 81.671c-3.714 0-7.427-1.413-10.253-4.24-5.654-5.654-5.654-14.853 0-20.506 5.651-5.652 14.852-5.654 20.505 0 5.654 5.654 5.654 14.853 0 20.506-2.826 2.827-6.539 4.24-10.252 4.24zm0-18.991c-1.152 0-2.305.438-3.183 1.316-1.754 1.754-1.754 4.609 0 6.364 1.756 1.754 4.608 1.755 6.364 0 1.754-1.754 1.754-4.609 0-6.364-.877-.877-2.03-1.316-3.181-1.316z" />
            <path d="m218.538 459.315c-3.713 0-7.427-1.414-10.253-4.24-5.654-5.654-5.654-14.853 0-20.506 5.652-5.653 14.854-5.653 20.506 0 5.654 5.654 5.654 14.853 0 20.506-2.826 2.827-6.54 4.24-10.253 4.24zm0-18.991c-1.152 0-2.305.438-3.183 1.316-1.754 1.754-1.754 4.609 0 6.364 1.756 1.755 4.609 1.755 6.365 0 1.754-1.754 1.754-4.609 0-6.364-.877-.877-2.03-1.316-3.182-1.316z" />
            <path d="m294.78 94.547c-1.279 0-2.56-.488-3.535-1.464l-5.965-5.964-5.965 5.964c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l9.5-9.5c1.951-1.952 5.119-1.952 7.07 0l9.5 9.5c1.953 1.953 1.953 5.119 0 7.071-.975.975-2.255 1.464-3.535 1.464z" />
            <path d="m439.746 440.322c-1.279 0-2.56-.488-3.535-1.464l-5.965-5.964-5.965 5.964c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l9.5-9.5c1.951-1.952 5.119-1.952 7.07 0l9.5 9.5c1.953 1.953 1.953 5.119 0 7.071-.975.975-2.256 1.464-3.535 1.464z" />
            <path d="m178.884 445.072c-1.279 0-2.56-.488-3.535-1.464l-5.965-5.964-5.965 5.964c-1.951 1.952-5.119 1.952-7.07 0-1.953-1.953-1.953-5.119 0-7.071l9.5-9.5c1.951-1.952 5.119-1.952 7.07 0l9.5 9.5c1.953 1.953 1.953 5.119 0 7.071-.976.975-2.256 1.464-3.535 1.464z" />
          </g>
        </g>
      </g>
    </svg>
  ),
};