import {
  Dialog,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import React, { useEffect, useState } from "react";
import {
  calendarSyncStore,
  emailCalendarListData,
  emailListData,
} from "../../../api/profileApi";
import Utils from "../../../helpers/Utils";
import CustomConfirmModal from "../../common/CustomConfirmModal";
import DynamicSkeleton from "../../common/DynamicSkeleton";
import BasicSelect from "../../custom/BasicSelect";
import {
  ButtonContainer,
  StyledButton,
  StyledOptionFormatter,
  StyledSelect
} from "./CalanderSyncDrawerCss";
import {
  CalenderSyncPopupStyle,
  CustomFormControlLabel,
  CustomRadio,
  CustomRadioGroup,
} from "./CalenderSyncPopupStyle";

const events = [
  {
    value: 2,
    label: "Appointment",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-calendar h-6 w-6 stroke-current"
      >
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
        <line x1="16" y1="2" x2="16" y2="6"></line>
        <line x1="8" y1="2" x2="8" y2="6"></line>
        <line x1="3" y1="10" x2="21" y2="10"></line>
      </svg>
    ),
  },
  {
    value: 1,
    label: "Call",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-phone-call"
      >
        <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
      </svg>
    ),
  },
  {
    value: 3,
    label: "Task",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-check-square h-6 w-6 stroke-current"
      >
        <polyline points="9 11 12 14 22 4"></polyline>
        <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
      </svg>
    ),
  },
  {
    value: 4,
    label: "Deadline",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-clock"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <polyline points="12 6 12 12 16 14"></polyline>
      </svg>
    ),
  },
  {
    value: 5,
    label: "Email",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-mail"
      >
        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
        <polyline points="22,6 12,13 2,6"></polyline>
      </svg>
    ),
  },
  {
    value: 6,
    label: "Followup",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-user-plus"
      >
        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
        <circle cx="8.5" cy="7" r="4"></circle>
        <line x1="20" y1="8" x2="20" y2="14"></line>
        <line x1="23" y1="11" x2="17" y2="11"></line>
      </svg>
    ),
  },
  {
    value: 7,
    label: "Others",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16px"
        height="16px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="feather feather-more-horizontal"
      >
        <circle cx="12" cy="12" r="1"></circle>
        <circle cx="19" cy="12" r="1"></circle>
        <circle cx="5" cy="12" r="1"></circle>
      </svg>
    ),
  },
];

const CalanderSyncDrawer = (props) => {
  const classes = CalenderSyncPopupStyle();
  const [loading, setLoading] = useState(
    typeof props.loading !== "undefined" ? props.loading : false
  );
  const [componentLoading, setComponentLoading] = useState(
    typeof props.componentLoading !== "undefined"
      ? props.componentLoading
      : true
  );
  const [syncType, setSyncType] = useState(2);
  const [incomingEvent, setIncomingEvent] = useState("");
  const [saveAsContact, setSaveAsContact] = useState(false);
  const [outgoingEvents, setOutgoingEvents] = useState(events);
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [emailCalendarList, setEmailCalendarList] = useState([]);
  const [selectedCalendarName, setSelectedCalendarName] = useState("");
  const [calenderConnector, setCalendarDisconnect] = useState(false);
  const calenderImg = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/calendar/1.0.0/calendar_icon.png`;

  useEffect(() => {
    emailListData()
      .then((resp) => {
        if (resp.data.status === "success") {
          const calendarEmails = resp.data.data;
          const filteredEmailList = [];
          calendarEmails.forEach((email) => {
            let singleEmail = {
              value: email.value,
              label: email.value,
              id: email.id,
            };
            filteredEmailList.push(singleEmail);
          });
          setEmailList(filteredEmailList);
        }
      })
      .catch((error) => { })
      .finally(() => {
        setComponentLoading(false);
      });
  }, [selectedEmail]);

  useEffect(() => {
    if (selectedEmail) {
      emailCalendarListData({ email_id: selectedEmail }).then((resp) => {
        if (resp.data.status === "success") {
          const calendarEmailsList = resp.data.data;
          const filteredCalenderList = [];
          calendarEmailsList.forEach((calendar) => {
            let singleEmail = {
              value: calendar.calendar_name,
              label: calendar.calendar_name,
              id: calendar.calendar_id,
            };
            filteredCalenderList.push(singleEmail);
          });

          setEmailCalendarList(filteredCalenderList);
          setSelectedCalendarName("");
          // setSelectedCalendarName(filteredEmailList[0]);
        }
      });
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (props.calendarSetting) {
      if (typeof props.calendarSetting.sync_type !== "undefined") {
        setSyncType(props.calendarSetting.sync_type);
      }

      if (
        typeof props.calendarSetting.incoming_event_task_id !== "undefined" &&
        props.calendarSetting.incoming_event_task_id
      ) {
        setIncomingEvent(
          events.filter(
            (singleEvent) =>
              singleEvent.value === props.calendarSetting.incoming_event_task_id
          )[0]
        );
      }

      if (
        typeof props.calendarSetting.does_fetch_lead !== "undefined" &&
        props.calendarSetting.does_fetch_lead
      ) {
        setSaveAsContact(
          props.calendarSetting.does_fetch_lead === 1 ? true : false
        );
      }

      if (
        typeof props.calendarSetting.outgoing_event_task_ids !== "undefined" &&
        props.calendarSetting.outgoing_event_task_ids
      ) {
        try {
          setOutgoingEvents(
            events.filter((item) =>
              JSON.parse(props.calendarSetting.outgoing_event_task_ids).includes(
                item.value
              )
            )
          );
        } catch (e) {
          console.log(e);
        }

      }
    }
  }, [props.calendarSetting]);

  useEffect(() => { }, [props.visible]);

  useEffect(() => {
    if (typeof props.loading !== "undefined") {
      setLoading(props.loading);
    }
  }, [props.loading]);

  useEffect(() => {
    if (typeof props.componentLoading !== "undefined") {
      setComponentLoading(props.componentLoading);
    }
  }, [props.componentLoading]);

  const closeModal = () => {
    setTimeout(() => {
      props.handleCancel();
    }, 400);
  };

  const handleSyncOnchange = (e) => {
    setSyncType(parseInt(e.target.value));
  };

  const handleSubmit = (_loading) => {
    if (!selectedCalendarName) {
      Utils.showNotification("No calendar is selected", "Error");
      return;
    }

    if (!outgoingEvents.length) {
      Utils.showNotification(
        "No Sync activities to calendar is selected",
        "Error"
      );
      return;
    }

    setLoading(_loading);

    let filteredOutgoingEvents = outgoingEvents.map(
      (singleEvent) => singleEvent.value
    );
    calendarSyncStore({
      form_data: {
        calendar_id: selectedCalendarName,
        name: selectedCalendarName,
        save_as_contact: saveAsContact,
        sync_type: syncType,
        incoming_event: syncType === 2 ? incomingEvent : null,
        outgoing_events: filteredOutgoingEvents,
        email_id: selectedEmail,
      },
    })
      .then((response) => {
        Utils.showNotification(response.data.data.html, "SUCCESS");
        props.callback(selectedCalendarName);
      })
      .catch((error) => {
        Utils.showNotification("Something went wrong", "ERROR");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const emaildata = (e) => {
    setSelectedCalendarName("");
    setSelectedEmail(e.target.value);
  };
  const selectedEvent = (e) => {
    setIncomingEvent(e.target.value);
  };
  const handleCloseModal = () => {
    setCalendarDisconnect(false);
  };
  const SaveContact = (e) => {
    setSaveAsContact(e.target.value === "true");
  };
  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={true}
        onClose={closeModal}
        PaperProps={{
          style: {
            width: "620px",
            overflowY: "hidden",
          },
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.header}>
              <img
                src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/calendar/1.0.0/calendar_icon.png`}
                alt=""
                width={60}
                height={60}
              />
              <div>
                <div className={classes.headerTitle}>Connect Your Calendar</div>
                <div className={classes.headerDescription}>
                  Complete the steps below and click connect.
                </div>
              </div>
            </div>
            <Grid item xs={12}>
              {componentLoading ? (
                <div>
                  <DynamicSkeleton count={9} height={"60px"} />
                </div>
              ) : (
                <div className={classes.content}>
                  <div className={classes.bodyContent}>
                    <div>
                      <div className={classes.SelectTitle}>
                        Select the email account associated with your calendar:
                      </div>
                      <BasicSelect
                        options={emailList}
                        onChange={(e) => {
                          emaildata(e);
                        }}
                        mapping={{
                          value: "id",
                          label: "value",
                        }}
                        value={selectedEmail}
                        defaultText="Select an Email"
                      ></BasicSelect>
                    </div>
                    <div className="form-group mt-2">
                      <div className={classes.SelectTitle}>
                        Select the calendar you want to connect:
                      </div>
                      <BasicSelect
                        options={emailCalendarList}
                        onChange={(e) => {
                          setSelectedCalendarName(e.target.value);
                        }}
                        mapping={{
                          value: "id",
                          label: "label",
                        }}
                        value={selectedCalendarName}
                        placeholder="Select calendar to sync"
                        defaultText="Select a Calendar"
                      />
                    </div>
                    <div className="form-group">
                      <div className={classes.SelectTitle}>
                        Select a calendar sync option:
                      </div>
                      <RadioGroup
                        column
                        value={syncType}
                        onChange={handleSyncOnchange}
                      >
                        <CustomFormControlLabel
                          value={2}
                          control={<CustomRadio />}
                          label="Two-Way Synchronization (recommended)"
                          checked={syncType === 2}
                        />
                        <CustomFormControlLabel
                          value={1}
                          control={<CustomRadio />}
                          label="One-Way Synchronization"
                          checked={syncType === 1}
                        />
                      </RadioGroup>
                    </div>
                    {parseInt(syncType) === 2 && (
                      <div className="form-group">
                        <div className={classes.SelectTitle}>
                          Save new calendar events as:
                        </div>
                        <BasicSelect
                          options={events}
                          value={incomingEvent}
                          onChange={(e) => {
                            selectedEvent(e);
                          }}
                          mapping={{
                            value: "value",
                            label: "label",
                          }}
                          defaultText="Select an option"
                        />
                      </div>
                    )}

                    <div className="form-group">
                      <div className={classes.SelectTitle}>
                        Select the following activity types you would like to
                        sync:
                      </div>
                      <StyledSelect
                        options={events}
                        onChange={(selectedOption) => {
                          setOutgoingEvents(selectedOption);
                        }}
                        value={outgoingEvents}
                        formatOptionLabel={({ value, label, icon }) => (
                          <StyledOptionFormatter>
                            {icon && (
                              <span className="input-select__icon">{icon}</span>
                            )}
                            <span>{label}</span>
                          </StyledOptionFormatter>
                        )}
                        isMulti={true}
                        placeholder={"Select activities"}
                        classNamePrefix="select"
                        components={{
                          DropdownIndicator: null,
                          IndicatorSeparator: null,
                        }}
                      />
                    </div>
                    <div className={classes.checkboxContainer}>
                      <div className={classes.SelectTitle}>
                        Would you like to create a new contact record when
                        someone books an appointment?
                      </div>
                      <CustomRadioGroup
                        aria-labelledby="save-as-contact"
                        name="save-as-contact"
                        value={saveAsContact}
                        onChange={(e) => SaveContact(e)}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          style={{ color: "#000000" }}
                          label="Yes (recommended)"
                        />
                        <FormControlLabel
                          value={false}
                          style={{ color: "#000000" }}
                          control={<Radio />}
                          label="No"
                        />
                      </CustomRadioGroup>
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <ButtonContainer>
          <StyledButton
            className="secondary"
            onClick={closeModal}
            type="button"
          >
            Cancel
          </StyledButton>
          <StyledButton
            className="primary"
            onClick={() => handleSubmit(true)}
            disabled={loading}
            type="submit"
          >
            {loading && <i className="la la-refresh la-spin" />}
            Connect
          </StyledButton>
        </ButtonContainer>
      </Drawer>
      <Dialog open={calenderConnector} onClose={handleCloseModal}>
        <CustomConfirmModal
          title="Disconnect Calendar"
          onClose={handleCloseModal}
          closeButtonText="Exit"
          showCloseButton={true}
          description={"Do you want to disconnect this calendar"}
          imgSrc={calenderImg}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default CalanderSyncDrawer;
