import moment from "moment";
import SwitchC from "../customField/subComponents/Switch";

const TeamUserList = (props) => {
    return(
        <div className="map__access__team__user__list">
            {props.isLoading &&
                <div>Please wait...</div>
            }

            {!props.isLoading && props.users.length === 0 &&
                <div>No team member found !</div>
            }

            <div className="mi_user_table_header">
                <p>Give Your Sub User Access</p>
            </div>

            {!props.isLoading && props.users.length !== 0 &&
                <div className="map__access_user_table">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        <span>
                                        {/* <FormControlLabel control={<Checkbox name="checkedC" />} /> */}
                                        Name
                                        </span>
                                        {/* <ArrowDownwardIcon /> */}
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Email
                                        {/* <ArrowDownwardIcon /> */}
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Access
                                        {/* <ArrowDownwardIcon /> */}
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                        {props.users.map((item, index) => {
                            let next_update_date = moment(item.change_date).format('MM/DD/YYYY');
                            return (
                                <tr key={`${item.id}`}>
                                    <td>
                                        {/* <FormControlLabel control={<Checkbox name="checkedC" />} /> */}
                                        {item.full_name}
                                    </td>
                                    <td>{item.email}</td>
                                    <td>
                                        <SwitchC 
                                            status={item.has_access} 
                                            brandColor={"#3C7EF3"}
                                            onChange={() => {props.handleUserAccess(item, !item.has_access, index)}}
                                        />
                                        {item.has_access &&
                                        <span><small>You can not update this user before {next_update_date}</small></span>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    
                </div>
            }

        </div>
    )
}
export default TeamUserList;

/* 
get team user list data logic:
    get by parent id: user id from user table
    join external_service_sub_account







*/