import React from "react";

const StepLeft = ({step,title,content='',content2='',img ,social=false}) => {
    return(
        <div className="geo__how__work__wr">
            <div className="geo__how__work__wr__left">
                <h2 className="geo__how__work__wr__left__step">{step}</h2>
                <h3 className="geo__how__work__wr__left__title">{title}</h3>
                <p className="geo__how__work__wr__left__content">{content}</p>
                <p>{content2}</p>
                {
                    social && 
                    <div className="geo__marketing__social">

                        <ol style={{listStyle : 'disc'}}>
                            <li style={{fontSize: '20px'}}>Direct Mail & Postcards</li>
                            <li style={{fontSize: '20px'}}>Email Marketing</li>
                            <li style={{fontSize: '20px'}}>Video Email</li>
                            <li style={{fontSize: '20px'}}>Text Message Marketing</li>
                            <li style={{fontSize: '20px'}}>Ringless Voicemail</li>
                            <li style={{fontSize: '20px'}}>Create & Filter A List of Prospects</li>
                        </ol>
                </div>
                }
                
            </div>
            <div className="geo__how__work__wr_right">
                <img src={img} alt="img"/>
            </div>
           
        </div>
    )
}


export default StepLeft;