import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";
import TagsTable from "./TagsTable";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(2),

    "& .record_search": {
      display: "flex",
      justifyContent: "flex-end",
      "& .search": {
        margin: 0,
        "&:fucus": {
          boxShadow: "none !important",
        },
      },

      "& .search_icon": {
        right: 12,
        top: 12,
      },
    },
  },
}));

const MainContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContent}>
      <Tabs
        // value={value}
        // onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        value={1}
      >
        <Tab disableRipple label="Global Tags" style={{textTransform: 'capitalize'}} />
        <Tab disableRipple label="My Tags" style={{textTransform: 'capitalize'}} />
      </Tabs>

      <TagsTable />
    </div>
  );
};

export default MainContent;
