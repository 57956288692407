import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import "./CardWrapper.css";
import CardPaymentV2 from "../CardPaymentV2";

const ChangeWrapper = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);

    return (
        <Elements stripe={stripePromise}>.
            <CardPaymentV2 searchingMapUser={props.searchingMapUser} updateReducerInformations={props.updateReducerInformations} packageInformation={props.packageInformation} getTeamList={props.getTeamList} />
        </Elements>
    );
};

export default ChangeWrapper;