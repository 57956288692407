import React from "react";
import PropTypes from "prop-types";
import {StripedProgressStyles} from "./StripedProgressStyles";

const Index = ({ width, text }) => {
    const {progressContainer, progressBar, progressText, progressRunner} = StripedProgressStyles();
  return (
      <div className={progressContainer}>
          <div className={`${progressBar} ${progressRunner}`} style={{width: `${width}%`}}>
            <div className={progressText}>{text}</div>
          </div>
      </div>
  );
};

Index.propTypes = {
    width: PropTypes.number.isRequired,
    text: PropTypes.string,
};

export default Index;