import {
  Typography,
  makeStyles,
} from "@material-ui/core";
import RecycleEventTable from "./RecycleEventTable";
import { LostReasonStyle } from "../RecycleEventStyle";

const useStyles = makeStyles({
  bodyWrapper: { padding: "16px" },
  paginationWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "16px",
  },
  textFontWeight: {
    fontWeight: 500,
  },
  paginationButton: {
    "& .MuiIconButton-root": {
      "&:focus": {
        background: "white",
      },
      "&:hover": {
        background: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
});

const RecycleEventBody = () => {
  const { grayColor } = LostReasonStyle();

  const classes = useStyles();
  return (
    <div className={classes.bodyWrapper}>

      <RecycleEventTable />

      <div className={classes.paginationWrapper}>
        <div style={{ fontStyle: "italic", color: "#2D6CFF" }}>
          <span className={classes.textFontWeight}>
            **Be sure to choose your recycle events carefully.
            <br />
            This data will be used to compile reports that can be used when
            reaching out to old prospects.
          </span>
        </div>

        {/* <div style={{ minWidth: "410px" }}>
          <TablePagination
            component="div"
            // count={totalCount}
            // page={pagination?.page - 1}
            // onPageChange={handleChangePage}
            // rowsPerPage={pagination?.perPage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.paginationButton}
          />
        </div> */}
      </div>
    </div>
  );
};

export default RecycleEventBody;
