import { useState } from "react";
import { connect } from "react-redux";
import {
  _handleDeleteLeadSourceModal,
  _handleLeadSourceEdit,
} from "../../../actions/leadSourceAction";
import {
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
} from "../../../constants/CoreConstants";
import NewConfirmAlert from "../../activity/new-alert/NewConfirmAlert";
import PermissionDeniedModal from "../../products/common/permissionModal/PermissionDeniedModal";
import { LEAD_TYPES_STATUS } from "../../products/helper/coreConstant";
import { Delete, EditLead, icons } from "./icon";
import AddLeadSourceModal from "./modals/AddLeadSourceModal";
import { ModalStylesLeadSource } from "./ModalStylesLeadSource";

const EachRowApp = (props) => {
  const classes = ModalStylesLeadSource();
  const [openEditLeadModal, setOpenEditLeadModal] = useState(false);
  const [permissionState, setPermissionState] = useState(null);
  const { eachItem } = props;
  const isUserHasPermission = window.hasPermission(
    PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS,
    PERMISSION_LEVELS.WRITE
  );

  function getIcon(sourceTitle) {
    switch (sourceTitle) {
      case "Map":
        return icons.map;
      case "Outgoing Call":
        return icons.outgoingCall;
      case "Incoming SMS":
        return icons.incomingSms;
      case "Api":
        return icons.api;
      case "Contact Share":
        return icons.contactShare;
      case "Mobile app":
        return icons.outgoingCall;
      case "Facebook":
        return icons.facebook;
      case "Followupboss":
        return icons.followup;
      case "Zapier":
        return icons.zapier;
      default:
        return "";
    }
  }

  const handleEditModal = (e) => {
    if (isUserHasPermission) {
      setOpenEditLeadModal(true);
      props._handleLeadSourceEdit({ editFlag: true, editData: eachItem });
    } else {
      setPermissionState("edit");
    }
  };

  const handleDelete = (e) => {
    if (isUserHasPermission) {
      props._handleDeleteLeadSourceModal({
        deleteFlag: false,
        deleteData: null,
        isTransfer: true,
      });

      NewConfirmAlert({
        onSubmit: () => {
          props._handleDeleteLeadSourceModal({
            deleteFlag: true,
            deleteData: eachItem,
            isTransfer: false,
          });
        },
        onCloseClick: () => {
          props._handleDeleteLeadSourceModal({
            deleteFlag: false,
            deleteData: null,
            isTransfer: true,
          });
        },
        title: "Are you sure?",
        description: "Do you want to delete this source?",
        cancelText: "Cancel",
        submitText: "Delete",
        width: "480px",
      });
    } else {
      setPermissionState("delete");
    }
  };

  const handleClose = () => {
    setOpenEditLeadModal(false);
    props._handleLeadSourceEdit({ editFlag: false });
  };
  return (
    <tr>
      <td>
        <div className="icon_text d-flex">
          <span>{getIcon(eachItem.source_title)}</span>
          <span className={classes.text}>{eachItem.source_title}</span>
        </div>
      </td>
      <td className="date_time" style={{ textAlign: "center" }}>
        <span className={`${classes.text} date`}>
          {window.globalTimezoneConversionToDifferentTimezone(
            eachItem.created_at,
            "UTC",
            "",
            "MM/DD/Y, h:mm a"
          )}
        </span>
      </td>

      <td style={{ textAlign: "center" }}>
        <button
          className={classes.button}
          style={{
            backgroundColor:
              eachItem.defined_by === LEAD_TYPES_STATUS.GLOBAL
                ? "#181f48"
                : eachItem.defined_by === LEAD_TYPES_STATUS.USER
                ? "#4a6aff"
                : "#d3d3d3",
          }}
        >
          <span
            style={{
              color:
                eachItem.defined_by === LEAD_TYPES_STATUS.GLOBAL
                  ? "#ffffff"
                  : eachItem.defined_by === LEAD_TYPES_STATUS.USER
                  ? "#ffffff"
                  : "#000000",
              fontSize: "14px",
            }}
          >
            {eachItem.defined_by === LEAD_TYPES_STATUS.GLOBAL
              ? "GLOBAL"
              : eachItem.defined_by === LEAD_TYPES_STATUS.USER
              ? "MY LEAD SOURCE"
              : "SYSTEM"}
          </span>
        </button>
      </td>
      <td>
        <div className={classes.actionContainer}>
          <div className={classes.actions}>
            <button className={classes.editButton} onClick={handleEditModal}>
              {isUserHasPermission ? <EditLead /> : <EditLead fill={"gray"} />}
            </button>
            {eachItem.defined_by === LEAD_TYPES_STATUS.GLOBAL ||
            eachItem.defined_by === LEAD_TYPES_STATUS.USER ? (
              <button className={classes.deleteButton} onClick={handleDelete}>
                {isUserHasPermission ? <Delete /> : <Delete fill={"gray"} />}
              </button>
            ) : (
              <button className={classes.deleteButton} disabled>
                <Delete fill={"gray"} />
              </button>
            )}
          </div>
        </div>
        {openEditLeadModal && (
          <AddLeadSourceModal open={openEditLeadModal} onClose={handleClose} />
        )}
        {permissionState && (
          <PermissionDeniedModal
            open={true}
            onClose={() => setPermissionState(null)}
            permissionModule={"Lead Source"}
            action={permissionState}
          />
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  leadSourceReducer: state.leadSourceReducer,
});

const mapDispatchToProps = (dispatch) => ({
  _handleLeadSourceEdit: (params) => dispatch(_handleLeadSourceEdit(params)),
  _handleDeleteLeadSourceModal: (params) =>
    dispatch(_handleDeleteLeadSourceModal(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EachRowApp);