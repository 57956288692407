import { sendRequest } from './rootApi';
import Api from "../constants/Api";

export const getMapListContactsApi = async (payload) => {
    return await sendRequest("post", Api.getMapListContacts, payload);
}

export const skipTraceRequestApi = async (payload) => {
    return await sendRequest("post", Api.skipTraceRequest, payload);
}

