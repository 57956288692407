import React from 'react';
import { Skeleton } from '@material-ui/lab';

const StatSkeleton = () => {
    
    return (
        <div>
            <div style={{width: '100%', display: 'flex', gap: '20px', padding: '16px', backgroundColor: 'white'}}>
                <div style={{width: '70%', display: 'flex', gap: '12px'}}>
                    <div style={{width: '70%', display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Skeleton variant="rounded" width={"70%"} height={'50px'}/>
                        <Skeleton variant="rounded" width={"70%"} height={'100px'}/>
                        <Skeleton variant="rounded" width={"70%"} height={'100px'}/>
                        <Skeleton variant="rounded" width={"70%"} height={'100px'}/>
                    </div>
                    <div
                        style={{display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Skeleton variant='circle' width={'230px'} height={"230px"}/>
                    </div>
                    <Skeleton variant="rounded" width={"150px"} height={'35px'}/>
                </div>

                <div style={{width: '30%'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '8px'}}>
                        <Skeleton variant="rounded" width={"180px"} height={'50px'}/>
                        <Skeleton variant="rounded" width={"120px"} height={'50px'}/>
                    </div>
                    <Skeleton variant="rounded" width={"100%"} height={'100px'}/>
                    <Skeleton variant="rounded" width={"100%"} height={'100px'}
                              style={{marginTop: '8px', marginBottom: '8px'}}/>
                    <Skeleton variant="rounded" width={"100%"} height={'100px'}/>
                </div>
            </div>
        </div>
    );
};

export default StatSkeleton;
