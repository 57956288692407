import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// import components
import Profile from "../components/profile/Profile";
import General from "../components/general/General";
import Activity from "../components/activity/Activity";
import LeadGroup from "../components/group/LeadGroup";
import Settings from '../components/settings/Settings';
import Team from '../components/team/Team';
import EmailSettings from '../components/emailSettings/EmailSettings';
import Lists from '../components/list/Lists';
import Tags from '../components/tag/Tags';
import ActivityLogs from '../components/activityLogs/ActivityLogs';
import Packages from '../components/packages/Packages';
import Package from '../components/packages/Package';
import MapOrderList from '../components/mapOrderList/MapOrderList';
import MapOrder from '../components/mapOrderList/MapOrder';
import PhoneSettings from '../components/phoneSettings/PhoneSettings';
import MailBox from '../components/mailBox/MailBox';
import Contact from "../components/contactCustomFields/ContactCustomFields"
import CustomField from '../components/customField';
import leadSource from '../components/leadSource';
import SiteNotification from '../components/siteNotifications/SiteNotification';
import LeadNotification from '../components/siteNotifications/LeadNotification';
import HealthCheck from '../components/HealthCheck';
import CallOutcome from '../components/settings/CallOutcome';
import PublicApi from '../components/publicApi';
// import MapIntegration from '../components/mapIntegration/Index';
import GeoMain from '../components/geo/GeoMain';
import GeoFarmingUserNotiMain from '../components/geo-farming-user-noti/GeoFarmingUserNotiMain';
import CardMainPage from '../components/mapIntegration/CardmainPage';
import CardPayment from '../components/mapIntegration/CardPayment';
import FirstPromoter from "../components/firstPromoter/FirstPromoter";
import SnapShot from "../components/snapshot/SnapShot";
import LeadPermissionRequest from "../components/leadPermissionRequest";
import SnapShotCategory from '../components/snapshotCategory/SnapShotCategory';
import ViewSettings from "../components/viewControl/ViewSettings";
import CustomMenu from '../components/customMenu/CustomMenu';
import ContactValidationReport from "../components/contactValidationReport/ContactValidationReport";
import LookupValidation from '../components/lookupValidation/LookupValidation';
import Invoice from '../components/invoice/Invoice';
import TelephonyService from "../components/telephonyService";
import SmartWebhookReport from "../components/smartWebhookReport/SmartWebhookReport";
import SmartWebhookHistoryReport from "../components/smartWebhookReport/SmartWebhookHistoryReport";
import InvoiceSettings from '../components/invoiceSettings/InvoiceSettings';
import MapOrderV2 from "../components/mapOrderV2/MapOrderV2";
import { AppList } from '../components/appList/AppList';
import AppAuthorizeUrl from '../components/appList/AppAuthorizeUrl';
import AppAuthorization from '../components/appList/AppAuthorization';
import CalendarSyncNew from '../components/calendarSyncNew/CalendarSyncNew';
import OpenAi from "../components/openAI/OpenAi";
import DealReport from '../components/dealReport';
import ConnectedGoogleAccount from '../ConnectedGoogleAccount/ConnectedGoogleAccount';
import Products from '../components/products';
import SalesReports from '../components/salesReports/SalesReports';
import Policies from '../components/policies/Policies';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/user/profile'} component={Profile} />
                <Route exact path={'/user/general-settings'} component={Settings} />
                <Route exact path={'/user/call-outcome'} component={CallOutcome} />
                <Route exact path={'/user/settings'} component={General} />
                <Route exact path={'/user/profile/email-settings'} component={EmailSettings} />
                <Route exact path={'/user/profile/team'} component={Team} />
                <Route exact path={'/user/profile/snapshot'} component={SnapShot} />
                <Route exact path={'/user/profile/calendar-sync'} component={CalendarSyncNew} />
                {/* <Route exact path={'/user/profile/calendar-sync'} component={CalendarSync} /> */}
                {/* <Route exact path={'/user/profile/calendar-sync-new'} component={CalendarSyncNew} /> */}
                <Route exact path={'/user/profile/activity-logs'} component={ActivityLogs} />
                <Route exact path={'/user/lists'} component={Lists} />
                <Route exact path={'/user/tags'} component={Tags} />
                <Route exact path={'/user/activity'} component={Activity} />
                <Route exact path={'/user/lead-group'} component={LeadGroup} />
                <Route exact path={'/user/packages'} component={Packages} />
                <Route exact path={'/user/package/:id'} component={Package} />
                <Route exact path={'/user/map-orders'} component={MapOrderList} />
                <Route exact path={'/user/map-orders/:id'} component={MapOrder} />
                <Route exact path={'/user/phone-settings'} component={PhoneSettings} />
                <Route exact path={'/user/mail-box'} component={MailBox} />
                <Route exact path={'/user/all-site-notification'} component={SiteNotification} />
                <Route exact path={'/user/all-lead-notification'} component={LeadNotification} />
                <Route path={'/user/contact'} component={Contact} />
                <Route exact path={'/user/lookup-validation'} component={LookupValidation} />

                {/* custom fields */}
                <Route exact path={'/contact-custom-fields'} component={CustomField} />
                {/* lead source */}
                <Route exact path={'/lead-source-type'} component={leadSource} />

                <Route exact path={'/lead-permission-requests'} component={LeadPermissionRequest} />

                <Route path={"/profile/health-check"} component={HealthCheck} />

                {/* public api related routes */}
                <Route path={"/public-api"} component={PublicApi} />
                <Route exact path={"/user/profile/first-promoter"} component={FirstPromoter} />

                {/* Geofarming integration related routes */}
                <Route exact path={"/map/integration"} component={CardMainPage} />
                <Route exact path={"/map/integration/payment"} component={CardPayment} />


                {/* Geo route */}
                <Route path={"/geo"} component={GeoMain} />

                {/* Geo farming user Notifying route */}
                <Route path={"/geo-farming-user"} component={GeoFarmingUserNotiMain} />

                <Route exact path={'/user/profile/snapshot-marketplace'} component={SnapShotCategory} />
                <Route exact path={'/user/profile/view-settings'} component={ViewSettings} />

                <Route exact path={'/user/profile/custom-menu'} component={CustomMenu} />
                <Route exact path={'/user/contact-validation-report'} component={ContactValidationReport} />
                <Route exact path={'/user/trust-center'} component={TelephonyService} />
                <Route exact path={'/user/invoice'} component={Invoice} />
                <Route exact path={'/user/invoice-settings'} component={InvoiceSettings} />
                <Route exact path={'/user/telephony-service/phone-settings'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/trust-center'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/shaken-stir-cnam'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-outcome'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-logs'} component={TelephonyService} />
                <Route exact path={'/user/telephony-service/call-logs-details'} component={TelephonyService} />

                <Route exact path={'/user/smart-webhook-report'} component={SmartWebhookReport} />
                <Route exact path={'/user/smart-webhook-report/history'} component={SmartWebhookHistoryReport} />

                <Route exact path={'/user/map-orders/v2/:id'} component={MapOrderV2} />

                <Route exact path={'/user/app-list'} component={AppList} />
                <Route exact path={'/user/app-list/authorize/:clientID'} component={AppAuthorizeUrl} />
                <Route exact path={'/oauth2/authorize'} component={AppAuthorization} />
                <Route exact path={'/user/profile/open-ai-integration'} component={OpenAi} />

                <Route exact path={'/user/deal-report'} component={DealReport} />
                <Route exact path={'/user/connected-google-account'} component={ConnectedGoogleAccount} />,
                <Route exact path={'/user/products'} component={Products} />
                <Route exact path={'/user/sales-reports'} component={SalesReports} />
                <Route exact path={'/user/policies'} component={Policies} />
            </Switch>
        </Router>
    );
}

export default Routes;